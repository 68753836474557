import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  background-color: rgba(0, 0, 0, 0.75);
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
`;
