export default {
  confirmation: {
    fan: {
      id: 'emailConfirmed.message.fan',
      defaultMessage: 'Your email address has been confirmed. You can now sign in in the app.',
    },
    artist: {
      id: 'emailConfirmed.message.artist',
      defaultMessage: 'Your email address has been confirmed. You can now sign in in the app.',
    },
  },
  cta: {
    fan: {
      id: 'emailConfirmed.cta.fan',
      defaultMessage: 'Open Stanbase app',
    },
    artist: {
      id: 'emailConfirmed.cta.artist',
      defaultMessage: 'Open Stanbase Artist app',
    },
  },
  errorCantOpenApp: {
    id: 'emailConfirmed.error.cantOpenApp',
    defaultMessage:
      "Looks like you're on a device that doesn't have the app installed. Please open the app manually on your phone.",
  },
};
