import React from 'react';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import styled from 'styled-components/native';

export interface BorderBox {
  isLive?: boolean;
  isArchived?: boolean;
}

const BorderBox: React.FC<BorderBox> = ({ isLive, isArchived, ...rest }: BorderBox) => {
  return (
    <Svg width={'100%'} height={'100%'} viewBox="0 0 164 164" fill="#000" {...rest}>
      <Rect
        x={0}
        y={0}
        rx={10}
        fill={`rgba(0,0,0,${isArchived ? '0.5' : '0'})`}
        stroke="url(#prefix__paint10_linear)"
        strokeWidth={isLive ? 5 : 2}
        width={'100%'}
        height={'100%'}
      />
      <Defs>
        <LinearGradient
          id="prefix__paint10_linear"
          x1={0}
          y1={0}
          x2={150}
          y2={150}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#0537FF" />
          <Stop offset={0.7} stopColor="#9A26FF" />
          <Stop offset={1} stopColor="#1DFF78" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default styled(BorderBox)`
  left: 0;
  position: absolute;
  top: 0;
`;
