import React from 'react';
import styled from 'styled-components/native';

import { useUnsubscribeModal } from 'context';
import { useActiveFollowing, useFollowing } from 'hooks';
import { Artist } from 'types';

import Avatar from './Avatar';

const View = styled.View`
  align-items: center;
`;

const Card = styled.View`
  background: rgba(46, 81, 223, 0.24);
  border-radius: 14px;
  width: 216px;
  height: 251px;
  margin: 8px;
  align-items: center;
  padding: 24px 0;
  justify-content: space-between;
`;

const Name = styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: #ffffff;
  margin-top: 16px;
`;

const TouchableOpacity = styled.TouchableOpacity`
  border: 1px solid #fff;
  border-radius: 22px;
  width: 100px;
  height: 28;
  justify-content: center;
`;

const ButtonLabel = styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.06px;
  color: #ffffff;
`;

interface Props {
  artist: Artist;
  buttonLabel: string;
  currentTabIndex: number;
  onPress: () => void;
}

const ArtistCard: React.FC<Props> = ({ artist, buttonLabel, currentTabIndex, onPress }: Props) => {
  const { unfollow } = useFollowing(artist.username);
  const { mutate: mutateFollowers } = useActiveFollowing();
  const { unsubscribeModalOpen, isUnsubscribing } = useUnsubscribeModal();

  const unfollowArtist = async () => {
    await unfollow();
    mutateFollowers();
  };

  const unsubscribeArtist = async () => {
    await unsubscribeModalOpen(artist);
  };

  return (
    <Card>
      <View>
        <Avatar imageUrl={artist?.profile_picture || ''} onPress={onPress} />
        <Name>{artist?.display_name}</Name>
      </View>
      <TouchableOpacity onPress={currentTabIndex === 0 ? unsubscribeArtist : unfollowArtist}>
        <ButtonLabel>{buttonLabel}</ButtonLabel>
      </TouchableOpacity>
    </Card>
  );
};

export default ArtistCard;
