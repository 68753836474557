import styled, { css } from 'styled-components/native';

import { AvatarMode } from '../Avatar';

interface TexViewProps {
  mode: AvatarMode;
}

export default styled.View<TexViewProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.mode === 'row' &&
    css`
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
`;
