import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface SvgComponentProps {}

const SvgComponent: React.FC<SvgComponentProps> = ({ ...rest }: SvgComponentProps) => {
  return (
    <Svg width={96} height={96} viewBox="0 0 96 96" fill="none" {...rest}>
      <Path
        d="M80 78V76C80 71.7565 78.3143 67.6869 75.3137 64.6863C72.3131 61.6857 68.2435 60 64 60H32C27.7565 60 23.6869 61.6857 20.6863 64.6863C17.6857 67.6869 16 71.7565 16 76V78"
        stroke="#ffffff"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M48 50C56.8366 50 64 42.8366 64 34C64 25.1634 56.8366 18 48 18C39.1634 18 32 25.1634 32 34C32 42.8366 39.1634 50 48 50Z"
        stroke="#ffffff"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
