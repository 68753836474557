import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.pollContent.primary};
  margin-left: 25px;
  font-size: 15px;
  z-index: 10;
  opacity: 1;
  font-family: BasierCircle-Regular;
`;
