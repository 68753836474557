export default {
  unlockPaymentRequestTitle: {
    id: 'post.commentsSubscribe',
    defaultMessage: 'Become a Stan',
  },
  unlockPaymentRequestDescription: {
    id: 'post.commentsSubscribePaymentMessage',
    defaultMessage: 'Subscribe to {username} and get access to exclusive Stan only content!',
  },
};
