import styled from 'styled-components/native';

import FeedItemPollContent from 'components/FeedItemPollContent';
import { FeedItemPollContentProps } from 'components/FeedItemPollContent/FeedItemPollContent';

export default styled(FeedItemPollContent)<FeedItemPollContentProps>`
  position: absolute;
  width: 100%;
  height: 100%;
`;
