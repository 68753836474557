import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

interface Props {
  active: boolean;
}

export default styled(Button).attrs<Props>(({ active, theme }) => ({
  short: true,
  secondary: true,
  labelStyle: {
    color: active ? theme.colors.text : theme.button.textGhostSecondary,
  },
}))`
  height: 36px;
  padding: 0 15px;
  border-radius: 10px;
  border-width: 0;
  margin-right: 7px;
  ${({ active }: Props) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.button.backgroundColorGhost};
    `}
`;
