import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import { VARIANT } from 'context/appInfo';
import { useSnackbarSet } from 'context/snackbar';

import messages from './messages';
import Container from './styled/Container';
import DownloadAttachment from './styled/DownloadAttachment';
import IconEdit from './styled/IconEdit';
import ImagePreview from './styled/ImagePreview';
import ImageWrapper from './styled/ImageWrapper';
import InfoWrapper from './styled/InfoWrapper';
import NameView from './styled/NameView';
import OrderName from './styled/OrderName';
import OrderSendDate from './styled/OrderSendDate';
import OrderSize from './styled/OrderSize';

export interface OrderAttachmentProps {
  /** The preview image url for the order */
  previewImage?: string;
  /** The order attachment name */
  name: string;
  /** The order attachment size */
  size: string;
  /** The order attachment send date */
  sendDate: string;

  onEditPress?: () => void;
}

/**
 * Orders preview in Marketplace module
 */
const OrderAttachment: React.FC<OrderAttachmentProps> = ({
  name,
  size,
  previewImage,
  sendDate,
  onEditPress,
  ...rest
}: OrderAttachmentProps) => {
  const intl = useIntl();
  const { setSnackbar } = useSnackbarSet();
  const [isDownloading, setIsDownloading] = useState(false);
  const formattedDate = useMemo(() => {
    try {
      return intl.formatMessage(messages.sentAt, {
        date: format(new Date(sendDate), 'dd/MMM/yyyy, H:m'),
      });
    } catch (e) {
      return 'error date';
    }
  }, [sendDate]);

  const downloadFile = useCallback(async () => {
    if (previewImage && !isDownloading) {
      setIsDownloading(true);
      let message;
      try {
        const link = document.createElement('a');
        link.download = `${name}.jpg`;
        link.target = '_blank';
        link.href = previewImage;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return Promise.resolve();
        message = intl.formatMessage(messages.attachment.saved);
      } catch (e) {
        message = intl.formatMessage(messages.attachment.error);
      } finally {
        setIsDownloading(false);
        setSnackbar({
          visible: true,
          label: message,
          actionLabel: intl.formatMessage(messages.closeSnackbar),
        });
      }
    }
  }, [previewImage, name, isDownloading]);

  return (
    <Container {...rest}>
      <ImageWrapper>{previewImage && <ImagePreview source={{ uri: previewImage }} />}</ImageWrapper>
      <InfoWrapper>
        <NameView>
          <OrderName numberOfLines={1}>{name}</OrderName>
          {VARIANT === 'artist' && (
            <TouchableOpacity onPress={onEditPress}>
              <IconEdit />
            </TouchableOpacity>
          )}
        </NameView>
        <OrderSize>{size}</OrderSize>
        <OrderSendDate>{formattedDate}</OrderSendDate>
        <DownloadAttachment processing={isDownloading} onPress={downloadFile}>
          {intl.formatMessage(messages.action)}
        </DownloadAttachment>
      </InfoWrapper>
    </Container>
  );
};

OrderAttachment.displayName = 'OrderAttachment';

export default OrderAttachment;
