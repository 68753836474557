import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import messages from '../messages';

const TouchableOpacity = styled.TouchableOpacity``;

const Text = styled.Text`
  font-size: 17px;
  color: ${({ theme }) => theme.webview.text};
`;

interface BackButtonProps {
  onPress: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onPress }: BackButtonProps) => {
  const intl = useIntl();
  return (
    <TouchableOpacity onPress={onPress}>
      <Text>{intl.formatMessage(messages.backToApp)}</Text>
    </TouchableOpacity>
  );
};

export default BackButton;
