import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

import { useBackEnd } from 'context/backEnd';
import { useAuthSwr } from 'hooks/swr';
import { ArtistProduct, Bookmarks, FeedItemUpdateFn, MarketplaceItem } from 'types';
import { getModelNamePlural } from 'utils/product';

export interface UseBookmarkArgs {
  product: ArtistProduct | MarketplaceItem;
  updateFeedItem: FeedItemUpdateFn;
}

export interface UseBookmark {
  likesCount?: number;
  toggleBookmark: () => void;
  isProcessing: boolean;
}

export function useBookmarks(): {
  bookmarks?: Bookmarks;
  isLoading: boolean;
  refresh: () => void;
  error: AxiosError<any>;
} {
  const BOOKMARKS_KEY = '/bookmarks';

  const {
    data: bookmarks,
    error,
    isLoading,
    mutate,
  } = useAuthSwr<Bookmarks>({
    key: BOOKMARKS_KEY,
    isPublic: false,
    cache: false,
    dontCheckValidation: true,
  });

  return {
    bookmarks,
    isLoading,
    refresh: mutate,
    error,
  };
}

export function useBookmark({ product, updateFeedItem }: UseBookmarkArgs): UseBookmark {
  const pluralName = getModelNamePlural(product.model_name);
  const LIKE_BASE_KEY = `/artists/${product?.artist?.username}/products/${pluralName}/${product.id}/bookmark`;
  const { axiosInstance } = useBackEnd();
  const { refresh } = useBookmarks();
  const [isProcessing, setIsProcessing] = useState(false);

  const toggleBookmark = useCallback(async () => {
    const sendBookmark = product.is_bookmarked ? axiosInstance.delete : axiosInstance.post;
    const newBookmarksCount = product.is_bookmarked
      ? product.bookmarks_count - 1
      : product.bookmarks_count + 1;
    const newBookmark = !product.is_bookmarked;
    const cachedBookmarks = product.bookmarks_count;

    updateFeedItem?.(
      product,
      {
        is_bookmarked: newBookmark,
        bookmarks_count: newBookmarksCount,
      },
      false,
    );

    try {
      const { data } = await sendBookmark(LIKE_BASE_KEY);
      setIsProcessing(true);
      updateFeedItem?.(product, {
        is_bookmarked: newBookmark,
        bookmarks_count: data?.bookmarks_count,
      });
      refresh();
    } catch (e) {
      updateFeedItem?.(product, {
        is_bookmarked: product.is_bookmarked,
        bookmarks_count: cachedBookmarks,
      });
    }
    setIsProcessing(false);
  }, [refresh, updateFeedItem, isProcessing]);

  return {
    isProcessing,
    toggleBookmark,
  };
}
