import styled from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

import darkTheme from 'themes/dark';

export default styled(ActivityIndicator).attrs({
  color: darkTheme.colors.text,
  size: 24,
})<{ noMargin?: boolean; withPadding?: boolean }>`
  margin-left: ${({ noMargin }) => (noMargin ? 0 : '10px')};
  padding: ${({ withPadding }) => (withPadding ? '30px 0 15px' : 0)};
`;
