import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

interface IconProps {
  color: string;
}

export default styled((props: any) => <FontAwesome5 name="credit-card" {...props} />).attrs({
  size: 14,
})<IconProps>``;
