import { Platform } from 'react-native';
import styled from 'styled-components/native';

export default styled.View`
  ${({ theme }) =>
    theme.media.tabletLandscape
      ? `
  flex-direction: row;
  justify-content: space-between;`
      : `padding: 41px 30px`}
`;

export const MainWrapper = styled.View`
  display: block;
  padding: ${({ theme }) => (theme.media.tabletLandscape ? '41px 148px' : `41px 30px`)};
`;

export const Section = styled.View<{ wild?: boolean }>`
  ${({ theme, wild }) =>
    Platform.OS === 'web' &&
    (theme.media.tabletLandscape
      ? `
  ${wild ? 'width: 60%' : 'width: 30%'}`
      : 'margin-top: 20px;')}
`;
