import React from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'components/Dialog';

import { usePayments } from 'context/payments';
import { SubscriptionStatus } from 'hooks/subscriptions';
import { Artist } from 'types';
import { formatDate } from 'utils/date';

import Button from './styled/Button';
import ButtonText from './styled/ButtonText';
import ButtonTextSecondary from './styled/ButtonTextSecondary';

interface Props {
  artist?: Artist;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  unsubscribeData?: SubscriptionStatus | null;
}

const UnsubscribedDialog: React.FC<Props> = ({
  artist,
  isOpen,
  setIsOpen,
  unsubscribeData,
}: Props) => {
  const intl = useIntl();
  const { requestPayment } = usePayments();

  return (
    <Dialog
      onPressOutside={() => setIsOpen(false)}
      visible={isOpen}
      title={intl.formatMessage({
        id: 'subscription.unsubscribed',
        defaultMessage: 'Artist unsubscribed',
      })}
      subtitle={`${artist?.display_name} ${intl.formatMessage({
        id: 'subscription.successfulyUnfollowed',
        defaultMessage:
          'has been successfuly unfollowed. You still have access to Fan content till',
      })} ${formatDate(
        unsubscribeData?.time_end ? new Date(unsubscribeData.time_end) : undefined,
        'Do of MMMM YYYY',
      )}`}
    >
      <>
        <Button
          onPress={() => {
            setIsOpen(false);
            artist &&
              requestPayment(
                { type: 'resubscription', artistUsername: artist.username },
                intl.formatMessage({
                  id: 'subscription.resubscriptionPaymentSheetTitle',
                  defaultMessage: 'Keep your Stan badge',
                }),
                `${intl.formatMessage(
                  {
                    id: 'subscription.resubscriptionPaymentSheetDescription',
                    defaultMessage: 'You still have access to Fan content till {tillDate}',
                  },
                  {
                    tillDate: formatDate(
                      unsubscribeData?.time_end ? new Date(unsubscribeData.time_end) : undefined,
                      'Do of MMMM YYYY',
                    ),
                  },
                )}`,
              );
          }}
        >
          <ButtonText>
            {intl.formatMessage({
              id: 'subscription.badgeBack',
              defaultMessage: 'Give me my Stan badge back!',
            })}
          </ButtonText>
        </Button>
        <Button secondary onPress={() => setIsOpen(false)}>
          <ButtonTextSecondary>
            {intl.formatMessage({
              id: 'subscription.close',
              defaultMessage: 'Close',
            })}
          </ButtonTextSecondary>
        </Button>
      </>
    </Dialog>
  );
};

export default UnsubscribedDialog;
