import { useWindowDimensions } from 'react-native';

export interface Media {
  mobile: boolean;
  tablet: boolean;
  tabletLandscape: boolean;
  desktop: boolean;
  bigDesktop: boolean;
}

export const useScreenSize = (): Media => {
  const windowWidth = useWindowDimensions().width;
  const media: Media = {
    mobile: true,
    tablet: false,
    tabletLandscape: false,
    desktop: false,
    bigDesktop: false,
  };

  if (windowWidth >= 420) media.mobile = true;
  if (windowWidth >= 760) media.tablet = true;
  if (windowWidth >= 1024) media.tabletLandscape = true;
  if (windowWidth >= 1600) media.desktop = true;
  if (windowWidth >= 1921) media.bigDesktop = true;

  return media;
};
