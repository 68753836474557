import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function IconRespond(props: SvgProps): JSX.Element {
  return (
    <Svg width={23} height={19} viewBox="0 0 23 19" fill="none" {...props}>
      <Path
        d="M1 9L9 1V6C14.6 6 22 8.2 22 18C19.3 13.4 16.5 12 9 12V17L1 9Z"
        stroke="#FFF"
        strokeWidth={1.4}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
