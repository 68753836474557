import styled from 'styled-components/native';

import LockedItem from 'components/LockedItem';

export default styled(LockedItem)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
