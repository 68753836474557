import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View`
  background-color: ${({ theme }) => theme.notificationsGroup.indicatorBackground};
  border-radius: 2.5px;
  height: 5px;
  position: absolute;
  width: 5px;

  ${Platform.OS !== 'web'
    ? css`
        left: 10px;
      `
    : css`
        right: 10px;
      `}
`;
