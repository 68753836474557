import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function ViewersCountIcon(props: SvgProps) {
  return (
    <Svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <Path
        d="M1.5835 7.00004C1.5835 7.00004 3.91683 2.33337 8.00016 2.33337C12.0835 2.33337 14.4168 7.00004 14.4168 7.00004C14.4168 7.00004 12.0835 11.6667 8.00016 11.6667C3.91683 11.6667 1.5835 7.00004 1.5835 7.00004Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8 8.75C8.9665 8.75 9.75 7.9665 9.75 7C9.75 6.0335 8.9665 5.25 8 5.25C7.0335 5.25 6.25 6.0335 6.25 7C6.25 7.9665 7.0335 8.75 8 8.75Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledViewersCountIcon = styled(ViewersCountIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export default React.memo(StyledViewersCountIcon);
