import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, Platform } from 'react-native';

import ItemOverlay from 'components/ItemOverlay';
import LockedItem from 'components/LockedItem';
import { LockedItemProps } from 'components/LockedItem/LockedItem';

import { Cover } from 'types';

import ImageBackground from './styled/ImageBackground';
import LoadingIndicator from './styled/LoadingIndicator';
import PinchableImage from './styled/PinchableImage';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';

export interface FeedItemPhotoContentProps {
  /** Access info */
  access?: {
    hasAccess: boolean;
  };
  /** Blurred cover to display when the image is locked */
  blurredCover?: Cover;
  /** Image file URL */
  url?: string;
  /** Image width */
  mediaWidth?: number;
  /** Image height */
  mediaHeight?: number;
  /**
   * Show/hide dark overlay over the image content to increase legibility of text and icons
   * displayed over the image
   */
  showOverlay?: boolean;
  /** Callback fired when image dimensions are retrieved (unless passed explicitly as props) */
  onDimensions?: (width: number, height: number) => void;
  /** Callback fired when the image content finishes loading */
  onLoad?: () => void;
  /** Press even callback */
  onPress?: () => void;
  /** Callback fired when the locked item is pressed */
  isExpanded?: boolean;
  lockedItemProps?: LockedItemProps;
}

type CancelPromise = ((reason?: Error) => void) | undefined;
type ImageSize = { width: number; height: number };
interface ImageSizeOperation {
  start: () => Promise<ImageSize>;
  cancel: CancelPromise;
}

const getImageSize = (uri: string): ImageSizeOperation => {
  let cancel: CancelPromise;
  const start = (): Promise<ImageSize> =>
    new Promise<{ width: number; height: number }>((resolve, reject) => {
      cancel = reject;
      Image.getSize(
        uri,
        (width, height) => {
          cancel = undefined;
          resolve({ width, height });
        },
        (error) => {
          reject(error);
        },
      );
    });

  return { start, cancel };
};

const FeedItemPhotoContent: React.FC<FeedItemPhotoContentProps> = ({
  access,
  blurredCover,
  url,
  mediaWidth,
  mediaHeight,
  showOverlay,
  onDimensions,
  onLoad,
  onPress,
  isExpanded,
  lockedItemProps,
  ...restProps
}: FeedItemPhotoContentProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaderVisible, setLoaderVisible] = useState(false);
  const loaderTimeoutId = useRef<number>();

  useEffect(() => {
    loaderTimeoutId.current = setTimeout(() => setLoaderVisible(true), 250);
    return () => {
      clearTimeout(loaderTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (!onDimensions) {
      return;
    }

    if (mediaWidth && mediaHeight) {
      onDimensions?.(mediaWidth, mediaHeight);
      return;
    }

    let cancel: CancelPromise;
    const sideEffect = async (): Promise<void> => {
      if (url) {
        try {
          const operation = getImageSize(url);
          cancel = operation.cancel;
          const { width, height } = await operation.start();
          onDimensions?.(width, height);
        } catch (error) {
          console.error(error);
        }
      }
    };

    sideEffect();

    return () => {
      if (cancel) {
        cancel();
      }
    };
  }, [url, onDimensions]);

  const onLoadComplete = useCallback(() => {
    setIsLoaded(true);
    onLoad?.();
  }, [onLoad]);

  if (access?.hasAccess === false && blurredCover) {
    return (
      <LockedItem
        type="image"
        isExpanded={isExpanded}
        cover={blurredCover}
        showOverlay={showOverlay}
        {...lockedItemProps}
      />
    );
  }

  return (
    <TouchableOpacity onPress={onPress} {...restProps}>
      <View>
        {isExpanded ? (
          Platform.OS === 'web' ? (
            <Image
              source={{ uri: url }}
              onLoad={onLoadComplete}
              style={{ height: '100%', width: '100%' }}
              resizeMode="contain"
            />
          ) : (
            <PinchableImage
              mediaWidth={mediaWidth}
              mediaHeight={mediaHeight}
              source={{ uri: url }}
              onLoad={onLoadComplete}
            />
          )
        ) : (
          <ImageBackground source={{ uri: url }} onLoad={onLoadComplete} />
        )}
        {showOverlay && <ItemOverlay />}
        <LoadingIndicator animating={!isLoaded && isLoaderVisible} />
      </View>
    </TouchableOpacity>
  );
};

export default FeedItemPhotoContent;
