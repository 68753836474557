import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';

import ActivityIndicator from 'components/ActivityIndicator';

import { useActiveFollowing, useActiveSubscriptions } from 'hooks';
import { Artist } from 'types';

import Tabs from '../../styled/Tabs';
import messages from './messages';
import ArtistCard from './styled/ArtistCard';
import ArtistsCardsContainer from './styled/ArtistsCardsContainer';
import MainView from './styled/MainView';
import SearchBar from './styled/SearchBar';

const YourArtists: React.FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const intl = useIntl();
  const { activeSubscriptions, isLoading: isLoadingSubs } = useActiveSubscriptions();
  const { activeFollowing, isLoading: isLoadingFollowed } = useActiveFollowing();
  const navigation = useNavigation();

  const navigateToArtist = (artist: Artist) => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, { artistUsername: artist.username });
  };

  const tabs = useMemo(() => {
    return [
      {
        label: intl.formatMessage(messages.tabs.subscribed),
      },
      {
        label: intl.formatMessage(messages.tabs.followed),
      },
    ];
  }, []);

  const renderContent = useCallback(() => {
    if ((currentTabIndex === 0 && isLoadingSubs) || (currentTabIndex === 1 && isLoadingFollowed)) {
      return <ActivityIndicator />;
    }

    const mappedArtists =
      currentTabIndex === 0
        ? activeSubscriptions?.map((x) => x.plan_price.plan.artist)
        : activeFollowing || [];

    let filteredArtists;

    if (searchQuery) {
      filteredArtists = mappedArtists.filter((_) =>
        new RegExp(searchQuery, 'i').test(_.display_name),
      );
    }
    return (searchQuery ? filteredArtists : mappedArtists).map((artist: Artist, index: number) => (
      <ArtistCard
        artist={artist}
        key={`${artist?.username}-${index}-card`}
        onPress={() => navigateToArtist(artist)}
        currentTabIndex={currentTabIndex}
        buttonLabel={
          currentTabIndex === 0
            ? intl.formatMessage(messages.yourArtists.price, {
                price: Number(activeSubscriptions[index].plan_price.price)?.toFixed(2),
              })
            : intl.formatMessage(messages.yourArtists.followed)
        }
      />
    ));
  }, [
    currentTabIndex,
    isLoadingSubs,
    isLoadingFollowed,
    activeSubscriptions,
    activeFollowing,
    searchQuery,
  ]);

  return (
    <MainView>
      <SearchBar
        onCancel={() => setSearchQuery('')}
        onChange={(query: string) => setSearchQuery(query)}
        value={searchQuery}
      />
      <Tabs tabs={tabs} currentTabIndex={currentTabIndex} onTabIndexChange={setCurrentTabIndex} />
      <ArtistsCardsContainer>{renderContent()}</ArtistsCardsContainer>
    </MainView>
  );
};

export default YourArtists;
