import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Alert, Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as FORGOT_PASSWORD_SCREEN_NAME } from 'screens/ForgotPasswordScreen';
import InnerLinkText from 'screens/LoginWithEmailScreen/styled/InnerLinkText';
import { SCREEN_NAME as REGISTER_WITH_EMAIL_SCREEN_NAME } from 'screens/RegisterWithEmailScreen';

import ContinueWithView from 'components/ContinueWithView';
import FormErrorsView from 'components/FormErrorsView';
import FormTitleText from 'components/FormTitleText';
import LoginFormButton from 'components/LoginFormButton';
import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { usePushNotifications, VARIANT } from 'context';
import { useAuth } from 'hooks/auth';
import { getFingerprint } from 'utils/fingerprint';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
export { SCREEN_NAME } from './constants';
import messages from './messages';
import ContentView from './styled/ContentView';
import LinkText from './styled/LinkText';
import LoginView from './styled/LoginView';
import MainView from './styled/MainView';
import Text from './styled/Text';
import TextInput from './styled/TextInput';
import TextView from './styled/TextView';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const LoginWithEmailScreen: React.FC<Props> = ({ navigation }: Props) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    getValues,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<{
    email: string;
    password: string;
    login: any;
  }>({
    mode: 'onChange',
    defaultValues: {
      email:
        process.env.ENV === 'staging' ? (VARIANT === 'fan' ? 'stanfan1@stanbase.com' : '') : '',
      password:
        process.env.ENV === 'staging' ? (VARIANT === 'fan' ? 'StanUser$01' : 'StanArtist$01') : '',
    },
  });
  const { deviceToken } = usePushNotifications();
  const { isLoading, authError, logIn, resendVerificationEmail } = useAuth();
  const intl = useIntl();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setSubmitted(isSubmitted);
  }, [isSubmitted]);

  useEffect(() => {
    setSubmitted(!isSubmitting);
  }, [isSubmitting]);

  const doSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [handleSubmit]);

  const onSubmit = useCallback(
    async (args: { email: string; password: string }) => {
      const { email, password } = args;
      if (isLoading) {
        return;
      }
      const browserId = await getFingerprint();
      return logIn(email, password, deviceToken, browserId);
    },
    [isLoading, logIn, deviceToken],
  );

  useEffect(() => {
    (emailInputRef.current as any)?.focus();
  }, []);

  const handleResendEmail = useCallback(() => {
    const email = getValues('email');
    const response = resendVerificationEmail(email);
    if (response) {
      clearErrors('email');
      clearErrors('password');
      clearErrors('login');
      Alert.alert(
        intl.formatMessage(messages.resendVerificationEmail.title),
        intl.formatMessage(messages.resendVerificationEmail.description, {
          emailAddress: function emailAddress() {
            return email;
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!authError) {
      return;
    }
    setError('login', {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      message: intl.formatMessage(
        (messages.error as any)[authError.data.code] || messages.error.authentication_failed,
        {
          resend: function resendEmail(chunk) {
            return <InnerLinkText onPress={handleResendEmail}>{chunk}</InnerLinkText>;
          },
        },
      ),
    });
  }, [authError, isSubmitting]);

  const handleResetPassword = useCallback(() => {
    navigation.navigate(FORGOT_PASSWORD_SCREEN_NAME, { email: getValues('email') });
  }, []);

  const onRegisterCtaPress = useCallback(() => {
    navigation.navigate(REGISTER_WITH_EMAIL_SCREEN_NAME);
  }, []);

  return (
    <MainView>
      <WebContainer>
        <NavigationHeader showGoBack insets={{ top: 35 }} headerMiddleLogo={true} />
        <LoginView>
          <ContentView>
            <FormTitleText>{intl.formatMessage(messages.title[VARIANT])}</FormTitleText>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={emailInputRef}
                  label={intl.formatMessage(messages.emailInput.label)}
                  placeholder={intl.formatMessage(messages.emailInput.placeholder)}
                  keyboardType="email-address"
                  returnKeyType="next"
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: any) => {
                    clearErrors('email');
                    clearErrors('login');
                    onChange(value?.toString().trim());
                  }}
                  onFocus={() => setSubmitted(false)}
                  onSubmitEditing={() => (passwordInputRef.current as any)?.focus()}
                  isError={submitted}
                />
              )}
              name="email"
              rules={{
                required: intl.formatMessage(messages.emailInput.error.required),
                pattern: {
                  value: regex.email,
                  message: intl.formatMessage(messages.emailInput.error.invalid),
                },
              }}
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={passwordInputRef}
                  label={intl.formatMessage(messages.passwordInput.label)}
                  placeholder={intl.formatMessage(messages.passwordInput.placeholder)}
                  secureTextEntry={true}
                  returnKeyType="go"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: any) => {
                    clearErrors('password');
                    clearErrors('login');
                    onChange(value);
                  }}
                  onSubmitEditing={doSubmit}
                  isError={submitted}
                  onFocus={() => setSubmitted(false)}
                />
              )}
              name="password"
              rules={{
                required: intl.formatMessage(messages.passwordInput.error.required),
              }}
            />
            <LinkText onPress={handleResetPassword}>
              {intl.formatMessage(messages.resetPasswordCtaLabel)}
            </LinkText>

            {submitted && <FormErrorsView errors={errors} />}

            <LoginFormButton
              disabled={!isValid}
              processing={isSubmitting || isLoading}
              onPress={doSubmit}
            >
              {intl.formatMessage(messages.loginCtaLabel)}
            </LoginFormButton>
          </ContentView>
          <ContinueWithView />
          <TextView>
            <Text>
              {intl.formatMessage(messages.createAccountCtaLabel, {
                cta: function CtaChunk(chunk) {
                  return (
                    <Text underline={true} onPress={onRegisterCtaPress}>
                      {chunk}
                    </Text>
                  );
                },
              })}
            </Text>
          </TextView>
        </LoginView>
      </WebContainer>
    </MainView>
  );
};

export default LoginWithEmailScreen;
