import { Platform } from 'react-native';
import styled from 'styled-components/native';

interface ViewProps {
  isActive: boolean;
}

export default styled.View<ViewProps>`
  margin-right: 5px;
  ${({ isActive, theme }) =>
    isActive
      ? `
        width: 8px;
        height: 8px;
        background-color: ${theme.bottomSheet.activeDot};
        border-radius: 5px;
        ${Platform.OS === 'web' && 'transform: translateY(-2px);'}
    `
      : `
        width: 5px;
        height: 5px;
        background-color: ${theme.bottomSheet.unactiveDot};
        border-radius: 8px;
    `}
`;
