import styled from 'styled-components/native';

export default styled.Text`
  color: #ccc;
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: right;
  height: 20px;
`;
