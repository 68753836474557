import React from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/Button';

import { useConfig } from 'hooks/config';

import messages from './messages';
import ButtonView from './styled/ButtonView';
import Description from './styled/Description';
import FollowedArtists from './styled/FollowedArtists';
import Placeholder from './styled/Placeholder';
import StancastingArtists from './styled/StancastingArtists';
import Thumbnail from './styled/Thumbnail';
import Title from './styled/Title';
import View from './styled/View';

interface Props {
  screenName: 'FollowArtistsScreen' | 'ViewStancastingScreen';
  thumbnail: any;
  title: string;
  description: string;
  index: number;
  length: number;
  onNextPress?: () => void;
}

const OnBoardingContainer: React.FC<Props> = ({
  screenName,
  thumbnail,
  title,
  description,
  index,
  length,
  onNextPress,
}: Props) => {
  const { config } = useConfig();
  const intl = useIntl();
  return (
    <View>
      {screenName === 'FollowArtistsScreen' && config.onBoardingUseBackendFaces && (
        <FollowedArtists />
      )}
      {screenName === 'ViewStancastingScreen' && config.onBoardingUseBackendFaces && (
        <StancastingArtists />
      )}
      {!(
        (screenName === 'FollowArtistsScreen' && config.onBoardingUseBackendFaces) ||
        (screenName === 'ViewStancastingScreen' && config.onBoardingUseBackendFaces)
      ) && <Thumbnail source={thumbnail} resizeMode="contain" />}
      <Placeholder />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ButtonView>
        <Button primary onPress={onNextPress}>
          {index < length - 1
            ? intl.formatMessage(messages.next)
            : intl.formatMessage(messages.getStarted)}
        </Button>
      </ButtonView>
    </View>
  );
};

export default OnBoardingContainer;
