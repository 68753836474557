import { Video } from 'expo-av';
import styled from 'styled-components/native';

export default styled(Video)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;
