import { ProductType } from 'types';

import { MessageDescriptor } from '@formatjs/intl/src/types';

type Messages = {
  [key in ProductType | 'unlock']: MessageDescriptor;
};

interface LockedItemMessages {
  subscription: Messages;
  stanPlus: Messages;
}

const defaultMessage: MessageDescriptor = {
  id: 'lockeditem.default',
  defaultMessage: 'Want to see what others can’t?\nUnlock and load.',
};

const messages: LockedItemMessages = {
  subscription: {
    video: {
      id: 'lockeditem.subscription.video',
      defaultMessage: 'Easy, tiger. This video is blocked.\nClick below to enjoy.',
    },
    image: {
      id: 'lockeditem.subscription.photo',
      defaultMessage: 'Want to see what others can’t?\nUnlock and load.',
    },
    audio: {
      id: 'lockeditem.subscription.audio',
      defaultMessage: 'If you want to play, first you must pay.\nClick below.',
    },
    poll: {
      id: 'lockeditem.subscription.poll',
      defaultMessage: '[{artist}] has some questions for you.\nUnlock below.',
    },
    marketplaceitem: defaultMessage,
    past_live_stream: defaultMessage,
    live_stream: defaultMessage,
    unlock: {
      id: 'lockeditem.subscription.unlock',
      defaultMessage: 'Become a Stan',
    },
  },
  stanPlus: {
    video: {
      id: 'lockeditem.stanPlus.video',
      defaultMessage: '[{artist}] has posted an exclusive video.\nWanna see it?',
    },
    image: {
      id: 'lockeditem.stanPlus.photo',
      defaultMessage: '[{artist}] has posted an exclusive photo.\nWanna see it?',
    },
    audio: {
      id: 'lockeditem.stanPlus.audio',
      defaultMessage: 'For your ears only. Well, almost. Make a payment to unlock exclusive audio.',
    },
    poll: {
      id: 'lockeditem.stanPlus.poll',
      defaultMessage: '[{artist}] has some questions for you.\nUnlock below.',
    },
    marketplaceitem: defaultMessage,
    past_live_stream: defaultMessage,
    live_stream: defaultMessage,
    unlock: {
      id: 'lockeditem.stanPlus.unlock',
      defaultMessage: 'Unlock',
    },
  },
};

export default messages;
