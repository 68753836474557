export default {
  youAreStan: {
    id: 'payment.subscriptionActivated.youAreStan',
    defaultMessage: 'You’re a Stan!',
  },
  description: {
    id: 'payment.subscriptionActivated.description',
    defaultMessage: 'The subscription has been activated.',
  },
  download: {
    id: 'payment.subscriptionActivated.download',
    defaultMessage: 'Download the app to get full Stan experience',
  },
};
