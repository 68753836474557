import { AVPlaybackStatus } from 'expo-av';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
let videojs: any;
let VideoJsPlayer: any;
let VideoWeb: any;

import PreviewImage from './styled/PreviewImage';
import VideoNative from './styled/VideoNative';
import View from './styled/View';

if (Platform.OS === 'web') {
  try {
    require('video.js/dist/video-js.css');
  } catch (err) {
    console.log(err);
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  videojs = require('video.js').default;
  VideoJsPlayer = videojs.VideoJsPlayer;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VideoWeb = require('./styled/VideoWeb').default;
}

interface LiveStreamVideoPlayerProps {
  url: string;
  isLive: boolean;
  onLoaded?: () => void;
  onEnded?: () => void;
  cover?: string;
  isMuted?: boolean;
}

const LiveStreamVideoPlayer: FunctionComponent<LiveStreamVideoPlayerProps> = ({
  url,
  isLive,
  cover,
  onLoaded,
  onEnded,
  isMuted,
}: LiveStreamVideoPlayerProps) => {
  const playerRef = useRef<typeof VideoJsPlayer>();
  const videoRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = useState({
    position: 0,
    duration: 0,
  });

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const videoElement = videoRef.current;
    if (!videoElement) return;

    playerRef.current = videojs(
      videoElement,
      {
        sources: [{ src: url, type: isLive ? 'application/x-mpegURL' : 'video/mp4' }],
        autoplay: true,
        responsive: true,
        muted: false,
        controls: true,
      },
      () => {
        setIsLoaded(true);
        onLoaded?.();
      },
    );

    return () => {
      if (playerRef.current) {
        setIsLoaded(false);
        playerRef.current.dispose();
        playerRef.current.off('timeupdate');
        playerRef.current = null;
      }
    };
  }, []);

  const onPlaybackStatusUpdate = useCallback(
    (status: AVPlaybackStatus) => {
      if (status.isLoaded) {
        setPlaybackInstanceInfo({
          ...playbackInstanceInfo,
          position: status.positionMillis,
          duration: status.durationMillis || 0,
        });
        setIsLoaded(true);
        onLoaded?.();
      }
    },
    [onLoaded],
  );

  const onPlaybackLiveStatusUpdate = useCallback(
    (status: AVPlaybackStatus) => {
      if (status.isLoaded) {
        setIsLoaded(true);
        onLoaded?.();
        if (status.didJustFinish) {
          setHasEnded(true);
          onEnded?.();
        }
        if (status.isPlaying && hasEnded) {
          setHasEnded(false);
        }
      }
    },
    [onLoaded, isLoaded, hasEnded],
  );

  if (Platform.OS !== 'web') {
    return (
      <View>
        <VideoNative
          source={isLive ? { uri: url, overrideFileExtensionAndroid: 'm3u8' } : { uri: url }}
          rate={1.0}
          volume={1.0}
          isMuted={isMuted}
          resizeMode="cover"
          shouldPlay={!hasEnded}
          onPlaybackStatusUpdate={isLive ? onPlaybackLiveStatusUpdate : onPlaybackStatusUpdate}
          isLooping={!isLive}
        />
        {(!isLoaded || hasEnded) && <PreviewImage source={{ uri: cover }} />}
      </View>
    );
  }

  return (
    <View>
      {(!isLoaded || hasEnded) && (
        <img src={cover} style={{ height: '100%', position: 'absolute' }} alt="cover" />
      )}
      <VideoWeb id="video" ref={videoRef} className="video-js vjs-big-play-centered" />
    </View>
  );
};

export default LiveStreamVideoPlayer;
