export default {
  becomeStan: {
    id: 'payment.prompt.becomeStan',
    defaultMessage: 'Become a Stan',
  },
  priceFixed: {
    id: 'payment.prompt.price.fixed',
    defaultMessage: '{currency}{price}',
  },
  description: {
    id: 'payment.prompt.description',
    defaultMessage: 'Subscribe to {artistFullName} and get access to exclusive Stan only content!',
  },
  priceMonthly: {
    id: 'payment.prompt.price.monthly',
    defaultMessage: '{currency}{price}/month',
  },
  disclaimer: {
    id: 'payment.prompt.disclaimer',
    defaultMessage:
      'By purchasing you agree to our general <termsCta>Terms of Use</termsCta> and <privacyPolicyCta>Privacy Policy</privacyPolicyCta>.',
  },
  payButtonLabelGeneric: {
    id: 'payment.prompt.payButtonLabelGeneric',
    defaultMessage: 'Pay',
  },
  payButtonLabel: {
    id: 'payment.prompt.payButtonLabel',
    defaultMessage: 'Pay with {paymentMethod}',
  },
  paymentMethodsListTitle: {
    id: 'payment.prompt.paymentMethodsListTitle',
    defaultMessage: 'Payment method',
  },
  addMethod: {
    id: 'payment.prompt.addMethod',
    defaultMessage: '+ Add another card',
  },
  methodName: {
    id: 'payment.prompt.methodName',
    defaultMessage: '•••• {lastFourDigits}',
  },
  card: {
    id: 'payment.prompt.card',
    defaultMessage: 'Card •••• {lastFourDigits}',
  },
  result: {
    error: {
      id: 'payment.prompt.result.error',
      defaultMessage: 'An error has occurred. Please try again.',
    },
    success: {
      id: 'payment.prompt.result.success',
      defaultMessage: 'The subscription has been activated.',
    },
  },
  badgeBack: {
    id: 'subscription.badgeBack',
    defaultMessage: 'Give me my Stan badge back!',
  },
  subscriptionDisabled: {
    id: 'subscription.disabled',
    defaultMessage:
      "Sorry but it's not possible to become a Stan within the app. Please use our web app instead.",
  },
  whyBecomeStanTitle: {
    id: 'subscription.whyBecomeStanTitle',
    defaultMessage: 'Why become a Stan?',
  },
  whyBecomeStanDescription: {
    id: 'subscription.whyBecomeStanDescription',
    defaultMessage:
      'By being a Stan you receive access to exclusive content published by the Artist.',
  },
  beingAStan: {
    id: 'subscription.beingAStan',
    defaultMessage: 'Being a Stan of {displayName} is only {price}/month.',
  },
};
