import React from 'react';
import styled from 'styled-components/native';

import { useTheme } from 'themes';

import IconAmericanExpress from './styled/IconAmericanExpress';
import IconGeneric from './styled/IconGeneric';
import IconMastercard from './styled/IconMastercard';
import IconVisa from './styled/IconVisa';

const CARD_ICONS: Record<string, React.FC> = {
  visa: IconVisa,
  mastercard: IconMastercard,
  'american-express': IconAmericanExpress,
  generic: IconGeneric,
};

const IconView = styled.View`
  padding: 0 5px 0 0;
`;

interface CreditCardIconProps {
  cardType?: string;
  inverse?: boolean;
}

const CreditCardIcon: React.FC<CreditCardIconProps> = ({
  cardType,
  inverse,
  ...restProps
}: CreditCardIconProps) => {
  const CardIcon = CARD_ICONS[cardType || 'generic'] || IconGeneric;
  const { theme } = useTheme();

  const IconComponent = CardIcon as any;

  return (
    <IconView {...restProps}>
      <IconComponent color={inverse ? theme.colors.background : theme.colors.text} />
    </IconView>
  );
};

export default CreditCardIcon;
