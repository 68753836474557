import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Image, View } from 'react-native';

import { SCREEN_NAME as ARTIST_PROFILE_STACK_NAME } from 'stacks/artist/ArtistProfileStack';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_EDIT_POST_SETTINGS_SCREEN_NAME } from 'screens/ArtistEditPostSettingsScreen';
import { SCREEN_NAME as ARTIST_MY_PROFILE_SCREEN_NAME } from 'screens/ArtistMyProfileScreen';

import ScreenView from 'containers/ModalScreen';

import NavigationHeader from 'components/NavigationHeader';
import OptionModal, { ModalButton } from 'components/OptionModal';
import TextInput from 'components/TextInput';

import { usePostsRefreshSet, usePostsRefreshState, useSnackbarSet } from 'context';
import {
  useArtistAudioUpload,
  useArtistImageUpload,
  useArtistPollUpload,
  useArtistVideoUpload,
  useAudio,
} from 'hooks';
import { ProductTypePlural } from 'types';
import { pickPreviewImage } from 'utils/product';
import { milliSecondsToTimeString } from 'utils/time';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ContentView from './styled/ContentView';
import DeleteButton from './styled/DeleteButton';
import Duration from './styled/Duration';
import FeedItem from './styled/FeedItem';
import PlayButton from './styled/PlayButton';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistPostScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const { product, updateSingleItem } = route.params;
  const intl = useIntl();
  const [caption, setCaption] = useState<string>(product.description);
  const isFocused = useIsFocused();
  const activateRef = useRef<(() => void) | null>();
  const deactivateRef = useRef<(() => void) | null>();
  const artistImageUpload = useArtistImageUpload();
  const artistVideoUpload = useArtistVideoUpload();
  const artistAudioUpload = useArtistAudioUpload();
  const artistPollUpload = useArtistPollUpload();
  const postsRefreshSet = usePostsRefreshSet();
  const postsRefreshState = usePostsRefreshState();
  const { setSnackbar } = useSnackbarSet();
  const [imageHeight, setImageHeight] = useState<number>();
  const [isPlaying, setIsPlaying] = useState<boolean>();
  const { durationMillis, isLoaded, playAudio, stopAudio, setOnAudioStatusUpdate } = useAudio(
    product.file_proxy,
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      deactivateRef.current?.();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isLoaded) {
      setOnAudioStatusUpdate((status) => {
        setIsPlaying((status as any).isPlaying);
      });
    }
  }, [isLoaded]);

  const refActivate = (activateFn: (() => void) | null) => {
    activateRef.current = activateFn;
  };

  const refDeactivate = (deactivateFn: (() => void) | null) => {
    deactivateRef.current = deactivateFn;
  };

  const onDelete = async () => {
    try {
      setDeleteModalOpen(false);
      let deleted = false;
      let modelNamePlural: ProductTypePlural | null = null;
      if (product.model_name === 'image') {
        deleted = await artistImageUpload.remove(product.id);
        modelNamePlural = 'images';
      } else if (product.model_name === 'video') {
        deleted = await artistVideoUpload.remove(product.id);
        modelNamePlural = 'videos';
      } else if (product.model_name === 'audio') {
        deleted = await artistAudioUpload.remove(product.id);
        modelNamePlural = 'audios';
      } else if (product.model_name === 'poll') {
        deleted = await artistPollUpload.remove(product.id);
        modelNamePlural = 'polls';
      }
      if (deleted) {
        if (modelNamePlural) {
          const prs = {
            ...postsRefreshState,
            shouldRefresh: [...postsRefreshState.shouldRefresh, modelNamePlural],
          };
          postsRefreshSet(prs);
        }
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.deleted),
          actionLabel: intl.formatMessage(messages.closeSnackbar),
        });
        navigation.navigate(ARTIST_PROFILE_STACK_NAME, {
          screen: ARTIST_MY_PROFILE_SCREEN_NAME,
        });
      }
    } catch (e) {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.error.other),
        dismissAfter: 5000,
      });
    }
  };

  return (
    <>
      <ScreenView
        header={
          <NavigationHeader
            insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
            navigation={navigation}
            title={intl.formatMessage(messages.title)}
            headerBackTitle={intl.formatMessage(messages.cancel)}
            headerBackImage={() => <View />}
            headerRightLabel={intl.formatMessage(messages.next)}
            headerRightDisabled={
              artistImageUpload.isUploading ||
              artistVideoUpload.isUploading ||
              artistAudioUpload.isUploading ||
              artistPollUpload.isUploading
            }
            headerRightOnPress={() =>
              navigation.navigate(ARTIST_EDIT_POST_SETTINGS_SCREEN_NAME, {
                product,
                caption,
                updateSingleItem,
              })
            }
          />
        }
      >
        {product.model_name === 'audio' && (
          <View style={{ flexDirection: 'row', marginVertical: 20 }}>
            <View
              style={{
                flex: 1,
                marginRight: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <PickerButton onMediaChange={onImageChange} label="Upload photo" /> */}
              <Image
                onLayout={(event) => setImageHeight(event.nativeEvent.layout.width)}
                source={{
                  uri: pickPreviewImage(product).url,
                }}
                style={{ width: '100%', height: imageHeight, borderRadius: 20 }}
                blurRadius={10}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 10 }}>
              <PlayButton
                isPlaying={isPlaying}
                onPress={async () => (isPlaying ? stopAudio() : playAudio(0))}
              />
              <Duration>
                Duration{' '}
                {milliSecondsToTimeString(durationMillis !== Infinity ? durationMillis : 0)}
              </Duration>
            </View>
          </View>
        )}
        {['image', 'video', 'poll'].includes(product.model_name) && (
          <FeedItem
            product={product}
            showAvatar={false}
            videoOptions={{
              autoPlay: true,
              startMuted: false,
              shouldLoop: false,
              coverImageUrl: pickPreviewImage(product).url,
              autoHideControls: false,
              refActivate: (index: number, activateFn: (() => void) | null) =>
                refActivate(activateFn),
              refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
                refDeactivate(deactivateFn),
            }}
            showCaption={false}
            showControls={false}
            showCommentsBottomCounter={false}
            pollOptions={{ answerable: false, showResults: true }}
          />
        )}
        <ContentView>
          <TextInput
            returnKeyType="done"
            label={intl.formatMessage(messages.caption)}
            value={caption || ''}
            onChangeText={(v: string) => setCaption(v)}
          />
          <DeleteButton
            onPress={() => setDeleteModalOpen(true)}
            processing={
              artistImageUpload.isUploading ||
              artistVideoUpload.isUploading ||
              artistAudioUpload.isUploading ||
              artistPollUpload.isUploading
            }
          >
            {intl.formatMessage(messages.delete)}
          </DeleteButton>
        </ContentView>
      </ScreenView>
      <OptionModal
        isVisible={isDeleteModalOpen}
        title={intl.formatMessage(messages.deleteModal.title)}
      >
        <ModalButton warning onPress={onDelete}>
          {intl.formatMessage(messages.deleteModal.delete)}
        </ModalButton>
        <ModalButton bold onPress={() => setDeleteModalOpen(false)}>
          {intl.formatMessage(messages.deleteModal.cancel)}
        </ModalButton>
      </OptionModal>
    </>
  );
};

export default ArtistPostScreen;
