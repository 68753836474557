import styled from 'styled-components/native';

export default styled.Text.attrs(() => ({
  numberOfLines: 1,
}))<{ large?: boolean }>`
  color: ${({ theme }) => theme.orderSectionTitle.primaryText};
  font-family: BasierCircle-Medium;
  font-size: ${({ large }) => (large ? '22px' : '17px')};
  font-weight: 600;
  line-height: 22px;
`;
