import styled from 'styled-components/native';

export default styled.View`
  width: 21px;
  height: 21px;
  margin-left: 2px;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;
