import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import styled from 'styled-components/native';

export default styled(Animated.View)`
  flex-direction: row;
  flex: 1;
  height: 100%;
  padding: 0 15px;
  background-color: ${({ theme }) => (Platform.OS !== 'web' ? theme.colors.background : '#131722')};
`;
