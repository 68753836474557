import React from 'react';
import styled from 'styled-components/native';

import { LeftArrowSvg, RightArrowSvg, SafariSvg, ShareSvg } from './Icons';
import NavigationButton from './NavigationButton';

const View = styled.SafeAreaView`
  width: 100%;
  background-color: ${({ theme }) => theme.webview.background};
`;

const PaddingView = styled.View`
  position: relative;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface BottomNavProps {
  isLeftArrowActive: boolean;
  isRightArrowActive: boolean;
  backToPreviousPage: () => void;
  goToNextPage: () => void;
  shareWebsite?: () => void;
  openInBrowser?: () => void;
}

const BottomNav: React.FC<BottomNavProps> = ({
  isLeftArrowActive,
  isRightArrowActive,
  backToPreviousPage,
  goToNextPage,
  shareWebsite,
  openInBrowser,
}: BottomNavProps) => {
  return (
    <View>
      <PaddingView>
        <NavigationButton
          icon={<LeftArrowSvg isActive={isLeftArrowActive} />}
          onPress={backToPreviousPage}
        />
        <NavigationButton
          icon={<RightArrowSvg isActivve={isRightArrowActive} />}
          onPress={goToNextPage}
        />
        <NavigationButton icon={<ShareSvg />} onPress={shareWebsite} />
        <NavigationButton icon={<SafariSvg />} onPress={openInBrowser} />
      </PaddingView>
    </View>
  );
};

export default BottomNav;
