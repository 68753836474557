import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import ItemOverlay from 'components/ItemOverlay';

import { useMemoizedImage } from 'hooks/useMemoizedImage';
import { useTheme } from 'themes';
import { Cover, PaymentModel, ProductType } from 'types';

import pollBg from './images/poll_bg.jpg';
import messages from './messages';
import Container from './styled/Container';
import ContainerTouchable from './styled/ContainerTouchable';
import Content from './styled/Content';
import Description from './styled/Description';
import Gradient from './styled/Gradient';
import IconBlocked from './styled/IconBlocked';
import IconLocked from './styled/IconLocked';
import IconLockedBackground from './styled/IconLockedBackground';
import Image from './styled/Image';
import ImageBackground from './styled/ImageBackground';
import UnlockAction from './styled/UnlockAction';
import UnlockButton from './styled/UnlockButton';

export interface LockedItemProps {
  type?: ProductType;
  cover?: Cover;
  isPreview?: boolean;
  unlockText?: string;
  /** Is single post mode */
  isExpanded?: boolean;
  showUnlockText?: boolean;
  showOverlay?: boolean;
  smallItem?: boolean;
  /** Whether the imgWidth > imgHeight */
  isLandscape?: boolean;
  artistDisplayName?: string;
  onPress?: () => void;
  onUnlockPress?: () => void;
  overlayButtonMarginTop?: number;
  productPaymentModel?: PaymentModel;
}

/**
 * A "Locked" overlay to be shown when the user doesn't have access to the item.
 * Used for all product types, including audio and video (in which case this item is being shown
 * after the free portion of the content has finished playing).
 */
const LockedItem: FC<LockedItemProps> = ({
  type = 'image',
  cover,
  isPreview = false,
  isExpanded,
  showUnlockText = true,
  productPaymentModel,
  showOverlay,
  smallItem,
  artistDisplayName = 'artist',
  isLandscape,
  onUnlockPress,
  overlayButtonMarginTop,
  onPress,
  ...rest
}: LockedItemProps): JSX.Element => {
  const { theme } = useTheme();
  const isStanPlus = productPaymentModel === 'requires_payment';
  const backgroundSource = useMemoizedImage({ uri: cover?.url });
  const shouldDisplayCover = useMemo(() => type !== 'audio' && type !== 'poll', [type]);
  const intl = useIntl();

  const ContainerComponent = (
    onPress ? ContainerTouchable : Container
  ) as typeof ContainerTouchable;

  const unlockCopy = intl.formatMessage(messages[isStanPlus ? 'stanPlus' : 'subscription'][type], {
    artist: artistDisplayName,
  });
  const unlockButtonCopy = intl.formatMessage(
    messages[isStanPlus ? 'stanPlus' : 'subscription'].unlock,
  );

  return (
    <ContainerComponent onPress={onPress} {...rest}>
      {cover?.url && shouldDisplayCover && (
        <ImageBackground source={backgroundSource} blurRadius={cover.isBlurred ? 0 : 20}>
          {showOverlay && <ItemOverlay />}
        </ImageBackground>
      )}
      {type === 'poll' && <Image source={pollBg} />}
      {!isPreview ? (
        <>
          <Gradient type={type} />
          {!smallItem && (
            <IconLockedBackground
              overlayButtonMarginTop={overlayButtonMarginTop}
              isExpanded={isExpanded}
            >
              <Content>
                <IconLocked isLandscape={isLandscape} />
                {showUnlockText && <Description>{unlockCopy}</Description>}
                <UnlockButton
                  unlockText={unlockButtonCopy}
                  onPress={onUnlockPress}
                  isLandscape={isLandscape}
                  testID="UnlockButton"
                />
              </Content>
            </IconLockedBackground>
          )}
        </>
      ) : (
        <>
          {isExpanded && showUnlockText && <Description>{unlockCopy}</Description>}
          {type === ('poll' || 'audio') && isPreview && <IconBlocked />}
          {(isExpanded || isPreview) && (
            <View pointerEvents={isPreview ? 'none' : 'auto'}>
              <UnlockAction
                labelColor={theme.lockedItem.actionText}
                onPress={onUnlockPress}
                testID="UnlockButton"
              >
                {unlockButtonCopy}
              </UnlockAction>
            </View>
          )}
        </>
      )}
    </ContainerComponent>
  );
};

export default LockedItem;
