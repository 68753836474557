import React, { useEffect, useState } from 'react';

import Fade from 'components/Transitions/Fade/Fade';

import BufferingIndicator from './styled/BufferingIndicator';
import CenterView from './styled/CenterView';
import ContentTouchable from './styled/ContentTouchable';
import FullscreenButton from './styled/FullscreenButton';
import MuteButton from './styled/MuteButton';
import PlayPauseButton from './styled/PlayPauseButton';
import VideoProgressBar from './styled/VideoProgressBar';
import VideoTimestamp from './styled/VideoTimestamp';
import View from './styled/View';

export interface VideoControlsProps {
  isPlaying?: boolean;
  isMuted?: boolean;
  isLooping?: boolean;
  showPlayPauseButton?: boolean;
  showMuteButton?: boolean;
  showFullscreenButton?: boolean;
  showVideoTimestamp?: boolean;
  showProgressBar?: boolean;
  showLoader?: boolean;
  durationMillis?: number;
  positionMillis?: number;
  setPositionMillis?: (positionMillis: number) => void;
  autoHide?: boolean;
  autoHideDelay?: number;
  isHiddenAtStart?: boolean;
  showControlsBottom?: boolean;
  isFullscreen?: boolean;
  onFullscreenPress?: () => void;
  onPlayPausePress?: () => void;
  onMutePress?: () => void;
}

const VideoControls: React.FC<VideoControlsProps> = ({
  isPlaying = false,
  isMuted,
  isLooping,
  showPlayPauseButton = true,
  showMuteButton = true,
  showFullscreenButton,
  showVideoTimestamp = false,
  showLoader = false,
  showProgressBar = true,
  durationMillis,
  positionMillis,
  setPositionMillis,
  autoHide = true,
  autoHideDelay = 4000,
  isHiddenAtStart = true,
  showControlsBottom,
  isFullscreen,
  onFullscreenPress,
  onPlayPausePress,
  onMutePress,
  ...restProps
}: VideoControlsProps) => {
  const [isVisible, setIsVisible] = useState(!isHiddenAtStart);

  const wakeUp = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    let timeoutId = NaN;
    if (isVisible && autoHide) {
      timeoutId = setTimeout(() => setIsVisible(false), autoHideDelay);
    }
    if (!isLooping && !autoHide) {
      setIsVisible(true);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible, autoHide, isLooping, autoHideDelay]);

  return (
    <View {...restProps}>
      <ContentTouchable onPress={!isVisible ? wakeUp : undefined}>
        {isVisible && (
          <Fade fill>
            <CenterView>
              {showPlayPauseButton && (
                <PlayPauseButton
                  isPlaying={isPlaying}
                  onPress={onPlayPausePress}
                  testID="PlayPauseButton"
                />
              )}
              {showLoader && <BufferingIndicator animating />}
            </CenterView>
            {showFullscreenButton && (
              <FullscreenButton
                showControlsBottom={showControlsBottom}
                isFullscreen={isFullscreen}
                onPress={onFullscreenPress}
                testID="FullscreenButton"
              />
            )}
            {showMuteButton && (
              <MuteButton
                showControlsBottom={showControlsBottom}
                isMuted={isMuted}
                onPress={onMutePress}
                testID="MuteButton"
                isFullscreen={isFullscreen}
              />
            )}
            {showVideoTimestamp && !!durationMillis && (
              <VideoTimestamp
                positionMillis={positionMillis}
                durationMillis={durationMillis}
                testID="VideoTimestamp"
                isFullscreen={isFullscreen}
              />
            )}
            {showProgressBar && (
              <VideoProgressBar
                positionMillis={positionMillis}
                durationMillis={durationMillis}
                setPositionMillis={setPositionMillis}
                testID="VideoProgressBar"
                isFullscreen={isFullscreen}
              />
            )}
          </Fade>
        )}
      </ContentTouchable>
    </View>
  );
};

export default VideoControls;
