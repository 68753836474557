import styled, { css } from 'styled-components/native';

interface Props {
  bold?: boolean;
  warning?: boolean;
}

export default styled.Text<Props>`
  font-size: 17px;
  line-height: 22px;
  font-family: 'BasierCircle-Regular';
  color: ${({ theme, warning }) => (warning ? theme.colors.errorAccent : theme.colors.text)};
  ${({ bold }) =>
    bold &&
    css`
      font-family: 'BasierCircle-Semibold';
      font-weight: bold;
    `}
`;
