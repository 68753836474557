export default {
  title: {
    id: 'TippingSubscribeDialog.title',
    defaultMessage: 'Confirm completing',
  },
  description: {
    id: 'TippingSubscribeDialog.description',
    defaultMessage: 'Only Stans can tip Artists. Subscribe now to unlock this feature',
  },
  subscribe: {
    id: 'TippingSubscribeDialog.subscribe',
    defaultMessage: 'Become a Stan',
  },
  dismiss: {
    id: 'TippingSubscribeDialog.dismiss',
    defaultMessage: 'Close',
  },
};
