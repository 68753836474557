import styled, { css } from 'styled-components/native';

interface Props {
  solid?: boolean;
  isTop?: boolean;
  isBottom?: boolean;
}

export default styled.TouchableOpacity<Props>`
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;

  margin: 4px 0;

  ${({ isTop, isBottom }) =>
    isTop
      ? css`
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
          margin: 0;
        `
      : isBottom
      ? css`
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          margin: 0 0 4px;
        `
      : css`
          border-radius: 14px;
        `}
`;
