import styled from 'styled-components/native';

export default styled.Text`
  font-size: 13px;
  font-family: 'BasierCircle-Regular';
  letter-spacing: -0.08px;
  margin-top: 17px;
  padding-right: 20px;
  color: ${({ theme }) => theme.artistDashboard.brighterText};
`;
