import styled from 'styled-components/native';

import { DialogButton } from 'components/Button';

interface Props {
  isDelete: boolean;
}

export default styled(DialogButton).attrs<Props>(({ isDelete }) => ({
  primary: true,
  warning: isDelete,
}))<Props>`
  width: 100%;
  margin-top: 20px;
`;
