import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import Subtitle from '../../components/Subtitle';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Image from './styled/Image';
import Link from './styled/Link';
import ScreenView from './styled/ScreenView';
import Title from './styled/Title';

export { SCREEN_NAME };

type ArtistJoinRequestSentScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ArtistJoinRequestSentScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ArtistJoinRequestSentScreenRouteProp;
  navigation: ArtistJoinRequestSentScreenNavigationProp;
};

const ArtistJoinRequestSentScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();

  return (
    <ScreenView>
      <Image />
      <Title>{intl.formatMessage(messages.thanks)}</Title>
      <Subtitle>{intl.formatMessage(messages.inTouch)}</Subtitle>
      <Link onPress={() => navigation.navigate(WELCOME_SCREEN_NAME, { isHoldingPage: false })}>
        {intl.formatMessage(messages.back)}
      </Link>
    </ScreenView>
  );
};

ArtistJoinRequestSentScreen.displayName = 'ArtistJoinRequestSentScreen';

export default ArtistJoinRequestSentScreen;
