import { MaterialIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

export default styled(MaterialIcons).attrs(() => ({
  name: 'fullscreen',
  size: 24,
  color: '#fff',
}))`
  text-align: center;
`;
