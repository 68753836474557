export default {
  headingCardWeb: {
    id: 'settings.paymentMethods.edit.headingCardWeb',
    defaultMessage: 'Add another card',
  },
  headingCard: {
    id: 'settings.paymentMethods.edit.headingCard',
    defaultMessage: 'Credit card',
  },
  nameInput: {
    label: {
      id: 'settings.paymentMethods.edit.fullName.label',
      defaultMessage: 'Full name on card',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.fullName.placeholder',
      defaultMessage: 'Name Surname',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.fullName.error.required',
        defaultMessage: 'Please enter your full name',
      },
      invalid: {
        id: 'settings.paymentMethods.edit.fullName.error.invalid',
        defaultMessage: 'Entered name does not seem to be valid',
      },
    },
  },
  cardNumberInput: {
    label: {
      id: 'settings.paymentMethods.edit.cardNumber.label',
      defaultMessage: 'Card number',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.cardNumber.placeholder',
      defaultMessage: '1234 2137 4567 0204',
    },
  },
  cardExpiryInput: {
    label: {
      id: 'settings.paymentMethods.edit.cardExpiryInput.label',
      defaultMessage: 'MM/YY Expiry',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.cardExpiryInput.placeholder',
      defaultMessage: 'MM/YY Expiry',
    },
  },
  cardCvvInput: {
    placeholder: {
      id: 'settings.paymentMethods.edit.cardCvvInput.placeholder',
      defaultMessage: '345',
    },
  },
  headingBillingAddress: {
    id: 'settings.paymentMethods.edit.headingBillingAddress',
    defaultMessage: 'Billing address',
  },
  billingAddressInput: {
    label: {
      id: 'settings.paymentMethods.edit.billingAddressInput.label',
      defaultMessage: 'Address',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.billingAddressInput.placeholder',
      defaultMessage: '123 Street, flat B',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.billingAddressInput.error.required',
        defaultMessage: 'Billing address is required',
      },
    },
  },
  cityInput: {
    label: {
      id: 'settings.paymentMethods.edit.cityInput.label',
      defaultMessage: 'City',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.cityInput.placeholder',
      defaultMessage: 'London',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.cityInput.error.required',
        defaultMessage: 'City is required',
      },
    },
  },
  stateInput: {
    label: {
      id: 'settings.paymentMethods.edit.stateInput.label',
      defaultMessage: 'State',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.stateInput.placeholder',
      defaultMessage: 'Greater London',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.stateInput.error.required',
        defaultMessage: 'State is required',
      },
    },
  },
  postalCodeInput: {
    label: {
      id: 'settings.paymentMethods.edit.postalCodeInput.label',
      defaultMessage: 'Postal code',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.postalCodeInput.placeholder',
      defaultMessage: 'N16NU',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.postalCodeInput.error.required',
        defaultMessage: 'Postal code is required',
      },
    },
  },
  phoneInput: {
    label: {
      id: 'settings.paymentMethods.edit.phoneInput.label',
      defaultMessage: 'Phone number',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.phoneInput.placeholder',
      defaultMessage: '2071231234',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.phoneInput.error.required',
        defaultMessage: 'Phone number is required',
      },
    },
  },
  countryInput: {
    label: {
      id: 'settings.paymentMethods.edit.countryInput.label',
      defaultMessage: 'Country: {countryName}',
    },
    placeholder: {
      id: 'settings.paymentMethods.edit.countryInput.placeholder',
      defaultMessage: 'Country',
    },
    selectorTitle: {
      id: 'settings.paymentMethods.edit.countryInput.selectorTitle',
      defaultMessage: 'Select your country',
    },
    error: {
      required: {
        id: 'settings.paymentMethods.edit.countryInput.error.required',
        defaultMessage: 'Country is required',
      },
    },
  },
  snackbar: {
    success: {
      add: {
        id: 'settings.snackbar.success.add',
        defaultMessage: 'Card has been added.',
      },
      remove: {
        id: 'settings.snackbar.success.add',
        defaultMessage: 'Card has been removed.',
      },
    },
    error: {
      add: {
        id: 'settings.snackbar.error.add',
        defaultMessage: 'Error adding card.',
      },
      addRetry: {
        id: 'settings.snackbar.error.add',
        defaultMessage: 'Please try again later or use another card.',
      },
      addAnother: {
        id: 'settings.snackbar.error.add',
        defaultMessage: 'Please use another card.',
      },
      remove: {
        id: 'settings.snackbar.error.remove',
        defaultMessage: 'Error removing card.',
      },
    },
    close: {
      id: 'settings.snackbar.close',
      defaultMessage: 'Close',
    },
  },
  removeModal: {
    title: {
      id: 'settings.paymentMethods.removeModal.title',
      defaultMessage: 'Remove card',
    },
    content: {
      id: 'settings.paymentMethods.removeModal.content',
      defaultMessage: 'Are you sure you want to remove \n Card',
    },
    cancel: {
      id: 'settings.paymentMethods.removeModal.cancel',
      defaultMessage: 'Cancel',
    },
  },
  updateButtonLabel: {
    id: 'settings.paymentMethods.edit.updateButtonLabel',
    defaultMessage: 'Remove card',
  },
  saveButtonLabel: {
    id: 'settings.paymentMethods.edit.saveButtonLabel',
    defaultMessage: 'Save',
  },
  error: {
    cardExists: {
      id: 'settings.paymentMethods.add.error.cardExists',
      defaultMessage: 'Card already exists.',
    },
  },
};
