import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import cardValidator from 'card-validator';
import React from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import {
  SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREEN_NAME,
  SCREEN_NAME_EDIT as EDIT_PAYMENT_METHOD_SCREEN_NAME,
} from 'screens/EditPaymentMethodScreen/constants';

import { usePayments } from 'context';
import { PaymentMethod } from 'types';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import AddButton from './styled/AddButton';
import CreditCardIcon from './styled/CreditCardIcon';
import OptionsGroup from './styled/OptionsGroup';
import PaymentMethodsList from './styled/PaymentMethodsList';
import View from './styled/View';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const PaymentMethodsScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const { isLoadingPaymentMethods, paymentMethods } = usePayments();

  const onAddPress = () => {
    navigation.navigate(ADD_PAYMENT_METHOD_SCREEN_NAME);
  };

  const onMethodSelect = (method: PaymentMethod) => {
    navigation.navigate(EDIT_PAYMENT_METHOD_SCREEN_NAME, { method });
  };

  return (
    <View>
      <PaymentMethodsList>
        {isLoadingPaymentMethods && <ActivityIndicator animating />}
        {!isLoadingPaymentMethods && paymentMethods && (
          <OptionsGroup
            options={paymentMethods.map((method) => ({
              label: intl.formatMessage(messages.cardItem, {
                lastDigits: method.creditCardInfo.last_four_digits,
              }),
              labelPrefixComponent: (
                <CreditCardIcon
                  cardType={cardValidator.number(method.creditCardInfo.first_six_digits).card?.type}
                />
              ),
              value: method,
              onAction: onMethodSelect,
            }))}
          />
        )}
      </PaymentMethodsList>
      <AddButton onPress={onAddPress}>
        {intl.formatMessage(paymentMethods.length > 0 ? messages.addAnotherCard : messages.addCard)}
      </AddButton>
    </View>
  );
};

export default PaymentMethodsScreen;
