import { Audio, AVPlaybackStatus } from 'expo-av';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Animated, Easing, Platform, View } from 'react-native';

import { AudioBufferSlice, cutAudioMobile } from 'utils/audio';
import { getFileDirectory, getFileExt } from 'utils/path';

import Button from './Button';
import OptionButton from './OptionButton';
import OptionButtonText from './OptionButtonText';
import Pause from './Pause';
import Play from './Play';
import SeekBackward from './SeekBackward';
import SeekForward from './SeekForward';
import Time from './Time';

interface Props {
  sound: Audio.Sound;
  file: any;
  isLoaded: boolean;
  durationMillis: number;
  waveformWidth: number;
  playbackOffsetAnimated: Animated.Value;
  trimFromMillis: number;
  trimToMillis: number;
  currentPositionXRef: any;
  setTrimmedAudioPath: any;
  setTrimmedAudioFile: any;
  trimmedAudioPath?: string;
  trimmedAudioFile?: Blob;
  soundUpdateInterval: number;
  setOnAudioStatusUpdate: (
    onAudioStatusUpdate: ((status: AVPlaybackStatus) => void) | null,
  ) => void;
  pauseAudio: () => void;
  stopAudio: () => void;
  playAudio: (millis: number) => void;
  onTrimming: () => void;
  onDelete: () => void;
  onPlaybackStatusUpdateStateUpdater: number;
  playerControlsUpdater: number;
  onRewindBackward: () => void;
  onRewindForward: () => void;
}

const PlayerControls: React.FC<Props> = ({
  setOnAudioStatusUpdate,
  pauseAudio,
  stopAudio,
  playAudio,
  file,
  isLoaded,
  durationMillis,
  waveformWidth,
  playbackOffsetAnimated,
  trimFromMillis,
  trimToMillis,
  currentPositionXRef,
  setTrimmedAudioPath,
  setTrimmedAudioFile,
  trimmedAudioPath,
  trimmedAudioFile,
  soundUpdateInterval,
  onTrimming,
  onDelete,
  onPlaybackStatusUpdateStateUpdater,
  playerControlsUpdater,
  onRewindBackward,
  onRewindForward,
}: Props) => {
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTrimming, setIsTrimming] = useState(false);
  const [cutToMillis, setCutToMillis] = useState<number>(0);
  const intl = useIntl();

  useEffect(() => {
    setCutToMillis(0);
  }, [trimToMillis]);

  const onPlaybackStatusUpdate = useCallback(
    async (status: AVPlaybackStatus) => {
      const positionMillis = (status as any).positionMillis || trimFromMillis;
      if (isPlaying && !(status as any).isPlaying && positionMillis > trimToMillis) {
        setCutToMillis(positionMillis);
      }
      const prgrs = positionMillis / durationMillis || 0;
      setIsPlaying((status as any).isPlaying);
      if (!Number.isNaN(durationMillis) && durationMillis > 1) {
        if (trimToMillis === 0 || trimToMillis > durationMillis) {
          trimToMillis = durationMillis;
        }
      }
      setProgress(prgrs);
      const margin = waveformWidth * prgrs * -1;
      currentPositionXRef.current = margin;
      Animated.timing(playbackOffsetAnimated, {
        toValue: margin,
        duration: soundUpdateInterval,
        useNativeDriver: false,
        easing: Easing.linear,
      }).start(async ({ finished }) => {
        if (positionMillis > trimToMillis - soundUpdateInterval) {
          if (trimToMillis < durationMillis || (trimToMillis === durationMillis && finished)) {
            stopAudio();
          }
        }
      });
    },
    [
      isPlaying,
      trimToMillis,
      trimFromMillis,
      durationMillis,
      stopAudio,
      onPlaybackStatusUpdateStateUpdater,
    ],
  );

  useEffect(() => {
    setOnAudioStatusUpdate(onPlaybackStatusUpdate);
  }, [onPlaybackStatusUpdate]);

  const onPlayPausePress = useCallback(async () => {
    try {
      if (isPlaying) {
        await pauseAudio();
      } else {
        let positionMillis = (-currentPositionXRef.current / waveformWidth) * durationMillis;
        if (positionMillis < trimFromMillis) {
          positionMillis = trimFromMillis;
        }
        if (positionMillis === trimToMillis) {
          positionMillis = trimFromMillis;
        }
        await playAudio(positionMillis);
      }
    } catch (e) {
      console.error(e);
    }
  }, [trimToMillis, trimFromMillis, isLoaded, isPlaying]);

  // Trimming

  const trimAudioMobile = async () => {
    const inputFilePath = trimmedAudioPath || file;
    const outputFilePathWithoutExt = getFileDirectory(inputFilePath) + '/' + Date.now();
    try {
      setIsTrimming(true);
      cutAudioMobile({
        from: trimFromMillis || 0,
        to: cutToMillis || trimToMillis || durationMillis,
        inputFilePath: inputFilePath,
        outputFilePathWithoutExt: outputFilePathWithoutExt,
        inputFileExt: getFileExt(inputFilePath),
      }).then((outputPath) => {
        onTrimming();
        setTrimmedAudioPath(outputPath);
        setIsTrimming(false);
      });
    } catch (e) {
      console.error(e);
      setTrimmedAudioPath(inputFilePath);
      setIsTrimming(false);
    }
  };

  const trimAudioWeb = async () => {
    const fileReader = new FileReader();
    let arrayBuffer;
    setIsTrimming(true);
    fileReader.onloadend = async () => {
      arrayBuffer = fileReader.result;
      const output = await AudioBufferSlice(
        arrayBuffer,
        trimFromMillis,
        cutToMillis || trimToMillis,
      );
      setTrimmedAudioPath(URL.createObjectURL(output));
      setTrimmedAudioFile(new File([output], Date.now() + '.mp3'));
      setIsTrimming(false);
    };
    fileReader.readAsArrayBuffer(trimmedAudioFile || file);
  };

  const trimAudio = () => {
    if (trimmedAudioFile || trimmedAudioPath || file) {
      if (Platform.OS !== 'web') {
        trimAudioMobile();
      } else {
        trimAudioWeb();
      }
    }
  };

  return (
    <>
      <Time positionMillis={progress * durationMillis} />
      {useMemo(() => {
        return (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <View style={{ justifyContent: 'center' }}>
              {!isNaN(trimToMillis) && (trimFromMillis != 0 || trimToMillis != durationMillis) ? (
                <OptionButton disabled={isTrimming} onPress={trimAudio}>
                  <OptionButtonText>
                    {intl.formatMessage({
                      id: 'post.audioNote.trim',
                      defaultMessage: 'Trim',
                    })}
                  </OptionButtonText>
                </OptionButton>
              ) : (
                <SeekBackward onPress={onRewindBackward} />
              )}
            </View>
            {/* {durationMillis && durationMillis !== Infinity ? ( */}
            <Button
              onPress={onPlayPausePress}
              // disabled={!isLoaded}
            >
              {isPlaying ? <Pause /> : <Play />}
            </Button>
            {/* ) : null} */}
            <View style={{ justifyContent: 'center' }}>
              {!isNaN(trimToMillis) && (trimFromMillis != 0 || trimToMillis != durationMillis) ? (
                <OptionButton disabled={isTrimming} onPress={onDelete}>
                  <OptionButtonText>
                    {intl.formatMessage({
                      id: 'post.audioNote.reset',
                      defaultMessage: 'Reset',
                    })}
                  </OptionButtonText>
                </OptionButton>
              ) : (
                <SeekForward onPress={onRewindForward} />
              )}
            </View>
          </View>
        );
      }, [onPlayPausePress, isPlaying, durationMillis, playerControlsUpdater])}
    </>
  );
};

export default PlayerControls;
