import styled from 'styled-components/native';

interface ViewProps {
  isActive?: boolean;
}

export default styled.View<ViewProps>`
  background-color: ${({ isActive }) => (isActive ? '#0537FF' : '#31374b')};
  width: 150px;
  height: ${({ isActive }) => (isActive ? '190px' : '160px')};
  border-radius: 8px;
  margin: 30px 15px 30px 0;
  align-items: center;
  position: relative;
`;
