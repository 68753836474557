import { AntDesign } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ResizeMode } from 'expo-av';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Image, Linking, useWindowDimensions } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as HOME_SCREEN } from 'screens/HomeScreen';
import { SCREEN_NAME as JOIN_STANBASE_SCREEN_NAME } from 'screens/JoinStanbaseScreen';
import { SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME } from 'screens/LoginWithEmailScreen';

import HamburgerMenu from 'components/HamburgerMenu';
import NavigationHeader from 'components/NavigationHeader';
import NavigationMenu from 'components/NavigationMenu';
import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

import { VARIANT } from 'context';
import { useScreenSize } from 'hooks';
import {
  artistAppStoreURL,
  artistGooglePlayURL,
  fanAppStoreURL,
  fanGooglePlayURL,
} from 'utils/links';
import { breakpoints } from 'utils/responsive';
import { screenWidth } from 'utils/screen';

import { SCREEN_NAME } from './constants';
import { menuItems } from './menuItems';
import messages from './messages';
import BackgroundView from './styled/BackgroundView';
import Button from './styled/Button';
import ButtonLogin from './styled/ButtonLogin';
import ButtonRegister from './styled/ButtonRegister';
import ButtonsContainer from './styled/ButtonsContainer';
import HamburgerButtonsView from './styled/HamburgerButtonsView';
import HamburgerCloseButton from './styled/HamburgerCloseButton';
import HamburgerWrapper from './styled/HamburgerWrapper';
import LogoNavigation from './styled/LogoNavigation';
import * as S from './WelcomeScreen.styled';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type NavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: NavigationProp;
};

const WelcomeScreenFan: React.FC<Props> = ({ navigation, route }: Props) => {
  const intl = useIntl();
  const media = useScreenSize();
  const windowWidth = useWindowDimensions().width;
  const [hoveredItem, setHoveredItem] = useState(-1);
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);
  const showHamburgerMenu = windowWidth < 1215;
  const onRegisterPress = useCallback(() => {
    navigation.navigate(JOIN_STANBASE_SCREEN_NAME);
  }, []);

  const onLoginPress = useCallback(() => {
    navigation.navigate(LOGIN_WITH_EMAIL_SCREEN_NAME);
  }, []);

  const onLogoPress = () => {
    navigation.navigate(HOME_SCREEN);
  };

  const isMobile = screenWidth < breakpoints.tablet;

  const renderLoginButtons = (): React.ReactNode => {
    return (
      <ButtonsContainer>
        <ButtonRegister onPress={onRegisterPress}>
          {intl.formatMessage(messages.registerCta.fanWeb)}
        </ButtonRegister>
        <ButtonLogin onPress={onLoginPress}>
          {intl.formatMessage(messages.loginCta.fanWeb)}
        </ButtonLogin>
      </ButtonsContainer>
    );
  };

  const renderHamburgerMenu = () => {
    return <HamburgerMenu onPress={() => setHamburgerOpen(true)} />;
  };

  return (
    <>
      <BackgroundView>
        <S.HeaderSectionContainer>
          <S.VideoWrapper>
            <S.BackgroundVideo
              isMuted
              shouldPlay
              source={require('./images/bg_video.mp4')}
              resizeMode={ResizeMode.COVER}
              isLooping
            />
          </S.VideoWrapper>
          <S.NavHeaderWrapper isMobile={isMobile}>
            <NavigationHeader
              transparent
              insets={{ top: media.tablet ? 20 : 30 }}
              navigation={navigation}
              headerLeft={() => (
                <TouchableOpacity onPress={onLogoPress}>
                  <LogoNavigation />
                </TouchableOpacity>
              )}
              headerRight={
                route.params?.isHoldingPage
                  ? undefined
                  : showHamburgerMenu
                  ? () => renderHamburgerMenu()
                  : () => renderLoginButtons()
              }
              headerMiddle={
                route.params?.isHoldingPage
                  ? undefined
                  : showHamburgerMenu
                  ? undefined
                  : () => <S.NavigationMenuTop menuItems={menuItems} />
              }
            />
          </S.NavHeaderWrapper>
          <S.HeaderSectionInner isMobile={isMobile}>
            <S.HeaderTextRow isMobile={isMobile}>
              <S.HeaderBig>Calling all</S.HeaderBig>
              <S.HeaderBigWithBg>Stans*</S.HeaderBigWithBg>
              <S.EmojiWrapper>
                <S.EmojiImage source={require('./images/emoji_flame.png')} />
                <S.EmojiImage source={require('./images/emoji_star.png')} />
              </S.EmojiWrapper>
            </S.HeaderTextRow>
            <S.HeaderSmallWrapper isMobile={isMobile}>
              {isMobile ? (
                <S.HeaderSmall>
                  Sign up now, or download now on IOS and Android. Get your Stan on.
                </S.HeaderSmall>
              ) : (
                <>
                  <S.HeaderSmallWithUnderline>Sign up now,</S.HeaderSmallWithUnderline>
                  <S.HeaderSmall>
                    {' '}
                    or download now on IOS and Android. Get your Stan on.
                  </S.HeaderSmall>
                </>
              )}
            </S.HeaderSmallWrapper>
            <S.HeaderButtonsWrapper>
              <S.TransparentButton onPress={() => Linking.openURL(fanAppStoreURL)}>
                <S.ButtonLabel>Download for IOS</S.ButtonLabel>
              </S.TransparentButton>
              <S.TransparentButton onPress={() => Linking.openURL(fanGooglePlayURL)}>
                <S.ButtonLabel>Download for Android</S.ButtonLabel>
              </S.TransparentButton>
            </S.HeaderButtonsWrapper>
          </S.HeaderSectionInner>
          <S.HeaderSectionBottomTextWrapper isMobile={isMobile}>
            {isMobile ? (
              <S.HeaderSmall>*noun - an artist’s most engaged fans, superfans</S.HeaderSmall>
            ) : (
              <>
                <S.HeaderSmall>*</S.HeaderSmall>
                <S.HeaderSmallItalic>noun</S.HeaderSmallItalic>
                <S.HeaderSmall> - an artist’s most engaged fans, superfans</S.HeaderSmall>
              </>
            )}
          </S.HeaderSectionBottomTextWrapper>
        </S.HeaderSectionContainer>
        <S.DoubleColumnSection isSingleCol={screenWidth <= breakpoints.desktop}>
          <S.ListSectionWrapper>
            <S.ListSectionContent>
              <S.ListSectionHeaderWrapper>
                <S.ListSectionHeaderText isColored>Calling all Stans*</S.ListSectionHeaderText>
                <S.ListSectionHeaderText>
                  Get closer to artists than ever before.
                </S.ListSectionHeaderText>
              </S.ListSectionHeaderWrapper>
              <S.ListSectionItemsWrapper>
                <S.ListSectionLine isHighlighted={hoveredItem === 0} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 0} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(0)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/disc.png')} />
                  <S.ListItemText>Exclusive photos and videos</S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 0 || hoveredItem === 1} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 1} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(1)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/diamond.png')} />
                  <S.ListItemText>Voice notes and exclusive demos</S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 1 || hoveredItem === 2} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 2} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(2)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/rocket.png')} />
                  <S.ListItemText>Live streams</S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 2 || hoveredItem === 3} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 3} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(3)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/money.png')} />
                  <S.ListItemText>Exclusive merchandise and experience store </S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 3} />
              </S.ListSectionItemsWrapper>
              <S.StoreButtonRow>
                <TouchableOpacity onPress={() => Linking.openURL(fanAppStoreURL)}>
                  <Image
                    source={require('./images/button_apple.png')}
                    style={{ height: 39, width: 117 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL(fanGooglePlayURL)}>
                  <Image
                    source={require('./images/button_play_store.png')}
                    style={{ height: 39, width: 133 }}
                  />
                </TouchableOpacity>
              </S.StoreButtonRow>
            </S.ListSectionContent>
          </S.ListSectionWrapper>
          <S.FanImageSection>
            <S.ImageLeft source={require('./images/img1.png')} />
            <S.ImageMiddle source={require('./images/img2.png')} />
            <S.ImageRight source={require('./images/img3.png')} />
          </S.FanImageSection>
        </S.DoubleColumnSection>
        <S.ImageBackgroundSection
          source={
            screenWidth < breakpoints.tabletLandscape
              ? require('./images/Jelani_Mobile.jpg')
              : require('./images/Jelani_Desktop.jpg')
          }
        >
          <S.ImageBackgroundSectionContent>
            <S.ImageBackgroundText>
              Access exclusive content and support your favourite artists with monthly subscriptions
              on Stanbase.
            </S.ImageBackgroundText>
            {/*<S.ImageBackgroundButton onPress={() => Linking.openURL(fanAppStoreURL)}>*/}
            {/*  <S.ButtonLabel>Read more</S.ButtonLabel>*/}
            {/*</S.ImageBackgroundButton>*/}
          </S.ImageBackgroundSectionContent>
        </S.ImageBackgroundSection>
        <S.DoubleColumnSection isSingleCol={screenWidth <= breakpoints.desktop}>
          <S.ArtistImageSection>
            <S.ImageSingleCentered source={require('./images/img4.png')} />
          </S.ArtistImageSection>
          <S.ListSectionWrapper>
            <S.ListSectionContent>
              <S.ListSectionHeaderWrapper>
                <S.ListSectionHeaderText>For Artists</S.ListSectionHeaderText>
                <S.ListSectionHeaderText isColored>
                  Find out who your real Stans are.
                </S.ListSectionHeaderText>
              </S.ListSectionHeaderWrapper>
              <S.ListSectionItemsWrapper>
                <S.ListSectionLine isHighlighted={hoveredItem === 4} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 4} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(4)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/disc.png')} />
                  <S.ListItemText>Earn from your social media content</S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 4 || hoveredItem === 5} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 5} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(5)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/diamond.png')} />
                  <S.ListItemText>Get to know your core fans - insights and data</S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 5 || hoveredItem === 6} />
                <S.ListSectionItem
                  isHighlighted={hoveredItem === 6} // @ts-ignore
                  onMouseEnter={() => setHoveredItem(6)}
                  onMouseLeave={() => setHoveredItem(-1)}
                >
                  <S.ListItemImage source={require('./images/rocket.png')} />
                  <S.ListItemText>
                    Launch merchandise and ticket pre-sales, exclusively to your most engaged fans
                  </S.ListItemText>
                </S.ListSectionItem>
                <S.ListSectionLine isHighlighted={hoveredItem === 6} />
              </S.ListSectionItemsWrapper>
              <S.StoreButtonRow>
                <TouchableOpacity onPress={() => Linking.openURL(artistAppStoreURL)}>
                  <Image
                    source={require('./images/button_apple.png')}
                    style={{ height: 39, width: 117 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL(artistGooglePlayURL)}>
                  <Image
                    source={require('./images/button_play_store.png')}
                    style={{ height: 39, width: 133 }}
                  />
                </TouchableOpacity>
              </S.StoreButtonRow>
            </S.ListSectionContent>
          </S.ListSectionWrapper>
        </S.DoubleColumnSection>
        <S.Footer isMobile={screenWidth < breakpoints.tabletLandscape}>
          <NavigationMenu menuItems={menuItems} />
          <S.CopyRightText>© Stanbase {new Date().getFullYear()}</S.CopyRightText>
        </S.Footer>
      </BackgroundView>
      {showHamburgerMenu && (
        // @ts-ignore
        <HamburgerWrapper style={{ transform: [{ translateX: isHamburgerOpen ? 0 : '100%' }] }}>
          <HamburgerCloseButton onPress={() => setHamburgerOpen(false)}>
            <AntDesign name="close" size={30} color="white" />
          </HamburgerCloseButton>
          <HamburgerButtonsView>
            <Button onPress={onRegisterPress} secondary>
              {intl.formatMessage(messages.registerCta[VARIANT])}
            </Button>
            <Button onPress={onLoginPress}>{intl.formatMessage(messages.loginCta[VARIANT])}</Button>
          </HamburgerButtonsView>
        </HamburgerWrapper>
      )}
    </>
  );
};

export default WelcomeScreenFan;
