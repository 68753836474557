import { FontAwesome } from '@expo/vector-icons';
import React, { memo } from 'react';
import styled from 'styled-components/native';

const IconPause: React.FC = ({ ...restProps }) => (
  <FontAwesome name="pause" size={24} {...restProps} />
);

export default memo(styled(IconPause)`
  color: ${(props) => props.theme.colors.primaryActive};
`);
