import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import EmailSentView from 'components/EmailSentView';
import FormContentView from 'components/FormContentView';
import FormErrorsView from 'components/FormErrorsView';
import FormTitleText from 'components/FormTitleText';
import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { useAuth } from 'hooks/auth';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
export { SCREEN_NAME } from './constants';
import messages from './messages';
import ForgotView from './styled/ForgotView';
import LoginFormButton from './styled/LoginFormButton';
import MainView from './styled/MainView';
import TextInput from './styled/TextInput';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ForgotPasswordScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<{
    email: string;
    forgotPassword: any;
  }>({
    mode: 'onChange',
    defaultValues: {
      email: route.params.email || '',
    },
  });
  const { isLoading, authError, forgotPassword } = useAuth();
  const intl = useIntl();
  const emailInputRef = useRef(null);
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const doSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }
    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [handleSubmit]);

  const onSubmit = useCallback(
    async (args: { email: string }) => {
      if (isLoading) {
        return;
      }
      const response = await forgotPassword(args.email);
      if (response) {
        setEmail(args.email);
        setFormSubmitted(true);
      }
    },
    [isLoading, forgotPassword],
  );

  useEffect(() => {
    (emailInputRef.current as any)?.focus();
  }, []);

  useEffect(() => {
    if (!authError) {
      return;
    }
    setError('forgotPassword', {
      message: intl.formatMessage(
        (messages.error as any)[authError.data.code] || messages.error.forgot_password_failed,
      ),
    });
  }, [authError]);

  return (
    <MainView>
      <WebContainer>
        <NavigationHeader showGoBack insets={{ top: 35 }} headerMiddleLogo={true} />
        <ForgotView isSubmitted={!!formSubmitted}>
          {!formSubmitted ? (
            <>
              <FormContentView>
                <FormTitleText>{intl.formatMessage(messages.title)}</FormTitleText>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextInput
                      ref={emailInputRef}
                      label={intl.formatMessage(messages.emailInput.label)}
                      placeholder={intl.formatMessage(messages.emailInput.placeholder)}
                      keyboardType="email-address"
                      returnKeyType="next"
                      autoCapitalize="none"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        clearErrors('email');
                        clearErrors('forgotPassword');
                        onChange(value);
                      }}
                    />
                  )}
                  name="email"
                  rules={{
                    required: intl.formatMessage(messages.emailInput.error.required),
                    pattern: {
                      value: regex.email,
                      message: intl.formatMessage(messages.emailInput.error.invalid),
                    },
                  }}
                  defaultValue=""
                />

                {isSubmitted && <FormErrorsView errors={errors} />}

                <LoginFormButton
                  disabled={!isValid}
                  processing={isSubmitting || isLoading}
                  onPress={doSubmit}
                >
                  {intl.formatMessage(messages.title)}
                </LoginFormButton>
              </FormContentView>
            </>
          ) : (
            <EmailSentView
              navigation={navigation}
              title={messages.title}
              description={messages.success}
              email={email}
            />
          )}
        </ForgotView>
      </WebContainer>
    </MainView>
  );
};

export default ForgotPasswordScreen;
