import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

interface Props {
  percent: number;
}

export default styled(LinearGradient).attrs<Props, { colors?: string[] }>((props) => ({
  colors: [props.theme.pollContent.answer, props.theme.pollContent.background],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
  locations: [props.percent / 100, props.percent / 100],
}))<Props>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 22px;
`;
