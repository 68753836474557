import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button)`
  width: 32px;
  height: 32px;
  min-height: 32px;
  padding: 0;
  background: rgba(18, 18, 18, 0.52);
  border-radius: 16px;
  margin-top: 46px;
  position: absolute;
`;
