import React from 'react';

import KeyboardAvoidingView from 'components/KeyboardAvoidingView';
import ScrollView from 'components/ScreenScrollView';

import MainContainer from './styled/MainContainer';
import MainView from './styled/MainView';

type Props = {
  children: React.ReactNode;
  header?: any;
};

const ScreenView: React.FC<Props> = ({ children, header }: Props) => {
  return (
    <KeyboardAvoidingView>
      <MainContainer>
        <MainView>
          {header}
          <ScrollView
            contentContainerStyle={{
              paddingHorizontal: 15,
              paddingBottom: 15,
              flexGrow: 1,
            }}
          >
            {children}
          </ScrollView>
        </MainView>
      </MainContainer>
    </KeyboardAvoidingView>
  );
};

export default ScreenView;
