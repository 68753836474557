import * as React from 'react';

import Container from './styled/Container';
import ImagePreview from './styled/ImagePreview';
import ImageWrapper from './styled/ImageWrapper';
import InfoWrapper from './styled/InfoWrapper';
import OrderName from './styled/OrderName';
import OrderPrice from './styled/OrderPrice';

export interface OrderProductPreviewProps {
  /** The preview image url for the order */
  previewImage?: string;
  /** The order name */
  name: string;
  /** The order price */
  price: string;
}

/**
 * Orders product preview in Marketplace module
 */
const OrderProductPreview: React.FC<OrderProductPreviewProps> = ({
  name,
  price,
  previewImage,
  ...rest
}: OrderProductPreviewProps) => {
  return (
    <Container {...rest}>
      <ImageWrapper>{previewImage && <ImagePreview source={{ uri: previewImage }} />}</ImageWrapper>
      <InfoWrapper>
        <OrderName>{name}</OrderName>
        <OrderPrice>{price}</OrderPrice>
      </InfoWrapper>
    </Container>
  );
};

OrderProductPreview.displayName = 'OrderProductPreview';

export default OrderProductPreview;
