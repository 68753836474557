import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function SuccessIcon(props: SvgProps) {
  return (
    <Svg viewBox="0 0 86 85" fill="none" {...props}>
      <Path
        d="M85.5069 35.5485C83.186 17.2388 71.4389 3.70433 55.4323 1.06325C47.7047 -0.217279 36.2422 -0.982038 25.5178 2.63723C14.9712 6.19425 7.6082 13.1126 3.63323 23.1968C-2.64491 39.1145 -0.670765 57.611 8.65752 70.3273C14.6244 78.4462 28.8169 85 42.8583 85C50.1857 85 57.4776 83.2215 63.5334 78.9264C81.5053 70.8786 87.5166 51.3862 85.5069 35.5485Z"
        fill="#1DFF78"
      />
      <Path
        d="M39.4257 62.813C39.1338 62.8115 38.8467 62.7382 38.5898 62.5995C34.6949 60.5276 31.3513 57.264 28.1144 54.1072C26.3326 52.2996 24.4597 50.5841 22.5032 48.9673C22.0044 48.5717 21.7546 47.9401 21.8477 47.3104C21.9409 46.6807 22.3629 46.1485 22.9548 45.9143C23.5468 45.6801 24.2187 45.7795 24.7175 46.175C26.7361 47.7846 28.7013 49.732 30.6043 51.5639C33.1544 54.2388 35.9734 56.6436 39.0167 58.7402C43.9609 52.6132 47.7403 45.2057 51.404 38.0294C54.0718 32.8984 56.7395 27.5985 59.8608 22.752C60.398 21.9377 61.4908 21.7081 62.3103 22.2373C63.1298 22.7666 63.37 23.8572 62.8487 24.6817C59.8293 29.527 57.0572 34.5221 54.5431 39.6479C50.5503 47.4555 46.4242 55.5389 40.7418 62.1905C40.4121 62.5763 39.9332 62.8028 39.4257 62.813Z"
        fill="white"
      />
    </Svg>
  );
}

const styledSuccessIcon = styled(SuccessIcon)`
  width: 86px;
  height: 85px;
  margin: 30px 0 25px;
`;

export default React.memo(styledSuccessIcon);
