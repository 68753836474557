export default [
  {
    title: {
      id: 'onBoarding.screen1.title',
      defaultMessage: 'Follow Artists',
    },
    description: {
      id: 'onBoarding.screen1.description',
      defaultMessage: 'Keep up to date with your favourites!',
    },
    image: require('./images/screen-1.png'),
    name: 'FollowArtistsScreen',
  },
  {
    title: {
      id: 'onBoarding.screen2.title',
      defaultMessage: 'Become a Stan',
    },
    description: {
      id: 'onBoarding.screen2.description',
      defaultMessage: 'Subscribe to your favourite artists to access exclusive content.',
    },
    image: require('./images/screen-2.png'),
    name: 'UnlockExclusiveContentScreen',
  },
  {
    title: {
      id: 'onBoarding.screen3.title',
      defaultMessage: 'Watch StanCasts',
    },
    description: {
      id: 'onBoarding.screen3.description',
      defaultMessage: 'Join your favourite artists as they go live on Stanbase.',
    },
    image: require('./images/screen-3.png'),
    name: 'ViewStancastingScreen',
  },
  {
    title: {
      id: 'onBoarding.screen4.title',
      defaultMessage: 'Engage',
    },
    description: {
      id: 'onBoarding.screen4.description',
      defaultMessage:
        'Make your Stanning supreme by bookmarking, commenting, sharing, liking and more.',
    },
    image: require('./images/screen-4.png'),
    name: 'EngageScreen',
  },
  {
    title: {
      id: 'onBoarding.screen5.title',
      defaultMessage: 'StanStore',
    },
    description: {
      id: 'onBoarding.screen5.description',
      defaultMessage: 'See what’s on offer at your favourite artists’ exclusive marketplaces.',
    },
    image: require('./images/screen-5.png'),
    name: 'ShopScreen',
  },
];
