import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';

import Item from 'components/ArtistsList/styled/Item';
import Avatar from 'components/Avatar';

import { useActiveFollowing, useActiveSubscriptions } from 'hooks';
import { Artist } from 'types';

interface Props {
  leftPosition: number;
  topPosition: number;
}

const List = styled.FlatList`
  flex: 1;
  padding-bottom: 20px;
  width: 100%;
`;

const StyledAvatar = styled(Avatar)`
  width: 100%;
  padding: 0 15px 0 15px;
`;

const StyledItem = styled(Item)`
  width: 100%;
`;

const Title = styled.Text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #ffffff;
  margin: 16px 0 20px 12px;
  font-family: 'BasierCircle-Medium';
`;

const Wrapper = styled.View<Props>`
  position: fixed;
  z-index: 2147483647;
  top: ${({ topPosition }) => (topPosition ? topPosition + 60 : 100)}px;
  left: ${({ leftPosition }) => (leftPosition ? leftPosition - 200 : 100)}px;
  width: 332px;
  height: 484px;
  background-color: red;
  justify-content: center;
  align-items: flex-start;
  background-color: #151a2b;
  border: 0.5px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
`;

const Separator = styled.View`
  width: auto;
  height: 0;
  border: 1px solid rgba(255, 255, 255, 0.09);
  margin: 8px 0;
`;

interface SubscribedArtist {
  artist: Artist;
  price?: string;
  price_currency?: string;
}

const ArtistProfilesPopup: React.FC<Props> = ({ leftPosition, topPosition }: Props) => {
  const navigation = useNavigation();
  const { activeSubscriptions } = useActiveSubscriptions();
  const { activeFollowing } = useActiveFollowing();

  const displayedSubscriptions = useMemo(() => {
    return activeSubscriptions
      ? activeSubscriptions.map((subscription) => subscription?.plan_price?.plan.artist)
      : [];
  }, [activeSubscriptions]);

  const navigateToArtist = useCallback(
    (username: string) => {
      navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
        artistUsername: username,
      });
    },
    [navigation],
  );

  const renderArtistItem = ({ item }: { item: unknown }) => {
    const typedItem = item as SubscribedArtist;

    return (
      <StyledItem>
        <StyledAvatar
          mode="minimal-row-big"
          name={typedItem.display_name}
          isLive={typedItem.is_live}
          imageUrl={typedItem.low_res_profile_picture}
          onPress={() => navigateToArtist(typedItem.username)}
        />
      </StyledItem>
    );
  };

  return (
    <Wrapper leftPosition={leftPosition} topPosition={topPosition}>
      {displayedSubscriptions.length ? (
        <>
          <Title>You&apos;re stan of</Title>
          <List
            data={displayedSubscriptions}
            extraData={displayedSubscriptions}
            renderItem={renderArtistItem}
            ItemSeparatorComponent={Separator}
          />
        </>
      ) : (
        <Title>You don&apos;t have any subscriptions yet.</Title>
      )}

      {activeFollowing ? (
        <>
          <Title>You&apos;re following</Title>
          <List
            data={activeFollowing}
            extraData={activeFollowing}
            renderItem={renderArtistItem}
            ItemSeparatorComponent={Separator}
          />
        </>
      ) : (
        <Title>You don&apos;t have any followers yet.</Title>
      )}
    </Wrapper>
  );
};

export default ArtistProfilesPopup;
