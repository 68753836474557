import { SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack/constants';

import { SCREEN_NAME as MARKETPLACE_AVAILABLE_SCREEN_NAME } from 'screens/MarketplaceAvailableItemsScreen';
import { SCREEN_NAME as MARKETPLACE_NEW_MESSAGE_SCREEN_NAME } from 'screens/MarketplaceNewMessageScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME } from 'screens/MarketplaceOrderInformationScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen/constants';
import {
  SCREEN_NAME as MARKETPLACE_ORDERS_SCREEN_NAME,
  SCREEN_NAME as MARKETPLACE_SCREEN_NAME,
} from 'screens/MarketplaceOrdersScreen/constants';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from 'screens/MarketplaceProductOrderScreen/constants';

import { MarketplaceItem, MarketplaceOrder, MarketplaceQuestionareAnswer } from 'types';

export const SCREEN_NAMES = [
  MARKETPLACE_STACK_SCREEN_NAME,
  MARKETPLACE_SCREEN_NAME,
  MARKETPLACE_ORDER_DETAILS_SCREEN_NAME,
  MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME,
  MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME,
  MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME,
  MARKETPLACE_ORDER_REPORT_SCREEN_NAME,
  MARKETPLACE_NEW_MESSAGE_SCREEN_NAME,
  MARKETPLACE_AVAILABLE_SCREEN_NAME,
  MARKETPLACE_ORDERS_SCREEN_NAME,
];

type ParamListBaseType = { [K in (typeof SCREEN_NAMES)[number]]: any };
export interface ParamList extends ParamListBaseType {
  [MARKETPLACE_STACK_SCREEN_NAME]:
    | {
        screen: string;
      }
    | undefined;
  [MARKETPLACE_SCREEN_NAME]: undefined;
  [MARKETPLACE_ORDER_DETAILS_SCREEN_NAME]: {
    marketplaceProductId: number;
    order: MarketplaceOrder;
  };
  [MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME]: {
    marketplaceProductId: number;
    order: MarketplaceOrder;
  };
  [MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME]: {
    artist: string;
    productId: number;
  };
  [MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME]: {
    marketplaceProductId: number;
    artistUsername: string;
    item: MarketplaceItem;
    answers?: MarketplaceQuestionareAnswer[];
  };
  [MARKETPLACE_ORDER_REPORT_SCREEN_NAME]: {
    artistUsername: string;
    marketplaceProductId: number;
    orderExternalId: string;
  };
  [MARKETPLACE_NEW_MESSAGE_SCREEN_NAME]: {
    artistUsername?: string;
    marketplaceProductId: number;
    orderExternalId: string;
    onMessageAdded?: () => void;
  };
  [MARKETPLACE_AVAILABLE_SCREEN_NAME]: undefined;
  [MARKETPLACE_ORDERS_SCREEN_NAME]: undefined;
}
