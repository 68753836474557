import CheckBox from '@react-native-community/checkbox';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import messages from '../messages';

const MainView = styled.View`
  margin-top: 25px;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TextView = styled.View`
  margin: 0 10px;
  flex-shrink: 1;
`;

const BiggerText = styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  font-family: 'BasierCircle-Medium';
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.orderAttachment.secondaryText};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.06px;
  font-family: 'BasierCircle-Regular';
`;

interface CheckboxProps {
  value?: boolean;
  onValueChange?: any;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({ value, onValueChange }: CheckboxProps) => {
  const intl = useIntl();
  return (
    <MainView>
      <CheckBox
        value={value}
        onValueChange={(newValue) => onValueChange(newValue)}
        tintColors={{ false: '#ffffff' }}
      />
      <TextView>
        <BiggerText>{intl.formatMessage(messages.body.checkbox.title)}</BiggerText>
        <Text>{intl.formatMessage(messages.body.checkbox.description)}</Text>
      </TextView>
    </MainView>
  );
};

export default CustomCheckbox;
