interface Face {
  width: number;
  height: number;
  bottom: number;
  right: number;
  source: string | undefined;
}

export const followedArtistsPositions: Face[] = [
  {
    width: 100,
    height: 100,
    bottom: 65,
    right: 24,
    source: '',
  },
  {
    width: 158,
    height: 158,
    bottom: 35,
    right: -12,
    source: '',
  },
  {
    width: 91,
    height: 91,
    bottom: 12,
    right: 10,
    source: '',
  },
  {
    width: 103,
    height: 103,
    bottom: 0,
    right: 38,
    source: '',
  },
  {
    width: 155,
    height: 155,
    bottom: 17,
    right: 70,
    source: '',
  },
  {
    width: 122,
    height: 122,
    bottom: 58,
    right: 57,
    source: '',
  },
];

export const stancastingArtistsPosition: Face[] = [
  {
    width: 130,
    height: 180,
    bottom: 45,
    right: 10,
    source: '',
  },
  {
    width: 130,
    height: 180,
    bottom: 32,
    right: 58,
    source: '',
  },
  {
    width: 130,
    height: 130,
    bottom: 6,
    right: 10,
    source: '',
  },
];
