import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as CONTACT_FORM_SCREEN } from 'screens/ContactFormScreen/constants';
import PreFormScreen from 'screens/PreFormScreen';

import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation: ScreenNavigationProp;
};

const ContactScreen: React.FC<Props> = ({ navigation }: Props) => (
  <PreFormScreen
    messages={messages}
    navigation={navigation}
    destination={CONTACT_FORM_SCREEN}
    breadcumb="Contact"
  />
);

export default ContactScreen;
