import styled from 'styled-components/native';

const List = styled.FlatList`
  margin-top: 10px;
`;

List.defaultProps = {
  contentContainerStyle: {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

export default List;
