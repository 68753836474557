import { Animated } from 'react-native';
import styled from 'styled-components/native';

export default styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;
