import { AxiosError } from 'axios';
import * as Notifications from 'expo-notifications';
import * as React from 'react';

import { useBackEnd } from 'context/backEnd';
import { useAuthSwr } from 'hooks/swr';
import { NotificationsGroupType, NotificationsPolling, SingleNotification } from 'types';
import { groupNotifications } from 'utils/notifications';

export const FEED_KEY = '/notifications';
export const MARK_NOTIFICATIONS_AS_READ_KEY = `${FEED_KEY}/mark_all_as_read`;

export const useNotifications = (
  mockValue?: SingleNotification[],
): {
  notifications: NotificationsGroupType[];
  isLoading: boolean;
  refresh: () => void;
  markNotificationsAsRead: () => void;
  error?: AxiosError;
} => {
  const {
    data: notifications,
    error,
    isLoading,
    mutate,
  } = useAuthSwr<SingleNotification[]>({
    key: FEED_KEY,
    isPublic: false,
    cache: false,
    mockValue,
  });

  const { axiosInstance } = useBackEnd();

  const markNotificationsAsRead = async () => {
    try {
      await axiosInstance.post(MARK_NOTIFICATIONS_AS_READ_KEY);
      Notifications.setBadgeCountAsync(0);
    } catch (err) {
      console.error(err);
    }
  };

  const unreadNotificationsCount =
    notifications?.filter((notification) => !notification.is_read).length || 0;

  React.useEffect(() => {
    Notifications.setBadgeCountAsync(unreadNotificationsCount);
  }, [unreadNotificationsCount]);

  return {
    notifications: groupNotifications(notifications),
    isLoading,
    refresh: () => mutate(),
    error,
    markNotificationsAsRead,
  };
};

export const useNotificationsGreenCircles = (
  isArtist: boolean,
  mockValue?: NotificationsPolling,
): {
  data?: NotificationsPolling;
  isLoading: boolean;
  error?: AxiosError;
} => {
  const { data, error, isLoading } = useAuthSwr<NotificationsPolling>({
    key: isArtist ? '/artists/me/green_circles' : '/green_circles',
    isPublic: false,
    cache: true,
    swrOptions: {
      refreshInterval: 10000,
    },
    mockValue,
  });

  return {
    data,
    isLoading,
    error,
  };
};
