import React, { FC, useCallback, useState } from 'react';
import { Dimensions, LayoutChangeEvent } from 'react-native';

import { DIM_TARGET_OPACITY } from './BottomSheet.constants';
import BottomSheetView from './styled/BottomSheetView';
import DimView from './styled/DimView';
import Handle from './styled/Handle';
import PressableBackground from './styled/PressableBackground';
import View from './styled/View';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  showDimmer?: boolean;
  isClosable?: boolean;
  children: React.ReactNode;
}

const BottomSheet: FC<Props> = ({
  isOpen,
  setIsOpen,
  children,
  showDimmer = true,
  isClosable = true,
  ...rest
}) => {
  const [height, setHeight] = useState(Dimensions.get('screen').height);

  const onLayout = (e: LayoutChangeEvent) => {
    const { height: newHeight } = e.nativeEvent.layout;

    setHeight(newHeight);
  };

  const onBackgroundPress = useCallback(() => {
    if (isClosable) {
      setIsOpen(false);
    }
  }, [isClosable]);

  return (
    <View pointerEvents={isOpen ? 'auto' : 'none'}>
      {showDimmer && <DimView style={{ opacity: isOpen ? DIM_TARGET_OPACITY : 0 }} />}
      <PressableBackground onPress={onBackgroundPress} />
      <BottomSheetView
        onLayout={onLayout}
        style={{
          transform: [{ translateY: isOpen ? 0 : height + Dimensions.get('screen').height * 0.15 }],
        }}
        {...rest}
      >
        <Handle dark />
        {children}
      </BottomSheetView>
    </View>
  );
};

export default BottomSheet;
