import React from 'react';
import { useIntl } from 'react-intl';
import { ScrollView } from 'react-native-gesture-handler';

import NewMasonry from 'components/NewMasonry';

import { useBookmarks } from 'hooks';
import { ArtistProduct } from 'types';

import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import CenterView from './styled/CenterView';
import SafeAreaView from './styled/SafeAreaView';
import Text from './styled/Text';

export interface BookmarkedProps {
  navigateToProduct: (product: ArtistProduct) => void;
  scrollable?: boolean;
}

const Bookmarked: React.FC<BookmarkedProps> = ({
  navigateToProduct,
  scrollable = true,
}: BookmarkedProps) => {
  const intl = useIntl();
  const { bookmarks, isLoading } = useBookmarks();

  if (isLoading) {
    return (
      <CenterView>
        <ActivityIndicator />
      </CenterView>
    );
  }

  if (!isLoading && bookmarks?.length === 0) {
    return (
      <CenterView>
        <Text>{intl.formatMessage(messages.noBookmarks)}</Text>
      </CenterView>
    );
  }

  const renderMasonry = () => {
    return <NewMasonry data={bookmarks as any[]} onProductPreview={navigateToProduct} />;
  };

  return scrollable ? (
    <SafeAreaView>
      <ScrollView>{renderMasonry()}</ScrollView>
    </SafeAreaView>
  ) : (
    renderMasonry()
  );
};

Bookmarked.displayName = 'Bookmarked';

export default Bookmarked;
