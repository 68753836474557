import { memo } from 'react';
import styled, { css } from 'styled-components/native';

import { lerpColor } from 'utils/color';

interface BarProps {
  width: number;
  height: number;
  isActive: boolean;
  progress: number;
  barColor?: string;
}

export default memo(styled.View<BarProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height * 100}%;
  border-radius: ${({ width }) => width / 2}px;
  background-color: ${({ theme, barColor }) => barColor || theme.colors.progressBackgorund};

  ${({ isActive, progress, barColor }) =>
    isActive &&
    css`
      background-color: ${({ theme }) =>
        barColor || lerpColor(theme.colors.progressStart, theme.colors.progressEnd, progress)};
    `}
`);
