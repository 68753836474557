import { Platform } from 'react-native';
import styled from 'styled-components/native';

export default styled.View<{ withBorderBottom?: boolean }>`
  flex: 1;
  ${Platform.OS === 'web' &&
  `position: relative;
  flex-basis: content;`}

  ${({ withBorderBottom, theme }) =>
    withBorderBottom &&
    `
    padding-bottom: 32px;
    border-bottom-width: 1px;
    border-bottom-color: ${theme.orderDetailsHeader.border};
    `}
`;
