import Animated from 'react-native-reanimated';
import styled from 'styled-components/native';

export default styled(Animated.View)`
  position: absolute;
  left: 20px;
  right: 20px;
  padding-bottom: 16px;
  opacity: 1;
  bottom: 30px;
`;
