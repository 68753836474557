import { NavigationContainerRef } from '@react-navigation/native';
import React, { MutableRefObject, useMemo, useState } from 'react';

import TippingSheet from 'components/TippingSheet';

import { PaidProduct } from 'types';

export interface SubscriptionStatus {
  isSubscribed: boolean;
  artistUsername: string;
}

interface TippingState {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setProduct: (value: PaidProduct) => void;
}

const DEFAULT_STATE: TippingState = {
  isOpen: false,
  setIsOpen: () => {},
  setProduct: () => {},
};

export const TippingContext = React.createContext(DEFAULT_STATE);

export function useTippingSheet(): TippingState {
  const context = React.useContext<TippingState>(TippingContext);
  if (context === undefined) {
    throw new Error('useTippingSheet must be used within a TippingProvider');
  }
  return context;
}

interface Props {
  navigationRef: MutableRefObject<NavigationContainerRef | null>;
  children: React.ReactNode;
}

export const TippingProvider: React.FC<Props> = ({ navigationRef, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<PaidProduct>({
    artistUsername: '',
    type: 'tip',
  });

  const value = useMemo<TippingState>(
    () => ({
      isOpen,
      setIsOpen,
      setProduct,
    }),
    [isOpen],
  );

  return (
    <TippingContext.Provider value={value}>
      {children}
      <TippingSheet
        navigationRef={navigationRef}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        product={product}
      />
    </TippingContext.Provider>
  );
};
