import styled from 'styled-components/native';

export default styled.View`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.52);
  background-color: rgba(255, 255, 255, 0.52);
`;
