import styled, { css } from 'styled-components/native';

import { AvatarMode } from '../Avatar';

interface ViewProps {
  mode: AvatarMode;
}

export default styled.TouchableOpacity<ViewProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ mode }) =>
    (mode === 'compact' || mode === 'compact-small') &&
    css`
      flex-direction: column;
    `}
`;
