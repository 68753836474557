import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useBackEnd, usePayments, useSnackbarSet, VARIANT } from 'context';
import {
  MarketplaceItem,
  MarketplaceOrder,
  MarketplaceOrderItem,
  MarketplaceOrderReportResponse,
  MarketplaceOrderResponse,
  OrderStatus,
} from 'types';

import { useAuthSwr } from './swr';

export interface UseArtistMarketplaceProducts {
  products: MarketplaceItem[] | undefined;
  isLoading?: boolean;
  error: AxiosError<any>;
  refresh: () => void;
}

export function useArtistMarketplaceProducts(
  mockValue?: MarketplaceItem[],
): UseArtistMarketplaceProducts {
  const {
    isLoading,
    data: products,
    error,
    mutate,
  } = useAuthSwr<MarketplaceItem[]>({
    key: '/artists/me/products/marketplace_items',
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    products,
    error,
    refresh: mutate,
  };
}

export interface UseFanMarketplaceOrders {
  orders: MarketplaceOrder[] | undefined;
  isLoading?: boolean;
  error: AxiosError<any>;
  refresh: () => void;
}

export function useFanMarketplaceOrders(mockValue?: MarketplaceOrder[]): UseFanMarketplaceOrders {
  const {
    isLoading,
    data: orders,
    error,
    mutate,
  } = useAuthSwr<MarketplaceOrder[]>({
    key: '/artists/all/products/marketplace_items/all/orders',
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    orders,
    error,
    refresh: mutate,
  };
}

export interface UseFollowedArtistsMarketplaceItems {
  items: MarketplaceItem[] | undefined;
  isLoading?: boolean;
  error: AxiosError<any>;
  refresh: () => void;
}

export function useFollowedArtistsMarketplaceItems(
  mockValue?: MarketplaceItem[],
): UseFollowedArtistsMarketplaceItems {
  const {
    isLoading,
    data: items,
    error,
    mutate,
  } = useAuthSwr<MarketplaceItem[]>({
    key: '/artists/followed_or_subscribed/products/marketplace_items',
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading,
    items,
    error,
    refresh: mutate,
  };
}

export interface UseFanMarketplaceOrder {
  order: MarketplaceOrder | undefined;
  refresh: () => void;
  isLoading?: boolean;
  error: AxiosError<any>;
}

export function useMarketplaceOrder(
  artistUsername: string,
  marketplaceItemId: number,
  orderExternalId: string,
  mockValue?: MarketplaceOrder,
): UseFanMarketplaceOrder {
  const {
    isLoading,
    data: order,
    error,
    mutate,
  } = useAuthSwr<MarketplaceOrder>({
    key: `/artists/${
      VARIANT === 'fan' ? artistUsername : 'me'
    }/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading,
    order,
    error,
    refresh: mutate,
  };
}

export interface UseFanMarketplaceItems {
  items: MarketplaceItem[] | undefined;
  isLoading?: boolean;
  isValidating?: boolean;
  error: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
}

export function useFanMarketplaceItems(
  artistUsername: string,
  mockValue?: MarketplaceItem[],
): UseFanMarketplaceItems {
  const {
    isLoading,
    isValidating,
    data: items,
    error,
    mutate,
  } = useAuthSwr<MarketplaceItem[]>({
    key: `/artists/${artistUsername}/products/marketplace_items`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    isValidating,
    items,
    mutate,
    error,
  };
}

export interface UseMarketplaceItem {
  item: MarketplaceItem | undefined;
  updateMarketplaceItem: (orderProduct: MarketplaceItem, overrideItem: any) => void;
  isLoading?: boolean;
  isValidating?: boolean;
  error: AxiosError<any>;
}

export function useMarketplaceItem(
  artistUsername: string,
  marketplaceItemId: number,
  mockValue?: MarketplaceItem,
): UseMarketplaceItem {
  const {
    isLoading,
    isValidating,
    data: item,
    error,
    mutate,
  } = useAuthSwr<MarketplaceItem>({
    key: `/artists/${
      VARIANT === 'artist' ? 'me' : artistUsername
    }/products/marketplace_items/${marketplaceItemId}`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  const updateMarketplaceItem = (itemMarketplace: MarketplaceItem, overrideItem: any) => {
    if (!item) {
      return;
    }
    const newItem = {
      ...itemMarketplace,
      ...overrideItem,
    };

    mutate(newItem);
  };

  return {
    isLoading: isLoading,
    updateMarketplaceItem,
    isValidating,
    item,
    error,
  };
}

export interface FanMarketplaceCreateOrder {
  createOrder: (marketplaceItem: MarketplaceOrderItem) => Promise<MarketplaceOrderResponse>;
}

export function useFanMarketplaceCreateOrder(
  artistUsername: string,
  marketplaceItemId: number,
): FanMarketplaceCreateOrder {
  const { axiosInstance } = useBackEnd();
  const API_KEY = `/artists/${artistUsername}/products/marketplace_items/${marketplaceItemId}/orders`;

  const createOrder = async (
    marketplaceItem: MarketplaceOrderItem,
  ): Promise<MarketplaceOrderResponse> => {
    const { data } = await axiosInstance.post<MarketplaceOrderResponse>(API_KEY, {
      marketplace_item: marketplaceItem,
    });
    return data;
  };

  return {
    createOrder,
  };
}

export interface FanMarketplaceCreateOrderReport {
  createOrderReport: (email: string, message: string) => Promise<MarketplaceOrderReportResponse>;
}

export function useFanMarketplaceCreateOrderReport(
  artistUsername: string,
  marketplaceItemId: number,
  orderExternalId: string,
): FanMarketplaceCreateOrderReport {
  const { axiosInstance } = useBackEnd();
  const API_KEY = `/artists/${artistUsername}/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}/reports`;

  const createOrderReport = async (
    email: string,
    message: string,
  ): Promise<MarketplaceOrderReportResponse> => {
    const { data } = await axiosInstance.post<MarketplaceOrderReportResponse>(API_KEY, {
      email,
      message,
    });
    return data;
  };

  return {
    createOrderReport,
  };
}

type FanOrderStatusUpdate = OrderStatus.COMPLETED | OrderStatus.CANCEL;

export interface useFanMarketplaceUpdateOrder {
  updateOrder: (status: FanOrderStatusUpdate) => Promise<{ status: FanOrderStatusUpdate }>;
}

export function useFanMarketplaceUpdateOrder(
  artistUsername: string,
  marketplaceItemId: number,
  orderExternalId: string,
): useFanMarketplaceUpdateOrder {
  const { axiosInstance } = useBackEnd();
  const API_KEY = `/artists/${artistUsername}/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}`;

  const updateOrder = async (
    status: FanOrderStatusUpdate,
  ): Promise<{ status: FanOrderStatusUpdate }> => {
    const { data } = await axiosInstance.patch<{
      status: FanOrderStatusUpdate;
    }>(API_KEY, {
      status,
    });
    return data;
  };

  return {
    updateOrder,
  };
}

interface UseArtistMarketplaceOrders {
  orders: MarketplaceOrder[] | undefined;
  isLoading?: boolean;
  isValidating?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export function useArtistMarketplaceOrders(
  tab: 'closed' | 'ongoing',
  mockValue?: MarketplaceOrder[],
): UseArtistMarketplaceOrders {
  const {
    isLoading,
    isValidating,
    data: orders,
    error,
    mutate,
  } = useAuthSwr<MarketplaceOrder[]>({
    key: `/artists/me/products/marketplace_items/all/orders/${tab}`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    isValidating,
    orders,
    error,
    refresh: mutate,
  };
}

export const useMarketplaceOrderControls = (
  initOrder?: MarketplaceOrder,
): {
  onDialogConfirmPress: () => void;
  isDialogOpen: boolean;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onActionPress: (onSuccess?: () => void, webMarketplace?: boolean) => void;
  order?: MarketplaceOrder;
  setOrder?: Dispatch<SetStateAction<MarketplaceOrder | undefined>>;
  showCTA?: boolean;
  showStatus?: boolean;
  refresh: () => void;
  paymentForm?: JSX.Element;
} => {
  const { setSnackbar } = useSnackbarSet();
  const showErrorSnackbar = () => {
    setSnackbar({
      visible: true,
      label: 'Unexpected error has occurred.',
      actionLabel: 'Close',
    });
  };

  const { order: apiOrder, refresh } = useMarketplaceOrder(
    initOrder?.product.artist?.username || '',
    initOrder?.product.id || -1,
    initOrder?.external_id || '',
  );
  const { confirmOrder } = useArtistMarketplaceUpdateOrder();
  const { updateOrder: updateOrderFan } = useFanMarketplaceUpdateOrder(
    initOrder?.product.artist?.username || '',
    initOrder?.product.id || -1,
    initOrder?.external_id || '',
  );
  const [order, setOrder] = useState<MarketplaceOrder | undefined>(initOrder);

  useEffect(() => {
    if (apiOrder) setOrder(apiOrder);
  }, [apiOrder]);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { requestPayment, paymentForm } = usePayments();
  const showCTA = useMemo(() => {
    if (VARIANT === 'fan') {
      return (
        order &&
        order.status !== OrderStatus.COMPLETED &&
        (order.status === OrderStatus.WAITING_FOR_PAYMENT ||
          (order.is_artist_asked_user_for_confirmation && order.status != OrderStatus.DECLINED))
      );
    } else {
      return order && [OrderStatus.PURCHASED, OrderStatus.IN_PROGRESS].includes(order.status);
    }
  }, [order?.status, order?.is_artist_asked_user_for_confirmation]);

  const showStatus = useMemo(
    () => order && [OrderStatus.DECLINED, OrderStatus.COMPLETED].includes(order.status),
    [order?.status],
  );

  const onArtistConfirmPress = useCallback(async () => {
    if (!initOrder) return;
    try {
      await confirmOrder(initOrder.product.id, initOrder.external_id);
      refresh();
      setIsDialogOpen(false);
    } catch (e) {
      setIsDialogOpen(false);
      showErrorSnackbar();
    }
  }, [initOrder]);

  const onStanConfirmPress = useCallback(async () => {
    try {
      await updateOrderFan(OrderStatus.COMPLETED);
      refresh();
      setIsDialogOpen(false);
    } catch (e) {
      setIsDialogOpen(false);
      showErrorSnackbar();
    }
  }, []);

  const onActionPress = useCallback(
    async (onSuccess?: () => void, webMarketplace?: boolean) => {
      if (!order) return requestPayment();
      if (order.status === OrderStatus.WAITING_FOR_PAYMENT) {
        requestPayment(
          {
            artistUsername: order.product.artist.username || '',
            type: 'marketplaceitem',
            orderExternalId: order.external_id,
            productId: order.product.id,
          },
          webMarketplace ? undefined : 'Choose payment method',
          webMarketplace ? undefined : '',
          () => {
            refresh();
            onSuccess?.();
          },
          webMarketplace,
        );
      } else {
        setIsDialogOpen(true);
      }
    },
    [order?.status, order?.product, order?.external_id, refresh],
  );

  return {
    paymentForm,
    order,
    setOrder,
    isDialogOpen,
    setIsDialogOpen,
    onActionPress,
    showCTA,
    showStatus,
    refresh,
    onDialogConfirmPress: VARIANT === 'fan' ? onStanConfirmPress : onArtistConfirmPress,
  };
};

type ArtistOrderStatusUpdate = OrderStatus.DECLINED | OrderStatus.IN_PROGRESS;
type ArtistOrderStatusUpdateResponse = {
  status: ArtistOrderStatusUpdate;
  is_artist_asked_user_for_confirmation: boolean;
};

export interface useArtistMarketplaceUpdateOrder {
  updateOrder: (
    marketplaceItemId: number,
    orderExternalId: string,
    status: ArtistOrderStatusUpdate,
  ) => Promise<ArtistOrderStatusUpdateResponse>;
  confirmOrder: (
    marketplaceItemId: number,
    orderExternalId: string,
  ) => Promise<ArtistOrderStatusUpdateResponse>;
  uploadAttachment: (
    marketplaceItemId: number,
    orderExternalId: string,
    fileUri: string,
    mime: string,
    name?: string,
  ) => Promise<AxiosResponse>;
  updateAttachment: (
    marketplaceItemId: number,
    orderExternalId: string,
    id: number,
    name: string,
  ) => Promise<AxiosResponse>;
  deleteOrder: (marketplaceItemId: number, orderExternalId: string) => Promise<AxiosResponse>;
}

export function useArtistMarketplaceUpdateOrder(): useArtistMarketplaceUpdateOrder {
  const { axiosInstance } = useBackEnd();
  const getKey = (marketplaceItemId: number, orderExternalId: string): string =>
    `/artists/me/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}`;

  const updateOrder = async (
    marketplaceItemId: number,
    orderExternalId: string,
    status: ArtistOrderStatusUpdate,
  ): Promise<ArtistOrderStatusUpdateResponse> => {
    const { data } = await axiosInstance.patch<ArtistOrderStatusUpdateResponse>(
      getKey(marketplaceItemId, orderExternalId),
      {
        status,
      },
    );
    return data;
  };

  const confirmOrder = async (
    marketplaceItemId: number,
    orderExternalId: string,
  ): Promise<ArtistOrderStatusUpdateResponse> => {
    const { data } = await axiosInstance.patch<ArtistOrderStatusUpdateResponse>(
      getKey(marketplaceItemId, orderExternalId),
      {
        is_artist_asked_user_for_confirmation: true,
      },
    );
    return data;
  };

  const uploadAttachment = async (
    marketplaceItemId: number,
    orderExternalId: string,
    fileUri: string,
    mime: string,
  ) => {
    const requestBody = new FormData();
    const fileExt = mime.split('/')[1];
    const fileName = `${Date.now()}.${fileExt}`;
    requestBody.append('name', fileName);
    requestBody.append('file', {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uri: fileUri,
      type: mime,
      name: fileName,
    });
    return await axiosInstance.post(
      getKey(marketplaceItemId, orderExternalId).concat('/attachments'),
      requestBody,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  const updateAttachment = async (
    marketplaceItemId: number,
    orderExternalId: string,
    id: number,
    name: string,
  ) => {
    return await axiosInstance.patch(
      `${getKey(marketplaceItemId, orderExternalId)}/attachments/${id}`,
      { name },
    );
  };

  const deleteOrder = async (
    marketplaceItemId: number,
    orderExternalId: string,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.delete(getKey(marketplaceItemId, orderExternalId));
  };

  return {
    updateOrder,
    confirmOrder,
    uploadAttachment,
    updateAttachment,
    deleteOrder,
  };
}

interface UseMarketPlaceConversation {
  sendMessage: (
    artistUsername: string,
    marketplaceItemId: number,
    orderExternalId: string,
    text: string,
  ) => Promise<AxiosResponse>;
  markMessagesAsRead: (
    artistUsername: string,
    marketplaceItemId: number,
    orderExternalId: string,
  ) => Promise<AxiosResponse>;
}

export const useMarketPlaceConversation = (): UseMarketPlaceConversation => {
  const { axiosInstance } = useBackEnd();

  const sendMessage = async (
    artistUsername: string,
    marketplaceItemId: number,
    orderExternalId: string,
    text: string,
  ) => {
    return await axiosInstance.post(
      `/artists/${artistUsername}/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}/messages`,
      { text },
    );
  };

  const markMessagesAsRead = async (
    artistUsername: string,
    marketplaceItemId: number,
    orderExternalId: string,
  ) => {
    return await axiosInstance.post(
      `/artists/${artistUsername}/products/marketplace_items/${marketplaceItemId}/orders/${orderExternalId}/messages/mark_as_read`,
    );
  };

  return { sendMessage, markMessagesAsRead };
};
