import React from 'react';

import Line from './styled/Line';
import Text from './styled/Text';
import View from './styled/View';

interface SeparatorTextProps {
  children?: React.ReactNode;
}

const SeparatorText: React.FC<SeparatorTextProps> = ({
  children,
  ...restProps
}: SeparatorTextProps) => {
  return (
    <View {...restProps}>
      {children ? (
        <>
          <Line />
          <Text>{React.Children.toArray(children)}</Text>
          <Line />
        </>
      ) : (
        <Line />
      )}
    </View>
  );
};

export default SeparatorText;
