import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen';

import ArtistsFeed from 'containers/ArtistsFeed';

import ArtistsSearchResults from 'components/ArtistsSearchResults';
import ScreenView from 'components/ScreenView';
import SearchBar from 'components/SearchBar';

import { useSearch } from 'hooks';
import { GroupType } from 'hooks/useSearch';
import { Artist, ArtistProduct } from 'types';

import { SCREEN_NAME } from './constants';
import Content from './styled/Content';
import HeaderContainer from './styled/HeaderContainer';
import ResultsWrapper from './styled/ResultsWrapper';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const DiscoverScreen: React.FC<Props> = () => {
  const navigation = useNavigation();
  const {
    isLoading,
    artistsResults,
    groupType,
    searchMode,
    searchQuery,
    changeSearchMode,
    changeSearchQuery,
    onSearchCancel,
  } = useSearch();

  const navigateToArtist = (artist: Artist) => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      screen: ARTIST_PROFILE_SCREEN_NAME,
      artistUsername: artist.username,
      artist: artist,
    });
  };

  const navigateToProduct = (product: ArtistProduct) => {
    navigation.navigate(SINGLE_POST_SCREEN_NAME, {
      screen: SINGLE_POST_SCREEN_NAME,
      artistUsername: product.artist.username,
      productType: product.model_name,
      productId: product.id,
      product: product,
    });
  };

  return (
    <ScreenView>
      <HeaderContainer>
        <SearchBar
          value={searchQuery}
          onCancel={onSearchCancel}
          onChange={changeSearchQuery}
          onFocus={() => changeSearchMode(true)}
        />
      </HeaderContainer>
      <Content>
        {groupType === GroupType.Artists && (
          <ArtistsFeed navigateToProduct={navigateToProduct} navigateToArtist={navigateToArtist} />
        )}
        {searchMode && (
          <ResultsWrapper>
            {groupType === GroupType.Artists && (
              <ArtistsSearchResults data={artistsResults} onResultPress={navigateToArtist} />
            )}
          </ResultsWrapper>
        )}
      </Content>
    </ScreenView>
  );
};

DiscoverScreen.displayName = 'DiscoverScreen';

export default DiscoverScreen;
