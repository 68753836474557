export default {
  subscribed: {
    id: 'artist.profile.nameBadge.subscribed',
    defaultMessage: "You're a stan",
  },
  locked: {
    id: 'artist.profile.nameBadge.locked',
    defaultMessage: 'Locked',
  },
  canceled: {
    id: 'artist.profile.nameBadge.pendingCancelation',
    defaultMessage: 'Pending cancelation',
  },
};
