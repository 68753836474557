import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface SvgComponentProps {
  liked?: boolean;
}

const SvgComponent: React.FC<SvgComponentProps> = ({ liked, ...rest }: SvgComponentProps) => {
  return (
    <Svg width={24} height={22} viewBox="0 0 24 22" fill={liked ? '#FC440F' : 'none'} {...rest}>
      <Path
        d="M20.84 3.61a5.5 5.5 0 00-7.78 0L12 4.67l-1.06-1.06a5.501 5.501 0 00-7.78 7.78l1.06 1.06L12 20.23l7.78-7.78 1.06-1.06a5.501 5.501 0 000-7.78v0z"
        stroke={liked ? '#FC440F' : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
