import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Medium;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #0537ff;
  margin-left: 5px;
`;
