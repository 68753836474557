import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.text};
  ${Platform.OS !== 'web' &&
  css`
    padding: 0 20px;
    text-align: center;
  `}
`;
