import React, { useEffect, useMemo } from 'react';
import { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import Modal from './styled/Modal';
import OverlayView from './styled/OverlayView';
import Title from './styled/Title';

interface Props {
  isVisible: boolean;
  title: string;
  children: React.ReactNode;
}

const OptionModal: React.FC<Props> = ({ title, isVisible, children }) => {
  const memoizedOpacity = useMemo(() => (isVisible ? 1 : 0), [isVisible]);
  const opacity = useSharedValue(memoizedOpacity);

  const style = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value, {
      duration: 300,
      easing: Easing.out(Easing.quad),
    }),
  }));

  useEffect(() => {
    opacity.value = memoizedOpacity;
  }, [memoizedOpacity]);

  return (
    <OverlayView style={style} pointerEvents={isVisible ? 'auto' : 'none'}>
      <Modal>
        <Title>{title}</Title>
        {children}
      </Modal>
    </OverlayView>
  );
};

export default OptionModal;
