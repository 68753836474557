import { Octicons } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const IconMute: React.FC = ({ ...restProps }) => (
  <Octicons name="unmute" size={15} {...restProps} />
);

export default styled(IconMute)`
  color: ${(props) => props.theme.colors.primaryActive};
`;
