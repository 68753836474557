import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import Notifications from 'containers/Notifications';

import ScreenView from 'components/ScreenView';

import { VARIANT } from 'context/appInfo';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Title from './styled/Title';

export { SCREEN_NAME };

type ProfileScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ProfileScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ProfileScreenRouteProp;
  navigation: ProfileScreenNavigationProp;
};

const ProfileScreen: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <ScreenView>
      {VARIANT === 'artist' && <Title>{intl.formatMessage(messages.title)}</Title>}
      <Notifications />
    </ScreenView>
  );
};

export default ProfileScreen;
