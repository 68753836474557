import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const ArrowIcon: React.FC = ({ ...restProps }) => (
  <MaterialIcons name="navigate-next" {...restProps} />
);

export default styled(ArrowIcon).attrs({ size: 24 })`
  color: ${({ theme }) => theme.colors.primaryActive};
`;
