import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 0 7px;
`;
