import * as React from 'react';

export interface UseFakeLoading {
  isFakeLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFakeLoading(timeoutOffset: number, mockValue?: any): UseFakeLoading {
  const [isFakeLoading, setFakeLoading] = React.useState<boolean>(!!mockValue);

  React.useEffect(() => {
    if (mockValue) {
      const handler = setTimeout(() => {
        setFakeLoading(false);
      }, timeoutOffset);

      return () => {
        clearTimeout(handler);
      };
    }
  }, []);

  return {
    isFakeLoading,
  };
}
