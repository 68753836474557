import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Semibold';
  font-size: 17px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${({ theme }) => theme.colors.text};
`;
