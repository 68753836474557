import styled, { css } from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: #ffffff;
  margin-bottom: 32px;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      text-align: center;
    `}
`;
