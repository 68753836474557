import { useIsFocused, useScrollToTop } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ViewabilityConfigCallbackPairs, ViewToken } from 'react-native';

import LiveMoments from 'containers/LiveMoments';

import AgeRestrictionDialog from 'components/AgeRestrictionDialog';
import FetchedDataContainer from 'components/FetchedDataContainer';

import { useFeedHandlers, useFeedState } from 'context/feed';
import { useConfig } from 'hooks';
import { useUser } from 'hooks/user';
import { ArtistProduct } from 'types';
import { isAgeRestrictedUser } from 'utils/user';

import messages from './messages';
import FeedIsEnded from './styled/FeedIsEnded';
import FeedItem from './styled/FeedItem';
import FeedItemPlaceholder from './styled/FeedItemPlaceholder';
import FlatList from './styled/FlatList';

export interface FeedProps {}

const Feed: React.FC<FeedProps> = () => {
  const intl = useIntl();
  const isNavigationFocused = useIsFocused();
  const { config } = useConfig();
  const { profile, updateGeneralInformation } = useUser();

  const { isLoadingInitial, hasReachedEnd, feed, error } = useFeedState();
  const { fetchMore, refresh: refreshFeed } = useFeedHandlers();

  const activateRefs = useRef<Record<string, (() => void) | null>>({});
  const deactivateRefs = useRef<Record<string, (() => void) | null>>({});
  const activeIndices = useRef<number[]>([]);
  const autoPlayRef = useRef(config.feedAutoPlayVideos);
  const [startMuted, setStartMuted] = useState(true);
  const flatListRef = useRef<any>();
  useScrollToTop(flatListRef);
  const showAgeRestrictionDialog = useMemo(
    () => isAgeRestrictedUser(profile) && !profile?.state_age_restriction_feed_is_seen,
    [profile],
  );

  useEffect(() => {
    if (isNavigationFocused) {
      if (autoPlayRef.current) {
        activeIndices.current.forEach((activeIndex) => {
          const activateFn = activateRefs.current[activeIndex.toString()];
          activateFn?.();
        });
      }
    } else {
      activeIndices.current.forEach((activeIndex) => {
        const deactivateFn = deactivateRefs.current[activeIndex.toString()];
        deactivateFn?.();
      });
    }
  }, [isNavigationFocused, autoPlayRef.current]);

  const refActivate = useCallback(
    (index: number, activateFn: (() => void) | null) => {
      activateRefs.current[index.toString()] = activateFn;
    },
    [activateRefs.current],
  );

  const refDeactivate = useCallback(
    (index: number, deactivateFn: (() => void) | null) => {
      deactivateRefs.current[index.toString()] = deactivateFn;
    },
    [deactivateRefs.current],
  );

  const extractKey = useCallback(
    (product: unknown) =>
      `${(product as ArtistProduct).model_name}-${(product as ArtistProduct).id}`,
    [],
  );

  const onRefresh = useCallback(() => {
    refreshFeed();
  }, []);

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      info.changed.forEach((changed) => {
        if (typeof changed.index === 'number') {
          if (changed.isViewable) {
            activeIndices.current.push(changed.index);
            const activateFn = activateRefs.current[changed.index.toString()];
            autoPlayRef.current && activateFn?.();
            console.log('[activate]', autoPlayRef.current);
          } else {
            activeIndices.current = activeIndices.current.filter((item) => item !== changed.index);
            const deactivateFn = deactivateRefs.current[changed.index.toString()];
            deactivateFn?.();
          }
        }
      });
    },
    // All the dependencies are refs, so we don't need to state them.
    [],
  );

  const onMutedChange = useCallback((isMuted: boolean) => setStartMuted(isMuted), [setStartMuted]);

  const onIsPlayingChange = useCallback(
    (isPlaying: boolean) => {
      console.log('[autoplay]', isPlaying, config.feedAutoPlayVideos);
      autoPlayRef.current = isPlaying && config.feedAutoPlayVideos;
    },
    [config],
  );

  const viewabilityConfigPairs: ViewabilityConfigCallbackPairs = useMemo(
    () => [
      {
        viewabilityConfig: {
          minimumViewTime: 10,
          waitForInteraction: false,
          itemVisiblePercentThreshold: 60,
        },
        onViewableItemsChanged,
      },
    ],
    [onViewableItemsChanged],
  );

  const onCloseAgeDialog = useCallback(() => {
    updateGeneralInformation({ state_age_restriction_feed_is_seen: true });
  }, [profile?.state_age_restriction_feed_is_seen]);

  const renderFeedEnd = useCallback(() => {
    if (hasReachedEnd) {
      return <FeedIsEnded>{intl.formatMessage(messages.feedEnd)}</FeedIsEnded>;
    }
    return <FeedItemPlaceholder />;
  }, [hasReachedEnd]);

  const renderFeedItem = useCallback(
    (args: { item: unknown; index: number }) => {
      const { item, index } = args;
      const product = item as ArtistProduct;
      const mediaOptions = {
        // `autoPlay` needs to be set to false because otherwise the video will start playing even
        // when it's hidden far below in the feed. On the Feed, `activate` and `deactivate`
        // functions are used instead.
        autoPlay: false,
        startMuted,
        dataIndex: index,
        refActivate,
        refDeactivate,
        onIsPlayingChange,
        onMutedChange,
      };

      return (
        <FeedItem
          product={product}
          audioOptions={mediaOptions}
          videoOptions={{ ...mediaOptions, shouldLoop: false }}
          reactionsEnabled={true}
          commentingEnabled={true}
          tippingEnabled={true}
          bookmarkingEnabled={true}
          sharingEnabled={true}
        />
      );
    },
    [startMuted, refActivate, refDeactivate, onIsPlayingChange, onMutedChange],
  );

  return (
    <FetchedDataContainer isLoading={isLoadingInitial || !!error} isError={false}>
      {showAgeRestrictionDialog && (
        <AgeRestrictionDialog isVisible={showAgeRestrictionDialog} onPress={onCloseAgeDialog} />
      )}
      <FlatList
        ref={flatListRef}
        data={feed}
        extraData={feed}
        keyExtractor={extractKey}
        renderItem={renderFeedItem}
        ListHeaderComponent={(config.feedLiveMomentsEnabled && LiveMoments) || null}
        ListFooterComponent={renderFeedEnd}
        refreshing={false}
        viewabilityConfigCallbackPairs={viewabilityConfigPairs}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        onRefresh={onRefresh}
        onEndReachedThreshold={0.8}
        onEndReached={fetchMore}
      />
    </FetchedDataContainer>
  );
};

export default Feed;
