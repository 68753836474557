import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import ProfileAvatar from 'containers/ProfileAvatar';
import ProfileInformationsForm from 'containers/ProfileInformationsForm';
import ProfilePasswordForm from 'containers/ProfilePasswordForm';

import FetchedDataContainer from 'components/FetchedDataContainer/FetchedDataContainer';
import KeyboardAvoidingView from 'components/KeyboardAvoidingView';

import { VARIANT } from 'context/appInfo';
import { useSnackbarSet } from 'context/snackbar';
import { useUser } from 'hooks';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ScreenView from './styled/ScreenView';
import ScrollView from './styled/ScrollView';
import Section from './styled/Section';
import SectionTitle from './styled/SectionTitle';

export { SCREEN_NAME };

type ProfileSettingsScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ProfileSettingsScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ProfileSettingsScreenRouteProp;
  navigation: ProfileSettingsScreenNavigationProp;
};

const ProfileSettingsScreen: React.FC<Props> = () => {
  const {
    error,
    isLoading,
    isUpdatingGeneral,
    isUpdatingPassword,
    isUpdatingPicture,
    profile,
    updateInformationError,
    updatePasswordError,
    updateGeneralInformation,
    updatePassword,
  } = useUser();

  const { setSnackbar } = useSnackbarSet();

  const intl = useIntl();

  const submitPasswordUpdate = useCallback(
    (oldPassword: string, newPassword: string) =>
      updatePassword(oldPassword, newPassword, () =>
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.passwordUpdated),
          actionLabel: intl.formatMessage(messages.close),
          dismissAfter: 5000,
        }),
      ),
    [updatePassword, setSnackbar],
  );

  return (
    <ScreenView>
      <FetchedDataContainer isLoading={isLoading} isError={!!error}>
        <KeyboardAvoidingView fill={false}>
          <ScrollView>
            <ProfileAvatar
              profilePicture={profile?.profile_picture}
              cover={profile?.cover_image}
              isUpdating={isUpdatingPicture}
              update={updateGeneralInformation}
            />
            <Section>
              <SectionTitle>{intl.formatMessage(messages.generalInfoTitle)}</SectionTitle>
              <ProfileInformationsForm
                username={profile?.username}
                fullName={VARIANT === 'artist' ? profile?.display_name : profile?.full_name}
                email={profile?.email}
                bio={profile?.bio}
                link={profile?.secondary_link}
                isUpdating={isUpdatingGeneral}
                updateError={updateInformationError}
                update={updateGeneralInformation}
              />
            </Section>

            <Section>
              <SectionTitle>{intl.formatMessage(messages.passwordTitle)}</SectionTitle>
              <ProfilePasswordForm
                isUpdating={isUpdatingPassword}
                updateError={updatePasswordError}
                update={submitPasswordUpdate}
              />
            </Section>
          </ScrollView>
        </KeyboardAvoidingView>
      </FetchedDataContainer>
    </ScreenView>
  );
};

ProfileSettingsScreen.displayName = 'ProfileSettingsScreen';

export default ProfileSettingsScreen;
