import styled from 'styled-components/native';

export default styled.Text.attrs(() => ({
  numberOfLines: 1,
}))`
  color: ${({ theme }) => theme.orderDetailsHeader.secondaryText};
  font-family: BasierCircle-Regular;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 3px;
  letter-spacing: -0.08px;
`;
