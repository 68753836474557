import React from 'react';

import ViewersCountIcon from 'components/ArtistProfileHeader/styled/ViewersCountIcon';

import LiveBadge from './styled/LiveBadge';

interface Props {
  count?: number | null;
  past?: boolean;
}

const ViewersCountBadge: React.FC<Props> = ({ count, past, ...rest }: Props) => (
  <LiveBadge
    highlight={!past}
    text={`${count || 0} ${past ? 'Viewers' : ''}`}
    icon={<ViewersCountIcon />}
    {...rest}
  />
);

export default ViewersCountBadge;
