import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';

import List from 'components/ArtistsList/styled/List';
import Separator from 'components/ArtistsList/styled/Separator';
import FetchedDataContainer from 'components/FetchedDataContainer';

import { useActiveFollowing, useActiveSubscriptions } from 'hooks';
import { ActiveSubscription, Artist } from 'types';
import { parseCurrency } from 'utils/currency';
import { getProfileLowResPictureUrl } from 'utils/user';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Action from './styled/Action';
import Avatar from './styled/Avatar';
import Item from './styled/Item';
import ScreenView from './styled/ScreenView';
import SearchBar from './styled/SearchBar';
import Tab from './styled/Tab';
import TabView from './styled/TabView';

export { SCREEN_NAME };

type SubscribedProfilesScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type SubscribedProfilesScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: SubscribedProfilesScreenRouteProp;
  navigation: SubscribedProfilesScreenNavigationProp;
};

interface SubscribedArtist {
  artist: Artist;
  price?: string;
  price_currency?: string;
}

const SubscribedProfilesScreen: React.FC<Props> = ({ navigation }: Props) => {
  const {
    error,
    isLoading,
    activeSubscriptions,
    mutate: mutateSubscriptions,
  } = useActiveSubscriptions();
  const { activeFollowing, mutate: mutateFollowing } = useActiveFollowing();
  const intl = useIntl();
  const [mode, setMode] = useState<'subscribed' | 'followed'>('subscribed');

  const [searchQuery, setSearchQuery] = useState<string>('');

  const toggleMode = useCallback(() => {
    setMode((prevState) => (prevState === 'subscribed' ? 'followed' : 'subscribed'));
  }, [setMode]);

  const displayedArtists = useMemo(() => {
    if (activeSubscriptions || activeFollowing) {
      const mappedArtists: SubscribedArtist[] =
        mode === 'subscribed'
          ? activeSubscriptions
            ? activeSubscriptions.map(
                (subscription: ActiveSubscription) =>
                  subscription?.plan_price?.plan as SubscribedArtist,
              )
            : []
          : activeFollowing
          ? activeFollowing.map((artist: Artist) => ({
              artist,
            }))
          : [];

      if (!searchQuery) {
        return mappedArtists;
      }

      return mappedArtists.filter((_) => new RegExp(searchQuery, 'i').test(_.artist.display_name));
    }

    return [];
  }, [activeSubscriptions, activeFollowing, searchQuery, mode]);

  useEffect(() => {
    const refresh = () => {
      mutateSubscriptions();
      mutateFollowing();
    };
    navigation.addListener('focus', refresh);
    return () => {
      navigation.removeListener('focus', refresh);
    };
  }, [navigation]);

  const navigateToArtist = useCallback(
    (artist: Artist) => {
      navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
        artistUsername: artist.username,
        artist: artist,
      });
    },
    [navigation],
  );

  const extractKey = (item: unknown): string => `${(item as SubscribedArtist).artist.username}`;

  const renderArtistItem = ({ item }: { item: unknown }) => {
    const typedItem = item as SubscribedArtist;

    return (
      <Item>
        <Avatar
          mode="minimal-row-big"
          name={typedItem.artist.display_name}
          isLive={typedItem.artist.is_live}
          imageUrl={getProfileLowResPictureUrl(typedItem.artist)}
          onPress={() => navigateToArtist(typedItem.artist)}
        />
        <Action onPress={() => navigateToArtist(typedItem.artist)}>
          {mode === 'subscribed'
            ? typedItem.price && typedItem.price_currency
              ? `${parseCurrency(typedItem.price_currency)}${parseFloat(typedItem.price).toFixed(
                  2,
                )}${intl.formatMessage(messages.perMonth)}`
              : intl.formatMessage(messages.subscribed)
            : intl.formatMessage(messages.followed)}
        </Action>
      </Item>
    );
  };

  return (
    <ScreenView>
      <FetchedDataContainer isLoading={isLoading} isError={!!error}>
        <SearchBar
          onCancel={() => setSearchQuery('')}
          onChange={(query: string) => setSearchQuery(query)}
          value={searchQuery}
        />
        <TabView>
          <Tab onPress={toggleMode} active={mode === 'subscribed'}>
            {intl.formatMessage(messages.subscribed)}
          </Tab>
          <Tab onPress={toggleMode} active={mode === 'followed'}>
            {intl.formatMessage(messages.followed)}
          </Tab>
        </TabView>
        <List
          data={displayedArtists}
          extraData={displayedArtists}
          keyExtractor={extractKey}
          renderItem={renderArtistItem}
          ItemSeparatorComponent={Separator}
        />
      </FetchedDataContainer>
    </ScreenView>
  );
};

export default SubscribedProfilesScreen;
