import { parseDigits } from 'utils/payments';

export const uriWithoutParams = (str: string): string => {
  const i = str.indexOf('?');
  return i > -1 ? str.substring(0, i) : str;
};

export const formatBirthday = (str: string): string => {
  let formattedStr = parseDigits(str);
  if (formattedStr.length > 4) {
    formattedStr = `${formattedStr.slice(0, 2)}/${formattedStr.slice(2, 4)}/${formattedStr.slice(
      4,
      formattedStr.length,
    )}`;
  } else if (formattedStr.length > 2) {
    formattedStr = `${formattedStr.slice(0, 2)}/${formattedStr.slice(2, formattedStr.length)}`;
  }
  return formattedStr.substring(0, 10);
};
