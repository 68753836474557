import React, { useCallback } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Fade from 'components/Transitions/Fade/Fade';

import ItemLabel from './styled/ItemLabel';
import ItemTextBackground from './styled/ItemTextBackground';
import ItemTouchable from './styled/ItemTouchable';
import TabView from './styled/TabView';
import Text from './styled/Text';
import View from './styled/View';

interface TabBarProps {
  tabs: (string | JSX.Element)[];
  activeIndex: number;
  activeBackground?: string;
  fit?: boolean;
  onTabPress?: (index: number) => void;
  tabLabelStyle?: StyleProp<TextStyle>;
  tabTouchableStyle?: StyleProp<ViewStyle>;
  activeLabelColor?: string;
  inactiveLabelColor?: string;
  center?: boolean;
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  activeIndex,
  activeBackground,
  onTabPress,
  tabLabelStyle,
  tabTouchableStyle,
  activeLabelColor,
  inactiveLabelColor,
  center = true,
  ...restProps
}: TabBarProps) => {
  const renderTab = useCallback(
    (tab: string | JSX.Element, index: number) => {
      if (typeof tab === 'string') {
        return (
          <Text
            active={index === activeIndex}
            activeLabelColor={activeLabelColor}
            inactiveLabelColor={inactiveLabelColor}
          >
            {tab}
          </Text>
        );
      }

      return <TabView>{tab}</TabView>;
    },
    [activeIndex, activeLabelColor, inactiveLabelColor],
  );

  return (
    <View {...restProps} center={center}>
      {tabs.map((tab, index) => (
        <ItemTouchable
          style={tabTouchableStyle}
          key={`tab-${index}`}
          active={index == activeIndex}
          disabled={index == activeIndex}
          onPress={() => onTabPress && onTabPress(index)}
        >
          {index == activeIndex && (
            <Fade fill>
              <ItemTextBackground active activeBackground={activeBackground} />
            </Fade>
          )}
          <ItemLabel
            style={tabLabelStyle}
            active={index == activeIndex}
            activeLabelColor={activeLabelColor}
            inactiveLabelColor={inactiveLabelColor}
          >
            {renderTab(tab, index)}
          </ItemLabel>
        </ItemTouchable>
      ))}
    </View>
  );
};

export default TabBar;
