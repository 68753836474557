import React, { memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';

import LockedItem from 'components/LockedItem';
import { LockedItemProps } from 'components/LockedItem/LockedItem';

import { useFeedItemUpdate } from 'context';
import { usePoll } from 'hooks';
import { Cover, FeedItemUpdateFn, PaymentModel } from 'types';
import { PollAnswer } from 'types/PollAnswers';
import { PollProduct } from 'types/PollProduct';

import AnswerContainer from './styled/AnswerContainer';
import AnswerPercent from './styled/AnswerPercent';
import AnswerText from './styled/AnswerText';
import Background from './styled/Background';
import Checkmark from './styled/CheckMark';
import Gradient from './styled/Gradient';
import Section from './styled/Section';
import Title from './styled/Title';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';

export interface FeedItemPollOptions {
  answerable?: boolean;
  showResults?: boolean;
}

export interface FeedItemPollContentProps extends FeedItemPollOptions {
  access?: {
    hasAccess: boolean;
  };
  showControlsBelowContent?: boolean;
  product: PollProduct;
  lockedItemProps?: LockedItemProps;
}

const FeedItemPollContent: React.FC<FeedItemPollContentProps> = ({
  access,
  answerable = true,
  showControlsBelowContent,
  product,
  showResults,
  lockedItemProps,
  ...restProps
}: FeedItemPollContentProps) => {
  const { updateFeedItem } = useFeedItemUpdate();
  const { toggleVote } = usePoll(product, updateFeedItem);
  const intl = useIntl();

  const hasBeenVoted = useMemo(
    () => product.answers?.some((a) => a.is_voted) || showResults,
    [product.answers, showResults],
  );

  const onAnswerPress = useCallback(
    (id: number) => () => {
      toggleVote(id);
    },
    [toggleVote],
  );

  const renderReadableAnswer = (answer: PollAnswer, index: number) => (
    <AnswerContainer key={`readable-answer-${index}`} voted={hasBeenVoted} index={index}>
      {hasBeenVoted && <Gradient percent={answer.votes_percentage || 0} />}
      <AnswerText>{answer.text}</AnswerText>
      {answer.is_voted && <Checkmark />}
      {hasBeenVoted && <AnswerPercent>{answer.votes_percentage || 0}%</AnswerPercent>}
    </AnswerContainer>
  );

  const renderVotableAnswer = (answer: PollAnswer, index: number) => (
    <TouchableOpacity onPress={onAnswerPress(answer.id)} key={`votable-answer-${index}`}>
      {renderReadableAnswer(answer, index)}
    </TouchableOpacity>
  );

  const renderAnswers = () =>
    product.answers
      .sort((a, b) => (a.id >= b.id ? 1 : -1))
      .map((answer: PollAnswer, index: number) =>
        answerable && !answer.is_voted
          ? renderVotableAnswer(answer, index)
          : renderReadableAnswer(answer, index),
      );

  if (access?.hasAccess === false) {
    return <LockedItem type="poll" {...lockedItemProps} />;
  }

  return (
    <View {...restProps}>
      <Background showControlsBelowContent={showControlsBelowContent}>
        <Section>
          <Title>
            {intl.formatMessage({
              id: 'post.poll.q',
              defaultMessage: 'Q:',
            })}{' '}
            {product.question}
          </Title>
        </Section>
        <Section>{renderAnswers()}</Section>
      </Background>
    </View>
  );
};

export default memo(FeedItemPollContent, isEqual);
