import styled from 'styled-components/native';

export default styled.Text`
  font-size: 34px;
  line-height: 41px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: center;
  margin-top: 8.2%;
  margin-bottom: 6.3%;
  letter-spacing: 0.41px;
  font-family: BasierCircle-Medium;
`;
