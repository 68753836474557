import styled from 'styled-components/native';

export default styled.Text`
  font-size: 16px;
  letter-spacing: -0.23px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  display: block;
`;
