export default {
  title: {
    id: 'resetPassword.title',
    defaultMessage: 'Create password',
  },
  resetPasswordCta: {
    id: 'resetPassword.cta',
    defaultMessage: 'Change password',
  },
  resetPasswordRequired: {
    id: 'resetPassword.resetPasswordRequired',
    defaultMessage: 'Your password must be changed.',
  },
  passwordInput: {
    label: {
      id: 'resetPassword.passwordInput.label',
      defaultMessage: 'Password',
    },
    placeholder: {
      id: 'resetPassword.passwordInput.placeholder',
      defaultMessage: 'Password',
    },
    requirements: {
      id: 'resetPassword.passwordInput.error.requirements',
      defaultMessage: 'Password must be at least 8 characters long and contain:',
    },
    error: {
      invalidLength: {
        id: 'resetPassword.passwordInput.error.invalidLength',
        defaultMessage: 'must be at least 8 characters long',
      },
      missingUpperOrLowerCase: {
        id: 'resetPassword.passwordInput.error.missingUpperOrLowerCase',
        defaultMessage: 'One upper/lower case letter',
      },
      missingNumber: {
        id: 'resetPassword.passwordInput.error.missingNumber',
        defaultMessage: 'One numeric character',
      },
      missingSpecialCharacter: {
        id: 'resetPassword.passwordInput.error.missingSpecialCharacter',
        defaultMessage: 'One special character',
      },
    },
  },
  error: {
    token_invalid_or_expired: {
      id: 'resetPassword.error.token.invalid',
      defaultMessage: 'Token is either invalid or expired',
    },
    reset_password_failed: {
      id: 'resetPassword.error.failed',
      defaultMessage: 'Error has occurred.',
    },
  },
};
