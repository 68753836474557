import styled from 'styled-components/native';

import handsup from '../images/handsup.png';

const StyledBecameStanIcon = styled.Image.attrs({
  resizeMode: 'contain',
  source: handsup,
})`
  width: 23px;
  height: 20px;
  margin-left: 7px;
`;

export default StyledBecameStanIcon;
