import { useMemo } from 'react';
import { ImageSourcePropType, ImageURISource } from 'react-native';

export function isUriSource(src: ImageSourcePropType): src is ImageURISource {
  return (<ImageURISource>src).uri !== undefined;
}

/**
 * Google cloud returns images with hashed signature which is different every request,
 * so we only compare the base image uri to prevent reloading
 */
export const useMemoizedImage = (source?: ImageSourcePropType): ImageSourcePropType => {
  const comparator = useMemo(() => {
    const src = source as ImageURISource;
    return src?.uri ? src.uri.split('?', 1)[0] : '';
  }, [source]);
  const memo = useMemo(() => ({ uri: (source as ImageURISource)?.uri || '' }), [comparator]);

  if (!source) return { uri: '' };
  if (!isUriSource(source)) return source;
  else return memo;
};
