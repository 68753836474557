import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

// configure and initialize Firebase for web (copy this from the Firebase Console Project Settings for the exact values)
const firebaseConfig = {
  apiKey: 'AIzaSyC3m-RrO38hAoVu1yth9q7h8ayalcuwTjc',
  authDomain: 'stanbase-c16b.firebaseapp.com',
  projectId: 'stanbase-c16b',
  storageBucket: 'stanbase-c16b.appspot.com',
  messagingSenderId: '358906291645',
  appId: '1:358906291645:web:60e29ffd8bd4d646f15ed4',
  measurementId: 'G-B0XJZ8JHSQ',
};

const app = initializeApp(firebaseConfig);
const webAnalytics = getAnalytics(app);

export async function sendAnalyticsEventAsync(name: string, params: { [key: string]: any }) {
  await logEvent(webAnalytics, name, params);
}
