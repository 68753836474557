import React from 'react';
import styled from 'styled-components/native';

import { parseCurrency, PRIMARY_CURRENCY } from 'utils/currency';

interface ViewInterface {
  height: number;
}

const View = styled.View<ViewInterface>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding-left: 19px;
  margin-top: 25px;
  align-self: center;
`;

const InputText = styled.TextInput`
  width: 100%;
  height: 80%;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
`;

const Text = styled.Text`
  color: rgba(158, 158, 158, 1);
  margin: 0 13px 0 0px;
  font-size: 15px;
`;

interface InputProps {
  type?: 'default' | 'numeric';
  isDescription?: boolean;
  isPrice?: boolean;
  isEditable?: boolean;
  height?: number;
  placeholder?: string;
  value?: string;
  onChangeValue?: any;
}

const Input: React.FC<InputProps> = ({
  type = 'default',
  isDescription = false,
  isPrice = false,
  isEditable = true,
  height = 65,
  placeholder,
  value,
  onChangeValue,
}: InputProps) => {
  return (
    <View height={height}>
      {isPrice && <Text>{parseCurrency(PRIMARY_CURRENCY)}</Text>}
      <InputText
        placeholder={placeholder}
        onChangeText={onChangeValue}
        value={value}
        keyboardType={type}
        placeholderTextColor="rgba(255, 255, 255, 0.52)"
        editable={isEditable}
        textAlignVertical={isDescription ? 'top' : 'center'}
      />
    </View>
  );
};

export default Input;
