import React from 'react';

import Text from 'components/Text';

import Container from './styled/Container';
import Switch from './styled/Switch';

interface Props {
  onChange?: (checked: boolean) => void;
  checked: boolean;
  label?: string;
}

const CheckBox: React.FC<Props> = ({ onChange, checked, label, ...rest }: Props) => {
  return (
    <Container {...rest}>
      {label && <Text>{label}</Text>}
      <Switch onValueChange={onChange} value={checked} />
    </Container>
  );
};

export default CheckBox;
