import React from 'react';

import Container from './styled/Container';
import CopyRights from './styled/CopyRights';
import Footer from './styled/Footer';

interface Props {
  children: React.ReactNode;
  textColor: string;
}

const WebFooter: React.FC<Props> = ({ children, textColor, ...props }: Props) => {
  return (
    <Footer {...props}>
      <CopyRights textColor={textColor}>©Stanbase 2022</CopyRights>
      <Container>{children}</Container>
    </Footer>
  );
};

export default WebFooter;
