export default {
  action: {
    id: 'order_attachment.download',
    defaultMessage: 'Download attachment',
  },
  sentAt: {
    id: 'order_attachment.sentAt',
    defaultMessage: 'Sent at {date}',
  },
  attachment: {
    saved: {
      id: 'order_attachment.attachment.saved',
      defaultMessage: 'Attachment has been saved.',
    },
    error: {
      id: 'order_attachment.attachment.error',
      defaultMessage: 'Error saving attachment.',
    },
  },
  closeSnackbar: {
    id: 'order_attachment.closeSnackbar',
    defaultMessage: 'Close',
  },
};
