import { TextInput } from 'react-native-paper';
import styled from 'styled-components/native';

export default styled(TextInput).attrs(({ theme }) => ({
  mode: 'outlined',
  dense: true,
  placeholderTextColor: theme.colors.primaryInactive,
  underlineColor: 'transparent',
  autoCorrect: false,
  theme: {
    roundness: 10,
    colors: {
      borderColor: theme.textInput.borderColor,
      placeholder: theme.colors.primaryInactive,
      text: theme.colors.text,
      primary: theme.colors.text,
      background: theme.textInput.background,
      underline: 'transparent',
    },
  },
}))`
  flex-grow: 1;
  padding: 0 16px;
  margin: 0 10px 19px 10px;
`;
