export default {
  betaInfo: {
    id: 'artist.profile.beta',
    defaultMessage:
      'Web app is still on Beta, please use app to see artist’s updates and published content.',
  },
  paymentSheetTitle: {
    id: 'artist.profile.subscriptionPaymentSheet.title',
    defaultMessage: 'Become a Stan',
  },
  paymentSheetDescription: {
    id: 'artist.profile.subscriptionPaymentSheet.description',
    defaultMessage: 'Subscribe to {artistName} and get access to exclusive Stan only content!',
  },
  resubscriptionPaymentSheetTitle: {
    id: 'subscription.resubscriptionPaymentSheetTitle',
    defaultMessage: 'Keep your Stan badge',
  },
  resubscriptionPaymentSheetDescription: {
    id: 'subscription.resubscriptionPaymentSheetDescription',
    defaultMessage: 'You still have access to Fan content till {tillDate}',
  },
  unsubscribeModalOptions: {
    confirm: {
      id: 'artist.profile.unsubscribeOptions.confirm',
      defaultMessage: 'Unsubscribe',
    },
    dismiss: {
      id: 'artist.profile.unsubscribeOptions.dismiss',
      defaultMessage: 'Cancel',
    },
  },
  emptyTab: {
    id: 'artist.profile.tab.empty',
    defaultMessage: 'There are no items in this category.',
  },
  goBack: {
    id: 'artist.profile.goBack',
    defaultMessage: 'Go home',
  },
  error: {
    notFound: {
      id: 'artist.profile.error.notFound',
      defaultMessage: "It looks like artist {username} doesn't exist. 🙁",
    },
  },
  logoutUser: {
    id: 'artist.profile.logoutUser',
    defaultMessage: 'To see full artist profile sign up here',
  },
};
