import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import PreFormScreen from 'screens/PreFormScreen';
import { SCREEN_NAME as PRESS_FORM } from 'screens/PressFormScreen/constants';

import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation: ScreenNavigationProp;
};

const PressScreen: React.FC<Props> = ({ navigation }: Props) => (
  <PreFormScreen
    messages={messages}
    navigation={navigation}
    destination={PRESS_FORM}
    breadcumb="Press"
  />
);

export default PressScreen;
