import cardValidator from 'card-validator';
import * as InAppPurchases from 'expo-in-app-purchases';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREN_NAME } from 'screens/EditPaymentMethodScreen/constants';

import { useFeedHandlers } from 'context';
import { useCvvModal } from 'context/cvvModal';
import { WebviewContext } from 'context/webview';
import {
  useArtist,
  useFeeBasedPostPayment,
  useMarketplacePayment,
  useSubscribe,
  useSubscriptionPlan,
  useTipping,
  useUnsubscribe,
  useUser,
} from 'hooks';
import { PaidProduct, PaymentMethod } from 'types';
import { parseCurrency } from 'utils/currency';
import { privacyPolicyURL, termsURL } from 'utils/links';
import { getAge } from 'utils/user';

import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import AddPaymentMethodCtaText from './styled/AddPaymentMethodCtaText';
import AddPaymentMethodTouchable from './styled/AddPaymentMethodTouchable';
import BackButton from './styled/BackButton';
import ContentInnerView from './styled/ContentInnerView';
import CreditCardIcon from './styled/CreditCardIcon';
import CvvTextInput from './styled/CvvTextInput';
import DescriptionText from './styled/DescriptionText';
import DisclaimerText from './styled/DisclaimerText';
import Divider from './styled/Divider';
import IconCheck from './styled/IconCheck';
import PayButton from './styled/PayButton';
import PaymentMethodName from './styled/PaymentMethodName';
import PaymentMethodNameView from './styled/PaymentMethodNameView';
import PaymentMethodsHeaderView from './styled/PaymentMethodsHeaderView';
import PaymentMethodsListTitleText from './styled/PaymentMethodsListTitleText';
import PaymentMethodsListView from './styled/PaymentMethodsListView';
import PaymentMethodsView from './styled/PaymentMethodsView';
import PaymentMethodTouchable from './styled/PaymentMethodTouchable';
import PaymentMethodView from './styled/PaymentMethodView';
import PriceText from './styled/PriceText';
import RestrictionText from './styled/RestrictionText';
import ResultIconCircle from './styled/ResultIconCircle';
import ResultIconError from './styled/ResultIconError';
import ResultIconSuccess from './styled/ResultIconSuccess';
import ResultText from './styled/ResultText';
import ResultView from './styled/ResultView';
import TitleText from './styled/TitleText';
import TitleView from './styled/TitleView';

interface PaymentContainerProps {
  navigationRef: any;
  product?: PaidProduct;
  title?: string;
  description?: string;
  isLoadingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
  iapError?: string;
  setIapError: (value?: string) => void;
  setIsOpen: (value: boolean) => void;
  onSuccess?: () => void;
  onBackPress?: () => void;
  onProcessingChange?: (isProcessing: boolean) => void;
}

const DEFAULT_PRODUCT_TYPE = 'marketplaceitem';
const PaymentContainer: React.FC<PaymentContainerProps> = ({
  navigationRef,
  product,
  title,
  description,
  isLoadingPaymentMethods,
  paymentMethods,
  iapError,
  setIapError,
  setIsOpen,
  onSuccess,
  onBackPress,
  onProcessingChange,
}: PaymentContainerProps) => {
  const intl = useIntl();
  const productType = useMemo(() => product?.type || DEFAULT_PRODUCT_TYPE, [product?.type]);
  const isSubscription = useMemo(
    () => !['tip', 'marketplaceitem'].includes(productType),
    [productType],
  );
  const [showRestrictionMessage, setShowRestrictionMessage] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [appleProductId, setAppleProductId] = useState<string>();
  const [price, setPrice] = useState<number | string>();
  const [isLoadingIAPProduct, setIsLoadingIAPProduct] = useState<boolean>(false);
  const [isRecurring, setIsRecurring] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(isSubscription);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
  const { subscriptionPlan, isLoading: isLoadingSubscriptionPlan } = useSubscriptionPlan(
    product?.artistUsername,
  );
  const { subscribeWithCard, subscribeWithApple, isSubscribing, subscribeError, subscribeData } =
    useSubscribe();
  const { payForOrder, paymentSuccess, paymentError, paymentInProgress } = useMarketplacePayment();
  const { resubscribe } = useUnsubscribe();
  const { openWebView } = useContext(WebviewContext);
  const isPayButtonDisabled = useMemo(() => {
    if (!product) return true;
    if (isLoadingPaymentMethods || paymentMethods.length === 0) return true;
    else return productType === 'subscription' ? isLoading || !selectedPaymentMethod : false;
  }, [
    isLoadingPaymentMethods,
    paymentMethods,
    isLoading,
    selectedPaymentMethod,
    product,
    productType,
  ]);
  const { payForTipWithCard, payForTipWithApple, tippingData, tippingError, isTipping } =
    useTipping();
  const { artist } = useArtist(product?.artistUsername || '');
  const {
    payForFeeBasedPostWithCard,
    payForPostWithApple,
    isPayingForPost,
    feeBasedPostPaymentData,
    feeBasedPostPaymentError,
  } = useFeeBasedPostPayment();
  const { profile } = useUser();
  const { cvv, setCvv } = useCvvModal();
  const age = useMemo(() => {
    return profile?.date_of_birth ? getAge(profile.date_of_birth) : 0;
  }, [profile]);
  const { refresh: refreshFeed } = useFeedHandlers();
  const isApplePaidSubscription = useMemo(
    () =>
      subscriptionPlan?.apple_product_id &&
      Platform.OS === 'ios' &&
      product?.type === 'subscription',
    [subscriptionPlan?.apple_product_id, product?.type],
  );

  useEffect(() => {
    setShowRestrictionMessage(age <= 18 && productType === 'subscription');
  }, [age]);

  useEffect(() => {
    onProcessingChange?.(isSubscribing || paymentInProgress || isTipping || isPayingForPost);
  }, [isSubscribing, paymentInProgress, isTipping, isPayingForPost]);

  useEffect(() => {
    if (product?.appleProductId) {
      setAppleProductId(product.appleProductId);
    }
  }, [product]);

  useEffect(() => {
    if (!subscriptionPlan && isSubscription) {
      setIsLoading(true);
      return;
    }

    if (['subscription', 'resubscription'].includes(productType) && subscriptionPlan) {
      if (Platform.OS !== 'ios') {
        setPrice(subscriptionPlan.price);
      }
      setAppleProductId(subscriptionPlan.apple_product_id);
      setIsRecurring(true);
      setIsLoading(false);
    }
  }, [product, subscriptionPlan, isSubscription]);

  useEffect(() => {
    const onSuccessfulPayment = async () => {
      await onSuccess?.();
      setTimeout(() => {
        setIsProcessing(false);
        setIapError(undefined);
        if (feeBasedPostPaymentData && !feeBasedPostPaymentError) {
          setIsOpen(false);
        }
      }, 4000);
    };
    if (product?.type === 'paid_post') {
      if (!isProcessing && isPayingForPost) {
        setIsProcessing(true);
      } else if (isProcessing && !isPayingForPost) {
        if (iapError === 'canceled') {
          setIsProcessing(false);
          setIapError(undefined);
        } else {
          onSuccessfulPayment();
        }
      }
    }
  }, [
    isPayingForPost,
    isProcessing,
    feeBasedPostPaymentData,
    feeBasedPostPaymentError,
    product?.type,
    iapError,
  ]);

  useEffect(() => {
    if (isApplePaidSubscription && subscriptionPlan?.apple_product_id) {
      setIsLoadingIAPProduct(true);
      InAppPurchases.getProductsAsync([subscriptionPlan.apple_product_id]).then(
        ({ results, errorCode }) => {
          if (results && !errorCode) {
            const product = results[0];
            console.log(JSON.stringify(product));
            setPrice(product.price);
          }
          setIsLoadingIAPProduct(false);
        },
      );
    }
  }, [subscriptionPlan?.apple_product_id]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length !== 0) {
      setSelectedPaymentMethod(
        paymentMethods.find((pm) => pm.creditCardInfo.is_primary) || paymentMethods[0],
      );
    }
  }, [paymentMethods]);

  useEffect(() => {
    const onSuccessfulPayment = async () => {
      await onSuccess?.();
      setTimeout(() => {
        setIsProcessing(false);
        setIapError(undefined);
        if (subscribeData && !subscribeError && !iapError) {
          setIsOpen(false);
        }
      }, 4000);
    };

    if (isSubscription) {
      if (!isProcessing && isSubscribing) {
        setIsProcessing(true);
      } else if (isProcessing && !isSubscribing) {
        if (iapError === 'canceled') {
          setIsProcessing(false);
          setIapError(undefined);
        } else {
          onSuccessfulPayment();
        }
      }
    }
  }, [isSubscribing, isProcessing, subscribeData, subscribeError, iapError, isSubscription]);

  //marketplace payment
  useEffect(() => {
    const onSuccessfulPayment = async () => {
      setTimeout(() => {
        setIsOpen(false);
        setIsProcessing(false);
      }, 4000);
      onSuccess?.();
    };

    if (paymentInProgress) {
      setIsProcessing(true);
    }
    if (paymentSuccess && !paymentError) {
      onSuccessfulPayment();
    }
  }, [
    paymentInProgress,
    isProcessing,
    paymentSuccess,
    paymentError,
    productType,
    onSuccess,
    setIsOpen,
  ]);

  useEffect(() => {
    const onSuccessfulPayment = async () => {
      await onSuccess?.();
      setTimeout(() => {
        setIsProcessing(false);
        setIapError(undefined);
        if (tippingData && !tippingError) {
          setIsOpen(false);
        }
      }, 4000);
    };

    if (productType === 'tip') {
      if (!isProcessing && isTipping) {
        setIsProcessing(true);
      } else if (isProcessing && !isTipping) {
        if (iapError === 'canceled') {
          setIsProcessing(false);
          setIapError(undefined);
        } else {
          onSuccessfulPayment();
        }
      }
    }
  }, [isTipping, isProcessing, tippingData, tippingError, productType, iapError]);

  useEffect(() => {
    const onSuccessfulPayment = async () => {
      await onSuccess?.();
      refreshFeed();
      setTimeout(() => {
        setIsProcessing(false);
        setIapError(undefined);
        if (feeBasedPostPaymentData && !feeBasedPostPaymentError) {
          setIsOpen(false);
        }
      }, 2000);
    };
    if (product?.type === 'paid_post') {
      if (!isProcessing && isPayingForPost) {
        setIsProcessing(true);
      } else if (isProcessing && !isPayingForPost) {
        if (iapError === 'canceled') {
          setIsProcessing(false);
          setIapError(undefined);
        } else {
          onSuccessfulPayment();
        }
      }
    }
  }, [
    isPayingForPost,
    isProcessing,
    feeBasedPostPaymentData,
    feeBasedPostPaymentError,
    product?.type,
    iapError,
  ]);

  const onAddPaymentMethodPress = useCallback(() => {
    if (navigationRef.current) {
      setIsOpen(false);
      navigationRef.current.navigate(ADD_PAYMENT_METHOD_SCREN_NAME, {
        onCardSaved: () => setIsOpen(true),
      });
    } else {
      console.error('navigation is not initialized');
    }
  }, [setIsOpen]);

  const onTermsCtaPress = useCallback(() => {
    if (openWebView) {
      openWebView(termsURL);
    }
  }, [openWebView]);

  const onPrivacyPolicyCta = useCallback(() => {
    if (openWebView) {
      openWebView(privacyPolicyURL);
    }
  }, [openWebView]);

  const onContinuePress = useCallback(() => {
    setShowRestrictionMessage(false);
  }, []);

  const onPayPress = useCallback(async () => {
    if (productType === 'tip') {
      if (
        !(
          product &&
          product.productId &&
          product.price &&
          product.modelNamePlural &&
          selectedPaymentMethod
        )
      ) {
        return;
      }
      payForTipWithCard({
        paymentMethod: selectedPaymentMethod,
        artistUsername: product.artistUsername,
        productId: product.productId,
        modelNamePlural: product.modelNamePlural,
        amount: product.price,
      });
    } else if (productType === 'paid_post') {
      if (
        !(
          product &&
          product.productId &&
          product.price &&
          product.modelNamePlural &&
          selectedPaymentMethod
        )
      ) {
        return;
      }

      payForFeeBasedPostWithCard({
        paymentMethod: selectedPaymentMethod,
        artistUsername: product.artistUsername,
        productId: product.productId,
        modelNamePlural: product.modelNamePlural,
        amount: product.price,
      });
    } else if (productType === 'subscription') {
      if (!(selectedPaymentMethod && product)) {
        return;
      }
      await subscribeWithCard({
        paymentMethod: selectedPaymentMethod,
        artistUsername: product.artistUsername,
      });
    } else if (productType === 'resubscription') {
      if (!product) {
        return;
      }
      await resubscribe(product.artistUsername);
      setIsOpen(false);
    } else if (
      product &&
      productType === 'marketplaceitem' &&
      product.productId &&
      selectedPaymentMethod
    ) {
      payForOrder(
        product.artistUsername,
        product.productId,
        product.orderExternalId || '',
        selectedPaymentMethod,
      );
    }
  }, [subscribeWithCard, resubscribe, payForOrder, payForTipWithCard, product]);

  const onApplePayPress = useCallback(async () => {
    if (product) {
      const { artistUsername, type, price, modelNamePlural, productId } = product;

      if (type === 'paid_post') {
        if (!(product.appleProductId && price && productId && modelNamePlural)) {
          return;
        }
        setIsProcessing(true);
        payForPostWithApple({
          amount: price,
          artistUsername,
          productId,
          modelNamePlural,
          appleProductId: product.appleProductId,
        });
      } else if (type === 'tip') {
        if (!(product.appleProductId && price && productId && modelNamePlural)) {
          return;
        }
        payForTipWithApple({
          amount: price,
          artistUsername,
          productId,
          modelNamePlural,
          appleProductId: product.appleProductId,
        });
      } else {
        if (appleProductId && artistUsername) {
          await subscribeWithApple({ appleProductId, artistUsername });
        }
      }
    }
  }, [
    subscriptionPlan,
    subscribeWithApple,
    payForTipWithApple,
    payForPostWithApple,
    product,
    appleProductId,
  ]);

  const onMethodPress = (method: PaymentMethod) => {
    setCvv('');
    setSelectedPaymentMethod(method);
  };

  const renderPaymentMethod = (method: PaymentMethod) => (
    <PaymentMethodTouchable
      key={method.creditCardInfo.external_id || method.creditCardInfo.number}
      onPress={() => onMethodPress(method)}
    >
      <PaymentMethodView>
        <PaymentMethodNameView>
          <CreditCardIcon
            cardType={cardValidator.number(method.creditCardInfo.number).card?.type}
          />
          <PaymentMethodName>
            {intl.formatMessage(messages.card, {
              lastFourDigits: method.creditCardInfo.last_four_digits,
            })}
          </PaymentMethodName>
        </PaymentMethodNameView>
        {selectedPaymentMethod?.creditCardInfo.external_id ===
          method.creditCardInfo.external_id && <IconCheck />}
      </PaymentMethodView>
    </PaymentMethodTouchable>
  );

  const isPaymentSuccess = () => {
    return (
      (productType === 'tip' && tippingData && !tippingError && !iapError) ||
      (productType === 'paid_post' &&
        feeBasedPostPaymentData &&
        !feeBasedPostPaymentError &&
        !iapError) ||
      (productType === 'marketplaceitem' && paymentSuccess && !paymentError) ||
      (isSubscription && subscribeData && !subscribeError && !iapError)
    );
  };

  const renderAgeRestrictionMessage = () => (
    <>
      <RestrictionText>
        {intl.formatMessage(messages.restrictionMessage, { artistName: artist?.display_name })}
      </RestrictionText>
      <PayButton onPress={onContinuePress} isRestricted={true}>
        {intl.formatMessage(messages.restrictionButton)}
      </PayButton>
    </>
  );

  const getResultMessage = () => {
    if (productType === 'marketplaceitem') {
      return isPaymentSuccess()
        ? intl.formatMessage(messages.result.successPayment)
        : paymentError?.message;
    } else if (productType === 'paid_post') {
      return isPaymentSuccess() ? 'Success' : 'Error';
    } else if (productType === 'tip') {
      return isPaymentSuccess()
        ? intl.formatMessage(messages.result.successTipPayment)
        : tippingError;
    } else {
      return isPaymentSuccess()
        ? intl.formatMessage(messages.result.successSubscription)
        : subscribeError;
    }
  };

  const renderProcessing = () => (
    <>
      {isSubscribing ||
      iapError === 'canceled' ||
      !productType ||
      paymentInProgress ||
      isTipping ||
      isPayingForPost ? (
        <ActivityIndicator noMargin withPadding />
      ) : (
        <>
          <ResultView>
            <ResultIconCircle>
              {isPaymentSuccess() ? <ResultIconSuccess /> : <ResultIconError />}
            </ResultIconCircle>
            <ResultText>{getResultMessage()}</ResultText>
          </ResultView>
        </>
      )}
    </>
  );

  const renderForm = () => (
    <ContentInnerView>
      {(Platform.OS !== 'web' || productType !== 'marketplaceitem') && (
        <TitleView>
          {onBackPress && <BackButton onBackPress={onBackPress} />}
          {title ? <TitleText>{title} </TitleText> : <></>}
          {productType === 'subscription' &&
            (isLoading || isLoadingIAPProduct ? (
              <ActivityIndicator animating />
            ) : (
              <PriceText>
                {intl.formatMessage(isRecurring ? messages.priceMonthly : messages.priceFixed, {
                  price,
                  currency: isApplePaidSubscription ? null : parseCurrency(),
                })}
              </PriceText>
            ))}
        </TitleView>
      )}
      {!!description && isSubscription && (
        <>
          <Divider />
          <DescriptionText>{description}</DescriptionText>
        </>
      )}
      {!(
        Platform.OS === 'ios' && ['subscription', 'tip', 'paid_post'].includes(productType || '')
      ) && (
        <>
          {['marketplaceitem', 'subscription', 'tip', 'paid_post'].includes(productType || '') && (
            <>
              <PaymentMethodsView>
                <PaymentMethodsHeaderView>
                  {(productType !== 'marketplaceitem' || Platform.OS !== 'web') && (
                    <PaymentMethodsListTitleText>
                      {intl.formatMessage(messages.paymentMethodsListTitle)}
                    </PaymentMethodsListTitleText>
                  )}
                  <AddPaymentMethodTouchable onPress={onAddPaymentMethodPress}>
                    <AddPaymentMethodCtaText>
                      {intl.formatMessage(messages.addMethod)}
                    </AddPaymentMethodCtaText>
                  </AddPaymentMethodTouchable>
                </PaymentMethodsHeaderView>
                <PaymentMethodsListView
                  style={{
                    height: paymentMethods.length ? Math.min(paymentMethods.length * 40 + 20) : 50,
                    width:
                      productType === 'marketplaceitem' && Platform.OS === 'web' ? 450 : '100%',
                  }}
                >
                  {isLoadingPaymentMethods && <ActivityIndicator animating />}
                  {!isLoadingPaymentMethods && paymentMethods.map(renderPaymentMethod)}
                </PaymentMethodsListView>
              </PaymentMethodsView>
              <CvvTextInput value={cvv} onChangeValue={setCvv} />
              <DisclaimerText>
                {intl.formatMessage(messages.disclaimer, {
                  termsCta: function TermsCtaChunk(chunk) {
                    return <DisclaimerText onPress={onTermsCtaPress}>{chunk}</DisclaimerText>;
                  },
                  privacyPolicyCta: function PrivacyCtaChunk(chunk) {
                    return <DisclaimerText onPress={onPrivacyPolicyCta}>{chunk}</DisclaimerText>;
                  },
                })}
              </DisclaimerText>
            </>
          )}
          <PayButton
            disabled={isPayButtonDisabled || !(cvv?.length === 3)}
            onPress={onPayPress}
            isMarketplace={productType === 'marketplaceitem'}
          >
            {['marketplaceitem', 'subscription', 'tip', 'paid_post'].includes(productType || '')
              ? intl.formatMessage(
                  selectedPaymentMethod && paymentMethods.length > 0
                    ? messages.payButtonLabel
                    : messages.payButtonLabelGeneric,
                  {
                    paymentMethod: intl.formatMessage(messages.methodName, {
                      lastFourDigits: selectedPaymentMethod
                        ? selectedPaymentMethod.creditCardInfo.last_four_digits
                        : '',
                    }),
                  },
                )
              : intl.formatMessage(messages.badgeBack)}
          </PayButton>
        </>
      )}
      {Platform.OS === 'ios' &&
        ['subscription', 'tip', 'paid_post'].includes(productType || '') && (
          <PayButton
            secondary
            onPress={onApplePayPress}
            disabled={isLoadingSubscriptionPlan || !appleProductId}
          >
            {productType === 'subscription'
              ? intl.formatMessage(messages.subscribeWithApple)
              : product?.type === 'paid_post'
              ? intl.formatMessage(messages.payForPostWithApple)
              : intl.formatMessage(messages.tipWithApple)}
          </PayButton>
        )}
    </ContentInnerView>
  );

  return (
    <>
      {isProcessing
        ? renderProcessing()
        : showRestrictionMessage
        ? renderAgeRestrictionMessage()
        : renderForm()}
    </>
  );
};

export default PaymentContainer;
