import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused?: boolean;
}

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 3H3V10H10V3Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21 3H14V10H21V3Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21 14H14V21H21V14Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10 14H3V21H10V14Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledIconActive = styled(SvgComponent)`
  opacity: 1;
`;

const StyledIconInactive = styled(SvgComponent)`
  opacity: 0.52;
`;

const Icon = ({ focused, ...rest }: IconProps) => (
  <TabBarIcon
    focused={!!focused}
    iconActive={<StyledIconActive />}
    iconInactive={<StyledIconInactive />}
    {...rest}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
