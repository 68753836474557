import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent: React.FC = (props) => {
  return (
    <Svg width={60} height={60} viewBox="0 0 60 60" fill="none" {...props}>
      <Path
        d="M19.125 7.375c-8.25 4-13.75 12.5-13.75 22.25 0 13.75 11.25 25 25 25s25-11.25 25-25c0-6.504-2.517-12.448-6.625-16.907"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        d="M4.625 5.125l14.75 2-2 15"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </Svg>
  );
};

export default SvgComponent;
