import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.View`
  padding: 0 15px;
  ${mediaWeb(css`
    flex: 1;
    align-items: center;
  `)};
`;
