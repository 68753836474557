import styled from 'styled-components/native';

export default styled.Image.attrs(() => ({
  source: require('assets/logo.png'),
  resizeMode: 'contain',
}))`
  width: 98px;
  height: 85px;
  align-self: center;
  margin-bottom: 25px;
`;
