import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { InteractiveSvgProps } from 'types/InteractiveSvgProps';

const FeedIcon: React.FC<InteractiveSvgProps> = ({ isActive, ...props }: InteractiveSvgProps) => {
  return (
    <Svg
      style={{ opacity: isActive ? 1 : 0.52 }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <Path
        d="M2 6.00001L8 1.33334L14 6.00001V13.3333C14 13.687 13.8595 14.0261 13.6095 14.2762C13.3594 14.5262 13.0203 14.6667 12.6667 14.6667H3.33333C2.97971 14.6667 2.64057 14.5262 2.39052 14.2762C2.14048 14.0261 2 13.687 2 13.3333V6.00001Z"
        fill={isActive ? 'white' : 'none'}
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default FeedIcon;
