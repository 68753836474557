import styled, { css } from 'styled-components/native';

import TextInput from 'components/TextInput';

export default styled(TextInput)`
  position: relative;
  width: 100%;
  margin: 25px 0 0;
  ${({ multiline }) =>
    multiline &&
    css`
      min-height: 110px;
    `}
`;
