import styled, { css } from 'styled-components/native';

interface ViewProps {
  large?: boolean;
}

export default styled.View<ViewProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.24);
  padding: 3px 7px 4px 7px;

  ${({ large }) =>
    large &&
    css`
      padding: 4px 8px;
    `}
`;
