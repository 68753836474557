import { AntDesign } from '@expo/vector-icons';
import * as React from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import Container from './styled/Container';
import Title from './styled/Title';

export interface OrderSectionTitleProps {
  /** The title */
  title: string;
  onToggle?: () => void;
  toggled?: boolean;
  large?: boolean;
}

/**
 * Orders title
 */
const OrderSectionTitle: React.FC<OrderSectionTitleProps> = ({
  title,
  onToggle,
  toggled,
  large,
  ...rest
}: OrderSectionTitleProps) => (
  <Container {...rest} web={large}>
    <Title large={large}>{title}</Title>
    {Platform.OS === 'web' && onToggle && (
      <TouchableOpacity onPress={onToggle}>
        <AntDesign
          name={toggled ? 'down' : 'up'}
          size={12}
          color="white"
          style={{ paddingTop: '4px' }}
        />
      </TouchableOpacity>
    )}
  </Container>
);

OrderSectionTitle.displayName = 'OrderSectionTitle';

export default OrderSectionTitle;
