import React from 'react';

import Hamburger from './styled/Hamburger';
import TouchableOpacity from './styled/TouchableOpacity';

interface Props {
  onPress: () => void;
}

const HamburgerMenu: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Hamburger />
    </TouchableOpacity>
  );
};

export default HamburgerMenu;
