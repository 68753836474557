import * as React from 'react';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';

import ArtistMarketplaceProductTile from 'components/ArtistMarketplaceProductTile';
import OrderProductPreview from 'components/OrderProductPreview';

import { MarketplaceItem } from 'types';
import { MarketplaceView } from 'types/MarketplaceItem';
import { parseItemPrice } from 'utils/marketplace';

import { List } from './styled/List';
import { GridItem, ListItem } from './styled/ProductTouchable';

export interface MarketplaceProductsProps {
  data: MarketplaceItem[];
  onProductPress?: (item: MarketplaceItem) => void;
  view?: MarketplaceView;
}

const MarketplaceComponents = (view: MarketplaceView) => {
  switch (view) {
    case 'grid':
      return GridItem;
    default:
      return ListItem;
  }
};

const MAX_TILE_WIDTH = 250;

const MarketplaceProducts: React.FC<MarketplaceProductsProps> = ({
  data,
  onProductPress,
  view = 'list',
}: MarketplaceProductsProps) => {
  const { width } = useWindowDimensions();
  const { viewPadding } = useTheme();
  const [numColumns, setNumColumns] = useState<number | undefined>();
  const extractKey = (item: unknown): string =>
    `marketplace-product-${(item as MarketplaceItem).id}`;

  const Component = MarketplaceComponents(view);

  useEffect(() => {
    if (view === 'list') setNumColumns(1);
    if (!numColumns && viewPadding && width)
      setNumColumns(Math.floor((width - viewPadding * 2) / MAX_TILE_WIDTH));
  }, [viewPadding, width]);

  const renderItem = ({ item }: { item: unknown }): JSX.Element => {
    const typedItem = item as MarketplaceItem;
    return (
      <Component
        onPress={() => onProductPress?.(item as MarketplaceItem)}
        width={numColumns === 1 ? width - viewPadding * 2 : MAX_TILE_WIDTH}
      >
        {view === 'list' ? (
          <OrderProductPreview
            name={typedItem.name}
            price={parseItemPrice(typedItem.price, typedItem.price_currency)}
            previewImage={typedItem.low_res_cover || typedItem.high_res_cover}
          />
        ) : (
          <ArtistMarketplaceProductTile product={typedItem} />
        )}
      </Component>
    );
  };

  if (!numColumns) return <></>;

  return (
    <List
      numColumns={numColumns}
      data={data}
      keyExtractor={extractKey}
      renderItem={renderItem}
      showsVerticalScrollIndicator={false}
    />
  );
};

MarketplaceProducts.displayName = 'MarketplaceProducts';

export default MarketplaceProducts;
