import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(({ theme }) => ({
  primary: true,
  labelColor: theme.marketplaceOrderDetails.actionText,
  bold: false,
  labelStyle: {
    fontSize: 15,
    fontFamily: 'BasierCircle-Medium',
    letterSpacing: -0.23,
  },
}))<{ withMargins?: boolean }>`
  margin: ${({ withMargins }) => (withMargins ? '40px 0 32px' : '0 0 15px')};
`;
