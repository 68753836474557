import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as HOME_STACK_SCREEN_NAME } from 'stacks/fan/FanHomeStack';

import {
  MARKETPLACE_SCREEN_NAME_ONGOING,
  SCREEN_NAME as MARKETPLACE_SCREEN_NAME,
} from 'screens/AritstMarketplaceScreen/constants';

import { Artist, SingleNotification } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import messages from './messages';
import Avatar from './styled/Avatar';
import Container from './styled/Container';
import Label from './styled/Label';
import TouchableOpacity from './styled/TouchableOpacity';
import UnreadIndicator from './styled/UnreadIndicator';

export interface OptionGroupItemProps {
  isFirst: boolean;
  navigateToProduct: (product: any) => void;
  navigateToMarketplaceProduct: (product: any) => void;
  notification: SingleNotification;
}

const NotificationsGroupItem: React.FC<OptionGroupItemProps> = ({
  isFirst,
  navigateToProduct,
  navigateToMarketplaceProduct,
  notification,
}: OptionGroupItemProps): JSX.Element => {
  const intl = useIntl();
  const navigation = useNavigation();
  const translationGroup = messages[notification.notification_type];
  const currentMessage = notification.product_model_name
    ? (translationGroup as any)[notification.product_model_name]
    : translationGroup;
  const onPress = () => {
    if (notification.notification_type === 'product_creation') {
      switch (notification.product_model_name) {
        case 'marketplaceitem':
          navigateToMarketplaceProduct({
            artistUsername: notification.artist?.username,
            productType: notification.product_model_name,
            id: notification.product_id,
            item: { artist: notification.artist },
          });
          break;
        case 'marketplaceitempayment':
          navigation.navigate(MARKETPLACE_SCREEN_NAME, { screen: MARKETPLACE_SCREEN_NAME_ONGOING });
          break;
        case 'subscription':
          navigation.navigate(HOME_STACK_SCREEN_NAME, {});
          break;
        default:
          navigateToProduct({
            artistUsername: notification.artist?.username,
            productType: notification.product_model_name,
            id: notification.product_id,
          });
      }
    }
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <Container isFirst={isFirst} read={notification.is_read}>
        {!notification.is_read && <UnreadIndicator />}
        {(notification?.artist || notification?.user_low_res_profile_picture) && (
          <Avatar
            imageUrl={
              notification?.artist
                ? getProfileLowResPictureUrl(notification?.artist as Artist)
                : notification?.user_low_res_profile_picture
            }
            mode="compact-small"
          />
        )}
        <Label>
          {intl.formatMessage(currentMessage, {
            fullName: notification?.artist?.display_name || notification?.user_username,
            strong: function StrongChunk(chunk) {
              return <Label strong>{chunk}</Label>;
            },
          })}
        </Label>
      </Container>
    </TouchableOpacity>
  );
};

NotificationsGroupItem.defaultProps = {
  isFirst: true,
};

NotificationsGroupItem.displayName = 'NotificationsGroupItem';

export default NotificationsGroupItem;
