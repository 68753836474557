import { Dimensions, Platform } from 'react-native';
import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/native';

const window = Dimensions.get('window');

export const vw = (v: number): FlattenSimpleInterpolation =>
  css`
    ${(window.width / 100) * v}px
  `;

export const mediaWeb = (css: FlattenSimpleInterpolation): FlattenSimpleInterpolation | null =>
  Platform.OS === 'web' ? css : null;
