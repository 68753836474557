import { useBackEnd } from 'context/backEnd';
import { FeedItemUpdateFn } from 'types';
import { PollAnswer } from 'types/PollAnswers';
import { PollProduct } from 'types/PollProduct';

const getNewAnswers = (oldAnswers: PollAnswer[], votedId: number): PollAnswer[] => {
  const newAnswers = [...oldAnswers];
  const oldVoted = newAnswers.find((a) => a.is_voted);
  let totalVotes = oldAnswers.map((a) => a.votes_count).reduce((total, current) => total + current);
  if (oldVoted && oldVoted.id !== votedId) {
    oldVoted.votes_count -= 1;
  } else {
    totalVotes++;
  }
  for (let i = 0; i < newAnswers.length; i++) {
    const answer = newAnswers[i];
    if (answer.id == votedId) {
      answer.votes_count += 1;
    }
    answer.is_voted = answer.id == votedId;
    answer.votes_percentage = Number(((answer.votes_count / totalVotes) * 100).toFixed(0));
  }
  return newAnswers;
};

export const usePoll = (
  product: PollProduct,
  updateFeedItem: FeedItemUpdateFn,
): {
  toggleVote: (answerId: number) => void;
} => {
  const getEndpoint = (answerId: number) =>
    `/artists/${product.artist.username}/products/polls/${product.id}/answers/${answerId}/vote-unvote`;
  const { axiosInstance } = useBackEnd();

  const toggleVote = async (answerId: number) => {
    const answer = product.answers.find((a) => a.id === answerId);
    try {
      if (answer && !answer.is_voted) {
        const newAnswers = getNewAnswers(product.answers, answerId);
        updateFeedItem(
          product,
          {
            answers: newAnswers,
          },
          false,
        );
        const { data } = await axiosInstance.post(getEndpoint(answerId));
        await updateFeedItem(product, {
          answers: data.answers,
        });
      }
    } catch (e) {
      await updateFeedItem(product, {
        answers: product.answers,
      });
    }
  };

  return {
    toggleVote,
  };
};
