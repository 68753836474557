import styled from 'styled-components/native';

interface ImageProps {
  isActive?: boolean;
}

export default styled.Image<ImageProps>`
  width: 64px;
  height: 64px;
  margin: ${({ isActive }) => (isActive ? '25px 0 6px 0' : '10px 0 6px 0')};
  z-index: 3;
`;
