import React from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import messages from './messages';
import CheckMarkBlankIcon from './styled/CheckMarkBlankIcon';
import CheckMarkCheckedIcon from './styled/CheckMarkCheckedIcon';
import ErrorsView from './styled/ErrorsView';
import HelperItemView from './styled/HelperItemView';
import PasswordHelperText from './styled/PasswordHelperText';
import Text from './styled/Text';

interface Props {
  passwordChecks: Record<string, { pass: boolean; message?: string }>;
  width?: number;
}

const FormPasswordErrorsView: React.FC<Props> = ({ passwordChecks, width }: Props) => {
  const intl = useIntl();
  return (
    <ErrorsView width={width}>
      {Platform.OS === 'web' ? (
        <Text>{intl.formatMessage(messages.password.requirements)}</Text>
      ) : (
        <PasswordHelperText type="error">
          {intl.formatMessage(messages.password.requirements)}
        </PasswordHelperText>
      )}
      {Object.keys(passwordChecks).map(
        (key) =>
          passwordChecks[key].message && (
            <HelperItemView key={key}>
              {passwordChecks[key].pass ? <CheckMarkCheckedIcon /> : <CheckMarkBlankIcon />}
              <PasswordHelperText type="error" check pass={passwordChecks[key].pass}>
                {passwordChecks[key].message}
              </PasswordHelperText>
            </HelperItemView>
          ),
      )}
    </ErrorsView>
  );
};

export default FormPasswordErrorsView;
