import { ActivityIndicator } from 'react-native-paper';
import styled from 'styled-components/native';

const LoadingIndicator = styled(ActivityIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
`;

LoadingIndicator.defaultProps = {
  color: '#ffffff',
  size: 30,
};

export default LoadingIndicator;
