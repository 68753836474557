import { Animated } from 'react-native';
import styled from 'styled-components/native';

export default styled(Animated.View)<{ height?: number; opacity?: number }>`
  background: ${({ theme }) => theme.colors.background};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
