import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Animated, Easing } from 'react-native';
import styled from 'styled-components/native';

import LogoChip from 'screens/WelcomeScreen/styled/LogoChip';
import LogoChipText from 'screens/WelcomeScreen/styled/LogoChipText';

import { VARIANT } from 'context';

import messages from '../messages';
import Beta from './Beta';
import LogoImage from './LogoImage';

const View = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LogoView = styled(Animated.View)`
  overflow: hidden;
`;

interface Props {
  isBeta?: boolean;
  onLoadEnd: () => void;
  handleLogoAnimationEnd: () => void;
}

const Logo: React.FC<Props> = ({ isBeta = false, onLoadEnd, handleLogoAnimationEnd }: Props) => {
  const intl = useIntl();
  const width = useRef(new Animated.Value(62)).current;

  useEffect(() => {
    Animated.timing(width, {
      toValue: 255,
      useNativeDriver: false,
      duration: 1000,
      delay: 500,
      easing: Easing.inOut(Easing.quad),
    }).start(handleLogoAnimationEnd);
  }, []);

  return (
    <View>
      <LogoView style={{ width }}>
        <LogoImage onLoadEnd={onLoadEnd} />
      </LogoView>
      {VARIANT === 'fan' ? (
        <>{isBeta ? <Beta>{intl.formatMessage(messages.welcome.beta)}</Beta> : null}</>
      ) : (
        <LogoChip>
          <LogoChipText>ARTIST</LogoChipText>
        </LogoChip>
      )}
    </View>
  );
};

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 40%;
  left: 50%;
`;

StyledLogo.displayName = 'Logo';

export default Logo;
