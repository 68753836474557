import styled from 'styled-components/native';

export default styled.View`
  display: flex;
  justify-content: flex-start;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`;
