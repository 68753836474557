import styled from 'styled-components/native';

export default styled.TouchableOpacity<{ variant: 'subscribed' | 'locked' | 'canceled' }>`
  background-color: ${({ variant }) =>
    variant === 'subscribed' ? '#0537FF' : variant === 'canceled' ? '#FF845E' : '#121212'};
  border-radius: 40px;
  flex-direction: row;
  align-items: center;
  height: 25px;
  padding-right: 10px;
`;
