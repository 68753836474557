import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface ArrowSvg extends SvgProps {
  isActive: boolean;
}

export const LeftArrowSvg: React.FC<ArrowSvg> = (props: ArrowSvg) => {
  return (
    <Svg width="11" height="18" viewBox="0 0 11 18" fill="white" {...props}>
      <Path
        d="M0.368164 8.59668C0.368164 8.80176 0.439941 8.97607 0.59375 9.11963L8.79688 16.9229C8.93018 17.0562 9.10449 17.1279 9.29932 17.1279C9.69922 17.1279 10.0068 16.8306 10.0068 16.4204C10.0068 16.2256 9.9248 16.0513 9.81201 15.9282L2.10107 8.59668L9.81201 1.26514C9.9248 1.13184 10.0068 0.95752 10.0068 0.762695C10.0068 0.362793 9.69922 0.0551758 9.29932 0.0551758C9.10449 0.0551758 8.93018 0.126953 8.79688 0.260254L0.59375 8.07373C0.439941 8.21729 0.368164 8.3916 0.368164 8.59668Z"
        fill="white"
        fillOpacity={props.isActive ? '1' : '0.4'}
      />
    </Svg>
  );
};

export const RightArrowSvg: React.FC<ArrowSvg> = (props: ArrowSvg) => {
  return (
    <Svg width="11" height="18" viewBox="0 0 11 18" fill="white" {...props}>
      <Path
        d="M10.6216 8.59668C10.6216 8.3916 10.5498 8.21729 10.396 8.07373L2.20312 0.260254C2.05957 0.126953 1.89551 0.0551758 1.69043 0.0551758C1.30078 0.0551758 0.98291 0.362793 0.98291 0.762695C0.98291 0.95752 1.06494 1.13184 1.18799 1.26514L8.88867 8.59668L1.18799 15.9282C1.06494 16.0513 0.98291 16.2256 0.98291 16.4204C0.98291 16.8306 1.30078 17.1279 1.69043 17.1279C1.89551 17.1279 2.05957 17.0562 2.20312 16.9229L10.396 9.11963C10.5498 8.97607 10.6216 8.80176 10.6216 8.59668Z"
        fill="white"
        fillOpacity={props.isActive ? '1' : '0.4'}
      />
    </Svg>
  );
};

export const ShareSvg: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="18" height="24" viewBox="0 0 18 24" fill="none" {...props}>
      <Path
        d="M9 15.3652C9.33838 15.3652 9.62549 15.0884 9.62549 14.75V3.70654L9.58447 2.21973L10.4971 3.16309L12.2402 4.97803C12.353 5.10107 12.5171 5.1626 12.6709 5.1626C12.9888 5.1626 13.2451 4.92676 13.2451 4.60889C13.2451 4.43457 13.1733 4.31152 13.0605 4.18848L9.45117 0.661133C9.29736 0.507324 9.16406 0.456055 9 0.456055C8.83594 0.456055 8.70264 0.507324 8.54883 0.661133L4.93945 4.18848C4.81641 4.31152 4.74463 4.43457 4.74463 4.60889C4.74463 4.92676 4.99072 5.1626 5.31885 5.1626C5.47266 5.1626 5.64697 5.10107 5.75977 4.97803L7.50293 3.16309L8.41553 2.21973L8.37451 3.70654V14.75C8.37451 15.0884 8.66162 15.3652 9 15.3652ZM3.09375 23.2607H14.896C16.8955 23.2607 17.9209 22.2354 17.9209 20.2666V9.78711C17.9209 7.80811 16.8955 6.78271 14.896 6.78271H11.9736V8.0542H14.8652C16.0034 8.0542 16.6494 8.66943 16.6494 9.84863V20.1948C16.6494 21.3843 16.0034 21.9893 14.8652 21.9893H3.11426C1.96582 21.9893 1.34033 21.3843 1.34033 20.1948V9.84863C1.34033 8.66943 1.96582 8.0542 3.11426 8.0542H6.02637V6.78271H3.09375C1.09424 6.78271 0.0688477 7.79785 0.0688477 9.78711V20.2666C0.0688477 22.2456 1.09424 23.2607 3.09375 23.2607Z"
        fill="white"
      />
    </Svg>
  );
};

export const SafariSvg: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <Path
        d="M10.5 20.8813C16.1294 20.8813 20.7847 16.2261 20.7847 10.5967C20.7847 4.95703 16.1294 0.312012 10.4897 0.312012C4.86035 0.312012 0.215332 4.95703 0.215332 10.5967C0.215332 16.2261 4.87061 20.8813 10.5 20.8813ZM10.5 19.5483C5.53711 19.5483 1.54834 15.5493 1.54834 10.5967C1.54834 5.63379 5.53711 1.63477 10.4897 1.63477C15.4526 1.63477 19.4517 5.63379 19.4619 10.5967C19.4619 15.5493 15.4629 19.5483 10.5 19.5483ZM6.0293 15.8159L12.0483 12.8013C12.3457 12.6577 12.5508 12.4424 12.7046 12.145L15.709 6.13623C16.0063 5.53125 15.5962 5.06982 14.9502 5.3877L8.94141 8.39209C8.66455 8.52539 8.46973 8.72021 8.29541 9.04834L5.28076 15.0674C4.99365 15.6621 5.42432 16.1133 6.0293 15.8159ZM10.5 11.8682C9.80273 11.8682 9.23877 11.3042 9.23877 10.6069C9.23877 9.90967 9.80273 9.35596 10.5 9.35596C11.1973 9.35596 11.751 9.90967 11.751 10.6069C11.751 11.3042 11.1973 11.8682 10.5 11.8682Z"
        fill="white"
      />
    </Svg>
  );
};

export const RefreshPageSvg: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="18" height="23" viewBox="0 0 18 23" fill="none" {...props}>
      <Path
        d="M8.44629 10.5767C8.64111 10.5767 8.78467 10.5151 8.89746 10.3921L13.2656 5.97266C13.4502 5.78809 13.5117 5.62402 13.5117 5.44971C13.5117 5.26514 13.4297 5.09082 13.2656 4.92676L8.90771 0.466309C8.79492 0.343262 8.65137 0.271484 8.43604 0.271484C8.07715 0.271484 7.8208 0.558594 7.8208 0.91748C7.8208 1.0918 7.88232 1.24561 8.00537 1.37891L11.2559 4.62939C10.5586 4.47559 9.7793 4.39355 9 4.39355C4.02686 4.39355 0.0585938 8.34131 0.0585938 13.3042C0.0585938 18.2876 4.02686 22.2559 9 22.2559C13.9731 22.2559 17.9312 18.2876 17.9312 13.3042C17.9312 12.9248 17.6646 12.6479 17.2749 12.6479C16.9058 12.6479 16.6699 12.9248 16.6699 13.3042C16.6699 17.5903 13.2656 20.9946 9 20.9946C4.72412 20.9946 1.33008 17.5903 1.33008 13.3042C1.33008 9.03857 4.72412 5.65479 9 5.65479C9.94336 5.65479 10.8252 5.74707 11.5635 5.93164L8.00537 9.48975C7.88232 9.62305 7.8208 9.77686 7.8208 9.94092C7.8208 10.2998 8.0874 10.5767 8.44629 10.5767Z"
        fill="white"
      />
    </Svg>
  );
};
