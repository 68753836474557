export default {
  title: {
    id: 'marketplaceOrderDetails.title',
    defaultMessage: 'Order',
  },
  purchaseQuestionnaire: {
    id: 'marketplaceOrderDetails.purchaseQuestionnaire',
    defaultMessage: 'First, a few questions…',
  },
  orderDetails: {
    id: 'marketplaceOrderDetails.orderDetails',
    defaultMessage: 'Order details',
  },
  amountPaid: {
    id: 'marketplaceOrderDetails.amountPaid',
    defaultMessage: 'Amount paid',
  },
  paymentMethod: {
    id: 'marketplaceOrderDetails.paymentMethod',
    defaultMessage: 'Choose payment method',
  },
  order: {
    id: 'marketplaceOrderDetails.order',
    defaultMessage: 'Order',
  },
  confirmOrder: {
    id: 'marketplaceOrderDetails.confirmOrder',
    defaultMessage: 'Confirm order',
  },
  confirmOrderWeb: {
    id: 'marketplaceOrderDetails.confirmOrderWeb',
    defaultMessage: 'Continue',
  },
  editQuestions: {
    id: 'marketplaceOrderDetails.editQuestions',
    defaultMessage: 'Edit',
  },
};
