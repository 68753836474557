import { useRoute } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import { SCREEN_NAME as MARKETPLACE_AVAILABLE_SCREEN_NAME } from 'screens/MarketplaceAvailableItemsScreen';
import { SCREEN_NAME as MARKETPLACE_ORDERS_SCREEN_NAME } from 'screens/MarketplaceOrdersScreen';

import SubNavigation from 'components/SubNavigation';

import messages from './messages';
import TitleContainer from './styled/TitleContainer';

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

const MarketplaceSubnavigation: React.FC = () => {
  const intl = useIntl();
  const navigation = useNavigation();
  const { name } = useRoute();
  const subNavItems = useMemo(
    () => [
      { name: intl.formatMessage(messages.products), id: MARKETPLACE_AVAILABLE_SCREEN_NAME },
      { name: intl.formatMessage(messages.orders), id: MARKETPLACE_ORDERS_SCREEN_NAME },
    ],
    [],
  );
  return (
    <TitleContainer>
      <Wrapper>
        {/*<SearchButton onClick={() => {}} fontSize={15} />*/}
        <SubNavigation
          activeId={name}
          items={subNavItems}
          onSelect={(id: string) => navigation.navigate(id)}
        />
      </Wrapper>
    </TitleContainer>
  );
};

export default MarketplaceSubnavigation;
