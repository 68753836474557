import { TextInput } from 'react-native-paper';
import styled, { DefaultTheme } from 'styled-components/native';

interface Props {
  theme: DefaultTheme;
  editable: boolean;
  isError?: boolean;
}

export default styled(TextInput).attrs(({ theme, editable, isError }: Props) => {
  return {
    mode: 'flat',
    placeholderTextColor: theme.colors.primaryInactive,
    underlineColor: 'transparent',
    theme: {
      colors: {
        borderColor: !isError ? theme.textInput.borderColor : theme.colors.errorAccent,
        placeholder: theme.colors.primaryInactive,
        text: editable === false ? theme.colors.primaryGhost : theme.colors.text,
        primary: theme.colors.text,
        background: theme.textInput.background,
      },
    },
  };
})`
  border-radius: 6px;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
`;
