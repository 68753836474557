export default {
  settings: {
    id: 'settingsStack.settings.title',
    defaultMessage: 'Settings',
  },
  liveEventBookings: {
    id: 'settingsStack.liveEventBookings.title',
    defaultMessage: 'Live event bookings',
  },
  notifications: {
    id: 'settingsStack.notifications.title',
    defaultMessage: 'Notifications',
  },
  paymentMethods: {
    id: 'settingsStack.paymentMethods.title',
    defaultMessage: 'Payment Methods',
  },
  editCard: {
    id: 'settingsStack.editCard.title',
    defaultMessage: 'Edit card',
  },
  addAnotherCard: {
    id: 'settingsStack.addAnotherCard.title',
    defaultMessage: 'Add another card',
  },
  profileSettings: {
    id: 'settingsStack.profileSettings.title',
    defaultMessage: 'Profile Settings',
  },
  bookmarks: {
    id: 'settingsStack.bookmarks.title',
    defaultMessage: 'Bookmarks',
  },
  subscriptions: {
    id: 'settingsStack.subscriptions.title',
    defaultMessage: 'Subscribed & Followed Artists',
  },
  blockedUsers: {
    id: 'settingsStack.blockedUsers.title',
    defaultMessage: 'Blocked Users',
  },
};
