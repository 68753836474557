import styled from 'styled-components/native';

import LogoImage from 'components/LogoImage';

export default styled(LogoImage)`
  width: 100%;
  aspect-ratio: 426/90;
  height: auto;
  margin: auto;
`;
