import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

const { width, height } = Dimensions.get('window');

export default styled.View`
  width: ${width}px;
  height: ${height}px;
  align-items: center;
`;
