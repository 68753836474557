import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';

import TabScreenView from './styled/TabScreenView';

interface Props {
  data?: any[];
  renderEmptyContent: () => JSX.Element;
  renderItem: ({ item, index }: { item: any; index: number }) => JSX.Element;
  extractKey: (item: any) => string;
  isLoading?: boolean;
  refresh?: () => void;
  numOfColumns?: number;
}

const MarketplaceFlatlist: React.FC<Props> = ({
  data,
  renderEmptyContent,
  renderItem,
  extractKey,
  isLoading,
  refresh,
  numOfColumns,
}: Props) => {
  useFocusEffect(
    React.useCallback(() => {
      refresh?.();
    }, [refresh]),
  );

  return (
    <TabScreenView>
      {!data?.length && !isLoading ? (
        renderEmptyContent()
      ) : (
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={extractKey}
          refreshing={isLoading}
          onRefresh={refresh}
          numColumns={numOfColumns}
        />
      )}
    </TabScreenView>
  );
};

export default MarketplaceFlatlist;
