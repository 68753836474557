export default {
  addFirstCardCta: {
    id: 'creditCard.cta',
    defaultMessage: 'Add credit card',
  },
  addCta: {
    id: 'creditCard.cta',
    defaultMessage: 'Add another card',
  },
  cardNumber: {
    id: 'creditCard.number',
    defaultMessage: 'Card number',
  },
  cardName: {
    id: 'creditCard.name',
    defaultMessage: 'Full name on card',
  },
  cardExpiryDate: {
    id: 'creditCard.expiryDate',
    defaultMessage: 'MM/YY Expiry',
  },
  cardCvv: {
    id: 'creditCard.cvv',
    defaultMessage: 'CVV',
  },
};
