import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack';
import React from 'react';
import { useIntl } from 'react-intl';

import BlockedUsersScreen, {
  SCREEN_NAME as BLOCKED_USERS_SCREEN_NAME,
} from 'screens/BlockedUsersScreen';
import EditPaymentMethodScreen, {
  SCREEN_NAME_EDIT as EDIT_PAYMENT_METHOD_SCREEN_NAME,
} from 'screens/EditPaymentMethodScreen';
import LiveEventBookingsScreen, {
  SCREEN_NAME as LIVE_EVENT_BOOKINGS_SCREEN_NAME,
} from 'screens/LiveEventBookingsScreen';
// import NotificationsSettingsScreen, {
//   SCREEN_NAME as NOTIFICATIONS_SETTINGS_SCREEN_NAME,
// } from 'screens/NotificationsSettingsScreen';
import PaymentMethodsScreen, {
  SCREEN_NAME as PAYMENT_METHODS_SCREEN_NAME,
} from 'screens/PaymentMethodsScreen';
import ProfileDashboardScreen, {
  SCREEN_NAME as PROFILE_DASHBOARD_SCREEN_NAME,
} from 'screens/ProfileDashboardScreen';
import SavedPostsScreen, { SCREEN_NAME as SAVED_POSTS_SCREEN_NAME } from 'screens/SavedPostsScreen';
import SubscribedProfilesScreen, {
  SCREEN_NAME as SUBSCRIBED_PROFILES_SCREEN_NAME,
} from 'screens/SubscribedProfilesScreen';

import NavigationHeader from 'components/NavigationHeader';

export { SCREEN_NAME } from './constants';
import messages from './messages';

export interface Props {}

export const linking: PathConfigMap = {
  [PROFILE_DASHBOARD_SCREEN_NAME]: '/',
  [LIVE_EVENT_BOOKINGS_SCREEN_NAME]: '/live-event-bookings',
  // [NOTIFICATIONS_SETTINGS_SCREEN_NAME]: '/notifications',
  [PAYMENT_METHODS_SCREEN_NAME]: '/payment-methods',
  [EDIT_PAYMENT_METHOD_SCREEN_NAME]: '/payment-methods/edit',
  [SAVED_POSTS_SCREEN_NAME]: '/bookmarks',
  [SUBSCRIBED_PROFILES_SCREEN_NAME]: '/subscriptions',
};

const SettingsStack = createStackNavigator();

const FanSettingsStack: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <SettingsStack.Navigator
      initialRouteName={PROFILE_DASHBOARD_SCREEN_NAME}
      screenOptions={{
        headerMode: 'screen',
        title: intl.formatMessage(messages.settings),
        header: function FanSettingsStackHeader(props: StackHeaderProps) {
          return <NavigationHeader {...props} />;
        },
      }}
    >
      <SettingsStack.Screen
        name={PROFILE_DASHBOARD_SCREEN_NAME}
        component={ProfileDashboardScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <SettingsStack.Screen
        name={LIVE_EVENT_BOOKINGS_SCREEN_NAME}
        component={LiveEventBookingsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.liveEventBookings),
        }}
      />
      {/* TODO: enable once implemented on the BE */}
      {/* <SettingsStack.Screen
        name={NOTIFICATIONS_SETTINGS_SCREEN_NAME}
        component={NotificationsSettingsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.notifications),
        }}
      /> */}
      <SettingsStack.Screen
        name={PAYMENT_METHODS_SCREEN_NAME}
        component={PaymentMethodsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.paymentMethods),
        }}
      />
      <SettingsStack.Screen
        name={EDIT_PAYMENT_METHOD_SCREEN_NAME}
        component={EditPaymentMethodScreen}
        options={({ route }) => ({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title: route?.params?.method
            ? intl.formatMessage(messages.editCard)
            : intl.formatMessage(messages.addAnotherCard),
          headerShown: true,
          animationEnabled: true,
        })}
      />

      <SettingsStack.Screen
        name={SAVED_POSTS_SCREEN_NAME}
        component={SavedPostsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.bookmarks),
        }}
      />
      <SettingsStack.Screen
        name={SUBSCRIBED_PROFILES_SCREEN_NAME}
        component={SubscribedProfilesScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.subscriptions),
        }}
      />
      <SettingsStack.Screen
        name={BLOCKED_USERS_SCREEN_NAME}
        component={BlockedUsersScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: intl.formatMessage(messages.blockedUsers),
        }}
      />
    </SettingsStack.Navigator>
  );
};

export default FanSettingsStack;
