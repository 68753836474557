import styled from 'styled-components/native';

export const ListItem = styled.TouchableOpacity<{ width: number }>`
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.marketplace.border};
  height: 75px;
  padding-bottom: 15px;
  padding-top: 15px;
  width: ${({ width }) => width}px;
`;

const GRID_ITEM_MARGIN = 10;
export const GridItem = styled.TouchableOpacity<{ width: number }>`
  background: rgba(46, 81, 223, 0.24);
  width: ${({ width }) => width - GRID_ITEM_MARGIN * 2}px;
  margin: ${GRID_ITEM_MARGIN}px;
  border-radius: 14px;
  padding: 16px 16px 24px 16px;
`;
