export default {
  yourArtists: {
    title: {
      id: 'feed.yourArtists.title',
      defaultMessage: 'Your Artists',
    },
    seeMore: {
      id: 'feed.yourArtists.seeMore',
      defaultMessage: 'See more',
    },
  },
  stancast: {
    title: {
      id: 'feed.stancast.title',
      defaultMessage: 'Always be a Stan!',
    },
    text: {
      id: 'feed.stancast.text',
      defaultMessage:
        'Get access to exclusive artists’ content and buy their virtual merch directly form the App!',
    },
  },
};
