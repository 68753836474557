import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import cardValidator from 'card-validator';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import NavigationHeader from 'components/NavigationHeader';

import { usePayments } from 'context/payments';
import { useSnackbarSet } from 'context/snackbar';
import { useUser } from 'hooks';
import { PaymentMethod } from 'types';
import { formatCardExpiry, formatCardNumber, parseDigits } from 'utils/payments';
import regex from 'utils/regex';
import { useRifm } from 'utils/rifm';

import { SCREEN_NAME_EDIT } from './constants';
import messages from './messages';
import CardExpAndCvvView from './styled/CardExpAndCvvView';
import CreditCardIcon from './styled/CreditCardIcon';
import EditPaymentMethodView from './styled/EditPaymentMethodView';
import ErrorsView from './styled/ErrorsView';
import HeaderView from './styled/HeaderView';
import HelperText from './styled/HelperText';
import MainView from './styled/MainView';
import SaveButton from './styled/SaveButton';
import SectionHeadingText from './styled/SectionHeadingText';
import TextInput from './styled/TextInput';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME_EDIT>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME_EDIT>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const EditPaymentMethodScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { setSnackbar } = useSnackbarSet();
  const {
    isLoadingPaymentMethods,
    addPaymentMethodError,
    addPaymentMethodSuccess,
    setAddPaymentMethodError,
    setAddPaymentMethodSuccess,
    addPaymentMethod,
  } = usePayments();
  const { isLoading: isLoadingUser, profile } = useUser();
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting },
    trigger,
  } = useForm<{
    fullName: string;
    cardNumber: string;
    cardExpiry: string;
    cardCvv: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    phone: string;
    register: any;
  }>({
    mode: 'onChange',
    defaultValues: {
      fullName: undefined,
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvv: undefined,
    },
  });
  const [rawCardNumber, setRawCardNumber] = useState('');
  const [rawCardExpiry, setRawCardExpiry] = useState('');
  const [showCardNumberError, setShowCardNumberError] = useState(false);
  const [showCardExpiryError, setShowCardExpiryError] = useState(false);
  const [showCardCvvError, setShowCardCvvError] = useState(false);
  const [cvvName, setCvvName] = useState('CVV');
  const [cvvLength, setCvvLength] = useState(3);
  const [cardType, setCardType] = useState<string>('');
  const cardNumberInputRef = useRef(null);
  const cardExpiryInputRef = useRef(null);
  const cardCvvInputRef = useRef(null);
  const addressInputRef = useRef(null);

  const { value: cardNumberValue, onChange: onCardNumberChange } = useRifm({
    value: rawCardNumber,
    onChange: setRawCardNumber,
    format: formatCardNumber,
  });

  const { value: cardExpiryValue, onChange: onCardExpiryChange } = useRifm({
    value: rawCardExpiry,
    onChange: setRawCardExpiry,
    format: formatCardExpiry,
  });

  useEffect(() => {
    if (cardValidator.expirationDate(cardExpiryValue).isValid) {
      clearErrors('cardExpiry');
      clearErrors('form');
      trigger();
    }
  }, [cardExpiryValue]);

  useEffect(() => {
    if (addPaymentMethodSuccess === true) {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.snackbar.success.add),
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
      if (typeof (route.params as any)?.goBack === 'function') {
        (route.params as any).goBack();
      } else {
        navigation.goBack();
      }
      setAddPaymentMethodError(undefined);
      setAddPaymentMethodSuccess(undefined);
    } else if (addPaymentMethodSuccess === false) {
      setSnackbar({
        visible: true,
        label:
          addPaymentMethodError?.code === 'retry_after_some_time_or_try_another_card'
            ? intl.formatMessage(messages.snackbar.error.addRetry)
            : addPaymentMethodError?.code === 'not_eligible_for_3ds'
            ? intl.formatMessage(messages.snackbar.error.addAnother)
            : intl.formatMessage(messages.snackbar.error.add),
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
      setError('form', {
        message:
          addPaymentMethodError?.code === 'credit_card_already_exists'
            ? intl.formatMessage(messages.error.cardExists)
            : addPaymentMethodError?.detail || intl.formatMessage(messages.snackbar.error.add),
      });
      setAddPaymentMethodError(undefined);
      setAddPaymentMethodSuccess(undefined);
    }
  }, [addPaymentMethodError, addPaymentMethodSuccess]);

  const onCardNumberChangeLive = (value: string) => {
    const result = cardValidator.number(value);
    setShowCardNumberError(
      !!result.card &&
        !!result.card.lengths &&
        parseDigits(value).length >= result.card.lengths[0] &&
        !result.isValid,
    );
    setCardType(result.card?.type || '');
    setCvvName(result.card?.code.name || 'CVV');
    setCvvLength(result.card?.code.size || 3);
  };

  const onCardExpiryChangeLive = (value: string) => {
    const result = cardValidator.expirationDate(value);
    setShowCardExpiryError(parseDigits(value).length === 4 && !result.isValid);
  };

  const onCardCvvChangeLive = (value: string) => {
    const result = cardValidator.cvv(value);
    setShowCardCvvError(parseDigits(value).length === cvvLength && !result.isValid);
  };

  const onSubmit = async (args: {
    fullName: string;
    cardNumber: string;
    cardExpiry: string;
    cardCvv: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    phone: string;
  }) => {
    if (isLoadingPaymentMethods) {
      return;
    }

    const { fullName, cardNumber, cardExpiry, cardCvv } = args;
    const number = parseDigits(cardNumber);
    const expValidation = cardValidator.expirationDate(cardExpiry);

    await addPaymentMethod({
      billingAddress: {
        email: profile?.email,
      },
      creditCardInfo: {
        number,
        expiry_month: expValidation.month as string,
        expiry_year: expValidation.year as string,
        cardholder_name: fullName,
        cvv: cardCvv,
      },
    } as PaymentMethod);
  };

  return (
    <MainView>
      <EditPaymentMethodView>
        <HeaderView>
          <NavigationHeader
            showGoBack
            insets={{ top: 1 }}
            headerMiddle={() => (
              <SectionHeadingText>{intl.formatMessage(messages.headingCardWeb)}</SectionHeadingText>
            )}
          />
        </HeaderView>
        <Controller
          name="fullName"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInput
              editable
              label={intl.formatMessage(messages.nameInput.label)}
              placeholder={intl.formatMessage(messages.nameInput.placeholder)}
              keyboardType="default"
              returnKeyType="next"
              autoCompleteType="name"
              autoCapitalize="words"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: string) => {
                clearErrors('fullName');
                clearErrors('form');
                onChange(value);
              }}
              onSubmitEditing={() => (cardNumberInputRef.current as any)?.focus()}
            />
          )}
          rules={{
            required: intl.formatMessage(messages.nameInput.error.required),
            pattern: {
              value: regex.fullName,
              message: intl.formatMessage(messages.nameInput.error.invalid),
            },
          }}
          defaultValue=""
        />

        <Controller
          name="cardNumber"
          control={control}
          render={({ onChange, onBlur }) => (
            <TextInput
              ref={cardNumberInputRef}
              label={intl.formatMessage(messages.cardNumberInput.label)}
              placeholder={intl.formatMessage(messages.cardNumberInput.placeholder)}
              editable
              left={<TextInput.Icon name={() => <CreditCardIcon cardType={cardType} />} />}
              keyboardType="number-pad"
              returnKeyType="next"
              autoCompleteType="cc-number"
              value={cardNumberValue}
              error={showCardNumberError}
              onBlur={onBlur}
              onChange={onCardNumberChange as any}
              onChangeText={(value: string) => {
                clearErrors('cardNumber');
                clearErrors('form');
                onCardNumberChangeLive(value);
                onChange(value);
              }}
              onSubmitEditing={() => (cardExpiryInputRef.current as any)?.focus()}
            />
          )}
          rules={{
            validate: (value) => cardValidator.number(value).isValid,
          }}
          defaultValue=""
        />

        <CardExpAndCvvView>
          <Controller
            name="cardExpiry"
            control={control}
            render={({ onChange, onBlur }) => (
              <TextInput
                maxLength={5}
                editable
                ref={cardExpiryInputRef}
                inline
                first
                label={intl.formatMessage(messages.cardExpiryInput.label)}
                placeholder={intl.formatMessage(messages.cardExpiryInput.placeholder)}
                keyboardType="number-pad"
                returnKeyType="next"
                autoCompleteType="cc-exp"
                value={cardExpiryValue}
                error={showCardExpiryError}
                onBlur={onBlur}
                onChange={onCardExpiryChange as any}
                onChangeText={(value: string) => {
                  clearErrors('cardExpiry');
                  clearErrors('form');
                  onCardExpiryChangeLive(value);
                  onChange(value);
                }}
                onSubmitEditing={() => (cardCvvInputRef.current as any)?.focus()}
              />
            )}
            rules={{
              validate: () => cardValidator.expirationDate(cardExpiryValue).isValid,
            }}
            defaultValue=""
          />

          <Controller
            name="cardCvv"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                editable
                ref={cardCvvInputRef}
                inline
                last
                label={cvvName}
                placeholder={intl.formatMessage(messages.cardCvvInput.placeholder)}
                secureTextEntry={true}
                keyboardType="number-pad"
                returnKeyType="next"
                autoCompleteType="cc-csc"
                maxLength={cvvLength}
                value={value}
                error={showCardCvvError}
                onBlur={onBlur}
                onChangeText={(value: string) => {
                  clearErrors('cardCvv');
                  clearErrors('form');
                  onCardCvvChangeLive(value);
                  onChange(value);
                }}
                onSubmitEditing={() => (addressInputRef.current as any)?.focus()}
              />
            )}
            rules={{
              validate: (value) => cardValidator.cvv(value).isValid,
            }}
            defaultValue=""
          />
        </CardExpAndCvvView>
        <SaveButton
          isDelete={false}
          disabled={!isValid || isLoadingUser || !profile}
          processing={isSubmitting || isLoadingPaymentMethods}
          onPress={handleSubmit(onSubmit)}
        >
          {intl.formatMessage(messages.saveButtonLabel)}
        </SaveButton>
        <ErrorsView>
          {Object.keys(errors)
            .filter((key) => !!(errors as any)[key])
            .map((key) => (
              <HelperText key={key} type="error">
                {(errors as any)[key].message}
              </HelperText>
            ))}
        </ErrorsView>
      </EditPaymentMethodView>
    </MainView>
  );
};

export default EditPaymentMethodScreen;
