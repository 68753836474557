import React, { useState } from 'react';

import { ProductTypePlural } from 'types';

interface PostsRefreshState {
  shouldRefresh: ProductTypePlural[];
}

type PostsRefreshSet = (shouldRefresh: PostsRefreshState) => void;

const DEFAULT_STATE: PostsRefreshState = {
  shouldRefresh: [],
};

const PostsRefreshStateContext = React.createContext<PostsRefreshState>(DEFAULT_STATE);
const PostsRefreshSetContext = React.createContext<PostsRefreshSet>(() => {});

export function usePostsRefreshState(): PostsRefreshState {
  const context = React.useContext<PostsRefreshState>(PostsRefreshStateContext);
  if (context === undefined) {
    throw new Error('usePostsRefreshState must be used within a PostsRefreshProvider');
  }

  return context;
}

export function usePostsRefreshSet(): PostsRefreshSet {
  const context = React.useContext(PostsRefreshSetContext);
  if (context === undefined) {
    throw new Error('usePostsRefreshSet must be used within a PostsRefreshProvider');
  }

  return context;
}

interface Props {
  children: React.ReactNode;
}

export const PostsRefreshProvider: React.FC<Props> = ({ children }: Props) => {
  const [postsRefresh, setPostsRefresh] = useState<PostsRefreshState>(DEFAULT_STATE);

  return (
    <PostsRefreshStateContext.Provider value={postsRefresh}>
      <PostsRefreshSetContext.Provider value={setPostsRefresh}>
        {children}
      </PostsRefreshSetContext.Provider>
    </PostsRefreshStateContext.Provider>
  );
};
