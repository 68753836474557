import { NavigationContainerRef } from '@react-navigation/native';
import * as InAppPurchases from 'expo-in-app-purchases';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';

import PaymentContainer from 'containers/PaymentContainer';

import BottomSheet from 'components/BottomSheet';

import { useBackEnd } from 'context';
import { useFeeBasedPostIAP } from 'hooks';
import { usePaymentMethods } from 'hooks/paymentMethods';
import { useSubscriptionIAP } from 'hooks/subscriptions';
import { useTippingIAP } from 'hooks/tipping';
import { PaidProduct, PaymentMethod } from 'types';

interface PaymentsState {
  requestPayment: (
    product: PaidProduct,
    title: string,
    description: string,
    onSuccess?: () => void,
    withoutPopup?: boolean,
  ) => void;
  isLoadingPaymentMethods: boolean;
  isAddingPaymentMethod: boolean;
  paymentMethods: PaymentMethod[];
  validatePaymentMethod: (paymentMethod: PaymentMethod) => boolean;
  addPaymentMethodError?: any;
  addPaymentMethodSuccess?: boolean;
  setAddPaymentMethodError: (error: any) => void;
  setAddPaymentMethodSuccess: (success?: boolean) => void;
  addPaymentMethod: (paymentMethod: PaymentMethod) => Promise<void>;
  removePaymentMethod: (paymentMethod: PaymentMethod) => Promise<boolean>;
  iapError?: string;
  setIapError: (error?: string) => void;
  paymentForm?: JSX.Element;
}

const DEFAULT_STATE: PaymentsState = {
  requestPayment: () => {},
  isLoadingPaymentMethods: true,
  isAddingPaymentMethod: false,
  paymentMethods: [],
  validatePaymentMethod: () => false,
  setAddPaymentMethodError: () => {},
  setAddPaymentMethodSuccess: () => {},
  addPaymentMethod: () => Promise.resolve(),
  removePaymentMethod: () => Promise.resolve(false),
  setIapError: () => {},
};

const PaymentsContext = React.createContext<PaymentsState>(DEFAULT_STATE);

export function usePayments(): PaymentsState {
  const context = React.useContext<PaymentsState>(PaymentsContext);
  if (context === undefined) {
    throw new Error('usePayments must be used within a PaymentsProvider');
  }

  return context;
}

interface PaymentsProviderProps {
  navigationRef: MutableRefObject<NavigationContainerRef<any> | null>;
  children: React.ReactNode;
}

export const PaymentsProvider: React.FC<PaymentsProviderProps> = ({ children, navigationRef }) => {
  const [noPopup, setNoPopup] = useState(false);
  const [isBottomSheetClosable, setIsBottomSheetClosable] = useState(true);
  const [product, setProduct] = useState<PaidProduct>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [iapError, setIapError] = useState<string>();
  const onSuccessRef = useRef<() => void>();
  const { axiosInstance } = useBackEnd();
  const {
    isLoading: isLoadingPaymentMethods,
    isAdding: isAddingPaymentMethod,
    paymentMethods,
    validate: validatePaymentMethod,
    addError: addPaymentMethodError,
    addSuccess: addPaymentMethodSuccess,
    setAddError: setAddPaymentMethodError,
    setAddSuccess: setAddPaymentMethodSuccess,
    add: addPaymentMethod,
    remove: removePaymentMethod,
  } = usePaymentMethods();
  const { subscriptionApplePaymentCallback } = useSubscriptionIAP();
  const { tippingApplePaymentCallback } = useTippingIAP();
  const { feeBasedPostApplePaymentCallback } = useFeeBasedPostIAP();

  useEffect(() => {
    const initIap = async () => {
      await InAppPurchases.connectAsync();
      console.log('/// ...  IAP connected to the store');

      InAppPurchases.setPurchaseListener(({ responseCode, results, errorCode }) => {
        setIapError(undefined);
        console.log('/// ... PurchaseListener');
        // Purchase was successful
        if (responseCode === InAppPurchases.IAPResponseCode.OK) {
          results?.forEach(async (purchase) => {
            if (!purchase.acknowledged) {
              console.log(`/// ... Successfully purchased ${purchase.productId}`);

              // Process transaction here and unlock content...
              const response = await axiosInstance.post(`/apple_iap/apple_product_id_type`, {
                apple_product_id: results[0].productId,
              });
              const { object_type }: { object_type: 'Tip' | 'SubscriptionPlan' } = response.data;

              const callback =
                object_type === 'Tip'
                  ? tippingApplePaymentCallback
                  : object_type === 'SubscriptionPlan'
                  ? subscriptionApplePaymentCallback
                  : feeBasedPostApplePaymentCallback;

              callback({
                data: results[0].transactionReceipt,
                apple_product_id: results[0].productId,
                timestamp: results[0].purchaseTime,
                transaction_id: results[0].orderId,
              })
                .catch((e) => {
                  const message = 'BE error';
                  console.log(`/// ... ${message}`, e);
                  setIapError(message);
                })
                .then((r) => {
                  console.log(`/// ... BE response`, r);
                  // Then when you're done
                  InAppPurchases.finishTransactionAsync(purchase, true);
                });
            }
          });
        } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
          const message = 'User canceled the transaction';
          console.log(`/// ... ${message}`);
          setIapError('canceled');
        } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
          const message = 'User does not have permissions to buy but requested parental approval';
          console.log(`/// ... ${message} (iOS only)`);
          setIapError(message);
        } else {
          const message = `Something went wrong with the purchase. Received errorCode ${errorCode}`;
          console.warn(`/// ... ${message}`);
          setIapError(message);
        }
      });
    };
    if (Platform.OS === 'ios') {
      initIap();
    }
  }, []);

  const requestPayment = useCallback(
    (
      product?: PaidProduct,
      title?: string,
      description?: string,
      onSuccess?: () => void,
      withoutPopup?: boolean,
    ) => {
      setProduct(product);
      if (!product) return;
      if (title) setTitle(title);
      if (description) setDescription(description);
      setIsOpen(true);
      setNoPopup(!!withoutPopup);
      onSuccessRef.current = onSuccess;
    },
    [],
  );

  const executeOnSuccess = useCallback(async () => {
    if (onSuccessRef.current) {
      await onSuccessRef.current();
      onSuccessRef.current = undefined;
    }
  }, []);

  const paymentForm = useMemo(
    () => (
      <PaymentContainer
        navigationRef={navigationRef}
        product={product}
        title={title}
        description={description}
        isLoadingPaymentMethods={isLoadingPaymentMethods}
        paymentMethods={paymentMethods}
        onSuccess={executeOnSuccess}
        setIsOpen={setIsOpen}
        iapError={iapError}
        setIapError={setIapError}
        onProcessingChange={(isProcessing) => setIsBottomSheetClosable(!isProcessing)}
      />
    ),
    [
      navigationRef?.current,
      product,
      title,
      description,
      isLoadingPaymentMethods,
      paymentMethods,
      executeOnSuccess,
      setIsOpen,
      iapError,
      setIapError,
      setIsBottomSheetClosable,
    ],
  );

  return (
    <PaymentsContext.Provider
      value={{
        requestPayment,
        isLoadingPaymentMethods,
        isAddingPaymentMethod,
        paymentMethods,
        validatePaymentMethod,
        addPaymentMethodError,
        addPaymentMethodSuccess,
        setAddPaymentMethodError,
        setAddPaymentMethodSuccess,
        addPaymentMethod,
        removePaymentMethod,
        iapError,
        setIapError,
        paymentForm,
      }}
    >
      {children}
      {!noPopup && (
        <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen} isClosable={isBottomSheetClosable}>
          {paymentForm}
        </BottomSheet>
      )}
    </PaymentsContext.Provider>
  );
};
