import styled from 'styled-components/native';

export default styled.Text.attrs(() => ({
  numberOfLines: 1,
}))<{ large?: boolean; size?: number }>`
  font-size: ${({ size }) => size || 15}px;
  color: ${({ theme }) => theme.orderPreview.primaryText};
  font-family: ${({ large }) => (large ? 'BasierCircle-Regular' : 'BasierCircle-Bold')};
  line-height: 20px;
  ${({ theme }) => theme.media.tabletLandscape && 'margin-bottom: 8px;'}
`;
