import styled, { css } from 'styled-components/native';

import TextInput from 'components/TextInput';

interface Props {
  secondary?: boolean;
  isAnswer?: boolean;
}

export default styled(TextInput)<Props>`
  margin: 10px 0;
  ${({ secondary }) =>
    secondary &&
    css`
      border-style: dashed;
    `}
  ${({ isAnswer }) =>
    isAnswer &&
    css`
      padding-left: 30px;
    `}
`;
