import React from 'react';
import { TouchableOpacity } from 'react-native';

import { ImageRatio } from '../types';
import Label from './Label';

interface Props {
  type: ImageRatio;
  selected: boolean;
  label: string;
  onSelect: (type: ImageRatio) => void;
}

const ArtistPhotoEditScreen: React.FC<Props> = ({ type, selected, label, onSelect }: Props) => {
  return (
    <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => onSelect?.(type)}>
      <Label selected={selected}>{label}</Label>
    </TouchableOpacity>
  );
};

export default ArtistPhotoEditScreen;
