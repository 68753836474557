import React from 'react';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components/native';

import SignInWithFacebookButton from 'components/SocialButtons/SignInWithFacebookButton/SignInWithFacebookButton';
import SignInWithGoogleButton from 'components/SocialButtons/SignInWithGoogleButton/SignInWithGoogleButton';

import { FACEBOOK_APP_ID, useAuth } from 'hooks/auth';
import { getFingerprint } from 'utils/fingerprint';

const Line = styled.View`
  width: 85%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primaryGhost};
`;

const Text = styled.Text`
  width: 131px;
  height: 20px;
  font-size: 15px;
  letter-spacing: -0.23px;
  position: absolute;
  color: ${({ theme }) => theme.holdingPage.textSecondary};
  background-color: ${({ theme }) => theme.continueWith.background};
  text-align: center;
`;

const DividerView = styled.View`
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`;
const MainView = styled.View`
  position: relative;
`;
const IconsView = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  height: 90px;
  justify-content: space-between;
`;

interface ContinueWithViewProps {}

const ContinueWithView: React.FC<ContinueWithViewProps> = () => {
  const { signInWithGoogle, signInWithFacebookWeb } = useAuth();

  const signInWithFacebook = async (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ) => {
    const browserId = await getFingerprint();
    signInWithFacebookWeb(response, browserId);
  };

  return (
    <MainView>
      <DividerView>
        <Line />
        <Text>or</Text>
      </DividerView>
      <IconsView>
        <SignInWithGoogleButton onPress={signInWithGoogle} />
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          callback={signInWithFacebook}
          render={(renderProps: any) => <SignInWithFacebookButton onPress={renderProps.onClick} />}
        />
      </IconsView>
    </MainView>
  );
};

export default ContinueWithView;
