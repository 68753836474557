import { useNavigation } from '@react-navigation/native';
import React, { memo, useCallback, useEffect } from 'react';

import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from 'screens/LiveStreamPlaybackScreen/constants';

import LiveMomentList from 'components/LiveMomentList';

import { useFeedHandlers } from 'context';
import { useLiveMoments } from 'hooks';
import { LiveMoment } from 'types';

const LiveMoments: React.FC = () => {
  const navigation = useNavigation();
  const { isLoading, liveMoments: data, error } = useLiveMoments();
  const { refresh: refreshFeed } = useFeedHandlers();

  const onItemPress = useCallback(
    (liveMoment: LiveMoment) => {
      navigation.navigate(LIVE_STREAM_PLAYBACK_SCREEN_NAME, {
        artistUsername: liveMoment.artist.username,
        url: liveMoment.m3u8_url || liveMoment.mp4_url,
        isLive: liveMoment.is_live,
        liveMomentId: liveMoment.id,
        isPastLiveMoment: !!liveMoment.mp4_url,
      });
    },
    [navigation],
  );

  useEffect(() => {
    if (data && data.length > 0) refreshFeed();
  }, [data, refreshFeed]);

  return (
    <LiveMomentList
      data={error ? [] : data}
      withPlaceholder={isLoading}
      onItemPress={onItemPress}
    />
  );
};

LiveMoments.displayName = 'LiveMoments';

export default memo(LiveMoments);
