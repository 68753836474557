import { useFonts } from 'expo-font';

export function useFontBasierCircle(): boolean {
  const [loaded] = useFonts({
    'BasierCircle-Bold': require('assets/fonts/BasierCircle-Bold.otf'),
    'BasierCircle-BoldItalic': require('assets/fonts/BasierCircle-BoldItalic.otf'),
    'BasierCircle-Medium': require('assets/fonts/BasierCircle-Medium.otf'),
    'BasierCircle-MediumItalic': require('assets/fonts/BasierCircle-MediumItalic.otf'),
    'BasierCircle-Regular': require('assets/fonts/BasierCircle-Regular.otf'),
    'BasierCircle-RegularItalic': require('assets/fonts/BasierCircle-RegularItalic.otf'),
    'BasierCircle-SemiBold': require('assets/fonts/BasierCircle-SemiBold.otf'),
    'BasierCircle-SemiBoldItalic': require('assets/fonts/BasierCircle-SemiBoldItalic.otf'),
  });

  return loaded;
}

export function useFontBasierSquare(): boolean {
  const [loaded] = useFonts({
    'BasierSquare-Bold': require('assets/fonts/BasierSquare-Bold.otf'),
    'BasierSquare-BoldItalic': require('assets/fonts/BasierSquare-BoldItalic.otf'),
    'BasierSquare-Medium': require('assets/fonts/BasierSquare-Medium.otf'),
    'BasierSquare-MediumItalic': require('assets/fonts/BasierSquare-MediumItalic.otf'),
    'BasierSquare-Regular': require('assets/fonts/BasierSquare-Regular.otf'),
    'BasierSquare-RegularItalic': require('assets/fonts/BasierSquare-RegularItalic.otf'),
    'BasierSquare-SemiBold': require('assets/fonts/BasierSquare-SemiBold.otf'),
    'BasierSquare-SemiBoldItalic': require('assets/fonts/BasierSquare-SemiBoldItalic.otf'),
  });

  return loaded;
}

export function useMiscFonts(): boolean {
  const [loaded] = useFonts({
    'Roboto-Medium': require('assets/fonts/Roboto-Medium.ttf'),
  });

  return loaded;
}
