export default {
  purchaseQuestionnaire: {
    id: 'marketplaceProductDetails.purchaseQuestionare',
    defaultMessage: 'Questions for buyer',
  },
  questionnaireDescription: {
    id: 'marketplaceProductDetails.questionnaireDescription',
    defaultMessage: 'This section is not visible for buyer till making the order.',
  },
  question: {
    id: 'marketplaceProductDetails.question',
    defaultMessage: 'Question {number}',
  },
  orderDetails: {
    id: 'marketplaceProductDetails.orderDetails',
    defaultMessage: 'Order details',
  },
  amountPaid: {
    id: 'marketplaceProductDetails.amountPaid',
    defaultMessage: 'Amount paid',
  },
  paymentMethod: {
    id: 'marketplaceProductDetails.paymentMethod',
    defaultMessage: 'Payment method',
  },
  order: {
    id: 'marketplaceProductDetails.order',
    defaultMessage: 'Order',
  },
  save: {
    id: 'marketplaceProductDetails.save',
    defaultMessage: 'Save',
  },
  share: {
    id: 'marketplaceProductDetails.share',
    defaultMessage: 'Share product',
  },
  more: {
    id: 'marketplaceProductDetails.more',
    defaultMessage: 'More',
  },
  edit: {
    id: 'marketplaceProductDetails.edit',
    defaultMessage: 'Edit',
  },
  seeInsights: {
    id: 'marketplaceProductDetails.seeInsights',
    defaultMessage: 'See Insights',
  },
  soldCount: {
    id: 'marketplaceProductDetails.soldCount',
    defaultMessage: 'sold <sold></sold> of <stock></stock>',
  },
};
