import { getPathFromState, useLinkTo, useNavigation } from '@react-navigation/native';
import React, { useCallback, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { linking as linkingArtist } from 'stacks/artist/ArtistRootStack/linking';
import { linking as linkingFan } from 'stacks/fan/FanRootStack/linking';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen/constants';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen';

import { NAVIGATION_STATE_PERSISTENCE_KEY } from 'containers/App/constants';
import ArtistsFeed from 'containers/ArtistsFeed';

import ArtistHistoryResult from 'components/ArtistHistoryResult';
import ConfirmPaymentModalWeb from 'components/ConfirmPaymentModalWeb';

import { VARIANT } from 'context';
import { useLocalStorage, useSearch, useSearchArtistsHistory } from 'hooks';
import { ArtistProduct } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import Avatar from './styled/Avatar';
import CloseIcon from './styled/CloseIcon';
import InputWrapper from './styled/InputWrapper';
import Item from './styled/Item';
import List from './styled/List';
import MainWrapper from './styled/MainWrapper';
import RecentSearchesWrapper from './styled/RecentSearchesWrapper';
import SearchIcon from './styled/SearchIcon';
import SearchInput from './styled/SearchInput';
import Subheading from './styled/Subheading';
import Title from './styled/Title';
import TouchableOpacity from './styled/TouchableOpacity';
import WebContainer from './styled/WebContainer';

export { SCREEN_NAME } from './constants';

interface Artist {
  username: string;
  display_name: string;
}

interface Props {}

const SearchAndDiscovery: React.FC<Props> = () => {
  const searchInputRef = useRef(null);
  const [webArtistUsername, setWebArtistUsername] = useState<string | undefined>('');
  const [webArtistFullName, setWebArtistFullName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { artistsResults, searchQuery, changeSearchMode, changeSearchQuery } = useSearch();
  const { searchArtistsHistory, addArtistToHistory, deleteArtistFromHistory } =
    useSearchArtistsHistory();
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const { value, loading } = useLocalStorage(NAVIGATION_STATE_PERSISTENCE_KEY);

  const navigateHome = useCallback(() => {
    navigation.navigate(HOME_SCREEN_NAME);
  }, [navigation]);

  const onGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    if (Platform.OS !== 'web' || loading || !value) {
      navigateHome();
      return;
    }
    const options = VARIANT === 'fan' ? linkingFan.config : linkingArtist.config;
    const path = getPathFromState(value, options);
    if (!path || path === '/') {
      navigateHome();
      return;
    }
    linkTo(path);
  }, [navigation, value, loading]);

  const onBlurAction = () => {
    changeSearchMode(false);
  };

  const onFocusAction = () => {
    changeSearchMode(true);
  };

  const navigateToArtist = useCallback(
    async (result) => {
      setWebArtistFullName('');
      setWebArtistUsername('');
      navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
        artistUsername: result.username,
      });
      await addArtistToHistory(result.username);
    },
    [navigation],
  );

  const navigateToProduct = useCallback(
    async (product: ArtistProduct) => {
      navigation.navigate(SINGLE_POST_SCREEN_NAME, {
        screen: SINGLE_POST_SCREEN_NAME,
        artistUsername: product.artist.username,
        productType: product.model_name,
        productId: product.id,
        product: product,
      });
    },
    [navigation],
  );

  const onDeleteArtistClick = useCallback(async (username: string) => {
    await deleteArtistFromHistory(username);
  }, []);

  const renderRecentSearches = () => {
    if (searchArtistsHistory) {
      return searchArtistsHistory.map((artist: Artist) => {
        return (
          <ArtistHistoryResult
            key={`artists-recent-search-${artist.username}`}
            artistThumbnailUrl={artist.low_res_profile_picture}
            artistFullname={artist.display_name}
            onArtistClick={() => navigateToArtist(artist)}
            onDeleteClick={() => onDeleteArtistClick(artist.username)}
          />
        );
      });
    }
  };

  const renderArtistsResults = () => {
    if (artistsResults?.length) {
      return artistsResults.map((result) => {
        return (
          <Item key={`result-${result.username}`}>
            <TouchableOpacity onPress={() => navigateToArtist?.(result)}>
              <Avatar
                mode="minimal-row-small"
                name={result.display_name}
                isLive={result.is_live}
                imageUrl={getProfileLowResPictureUrl(result)}
              />
            </TouchableOpacity>
          </Item>
        );
      });
    }
    return <></>;
  };

  return (
    <WebContainer>
      <MainWrapper>
        <CloseIcon onPress={onGoBack} />
        <Title>Search Stanbase</Title>
        <Subheading>Search for artists</Subheading>
        <InputWrapper>
          <SearchInput
            ref={searchInputRef}
            placeholder="Type Artist name"
            returnKeyType="search"
            value={searchQuery}
            onBlur={() => {
              if (!searchQuery) {
                onBlurAction();
              }
            }}
            onFocus={() => onFocusAction()}
            onChangeText={changeSearchQuery}
          />
          <SearchIcon />
        </InputWrapper>
        <List>{renderArtistsResults()}</List>
        {!!searchArtistsHistory && !!searchArtistsHistory.length && (
          <>
            <Subheading>Recent searches</Subheading>
            <RecentSearchesWrapper>{renderRecentSearches()}</RecentSearchesWrapper>
          </>
        )}

        <Subheading>Discover Artists</Subheading>
        <ArtistsFeed navigateToArtist={navigateToArtist} navigateToProduct={navigateToProduct} />
      </MainWrapper>
      {isModalOpen && (
        <ConfirmPaymentModalWeb
          artistFullName={webArtistFullName}
          artistUsername={webArtistUsername}
          setIsOpen={setIsModalOpen}
        />
      )}
    </WebContainer>
  );
};

export default SearchAndDiscovery;
