import styled from 'styled-components/native';

export default styled.View`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;
