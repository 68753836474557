import { Platform, StatusBar } from 'react-native';
import styled from 'styled-components/native';

export default styled.SafeAreaView`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  align-items: flex-end;
  padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
`;
