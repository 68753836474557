import React from 'react';

import { parseCurrency, PRIMARY_CURRENCY } from 'utils/currency';

import Coins2 from './images/2.png';
import Coins5 from './images/5.png';
import Coins10 from './images/10.png';
import CoinsChoice from './images/choice.png';
import Badge from './styled/Badge';
import Coins from './styled/Coins';
import CustomTouchableOpacity from './styled/CustomTouchableOpacity';
import Gradient from './styled/Gradient';
import Price from './styled/Price';
import View from './styled/View';

interface TippingCardPriceProps {
  price: number;
  isActive?: boolean;
  isMostPopular?: boolean;
  index: number;
  currentIndex?: number;
  onCardPress?: () => void;
}

const TippingCardPrice: React.FC<TippingCardPriceProps> = ({
  price,
  isActive,
  isMostPopular,
  index,
  currentIndex,
  onCardPress,
}: TippingCardPriceProps) => {
  const renderCoins = () => {
    if (index === 0) {
      return <Coins source={Coins2} isActive={isActive} />;
    }
    if (index === 1) {
      return <Coins source={Coins5} isActive={isActive} />;
    }
    if (index === 2) {
      return <Coins source={Coins10} isActive={isActive} />;
    }
    return <Coins source={CoinsChoice} isActive={isActive} />;
  };

  return (
    <CustomTouchableOpacity onPress={onCardPress}>
      <View isActive={isActive}>
        {renderCoins()}
        <Price>{price ? `${parseCurrency(PRIMARY_CURRENCY)}${price}` : 'Your choice'}</Price>
        {isMostPopular && <Badge text={'Most popular'} />}
        <Gradient isActive={isActive} index={index} currentIndex={currentIndex} />
      </View>
    </CustomTouchableOpacity>
  );
};

export default TippingCardPrice;
