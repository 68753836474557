import styled, { css } from 'styled-components/native';

import CachedImage from 'components/CachedImage';

import { AvatarMode } from '../Avatar';

interface ImageProps {
  mode: AvatarMode;
}

export default styled(CachedImage)<ImageProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 22px;

  ${(props) =>
    props.mode === 'expanded' &&
    css`
      width: 44px;
      height: 44px;
      border-radius: 22px;
    `}
`;
