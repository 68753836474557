import { AxiosError } from 'axios';
import { useState } from 'react';

import { useSnackbarSet } from 'context';
import { useBackEnd } from 'context/backEnd';
import { CommentAddFunc, CommentDeleteFunc, Comments, ProductType } from 'types';
import { getModelNamePlural } from 'utils/product';
import { containsLink } from 'utils/regex';

import { useAuthSwr } from './swr';

export interface UseComments {
  addComment: CommentAddFunc;
  deleteComment: CommentDeleteFunc;
  comments: Comments;
  commentAddError?: string;
  isLoading: boolean;
  isProcessingComment: (id: string | number) => boolean;
  error: AxiosError<any>;
}

export interface UseCommentsArgs {
  artistUsername: string;
  mockValue?: Comments;
  productId: number;
  productModelName: ProductType;
  onCommentsChange?: (comments: Comments) => void;
}

export const COMMENTS_REFRESH_INTERVAL = 10000;

export function useComments({
  artistUsername,
  mockValue,
  productId,
  productModelName,
  onCommentsChange,
}: UseCommentsArgs): UseComments {
  const COMMENTS_BASE_KEY = `/artists/${artistUsername}/products/${getModelNamePlural(
    productModelName,
  )}/${productId}/comments`;
  const [commentAddError, setCommentAddError] = useState<string>('');
  const { axiosInstance } = useBackEnd();
  const { setSnackbar } = useSnackbarSet();

  const { isLoading, data, error, mutate } = useAuthSwr<Comments>({
    key: COMMENTS_BASE_KEY,
    isPublic: true,
    cache: true,
    swrOptions: {
      refreshInterval: COMMENTS_REFRESH_INTERVAL,
    },
    mockValue,
  });

  const addComment: CommentAddFunc = async ({ text, displayName, profilePicture }) => {
    if (containsLink(text)) {
      setSnackbar({
        visible: true,
        label: 'Comments cannot contain external links.',
        actionLabel: 'Close',
        dismissAfter: 3000,
      });
    } else {
      const cachedComments = data ? [...data] : [];
      const fakeId = `fake-${cachedComments.length + 1}`;
      const processedComments = [
        ...cachedComments,
        {
          display_name: displayName || '',
          profile_picture: profilePicture,
          time_created: new Date(),
          id: fakeId,
          text,
          is_processing: true,
        },
      ];

      mutate(processedComments, false);

      try {
        const { data: newComment } = await axiosInstance.post(COMMENTS_BASE_KEY, {
          text,
        });
        const newComments = [...cachedComments, newComment];
        await mutate(newComments, true);
        onCommentsChange?.(newComments);
      } catch (e) {
        mutate(cachedComments, true);
        setCommentAddError(e.response);
      }
    }
  };

  const deleteComment = async (id: string | number) => {
    const newComments = data?.filter((singleComment) => singleComment.id !== id) || [];
    try {
      mutate(newComments, false);
      await axiosInstance.delete(`${COMMENTS_BASE_KEY}/${id}`);
      onCommentsChange?.(newComments);
      mutate();
    } catch (e) {
      mutate();
    }
  };

  const isProcessingComment = (id: string | number) =>
    data?.find((comment) => comment.id === id)?.is_processing === true;

  return {
    isLoading,
    addComment,
    deleteComment,
    isProcessingComment,
    comments: data || [],
    commentAddError,
    error,
  };
}
