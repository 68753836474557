import * as React from 'react';

import ArrowIcon from './styled/ArrowIcon';
import Container from './styled/Container';
import Label from './styled/Label';
import SuffixLabel from './styled/SuffixLabel';
import Switch from './styled/Switch';
import TouchableOpacity from './styled/TouchableOpacity';

export interface OptionGroupItemProps {
  isFirst?: boolean;
  label: string;
  labelColor?: string;
  labelPrefixComponent?: JSX.Element;
  labelSuffixText?: string;
  value?: any;
  type?: 'action' | 'switch';
  onAction?: (value?: any) => void;
  noArrow?: boolean;
  isDeleteButton?: boolean;
}

const OptionGroupItem: React.FC<OptionGroupItemProps> = ({
  isFirst,
  label,
  labelColor,
  labelPrefixComponent,
  labelSuffixText,
  value,
  type,
  onAction = () => {},
  noArrow = false,
  isDeleteButton = false,
  ...rest
}: OptionGroupItemProps): JSX.Element => {
  const renderOption = (labelSuffix: JSX.Element) => (
    <Container isFirst={isFirst} {...rest} isDeleteButton={isDeleteButton}>
      {labelPrefixComponent && labelPrefixComponent}
      <Label labelColor={labelColor}>{label}</Label>
      {labelSuffixText && <SuffixLabel>{labelSuffixText}</SuffixLabel>}
      {!noArrow && labelSuffix}
    </Container>
  );

  if (type === 'switch') {
    return renderOption(<Switch checked={value as boolean} onChange={() => onAction(value)} />);
  }

  return (
    <TouchableOpacity onPress={() => onAction(value)}>
      {renderOption(<ArrowIcon />)}
    </TouchableOpacity>
  );
};

OptionGroupItem.defaultProps = {
  isFirst: true,
  type: 'action',
};

OptionGroupItem.displayName = 'OptionGroupItem';

export default OptionGroupItem;
