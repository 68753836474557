import * as ImagePicker from 'expo-image-picker';
import { useState } from 'react';
import { Alert, Linking, Platform } from 'react-native';
import RNImagePicker, { Image, ImageOrVideo, Options } from 'react-native-image-crop-picker';
import { useTheme } from 'styled-components/native';

interface Props {
  onMediaChange?: (result?: ImageOrVideo | ImagePicker.ImagePickerResult) => void;
}

export function useImagePicker(options?: Props) {
  const [isPickingMedia, setIsPickingMedia] = useState(false);
  const theme = useTheme();

  const askForPermissions = () => {
    Alert.alert(
      'Permissions',
      'App needs to access your photos, do you want to open settings now?',
      [
        { text: 'Open settings', onPress: Linking.openSettings },
        { text: 'Cancel', style: 'cancel' },
      ],
    );
  };

  const checkCameraRollPermissions = async () => {
    if (Platform.OS !== 'web') {
      let permissions;
      try {
        permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
        console.log(permissions);
      } catch (e) {
        console.log('cannot get media library permissions');
        console.log(
          'returning permissions status "true" to get the permissions status after opening the camera roll',
        );
        return true;
      }
      if (!permissions?.granted || permissions.canAskAgain) {
        const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        return granted;
      } else {
        askForPermissions();
        return false;
      }
    }
    return true;
  };

  const launchImagePicker: (options: {
    pickerNativeOptions?: Options;
    pickerWebOptions?: ImagePicker.ImagePickerOptions;
  }) => Promise<void> = async ({ pickerNativeOptions, pickerWebOptions }) => {
    setIsPickingMedia(true);
    if (await checkCameraRollPermissions()) {
      try {
        let result;
        if (Platform.OS !== 'web' && pickerNativeOptions) {
          result = await RNImagePicker.openPicker(pickerNativeOptions);
        } else if (Platform.OS === 'web' && pickerWebOptions) {
          result = await ImagePicker.launchImageLibraryAsync(pickerWebOptions);
        }
        options?.onMediaChange?.(result);
        console.debug(result);
      } catch (e) {
        console.debug('error code:', e.code);
        console.debug('error message:', e.message);
        if (e.code === 'E_NO_LIBRARY_PERMISSION') {
          askForPermissions();
        }
      } finally {
        setIsPickingMedia(false);
      }
    } else {
      setIsPickingMedia(false);
    }
  };

  const launchImageCropper = async (props: {
    cropperOptions: {
      path: string;
      width?: number;
      height?: number;
      forceJpg: boolean;
      cropperCircleOverlay?: boolean;
    };
    onCrop: (image: Image) => void;
  }) => {
    setIsPickingMedia(true);
    try {
      const image = await RNImagePicker.openCropper({
        ...props.cropperOptions,
        cropperToolbarTitle: 'Crop photo',
        cropperStatusBarColor: theme.colors.background,
        cropperToolbarColor: theme.colors.background,
        cropperActiveWidgetColor: theme.button.backgroundColorBlue,
        cropperToolbarWidgetColor: theme.colors.text,
        mediaType: 'photo',
      });
      props.onCrop?.(image);
    } catch (e) {
      console.log(e);
    }
    setIsPickingMedia(false);
  };

  return {
    isPickingMedia,
    launchImagePicker,
    launchImageCropper,
  };
}
