export default {
  snackbar: {
    title: {
      id: 'blockedUsers.snackbar.title',
      defaultMessage: '<name></name> unblocked.',
    },
    close: {
      id: 'blockedUsers.snackbar.close',
      defaultMessage: 'Close',
    },
  },
  error: {
    unblock: {
      id: 'blockedUsers.error.unblock',
      defaultMessage: 'Unexpected error has occurred.',
    },
  },
};
