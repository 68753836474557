import React from 'react';
import styled from 'styled-components/native';

import CroppButton from './CroppButton';
import EditButton from './EditButton';

const Image = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

const View = styled.View`
  position: relative;
  width: 105px;
  height: 105px;
  margin: 10px 0 0 0;
  align-self: center;
  background: ${({ theme }) => theme.orderPreview.imageBg};
  border-radius: 6px;
`;

interface ImageProps {
  uri?: string;
  onEditPress?: () => void;
  onCroppPress?: () => void;
  isEditable?: boolean;
}

const ProductImage: React.FC<ImageProps> = ({
  uri,
  onEditPress,
  onCroppPress,
  isEditable,
}: ImageProps) => {
  return (
    <View>
      {uri && (
        <Image
          source={{
            uri: uri,
          }}
        />
      )}
      {uri && isEditable && <CroppButton onPress={onCroppPress} />}
      {isEditable && <EditButton onPress={onEditPress} />}
    </View>
  );
};

export default ProductImage;
