import styled, { css } from 'styled-components/native';

interface LabelProps {
  bold?: boolean;
  primary?: boolean;
  secondary?: boolean;
  alternative?: boolean;
  warning?: boolean;
  labelColor?: string;
  pay?: boolean;
  input?: boolean;
  inputActive?: boolean;
  disabled?: boolean;
}

export default styled.Text<LabelProps>`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  font-family: BasierCircle-Regular;
  z-index: 1;
  color: ${({ theme }) => theme.colors.background};

  ${({ primary, theme }) =>
    primary &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ secondary, theme }) =>
    secondary &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ alternative, theme }) =>
    alternative &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ warning, theme }) =>
    warning &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ pay, theme }) =>
    pay &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ input, theme }) =>
    input &&
    css`
      color: ${theme.colors.primaryInactive};
      padding: 20px 12px;
      font-weight: normal;
    `}

  ${({ inputActive, theme }) =>
    inputActive &&
    css`
      color: ${theme.colors.text};
      padding: 20px 12px;
      font-weight: normal;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.primaryInactive};
    `}

  ${({ labelColor }) =>
    labelColor &&
    css`
      color: ${labelColor};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-family: BasierCircle-Medium;
    `}
`;
