const privacyPolicyURL = 'https://stanbase.com/legal/stanbase_privacy_policy.pdf';
const termsURL = 'https://stanbase.com/legal/stanbase_terms_for_all_users.pdf';
const paymentTermsURL = 'https://stanbase.com/legal/stanbase_terms_for_fans.pdf';
const nondiscriminationPolicyURL = 'https://stanbase.com/legal/stanbase_acceptable_use_policy.pdf';
const helpURL = 'https://stanbase.com/help-form';
const fanAppStoreURL = 'https://apps.apple.com/pl/app/stanbase/id1570240159';
const fanGooglePlayURL = 'https://play.google.com/store/apps/details?id=com.stanbase.fan';
const artistAppStoreURL = 'https://apps.apple.com/pl/app/stanbase-for-artists/id1570267256';
const artistGooglePlayURL = 'https://play.google.com/store/apps/details?id=com.stanbase.artist';
const twitterURL = 'https://twitter.com/wearestanbase';
const facebookURL = 'https://facebook.com/wearestanbase';
const instagramURL = 'https://instagram.com/wearestanbase';
const youtubeURL = 'https://youtube.com/wearestanbase';
const tiktokURL = 'https://tiktok.com/@wearestanbase';

export {
  artistAppStoreURL,
  artistGooglePlayURL,
  facebookURL,
  fanAppStoreURL,
  fanGooglePlayURL,
  helpURL,
  instagramURL,
  nondiscriminationPolicyURL,
  paymentTermsURL,
  privacyPolicyURL,
  termsURL,
  tiktokURL,
  twitterURL,
  youtubeURL,
};
