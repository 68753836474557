import styled from 'styled-components/native';

export default styled.TouchableOpacity<{ isActive?: boolean; mobile?: boolean }>`
  align-self: flex-start;
  background: ${({ theme, isActive }) =>
    isActive ? theme.marketplace.decoratedTitleBg : 'transparent'};
  border-radius: 10px;
  padding: 8px 15px;
  margin: ${({ mobile }) => (mobile ? '31px 15px 25px' : '0 0 0 40px')};
`;
