export default {
  tabs: {
    subscribed: {
      id: 'settings.tabs.subscribed',
      defaultMessage: 'Subscribed',
    },
    followed: {
      id: 'settings.tabs.followed',
      defaultMessage: 'Followed',
    },
  },
  yourArtists: {
    price: {
      id: 'settings.yourArtists.subscribed.price',
      defaultMessage: '${price}/mth',
    },
    followed: {
      id: 'settings.yourArtists.subscribed.followed',
      defaultMessage: 'Followed',
    },
  },
};
