import styled from 'styled-components/native';

export default styled.View`
  width: 368px;
  background: #1a2143;
  border-radius: 14px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 40px 24px;
`;
