import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  width: 100%;
  height: 50px;
  margin-bottom: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;
