import { format, isSameDay } from 'date-fns';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { VARIANT } from 'context';
import { MarketplaceOrderMessage } from 'types';
import { MarketplaceOrderMessageUser } from 'types/MarketplaceOrder';

import messages from '../messages';
import IconRespond from './IconRespond';

const MainView = styled.View`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: 15px 15px 20px;
`;

const Label = styled.View`
  margin: 0 0 15px;
  flex-direction: row;
  justify-content: space-between;
`;

const Footer = styled.View`
  flex-direction: row-reverse;
  margin: 13px 0 0;
`;

const MainText = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  font-family: 'BasierCircle-Regular';
`;

const Author = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  font-family: 'BasierCircle-Medium';
`;

const DateText = styled.Text`
  color: ${({ theme }) => theme.colors.backgroundLight};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  font-family: 'BasierCircle-Regular';
`;

interface Props {
  message: MarketplaceOrderMessage;
  showRespond?: boolean;
  onRespondPress?: () => void;
}

const Message: React.FC<Props> = ({ message, showRespond, onRespondPress }: Props) => {
  const intl = useIntl();
  const isOwn = useMemo(
    () =>
      VARIANT === 'artist'
        ? message.user.user_type == MarketplaceOrderMessageUser.ARTIST
        : message.user.user_type == MarketplaceOrderMessageUser.FAN,
    [message],
  );

  const formattedDate = useMemo(() => {
    try {
      const date = new Date(message.time_created);
      return format(date, isSameDay(date, Date.now()) ? 'H:mm' : 'dd/MMM, H:mm');
    } catch (e) {
      return 'error';
    }
  }, [message.time_created]);

  return (
    <MainView>
      <Label>
        <Author>
          {message.user.display_name || message.user.full_name}
          {isOwn && ` ${intl.formatMessage(messages.message.ownSuffix)}`}
        </Author>
        <DateText>{formattedDate}</DateText>
      </Label>
      <MainText>{message.text}</MainText>
      {showRespond && (
        <Footer>
          <TouchableOpacity onPress={onRespondPress}>
            <IconRespond />
          </TouchableOpacity>
        </Footer>
      )}
    </MainView>
  );
};

export default Message;
