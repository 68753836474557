import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.pollContent.primary};
  position: absolute;
  right: 15px;
  margin-left: 5px;
  font-size: 15px;
  font-family: BasierCircle-Regular;
  z-index: 10;
`;
