import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  font-family: 'BasierCircle-Semibold';
  font-weight: bold;
`;
