import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View`
  ${Platform.OS === 'web' &&
  css`
    max-width: 300px;
    margin: 0 auto;
  `}
  padding: 23px 8px 0 8px;
`;
