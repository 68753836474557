import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused?: boolean;
}

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21 15L16 10L5 21"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledIconActive = styled(SvgComponent)`
  opacity: 1;
`;

const StyledIconInactive = styled(SvgComponent)`
  opacity: 0.52;
`;

const Icon = ({ focused, ...rest }: IconProps) => (
  <TabBarIcon
    focused={!!focused}
    iconActive={<StyledIconActive />}
    iconInactive={<StyledIconInactive />}
    {...rest}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
