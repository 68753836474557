import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard } from 'react-native';

import { VARIANT } from 'context/appInfo';
import { UserGeneralInformation } from 'hooks/user';
import regex from 'utils/regex';

import Container from './styled/Container';
import ErrorsView from './styled/ErrorsView';
import ErrorText from './styled/ErrorText';
import MainView from './styled/MainView';
import SubmitButton from './styled/SubmitButton';
import TextInput from './styled/TextInput';

export interface ProfileInformationsFormProps {
  fullName?: string;
  username?: string;
  email?: string;
  isUpdating?: boolean;
  updateError?: string;
  bio?: string;
  link?: string;
  width?: number;
  update: (data: UserGeneralInformation) => void;
}

const ProfileInformationsForm: React.FC<ProfileInformationsFormProps> = ({
  email,
  updateError,
  isUpdating,
  fullName,
  username,
  bio,
  link,
  width,
  update,
}: ProfileInformationsFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    fullName: string;
    username: string;
    email: string;
    bio: string;
    secondary_link: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      fullName,
      username,
      email,
      bio,
      secondary_link: link,
    },
  });
  const [allowSave, setAllowSave] = useState<boolean>(false);

  const onValidSubmit = async (args: {
    username: string;
    email: string;
    fullName: string;
    bio: string;
    secondary_link: string;
  }) => {
    const { fullName, bio, secondary_link, username } = args;

    update({ username, fullName, bio, secondary_link });
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  return (
    <MainView>
      <Container width={width}>
        {VARIANT !== 'artist' && (
          <Controller
            control={control}
            defaultValue={username}
            name="username"
            render={({ onChange, onBlur, value }) => (
              <TextInput
                label="Username"
                returnKeyType="next"
                autoCapitalize="none"
                value={value}
                onBlur={onBlur}
                editable={true}
                onChangeText={(value: string) => {
                  clearErrors('username');
                  setAllowSave(true);
                  onChange(value);
                }}
              />
            )}
          />
        )}

        <Controller
          control={control}
          defaultValue={fullName}
          name="fullName"
          render={({ onChange, onBlur, value }) => (
            <TextInput
              label="Full name"
              returnKeyType="next"
              autoCapitalize="none"
              value={value}
              onBlur={onBlur}
              editable={!(VARIANT === 'artist')}
              onChangeText={(value: string) => {
                clearErrors('fullName');
                setAllowSave(true);
                onChange(value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={email}
          name="email"
          render={({ onChange, onBlur, value }) => (
            <TextInput
              label={'E-mail address'}
              returnKeyType={VARIANT === 'artist' ? 'next' : 'go'}
              autoCapitalize="none"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: string) => {
                clearErrors('email');
                setAllowSave(true);
                onChange(value);
              }}
              editable={false}
            />
          )}
        />

        {VARIANT === 'artist' && (
          <>
            <Controller
              control={control}
              defaultValue={bio}
              name="bio"
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  multiline
                  label={'Bio'}
                  returnKeyType="next"
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('bio');
                    setAllowSave(true);
                    onChange(value);
                  }}
                  editable={true}
                />
              )}
            />

            <Controller
              control={control}
              defaultValue={link}
              name="secondary_link"
              rules={{
                pattern: {
                  value: regex.link,
                  message: 'Not a valid link.',
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  label={'Link'}
                  returnKeyType="go"
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('secondary_link');
                    setAllowSave(true);
                    onChange(value);
                  }}
                  editable={true}
                />
              )}
            />
          </>
        )}

        {!!updateError && (
          <ErrorsView>
            <ErrorText>{updateError}</ErrorText>
          </ErrorsView>
        )}
      </Container>
      <SubmitButton
        disabled={!isValid || !allowSave || isUpdating}
        processing={isSubmitting || isUpdating}
        onPress={onSubmit}
      >
        Save Changes
      </SubmitButton>
    </MainView>
  );
};

export default ProfileInformationsForm;
