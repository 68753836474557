import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Button from './Button';

export default styled(Button)`
  margin-right: 8px;

  ${Platform.OS === 'web' &&
  css`
    margin: 0;
    background-color: transparent;
  `}
`;
