export default {
  cardItem: {
    id: 'settings.paymentMethods.cardItem',
    defaultMessage: 'Card •••• {lastDigits}',
  },
  addCard: {
    id: 'settings.paymentMethods.addCard',
    defaultMessage: 'Add Payment Method',
  },
  addAnotherCard: {
    id: 'settings.paymentMethods.addAnotherCard',
    defaultMessage: 'Add another card',
  },
};
