import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import handsImage from './images/hands.png';
import messages from './messages';
import Chevron from './styled/Chevron';
import Emoji from './styled/Emoji';
import Icon from './styled/Icon';
import IconBox from './styled/IconBox';
import Label from './styled/Label';
import PendingIcon from './styled/PendingIcon';
import TouchableOpacity from './styled/TouchableOpacity';

interface Props {
  variant: 'subscribed' | 'canceled' | 'locked';
  onSubscribePress?: () => void;
}

const emojisMap = [
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
  `\u{1F512}`,
];

const getRandomEmoji = () => {
  const emojiIndex = Math.ceil(Math.random() * 10) - 1;

  return emojisMap[emojiIndex];
};

const ArtistNameBadge: React.FC<Props> = ({ variant, onSubscribePress, ...rest }: Props) => {
  const intl = useIntl();
  const randomEmoji = useRef<string>(getRandomEmoji());

  return (
    <TouchableOpacity
      disabled={variant === 'locked'}
      variant={variant}
      onPress={onSubscribePress}
      {...rest}
    >
      <IconBox>
        {variant === 'canceled' ? (
          <PendingIcon />
        ) : variant === 'subscribed' ? (
          <Icon source={handsImage} />
        ) : (
          variant === 'locked' && <Emoji>{randomEmoji.current}</Emoji>
        )}
      </IconBox>
      <Label>{intl.formatMessage(messages[variant])}</Label>
      {variant !== 'locked' && <Chevron />}
    </TouchableOpacity>
  );
};

export default React.memo(ArtistNameBadge);
