import styled from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

export default styled(ActivityIndicator).attrs({ size: 18 })`
  position: absolute;
  left: 28px;
  top: 15px;
  color: ${(props) => props.theme.searchBar.iconColor};
`;
