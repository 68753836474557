import styled, { css } from 'styled-components/native';

import { AvatarMode } from '../Avatar';

interface NameTextProps {
  mode: AvatarMode;
  withTextEllipsis?: boolean;
}

export default styled.Text<NameTextProps>`
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  font-family: BasierCircle-Regular;

  ${(props) =>
    props.mode === 'compact' &&
    css`
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.06px;
    `}

  ${(props) =>
    (props.mode === 'minimal-row' || props.mode === 'minimal-row-big') &&
    css`
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -0.23px;
    `}

  ${(props) =>
    props.mode === 'minimal-row-small' &&
    css`
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.43px;
    `}

  ${({ withTextEllipsis }) =>
    withTextEllipsis &&
    css`
      max-width: 50px;
      overflow: hidden;
    `}
`;
