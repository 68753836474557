import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import React from 'react';

import { useTheme } from 'themes';
import darkTheme from 'themes/dark';
import lightTheme from 'themes/light';
import AppTheme from 'themes/theme';

const Tab = createBottomTabNavigator();

const getNavigatorOptions = (theme: AppTheme): BottomTabNavigationOptions => ({
  headerShown: false,
  tabBarActiveTintColor: theme.colors.primaryActive,
  tabBarInactiveTintColor: theme.colors.primaryInactive,
  tabBarActiveBackgroundColor: theme.tabBar.background,
  tabBarInactiveBackgroundColor: theme.tabBar.background,
  tabBarStyle: {
    backgroundColor: theme.tabBar.background,
    borderTopColor: 'rgba(255, 255, 255, 0.12)',
  },
  tabBarShowLabel: false,
});

type Props = typeof Tab.Navigator & {
  children: React.ReactNode;
};

const TabNavigatorStyled: React.FC<Props> = ({ children, ...rest }: Props) => {
  const theme = useTheme();
  const screenOptions = getNavigatorOptions(theme.mode === 'light' ? lightTheme : darkTheme);

  return (
    <Tab.Navigator screenOptions={screenOptions} {...rest}>
      {children}
    </Tab.Navigator>
  );
};

export default {
  Navigator: TabNavigatorStyled,
  Screen: Tab.Screen,
};
