import styled from 'styled-components/native';

interface TextProps {
  completedOrder?: boolean;
}

export default styled.Text<TextProps>`
  color: ${({ theme }) => theme.bottomSheet.text};
  font-size: 22px;
  letter-spacing: -0.26px;
  font-weight: bold;
  margin-top: ${({ completedOrder }) => (completedOrder ? '5' : '35')}px;
`;
