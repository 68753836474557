import styled from 'styled-components/native';

export default styled.View<{ width: number }>`
  align-items: center;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: ${({ width }) => width || 100}px;
`;
