import { useBackEnd } from 'context/backEnd';
import { ArtistProduct } from 'types';
import { FeedItemUpdateFn } from 'types/Feed';

export interface UseLike {
  likesCount?: number;
  toggleLike: () => void;
}

export interface UseLikeArgs {
  product: ArtistProduct;
  updateFeedItem: FeedItemUpdateFn;
}

export function useLike({ product, updateFeedItem }: UseLikeArgs): UseLike {
  const LIKE_BASE_KEY = `/artists/${product.artist.username}/products/${product.model_name}s/${product.id}/like`;
  const { axiosInstance } = useBackEnd();

  const toggleLike = async () => {
    const sendLike = product.is_liked ? axiosInstance.delete : axiosInstance.post;
    const newLikesCount = product.is_liked ? product.likes_count - 1 : product.likes_count + 1;
    const newLike = !product.is_liked;
    const cachedLikes = product.likes_count;

    updateFeedItem(
      product,
      {
        is_liked: newLike,
        likes_count: newLikesCount,
      },
      false,
    );

    try {
      const { data } = await sendLike(LIKE_BASE_KEY);
      await updateFeedItem(product, {
        is_liked: newLike,
        likes_count: data?.likes_count,
      });
    } catch (e) {
      await updateFeedItem(product, {
        is_liked: product.is_liked,
        likes_count: cachedLikes,
      });
    }
  };

  return {
    toggleLike,
  };
}
