import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';
import Fade from 'components/Transitions/Fade/Fade';

interface ViewProps {
  mode?: 'row';
}

const View = styled.View<ViewProps>`
  background-color: ${({ theme }) => theme.insights.background};
  padding: 15px;
  margin: 15px 0;
  border-radius: 14px;
  ${({ mode }) =>
    mode == 'row' &&
    css`
      width: 47.8%;
      aspect-ratio: 1;
    `}
`;

const Title = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-family: 'BasierCircle-Medium';
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  margin-bottom: 5px;
`;

const ChildrenView = styled(Fade)`
  flex-grow: 1;
`;

const OverlayContainer = styled.View`
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  justify-content: center;
  align-items: center;
`;

interface Props {
  mode?: 'row';
  title: string;
  isLoading?: boolean;
}

const Tile: React.FC<Props> = ({
  mode,
  title,
  isLoading = false,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <View mode={mode}>
      <Title>{title}</Title>
      {!isLoading && <ChildrenView>{children}</ChildrenView>}
      <OverlayContainer>
        <ActivityIndicator animating={isLoading} />
      </OverlayContainer>
    </View>
  );
};

export default Tile;
