import styled from 'styled-components'; // this is intentionally the web styled components

export default styled.video`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;

  * {
    display: none;
  }

  .vjs-big-play-button {
    display: none;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
`;
