import React from 'react';
import Svg, { Circle, Defs, LinearGradient, Stop } from 'react-native-svg';
import styled from 'styled-components/native';

const ImageLiveBox: React.FC = (props) => {
  return (
    <Svg width={'100%'} height={'100%'} viewBox="0 0 50 50" fill="none" {...props}>
      <Circle
        cx={25}
        cy={25}
        r={24}
        fill="rgba(0,0,0,0)"
        stroke="url(#prefix__paint0_linear)"
        strokeWidth={2}
      />
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear"
          x1={0}
          y1={0}
          x2={50}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#0537FF" />
          <Stop offset={0.517} stopColor="#9A26FF" />
          <Stop offset={1} stopColor="#1DFF78" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default styled(ImageLiveBox)`
  position: absolute;
`;
