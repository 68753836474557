import styled from 'styled-components/native';

export default styled.View`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
