import styled, { css } from 'styled-components/native';

import MuteButton from 'components/MuteButton';

interface Props {
  showControlsBottom?: boolean;
  isFullscreen?: boolean;
}

export default styled(MuteButton)<Props>`
  position: absolute;
  right: 7px;

  ${({ showControlsBottom, isFullscreen }) =>
    showControlsBottom
      ? css`
          right: 15px;
          bottom: ${isFullscreen ? '55px' : '15px'};
        `
      : css`
          top: 7px;
        `}
`;
