import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StatusBar, View } from 'react-native';
import {
  FlatList,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

import ActivityIndicator from 'components/ActivityIndicator';
import Fade from 'components/Transitions/Fade/Fade';

import { useTheme } from 'themes';
import { SPRING_CONFIG } from 'utils/animated';
import { screenWidth } from 'utils/screen';

import * as S from './FlatListWithCover.styled';

type AnimatedGHContext = {
  startX: number;
  startY: number;
  activeIndex: number;
};

interface FlatListWithCoverProps {
  coverImageUrl?: string;
  coverImageHeight?: number;
  coverSpacerHeight?: number;
  renderHeader: () => JSX.Element;
  topTitle?: string;
  refreshing?: boolean;
  onRefresh?: () => void;
  activeTabIndex: number;
  setActiveTabIndex?: (index: number) => void;
  renderTab: (index: number) => JSX.Element;
  tabs: number;
}

const FlatListWithCover: React.FC<FlatListWithCoverProps> = ({
  coverImageUrl,
  coverImageHeight = 320,
  coverSpacerHeight = coverImageHeight,
  renderHeader,
  refreshing,
  onRefresh,
  topTitle = '',
  activeTabIndex,
  setActiveTabIndex = () => {},
  renderTab,
  tabs,
  ...rest
}) => {
  const ref = useRef<FlatList>();
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);
  const { theme } = useTheme();
  const statusBarHeight = StatusBar.currentHeight || 0;
  const displayTopTitle = useMemo(() => {
    if (!topTitle) return '';
    else return topTitle.length > 21 ? `${topTitle.substring(0, 21)}...` : topTitle;
  }, [topTitle]);
  const translateX = useSharedValue(0);
  const sharedIndex = useSharedValue(0);
  const scrollPositionY = useSharedValue(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const extractMainKey = (item: unknown, index: number) => {
    switch (index) {
      case 0:
        return 'spacer';
      case 1:
        return 'header';
      default:
        return 'content';
    }
  };

  const onCoverImageLoaded = () => {
    setIsCoverLoaded(true);
  };

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollPositionY.value = event.contentOffset.y;
  });

  const scrollTopTop = () => {
    if (scrollPositionY.value > headerHeight) {
      ref.current?.scrollToIndex({
        index: 1,
        viewPosition: 0,
        viewOffset: 150,
        animated: true,
      });
    }
  };

  useEffect(() => {
    //handle tabbar icon press
    if (activeTabIndex !== sharedIndex.value) {
      sharedIndex.value = activeTabIndex;
      translateX.value = -screenWidth * activeTabIndex;
    }
  }, [activeTabIndex]);

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (_, ctx) => {
      ctx.startX = translateX.value;
      ctx.activeIndex = sharedIndex.value;
    },
    onActive: (event, ctx) => {
      translateX.value = ctx.startX + event.translationX;
    },
    onEnd: (event, ctx) => {
      if (event.translationX < 0) {
        if (ctx.activeIndex <= tabs - 1 && ctx.activeIndex >= 0) {
          ctx.activeIndex !== tabs - 1
            ? (translateX.value = -screenWidth * (ctx.activeIndex + 1))
            : (translateX.value = -screenWidth * tabs - 1);

          sharedIndex.value !== tabs - 1 && (sharedIndex.value += 1);
          runOnJS(setActiveTabIndex)(sharedIndex.value);
        }
      }
      if (event.translationX > 0) {
        if (ctx.activeIndex >= 0) {
          ctx.activeIndex !== 0
            ? (translateX.value = -screenWidth * (ctx.activeIndex - 1))
            : (translateX.value = 0);

          sharedIndex.value !== 0 && (sharedIndex.value -= 1);
          runOnJS(setActiveTabIndex)(sharedIndex.value);
        }
      }
      runOnJS(scrollTopTop)();
    },
  });

  const headerStyle = useAnimatedStyle(
    () => ({
      opacity: withSpring(
        interpolate(scrollPositionY.value, [coverSpacerHeight - 75, coverSpacerHeight], [0, 1], {
          extrapolateRight: Extrapolation.CLAMP,
          extrapolateLeft: Extrapolation.CLAMP,
        }),
        SPRING_CONFIG.NO_BOUNCE,
      ),
    }),
    [coverSpacerHeight],
  );

  const imageStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: withSpring(
          interpolate(scrollPositionY.value, [100, 0], [1, 1.5], {
            extrapolateRight: Extrapolation.CLAMP,
            extrapolateLeft: Extrapolation.CLAMP,
          }),
          SPRING_CONFIG.NO_BOUNCE,
        ),
      },
    ],
  }));

  const tabWrapperStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: withSpring(translateX.value, SPRING_CONFIG.NO_BOUNCE),
      },
    ],
  }));

  const coverStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(
        scrollPositionY.value,
        [0, coverSpacerHeight - statusBarHeight],
        [0, 1],
        { extrapolateRight: Extrapolation.CLAMP },
      ),
    }),
    [coverSpacerHeight, statusBarHeight],
  );

  const renderTabs = () => (
    <PanGestureHandler onGestureEvent={gestureHandler} activeOffsetX={[-10, 10]}>
      <S.ContentView style={tabWrapperStyle}>
        {new Array(tabs).fill(null).map((_, index) => (
          <Animated.View key={`tab-${index}`} style={{ width: screenWidth }}>
            {renderTab(index)}
          </Animated.View>
        ))}
      </S.ContentView>
    </PanGestureHandler>
  );

  const renderMainItem = (info: { item: unknown; index: number }) => {
    switch (info.item) {
      case 'spacer':
        return (
          <View
            onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
            style={{ paddingTop: coverSpacerHeight }}
          >
            {renderHeader()}
          </View>
        );
      case 'content':
        return renderTabs();
      default:
        return null;
    }
  };

  return (
    <View {...rest}>
      <S.CoverView height={coverImageHeight}>
        <S.CoverRelativeView>
          <Fade isVisible={isCoverLoaded}>
            <Animated.View style={imageStyle}>
              {!!coverImageUrl && (
                <S.CoverImage
                  source={{ uri: coverImageUrl }}
                  resizeMode="cover"
                  onLoad={onCoverImageLoaded}
                />
              )}
            </Animated.View>
          </Fade>
          <S.CoverImageGradient colors={['transparent', theme.colors.background]} />
          <ActivityIndicator animating={!isCoverLoaded} />
          <S.Dimmer style={coverStyle} />
        </S.CoverRelativeView>
      </S.CoverView>
      <S.Flatlist
        //@ts-ignore
        ref={ref}
        data={['spacer', 'content']}
        listKey="main"
        renderItem={renderMainItem}
        keyExtractor={extractMainKey}
        scrollEnabled={true}
        contentContainerStyle={{ flexGrow: 1 }}
        scrollEventThrottle={16}
        refreshing={refreshing}
        onRefresh={onRefresh}
        onScroll={scrollHandler}
        progressViewOffset={20}
      />
      <S.HeaderView style={headerStyle}>
        <S.HeaderText>{displayTopTitle}</S.HeaderText>
      </S.HeaderView>
    </View>
  );
};

export default FlatListWithCover;
