import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function LinkIcon(props: SvgProps) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M9.99984 4.66663H11.9998C12.4376 4.66663 12.871 4.75285 13.2754 4.92036C13.6799 5.08788 14.0473 5.33341 14.3569 5.64294C14.6664 5.95247 14.9119 6.31993 15.0794 6.72435C15.247 7.12877 15.3332 7.56222 15.3332 7.99996C15.3332 8.4377 15.247 8.87115 15.0794 9.27557C14.9119 9.67999 14.6664 10.0475 14.3569 10.357C14.0473 10.6665 13.6799 10.912 13.2754 11.0796C12.871 11.2471 12.4376 11.3333 11.9998 11.3333H9.99984M5.99984 11.3333H3.99984C3.5621 11.3333 3.12864 11.2471 2.72423 11.0796C2.31981 10.912 1.95234 10.6665 1.64281 10.357C1.01769 9.73186 0.666504 8.88401 0.666504 7.99996C0.666504 7.1159 1.01769 6.26806 1.64281 5.64294C2.26794 5.01782 3.11578 4.66663 3.99984 4.66663H5.99984"
        stroke="#BCFFC9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.3335 8H10.6668"
        stroke="#BCFFC9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledLinkIcon = styled(LinkIcon)`
  width: 16px;
  height: 16px;
  margin-right: 7px;
  transform: rotate(-45deg);
`;

export default React.memo(StyledLinkIcon);
