import { BillingAddress } from './BillingAddress';
import { CreditCardBaseInfo } from './CreditCardInfo';

export interface MarketplaceOrderProduct {
  id: number;
  name: string;
  description: string;
  low_res_cover: string;
  low_res_cover_width: number;
  low_res_cover_height: number;
  artist: {
    username: string;
    display_name: string;
    cover_image: string;
    low_res_profile_picture: string;
  };
}

export interface MarketplaceQuestionareAnswer {
  questionare: {
    id: number;
    question: string;
  };
  text: string;
}

export interface MarketplaceOrderPayment {
  amount_currency: string;
  amount: string;
  credit_card: CreditCardBaseInfo & {
    billing_address: BillingAddress;
  };
}

export enum OrderStatus {
  /** Stan hasn't made a payment yet */
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  /** Paid but artist hasn't interacted with the order yet */
  PURCHASED = 'PURCHASED',
  /** Artist declined */
  DECLINED = 'DECLINED',
  /** Artist has interacted with the order */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Stan marked as completed */
  COMPLETED = 'COMPLETED',
  /** Cancelled by stan */
  CANCEL = 'CANCEL',
}

/** translated OrderStatus to use as keys etc. */
export type OrderStatusTranslation =
  | 'purchased'
  | 'waiting'
  | 'inprogress'
  | 'completed'
  | 'declined'
  | 'canceled';

export interface MarketplaceOrderAttachment {
  id: number;
  name: string;
  file: string;
  file_size: number;
  time_created: string;
}

export interface MarketPlaceOrderUser {
  low_res_profile_picture: string;
  profile_picture: string;
  full_name: string;
}

export enum MarketplaceOrderMessageUser {
  FAN = 1,
  ARTIST = 2,
}

export interface MarketplaceOrderMessage {
  text: string;
  user: {
    display_name: string;
    full_name: string;
    user_type: MarketplaceOrderMessageUser;
  };
  time_created: string;
  is_read: boolean;
}

export interface MarketplaceOrder {
  product: MarketplaceOrderProduct;
  questionare_answers: Array<MarketplaceQuestionareAnswer>;
  payment: MarketplaceOrderPayment;
  external_id: string;
  status: OrderStatus;
  time_created: string;
  time_updated: string;
  attachment: MarketplaceOrderAttachment;
  messages: Array<MarketplaceOrderMessage>;
  purchase_comment: string;
  is_artist_asked_user_for_confirmation: boolean;
  user: MarketPlaceOrderUser;
  number: string;
}
