import { FC } from 'react';
import { Platform, PressableProps } from 'react-native';

import Button from './styled/Button';
import IconPause from './styled/IconPause';
import IconPlay from './styled/IconPlay';

export interface VideoPlayPauseButtonProps {
  isPlaying?: boolean;
}

const VideoPlayPauseButton: FC<VideoPlayPauseButtonProps & PressableProps> = ({
  isPlaying,
  ...rest
}) => (
  <Button blur={Platform.OS === 'web' ? 8 : Platform.OS === 'android' ? 32 : 3} {...rest}>
    {isPlaying ? <IconPause /> : <IconPlay />}
  </Button>
);

export default VideoPlayPauseButton;
