import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  stroke?: string;
  fill?: string;
}

const SvgComponent: React.FC<Props> = ({ stroke = '#fff', fill = 'none' }: Props) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill}>
      <Path
        d="M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
