import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import styled, { css } from 'styled-components/native';

export default styled(Animated.View)`
  position: absolute;
  width: 92%;
  left: 4%;
  bottom: 3%;
  align-items: center;
  padding: 0 15px 25px 15px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.bottomSheet.background};
  ${Platform.OS === 'web' &&
  css`
    transition: transform 0.3s ease;
    ${({ theme }) =>
      theme.media.tablet &&
      css`
        left: calc(50% - 250px);
        max-width: 500px;
      `}
  `}
`;
