import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

const SvgComponent: React.FC = (props) => {
  return (
    <Svg width={16} height={16} fill="none" {...props}>
      <G opacity={0.52} stroke="#fff">
        <Path
          d="M12.667 5.333H3.333C2.597 5.333 2 5.93 2 6.667V11a4 4 0 004 4h4a4 4 0 004-4V6.667c0-.737-.597-1.334-1.333-1.334zM4.667 5.333v-.666a3.333 3.333 0 016.666 0v.666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Circle cx={8} cy={9.333} r={1.333} />
        <Path strokeLinecap="round" d="M8 11.167v1" />
      </G>
    </Svg>
  );
};

export default SvgComponent;
