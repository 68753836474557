import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({
  short: true,
  bold: false,
})`
  z-index: 50;
  border-radius: 40px;
  height: 32px;
  width: 91px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 7px;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      margin-left: 16px;
      margin-top: 0;
    `}
`;
