import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components';

const DropdownArrow: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6 9L12 15L18 9"
        stroke="white"
        strokeOpacity="0.52"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const StyledArrow = styled(DropdownArrow)`
  margin-top: 3px;
`;

export default StyledArrow;
