import styled from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 44px;
`;
