import styled from 'styled-components/native';

import Input from 'components/Input';

import darkTheme from 'themes/dark';

export default styled(Input).attrs({
  placeholderTextColor: darkTheme.searchBar.iconColor,
  autoCorrect: false,
})`
  background-color: ${({ theme }) => theme.searchBar.backgroundColor};
  border-radius: 10px;
  border-color: transparent;
  border-width: 0;
  flex: 1;
  font-size: 17px;
  height: 36px;
  padding: 0 40px;
`;
