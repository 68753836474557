import React, { useCallback, useMemo } from 'react';

import { useUser } from 'hooks/user';
import { getProfileLowResPictureUrl } from 'utils/user';

import ImageBackground from './styled/ImageBackground';
import ImageWrapper from './styled/ImageWrapper';
import TabBarIcon from './styled/TabBarIcon';

interface TabBarProfileIconProps {
  focused: boolean;
  hasNotifications?: boolean;
}

const TabBarProfileIcon: React.FC<TabBarProfileIconProps> = ({ ...restProps }) => {
  const { profile } = useUser();
  const iconSource = useMemo(() => ({ uri: getProfileLowResPictureUrl(profile) }), [profile]);

  const renderIcon = useCallback(
    (focused: boolean) => (
      <ImageWrapper focused={focused}>
        <ImageBackground source={iconSource} />
      </ImageWrapper>
    ),
    [iconSource],
  );

  const iconActive = useMemo(() => renderIcon(true), [renderIcon]);
  const iconInactive = useMemo(() => renderIcon(false), [renderIcon]);

  return <TabBarIcon iconActive={iconActive} iconInactive={iconInactive} {...restProps} />;
};

export default TabBarProfileIcon;
