import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import { parseCurrency, PRIMARY_CURRENCY } from 'utils/currency';

import messages from '../messages';

const View = styled.View`
  position: relative;
  width: 315px;
  height: 64px;
  background-color: ${({ theme }) => theme.bottomSheet.input.background};
  border: 1px solid ${({ theme }) => theme.bottomSheet.input.border};
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding-left: 19px;
  margin-top: 30px;
  align-self: center;
`;

const InputText = styled.TextInput`
  width: 315px;
  height: 64px;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.bottomSheet.secondText};
  font-size: 15px;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.bottomSheet.text};
  margin: 0 13px 0 0px;
  font-size: 22px;
`;

interface InputProps {
  value?: number;
  onChangeValue?: () => void;
}

const Input: React.FC<InputProps> = ({ value, onChangeValue }: InputProps) => {
  const intl = useIntl();
  return (
    <View>
      <Text>{parseCurrency(PRIMARY_CURRENCY)}</Text>
      <InputText
        placeholder={intl.formatMessage(messages.inputPlaceholder)}
        onChangeText={onChangeValue}
        value={value}
        keyboardType="numeric"
        placeholderTextColor="rgba(255, 255, 255, 0.52)"
      />
    </View>
  );
};

export default Input;
