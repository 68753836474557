import React, { useCallback, useMemo, useState } from 'react';

import { PlaceholderTextProps } from 'components/PlaceholderText/PlaceholderText';

import { useImageCache } from 'context/ImageCache';
import { useTimeAgo } from 'hooks/useTimeAgo';

import Image from './styled/Image';
import ImageView from './styled/ImageView';
import InteractiveLiveBadge from './styled/InteractiveLiveBadge';
import LiveBadge from './styled/LiveBadge';
import LoadingIndicator from './styled/LoadingIndicator';
import NameText from './styled/NameText';
import NameView from './styled/NameView';
import PlaceholderName from './styled/PlaceholderName';
import TextView from './styled/TextView';
import TimestampText from './styled/TimestampText';
import TimestampTextPlaceholder from './styled/TimestampTextPlaceholder';
import View from './styled/View';

export type AvatarMode =
  | 'expanded'
  | 'compact'
  | 'compact-small'
  | 'row'
  | 'minimal-row'
  | 'minimal-row-big'
  | 'minimal-row-small';

export interface AvatarProps {
  /** Display mode of the Avatar component */
  mode: AvatarMode;
  /** Artist name */
  name?: string;
  /** Artist username */
  username?: string;
  /** Profile picture URL */
  imageUrl?: string;
  /** Timestamp to be shown next to the avatar. Usually indicating last related activity */
  timestamp?: string;
  /** Whether to display the component in a placeholder mode (usually while loading actual data) */
  isPlaceholder?: boolean;
  /** If true, will display a "LIVE" badge */
  isLive?: boolean;
  /** Shows / hides the timestamp */
  showTimestamp?: boolean;
  /** Shows / hides the name label */
  showName?: boolean;
  /** The text to be used as the "LIVE" label */
  liveLabel?: string;
  /** Props controlling the parameters of the placeholder text to be rendered in placeholder mode */
  placeholderNameProps?: PlaceholderTextProps;
  /** How frequently the timestamp should be updated, in milliseconds */
  timestampUpdateInterval?: number;
  /** If true, the name will be truncated when its maximum length is exceeded */
  withTextEllipsis?: boolean;
  /** Callback triggered when the user presses the component */
  onPress?: () => void;
}

/**
 * Artist avatar component showing, most importantly, their name and profile picture.
 * It can be displayed in various modes depending on where it is used.
 */
const Avatar: React.FC<AvatarProps> = ({
  name,
  username,
  imageUrl,
  mode = 'row',
  timestamp,
  isPlaceholder,
  isLive = false,
  showTimestamp = true,
  showName = true,
  liveLabel = 'LIVE',
  placeholderNameProps,
  timestampUpdateInterval = 5000,
  withTextEllipsis,
  onPress,
  ...restProps
}: AvatarProps) => {
  const { cache } = useImageCache();
  const timestampDate = useMemo(() => (timestamp ? new Date(timestamp) : undefined), [timestamp]);
  const { timeAgoText } = useTimeAgo(timestampDate, timestampUpdateInterval);
  const [isImageLoaded, setIsImageLoaded] = useState(imageUrl && cache.has(imageUrl));

  const onImageLoadEnd = useCallback(() => {
    try {
      setIsImageLoaded(true);
    } catch (e) {
      // component has already unmounted
    }
  }, []);

  return (
    <View {...restProps} mode={mode} disabled={!onPress} onPress={onPress}>
      <ImageView showName={showName} isLive={isLive} mode={mode}>
        {!!imageUrl && <Image mode={mode} source={{ uri: imageUrl }} onLoad={onImageLoadEnd} />}
        <LoadingIndicator animating={!isImageLoaded && !isPlaceholder} />
      </ImageView>
      <TextView mode={mode}>
        {showName && (
          <NameView>
            {isPlaceholder ? (
              <PlaceholderName {...placeholderNameProps} />
            ) : (
              <NameText
                mode={mode}
                withTextEllipsis={withTextEllipsis}
                numberOfLines={withTextEllipsis ? 1 : undefined}
              >
                {name}
              </NameText>
            )}
            {['expanded', 'minimal-row', 'minimal-row-small', 'minimal-row-big'].includes(mode) && (
              <>
                {!username && isLive && liveLabel && <LiveBadge text={liveLabel} />}
                {username && <InteractiveLiveBadge isLive={isLive} artistUsername={username} />}
              </>
            )}
          </NameView>
        )}
        {![
          'compact',
          'minimal-row',
          'minimal-row-small',
          'minimal-row-big',
          'compact-small',
        ].includes(mode) && (
          <>
            {isPlaceholder && showTimestamp ? (
              <TimestampTextPlaceholder
                mode={mode}
                minNumWords={mode === 'row' ? 1 : 2}
                maxNumWords={mode === 'row' ? 1 : 3}
                minWordLength={mode === 'row' ? 8 : 2}
                maxWordLength={mode === 'row' ? 8 : 8}
              />
            ) : (
              <>{showTimestamp && <TimestampText mode={mode}>{timeAgoText}</TimestampText>}</>
            )}
          </>
        )}
      </TextView>
    </View>
  );
};

export default Avatar;
