import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { memo } from 'react';
import styled from 'styled-components/native';

const Icon = (props: any) => <MaterialCommunityIcons name="checkbox-blank-outline" {...props} />;

export default memo(styled(Icon).attrs({
  size: 12,
})`
  color: ${({ theme }) => theme.colors.primaryInactive};
  margin-right: 2px;
`);
