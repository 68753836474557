import styled from 'styled-components/native';

export default styled.TextInput`
  background-color: #141824;
  border-radius: 20px;
  border-color: #f5f5f5;
  border-width: 0.4px;
  color: #fafafa;
  padding: 10px 15px;
  justify-content: center;
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  flex: 1;
  margin-left: 8px;
  overflow: hidden;
`;
