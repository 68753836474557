import styled from 'styled-components/native';

export default styled.Text`
  font-size: 13px;
  line-height: 18px;
  font-family: BasierCircle-Regular;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 20px 0;
  text-align: center;
`;
