import styled, { css } from 'styled-components/native';

interface ControlGroupProps {
  rightAlign?: boolean;
  isOnFeed?: boolean;
}

export default styled.View<ControlGroupProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px 0 0;
  width: ${(props) => (props.isOnFeed ? '67px' : 'auto')};

  ${(props) =>
    props.rightAlign &&
    css`
      margin: 0 0 0 20px;
    `}
`;
