import { AxiosError } from 'axios';

import { useAuthSwr } from 'hooks/swr';
import { ArtistsFeed } from 'types';

export const FEED_KEY = '/artists/featured';

export function useArtistsFeed(mockValue?: ArtistsFeed): {
  data?: ArtistsFeed;
  isLoading: boolean;
  error?: AxiosError;
} {
  const { data, error, isLoading } = useAuthSwr<ArtistsFeed>({
    key: FEED_KEY,
    isPublic: false,
    cache: false,
    mockValue,
  });

  return {
    data,
    isLoading,
    error,
  };
}
