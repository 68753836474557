import { Platform } from 'react-native';
import styled from 'styled-components/native';

export default styled.View`
  border-top-width: 1px;
  border-top-color: rgba(250, 250, 250, 0.12);
  justify-content: center;
  padding: 20px 15px;
  background-color: ${({ theme }) => (Platform.OS !== 'web' ? theme.tabBar.background : '#141824')};
`;
