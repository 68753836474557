export default {
  title: {
    fan: {
      id: 'login.email.title.fan',
      defaultMessage: 'Welcome back, Stan! 👋',
    },
    artist: {
      id: 'login.email.title.artist',
      defaultMessage: 'Welcome back, Artist! 👋',
    },
  },
  emailInput: {
    label: {
      id: 'login.emailInput.label',
      defaultMessage: 'E-mail address',
    },
    placeholder: {
      id: 'login.emailInput.placeholder',
      defaultMessage: 'email@domain.com',
    },
    error: {
      required: {
        id: 'login.emailInput.error.required',
        defaultMessage: 'An email address is required',
      },
      invalid: {
        id: 'login.emailInput.error.invalid',
        defaultMessage: 'Provided e-mail address is invalid',
      },
    },
  },
  passwordInput: {
    label: {
      id: 'login.passwordInput.label',
      defaultMessage: 'Password',
    },
    placeholder: {
      id: 'login.passwordInput.placeholder',
      defaultMessage: 'Password',
    },
    error: {
      required: {
        id: 'login.passwordInput.error.required',
        defaultMessage: 'You need to enter a password',
      },
    },
  },
  loginCtaLabel: {
    id: 'login.cta.label',
    defaultMessage: 'Log in',
  },
  resetPasswordCtaLabel: {
    id: 'resetPassword.cta.label',
    defaultMessage: 'Forgot password?',
  },
  createAccountCtaLabel: {
    id: 'createAccount.cta.label',
    defaultMessage: 'Not a Stan yet? <cta>Create account!</cta>',
  },
  error: {
    authentication_failed: {
      id: 'login.error.authenticationFailed',
      defaultMessage:
        'Error signing in with provided credentials. Please double check the entered email and password.',
    },
    email_is_not_verified: {
      id: 'login.error.emailNotVerified',
      defaultMessage:
        'In order to sign in, you need to verify your email address. Click <resend>here</resend> to resend the confirmation email',
    },
  },
  resendVerificationEmail: {
    title: {
      id: 'login.resendVerificationEmail.title',
      defaultMessage: 'Success',
    },
    description: {
      id: 'login.resendVerificationEmail.description',
      defaultMessage: 'Verification e-mail has been sent to  <emailAddress></emailAddress>',
    },
  },
};
