import React from 'react';
import { ViewProps } from 'react-native';

import Button from './styled/Button';
import IconFullscreen from './styled/IconFullscreen';

export interface Props extends ViewProps {
  onPress?: () => void;
  transparent?: boolean;
}

const FullscreenButton: React.FC<Props> = ({ onPress, transparent, ...restProps }: Props) => {
  return (
    <Button transparent={transparent} {...restProps} onPress={onPress}>
      <IconFullscreen />
    </Button>
  );
};

FullscreenButton.defaultProps = {};

export default FullscreenButton;
