import styled from 'styled-components/native';

import FeedItem from 'components/FeedItem';

export default styled(FeedItem).attrs({ isPlaceholder: true, isExpanded: true })`
  margin-bottom: 30px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`;
