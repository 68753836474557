import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen';

import Bookmarked from 'containers/Bookmarked';

import NavigationHeaderFan from 'components/NavigationHeaderFan';

import { ArtistProduct } from 'types';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Header from './styled/Header';
import WebContainer from './styled/WebContainer';

export { SCREEN_NAME };

type SavedPostsScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type SavedPostsScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: SavedPostsScreenRouteProp;
  navigation: SavedPostsScreenNavigationProp;
};

const SavedPostsScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();

  const navigateToProduct = (product: ArtistProduct) => {
    if (product.model_name === 'marketplaceitem') {
      navigation.navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
        artist: product.artist.username,
        productId: product.id,
      });
    } else {
      navigation.navigate(SINGLE_POST_SCREEN_NAME, {
        artistUsername: product.artist.username,
        productType: product.model_name,
        productId: product.id,
        product: product,
      });
    }
  };

  return (
    <WebContainer>
      <NavigationHeaderFan />
      <Header>{intl.formatMessage(messages.title)}</Header>
      <Bookmarked navigateToProduct={navigateToProduct} scrollable={false} />
    </WebContainer>
  );
};

export default SavedPostsScreen;
