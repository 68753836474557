export default {
  closeSnackbar: {
    id: 'artistAudioRecordScreen.snackbar.close',
    defaultMessage: 'Close',
  },
  labelSnackbar: {
    id: 'artistAudioRecordScreen.snackbar.label',
    defaultMessage: 'Audio note was uploaded successfully!',
  },
};
