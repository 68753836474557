import styled from 'styled-components/native';

import LiveStreamVideoPlayer from 'components/LiveStreamVideoPlayer';

export default styled(LiveStreamVideoPlayer)`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
`;
