import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${Platform.OS === 'web'
    ? css`
        width: 450px;
        height: 190px;
        padding: 0 0 30px;
      `
    : css`
        width: 100%;
        height: 240px;
      `}
`;
