import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.Text`
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.primaryInactive};
  letter-spacing: -0.08px;

  ${Platform.OS === 'web' &&
  css`
    margin-bottom: 19px;
  `};
`;
