import styled from 'styled-components/native';

interface Props {
  error?: boolean;
}

export default styled.Text<Props>`
  font-family: BasierCircle-Regular;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.26px;
  color: ${({ theme }) => theme.colors.errorAccent};
  margin: 36px 0;
  max-width: 368px;
`;
