import React from 'react';

export interface CacheState {
  cache: Set<string>;
}

const DEFAULT_STATE: CacheState = {
  cache: new Set(),
};

export const CacheContext = React.createContext<CacheState>(DEFAULT_STATE);

export function useImageCache(): CacheState {
  const context = React.useContext<CacheState>(CacheContext);
  if (context === undefined) {
    throw new Error('useImageCache must be used within provider');
  }
  return context;
}
