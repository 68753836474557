import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

interface LabelProps {
  strong?: boolean;
}

export default styled.Text<LabelProps>`
  color: ${({ theme }) => theme.optionsGroup.text};
  flex: 1;
  font-size: 15px;
  letter-spacing: -0.23px;
  ${mediaWeb(css`
    font-size: 13px;
    letter-spacing: -0.08px;
    font-weight: normal;
    font-family: 'BasierCircle-Regular';
  `)};

  ${({ strong }) =>
    strong &&
    css`
      font-weight: bold;
    `};
`;
