import styled from 'styled-components/native';

import OptionGroupItem from 'components/OptionGroupItem';

export default styled(OptionGroupItem)<{ margin?: string }>`
  border-radius: 14px;
  height: 62px;
  width: 100%;
  margin: ${({ margin }) => margin || 0};
`;
