import React from 'react';

import Link from './styled/Link';
import Separator from './styled/Separator';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';

interface Link {
  title: string;
  onPress?: () => void;
}

interface Props {
  links: Link[];
  expireParents?: boolean;
}

const Breadcrumbs: React.FC<Props> = ({ links, expireParents, ...rest }: Props) => {
  return (
    <View {...rest}>
      {links.map((link, index) => (
        <>
          <TouchableOpacity
            expired={expireParents && index < links.length - 1}
            onPress={link.onPress}
            disabled={!link.onPress}
          >
            <Link>{link.title}</Link>
          </TouchableOpacity>
          {index < links.length - 1 && <Separator expired={expireParents}>{` / `}</Separator>}
        </>
      ))}
    </View>
  );
};

export default Breadcrumbs;
