import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import messages from 'screens/ResetPasswordScreen/messages';

import { FormPasswordErrorsView } from 'components/FormErrorsView';

import usePasswordChecks from 'hooks/usePasswordChecks';

import Container from './styled/Container';
import ErrorsView from './styled/ErrorsView';
import ErrorText from './styled/ErrorText';
import MainView from './styled/MainView';
import SubmitButton from './styled/SubmitButton';
import TextInput from './styled/TextInput';

export interface ProfilePasswordFormProps {
  isUpdating: boolean;
  updateError?: string;
  update: (oldPassword: string, newPassword: string) => void;
  width?: number;
}

const ProfilePasswordForm: React.FC<ProfilePasswordFormProps> = ({
  isUpdating,
  updateError,
  update,
  width,
}: ProfilePasswordFormProps): JSX.Element => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<{
    passwordOld: string;
    passwordNew: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      passwordOld: '',
      passwordNew: '',
    },
  });
  const passwordOldInputRef = useRef(null);
  const passwordNewInputRef = useRef(null);
  const { validatePassword, passwordChecks } = usePasswordChecks({
    missingUpperOrLowerCase: intl.formatMessage(
      messages.passwordInput.error.missingUpperOrLowerCase,
    ),
    missingNumber: intl.formatMessage(messages.passwordInput.error.missingNumber),
    missingSpecialCharacter: intl.formatMessage(
      messages.passwordInput.error.missingSpecialCharacter,
    ),
  });

  const onValidSubmit = async (args: { passwordOld: string; passwordNew: string }) => {
    const { passwordOld, passwordNew } = args;
    reset({
      passwordOld: '',
      passwordNew: '',
    });
    return update(passwordOld, passwordNew);
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  return (
    <MainView>
      <Container width={width}>
        <Controller
          control={control}
          name="passwordOld"
          rules={{
            validate: {
              required: () => true,
            },
          }}
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextInput
              disabled={isUpdating}
              ref={passwordOldInputRef}
              returnKeyType="next"
              autoCapitalize="none"
              placeholder="Current password"
              secureTextEntry={true}
              value={value}
              onBlur={onBlur}
              onChangeText={(value: string) => {
                clearErrors('passwordOld');
                clearErrors('passwordNew');
                onChange(value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="passwordNew"
          rules={{
            validate: {
              combined: validatePassword,
            },
          }}
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextInput
              disabled={isUpdating}
              ref={passwordNewInputRef}
              returnKeyType="go"
              autoCapitalize="none"
              placeholder=" New password"
              secureTextEntry={true}
              value={value}
              onBlur={onBlur}
              onChangeText={(value: string) => {
                clearErrors('passwordOld');
                clearErrors('passwordNew');
                onChange(value);
              }}
            />
          )}
        />

        {!!updateError && (
          <ErrorsView>
            <ErrorText>{updateError}</ErrorText>
          </ErrorsView>
        )}
      </Container>
      <FormPasswordErrorsView passwordChecks={passwordChecks} width={width} />
      <SubmitButton
        disabled={!isValid || isUpdating}
        processing={isSubmitting || isUpdating}
        onPress={onSubmit}
      >
        Change password
      </SubmitButton>
    </MainView>
  );
};

export default ProfilePasswordForm;
