export default {
  thanks: {
    id: 'newArtistSent.thanks',
    defaultMessage: 'Thanks for reaching out!',
  },
  inTouch: {
    id: 'newArtistSent.inTouch',
    defaultMessage: 'We’ll be in touch soon.',
  },
  back: {
    id: 'newArtistSent.back',
    defaultMessage: 'Back to login',
  },
};
