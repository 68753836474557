import React, { useMemo } from 'react';

import { VARIANT } from 'context/appInfo';
import { useNotificationsGreenCircles } from 'hooks/notifications';

interface NotificationState {
  general: boolean;
  marketplaceOrder: boolean;
  marketplaceMessage: boolean;
}

const DEFAULT_STATE: NotificationState = {
  general: false,
  marketplaceOrder: false,
  marketplaceMessage: false,
};

export const NotificationContext = React.createContext(DEFAULT_STATE);

export function useNotificationsContext(): NotificationState {
  const context = React.useContext<NotificationState>(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationsContext must be used within a NotificationsProvider');
  }
  return context;
}

interface Props {
  children: React.ReactNode;
}

export const NotificationsProvider: React.FC<Props> = ({ children }: Props) => {
  const isArtist = VARIANT == 'artist';
  const { data } = useNotificationsGreenCircles(isArtist);

  const value = useMemo<NotificationState>(
    () => ({
      general: data?.show_bell_notification_green_circle || false,
      marketplaceOrder: isArtist
        ? data?.show_cta_processing_marketplace_order_green_circle || false
        : data?.show_cta_completed_order_confirmation_green_circle || false,
      marketplaceMessage: data?.show_unread_marketplace_order_message_green_circle || false,
    }),
    [data, isArtist],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
