import React, { useContext, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';

import screens from './screens';

import OnBoardingContainer from 'containers/OnBoardingContainer';

import ScreenView from 'components/ScreenView';

import { OnBoardingContext } from 'context';

import Dot from './styled/Dot';
import DotView from './styled/DotView';
import ScrollView from './styled/ScrollView';
import SkipButton from './styled/SkipButton';
export { SCREEN_NAME } from './constants';

const { width } = Dimensions.get('window');

//Margin of error in px's
const MARGIN_OF_ERROR = 3;

interface Props {}

const OnBoardingScreen: React.FC<Props> = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const SCREENS_LENGTH = useMemo(() => screens.length, [screens]);
  const scrollViewRef = useRef<any>(null);
  const snapPoints = useMemo(() => screens.map((_, index) => index * width), [screens]);
  const { setOnBoardingStorage } = useContext(OnBoardingContext);
  const intl = useIntl();

  const closeOnBoarding = async () => {
    setOnBoardingStorage(false);
  };

  const onNextPress = async (index: number) => {
    if (scrollViewRef.current && index < screens.length - 1) {
      setCurrentIndex(currentIndex + 1);
      return scrollViewRef.current.scrollTo({ x: snapPoints[index + 1], y: 0, animated: true });
    }
    closeOnBoarding();
  };

  const onScrollEnd = (e) => {
    snapPoints.forEach((_, index) => {
      if (
        e.nativeEvent.contentOffset.x > snapPoints[index] - MARGIN_OF_ERROR &&
        e.nativeEvent.contentOffset.x < snapPoints[index] + MARGIN_OF_ERROR
      ) {
        setCurrentIndex(index);
      }
    });
  };

  const renderScreens = () => {
    return screens.map((screen, index) => {
      return (
        <OnBoardingContainer
          screenName={screen.name}
          title={intl.formatMessage(screen.title)}
          description={intl.formatMessage(screen.description)}
          thumbnail={screen.image}
          index={index}
          length={screens.length}
          onNextPress={() => onNextPress(index)}
          key={`onBoardingScreen-${index}`}
        />
      );
    });
  };

  const renderDots = () => {
    return Array.from(Array(screens.length).keys()).map((_, index) => {
      return <Dot isActive={currentIndex === index} key={`dot-${index}`} />;
    });
  };

  return (
    <ScreenView>
      <SkipButton currentIndex={currentIndex} length={SCREENS_LENGTH} onPress={closeOnBoarding} />
      <ScrollView
        ref={scrollViewRef}
        snapToOffsets={snapPoints}
        decelerationRate={'fast'}
        snapToAlignment={'center'}
        onMomentumScrollEnd={(e) => onScrollEnd(e)}
        disableIntervalMomentum={true}
        pagingEnabled
        horizontal
      >
        {renderScreens()}
      </ScrollView>
      <DotView>{renderDots()}</DotView>
    </ScreenView>
  );
};

export default OnBoardingScreen;
