import styled from 'styled-components/native';

import FormContentView from 'components/FormContentView';

export default styled(FormContentView)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
