export default {
  artistActivity: {
    title: {
      id: 'notificationSettings.artistActivity.title',
      defaultMessage: 'New artist activity',
    },
    description: {
      id: 'notificationSettings.artistActivity.description',
      defaultMessage: 'Know when followed Artist add new photo, video, audio note and pole post.',
    },
  },
  liveMoment: {
    title: { id: 'notificationSettings.liveMoment.title', defaultMessage: 'Live moment' },
    description: {
      id: 'notificationSettings.liveMoment.description',
      defaultMessage: 'Know when followed Artist starts Live moment.',
    },
  },
  recommendations: {
    title: { id: 'notificationSettings.recommendations.title', defaultMessage: 'Recommendations' },
    description: {
      id: 'notificationSettings.recommendations.description',
      defaultMessage: 'If we see something you might be interested in we will let you know.',
    },
  },
  push: { id: 'notificationSettings.push', defaultMessage: 'Push notification' },
  email: { id: 'notificationSettings.email', defaultMessage: 'E-mail from Stanbase' },
};
