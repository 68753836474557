import styled from 'styled-components/native';

interface Props {
  transparent: boolean;
}

export default styled.TouchableOpacity<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: ${({ transparent }) =>
    transparent ? 'transparent' : 'rgba(255, 255, 255, 0.12)'};
`;
