import styled from 'styled-components/native';

export default styled.View`
  padding: 3px 7px;
  background-color: ${({ theme }) => theme.colors.errorAccent};
  border-radius: 6px;
  position: absolute;
  top: 7px;
  right: 7px;
`;
