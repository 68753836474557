import { useMemo } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { VARIANT } from 'context';
import { OrderStatus } from 'types';
import { translateMarketplaceOrderStatus } from 'utils/marketplace';

import messages from './messages';
import Container from './styled/Container';
import Text from './styled/Text';

export interface OrderStatusProps {
  /** The status of the order */
  status: OrderStatus;
  /** is order waiting for confirmation for stan */
  waitingForConfirmation?: boolean;
}

/**
 * Orders status in Marketplace module
 */
const OrderStatusBadge: React.FC<OrderStatusProps> = ({
  status,
  waitingForConfirmation,
  ...rest
}: OrderStatusProps) => {
  const intl = useIntl();
  const translatedStatus = useMemo(() => translateMarketplaceOrderStatus(status), [status]);

  return (
    <Container status={translatedStatus} {...rest}>
      <Text status={translatedStatus}>
        {intl.formatMessage(
          waitingForConfirmation ? messages.needsConfirmation : messages[VARIANT][translatedStatus],
        )}
      </Text>
    </Container>
  );
};

OrderStatusBadge.displayName = 'OrderStatusBadge';

export default OrderStatusBadge;
