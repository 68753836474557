import { useNavigation } from '@react-navigation/native';
import React, { FC, useCallback, useMemo } from 'react';

import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from 'screens/LiveStreamPlaybackScreen/constants';

import { useLiveMoments } from 'hooks/liveMoments';

import Fade from './styled/Fade';
import LiveBadge from './styled/LiveBadge';
import TouchableOpacity from './styled/TouchableOpacity';

export interface Props {
  isLive: boolean;
  artistUsername: string;
}

const LiveBadgeButton: FC<Props> = ({ isLive, artistUsername, ...rest }: Props) => {
  const { liveMoments } = useLiveMoments(); // todo: find a better way to fetch full stream info (with id)
  const navigation = useNavigation();

  const liveStream = useMemo(
    () =>
      liveMoments?.find(
        (liveMoment) =>
          liveMoment.artist.username === artistUsername &&
          liveMoment.is_live === true &&
          !!liveMoment.m3u8_url,
      ),
    [liveMoments],
  );

  const navigateToLiveStream = useCallback(() => {
    if (!liveStream) return;
    navigation.navigate(LIVE_STREAM_PLAYBACK_SCREEN_NAME, {
      artistUsername: liveStream.artist.username || artistUsername,
      url: liveStream.m3u8_url,
      isLive: true,
      liveMomentId: liveStream.id,
      isPastLiveMoment: false,
    });
  }, [liveStream, navigation]);

  return (
    isLive &&
    !!liveStream && (
      <Fade {...rest}>
        <TouchableOpacity onPress={navigateToLiveStream}>
          <LiveBadge count={liveStream?.live_stream_viewers_count ?? 0} />
        </TouchableOpacity>
      </Fade>
    )
  );
};

export default LiveBadgeButton;
