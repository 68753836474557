import styled, { css } from 'styled-components/native';

interface TextProps {
  underline?: boolean;
}

export default styled.Text<TextProps>`
  font-size: 15px;
  color: ${({ theme }) => theme.holdingPage.textSecondary};
  letter-spacing: -0.23px;
  text-align: center;
  margin-top: 7%;

  ${({ underline }) =>
    underline &&
    css`
      color: ${({ theme }) => theme.insights.greenText};
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.insights.greenText} !important;
    `}
`;
