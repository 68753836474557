import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused: boolean;
}

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledIconActive = styled(SvgComponent)`
  opacity: 1;
`;

const StyledIconInactive = styled(SvgComponent)`
  opacity: 0.52;
`;

const Icon = (props: IconProps) => (
  <TabBarIcon iconActive={<StyledIconActive />} iconInactive={<StyledIconInactive />} {...props} />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
