import styled from 'styled-components/native';

import FlatListWithCover from 'components/FlatListWithCover/FlatListWithCover';

import { statusBarPadding } from 'utils/screen';

export const MainView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.colors.background};
`;

export const FlatList = styled(FlatListWithCover)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const TabBarWrapper = styled.View`
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OverlayView = styled.View`
  flex: 1;
  width: 100%;
  padding-top: ${statusBarPadding}px;
`;

export const OverlayHeaderView = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px;
`;

export const MarketplaceWrapper = styled.View``;

export const EmptyTabText = styled.Text`
  flex: 1;
  font-family: 'BasierCircle-Regular';
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: center;
  padding: 20px;
`;
