import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import NotificationsScreen, {
  SCREEN_NAME as NOTIFICATIONS_SCREEN_NAME,
} from 'screens/NotificationsScreen';

export const SCREEN_NAME = 'NotificationsStack';

export interface Props {}

export const linking: PathConfigMap = {
  [NOTIFICATIONS_SCREEN_NAME]: 'notifications',
};

const NotificationsStack = createStackNavigator();

const ArtistNotificationsStack: React.FC<Props> = () => {
  return (
    <NotificationsStack.Navigator
      initialRouteName={NOTIFICATIONS_SCREEN_NAME}
      screenOptions={{ title: 'Notifications' }}
    >
      <NotificationsStack.Screen
        name={NOTIFICATIONS_SCREEN_NAME}
        component={NotificationsScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
    </NotificationsStack.Navigator>
  );
};

export default ArtistNotificationsStack;
