import React from 'react';

import Webview from 'components/Webview';

import { useWebview } from 'hooks/useWebview';

export type WebviewUrlMethod = 'GET' | 'POST';

export interface WebviewInterface {
  isWebviewOpen: boolean;
  setIsWebviewOpen: (isOpen: boolean) => void;
  webviewUrlToOpen: string;
  setWebviewUrlToOpen: (url: string) => void;
  webviewUrlMethod?: WebviewUrlMethod;
  webviewUrlBody?: string;
  openWebView?: (url: string, method?: WebviewUrlMethod, body?: string) => void;
  displayUrl: string;
  currentUrl?: string;
  setCurrentUrl: (url: string) => void;
}

const webviewContextDefault: WebviewInterface = {
  isWebviewOpen: false,
  setIsWebviewOpen: () => {},
  webviewUrlToOpen: '',
  setWebviewUrlToOpen: () => {},
  openWebView: () => {},
  displayUrl: '',
  setCurrentUrl: () => {},
};

export const WebviewContext = React.createContext(webviewContextDefault);

interface Props {
  children: React.ReactNode;
}

export const WebviewProvider: React.FC<Props> = ({ children }: Props) => {
  const webviewState = useWebview();

  return (
    <WebviewContext.Provider value={webviewState}>
      {children}
      <Webview
        isWebviewOpen={webviewState.isWebviewOpen}
        setIsWebviewOpen={webviewState.setIsWebviewOpen}
        webviewUrlToOpen={webviewState.webviewUrlToOpen}
        setWebviewUrlToOpen={webviewState.setWebviewUrlToOpen}
        webviewUrlBody={webviewState.webviewUrlBody}
        webviewUrlMethod={webviewState.webviewUrlMethod}
        displayUrl={webviewState.displayUrl}
        setCurrentUrl={webviewState.setCurrentUrl}
      />
    </WebviewContext.Provider>
  );
};
