import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import { WebviewInterface } from 'context/webview';
import { shareCustomWebsite } from 'utils/sharing';

import BottomNav from './styled/BottomNav';
import Modal from './styled/Modal';
import TopNavbar from './styled/TopNavbar';
import WebviewView from './styled/WebviewView';

const Webview: React.FC<WebviewInterface> = ({
  isWebviewOpen,
  setIsWebviewOpen,
  webviewUrlToOpen,
  setWebviewUrlToOpen,
  webviewUrlMethod,
  webviewUrlBody,
  displayUrl,
  setCurrentUrl,
}: WebviewInterface) => {
  const webviewRef = useRef<WebView>(null);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web' && webviewUrlToOpen !== '') {
      Linking.openURL(webviewUrlToOpen);
      setWebviewUrlToOpen('');
      setIsWebviewOpen(false);
    }
  }, [webviewUrlToOpen]);

  const closeWebview = useCallback(() => {
    setIsWebviewOpen(false);
  }, []);

  const refreshPage = useCallback(() => {
    webviewRef.current?.reload();
  }, []);

  const backToPreviousPage = useCallback(() => {
    if (canGoBack) {
      webviewRef.current?.goBack();
    }
  }, []);

  const goToNextPage = useCallback(() => {
    if (canGoForward) {
      webviewRef.current?.goForward();
    }
  }, []);

  const shareWebsite = useCallback(() => {
    shareCustomWebsite(webviewUrlToOpen);
  }, [webviewUrlToOpen]);

  const openInBrowser = useCallback(() => {
    Linking.openURL(webviewUrlToOpen);
  }, [webviewUrlToOpen]);

  const onNavigationChange = useCallback((navState) => {
    setCanGoBack(navState.canGoBack);
    setCanGoForward(navState.canGoForward);
  }, []);

  return (
    <>
      {Platform.OS !== 'web' && (
        <Modal visible={isWebviewOpen} animationType="slide">
          <WebviewView>
            <TopNavbar url={displayUrl} onBackToApp={closeWebview} onRefresh={refreshPage} />
            <WebView
              source={{ uri: webviewUrlToOpen, method: webviewUrlMethod, body: webviewUrlBody }}
              ref={webviewRef}
              onNavigationStateChange={onNavigationChange}
              onLoadEnd={({ nativeEvent }) => setCurrentUrl(nativeEvent.url)}
            />
            <BottomNav
              isLeftArrowActive={canGoBack}
              isRightArrowActive={canGoForward}
              backToPreviousPage={backToPreviousPage}
              goToNextPage={goToNextPage}
              shareWebsite={shareWebsite}
              openInBrowser={openInBrowser}
            />
          </WebviewView>
        </Modal>
      )}
    </>
  );
};

export default Webview;
