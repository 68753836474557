import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function IconCancel(props: SvgProps): JSX.Element {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M13.5 2.5L2.5 13.5"
        stroke="#FFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.5 2.5L13.5 13.5"
        stroke="#FFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
