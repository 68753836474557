import { Platform } from 'react-native';
import styled from 'styled-components/native';

export default styled.View`
  flex: 1;
  justify-content: space-between;
  padding: 25px 0 50px;
  ${Platform.OS === 'web' &&
  `
    padding: 25px 0 0 0;
    flex: none;
    max-width: 450px;
    width: 100%;
    margin: auto;
  `}
`;
