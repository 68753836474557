import * as InAppPurchases from 'expo-in-app-purchases';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { useBackEnd } from 'context';
import { useApexx, useAuthSwr } from 'hooks';
import { PaymentMethod, ProductTypePlural } from 'types';
import { Tip } from 'types/Tipping';

const TIPS_LIST_KEY = '/artists/products/tips';

export const useTippingConfig = () => {
  const [tips, setTips] = useState<Tip[]>([]);
  const [defaultTip, setDefaultTip] = useState<Tip>();

  const {
    data,
    error,
    isLoading: isLoadingTips,
  } = useAuthSwr<Tip[]>({
    key: TIPS_LIST_KEY,
    isPublic: false,
    cache: false,
  });

  useEffect(() => {
    // console.log('tips:', data);
    if (data) {
      const t = [...data.sort((t1, t2) => (Number(t1.price) > Number(t2.price) ? 1 : -1))];
      if (Platform.OS !== 'ios') {
        t.push({ price: 0, app_store_price: 0, apple_product_id: '', is_most_popular: false });
      }
      setTips(t);
      setDefaultTip(t.find((x) => x.is_most_popular));
    }
  }, [data]);

  return {
    tips,
    defaultTip,
    isLoadingTips,
    error,
  };
};

export const useTipping = () => {
  const { axiosInstance } = useBackEnd();
  const [isTipping, setIsTipping] = useState<boolean>(false);
  const [tippingError, setTippingError] = useState<any>(null);
  const [tippingData, setTippingData] = useState<any>(null);
  const { payWithApexx, paymentSuccess, paymentError, isPaying } = useApexx();

  useEffect(() => {
    const finalizeApexxPayment = async () => {
      console.log('finalizing payment');
      if (paymentSuccess === false) {
        console.log('tip payment error:', paymentError?.message);
        setTippingError(paymentError?.message || 'subscription payment error');
        setIsTipping(false);
      } else if (paymentSuccess === true) {
        setTippingData({ data: 'payment succeed' } as any);
        setIsTipping(false);
      }
    };

    finalizeApexxPayment();
  }, [paymentSuccess, paymentError]);

  const initializeTransactionWithApple = async ({
    artistUsername,
    modelNamePlural,
    productId,
    appleProductId,
    amount,
  }: {
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    appleProductId: string;
    amount: number;
  }) => {
    try {
      await axiosInstance.post(
        `/artists/${artistUsername}/products/${modelNamePlural}/${productId}/tip_payment/apple_iap`,
        { amount, apple_product_id: appleProductId },
      );
      return true;
    } catch (e: any) {
      console.log('transaction initialization error:', e.response.data);

      setTippingError('There was an error during payment');
    }
    return false;
  };

  const payForTipWithCard = async ({
    paymentMethod,
    artistUsername,
    modelNamePlural,
    productId,
    amount,
  }: {
    paymentMethod: PaymentMethod;
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    amount: number;
  }) => {
    if (isTipping) {
      return;
    }

    setIsTipping(true);
    setTippingError(null);
    setTippingData(null);

    const res = await payWithApexx({
      artist_username: artistUsername,
      payment_type: 'tip',
      model_name_plural: modelNamePlural,
      product_id: productId,
      amount: amount.toString(),
      credit_card_external_id: (paymentMethod.creditCardInfo.external_id || '').toString(),
    });
  };

  const payForTipWithApple = async ({
    artistUsername,
    modelNamePlural,
    productId,
    appleProductId,
    amount,
  }: {
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    appleProductId: string;
    amount: number;
  }) => {
    if (isTipping) {
      return false;
    }

    setIsTipping(true);
    setTippingError(null);
    setTippingData(null);
    let isSuccess = false;

    if (
      appleProductId &&
      (await initializeTransactionWithApple({
        artistUsername,
        modelNamePlural,
        productId,
        appleProductId,
        amount,
      }))
    ) {
      console.log('/// ... IAP');
      console.log('/// ... appleProductId', appleProductId);
      const { responseCode, results, errorCode } = await InAppPurchases.getProductsAsync([
        appleProductId,
      ]);
      console.log('/// ...', responseCode, JSON.stringify(results), errorCode);
      if (responseCode === InAppPurchases.IAPResponseCode.OK) {
        console.log('OK');
        console.log('/// ... purchasing an item');
        try {
          await InAppPurchases.purchaseItemAsync(appleProductId);
          console.log('/// ... item purchased');
          setTippingData({ result: 'item purchased' } as any);
          isSuccess = true;
        } catch (e: any) {
          console.log('/// ... error', JSON.stringify(e));
          setTippingError('There was an error during payment');
        }
      }
      if (responseCode === InAppPurchases.IAPResponseCode.ERROR) {
        console.log('ERROR');
        setTippingError('There was an error during payment');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
        console.log('DEFERRED');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
        console.log('USER_CANCELED');
      }
    } else {
      const message = 'Cannot initialize transaction';
      console.log(message);
      setTippingError('There was an error during payment');
    }
    setIsTipping(false);
    return isSuccess;
  };

  return {
    payForTipWithCard,
    payForTipWithApple,
    tippingError,
    tippingData,
    isTipping,
  };
};

export function useTippingIAP(): {
  tippingApplePaymentCallback: ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => Promise<any>;
} {
  const { axiosInstance } = useBackEnd();

  const tippingApplePaymentCallback = async ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => {
    return await axiosInstance
      .post(`/tip_payment/apple_iap/confirm`, {
        receipt_data: data,
        apple_product_id,
        timestamp,
        transaction_id,
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log('tip payment confirmation:', JSON.stringify(e?.response?.data?.detail));
        return null;
      });
  };

  return {
    tippingApplePaymentCallback,
  };
}
