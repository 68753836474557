import styled from 'styled-components';

export default styled.h1`
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.41px;
  margin-top: 144px;
  color: #ffffff;
  font-family: 'BasierCircle-Medium';
  text-align: center;
`;
