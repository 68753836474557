export default {
  title: {
    id: 'notifications.title',
    defaultMessage: 'Notifications',
  },
  empty: {
    id: 'notifications.empty',
    defaultMessage: 'You don’t have any notifications yet.',
  },
};
