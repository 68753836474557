import React from 'react';
import styled, { css } from 'styled-components/native';

import { AvatarMode } from '../Avatar';
import ImageLiveBox from './ImageLiveBox';

interface ImageViewProps {
  children: React.ReactNode;
  isLive?: boolean;
  mode: AvatarMode;
  showName: boolean;
}

const View = styled.View<ImageViewProps>`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  background-color: #1a1a1a;
  margin-right: 10px;
  border-width: 0.4px;
  border-color: rgba(255, 255, 255, 0.52);
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.mode === 'compact' &&
    css`
      width: 40px;
      height: 40px;
      margin: 0 0 ${!props.showName ? 0 : '5px'} 0;
    `}

  ${(props) =>
    props.mode === 'compact-small' &&
    css`
      height: 32px;
      margin: 0;
      width: 32px;
    `}

  ${(props) =>
    (props.mode === 'row' || props.mode === 'minimal-row') &&
    css`
      width: 32px;
      height: 32px;
      margin: 0 8px 0 0;
    `}

  ${(props) =>
    props.mode === 'minimal-row-small' &&
    css`
      width: 21px;
      height: 21px;
      margin: 0 10px 0 0;
    `}

  ${(props) =>
    props.mode === 'minimal-row-big' &&
    css`
      width: 44px;
      height: 44px;
      margin: 0 15px 0 0;
    `}

  ${(props) =>
    props.isLive &&
    css`
      border-width: 0;
    `};
`;

const ImageView: React.FC<ImageViewProps> = ({ children, isLive, ...rest }: ImageViewProps) => {
  return (
    <View isLive={isLive} {...rest}>
      {isLive && <ImageLiveBox />}
      {children}
    </View>
  );
};

export default ImageView;
