import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Semibold;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.colors.text};
`;
