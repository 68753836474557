import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  width: 100%;
  text-align: center;
  padding: 0 15px;
  margin: 25px 0 0;
  color: ${({ theme }) => theme.orderAttachment.secondaryText};
`;
