import styled from 'styled-components/native';

import { OrderStatusTranslation } from 'types';

export default styled.Text<{ status: OrderStatusTranslation }>`
  color: ${({ status, theme }) => (theme.orderStatus as any)[`${status}Text`]};
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: center;
`;
