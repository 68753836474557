import { RouteProp, useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Options } from 'react-native-image-crop-picker';
import { PhotoFile } from 'react-native-vision-camera';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as MARKETPLACE_ARTIST_STACK_SCREEN_NAME } from 'screens/AritstMarketplaceScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/index.native';

import CameraPage from 'components/CameraPage/CameraPage';
import KeyboardAvoidingView from 'components/KeyboardAvoidingView';
import ModalOptions from 'components/ModalOptions';
import NavigationHeader from 'components/NavigationHeader/NavigationHeader';
import ScreenView from 'components/ScreenView';

import { useModals } from 'context/modals';
import { useSnackbarSet } from 'context/snackbar';
import { useImagePicker } from 'hooks';
import { useProduct } from 'hooks/product';
import { filePrefix } from 'utils/file';
import { parseItemPrice } from 'utils/marketplace';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import CustomCheckbox from './styled/Checkbox';
import ContentView from './styled/ContentView';
import DeleteButton from './styled/DeleteButton';
import Divider from './styled/Divider';
import Footer from './styled/Footer';
import Input from './styled/Input';
import Line from './styled/Line';
import MainView from './styled/MainView';
import MediumText from './styled/MediumText';
import OptionButton from './styled/OptionButton';
import OptionsText from './styled/OptionsText';
import ProductImage from './styled/ProductImage';
import Title from './styled/Title';

export { SCREEN_NAME };

type Media = {
  // common
  height: number;
  width: number;
  // web
  cancelled: boolean;
  uri: string;
  // native
  path: string;
  mime: string;
};

const pickerNativeOptions = {
  cropping: false,
  mediaType: 'photo',
  forceJpg: true,
} as Options;

const pickerWebOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: false,
  quality: 1,
};

type ArtistAddProductRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ArtistAddProductRouteProp;
};

const ArtistAddProductScreen: React.FC<Props> = ({ route }: Props) => {
  const paramItem = route?.params?.item;
  const { setSnackbar } = useSnackbarSet();
  const { showActivityIndicator, hideActivityIndicator } = useModals();
  const [isProcessing, setIsProcessing] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUnlimitedAmount, setIsUnlimitedAmount] = useState<boolean>(
    paramItem?.is_unlimited_stock || false,
  );
  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);

  const [title, setTitle] = useState<string>(paramItem?.name || '');
  const [description, setDescription] = useState<string>(paramItem?.description || '');
  const [price, setPrice] = useState<string>(
    paramItem?.price ? parseItemPrice(paramItem?.price) : '',
  );
  const [amount, setAmount] = useState<string>(paramItem?.stock?.toFixed(0) || '');
  const [media, setMedia] = useState<Media>();
  const [originWidth, setOriginWidth] = useState(500);

  const formattedPrice = useMemo(() => {
    if (!price.toString().match(/^[0-9]*\.?[0-9]*$/)) {
      //eslint-disable-line
      const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line
      setPrice(price.replace(format, ''));
      return '';
    }

    return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''; //eslint-disable-line
  }, [price]);

  const [editedImageUri, setEditedImageUri] = useState<string | null>(
    paramItem?.high_res_cover || paramItem?.low_res_cover || null,
  );

  const [question1, setQuestion1] = useState<string>(paramItem?.questionares[0]?.question || '');
  const [question2, setQuestion2] = useState<string>(paramItem?.questionares[1]?.question || '');
  const [question3, setQuestion3] = useState<string>(paramItem?.questionares[2]?.question || '');
  const [question4, setQuestion4] = useState<string>(paramItem?.questionares[3]?.question || '');
  const [question5, setQuestion5] = useState<string>(paramItem?.questionares[4]?.question || '');

  const intl = useIntl();
  const { navigate } = useNavigation();
  const { addProduct, updateProduct, deleteProduct } = useProduct();
  const { launchImagePicker, launchImageCropper } = useImagePicker({
    onMediaChange: setMedia,
  });

  const getMediaUri = (m: Media) => {
    return m.path || m.uri;
  };

  const setUriAndSize = (m: Media) => {
    const uri = getMediaUri(m);
    setOriginWidth(m.width);
    setEditedImageUri(uri);
  };

  const onNextPress = async () => {
    showActivityIndicator();
    const questions = [question1, question2, question3, question4, question5];
    const filteredQuestions = questions.filter((string) => string.length > 0);

    const priceWithDots = price.replace(',', '.');
    const temporaryPrice = parseFloat(priceWithDots);
    const temporaryAmount = parseFloat(amount);

    if (
      title &&
      description &&
      temporaryPrice > 0 &&
      (temporaryAmount > 0 || isUnlimitedAmount) &&
      (media || editedImageUri)
    ) {
      const path = editedImageUri ? editedImageUri : media?.path;
      const data = {
        title,
        description,
        price: priceWithDots,
        amount,
        path,
        questionares: filteredQuestions,
        isUnlimited: isUnlimitedAmount,
      };
      try {
        const response = paramItem
          ? await updateProduct(paramItem.id, data)
          : await addProduct(data);
        hideActivityIndicator();
        if (response?.id) {
          navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
            artist: response.artist.username,
            productId: response.id,
          });
        }
      } catch (e) {
        hideActivityIndicator();
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.error.other),
          actionLabel: intl.formatMessage(messages.close),
        });
      }
    }
  };

  const onDeletePress = useCallback(async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      if (paramItem) {
        await deleteProduct(paramItem.id);
        navigate(MARKETPLACE_ARTIST_STACK_SCREEN_NAME, { screen: 'Marketplace' });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsProcessing(false);
    }
  }, [paramItem, navigate, isProcessing]);

  const onCropPress = () => {
    if (media || editedImageUri) {
      const uri = !editedImageUri ? media?.path : editedImageUri;
      if (!uri) return;
      launchImageCropper({
        cropperOptions: {
          path: uri,
          forceJpg: true,
          height: originWidth,
          width: originWidth,
        },
        onCrop: setUriAndSize,
      });
    }
  };

  const onEditPress = () => {
    setIsModalOpen(true);
  };

  const onTakePicturePress = () => {
    setIsModalOpen(false);
    setIsCameraOpen(true);
  };

  const onChooseFromGalleryPress = async () => {
    await launchImagePicker({ pickerNativeOptions, pickerWebOptions });
    setEditedImageUri('');
    setIsModalOpen(false);
  };

  // const onUseEmojiPress = () => {
  //   setIsModalOpen(false);
  // };

  const onCancelPress = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ScreenView>
        <KeyboardAvoidingView>
          <MainView stickyHeaderIndices={[0]}>
            <NavigationHeader
              insets={{ top: 15 }}
              title={intl.formatMessage(messages.header.title[paramItem ? 'edit' : 'create'])}
              headerRightLabel={intl.formatMessage(
                paramItem ? messages.header.rightButtonSave : messages.header.rightButton,
              )}
              headerBackTitle={intl.formatMessage(messages.header.leftButton)}
              headerBackShowIcon={false}
              headerRightDisabled={
                !(
                  title &&
                  description &&
                  parseFloat(price) > 0 &&
                  (parseFloat(amount) > 0 || isUnlimitedAmount) &&
                  (media || editedImageUri)
                )
              }
              headerRightOnPress={onNextPress}
            />
            <ProductImage
              onEditPress={onEditPress}
              onCroppPress={onCropPress}
              uri={editedImageUri ? editedImageUri : media?.path}
              isEditable={!paramItem}
            />
            <Line />
            <ContentView>
              <Title>{intl.formatMessage(messages.body.title)}</Title>
              <Input
                placeholder={intl.formatMessage(messages.body.placeholders.title)}
                value={title}
                onChangeValue={setTitle}
              />
              <Input
                isDescription={true}
                placeholder={intl.formatMessage(messages.body.placeholders.description)}
                height={114}
                value={description}
                onChangeValue={setDescription}
              />
              <Input
                placeholder={intl.formatMessage(messages.body.placeholders.price)}
                isPrice={true}
                type="numeric"
                value={formattedPrice}
                onChangeValue={setPrice}
              />
              <Input
                placeholder={intl.formatMessage(messages.body.placeholders.amount)}
                type="numeric"
                value={isUnlimitedAmount ? 'Unlimited' : amount}
                onChangeValue={setAmount}
                isEditable={!isUnlimitedAmount}
              />
              <CustomCheckbox value={isUnlimitedAmount} onValueChange={setIsUnlimitedAmount} />
            </ContentView>
            <Line />
            <Footer>
              <Title>{intl.formatMessage(messages.footer.title)}</Title>
              <MediumText>{intl.formatMessage(messages.footer.description)}</MediumText>
              <Input
                placeholder="Question 1 (optional)"
                value={question1}
                onChangeValue={setQuestion1}
              />
              {(question1.length > 3 || question2.length > 0) && (
                <Input
                  placeholder="Question 2 (optional)"
                  value={question2}
                  onChangeValue={setQuestion2}
                />
              )}
              {(question2.length > 3 || question3.length > 0) && (
                <Input
                  placeholder="Question 3 (optional)"
                  value={question3}
                  onChangeValue={setQuestion3}
                />
              )}
              {(question3.length > 3 || question4.length > 0) && (
                <Input
                  placeholder="Question 4 (optional)"
                  value={question4}
                  onChangeValue={setQuestion4}
                />
              )}
              {(question4.length > 3 || question5.length > 0) && (
                <Input
                  placeholder="Question 5 (optional)"
                  value={question5}
                  onChangeValue={setQuestion5}
                />
              )}
            </Footer>
            {paramItem && (
              <DeleteButton processing={isProcessing} onPress={onDeletePress}>
                {intl.formatMessage(messages.delete)}
              </DeleteButton>
            )}
          </MainView>
        </KeyboardAvoidingView>
      </ScreenView>
      <ModalOptions isVisible={isModalOpen} isDimmerVisible={isModalOpen}>
        <OptionButton top onPress={onTakePicturePress}>
          <OptionsText>{intl.formatMessage(messages.modal.takePhoto)}</OptionsText>
        </OptionButton>
        <Divider />
        <OptionButton bottom onPress={onChooseFromGalleryPress}>
          <OptionsText>{intl.formatMessage(messages.modal.chooseImage)}</OptionsText>
        </OptionButton>
        {/* <Divider /> */}
        {/* <OptionButton bottom onPress={onUseEmojiPress}>
          <OptionsText>{intl.formatMessage(messages.modal.useEmoji)}</OptionsText>
        </OptionButton> */}
        <OptionButton solid onPress={onCancelPress}>
          <OptionsText>{intl.formatMessage(messages.modal.cancel)}</OptionsText>
        </OptionButton>
      </ModalOptions>
      {isCameraOpen && (
        <CameraPage
          video={false}
          onMediaCaptured={(data) => {
            setEditedImageUri(filePrefix(data.path));
            setOriginWidth((data as PhotoFile).width);
            setIsCameraOpen(false);
          }}
          onClosePress={() => setIsCameraOpen(false)}
        />
      )}
    </>
  );
};

export default ArtistAddProductScreen;
