import { Platform } from 'react-native';
import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(() => ({
  bold: false,
  blurAmount: Platform.OS === 'web' ? 40 : 10,
}))`
  background-color: ${({ theme }) => theme.lockedItem.actionBackground};
  color: ${({ theme }) => theme.lockedItem.actionText};
  min-height: 36px;
  width: 105px;
  border: 2px solid #ffffff;
  border-radius: 40px;
  z-index: 20;
`;
