export default {
  generalInfoTitle: {
    id: 'profileSettings.generalInfoTitle',
    defaultMessage: 'Your Details',
  },
  passwordTitle: {
    id: 'profileSettings.passwordTitle',
    defaultMessage: 'Password',
  },
  passwordUpdated: {
    id: 'profileSettings.passwordUpdated',
    defaultMessage: 'Password has been updated.',
  },
  close: {
    id: 'profileSettings.close',
    defaultMessage: 'Close',
  },
};
