import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as HELP_SCREEN_NAME } from 'screens/HelpScreen/constants';

import { useBackEnd, useSnackbarSet } from 'context';
import regex from 'utils/regex';

import * as Styled from './styled';
import { FormData, Props } from './types';

const FormScreen: React.FC<Props> = ({ messages, navigation }: Props) => {
  const intl = useIntl();
  const { setSnackbar } = useSnackbarSet();
  const { axiosInstance } = useBackEnd();
  const {
    control,
    handleSubmit,
    errors,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const goBack = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(HELP_SCREEN_NAME);
  };

  const onSubmit = async (args: { email: string; subject: string; description: string }) => {
    try {
      await axiosInstance.post('/support/request', args);
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.snackbar.sent),
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
      goBack();
    } catch (e) {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.snackbar.error),
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
    }
  };

  return (
    <Styled.WebContainer>
      <Styled.Header>
        <Styled.BackButton onPress={goBack}>
          <Styled.BackIcon />
          <Styled.BackText>{intl.formatMessage(messages.back)}</Styled.BackText>
        </Styled.BackButton>
      </Styled.Header>
      <Styled.Container>
        <Styled.Title>{intl.formatMessage(messages.title)}</Styled.Title>
        <Styled.Subtitle>{intl.formatMessage(messages.subtitle)}</Styled.Subtitle>

        <Controller
          name="email"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Styled.TextInput
              placeholder={intl.formatMessage(messages.emailInput.placeholder)}
              keyboardType="default"
              autoCompleteType="email"
              autoCapitalize="none"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: any) => {
                clearErrors('email');
                // clearErrors('form');
                onChange(value);
              }}
            />
          )}
          rules={{
            required: intl.formatMessage(messages.emailInput.error.required),
            pattern: {
              value: regex.email,
              message: intl.formatMessage(messages.emailInput.error.invalid),
            },
          }}
          defaultValue=""
        />
        {errors?.email?.message && <Styled.Error>{errors?.email?.message}</Styled.Error>}

        <Controller
          name="subject"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Styled.TextInput
              placeholder={intl.formatMessage(messages.subjectInput.placeholder)}
              keyboardType="default"
              autoCompleteType="off"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: any) => {
                clearErrors('subject');
                onChange(value);
              }}
            />
          )}
          rules={{
            required: intl.formatMessage(messages.subjectInput.error.required),
          }}
          defaultValue=""
        />
        {errors?.subject?.message && <Styled.Error>{errors?.subject?.message}</Styled.Error>}

        <Controller
          name="description"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Styled.TextInput
              placeholder={intl.formatMessage(messages.messageInput.placeholder)}
              keyboardType="default"
              autoCompleteType="off"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: any) => {
                clearErrors('description');
                // clearErrors('form');
                onChange(value);
              }}
              multiline
              numberOfLines={5}
              scrollEnabled
            />
          )}
          rules={{
            required: intl.formatMessage(messages.messageInput.error.required),
          }}
          defaultValue=""
        />
        {errors?.description?.message && (
          <Styled.Error>{errors?.description?.message}</Styled.Error>
        )}
        <Styled.SubmitButton
          processing={isSubmitting}
          onPress={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {intl.formatMessage(messages.send)}
        </Styled.SubmitButton>
      </Styled.Container>
    </Styled.WebContainer>
  );
};

export default FormScreen;
