import * as SplashScreen from 'expo-splash-screen';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Animated, Easing } from 'react-native';

import { useAuth } from 'hooks';

import messages from '../messages';

const useWelcomeScreenHandlers = () => {
  const intl = useIntl();
  const { authError, authErrorData, signInWithApple, signInWithGoogle, signInWithFacebook } =
    useAuth();

  const contentOpacityRef = useRef<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    if (authErrorData && authErrorData.response?.data?.code === 'not_in_whitelist') {
      alert(intl.formatMessage(messages.error.notInWhitelist));
    }
  }, [authError]);

  const onContinueWithApplePress = () => {
    signInWithApple();
  };

  const onContinueWithGooglePress = () => {
    signInWithGoogle();
  };

  const onContinueWithFacebookPress = () => {
    signInWithFacebook();
  };

  const onLogoLoaded = async () => {
    await SplashScreen.hideAsync();
  };

  const handleLogoAnimationEnd = useCallback(() => {
    Animated.timing(contentOpacityRef.current, {
      toValue: 1,
      duration: 500,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }).start();
  }, []);

  return {
    authError,
    onContinueWithApplePress,
    onContinueWithGooglePress,
    onContinueWithFacebookPress,
    onLogoLoaded,
    handleLogoAnimationEnd,
    contentOpacityRef,
  };
};

export default useWelcomeScreenHandlers;
