import styled from 'styled-components';

export default styled.div`
  width: 41%;
  height: 580px;
  max-width: 560px;
  min-width: 350px;
  background: linear-gradient(0deg, rgba(46, 81, 223, 0.24), rgba(46, 81, 223, 0.24)), #121212;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05), 0px 4px 44px rgba(0, 0, 0, 0.25),
    0px 24px 104px rgba(0, 0, 0, 0.22);
  border-radius: 14px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: auto 0;
`;
