import styled from 'styled-components/native';

import Badge from 'components/Badge';

export default styled(Badge)<{ highlight?: boolean }>`
  margin: 0;
  left: 7px;
  position: absolute;
  top: 7px;
  z-index: 3;

  background-color: ${({ theme, highlight }) =>
    highlight ? theme.colors.errorAccent : theme.colors.progressBackgorund};
`;
