import styled, { css } from 'styled-components/native';

interface ItemTextBackgroundProps {
  active?: boolean;
  activeBackground?: string;
}

export default styled.View<ItemTextBackgroundProps>`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.12;
  border-radius: 10px;

  ${({ active, activeBackground }) =>
    active &&
    css`
      background-color: ${({ theme }) => activeBackground || theme.colors.primaryActive};
    `}
`;
