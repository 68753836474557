import styled, { css } from 'styled-components/native';

interface Props {
  large?: boolean;
  underline?: boolean;
}

export default styled.Text<Props>`
  color: ${({ theme }) => theme.colors.primaryInactive};
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  ${({ large }) =>
    large
      ? css`
          font-size: 15px;
          line-height: 20px;
        `
      : css`
          font-size: 11px;
          line-height: 13px;
        `}

  ${({ onPress, large, underline }) =>
    onPress || underline
      ? large
        ? css`
            color: ${({ theme }) => theme.colors.text};
          `
        : css`
            color: ${({ theme }) => theme.colors.textActive};
            text-decoration: underline;
            text-decoration-color: ${({ theme }) => theme.colors.textActive};
          `
      : null}
`;
