import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

import { mediaWeb } from 'utils/styling';

interface ButtonProps {
  disabled?: boolean;
  theme?: any;
}

export default styled(Button).attrs((props: ButtonProps) => ({
  labelColor: props.disabled ? props.theme.button.textGhost : props.theme.button.text,
}))`
  margin: 20px 20% 0;
  ${mediaWeb(css`
    margin: 20px 0 0;
    width: 100%;
    height: 60px;
  `)}
  border-radius: 40px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.primaryGhost : theme.button.backgroundColorBlue};
`;
