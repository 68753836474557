import { VARIANT } from 'context';

import WelcomeScreenArtist from './WelcomeScreenArtist';
import WelcomeScreenFan from './WelcomeScreenFan';

const screen = VARIANT === 'fan' ? WelcomeScreenFan : WelcomeScreenArtist;

export { SCREEN_NAME } from './constants';

export default screen;
