import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 20px;
  padding: 8px 20px;
  width: 84px;
  flex-direction: row;
  align-items: center;
`;
