import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { useComments, useConfig } from 'hooks';
import { Comments, ProductType, UserProfile } from 'types';

import CommentList from './styled/CommentList';
import Container from './styled/Container';

export interface CommentsProps {
  commentAccess?: string;
  artistUsername: string;
  artistDisplayName?: string;
  emptyListMessage?: string;
  productId: number;
  productModelName: ProductType;
  profilePicture?: string;
  displayName?: string;
  ContentComponent?: JSX.Element | null;
  onCommentsChange?: (comments: Comments) => void;
  showAgeRestrictions?: boolean;
  profile?: UserProfile;
}

const ContentWithComments = (
  {
    commentAccess,
    artistUsername,
    artistDisplayName,
    emptyListMessage,
    productId,
    productModelName,
    profilePicture,
    displayName,
    ContentComponent,
    onCommentsChange,
    showAgeRestrictions,
    profile,
    ...restProps
  }: CommentsProps,
  ref: any,
): JSX.Element | null => {
  const { config } = useConfig();
  const commentsListRef = useRef<typeof CommentList>(null);

  const {
    addComment,
    deleteComment,
    isProcessingComment,
    comments: data,
  } = useComments({
    artistUsername,
    productId,
    productModelName,
    onCommentsChange,
  });

  useImperativeHandle(ref, () => ({
    focusOnCommentInput: () => {
      if (commentsListRef && commentsListRef.current) {
        (commentsListRef.current as any)?.focusOnCommentInput();
      }
    },
  }));

  const isAgeRestricted = useMemo(
    () => (showAgeRestrictions ? !data.some((c) => c.display_name === displayName) : false),
    [showAgeRestrictions, data, displayName],
  );

  return (
    <Container {...restProps}>
      <CommentList
        artistDisplayName={artistDisplayName}
        artistUsername={artistUsername}
        addComment={addComment}
        deleteComment={deleteComment}
        data={data}
        profilePicture={profilePicture}
        displayName={displayName}
        profile={profile}
        emptyListMessage={emptyListMessage}
        commentAccess={commentAccess}
        commentsAddNewEnabled={config.commentsAddNewEnabled}
        isProcessingComment={isProcessingComment}
        ListHeaderComponent={ContentComponent}
        ref={commentsListRef}
        showAgeRestrictions={isAgeRestricted}
      />
    </Container>
  );
};

ContentWithComments.displayName = 'ContentWithComments';

export default forwardRef(ContentWithComments);
