import { RouteProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import cardValidator from 'card-validator';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREEN_NAME } from 'screens/EditPaymentMethodScreen';

import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { usePayments } from 'context/payments';
import { WebviewContext } from 'context/webview';
import { useSubscribe, useSubscriptionPlan } from 'hooks';
import { PaymentMethod } from 'types';
import { parseCurrency } from 'utils/currency';
import { privacyPolicyURL, termsURL } from 'utils/links';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import AddCreditCardView from './styled/AddCreditCardView';
import AddPaymentMethodCtaText from './styled/AddPaymentMethodCtaText';
import AddPaymentMethodTouchable from './styled/AddPaymentMethodTouchable';
import ContentInnerView from './styled/ContentInnerView';
import CreditCardIcon from './styled/CreditCardIcon';
import Description from './styled/Description';
import DisclaimerText from './styled/DisclaimerText';
import Divider from './styled/Divider';
import IconCheck from './styled/IconCheck';
import MainView from './styled/MainView';
import PayButton from './styled/PayButton';
import PaymentMethodName from './styled/PaymentMethodName';
import PaymentMethodNameView from './styled/PaymentMethodNameView';
import PaymentMethodsHeaderView from './styled/PaymentMethodsHeaderView';
import PaymentMethodsListTitleText from './styled/PaymentMethodsListTitleText';
import PaymentMethodsListView from './styled/PaymentMethodsListView';
import PaymentMethodsView from './styled/PaymentMethodsView';
import PaymentMethodTouchable from './styled/PaymentMethodTouchable';
import PaymentMethodView from './styled/PaymentMethodView';
import PriceText from './styled/PriceText';
import TitleText from './styled/TitleText';
import TitleView from './styled/TitleView';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type ConfirmPaymentScreenProps = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ConfirmPaymentScreen: React.FC<ConfirmPaymentScreenProps> = ({
  route,
}: ConfirmPaymentScreenProps) => {
  const { artistUsername, artistFullName } = route.params;
  const intl = useIntl();
  const navigation = useNavigation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
  const { subscribeWithCard, isSubscribing } = useSubscribe();
  const { isLoading: isLoadingSubscriptionStatus, subscriptionPlan } =
    useSubscriptionPlan(artistUsername);
  const { isLoadingPaymentMethods, paymentMethods } = usePayments();
  const { openWebView } = useContext(WebviewContext);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length !== 0) {
      setSelectedPaymentMethod(
        paymentMethods.find((pm) => pm.creditCardInfo.is_primary) || paymentMethods[0],
      );
    }
  }, [paymentMethods]);

  const onMethodPress = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
  };

  const onAddPaymentMethod = () => {
    navigation.navigate(ADD_PAYMENT_METHOD_SCREEN_NAME);
  };

  const onTermsCtaPress = useCallback(() => {
    openWebView(termsURL);
  }, [openWebView]);

  const onPrivacyPolicyCtaPress = useCallback(() => {
    openWebView(privacyPolicyURL);
  }, [openWebView]);

  const renderPaymentMethod = (method: PaymentMethod) => (
    <PaymentMethodTouchable
      key={method.creditCardInfo.external_id || method.creditCardInfo.number}
      onPress={() => onMethodPress(method)}
    >
      <PaymentMethodView>
        <PaymentMethodNameView>
          <CreditCardIcon
            cardType={cardValidator.number(method.creditCardInfo.number).card?.type}
          />
          <PaymentMethodName>
            {intl.formatMessage(messages.card, {
              lastFourDigits: method.creditCardInfo.last_four_digits,
            })}
          </PaymentMethodName>
        </PaymentMethodNameView>
        {selectedPaymentMethod?.creditCardInfo.external_id ===
          method.creditCardInfo.external_id && <IconCheck />}
      </PaymentMethodView>
    </PaymentMethodTouchable>
  );

  const onPayPress = async () => {
    if (!selectedPaymentMethod) {
      return;
    }

    await subscribeWithCard({
      artistUsername: artistUsername,
      paymentMethod: selectedPaymentMethod,
    });
  };

  const renderForm = () => (
    <ContentInnerView>
      <TitleView>
        <TitleText>{intl.formatMessage(messages.becomeStan)} </TitleText>
        {!isLoadingSubscriptionStatus ? (
          <PriceText>
            {intl.formatMessage(messages.priceMonthly, {
              currency: parseCurrency(subscriptionPlan?.price_currency as string),
              price: subscriptionPlan?.price,
            })}
          </PriceText>
        ) : (
          <ActivityIndicator />
        )}
      </TitleView>
      <Description>{intl.formatMessage(messages.description, { artistFullName })}</Description>
      <Divider />
      <>
        <PaymentMethodsView>
          <PaymentMethodsHeaderView>
            <PaymentMethodsListTitleText>
              {intl.formatMessage(messages.paymentMethodsListTitle)}
            </PaymentMethodsListTitleText>
            <AddPaymentMethodTouchable onPress={onAddPaymentMethod}>
              <AddPaymentMethodCtaText>
                {intl.formatMessage(messages.addMethod)}
              </AddPaymentMethodCtaText>
            </AddPaymentMethodTouchable>
          </PaymentMethodsHeaderView>
          <PaymentMethodsListView
            style={{
              height: paymentMethods ? Math.min(paymentMethods.length * 40 + 20, 110) : 50,
            }}
          >
            {isLoadingPaymentMethods && <ActivityIndicator animating />}
            {!isLoadingPaymentMethods && paymentMethods && paymentMethods.map(renderPaymentMethod)}
          </PaymentMethodsListView>
        </PaymentMethodsView>
        <DisclaimerText>
          {intl.formatMessage(messages.disclaimer, {
            termsCta: function TermsCtaChunk(chunk) {
              return <DisclaimerText onPress={onTermsCtaPress}>{chunk}</DisclaimerText>;
            },
            privacyPolicyCta: function PrivacyCtaChunk(chunk) {
              return <DisclaimerText onPress={onPrivacyPolicyCtaPress}>{chunk}</DisclaimerText>;
            },
          })}
        </DisclaimerText>
      </>
      <PayButton
        disabled={paymentMethods.length === 0}
        processing={isLoadingSubscriptionStatus || isSubscribing}
        onPress={onPayPress}
      >
        {intl.formatMessage(
          selectedPaymentMethod ? messages.payButtonLabel : messages.payButtonLabelGeneric,
          {
            paymentMethod: intl.formatMessage(messages.methodName, {
              lastFourDigits: selectedPaymentMethod
                ? selectedPaymentMethod.creditCardInfo.last_four_digits
                : '',
            }),
          },
        )}
      </PayButton>
    </ContentInnerView>
  );

  return (
    <MainView>
      <NavigationHeader showGoBack insets={{ top: 35 }} />
      <WebContainer>
        <AddCreditCardView>{renderForm()}</AddCreditCardView>
      </WebContainer>
    </MainView>
  );
};

export default ConfirmPaymentScreen;
