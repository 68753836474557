import styled, { css } from 'styled-components/native';

interface ImageWrapperProps {
  focused: boolean;
}

export default styled.View<ImageWrapperProps>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, ${({ focused }) => (focused ? 1 : 0.52)});
  background-color: rgba(255, 255, 255, 0.52);

  ${({ focused }) => {
    const opacity = focused ? 1 : 0.52;
    const color = `rgba(255, 255, 255, ${opacity})`;
    return css`
      border: 1px solid ${color};
      background-color: ${color};
    `;
  }}
`;
