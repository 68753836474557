import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  font-size: 17px;
  margin-bottom: 10px;
  letter-spacing: -0.43px;
  font-weight: bold;
  ${mediaWeb(css`
    font-size: 13px;
    letter-spacing: -0.08px;
    font-weight: normal;
    font-family: 'BasierCircle-Medium';
  `)}
`;
