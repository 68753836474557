import styled from 'styled-components/native';

export default styled.Text<{ margin?: string }>`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.artistDashboard.primary};
  opacity: 0.52;
  letter-spacing: -0.23px;
  font-weight: 400;
  text-align: center;
  margin: ${({ margin }) => margin || '25px 0 0 0'};
`;
