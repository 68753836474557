export default {
  fanConfirm: {
    title: {
      id: 'marketplaceDialog.fanConfirm.title',
      defaultMessage: 'Confirm completing',
    },
    description: {
      id: 'marketplaceDialog.fanConfirm.description',
      defaultMessage: 'By clicking below you confirm fulfilling the order by the Artist.',
    },
    confirm: {
      id: 'marketplaceDialog.fanConfirm.confirm',
      defaultMessage: 'Confirm completing order',
    },
    dismiss: {
      id: 'marketplaceDialog.fanConfirm.dismiss',
      defaultMessage: 'Go back',
    },
  },
  artistConfirm: {
    title: {
      id: 'marketplaceDialog.artistConfirm.title',
      defaultMessage: 'Ask Stan for confirmation',
    },
    description: {
      id: 'marketplaceDialog.artistConfirm.description',
      defaultMessage:
        "Please make sure your order has been completed before asking for Stan's confirmation.",
    },
    confirm: {
      id: 'marketplaceDialog.artistConfirm.confirm',
      defaultMessage: 'Ask Stan for confirmation',
    },
    dismiss: {
      id: 'marketplaceDialog.artistConfirm.dismiss',
      defaultMessage: 'Go back',
    },
  },
  artistDecline: {
    title: {
      id: 'marketplaceDialog.artistConfirm.title',
      defaultMessage: 'Decline order',
    },
    description: {
      id: 'marketplaceDialog.artistConfirm.description',
      defaultMessage:
        'Are you sure you want to decline order? Buyer will be inform about this issue and money will be the money will be returned from you Stanbase account.',
    },
    confirm: {
      id: 'marketplaceDialog.artistConfirm.confirm',
      defaultMessage: 'Decline order',
    },
    dismiss: {
      id: 'marketplaceDialog.artistConfirm.dismiss',
      defaultMessage: 'Cancel',
    },
  },
};
