import styled, { css } from 'styled-components/native';

import MuteButton from 'components/MuteButton';

export default styled(MuteButton)<{ showProgressBar?: boolean }>`
  position: absolute;
  ${({ showProgressBar }) =>
    showProgressBar
      ? css`
          bottom: 15px;
          right: 15px;
        `
      : css`
          top: 7px;
          right: 7px;
        `}
`;
