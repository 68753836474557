import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.View`
  height: 40px;
  width: 100%;
  ${mediaWeb(css`
    height: 16px;
  `)};
`;
