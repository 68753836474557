import React from 'react';
import { Modal as RNModal, Platform, TouchableWithoutFeedback } from 'react-native';
import { Modal as WModal } from 'react-native-web';

import ModalCard from './styled/ModalCard';
import ModalView from './styled/ModalView';
import SubTitle from './styled/SubTitle';
import Title from './styled/Title';

interface Props {
  children: React.ReactNode;
  visible: boolean;
  title?: string;
  subtitle?: string;
  customWidth?: string;
  onPressOutside?: () => void;
}

const Dialog: React.FC<Props> = ({
  visible,
  title,
  subtitle,
  customWidth,
  children,
  onPressOutside,
}: Props) => {
  const Modal = Platform.OS === 'web' ? WModal : RNModal;
  return (
    <Modal animationType="fade" transparent visible={visible} statusBarTranslucent>
      <ModalView onPressOut={onPressOutside}>
        <TouchableWithoutFeedback>
          <ModalCard customWidth={customWidth}>
            <>
              {title && <Title>{title}</Title>}
              {subtitle && <SubTitle>{subtitle}</SubTitle>}
              {children}
            </>
          </ModalCard>
        </TouchableWithoutFeedback>
      </ModalView>
    </Modal>
  );
};

export default Dialog;
