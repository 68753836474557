import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import { SCREEN_NAME as LOGIN_STACK } from 'stacks/common/LoginStack/constants';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen/constants';
import { SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME } from 'screens/LoginWithEmailScreen/constants';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen/constants';

import ActivityIndicator from 'components/ActivityIndicator';
import Button from 'components/Button';
import NavigationHeaderFan from 'components/NavigationHeaderFan';
import NewMasonry from 'components/NewMasonry';
import Footer from 'components/WebFooterFan';

import { useBackEnd, useUnsubscribeModal } from 'context';
import { useFeedHandlers } from 'context/feed';
import { useSubscriptionModalWeb } from 'context/subscriptionModalWeb';
import {
  useArtist,
  useArtistAudios,
  useArtistImages,
  useArtistPolls,
  useArtistVideos,
  useConfig,
  useFanMarketplaceItems,
  useFollowing,
  useScreenSize,
  useSubscriptionStatus,
} from 'hooks';
import { ArtistProduct } from 'types';
import { getCoverImage } from 'utils/user';

import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from '../MarketplaceProductDetailsScreen';
import { SCREEN_NAME } from './constants';
import messages from './messages';
import ArtistProfileHeader from './styled/ArtistProfileHeader';
import ContentWrapper from './styled/ContentWrapper';
import ErrorText from './styled/ErrorText';
import IconAll from './styled/IconAll';
import IconAudio from './styled/IconAudio';
import IconImage from './styled/IconImage';
import IconMarketplace from './styled/IconMarketplace';
import IconPoll from './styled/IconPoll';
import IconVideo from './styled/IconVideo';
import Label from './styled/Label';
import NavigationHeaderFanWrapper from './styled/NavigationHeaderFanWrapper';
import TabBar from './styled/TabBar';
import TabBarWrapper from './styled/TabBarWrapper';
import WebBackgroundImage from './styled/WebBackgroundImage';
import WebBlur from './styled/WebBlur';
import WebContainer from './styled/WebContainer';
import WebGradient from './styled/WebGradient';
import WebHeader from './styled/WebHeader';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistProfileScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const [currentData, setCurrentData] = useState<ArtistProduct[] | undefined>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const artistUsername = route.params?.artistUsername;
  const { artist, error } = useArtist(artistUsername);
  const [webArtistUsername, setWebArtistUsername] = useState<string | undefined>('');
  const [webArtistFullName, setWebArtistFullName] = useState<string>('');
  const isNotFound = useMemo(() => error?.response?.status === 404, [error]);
  const { isLoading: isLoadingSubscriptionStatus, subscriptionStatus } =
    useSubscriptionStatus(artistUsername);

  useEffect(() => console.log(subscriptionStatus), [subscriptionStatus]);

  const isSubscribed = !!(
    subscriptionStatus?.time_end && moment().isBefore(moment(subscriptionStatus?.time_end))
  );
  const isSubscriptionPendingCancelation =
    isSubscribed && subscriptionStatus?.is_auto_renew === false;
  const { unsubscribeModalOpen, isUnsubscribing } = useUnsubscribeModal();
  const {
    isOpen: isModalOpen,
    setIsOpen,
    setArtistUsername,
    setArtistDisplayName,
  } = useSubscriptionModalWeb();
  const {
    isLoading: isLoadingFollowing,
    followingStatus,
    follow,
    unfollow,
  } = useFollowing(artistUsername);
  const { refresh: refreshFeed } = useFeedHandlers();
  const { config } = useConfig();
  const { isAuthenticated } = useBackEnd();

  useEffect(() => {
    setWebArtistUsername(artistUsername);
    if (artist) setWebArtistFullName(artist.display_name);
  }, [artist, artistUsername]);

  const onLoginPress = () => {
    navigation.navigate(LOGIN_STACK, {
      screen: LOGIN_WITH_EMAIL_SCREEN_NAME,
    });
  };

  const navigateToProduct = (product: ArtistProduct) => {
    if (!artist) {
      return;
    }

    let productWithArtist: ArtistProduct = product;
    if (!product.artist) {
      productWithArtist = {
        ...product,
        artist,
      };
    }

    if (product.model_name === 'marketplaceitem') {
      navigation.navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
        artist: product.artist.username,
        productId: product.id,
      });
    } else {
      navigation.navigate(SINGLE_POST_SCREEN_NAME, {
        artistUsername: artist.username,
        productType: product.model_name,
        productId: product.id,
        product: productWithArtist,
      });
    }
  };

  const { isLoading: isLoadingImages, images } = useArtistImages(artistUsername);
  const { isLoading: isLoadingVideos, videos } = useArtistVideos(artistUsername);
  const { isLoading: isLoadingAudios, audios } = useArtistAudios(artistUsername);
  const { isLoading: isLoadingPolls, polls } = useArtistPolls(artistUsername);
  const { isLoading: isLoadingMarketplace, items: marketplaceItems } =
    useFanMarketplaceItems(artistUsername);

  const allData = useMemo(
    () =>
      (images || [])
        .concat(videos || [])
        .concat(audios || [])
        .concat(polls || [])
        //@ts-ignore
        .sort((a, b) => new Date(b.time_created) - new Date(a.time_created)),
    [images, videos, audios, polls],
  );

  useEffect(() => {
    const sortedAudios = audios?.sort(
      (a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime(),
    );
    const sortedImages = images?.sort(
      (a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime(),
    );
    const sortedVideos = videos?.sort(
      (a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime(),
    );
    const sortedPolls = polls?.sort(
      (a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime(),
    );
    const sortedMarketplaceItems = marketplaceItems?.sort(
      (a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime(),
    );
    if (activeIndex === 0) setCurrentData(allData);
    if (activeIndex === 1) setCurrentData(sortedAudios);
    if (activeIndex === 2 && images) setCurrentData(sortedImages);
    if (activeIndex === 3) setCurrentData(sortedVideos);
    if (activeIndex === 4) setCurrentData(sortedPolls);
    if (activeIndex === 5) setCurrentData(sortedMarketplaceItems);
  }, [activeIndex]);

  useEffect(() => {
    if (activeIndex === 0 && allData.length !== currentData?.length) setCurrentData(allData);
  }, [allData]);

  const navigateHome = useCallback(() => {
    navigation.navigate(HOME_SCREEN_NAME);
  }, [navigation]);

  const onSubscribePress = () => {
    if (isSubscriptionPendingCancelation || !isSubscribed) {
      if (!webArtistUsername) throw new Error("Coudn't find an artist for subscription modal");
      setArtistUsername(webArtistUsername);
      setArtistDisplayName(webArtistFullName);
      setIsOpen(true);
    } else {
      unsubscribeModalOpen(artist);
    }
  };

  const onTabPress = (index: number) => {
    setActiveIndex(index);
  };

  const onFollowPress = async () => {
    if (followingStatus?.is_following) {
      await unfollow();
    } else {
      await follow();
    }
    refreshFeed();
  };

  const { tablet: isTablet } = useScreenSize();

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        label: isTablet ? 'All posts' : <IconAll focused={activeIndex === 0} />,
      },
      {
        label: <IconAudio focused={activeIndex === 1} />,
      },
      {
        label: <IconImage focused={activeIndex === 2} />,
      },
      {
        label: <IconVideo focused={activeIndex === 3} />,
      },
      {
        label: <IconPoll focused={activeIndex === 4} />,
      },
    ];
    return config.fanMarketplaceEnabled
      ? [
          ...baseTabs,
          {
            label: <IconMarketplace focused={activeIndex === 5} />,
          },
        ]
      : baseTabs;
  }, [config.fanMarketplaceEnabled, isTablet, activeIndex]);

  const onGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      return navigation.goBack();
    }
  }, [navigation]);

  const renderContent = () => (
    <>
      <WebBackgroundImage url={getCoverImage(artist)} />
      <WebBlur />
      <WebGradient />
      <NavigationHeaderFanWrapper isModalOpen={isModalOpen}>
        <NavigationHeaderFan
          navigation={navigation}
          customBackAction={onGoBack}
          hideArtistInput={!isAuthenticated}
          hideSearchBar={!isAuthenticated}
          hideMenuItems={!isAuthenticated}
        />
      </NavigationHeaderFanWrapper>
      <ArtistProfileHeader
        name={artist?.display_name}
        username={artist?.username}
        description={artist?.bio}
        firstLink={artist?.primary_link}
        secondLink={artist?.secondary_link}
        imageUrl={artist?.profile_picture}
        isLoadingSubscribed={isLoadingSubscriptionStatus || isUnsubscribing}
        isSubscribed={isSubscribed}
        isSubscriptionPendingCancelation={isSubscriptionPendingCancelation}
        isLoadingFollowing={isLoadingFollowing}
        isFollowing={followingStatus?.is_following}
        onSubscribePress={onSubscribePress}
        onFollowPress={onFollowPress}
        onLoginPress={onLoginPress}
      />
    </>
  );

  const renderNotFound = () => (
    <>
      <NavigationHeaderFanWrapper>
        <NavigationHeaderFan navigation={navigation} />
      </NavigationHeaderFanWrapper>
      <ContentWrapper>
        <ErrorText>
          {intl.formatMessage(messages.error.notFound, { username: artistUsername })}
        </ErrorText>
        <Button short primary onPress={navigateHome}>
          {intl.formatMessage(messages.goBack)}
        </Button>
      </ContentWrapper>
    </>
  );

  const renderBackground = () => {
    return (
      <WebHeader isModalOpen={isModalOpen}>
        {isNotFound ? renderNotFound() : artist ? renderContent() : null}
      </WebHeader>
    );
  };

  const renderTabs = () => {
    return (
      <TabBar
        center={false}
        activeBackground="rgba(255, 255, 255, 0.5)"
        tabs={tabs.map((tab) => tab.label)}
        tabLabelStyle={{
          fontFamily: 'BasierCircle-Regular',
          fontSize: 13,
          lineHeight: 18,
          textAlign: 'center',
          letterSpacing: -0.08,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        activeLabelColor="#fff"
        inactiveLabelColor="rgba(255,255,255,0.52)"
        onTabPress={onTabPress}
        activeIndex={activeIndex}
      />
    );
  };

  return (
    <WebContainer background={renderBackground} isModalOpen={isModalOpen}>
      <TabBarWrapper>{renderTabs()}</TabBarWrapper>
      <ContentWrapper>
        {!isAuthenticated ? (
          <ContentWrapper>
            <TouchableOpacity onPress={() => navigation.navigate(LOGIN_STACK)}>
              <Label>{intl.formatMessage(messages.logoutUser)}</Label>
            </TouchableOpacity>
          </ContentWrapper>
        ) : isLoadingImages &&
          isLoadingAudios &&
          isLoadingPolls &&
          isLoadingVideos &&
          isLoadingMarketplace ? (
          <ActivityIndicator animating />
        ) : (
          <NewMasonry data={currentData} onProductPreview={navigateToProduct} />
        )}
      </ContentWrapper>
      <Footer />
    </WebContainer>
  );
};

export default ArtistProfileScreen;
