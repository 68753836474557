import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import NavigationHeader from 'components/NavigationHeader/NavigationHeader';
import ScreenView from 'components/ScreenView';

import { usePostEngagement, usePostGeneralInsights, usePostReach, usePostRevenue } from 'hooks';
import { useLiveStreamInsights } from 'hooks/insights';
import { Bookmark, Comment, Like, Share } from 'svg';
import { formatBigNumber, formatNumberWithCommas } from 'utils/number';
import { secondsToTimeString } from 'utils/time';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Col from './styled/Col';
import Container from './styled/Container';
import Divider from './styled/Divider';
import IconView from './styled/IconView';
import MainView from './styled/MainView';
import ReactionCount from './styled/ReactionCount';
import Row from './styled/Row';
import Tile from './styled/Tile';
import TileFooter from './styled/TileFooter';
import TileRow from './styled/TileRow';
import TileRowContent from './styled/TileRowContent';
import TileText from './styled/TileText';

type ArtistPostInsightsRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ArtistPostInsightsNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ArtistPostInsightsRouteProp;
  navigation: ArtistPostInsightsNavigationProp;
};

export { SCREEN_NAME };

const ArtistPostInsights: React.FC<Props> = ({ route, navigation }: Props) => {
  const { id, model_name } = route.params;
  const showEngagement = useMemo(
    () => ['audio', 'video', 'live_stream', 'past_live_stream'].includes(model_name),
    [model_name],
  );
  const { data: general, isLoading: isGeneralLoading } = [
    'live_stream',
    'past_live_stream',
  ].includes(model_name)
    ? useLiveStreamInsights(id)
    : usePostGeneralInsights(id, model_name);
  const { data: revenue, isLoading: isRevenueLoading } = usePostRevenue(id, model_name);
  const { data: reach, isLoading: isReachLoading } = usePostReach(id, model_name);
  const { data: engagement, isLoading: isEngagementLoading } = showEngagement
    ? usePostEngagement(id, model_name)
    : { data: undefined, isLoading: false };
  const intl = useIntl();

  return (
    <ScreenView>
      <Container stickyHeaderIndices={[0]}>
        <NavigationHeader
          insets={{ top: 15 }}
          navigation={navigation}
          title={intl.formatMessage(messages.title)}
        />
        <MainView>
          <Tile title={intl.formatMessage(messages.general.title)} isLoading={isGeneralLoading}>
            <Row>
              <IconView>
                <Like />
                <ReactionCount>{formatBigNumber(general?.likes_count)}</ReactionCount>
              </IconView>
              <IconView>
                <Comment />
                <ReactionCount>{formatBigNumber(general?.comments_count)}</ReactionCount>
              </IconView>
              <IconView>
                <Bookmark />
                <ReactionCount>{formatBigNumber(general?.bookmarks_count)}</ReactionCount>
              </IconView>
              <IconView>
                <Share />
                <ReactionCount>{formatBigNumber(general?.shares_count)}</ReactionCount>
              </IconView>
            </Row>
          </Tile>

          {showEngagement && (
            <Tile
              title={intl.formatMessage(messages.engagement.title)}
              isLoading={isEngagementLoading}
            >
              <Row>
                <TileText size={'extra-large'} variant={'highlight'}>
                  {secondsToTimeString(engagement?.average_duration)}
                </TileText>
                <TileText size={'small'}>{engagement?.average_duration_in_percent}%</TileText>
              </Row>
              <Divider small />
              <TileText size={'extra-small'}>
                {intl.formatMessage(messages.engagement.info)}
              </TileText>
              <Divider />
              <TileText size={'extra-small'}>
                {intl.formatMessage(messages.engagement.footer, {
                  value: secondsToTimeString(engagement?.full_duration),
                })}
              </TileText>
            </Tile>
          )}

          <TileRow>
            <Tile
              title={intl.formatMessage(messages.tips.title)}
              mode="row"
              isLoading={isRevenueLoading}
            >
              <TileRowContent>
                <TileText size={'large'} variant={'success'}>
                  {formatNumberWithCommas(Number.parseFloat(revenue?.total_tip_revenue || '0'))}
                </TileText>
                <TileText size={'medium'}>
                  {intl.formatMessage(messages.tips.average, {
                    value: Number.parseFloat(revenue?.average_tip || '0').toFixed(2),
                  })}
                </TileText>
              </TileRowContent>
              <TileFooter>
                <TileText size={'extra-small'}>
                  {/* {intl.formatMessage(messages.tips.footer, {
                    value: formatNumberWithCommas(revenue?.tips_count),
                  })} */}
                  {intl.formatMessage(messages.tips.financialData)}
                </TileText>
              </TileFooter>
            </Tile>
            <Tile
              title={intl.formatMessage(messages.stans.title)}
              mode="row"
              isLoading={isRevenueLoading}
            >
              <TileRowContent>
                <TileText size={'extra-large'} variant={'highlight'}>
                  {revenue?.new_subscriptions}
                </TileText>
                <TileText size={'medium'} variant={'success'}>
                  {intl.formatMessage(messages.stans.perMonth, {
                    value: revenue?.subscriptions_revenue,
                  })}
                </TileText>
              </TileRowContent>
              <TileFooter>
                <TileText size={'extra-small'}>
                  {intl.formatMessage(messages.stans.footer)}
                </TileText>
              </TileFooter>
            </Tile>
          </TileRow>

          <Tile title={intl.formatMessage(messages.reach.title)} isLoading={isReachLoading}>
            <TileText size={'extra-large'} variant={'highlight'}>
              {formatNumberWithCommas(reach?.views_count)}
            </TileText>
            <Divider small />
            <TileText size={'extra-small'}>
              {intl.formatMessage(messages.reach.following, {
                value:
                  reach?.percentage_of_views_count_that_are_neither_follower_or_subscriber.toFixed(
                    0,
                  ),
              })}
            </TileText>
            <Divider />
            <Row>
              <Col>
                <TileText size={'extra-small'} variant={'highlight'}>
                  {intl.formatMessage(messages.reach.stans)}
                </TileText>
                <Divider small />
                <Row>
                  <TileText size={'medium'} variant={'highlight'}>
                    {formatNumberWithCommas(reach?.subscriber_views_count)}
                  </TileText>
                  <TileText size={'small'}>
                    {reach?.percentage_of_views_coming_from_subscriber.toFixed(0)}%
                  </TileText>
                </Row>
              </Col>
              <Col paddingLeft>
                <TileText size={'extra-small'} variant={'highlight'}>
                  {intl.formatMessage(messages.reach.followers)}
                </TileText>
                <Divider small />
                <Row>
                  <TileText size={'medium'} variant={'highlight'}>
                    {formatNumberWithCommas(reach?.followers_views_count)}
                  </TileText>
                  <TileText size={'small'}>
                    {reach?.percentage_of_views_coming_from_follower.toFixed(0)}%
                  </TileText>
                </Row>
              </Col>
            </Row>
          </Tile>
        </MainView>
      </Container>
    </ScreenView>
  );
};

export default ArtistPostInsights;
