import styled, { css } from 'styled-components/native';

interface TextProps {
  underline?: boolean;
}

export default styled.Text<TextProps>`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.52);
  letter-spacing: -0.23px;
  text-align: center;

  ${({ underline }) =>
    underline &&
    css`
      color: #bcffc9;
      text-decoration: underline;
      text-decoration-color: #bcffc9 !important;
    `}
`;
