import styled, { css } from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.backgroundLight};
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin: 20px 0 0 0;

  ${({ onPress }) =>
    onPress &&
    css`
      font-weight: bold;
    `}
`;
