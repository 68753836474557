import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;

  ${() =>
    Platform.OS === 'web' &&
    css`
      margin-left: 0;
      margin-right: 0;
    `}
`;
