import styled, { css } from 'styled-components/native';

export default styled.View`
  width: 100%;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 90%;
    `};

  ${({ theme }) =>
    theme.media.desktop &&
    css`
      width: 60%;
    `}
`;
