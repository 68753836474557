import styled from 'styled-components/native';

import OverlayButton from 'components/OverlayButton';

export default styled(OverlayButton)`
  position: absolute;
  right: 15px;
  bottom: 8px;
  z-index: 1;
`;
