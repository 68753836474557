import * as React from 'react';
import { SvgProps } from 'react-native-svg';

import { StyledIconActive, StyledIconInactive } from 'components/Icons/IconMarketplace';
import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused?: boolean;
}

const Icon = ({ focused, ...rest }: IconProps) => (
  <TabBarIcon
    focused={!!focused}
    iconActive={<StyledIconActive />}
    iconInactive={<StyledIconInactive />}
    {...rest}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
