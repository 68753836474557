import styled from 'styled-components/native';

export default styled.View<{ height: number }>`
  flex-direction: row;
  height: ${({ height }) => height || 0}px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;
