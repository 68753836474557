import React from 'react';
import { ViewProps } from 'react-native';

import { milliSecondsToTimeString } from 'utils/time';

import Duration from './styled/Duration';
import Timer from './styled/Timer';
import View from './styled/View';

interface VideoTimestamp {
  durationMillis?: number;
  positionMillis?: number;
}

type Props = VideoTimestamp & ViewProps;

const VideoTimestamp: React.FC<Props> = ({
  durationMillis,
  positionMillis,
  ...restProps
}: Props) => {
  return (
    <View {...restProps}>
      <Timer>{milliSecondsToTimeString(positionMillis, 'h?:m:ss')}</Timer>
      <Duration>{` / ${milliSecondsToTimeString(durationMillis, 'h?:m:ss')}`}</Duration>
    </View>
  );
};

export default VideoTimestamp;
