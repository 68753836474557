import { AntDesign } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/core';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import cardValidator from 'card-validator';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard, Platform, ScrollView, Text, View } from 'react-native';
import { Image, Options, Video } from 'react-native-image-crop-picker';

import { SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack/constants';
import NavigationHeaderFanWrapper from 'stacks/fan/FanMarketplaceStack/styled/NavigationHeaderFanWrapper';
import { SCREEN_NAME as FAN_TAB_STACK_SCREEN_NAME } from 'stacks/fan/FanTabStack/constants';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as MARKETPLACE_NEW_MESSAGE_SCREEN_NAME } from 'screens/MarketplaceNewMessageScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME } from 'screens/MarketplaceOrderInformationScreen/constants';
import Avatar from 'screens/MarketplaceOrderInformationScreen/styled/Avatar';
import IconFlag from 'screens/MarketplaceOrderInformationScreen/styled/IconFlag';
import IconHelp from 'screens/MarketplaceOrderInformationScreen/styled/IconHelp';
import IconWrapper from 'screens/MarketplaceOrderInformationScreen/styled/IconWrapper';
import OptionGroupItem from 'screens/MarketplaceOrderInformationScreen/styled/OptionGroupItem';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen';
import { SCREEN_NAME as MARKETPLACE_ORDERS_SCREEN_NAME } from 'screens/MarketplaceOrdersScreen/constants';
import OrderPreview from 'screens/MarketplaceProductOrderScreen/styled/OrderPreview';

import ArtistMarketplaceOrderHeader from 'components/ArtistMarketplaceOrderHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import CreditCardIcon from 'components/CreditCardIcon';
import Dialog from 'components/Dialog';
import NavigationHeader from 'components/NavigationHeader';
import NavigationHeaderFan from 'components/NavigationHeaderFan';
import OrderAttachment from 'components/OrderAttachment';
import DecoratedTitle from 'components/SubNavigation/DecoratedTitle';
import TextInput from 'components/TextInput';

import { useSnackbarSet, VARIANT, WebviewContext } from 'context';
import { useImagePicker } from 'hooks';
import {
  useArtistMarketplaceUpdateOrder,
  useMarketPlaceConversation,
  useMarketplaceOrderControls,
} from 'hooks/marketplace';
import { useTheme } from 'themes';
import { MarketplaceOrderMessage, MarketplaceQuestionareAnswer, OrderStatus } from 'types';
import { getExtension, getFilename, humanFileSize } from 'utils/file';
import { helpURL } from 'utils/links';
import { parseItemPrice } from 'utils/marketplace';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import OrderNumberContainer from './OrderNumberContainer';
import ActionsContainer from './styled/ActionsContainer';
import AttachmentContainer from './styled/AttachmentContainer';
import AttachmentInformation from './styled/AttachmentInformation';
import BackButton from './styled/BackButton';
import ConfirmationButton from './styled/ConfirmationButton';
import Container from './styled/Container';
import ConversationInfo from './styled/ConversationInfo';
import DetailsContainer from './styled/DetailsContainer';
import DetailsTabView from './styled/DetailsTabView';
import DialogButton from './styled/DialogButton';
import HeaderWrapper from './styled/HeaderWrapper';
import IconInformation from './styled/IconInformation';
import LinkText from './styled/LinkText';
import Main, { MainWrapper, Section } from './styled/Main';
import Message from './styled/Message';
import MessageList from './styled/MessageList';
import MessageSeparator from './styled/MessageSeparator';
import OrderDetailsHeader from './styled/OrderDetailsHeader';
import OrderStatusDescription from './styled/OrderStatusDescription';
import QuestionareOption from './styled/QuestionareOption';
import QuestionaryContainer from './styled/QuestionaryContainer';
import ScreenView from './styled/ScreenView';
import SectionTitle from './styled/SectionTitle';
import Separator from './styled/Separator';
import SubNavWrapper from './styled/SubNavWrapper';
import Tab from './styled/Tab';
import TabBar from './styled/TabBar';
import TabBarWrapper from './styled/TabBarWrapper';

import ConfirmCompletingOrderDialog from 'dialogs/ConfirmCompletingOrderDialog';
export { SCREEN_NAME };

type MarketplaceOrderDetailsRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type MarketplaceOrderDetailsNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: MarketplaceOrderDetailsRouteProp;
  navigation: MarketplaceOrderDetailsNavigationProp;
};

const pickerNativeOptions = {
  cropping: false,
  mediaType: 'any',
  compressVideoPreset: 'HighestQuality',
} as Options;

const pickerWebOptions = {
  allowsEditing: false,
  quality: 1,
  mediaType: 'any',
  compressVideoPreset: 'HighestQuality',
};

type Params = {
  order: string;
  marketplaceProductId: number;
};

const MarketplaceOrderDetailsScreen: React.FC<Props> = () => {
  const intl = useIntl();
  const { theme } = useTheme();
  const route = useRoute();
  const navigation = useNavigation<any>();
  const { order: paramOrder, marketplaceProductId } = route.params as Params;
  const [isUploading, setIsUploading] = useState(false);
  const [editAttachmentModalVisible, setEditAttachmentModalVisible] = useState(false);
  const { setSnackbar } = useSnackbarSet();
  const scrollRef = useRef<ScrollView>(null);
  const { markMessagesAsRead } = useMarketPlaceConversation();
  const { openWebView } = useContext(WebviewContext);
  console.debug(marketplaceProductId, paramOrder);

  const {
    order,
    isDialogOpen,
    setIsDialogOpen,
    onDialogConfirmPress,
    onActionPress,
    showCTA,
    showStatus,
    refresh,
  } = useMarketplaceOrderControls(JSON.parse(paramOrder));

  const hasMessages = useMemo(() => !!order?.messages?.length, [order]);
  const canReplyMessage = useMemo(
    () => order?.status != OrderStatus.COMPLETED && order?.status != OrderStatus.DECLINED,
    [order?.status],
  );
  const reversedMessages = useMemo(
    () => (order?.messages ? order?.messages.reverse() : []),
    [order?.messages],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    name: string;
  }>({
    mode: 'onChange',
  });

  const { uploadAttachment, updateAttachment } = useArtistMarketplaceUpdateOrder();

  const onSubmit = useCallback(
    async ({ name }: { name: string }) => {
      setIsUploading(true);
      try {
        if (!order) return false;
        const newFilename = `${name}.${getExtension(order.attachment.name)}`;
        await updateAttachment(
          order.product.id,
          order.external_id,
          order.attachment.id,
          newFilename,
        );
        refresh();
        setEditAttachmentModalVisible(false);
      } catch (e) {
        console.debug(e);
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.attachment.error.update),
        });
      } finally {
        setIsUploading(false);
      }
    },
    [updateAttachment, order, setSnackbar, refresh],
  );

  const doSubmit = useCallback(() => {
    if (!isValid || isUploading) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [handleSubmit, isUploading, onSubmit]);

  const onMediaChange = useCallback(
    async (result: Image | Video) => {
      setIsUploading(true);
      try {
        if (!order) return false;
        await uploadAttachment(order.product.id, order.external_id, result.path, result.mime);
        refresh();
      } catch (e) {
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.attachment.error.upload),
        });
      } finally {
        setIsUploading(false);
      }
    },
    [uploadAttachment, order, setSnackbar, refresh],
  );

  const { launchImagePicker } = useImagePicker({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onMediaChange,
  });

  const showPaymentDetails = useMemo(
    () => VARIANT === 'fan' && order?.status !== OrderStatus.WAITING_FOR_PAYMENT,
    [order?.status],
  );

  const showAttachment = useMemo(
    () => VARIANT === 'artist' || order?.status === OrderStatus.COMPLETED || order?.attachment,
    [order?.status, order?.attachment],
  );

  const navigateToOrderInformation = useCallback(() => {
    navigation.navigate(MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME, {
      marketplaceProductId,
      order: JSON.stringify(order),
    });
  }, [navigation, order]);

  const navigateToNewMessage = useCallback(() => {
    navigation.navigate(MARKETPLACE_NEW_MESSAGE_SCREEN_NAME, {
      artistUsername: order?.product?.artist?.username,
      marketplaceProductId,
      orderExternalId: order?.external_id,
      onMessageAdded: refresh,
    });
  }, [order?.external_id, order?.product, marketplaceProductId, refresh]);

  const navigateToArtist = () => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      artistUsername: order?.product.artist.username,
    });
  };

  const navigateToOrderReport = () => {
    navigation.navigate(MARKETPLACE_ORDER_REPORT_SCREEN_NAME, {
      orderExternalId: order?.external_id,
      artistUsername: order?.product.artist?.username,
      marketplaceProductId,
    });
  };

  const onHelpPress = useCallback(() => {
    openWebView?.(helpURL);
  }, [openWebView]);

  const confirmButtonCopy = useMemo(() => {
    if (VARIANT === 'artist' && order?.is_artist_asked_user_for_confirmation)
      return intl.formatMessage(messages.awaitingConfirmation);
    switch (order?.status) {
      case OrderStatus.WAITING_FOR_PAYMENT:
        return intl.formatMessage(messages[VARIANT].continueOrder);
      default:
        return intl.formatMessage(messages[VARIANT].confirm);
    }
  }, [order?.status, order?.is_artist_asked_user_for_confirmation]);

  const onUploadAttachmentPress = async () => {
    await launchImagePicker({ pickerNativeOptions, pickerWebOptions });
  };

  const renderDetails = () => (
    <>
      {!!order?.questionare_answers?.length && (
        <Section>
          <QuestionaryContainer>
            <SectionTitle title={intl.formatMessage(messages.purchaseQuestionnaire[VARIANT])} />
            {order?.questionare_answers.map(
              ({
                questionare: { id: questionareId, question },
                text,
              }: MarketplaceQuestionareAnswer) => (
                <QuestionareOption
                  key={`question-${questionareId}`}
                  title={question}
                  description={text}
                  style={Platform.OS === 'web' && { paddingRight: 0 }}
                />
              ),
            )}
          </QuestionaryContainer>
        </Section>
      )}

      {showPaymentDetails && (
        <Section>
          <DetailsContainer>
            <SectionTitle title={intl.formatMessage(messages.orderDetails)} />
            <QuestionareOption
              title={intl.formatMessage(messages.amountPaid)}
              description={parseItemPrice(order?.payment.amount, order?.payment.amount_currency)}
            />
            <QuestionareOption
              title={intl.formatMessage(messages.paymentMethod)}
              icon={() =>
                order?.payment.credit_card && (
                  <CreditCardIcon
                    cardType={
                      cardValidator.number(order?.payment.credit_card?.first_six_digits).card?.type
                    }
                  />
                )
              }
              description={
                order?.payment.credit_card &&
                intl.formatMessage(messages.card, {
                  lastFourDigits: order?.payment.credit_card.last_four_digits,
                })
              }
            />
          </DetailsContainer>
        </Section>
      )}
    </>
  );

  const renderDetailsMobile = () => (
    <DetailsTabView>
      <View>{renderDetails()}</View>
      <View>
        {showCTA && (
          <ActionsContainer>
            {VARIANT === 'artist' && !order?.is_artist_asked_user_for_confirmation && (
              <ConfirmationButton
                onPress={onUploadAttachmentPress}
                processing={isUploading}
                disabled={isUploading}
              >
                {intl.formatMessage(
                  order?.attachment ? messages.attachment.change : messages.attachment.upload,
                )}
              </ConfirmationButton>
            )}
            <ConfirmationButton
              disabled={VARIANT === 'artist' && order?.is_artist_asked_user_for_confirmation}
              onPress={onActionPress}
            >
              {confirmButtonCopy}
            </ConfirmationButton>
            {VARIANT === 'artist' && !hasMessages && (
              <LinkText>
                {intl.formatMessage(messages.extraInfo, {
                  cta: function cta(chunk) {
                    return <LinkText onPress={navigateToNewMessage}>{chunk}</LinkText>;
                  },
                })}
              </LinkText>
            )}
          </ActionsContainer>
        )}
      </View>
      {showStatus && hasMessages && order && (
        <OrderStatusDescription
          status={order.status}
          returnFundsDays={12}
          date={order.time_updated}
        />
      )}
    </DetailsTabView>
  );

  const renderMessage = ({ item, index }: { item: MarketplaceOrderMessage; index: number }) => (
    <>
      <Message
        message={item}
        showRespond={
          canReplyMessage && !!order?.messages.length && index === order.messages.length - 1
        }
        onRespondPress={navigateToNewMessage}
        key={`message-${index}`}
      />
      <MessageSeparator key={`separator-${index}`} />
    </>
  );

  const renderConversation = () => (
    <>
      {!canReplyMessage && (
        <ConversationInfo>
          {intl.formatMessage(
            order?.status == OrderStatus.COMPLETED
              ? messages.conversationCompleted.COMPLETED
              : messages.conversationCompleted.DECLINED,
          )}
        </ConversationInfo>
      )}
      <MessageList
        ref={scrollRef}
        onContentSizeChange={() => {
          scrollRef.current?.scrollToEnd({ animated: true });
        }}
      >
        {reversedMessages.map((item, index) => renderMessage({ item, index }))}
        {showStatus && order && (
          <OrderStatusDescription
            status={order.status}
            returnFundsDays={12}
            date={order.time_updated}
          />
        )}
      </MessageList>
    </>
  );

  const onTabPress = useCallback(
    (index: number) => {
      if (index === 1) {
        console.debug('focus', order?.product?.artist?.username);
        if (!order) return;
        markMessagesAsRead(
          VARIANT == 'fan' ? order.product.artist.username : 'me',
          order.product.id,
          order.external_id,
        );
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate(`Tab ${index + 1}`);
    },
    [navigation, markMessagesAsRead, paramOrder],
  );

  const detailsTab = {
    label: intl.formatMessage(messages.purchaseQuestionnaire[VARIANT]),
    renderTab: renderDetails,
  };
  const conversationTab = {
    label: intl.formatMessage(messages.conversation),
    renderTab: renderConversation,
  };

  const tabs = useMemo(
    () =>
      order?.questionare_answers?.length === 0 ? [conversationTab] : [detailsTab, conversationTab],
    [order?.questionare_answers],
  );

  const renderTabs = () => (
    <Tab.Navigator
      sceneContainerStyle={{ backgroundColor: theme.colors.background }}
      tabBar={({ state }) => {
        return (
          <TabBarWrapper>
            <TabBar
              tabs={tabs.map((tab) => tab.label)}
              activeIndex={state.index}
              onTabPress={onTabPress}
            />
          </TabBarWrapper>
        );
      }}
    >
      {tabs.map((tab, tabIndex) => (
        <Tab.Screen key={`Tab ${tabIndex + 1}`} name={`Tab ${tabIndex + 1}`}>
          {() => tab.renderTab()}
        </Tab.Screen>
      ))}
    </Tab.Navigator>
  );

  const renderMainSection = () => (
    <>
      {Platform.OS === 'web' ? (
        <>
          <Section>
            <OrderPreview
              artistName={order?.product.artist?.display_name || ''}
              name={order?.product.name || ''}
              previewImage={order?.product?.low_res_cover || ''}
              price={order?.payment.amount || ''}
              currency={order?.payment.amount_currency || ''}
              withoutMarker
              imageSize="100px"
              fontSizes={{
                artist: 15,
                name: 20,
                price: 15,
              }}
            />
            {showCTA && (
              <ConfirmationButton onPress={onActionPress} withMargins>
                {confirmButtonCopy}
              </ConfirmationButton>
            )}
            <Separator />
            {showAttachment && (
              <AttachmentContainer>
                <SectionTitle title={intl.formatMessage(messages.attachment.title)} />
                {order?.attachment ? (
                  <OrderAttachment
                    name={order?.attachment.name}
                    size={humanFileSize(order?.attachment.file_size)}
                    previewImage={order?.attachment.file}
                    sendDate={order?.attachment.time_created}
                    onEditPress={() => setEditAttachmentModalVisible(true)}
                  />
                ) : (
                  <AttachmentInformation>
                    {intl.formatMessage(messages.attachment.missing)}
                  </AttachmentInformation>
                )}
              </AttachmentContainer>
            )}
            <OptionGroupItem
              labelPrefixComponent={
                <Avatar
                  mode="minimal-row"
                  imageUrl={order?.product.artist.low_res_profile_picture}
                  showTimestamp={false}
                  showName={false}
                />
              }
              label={order?.product.artist.display_name || ''}
              onAction={navigateToArtist}
              margin="0 0 8px"
            />
            <OptionGroupItem
              labelPrefixComponent={
                <IconWrapper>
                  <IconHelp />
                </IconWrapper>
              }
              label={intl.formatMessage(messages.help)}
              onAction={onHelpPress}
              margin="8px 0"
            />
            <OptionGroupItem
              labelPrefixComponent={
                <IconWrapper>
                  <IconFlag />
                </IconWrapper>
              }
              label={intl.formatMessage(messages.report)}
              onAction={navigateToOrderReport}
              labelColor={theme.colors.errorAccent}
              noArrow
              margin="8px 0"
            />
          </Section>
          {hasMessages ? <Section wild>{renderTabs()}</Section> : renderDetails()}
        </>
      ) : (
        <>
          <HeaderWrapper>
            {VARIANT === 'fan' ? (
              <OrderDetailsHeader
                artistName={order?.product.artist?.display_name || ''}
                name={order?.product.name || ''}
                previewImage={order?.product.low_res_cover}
                status={order?.status}
                waitingForConfirmation={
                  order?.is_artist_asked_user_for_confirmation &&
                  order?.status !== OrderStatus.COMPLETED
                }
              />
            ) : (
              <ArtistMarketplaceOrderHeader
                cover={order?.product.low_res_cover || ''}
                avatar={order?.user?.low_res_profile_picture || ''}
                timestamp={order?.time_created}
                title={order?.product.name || ''}
                description={order?.user?.full_name || ''}
                status={order?.status}
              />
            )}
          </HeaderWrapper>
          {showAttachment && (
            <AttachmentContainer>
              <SectionTitle title={intl.formatMessage(messages.attachment.title)} />
              {order?.attachment ? (
                <OrderAttachment
                  name={order?.attachment.name}
                  size={humanFileSize(order?.attachment.file_size)}
                  previewImage={order?.attachment.file}
                  sendDate={order?.attachment.time_created}
                  onEditPress={() => setEditAttachmentModalVisible(true)}
                />
              ) : (
                <AttachmentInformation>
                  {intl.formatMessage(messages.attachment.missing)}
                </AttachmentInformation>
              )}
            </AttachmentContainer>
          )}
          {hasMessages ? renderTabs() : renderDetailsMobile()}
        </>
      )}
    </>
  );

  return (
    <ScreenView>
      {Platform.OS === 'web' ? (
        <>
          <NavigationHeaderFanWrapper>
            <NavigationHeaderFan showBackButton={false} />
          </NavigationHeaderFanWrapper>
          <SubNavWrapper>
            <BackButton
              isActive
              onPress={() => navigation.navigate(MARKETPLACE_ORDERS_SCREEN_NAME)}
            >
              <DecoratedTitle isActive>
                <AntDesign
                  name="arrowleft"
                  size={14}
                  color="white"
                  style={{ marginRight: '5px' }}
                />
                <Text>{intl.formatMessage(messages.back)}</Text>
              </DecoratedTitle>
            </BackButton>
            <Breadcrumbs
              expireParents
              links={[
                {
                  title: 'Marketplace',
                  onPress: () => navigation.navigate(MARKETPLACE_STACK_SCREEN_NAME),
                },
                {
                  title: 'Your orders',
                  onPress: () => navigation.navigate(MARKETPLACE_ORDERS_SCREEN_NAME),
                },
                {
                  title: order?.number || '',
                },
              ]}
            />
          </SubNavWrapper>
        </>
      ) : (
        <NavigationHeader
          insets={{ top: 15 }}
          navigation={navigation}
          headerRightImage={() => <IconInformation />}
          headerRightOnPress={navigateToOrderInformation}
          title={order?.number}
          headerBackOnPress={
            order?.status === OrderStatus.PURCHASED && VARIANT === 'fan'
              ? () =>
                  navigation.navigate(FAN_TAB_STACK_SCREEN_NAME, {
                    screen: MARKETPLACE_STACK_SCREEN_NAME,
                    params: {
                      screen: MARKETPLACE_ORDERS_SCREEN_NAME,
                    },
                  })
              : undefined
          }
        />
      )}
      <Container>
        {Platform.OS === 'web' ? (
          <>
            <MainWrapper>
              {order && (
                <OrderNumberContainer
                  number={order.number}
                  statusProps={{
                    status: order?.status,
                    waitingForConfirmation:
                      order.is_artist_asked_user_for_confirmation &&
                      order.status !== OrderStatus.COMPLETED,
                  }}
                />
              )}
              {<Main>{renderMainSection()}</Main>}
            </MainWrapper>
          </>
        ) : (
          <>{renderMainSection()}</>
        )}
      </Container>

      {showStatus && !hasMessages && order && (
        <OrderStatusDescription
          status={order.status}
          returnFundsDays={12}
          date={order.time_updated}
        />
      )}

      <ConfirmCompletingOrderDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onConfirmation={onDialogConfirmPress}
      />

      <Dialog
        visible={editAttachmentModalVisible}
        title={intl.formatMessage(messages.editFile.title)}
      >
        <Controller
          name="name"
          control={control}
          defaultValue={order?.attachment?.name ? getFilename(order?.attachment.name) : ''}
          rules={{
            required: intl.formatMessage(messages.editFile.error.required),
            pattern: {
              value: regex.filename,
              message: intl.formatMessage(messages.editFile.error.invalid),
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <TextInput
              label={intl.formatMessage(messages.editFile.label)}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
            />
          )}
        />
        <DialogButton primary disabled={!isValid} processing={isUploading} onPress={doSubmit}>
          {intl.formatMessage(messages.editFile.save)}
        </DialogButton>
        <DialogButton secondary onPress={() => setEditAttachmentModalVisible(false)}>
          {intl.formatMessage(messages.editFile.cancel)}
        </DialogButton>
      </Dialog>
    </ScreenView>
  );
};

export default MarketplaceOrderDetailsScreen;
