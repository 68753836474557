import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

interface ButtonProps {
  secondary?: boolean;
  theme?: any;
}

export default styled(Button).attrs((props: ButtonProps) => ({
  labelColor: props.secondary ? props.theme.button.backgroundColorBlue : props.theme.button.text,
  bold: true,
}))`
  margin: 5px 0;
  border: 1px solid #ffffff;
  border-radius: 40px;
  height: 50px;
  width: 92%;

  background-color: ${(props: ButtonProps) =>
    props.secondary ? props.theme.button.text : props.theme.button.backgroundColorBlue};

  ${Platform.OS === 'web' &&
  css`
    padding: 12px 25px;
    height: auto;
    width: auto;
    border: 1.6px solid #ffffff;
    margin: 0;
  `}
`;
