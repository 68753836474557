import React from 'react';
import { LayoutChangeEvent } from 'react-native';
import styled, { css } from 'styled-components/native';

const View = styled.View`
  align-self: center;
  flex-direction: column;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      flex-direction: row;
    `}
`;

const TouchableOpacity = styled.TouchableOpacity<{ selected: boolean; isFirst: boolean }>`
  padding: 8px 15px;
  ${({ selected }) =>
    selected &&
    css`
      background: rgba(255, 255, 255, 0.12);
      border-radius: 10px;
    `}

  ${({ isFirst, theme }) =>
    !isFirst &&
    theme.media.tablet &&
    css`
      margin-left: 30px;
    `}
`;

const Label = styled.Text<{ selected: boolean }>`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: #ffffff;
  opacity: ${({ selected }) => (selected ? 1 : 0.3)};
`;

interface Tab {
  label: string;
}

interface Props {
  tabs: Tab[];
  onTabIndexChange: (index: number) => void;
  currentTabIndex: number;
  onLayout: (event: LayoutChangeEvent) => void;
}

const Tabs: React.FC<Props> = ({ tabs, onTabIndexChange, currentTabIndex, onLayout }: Props) => {
  return (
    <View onLayout={onLayout}>
      {tabs.map((tab, index) => {
        const isSelected = currentTabIndex === index;
        return (
          <TouchableOpacity
            isFirst={index === 0}
            selected={isSelected}
            onPress={() => onTabIndexChange(index)}
            key={`tab-${index}`}
          >
            <Label selected={isSelected}>{tab.label}</Label>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default Tabs;
