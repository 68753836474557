import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import { useBackEnd } from 'context/backEnd';
import { Artist } from 'types';

interface UseSearch {
  isLoading: boolean;
  artistsResults: Artist[];
  groupType: GroupType;
  searchMode: boolean;
  searchQuery: string;
  changeSearchMode: (searchMode: boolean) => void;
  changeGroupType: (group: GroupType) => void;
  changeSearchQuery: (query: string) => void;
  onSearchCancel: () => void;
}

export enum GroupType {
  Artists,
  Events,
}

export function useSearch(): UseSearch {
  const cancelTokenSource = axios.CancelToken.source();
  const { axiosInstance } = useBackEnd();
  const queryRef = useRef('');
  const [searchMode, changeSearchMode] = useState<boolean>(false);
  const [groupType, changeGroupType] = useState<GroupType>(GroupType.Artists);
  const [searchQuery, changeSearchQuery] = useState<string>('');
  const [artistsResults, setArtistsResults] = useState<Artist[]>([]);
  const [handlingRequest, setHandlingRequest] = useState<boolean>(false);

  const onSearchCancel = () => {
    changeSearchMode(false);
    changeSearchQuery('');
  };

  useEffect(() => {
    if (searchQuery) {
      if (!handlingRequest && queryRef.current !== searchQuery) {
        setHandlingRequest(true);
        changeSearchMode(true);
        queryRef.current = searchQuery;
        axiosInstance
          .get(`/artists?display_name=${searchQuery}`, {
            cancelToken: cancelTokenSource.token,
          })
          .then(({ data }: any) => {
            setArtistsResults(data);
            setHandlingRequest(false);
          });
      }
    } else {
      setArtistsResults([]);
      queryRef.current = '';
    }
  }, [searchQuery, handlingRequest]);

  useEffect(
    () => () => {
      cancelTokenSource.cancel();
    },
    [],
  );

  return {
    isLoading: handlingRequest,
    artistsResults,
    groupType,
    searchMode,
    searchQuery,
    changeSearchMode,
    changeGroupType,
    changeSearchQuery,
    onSearchCancel,
  };
}
