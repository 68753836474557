import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import styled from 'styled-components/native';

const Label = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 15px;
  letter-spacing: -0.23px;
  font-family: 'BasierCircle-Medium';
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.errorAccent};
  height: 100%;
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
`;

const ButtonComponent = ({
  children,
  ...rest
}: React.PropsWithChildren<TouchableOpacityProps>): JSX.Element => (
  <Button {...rest}>
    <Label>{children}</Label>
  </Button>
);

export default ButtonComponent;
