export function roundNumberK(n: number): string {
  return n >= 10000
    ? `${Math.round(n / 1000)}k`
    : n >= 1000
    ? `${(n / 1000).toFixed(1)}k`
    : n.toString();
}

export function formatNumberWithCommas(number = 0): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatBigNumber = (num: number | undefined, min = 1000): string => {
  if (!num) return '0';
  if (num < min) {
    return num.toString();
  }
  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
  ];
  let i;
  for (i = si.length - 1; i >= 0; i--) {
    if (num >= si[i].v) {
      return (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].s;
    }
  }
  return '';
};
