import { Ionicons } from '@expo/vector-icons';
import styled from 'styled-components/native';

export default styled(Ionicons).attrs(({ theme }) => ({
  name: 'ios-cut-sharp',
  color: theme.colors.text,
  size: 16,
}))`
  margin-right: 5px;
`;
