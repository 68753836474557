import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const SvgComponent: React.FC = ({
  width = 56,
  height = 56,
  ...rest
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 56 56" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 26.8667C9.75213 26.8667 8.20005 28.4188 8.20005 30.3333V49C8.20005 50.9146 9.75213 52.4667 11.6667 52.4667H44.3334C46.248 52.4667 47.8 50.9146 47.8 49V30.3333C47.8 28.4188 46.248 26.8667 44.3334 26.8667H11.6667ZM5.80005 30.3333C5.80005 27.0933 8.42664 24.4667 11.6667 24.4667H44.3334C47.5735 24.4667 50.2 27.0933 50.2 30.3333V49C50.2 52.2401 47.5735 54.8667 44.3334 54.8667H11.6667C8.42664 54.8667 5.80005 52.2401 5.80005 49V30.3333ZM28 36.2C26.0855 36.2 24.5334 37.7521 24.5334 39.6667C24.5334 41.5813 26.0855 43.1333 28 43.1333C29.9146 43.1333 31.4667 41.5813 31.4667 39.6667C31.4667 37.7521 29.9146 36.2 28 36.2ZM22.1334 39.6667C22.1334 36.4266 24.76 33.8 28 33.8C31.2401 33.8 33.8667 36.4266 33.8667 39.6667C33.8667 42.9067 31.2401 45.5333 28 45.5333C24.76 45.5333 22.1334 42.9067 22.1334 39.6667Z"
        fill="white"
      />
      <Path
        d="M39.6668 16.3333V14C39.6973 10.9369 38.51 7.98708 36.3659 5.79925C34.2218 3.61143 31.2966 2.36474 28.2335 2.33334H28.0002C24.9371 2.30286 21.9872 3.49021 19.7994 5.63427C17.6116 7.77834 16.3649 10.7036 16.3335 13.7667V16.3333"
        stroke="white"
        strokeWidth="2.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
