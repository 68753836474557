import styled from 'styled-components';

export default styled.p`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #ffffff;
  font-family: 'BasierCircle-Regular';

  &::after {
    content: '';
    border: 0.3px solid rgba(255, 255, 255, 0.52);
    margin: 0 7px;
  }
`;
