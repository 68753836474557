import { StatusBar } from 'expo-status-bar';
import debounce from 'lodash.debounce';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ColorSchemeName } from 'react-native';
import { ThemeProvider } from 'styled-components/native';

import { useScreenSize } from 'hooks/responsive';

import Appearance from './appearance';
import darkTheme from './dark';
import lightTheme from './light';
import AppTheme from './theme';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends AppTheme {}
}

const defaultMode: ColorSchemeName = 'dark';

interface ThemeContextProps {
  mode: ColorSchemeName;
  setMode: (mode: ColorSchemeName) => void;
  theme: AppTheme;
}

const ThemeContext = createContext<ThemeContextProps>({
  mode: defaultMode,
  setMode: (mode: ColorSchemeName) => console.log(mode),
  theme: defaultMode === 'dark' ? darkTheme : lightTheme,
});

export const useTheme = (): ThemeContextProps => React.useContext(ThemeContext);

interface ThemeProviderProps {
  modeOverride?: ColorSchemeName;
  children?: React.ReactNode;
}

const ManagedThemeProvider: React.FC<ThemeProviderProps> = ({
  modeOverride,
  children,
}: ThemeProviderProps) => {
  const media = useScreenSize();

  const [colorScheme, setColorScheme] = useState<ColorSchemeName>(
    modeOverride || Appearance.getColorScheme(),
  );

  const setMode = useCallback((mode: ColorSchemeName) => {
    setColorScheme(mode);
  }, []);

  const theme = useMemo(() => {
    return colorScheme === 'dark' ? darkTheme : lightTheme;
  }, [colorScheme]);

  const themeWithBreakpoints = useMemo(() => {
    return { ...theme, media, viewPadding: media.tablet ? 32 : 10 };
  }, [theme, media]);

  const themeState = useMemo(() => {
    return {
      mode: colorScheme,
      setMode,
      theme,
    };
  }, [colorScheme, setMode, theme]);

  useEffect(() => {
    if (modeOverride) {
      setMode(modeOverride);
      return;
    }

    Appearance.addChangeListener(onColorSchemeChange);

    return () => Appearance.removeChangeListener(onColorSchemeChange);
  }, [modeOverride]);

  const onColorSchemeChange = useCallback(
    debounce((preferences: { colorScheme: ColorSchemeName }) => {
      setColorScheme(preferences.colorScheme);
    }, 500),
    [],
  );

  return (
    <ThemeContext.Provider value={themeState}>
      <ThemeProvider theme={themeWithBreakpoints}>
        <>
          <StatusBar style={colorScheme === 'dark' ? 'light' : 'dark'} translucent />
          {children}
        </>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

interface ThemeProviderLightDarkProps {
  modeOverride?: ColorSchemeName;
  children?: React.ReactNode;
}

const ThemeProviderLightDark: React.FC<ThemeProviderLightDarkProps> = ({
  modeOverride,
  children,
}: ThemeProviderLightDarkProps) => (
  <ManagedThemeProvider modeOverride={modeOverride}>{children}</ManagedThemeProvider>
);

export default ThemeProviderLightDark;
