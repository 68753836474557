import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
  letter-spacing: -0.08px;
  width: 90%;
  align-self: center;
  text-align: center;
  line-height: 18px;
`;
