import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const ArrowSvg: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M19 12H5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 19L5 12L12 5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  left: 10px;
  top: 50%;
  z-index: 5;
`;

interface Props {
  onPress: () => void;
}

const BackButton: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <ArrowSvg />
    </TouchableOpacity>
  );
};

export default BackButton;
