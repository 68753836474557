import { memo } from 'react';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { vw } from 'utils/styling';

interface MediaViewProps {
  aspect: 'portrait' | 'landscape' | 'square';
  isExpanded?: boolean;
  roundedCorners?: boolean;
  height: number;
  isSinglePostVideo: boolean;
}

export default memo(styled.View<MediaViewProps>`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: ${({ theme, roundedCorners }) => (roundedCorners ? theme.card.borderRadius : 0)};
  overflow: ${({ roundedCorners }) => (roundedCorners ? 'hidden' : 'visible')};
  margin: 20px 0;
  height: ${(props) =>
    props.height ||
    vw(
      props.aspect === 'portrait'
        ? 100 / props.theme.card.portraitRatio
        : props.aspect === 'landscape'
        ? 100 / props.theme.card.landscapeRatio
        : 100,
    )};

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${({ isSinglePostVideo }) =>
    isSinglePostVideo &&
    css`
      height: auto;
    `}

  ${Platform.OS === 'web' &&
  css`
    height: 70vh;
  `}
`);
