import { Platform } from 'react-native';
import styled from 'styled-components/native';

interface Props {
  showControlsBelowContent?: boolean;
}

export default styled.View<Props>`
  background-color: ${({ theme }) => theme.pollContent.background};
  flex: 1;
  justify-content: ${({ showControlsBelowContent }) =>
    showControlsBelowContent ? 'flex-end' : Platform.OS === 'web' ? 'center' : 'flex-start'};
`;
