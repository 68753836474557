import React from 'react';
import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

const TouchableOpacity = styled.TouchableOpacity.attrs(() => {
  return {
    activeOpacity: 0.7,
  };
})`
  background-color: transparent;
  width: ${Dimensions.get('screen').width * 0.2}px;
  height: ${Dimensions.get('screen').width * 0.2}px;
  border-radius: ${Dimensions.get('screen').width * 0.1}px;
  border-width: 3px;
  border-color: #fff;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.View`
  background-color: #fff;
  width: 90%;
  height: 90%;
  border-radius: 100px;
`;

interface Props {
  onPress?: () => void;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ onPress, disabled }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <Circle />
    </TouchableOpacity>
  );
};

export default Button;
