import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  width: auto;
  height: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 4px;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.52);
  border-radius: 20px;
  margin-right: 8px;
`;
