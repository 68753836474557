import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen/constants';

import NotificationsGroup from 'components/NotificationsGroup';

import { useNotifications } from 'hooks';
import { NotificationsGroupType } from 'types';

import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import CenterView from './styled/CenterView';
import EmptySvg from './styled/EmptySvg';
import EmptyText from './styled/EmptyText';
import EmptyView from './styled/EmptyView';
import Header from './styled/Header';
import ItemSeparator from './styled/ItemSeparator';
import List from './styled/List';
import Title from './styled/Title';

export interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = () => {
  const intl = useIntl();
  const { notifications, isLoading, markNotificationsAsRead, refresh } = useNotifications();
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      refresh();
      if (Platform.OS !== 'web') {
        markNotificationsAsRead();
      }
    }, []),
  );

  const navigateToProduct = useCallback(
    ({ artistUsername, productType, id }) => {
      if (Platform.OS === 'web') {
        markNotificationsAsRead();
        refresh();
      }
      navigation.navigate(SINGLE_POST_SCREEN_NAME, {
        artistUsername: artistUsername,
        productType: productType,
        productId: id,
      });
    },
    [navigation],
  );

  const navigateToMarketplaceProduct = useCallback(
    ({ artistUsername, id }) => {
      navigation.navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
        productId: id,
        artist: artistUsername,
      });
    },
    [navigation],
  );

  const extractKey = (typedItem: unknown) => `${(typedItem as NotificationsGroupType).dateKey}`;

  const renderItem = (args: { item: unknown; index: number }) => (
    <NotificationsGroup
      {...(args.item as NotificationsGroupType)}
      isLast={args.index === notifications.length - 1}
      navigateToProduct={navigateToProduct}
      navigateToMarketplaceProduct={navigateToMarketplaceProduct}
    />
  );

  const renderEmptyTab = () => (
    <EmptyView>
      <EmptySvg />
      <EmptyText>{intl.formatMessage(messages.empty)}</EmptyText>
    </EmptyView>
  );

  if (isLoading && (!notifications || notifications.length === 0)) {
    return (
      <CenterView>
        <ActivityIndicator />
      </CenterView>
    );
  }

  return (
    <>
      {Platform.OS == 'web' && (
        <Header>
          <Title>{intl.formatMessage(messages.title)}</Title>
        </Header>
      )}
      {notifications.length > 0 ? (
        <List
          data={notifications}
          keyExtractor={extractKey}
          renderItem={renderItem}
          onRefresh={refresh}
          refreshing={isLoading}
          ItemSeparatorComponent={() => <ItemSeparator />}
          showsHorizontalScrollIndicator={Platform.OS !== 'web'}
        />
      ) : (
        renderEmptyTab()
      )}
    </>
  );
};

Notifications.displayName = 'Notifications';

export default Notifications;
