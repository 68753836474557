import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import styled, { css } from 'styled-components/native';

export default styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  ${Platform.OS === 'web' &&
  css`
    transition: transform 0.3s ease;
  `}
`;
