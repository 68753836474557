import React from 'react';
import styled from 'styled-components/native';

import { Plus } from 'svg';

const PlusIcon = styled(Plus)``;

const View = styled.View`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.artistAccent};
`;

const PlusInCircle: React.FC = () => {
  return (
    <View>
      <PlusIcon />
    </View>
  );
};

export default PlusInCircle;
