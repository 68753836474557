import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { parseCurrency } from 'utils/currency';

import CoinsSmall from '../images/Coin1.png';
import CoinsMedium from '../images/Coin2.png';
import CoinsLarge from '../images/Coin3.png';
import PriceBoxTouchable from './PriceBoxTouchable';

const coins = [CoinsSmall, CoinsMedium, CoinsLarge];

type Props = {
  index: number;
  price: number;
  boxStyle?: any;
  selected: boolean;
  onPress: any;
};

const Price = styled.Text<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.text : theme.holdingPage.textSecondary};
  font-size: 20px;
  line-height: 25px;
  font-family: 'BasierCircle-Medium';
  letter-spacing: -0.45px;
  text-align: center;
`;

const CoinImage = styled.Image`
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
`;

const PriceBox: React.FC<Props> = ({ price, boxStyle, selected, index, onPress }: Props) => {
  return (
    <View style={{ flex: 1 }}>
      <PriceBoxTouchable selected={selected} style={boxStyle} onPress={() => onPress(price)}>
        <CoinImage source={coins[index]} />
        <Price selected={selected}>
          {parseCurrency()}
          {price.toFixed(0)}
        </Price>
      </PriceBoxTouchable>
    </View>
  );
};

export default PriceBox;
