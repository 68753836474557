import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_PROFILE_STACK_NAME } from 'stacks/artist/ArtistProfileStack/constants';

import { SCREEN_NAME as ARTIST_SINGLE_POST_SCREEN_NAME } from 'screens/ArtistSinglePostScreen/constants';

import { useBackEnd } from 'context/backEnd';
import { usePostsRefreshSet, usePostsRefreshState } from 'context/postsRefresh';
import { ArtistProduct, IapConfig, ProductType } from 'types';

export interface SnackbarState {
  product?: ArtistProduct;
  navigation?: any;
  modelNamePlural?: 'audios' | 'images' | 'videos' | 'polls';
  iap_config?: IapConfig;
  visible: boolean;
  label?: string;
  postType?: ProductType;
  thumbnailUri?: string;
  actionLabel?: string;
  actionOnPress?: () => void;
  dismissAfter?: number;
}

type SnackbarSet = {
  setSnackbar: (snackbar: SnackbarState) => void;
  setSnackbarVisible: (visible: boolean) => void;
};

const DEFAULT_STATE: SnackbarState = {
  visible: false,
};

const SnackbarStateContext = React.createContext<SnackbarState>(DEFAULT_STATE);
const SnackbarSetContext = React.createContext<SnackbarSet>({
  setSnackbar: () => {},
  setSnackbarVisible: () => {},
});
const SnackbarPostStatusContext = React.createContext<string | undefined>(undefined);

export function useSnackbarState(): SnackbarState {
  const context = React.useContext<SnackbarState>(SnackbarStateContext);
  if (context === undefined) {
    throw new Error('useSnackbarState must be used within a SnackbarStateProvider');
  }

  return context;
}

export function useSnackbarSet(): SnackbarSet {
  const context = React.useContext(SnackbarSetContext);
  if (context === undefined) {
    throw new Error('useSnackbarSet must be used within a SnackbarSetProvider');
  }

  return context;
}

export function useSnackbarPostStatus(): string | undefined {
  return React.useContext(SnackbarPostStatusContext);
}

interface Props {
  children: React.ReactNode;
}

export const SnackbarProvider: React.FC<Props> = ({ children }: Props) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>(DEFAULT_STATE);
  const { axiosInstance } = useBackEnd();
  const postsRefreshSet = usePostsRefreshSet();
  const postsRefreshState = usePostsRefreshState();
  const intl = useIntl();
  const [progress, setProgress] = useState();
  const [status, setStatus] = useState<'IN_PROGRESS' | 'COMPLETED' | 'TIMEOUT' | 'FAILED'>();
  const [duration, setDuration] = useState(0);
  const progressCheckTimeout = 500;

  useEffect(() => {
    switch (status) {
      case 'COMPLETED': {
        if (snackbar.iap_config) {
          try {
            axiosInstance.patch(
              `/artists/me/products/${snackbar.modelNamePlural}/${snackbar.product?.id}/iap_config`,
              { id: snackbar.iap_config?.id },
            );
          } catch (e) {
            console.log(JSON.stringify(e.response.data));
          }
        }
        const snckbCompleted = {
          ...snackbar,
          modelNamePlural: undefined,
          product: undefined,
          navigation: undefined,
          label: intl.formatMessage({
            id: 'post.done',
            defaultMessage: 'Done',
          }),
          actionLabel: intl.formatMessage({
            id: 'post.showPost',
            defaultMessage: 'Show post',
          }),
          dismissAfter: 5000,
          actionOnPress: () => {
            if (snackbar.product && snackbar.navigation) {
              snackbar.navigation?.navigate(ARTIST_PROFILE_STACK_NAME, {
                screen: ARTIST_SINGLE_POST_SCREEN_NAME,
                params: {
                  artistUsername: snackbar.product.artist.username,
                  productType: snackbar.postType,
                  productId: snackbar.product.id,
                  product: {
                    ...snackbar.product,
                    model_name: snackbar.postType,
                  },
                },
              });
            }
          },
        };
        if (snackbar.modelNamePlural) {
          const prs = {
            ...postsRefreshState,
            shouldRefresh: [...postsRefreshState.shouldRefresh, snackbar.modelNamePlural],
          };
          postsRefreshSet(prs);
        }
        setSnackbar(snckbCompleted);
        setStatus(undefined);
        break;
      }
      case 'IN_PROGRESS':
        setSnackbar({
          ...snackbar,
          label: intl.formatMessage(
            {
              id: 'post.processing',
              defaultMessage: 'Processing... {progress}%',
            },
            { progress: progress || 0 },
          ),
        });
        break;
      case 'TIMEOUT':
      case 'FAILED': {
        const snckbFailed = {
          ...snackbar,
          modelNamePlural: undefined,
          product: undefined,
          navigation: undefined,
          label: intl.formatMessage({
            id: 'post.failed',
            defaultMessage: 'Failed',
          }),
          actionLabel: intl.formatMessage({
            id: 'snackbar.dismiss',
            defaultMessage: 'Dismiss',
          }),
        };
        setSnackbar(snckbFailed);
        setStatus(undefined);
        setStatus(undefined);
        break;
      }
    }
  }, [status, progress]);

  useEffect(() => {
    let t: number;
    if (snackbar.modelNamePlural && snackbar.product) {
      t = setTimeout(async () => {
        const response = await axiosInstance.get(
          `/artists/me/products/${snackbar.modelNamePlural}/${snackbar.product?.id}/post_processing/progress`,
        );
        const sts = response?.data?.status || 'COMPLETED';
        setProgress(response?.data?.progress);
        setStatus(sts);
        setDuration(duration + progressCheckTimeout);
      }, progressCheckTimeout);
    }

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [duration, snackbar.modelNamePlural, snackbar.product?.id]);

  useEffect(() => {
    let t: number;
    if (snackbar.visible) {
      const dismissTime = snackbar.dismissAfter;
      const setNewSnackbarState = (prevState: any) => ({
        ...prevState,
        visible: false,
        dismissAfter: undefined,
      });
      if (dismissTime) {
        t = setTimeout(() => {
          setSnackbar(setNewSnackbarState);
        }, dismissTime);
      }
    }

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [snackbar.visible, snackbar.dismissAfter]);

  return (
    <SnackbarStateContext.Provider value={snackbar}>
      <SnackbarSetContext.Provider
        value={{
          setSnackbar: (s) => setSnackbar(s),
          setSnackbarVisible: (v: boolean) => setSnackbar({ ...snackbar, visible: v }),
        }}
      >
        <SnackbarPostStatusContext.Provider value={status}>
          {children}
        </SnackbarPostStatusContext.Provider>
      </SnackbarSetContext.Provider>
    </SnackbarStateContext.Provider>
  );
};
