import { AxiosError } from 'axios';
import { useState } from 'react';
import { cache, mutate as baseMutate } from 'swr';

import { useBackEnd } from 'context/backEnd';
import { useAuthSwr } from 'hooks/swr';
import { FollowingStatus } from 'types';

export const ARTISTS_KEY = '/artists';

export function useFollowing(artistUsername: string): {
  followingStatus: FollowingStatus | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error?: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
  follow: () => Promise<void>;
  unfollow: () => Promise<void>;
} {
  const [isMutating, setIsMutating] = useState(false);
  const { axiosInstance } = useBackEnd();
  const {
    isLoading,
    isValidating,
    data: followingStatus,
    error,
    mutate,
  } = useAuthSwr<FollowingStatus>({
    key: `${ARTISTS_KEY}/${artistUsername}/follow`,
  });

  const mutateFeed = async () => {
    const feedKeys = cache.keys().filter((key) => key.startsWith('/feed'));
    return Promise.all(feedKeys.map((key) => baseMutate(key, [], true)));
  };

  const follow = async (): Promise<void> => {
    setIsMutating(true);
    mutate({ is_following: true }, false);
    await axiosInstance.post(`${ARTISTS_KEY}/${artistUsername}/follow`);
    baseMutate(`${ARTISTS_KEY}/${artistUsername}`);
    await mutateFeed();
    await mutate({ is_following: true }, true);
    setIsMutating(false);
  };

  const unfollow = async (): Promise<void> => {
    setIsMutating(true);
    mutate({ is_following: false }, false);
    await axiosInstance.delete(`${ARTISTS_KEY}/${artistUsername}/follow`);
    baseMutate(`${ARTISTS_KEY}/${artistUsername}`);
    await mutateFeed();
    await mutate({ is_following: false }, true);
    setIsMutating(false);
  };

  return {
    followingStatus,
    isLoading: isLoading || isMutating,
    isValidating,
    error,
    mutate,
    follow,
    unfollow,
  };
}
