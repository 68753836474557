import React, { useState } from 'react';

interface TabBarState {
  height: number | null;
}

type TabBarSet = (tabBar: any) => void;

const DEFAULT_STATE: TabBarState = {
  height: null,
};

const TabBarStateContext = React.createContext<TabBarState>(DEFAULT_STATE);
const TabBarSetContext = React.createContext<TabBarSet>(() => {});

export function useTabBarState(): TabBarState {
  const context = React.useContext<TabBarState>(TabBarStateContext);
  if (context === undefined) {
    throw new Error('useTabBarState must be used within a TabBarProvider');
  }

  return context;
}

export function useTabBarSet(): TabBarSet {
  const context = React.useContext(TabBarSetContext);
  if (context === undefined) {
    throw new Error('useTabBarSet must be used within a TabBarProvider');
  }

  return context;
}

interface Props {
  children: React.ReactNode;
}

export const TabBarProvider: React.FC<Props> = ({ children }: Props) => {
  const [tabBar, setTabBar] = useState<TabBarState>(DEFAULT_STATE);

  return (
    <TabBarStateContext.Provider value={tabBar}>
      <TabBarSetContext.Provider value={setTabBar}>{children}</TabBarSetContext.Provider>
    </TabBarStateContext.Provider>
  );
};
