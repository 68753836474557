import React from 'react';
import { Image as RNImage, ImageProps, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import logo from './images/logo.png';

type Props = Omit<ImageProps, 'source'>;

const Image = styled(RNImage)`
  width: 255px;
  height: 54px;

  ${Platform.OS === 'web' &&
  css`
    height: 30px;
    width: 142px;
  `}
`;

const LogoImage: React.FC<Props> = (props: Props) => {
  return <Image {...props} source={logo} />;
};

export default LogoImage;
