import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as SETTINGS_SCREEN_NAME } from 'screens/SettingsScreen';

import NavigationHeaderFan from 'components/NavigationHeaderFan';

import { useFeedState } from 'context/feed';
import { useActiveFollowing, useActiveSubscriptions, useScreenSize } from 'hooks';
import { Artist } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Avatar from './styled/Avatar';
import Button from './styled/Button';
import ColumnsWrapper from './styled/ColumnsWrapper';
import DownloadButtonAppStore from './styled/DownloadButtonAppStore';
import DownloadButtonGooglePlay from './styled/DownloadButtonGooglePlay';
import EmptyFeedView from './styled/EmptyFeedView';
import Feed from './styled/Feed';
import FeedColumn from './styled/FeedColumn';
import FeedWrapper from './styled/FeedWrapper';
import HandsupImage from './styled/HandsupImage';
import SideColumn from './styled/SideColumn';
import StancastBckground from './styled/StancastBackground';
import StancastText from './styled/StancastText';
import StancastTitle from './styled/StancastTitle';
import WebContainer from './styled/WebContainer';
import YourArtistsTitle from './styled/YourArtistsTitle';
import YourArtistsView from './styled/YourArtistsView';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const HomeScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const { activeSubscriptions } = useActiveSubscriptions();
  const { activeFollowing } = useActiveFollowing();
  const [artists, setArtists] = useState<Artist[]>([]);
  const media = useScreenSize();
  const { isEmpty } = useFeedState();

  useEffect(() => {
    let data: Artist[] = [];
    if (activeSubscriptions) {
      data = [...data, ...activeSubscriptions.map((sub) => sub.plan_price.plan.artist)];
    }
    if (activeFollowing) {
      data = [
        ...data,
        ...activeFollowing.filter(
          (artist: Artist) =>
            !activeSubscriptions?.some(
              (sub) => sub.plan_price.plan.artist.username === artist.username,
            ),
        ),
      ];
    }
    setArtists(data);
  }, [activeSubscriptions, activeFollowing]);

  const navigateToSettings = () => {
    navigation.navigate(SETTINGS_SCREEN_NAME);
  };

  const navigateToArtist = (username: string) => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      artistUsername: username,
    });
  };

  return (
    <WebContainer fullWidth>
      <NavigationHeaderFan showBackButton={false} />
      <ColumnsWrapper>
        {media.tablet && (
          <SideColumn mode="left">
            <YourArtistsView>
              <YourArtistsTitle>{intl.formatMessage(messages.yourArtists.title)}</YourArtistsTitle>
              {artists.map((artist) => (
                <Avatar
                  key={`your-artist-${artist.username}`}
                  mode="minimal-row"
                  name={artist.display_name}
                  showTimestamp={false}
                  imageUrl={getProfileLowResPictureUrl(artist)}
                  onPress={() => navigateToArtist(artist.username)}
                />
              ))}
              <Button onPress={navigateToSettings}>
                {intl.formatMessage(messages.yourArtists.seeMore)}
              </Button>
            </YourArtistsView>
          </SideColumn>
        )}
        <FeedColumn>
          <FeedWrapper>{isEmpty ? <EmptyFeedView /> : <Feed />}</FeedWrapper>
        </FeedColumn>
        {media.tabletLandscape && (
          <SideColumn mode="right">
            <StancastBckground>
              <HandsupImage />
              <StancastTitle>{intl.formatMessage(messages.stancast.title)}</StancastTitle>
              <StancastText>{intl.formatMessage(messages.stancast.text)}</StancastText>
              <DownloadButtonAppStore />
              <DownloadButtonGooglePlay />
            </StancastBckground>
          </SideColumn>
        )}
      </ColumnsWrapper>
    </WebContainer>
  );
};

export default HomeScreen;
