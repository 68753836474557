import { useNetInfo } from '@react-native-community/netinfo';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { Audio } from 'expo-av';
import React, { useEffect, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Platform } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { linking as artistLinking } from 'stacks/artist/ArtistRootStack/linking';
import { SCREEN_NAME as LOGIN_STACK_SCREEN_NAME } from 'stacks/common/LoginStack';
import { linking as fanLinking } from 'stacks/fan/FanRootStack/linking';

import MaintenanceModeScreen from 'screens/MaintenanceModeScreen';
import NoNetworkScreen from 'screens/NoNetworkScreen';
import { SCREEN_NAME as RESET_PASSWORD_SCREEN_NAME } from 'screens/ResetPasswordScreen';
import { SCREEN_NAME as SEARCH_AND_DISCOVERY_SCREEN_NAME } from 'screens/SearchAndDiscoveryScreen/constants';
import { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import Snackbar from 'components/Snackbar';

import {
  AgeRestrictionContextProvider,
  CardinalProvider,
  FeedProvider,
  ImageCacheProvider,
  ModalsProvider,
  MoreOptionsProvider,
  OnBoardingArtistContextProvider,
  OnBoardingContextProvider,
  PaymentsProvider,
  PostsRefreshProvider,
  PushNotificationsProvider,
  SnackbarProvider,
  SubscriptionModalWebProvider,
  TabBarProvider,
  TippingProvider,
  UnsubscribeProvider,
  useBackEnd,
  VARIANT,
  WebviewProvider,
} from 'context';
import { CvvModalProvider } from 'context/cvvModal';
import { NotificationsProvider } from 'context/notifications';
import { useConfig, useLocalStorage, useTranslations } from 'hooks';
import ThemeProviderLightDark from 'themes';
import ThemeProviderLightDarkWeb from 'themes/web';
import { NavigationState } from 'types';
import { sendAnalyticsEventAsync } from 'utils/analytics';
import { getActiveRouteName } from 'utils/navigation';

import { NAVIGATION_STATE_PERSISTENCE_KEY } from './constants';

import 'intl';
import 'intl/locale-data/jsonp/en';

const FanStack = React.lazy(() => import('stacks/fan/FanRootStack'));
const ArtistStack = React.lazy(() => import('stacks/artist/ArtistRootStack'));

const linking = VARIANT === 'fan' ? fanLinking : artistLinking;

const App: React.FC = () => {
  const { isConnected } = useNetInfo();
  const { loginRequest, resetPasswordRequired } = useBackEnd();
  const [refReady, setRefReady] = useState(false);
  const { translations } = useTranslations('us', 'en');
  const { config } = useConfig();
  const routeNameRef = useRef<string>();
  const navigationContainerRef = useNavigationContainerRef();
  const { setValueWrapper: setNavigationState } = useLocalStorage(NAVIGATION_STATE_PERSISTENCE_KEY);

  useEffect(() => {
    if (!refReady) return;
    const subscription = loginRequest.subscribe({
      next: () => {
        navigationContainerRef?.current?.navigate(LOGIN_STACK_SCREEN_NAME, {
          screen: resetPasswordRequired ? RESET_PASSWORD_SCREEN_NAME : WELCOME_SCREEN_NAME,
        });
      },
    });

    return () => refReady && subscription.unsubscribe();
  }, [loginRequest, refReady, resetPasswordRequired, navigationContainerRef]);

  // Global maintenance mode switched controlled by the CMS
  const isMaintenanceMode =
    (config.fanAppMaintenanceMode && VARIANT === 'fan') ||
    (config.artistAppMaintenanceMode && VARIANT === 'artist');

  useEffect(() => {
    // Play audio on iOS even if the hardware mute switch is on
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
  }, []);

  const onNavigationStateChange = (newState: NavigationState) => {
    // Track screen navigation on Firebase Analytics
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationContainerRef.getCurrentRoute()?.name;

    if (previousRouteName !== currentRouteName) {
      routeNameRef.current = currentRouteName;

      console.log('[Analytics] tracking screen', currentRouteName);
      sendAnalyticsEventAsync('screen_change', { routeName: currentRouteName });
    }
    if (
      Platform.OS === 'web' &&
      newState?.routes &&
      newState.routes.length >= 1 &&
      newState.routes[newState.routes.length - 1].name !== SEARCH_AND_DISCOVERY_SCREEN_NAME
    ) {
      setNavigationState(newState);
    }
  };

  return (
    <IntlProvider messages={translations} defaultLocale="en-us" locale="en-us">
      <ThemeProviderLightDarkWeb modeOverride={'dark'}>
        <ThemeProviderLightDark modeOverride={'dark'}>
          <PaperProvider>
            {isConnected === false ? (
              <NoNetworkScreen />
            ) : isMaintenanceMode ? (
              <MaintenanceModeScreen />
            ) : (
              <FeedProvider>
                <PushNotificationsProvider
                  navigationRef={navigationContainerRef}
                  isReady={refReady}
                >
                  <NotificationsProvider>
                    <CvvModalProvider>
                      <ModalsProvider>
                        <WebviewProvider>
                          <CardinalProvider>
                            <PaymentsProvider navigationRef={navigationContainerRef}>
                              <TippingProvider navigationRef={navigationContainerRef}>
                                <UnsubscribeProvider>
                                  <TabBarProvider>
                                    <PostsRefreshProvider>
                                      <SnackbarProvider>
                                        <Snackbar />
                                        <MoreOptionsProvider>
                                          <SafeAreaProvider>
                                            <ImageCacheProvider>
                                              <AgeRestrictionContextProvider>
                                                <OnBoardingContextProvider>
                                                  <SubscriptionModalWebProvider
                                                    navigationRef={navigationContainerRef}
                                                  >
                                                    <OnBoardingArtistContextProvider>
                                                      <NavigationContainer
                                                        onReady={() => {
                                                          setRefReady(true);
                                                          const name =
                                                            navigationContainerRef.getCurrentRoute()
                                                              ?.name;
                                                          if (name) routeNameRef.current = name;
                                                        }}
                                                        ref={navigationContainerRef}
                                                        linking={linking}
                                                        onStateChange={onNavigationStateChange}
                                                        documentTitle={{
                                                          formatter: (options) =>
                                                            `${
                                                              Platform.OS === 'web'
                                                                ? options?.title
                                                                  ? `Stanbase - ${options?.title}`
                                                                  : 'Stanbase'
                                                                : options?.title && options?.title
                                                            }`,
                                                        }}
                                                      >
                                                        <React.Suspense>
                                                          {VARIANT === 'fan' ? (
                                                            <FanStack
                                                              isReady={refReady}
                                                              navigation={navigationContainerRef}
                                                            />
                                                          ) : (
                                                            <ArtistStack
                                                              isReady={refReady}
                                                              navigation={navigationContainerRef}
                                                            />
                                                          )}
                                                        </React.Suspense>
                                                      </NavigationContainer>
                                                    </OnBoardingArtistContextProvider>
                                                  </SubscriptionModalWebProvider>
                                                </OnBoardingContextProvider>
                                              </AgeRestrictionContextProvider>
                                            </ImageCacheProvider>
                                          </SafeAreaProvider>
                                        </MoreOptionsProvider>
                                      </SnackbarProvider>
                                    </PostsRefreshProvider>
                                  </TabBarProvider>
                                </UnsubscribeProvider>
                              </TippingProvider>
                            </PaymentsProvider>
                          </CardinalProvider>
                        </WebviewProvider>
                      </ModalsProvider>
                    </CvvModalProvider>
                  </NotificationsProvider>
                </PushNotificationsProvider>
              </FeedProvider>
            )}
          </PaperProvider>
        </ThemeProviderLightDark>
      </ThemeProviderLightDarkWeb>
    </IntlProvider>
  );
};

export default App;
