import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { MarketplaceItemQuestion, MarketplaceQuestionareAnswer } from 'types';

import messages from './messages';
import ActionContainer from './styled/ActionContainer';
import Container from './styled/Container';
import ErrorsView from './styled/ErrorsView';
import ErrorText from './styled/ErrorText';
import FormContainer from './styled/FormContainer';
import SubmitButton from './styled/SubmitButton';
import TextInput from './styled/TextInput';

export interface MarketplaceOrderFormProps {
  questionares: MarketplaceItemQuestion[];
  isUpdating?: boolean;
  updateError?: string;
  onSubmit: () => void;
  isValid: boolean;
  control: any;
  isSubmitting: boolean;
  handleSubmit: any;
  clearErrors: any;
  restoredAnswers?: MarketplaceQuestionareAnswer[];
  saveDraft: () => void;
  buttonLabel: string;
}

const MarketplaceOrderForm: React.FC<MarketplaceOrderFormProps> = ({
  questionares,
  updateError,
  isUpdating,
  isValid,
  control,
  isSubmitting,
  clearErrors,
  onSubmit,
  restoredAnswers,
  saveDraft,
  buttonLabel,
}: MarketplaceOrderFormProps): JSX.Element => {
  const intl = useIntl();
  const formFieldNames = questionares.map((_: any, index: number) => `question-${index}`);
  const [allowSave, setAllowSave] = useState<boolean>(questionares.length === 0);
  const timeoutId = useRef(0);

  return (
    <Container>
      <FormContainer>
        {questionares.map(({ question }: MarketplaceItemQuestion, index: number) => {
          const questionKey = `question-${index}`;

          return (
            <Controller
              key={questionKey}
              control={control}
              name={questionKey}
              rules={{ required: intl.formatMessage(messages.errors.required) }}
              defaultValue={restoredAnswers ? restoredAnswers[index]?.text || '' : ''}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  disabled={isUpdating}
                  label={question}
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: any) => {
                    clearTimeout(timeoutId.current);
                    formFieldNames.forEach((fieldName: string) => {
                      clearErrors(fieldName);
                    });
                    setAllowSave(true);
                    onChange(value);
                    timeoutId.current = setTimeout(saveDraft, 1000);
                  }}
                />
              )}
            />
          );
        })}

        {!!updateError && (
          <ErrorsView>
            <ErrorText>{updateError}</ErrorText>
          </ErrorsView>
        )}
      </FormContainer>

      <ActionContainer>
        <SubmitButton
          disabled={!isValid || !allowSave || isUpdating}
          processing={isSubmitting || isUpdating}
          onPress={onSubmit}
        >
          {buttonLabel}
        </SubmitButton>
      </ActionContainer>
    </Container>
  );
};

export default MarketplaceOrderForm;
