import { useCallback, useState } from 'react';

import regex from 'utils/regex';

const usePasswordChecks = (errorMessages: {
  missingUpperOrLowerCase: string;
  missingNumber: string;
  missingSpecialCharacter: string;
}): {
  passwordChecks: Record<string, { pass: boolean; message?: string }>;
  validatePassword: (value: string) => 'combinedError' | true;
} => {
  const [passwordChecks, setPasswordChecks] = useState<
    Record<string, { pass: boolean; message?: string }>
  >({});

  const validatePassword = useCallback(
    (value: string) => {
      const checkResult = {
        length: {
          pass: value.length >= 8,
        },
        upperLower: {
          pass: regex.lowerAndUpper.test(value),
          message: errorMessages.missingUpperOrLowerCase,
        },
        numeric: {
          pass: regex.number.test(value),
          message: errorMessages.missingNumber,
        },
        special: {
          pass: regex.specialCharacter.test(value),
          message: errorMessages.missingSpecialCharacter,
        },
      };

      setPasswordChecks(checkResult);

      const hasError = Object.keys(checkResult).reduce(
        (prev, current) => prev || !((checkResult as any)[current].pass as boolean),
        false,
      );

      return value.length <= 0 ? true : hasError ? 'combinedError' : true;
    },
    [setPasswordChecks],
  );
  return { passwordChecks, validatePassword };
};

export default usePasswordChecks;
