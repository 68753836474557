import styled, { css } from 'styled-components/native';

import Footer from 'components/WebFooter';

export default styled(Footer)<{ secondary?: boolean }>`
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 16px;

  ${({ secondary }) =>
    secondary &&
    css`
      flex-grow: 0;
      flex-basis: auto;
      flex-direction: row;
      height: auto;
      padding-top: 32px;
    `}
`;
