import styled from 'styled-components/native';

import VideoControls from 'components/VideoControls';

export default styled(VideoControls)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
`;
