import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  ${Platform.OS === 'web' &&
  css`
    background-color: #131722;
  `}
`;
