import { OrderStatus, OrderStatusTranslation } from 'types';
import { parseCurrency } from 'utils/currency';

export const translateMarketplaceOrderStatus = (status: OrderStatus): OrderStatusTranslation => {
  switch (status) {
    case OrderStatus.WAITING_FOR_PAYMENT:
      return 'waiting';
    case OrderStatus.PURCHASED:
      return 'purchased';
    case OrderStatus.DECLINED:
      return 'declined';
    case OrderStatus.IN_PROGRESS:
      return 'inprogress';
    case OrderStatus.COMPLETED:
      return 'completed';
    case OrderStatus.CANCEL:
      return 'canceled';
  }
};

export const parseItemPrice = (price?: string, currency?: string): string => {
  if (!price) return '';
  else
    return currency
      ? `${parseCurrency(currency)}${Number(price).toLocaleString('en', {
          minimumFractionDigits: 2,
        })}`
      : Number(price).toLocaleString('en', {
          minimumFractionDigits: 2,
        });
};
