import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
