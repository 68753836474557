import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREEN_NAME } from 'screens/EditPaymentMethodScreen';

import CreditCardWeb from 'components/CreditCardWeb';

import { useSnackbarSet } from 'context';
import { usePayments } from 'context';
import { BillingAddress, CreditCardInfo } from 'types';

import View from './styled/View';

interface Props {
  tabWidth?: number;
}

interface Method {
  billingAddress: BillingAddress;
  creditCardInfo: CreditCardInfo;
}

const PaymentSettings: React.FC<Props> = ({ tabWidth }: Props) => {
  const { paymentMethods, removePaymentMethod } = usePayments();
  const { navigate } = useNavigation();
  const { setSnackbar } = useSnackbarSet();

  const removeMethod = async (method: Method) => {
    try {
      await removePaymentMethod(method);
      setSnackbar({
        visible: true,
        label: 'Credit card deleted.',
        actionLabel: 'Credit card was deleted.',
        dismissAfter: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onAddNewCard = () => {
    navigate(ADD_PAYMENT_METHOD_SCREEN_NAME);
  };

  const renderPaymentCards = () => {
    return paymentMethods.map((card: Method) => {
      return (
        <CreditCardWeb
          onRemoveCard={() => removeMethod(card)}
          key={card.creditCardInfo.last_four_digits}
          cvvKey="***"
          fullName={card.creditCardInfo.cardholder_name}
          cardNumber={`**** **** **** ${card.creditCardInfo.last_four_digits}`}
          expiryDate={`${card.creditCardInfo.expiry_month}/${card.creditCardInfo.expiry_year}`}
        />
      );
    });
  };

  return (
    <View tabWidth={tabWidth}>
      {renderPaymentCards()}
      <CreditCardWeb
        isAddingNewCard={true}
        onAddNewCard={onAddNewCard}
        creditCardsLength={paymentMethods.length}
      />
    </View>
  );
};

export default PaymentSettings;
