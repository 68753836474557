import { makeRedirectUri } from 'expo-auth-session';
import { Platform } from 'react-native';

import { VARIANT } from 'context/appInfo';

const GOOGLE_CLIENT_WEB =
  '358906291645-sgq53tnjvsr1mmjousrk8j0okho26fou.apps.googleusercontent.com';

const GOOGLE_CLIENTS_NATIVE = {
  ios: {
    local: {
      fan: '358906291645-jcgcuvk8ltirlt10mtudfv403unt3bbi.apps.googleusercontent.com',
      artist: '358906291645-npl5ai9hpt8qalj4fernnrrvd7clokdp.apps.googleusercontent.com',
    },
    development: {
      fan: '358906291645-a1gvcpl6tduegpjg0d52d5bfpf6s8ukh.apps.googleusercontent.com',
      artist: '358906291645-9k6d5hgkfqlaro5hh3g799famljf8pab.apps.googleusercontent.com',
    },
    test: {
      fan: '358906291645-k9h1ionggdk6lmc9aqppr1a70ukpr6hb.apps.googleusercontent.com',
      artist: '358906291645-lfluv2c5muo376i6vmduba8g9t1qvc13.apps.googleusercontent.com',
    },
    staging: {
      fan: '358906291645-2ae6j8s5na8fbhjv98n9abe440atmh3q.apps.googleusercontent.com',
      artist: '358906291645-m1912jvgjg1v9as1puf3b3opmse0peva.apps.googleusercontent.com',
    },
    production: {
      fan: '358906291645-qdnfhtu05qnkrmndhptl1h1ubr70s6dl.apps.googleusercontent.com',
      artist: '358906291645-ia6kdt67n9dbdo78td2ar57gp065h3g6.apps.googleusercontent.com',
    },
  },
  android: {
    local: '358906291645-hes8db1oja68mv75o2sg5ktv052gqu31.apps.googleusercontent.com',
    development: '358906291645-703e50qngacg2sbm1mgmrtr2k94mrbfl.apps.googleusercontent.com',
    test: '358906291645-ka3vs0ehb0vui102m58q7uj8nrjee3ng.apps.googleusercontent.com',
    staging: '358906291645-hdc9oaackhbmqhjhc5fgl3a9h2275j4t.apps.googleusercontent.com',
    production: '358906291645-g8a0k21c7sf335nj2anlir4g2nnr5aeu.apps.googleusercontent.com',
  },
};

type ENV_TYPE = 'local' | 'development' | 'test' | 'staging' | 'production';

const ENV: ENV_TYPE = (process.env.ENV as ENV_TYPE) || 'local';

export const iosClientId = GOOGLE_CLIENTS_NATIVE.ios[ENV][VARIANT];
export const androidClientId = GOOGLE_CLIENTS_NATIVE.android[ENV];
export const googleWebClientId = GOOGLE_CLIENT_WEB;

export const IOS_SCHEME = {
  local: {
    fan: 'com.googleusercontent.apps.358906291645-jcgcuvk8ltirlt10mtudfv403unt3bbi',
    artist: 'com.googleusercontent.apps.358906291645-npl5ai9hpt8qalj4fernnrrvd7clokdp',
  },
  development: {
    fan: 'com.googleusercontent.apps.358906291645-a1gvcpl6tduegpjg0d52d5bfpf6s8ukh',
    artist: 'com.googleusercontent.apps.358906291645-9k6d5hgkfqlaro5hh3g799famljf8pab',
  },
  test: {
    fan: 'com.googleusercontent.apps.358906291645-k9h1ionggdk6lmc9aqppr1a70ukpr6hb',
    artist: 'com.googleusercontent.apps.358906291645-lfluv2c5muo376i6vmduba8g9t1qvc13',
  },
  staging: {
    fan: 'com.googleusercontent.apps.358906291645-2ae6j8s5na8fbhjv98n9abe440atmh3q',
    artist: 'com.googleusercontent.apps.358906291645-m1912jvgjg1v9as1puf3b3opmse0peva',
  },
  production: {
    fan: 'com.googleusercontent.apps.358906291645-qdnfhtu05qnkrmndhptl1h1ubr70s6dl',
    artist: 'com.googleusercontent.apps.358906291645-ia6kdt67n9dbdo78td2ar57gp065h3g6',
  },
};

export const getRedirectUri = () => {
  if (Platform.OS === 'android')
    // return makeRedirectUri({
    //   scheme: ENV === 'local' ? 'com.stanbase' : `com.stanbase.${VARIANT}.${ENV}`,
    // });
    return ENV === 'local'
      ? 'com.stanbase:///oauthredirect'
      : `com.stanbase.${VARIANT}.${ENV}:///oauthredirect`;
  if (Platform.OS === 'ios') return makeRedirectUri({ scheme: IOS_SCHEME[ENV][VARIANT] });
  else if (ENV === 'local') return 'http://localhost:19006';
  else if (ENV === 'production') return 'https://stanbase.com';
  else return `https://${ENV}-dot-stanbase-c16b.uc.r.appspot.com`;
};

export const redirectUri = getRedirectUri();
