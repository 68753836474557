import { FC, useMemo } from 'react';
import { Text, TextProps } from 'react-native';

import { BreakPoints, breakpoints } from 'utils/responsive';
import { screenWidth } from 'utils/screen';

type breakpointKey = keyof BreakPoints;

interface TextStyle {
  fontFamily?: string;
  fontSize?: number;
  color?: string;
}

export type ResponsiveTextProps = {
  [k in breakpointKey]?: TextStyle;
};

const ResponsiveText: FC<TextProps & ResponsiveTextProps> = ({
  style,
  mobile,
  tablet,
  tabletLandscape,
  desktop,
  bigDesktop,
  ...rest
}) => {
  const textStyle: TextStyle = useMemo(() => {
    let s = { ...mobile };
    if (screenWidth >= breakpoints.tablet) s = { ...s, ...tablet };
    if (screenWidth >= breakpoints.tabletLandscape) s = { ...s, ...tabletLandscape };
    if (screenWidth >= breakpoints.desktop) s = { ...s, ...desktop };
    if (screenWidth >= breakpoints.bigDesktop) s = { ...s, ...bigDesktop };
    return s;
  }, [bigDesktop, desktop, mobile, tablet, tabletLandscape]);

  return <Text style={[style, textStyle]} {...rest} />;
};

export default ResponsiveText;
