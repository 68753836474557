import styled from 'styled-components/native';

import PlaceholderText from 'components/PlaceholderText';

const PlaceholderName = styled(PlaceholderText)``;

PlaceholderName.defaultProps = {
  minNumWords: 2,
  maxNumWords: 2,
  singleLine: true,
};

export default PlaceholderName;
