import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { PermissionsAndroid, Platform, TouchableOpacity } from 'react-native';

import { SCREEN_NAME as NEW_POST_STACK_SCREEN_NAME } from 'stacks/artist/ArtistNewPostStack/constants';

import { SCREEN_NAME as ARTIST_ADD_PRODUCT_SCREEN } from 'screens/ArtistAddProductScreen';
import { SCREEN_NAME as ARTIST_AUDIO_RECORD_SCREEN_NAME } from 'screens/ArtistAudioRecordScreen/constants';
import { SCREEN_NAME as ARTIST_LIVE_SCREEN_NAME } from 'screens/ArtistLiveScreen/constants';
import { SCREEN_NAME as ARTIST_CREATE_POLL_SCREEN_NAME } from 'screens/ArtistPollCreateScreen/constants';
import { SCREEN_NAME as ARTIST_POST_SCREEN_NAME } from 'screens/ArtistPostScreen/constants';

import { useTheme } from 'themes';

import Modal, { OptionType } from './styled/Modal';
import Plus from './styled/Plus';

interface ModalSelectorOptionProps {
  key: string;
  label: string;
}

interface Props {}

const More: React.FC<Props> = () => {
  const intl = useIntl();
  const navigation = useNavigation();
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);

  const requestCameraPermission = async () => {
    try {
      const granted = await PermissionsAndroid.requestMultiple([
        PermissionsAndroid.PERMISSIONS.CAMERA,
        PermissionsAndroid.PERMISSIONS.RECORD_AUDIO,
      ]);
      return (
        granted['android.permission.CAMERA'] === PermissionsAndroid.RESULTS.GRANTED &&
        granted['android.permission.RECORD_AUDIO'] === PermissionsAndroid.RESULTS.GRANTED
      );
    } catch (err) {
      return false;
    }
  };

  const onOptionPress = async ({ key }: ModalSelectorOptionProps) => {
    switch (key) {
      case 'poll':
        navigation.navigate(NEW_POST_STACK_SCREEN_NAME, {
          screen: ARTIST_CREATE_POLL_SCREEN_NAME,
        });
        break;
      case 'post':
        navigation.navigate(NEW_POST_STACK_SCREEN_NAME, {
          screen: ARTIST_POST_SCREEN_NAME,
        });
        break;
      case 'add-new-product':
        navigation.navigate(ARTIST_ADD_PRODUCT_SCREEN, {
          screen: ARTIST_ADD_PRODUCT_SCREEN,
        });
        break;
      case 'audio-note':
        if (Platform.OS === 'web') {
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const { isSafari, isIE } = require('react-device-detect');
          if (isSafari || isIE) {
            alert('Audio recording is not supported in your browser.');
            return;
          }
        }
        navigation.navigate(NEW_POST_STACK_SCREEN_NAME, {
          screen: ARTIST_AUDIO_RECORD_SCREEN_NAME,
        });
        break;
      case 'live':
        if (
          (Platform.OS === 'android' && (await requestCameraPermission())) ||
          Platform.OS === 'ios'
        ) {
          navigation.navigate(NEW_POST_STACK_SCREEN_NAME, {
            screen: ARTIST_LIVE_SCREEN_NAME,
          });
        }
        break;
    }
  };

  const getOptions: () => Array<Array<OptionType>> = () => {
    const common = [
      {
        key: 'post',
        label: intl.formatMessage({
          id: 'post.post',
          defaultMessage: 'Post',
        }),
      },
      {
        key: 'poll',
        label: intl.formatMessage({
          id: 'post.poll.title',
          defaultMessage: 'Poll',
        }),
      },
      {
        key: 'audio-note',
        label: intl.formatMessage({
          id: 'post.audioNote.title',
          defaultMessage: 'Audio Note',
        }),
      },
      {
        key: 'add-new-product',
        label: intl.formatMessage({
          id: 'marketplace.add.product',
          defaultMessage: 'Add Store Product',
        }),
      },
    ];
    return Platform.OS === 'web'
      ? [common]
      : [
          common,
          [
            {
              key: 'live',
              label: intl.formatMessage({
                id: 'post.liveMoment',
                defaultMessage: 'Live Stream Now',
              }),
              background: theme.theme.colors.backgroundBlue,
            },
          ],
        ];
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          setModalVisible(true);
        }}
      >
        <Plus />
      </TouchableOpacity>
      <Modal
        options={getOptions()}
        onOptionPress={onOptionPress}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default More;
