import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';
import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';

import VideoStyled from 'components/FeedItemVideoContent/styled/Video';
import KeyboardAvoidingView from 'components/KeyboardAvoidingView';
import WebContainer from 'components/WebContainer';

import { useBackEnd, useFeedItemUpdate, useMoreOptionsSet, usePayments } from 'context';
import { useArtistProduct, useConfig, useInsights, useUser } from 'hooks';
import { Comments } from 'types';
import { shareProduct } from 'utils/sharing';
import { getProfileLowResPictureUrl, isAgeRestrictedUser } from 'utils/user';

import { SCREEN_NAME } from './constants';
import logo from './images/logo.png';
import messages from './messages';
import Avatar from './styled/Avatar';
import AvatarRow from './styled/AvatarRow';
import CommentsCount from './styled/CommentsCount';
import CommentsCountNumber from './styled/CommentsCountNumber';
import CommentsView from './styled/CommentsView';
import ContentView from './styled/ContentView';
import ContentWithComments from './styled/ContentWithComments';
import Divider from './styled/Divider';
import FeedItem from './styled/FeedItem';
import FeedItemControls from './styled/FeedItemControls';
import FeedItemPlaceholder from './styled/FeedItemPlaceholder';
import FeedItemView from './styled/FeedItemView';
import Logo from './styled/Logo';
import LogoButton from './styled/LogoButton';
import More from './styled/More';
import OverlayButton from './styled/OverlayButton';
import SafeAreaView from './styled/SafeAreaView';
import View from './styled/View';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const SinglePostScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { config } = useConfig();
  const isFocused = useIsFocused();
  const deactivateRef = useRef<(() => void) | null>();
  const contentWithCommentsRef = useRef<typeof ContentWithComments>(null);
  const { artistUsername, productType, productId } = route.params;
  const { product, mutate } = useArtistProduct({
    artistUsername,
    productType,
    productId,
  });
  const [overlayButtonMarginTop, setOverlayButtonMarginTop] = useState<number>(15);
  const { profile } = useUser();
  const showAgeRestrictionDialog = useMemo(
    () => isAgeRestrictedUser(profile) && !profile?.state_age_restriction_single_post_is_seen,
    [profile],
  );
  const { requestPayment } = usePayments();
  const { updateFeedItem } = useFeedItemUpdate();
  const setMoreOptions = useMoreOptionsSet();
  const { createShare, createView } = useInsights(
    product?.id || -1,
    product?.model_name || 'image',
    product?.artist.username || '',
  );
  const { isAuthenticated, verified } = useBackEnd();

  useEffect(() => {
    if (!isAuthenticated && verified)
      navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, { artistUsername });
  }, [isAuthenticated, verified]);

  const formatCommentsCount = (num?: number) => {
    return (num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')) || 0;
  };

  useEffect(() => {
    createView();
  }, [createView]);

  useEffect(() => {
    if (!isFocused) {
      deactivateRef.current?.();
    }
  }, [isFocused]);

  const onCloseButtonPress = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(HOME_SCREEN_NAME);
  }, [navigation]);

  const navigateToArtist = useCallback(() => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      artistUsername,
      artist: product?.artist,
    });
  }, [navigation, product]);

  const onUnlockPress = useCallback(async () => {
    const item = product;

    if (!item) {
      return;
    }

    await requestPayment(
      { artistUsername: item.artist.username, type: 'subscription' },
      intl.formatMessage(messages.unlockPaymentRequestTitle),
      intl.formatMessage(messages.unlockPaymentRequestDescription, {
        username: item.artist.display_name,
      }),
    );
  }, [product]);

  const onSharePress = useCallback(async () => {
    if (!product) {
      return;
    }
    const { action } = await shareProduct(product);
    if (action === 'sharedAction') {
      createShare('web');
    }
  }, [product, createShare]);

  const onCommentsChange = (comments: Comments) => {
    mutate({ ...product, comments_count: comments.length });
  };

  useEffect(() => {
    if (product) {
      updateFeedItem(product, { comments_count: product.comments_count });
    }
  }, [product?.comments_count]);

  const refDeactivate = (deactivateFn: (() => void) | null) => {
    deactivateRef.current = deactivateFn;
  };

  const focusOnCommentAdd = useCallback(() => {
    if (contentWithCommentsRef && contentWithCommentsRef.current) {
      (contentWithCommentsRef.current as any)?.focusOnCommentInput();
    }
  }, []);

  const onMorePress = () => {
    if (product) {
      setMoreOptions({
        itemType: 'post',
        itemId: product.id,
        productType: product.model_name,
        artistUsername: product.artist.username,
        isMoreOptionsOpen: true,
        onClose: undefined,
      });
    }
  };

  const renderCommentsHeader = () => {
    return (
      <ContentView isExpanded={true}>
        <AvatarRow>
          <Avatar
            mode="expanded"
            isFeedItemExpanded={true}
            isPlaceholder={false}
            name={product?.artist?.display_name}
            imageUrl={getProfileLowResPictureUrl(product?.artist)}
            isLive={product?.artist?.is_live}
            timestamp={product?.time_created}
            onPress={navigateToArtist}
          />
          <More onPress={onMorePress} isExpanded={true} />
        </AvatarRow>
        {product && (
          <FeedItemControls
            commentingEnabled={false}
            sharingEnabled={false}
            tippingEnabled
            bookmarkingEnabled={product?.has_access}
            isBookmarked={product?.is_bookmarked}
            reactionsEnabled={product?.has_access}
            isReacted={product?.is_liked}
            reactionsCount={product?.likes_count}
            isTipped={product?.is_tipped}
            product={product}
            commentsCount={product?.comments_count}
          />
        )}
        <Divider />
        <CommentsCount>
          Comments
          <CommentsCountNumber>{` (${formatCommentsCount(
            product?.comments_count || 0,
          )})`}</CommentsCountNumber>
        </CommentsCount>
      </ContentView>
    );
  };

  const renderContent = useCallback(() => {
    if (!product) {
      return <FeedItemPlaceholder />;
    }

    return (
      <FeedItem
        product={product}
        isExpanded
        showEdit={false}
        videoOptions={{
          autoPlay: true,
          startMuted: false,
          showVideoTimestamp: true,
          showProgressBar: true,
          dataIndex: product.id,
          resizeMode: VideoStyled.RESIZE_MODE_CONTAIN,
          refDeactivate: (index: number, deactivateFn: (() => void) | null) => {
            refDeactivate(deactivateFn);
          },
        }}
        audioOptions={{
          dataIndex: product.id,
          refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
            refDeactivate(deactivateFn),
        }}
        pollOptions={{
          answerable: true,
        }}
        commentingEnabled={true}
        reactionsEnabled={false}
        tippingEnabled
        bookmarkingEnabled
        sharingEnabled
        showCaption
        showCommentsBottomCounter={
          config.singlePostCommentsEnabled && product.has_comment_access_code !== 'disabled'
        }
        showControls={true}
        showControlsBelowContent
        roundedCorners={false}
        focusOnCommentAdd={focusOnCommentAdd}
        overlayButtonMarginTop={overlayButtonMarginTop}
        onPaymentDone={() => mutate()}
      />
    );
  }, [product, navigateToArtist, onUnlockPress, onSharePress, overlayButtonMarginTop, mutate]);

  const onLogoPress = () => navigation.navigate(HOME_SCREEN_NAME);

  return (
    <KeyboardAvoidingView>
      <WebContainer fullWidth padding={0}>
        <View>
          <LogoButton onPress={onLogoPress}>
            <Logo source={logo} />
          </LogoButton>

          <FeedItemView>{renderContent()}</FeedItemView>
          <CommentsView>
            <ContentWithComments
              commentAccess={product?.has_comment_access_code}
              artistUsername={artistUsername}
              artistDisplayName={product?.artist?.display_name}
              productId={productId}
              productModelName={productType}
              profilePicture={getProfileLowResPictureUrl(profile)}
              displayName={profile?.full_name}
              profile={profile}
              emptyListMessage={
                !product?.comments_count ? intl.formatMessage(messages.emptyMessage) : ''
              }
              ContentComponent={renderCommentsHeader()}
              onCommentsChange={onCommentsChange}
              ref={contentWithCommentsRef}
              showAgeRestrictions={showAgeRestrictionDialog}
            />
          </CommentsView>

          <SafeAreaView pointerEvents="box-none">
            <OverlayButton
              icon="close"
              onPress={onCloseButtonPress}
              onLayout={(event: { nativeEvent: { layout: { y: number } } }) => {
                if (event.nativeEvent.layout.y > overlayButtonMarginTop) {
                  setOverlayButtonMarginTop(event.nativeEvent.layout.y);
                }
              }}
            />
          </SafeAreaView>
        </View>
      </WebContainer>
    </KeyboardAvoidingView>
  );
};

export default SinglePostScreen;
