import styled, { css } from 'styled-components/native';

import NotificationsButton from 'components/FeedTopNav/styled/NotificationsButton';

export default styled(NotificationsButton)`
  margin-right: 26px;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      margin-right: 34px;
    `}
`;
