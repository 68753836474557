import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({
  short: true,
  primary: true,
  labelStyle: {
    fontSize: 11,
    lineHeight: 13,
    fontFamily: 'BasierCircle-Medium',
  },
})`
  height: 27px;
  padding: 0 15px;
  border-radius: 27px;
`;
