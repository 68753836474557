import styled from 'styled-components/native';

interface Props {
  isActive: boolean;
}

export default styled.View<Props>`
  background-color: ${({ theme }) => theme.colors.primaryActive};
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 6px 0 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`;
