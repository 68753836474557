import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import CommentAdd from 'components/CommentAdd';

import { usePayments } from 'context';
import { Comment, CommentAddFunc, CommentDeleteFunc, UserProfile } from 'types';
import { sortByDate } from 'utils/sort';

import messages from './messages';
import BecomeStanButton from './styled/BecomeStanButton';
import BecomeStanButtonIcon from './styled/BecomeStanButtonIcon';
import BecomeStanButtonWrapper from './styled/BecomeStanButtonWrapper';
import CommentItem from './styled/CommentItem';
import CommentsDisabledMessage from './styled/CommentsDisabledMessage';
import Container from './styled/Container';
import EmptyListMessage from './styled/EmptyListMessage';
import FooterContent from './styled/FooterContent';
import HeaderWrapper from './styled/HeaderWrapper';
import List from './styled/List';
import ViewCTA from './styled/ViewCTA';

export interface CommentListProps {
  artistUsername?: string;
  artistDisplayName?: string;
  commentAccess?: string;
  addComment: CommentAddFunc;
  deleteComment: CommentDeleteFunc;
  data?: Comment[];
  commentsAddNewEnabled?: boolean;
  emptyListMessage?: string;
  profilePicture?: string;
  displayName?: string;
  isProcessingComment: (id: string | number) => boolean;
  ListHeaderComponent?: JSX.Element | null;
  ListFooterComponent?: JSX.Element | null;
  showAgeRestrictions?: boolean;
  profile?: UserProfile;
}

const CommentList = (
  {
    artistUsername,
    artistDisplayName,
    commentAccess,
    addComment,
    deleteComment,
    data = [],
    emptyListMessage,
    commentsAddNewEnabled,
    profilePicture,
    displayName,
    isProcessingComment,
    ListHeaderComponent,
    showAgeRestrictions,
    profile,
    ...rest
  }: CommentListProps,
  ref: any,
): JSX.Element => {
  const listRef = useRef<FlatList>(null);
  const commentAddRef = useRef<typeof CommentAdd>(null);
  const intl = useIntl();
  const { requestPayment } = usePayments();
  const [allCommentsVisible, setCommentsVisibility] = useState<boolean>(false);
  const [shouldScrollToEnd, setScrollToEnd] = useState<boolean>(false);
  const [isAgeRestricted, setIsAgeRestricted] = useState(true);
  const [reported, setReported] = useState<Comment[]>([]);
  const listData = useMemo(() => {
    const sortedData = sortByDate<Comment>(data, 'time_created');
    sortedData.filter((com) => !reported.includes(com));

    return allCommentsVisible ? sortedData : sortedData.slice(-4);
  }, [allCommentsVisible, data]);

  const triggerAllComments = () => {
    setCommentsVisibility((prevState: boolean) => !prevState);
  };

  const onAddComment = (data: { text: string; profilePicture?: string; displayName?: string }) => {
    addComment(data);
    setScrollToEnd(true);
  };

  const extractKey = (comment: unknown): string => (comment as Comment).id.toString();

  const renderComment = ({ item }: { item: any }): JSX.Element => (
    <CommentItem
      comment={item as Comment}
      deleteComment={deleteComment}
      isProcessingComment={isProcessingComment}
      reportable={item.display_name !== displayName && item.display_name !== profile?.username}
      onReport={() => setReported((prev) => [...prev, item])}
    />
  );

  const renderHeader = () => (
    <>
      <HeaderWrapper>{ListHeaderComponent}</HeaderWrapper>
      {!allCommentsVisible && listData.length >= 4 && (
        <ViewCTA onPress={triggerAllComments} text={`View all ${data.length} comments`} />
      )}
    </>
  );

  const renderEmpty = () => {
    if (!emptyListMessage || !commentsAddNewEnabled) {
      return null;
    }

    return <EmptyListMessage>{emptyListMessage}</EmptyListMessage>;
  };

  useEffect(() => {
    if (shouldScrollToEnd) {
      const timeoutHandler = setTimeout(() => {
        (listRef.current as any)?.scrollToEnd({ animated: false });
        setScrollToEnd(false);
      }, 500);

      return () => {
        clearTimeout(timeoutHandler);
      };
    }
  }, [shouldScrollToEnd]);

  const onBecomeStanPress = useCallback(
    async (data: { artistUsername?: string; artistDisplayName?: string }) => {
      if (!data.artistUsername || !data.artistDisplayName) {
        return;
      }

      requestPayment(
        { artistUsername: data.artistUsername, type: 'subscription' },
        intl.formatMessage(messages.unlockPaymentRequestTitle),
        intl.formatMessage(messages.unlockPaymentRequestDescription, {
          username: data.artistDisplayName,
        }),
        () => {
          // TODO: enable comments
        },
      );
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    focusOnCommentInput: () => {
      if (commentAddRef && commentAddRef.current) {
        (commentAddRef.current as any)?.focusOnCommentInput();
      }
    },
  }));

  const renderFooterContent = () => {
    if (showAgeRestrictions && isAgeRestricted) {
      return (
        <>
          <CommentsDisabledMessage>
            {intl.formatMessage({
              id: 'post.commentsAgeRestricted',
              defaultMessage:
                'Comments may contain adult themes, please use the moderation button if you see anything inappropriate.',
            })}
          </CommentsDisabledMessage>
          <BecomeStanButtonWrapper>
            <BecomeStanButton onPress={() => setIsAgeRestricted(false)}>
              {intl.formatMessage({
                id: 'post.commentsAgeRestrictionDismiss',
                defaultMessage: 'I understand',
              })}
            </BecomeStanButton>
          </BecomeStanButtonWrapper>
        </>
      );
    }
    switch (commentAccess) {
      case 'subscription_required':
      case 'subscription_or_following_required':
        return (
          <>
            <CommentsDisabledMessage>
              {intl.formatMessage({
                id: 'post.commentsStansOnly',
                defaultMessage:
                  'Commenting is available for Stans only. Subscribe now to become a Stan and leave a comment.',
              })}
            </CommentsDisabledMessage>
            <BecomeStanButtonWrapper>
              <BecomeStanButton
                icon={BecomeStanButtonIcon}
                onPress={() =>
                  onBecomeStanPress({
                    artistUsername: artistUsername,
                    artistDisplayName: artistDisplayName,
                  })
                }
              >
                {intl.formatMessage({
                  id: 'post.commentsSubscribe',
                  defaultMessage: 'Become a Stan',
                })}
              </BecomeStanButton>
            </BecomeStanButtonWrapper>
          </>
        );
      case 'disabled':
        return (
          <CommentsDisabledMessage>
            {intl.formatMessage({
              id: 'post.commentsDisabled',
              defaultMessage: 'Commenting has been disabled for this post.',
            })}
          </CommentsDisabledMessage>
        );
      default:
        return (
          <CommentAdd
            addComment={onAddComment}
            displayName={displayName}
            profilePicture={profilePicture}
            ref={commentAddRef}
          />
        );
    }
  };

  return (
    <Container>
      <List
        {...rest}
        data={listData}
        extraData={listData}
        keyExtractor={extractKey}
        ref={listRef}
        renderItem={renderComment}
        ListHeaderComponentStyle={{ zIndex: 1 }}
        ListHeaderComponent={renderHeader()}
        ListEmptyComponent={renderEmpty()}
      />

      {commentsAddNewEnabled && <FooterContent>{renderFooterContent()}</FooterContent>}
    </Container>
  );
};

CommentList.displayName = 'CommentList';

export default forwardRef(CommentList);
