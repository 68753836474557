import React from 'react';
import { useIntl } from 'react-intl';

import ScreenView from 'components/ScreenView';

import messages from './messages';
import Icon from './styled/Icon';
import Text from './styled/Text';
import Wrapper from './styled/Wrapper';

interface Props {}

const MaintenanceModeScreen: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <ScreenView>
      <Wrapper>
        <Icon />
        <Text>{intl.formatMessage(messages.info)}</Text>
      </Wrapper>
    </ScreenView>
  );
};

export default MaintenanceModeScreen;
