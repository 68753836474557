import { AntDesign } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack/constants';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from 'screens/MarketplaceProductOrderScreen';
import logo from 'screens/SinglePostScreen/images/logo.png';
import CommentsView from 'screens/SinglePostScreen/styled/CommentsView';
import FeedItemView from 'screens/SinglePostScreen/styled/FeedItemView';
import Logo from 'screens/SinglePostScreen/styled/Logo';
import LogoButton from 'screens/SinglePostScreen/styled/LogoButton';
import OverlayButton from 'screens/SinglePostScreen/styled/OverlayButton';
import SafeAreaView from 'screens/SinglePostScreen/styled/SafeAreaView';
import View from 'screens/SinglePostScreen/styled/View';

import CachedImage from 'components/CachedImage';
import ProductOwner from 'components/ProductOwner';
import WebContainer from 'components/WebContainer';

import { VARIANT } from 'context';
import { useBookmark, useMarketplaceItem } from 'hooks';
import { Bookmark, More, Share } from 'svg';
import { ArtistProduct, MarketplaceItem } from 'types';
import { parseItemPrice } from 'utils/marketplace';
import { shareProduct } from 'utils/sharing';

import messages from './messages';
import ActionRow from './styled/ActionRow';
import ActionText from './styled/ActionText';
import ActionTouchable from './styled/ActionTouchable';
import ArticleHeader from './styled/ArticleHeader';
import ConfirmationButton from './styled/ConfirmationButton';
import Description from './styled/Description';
import Divider from './styled/Divider';
import Price from './styled/Price';
import ProductOwnerWrapper from './styled/ProductOwnerWrapper';
import Title from './styled/Title';
import WebButtonWrapper from './styled/WebButtonWrapper';
export { SCREEN_NAME } from './constants';

const SHOW_MORE_BUTTON = false;

const emptyProduct: ArtistProduct | MarketplaceItem = {
  artist: {
    display_name: '',
    followers_count: 0,
    is_live: false,
    live_stream_viewers_count: 0,
    subscribers_count: 0,
    username: '',
  },
  bookmarks_count: 0,
  comment_tier: null,
  comments_count: 0,
  description: '',
  file_proxy: '',
  has_access: false,
  has_access_code: '',
  has_comment_access_code: '',
  high_res_blurred_cover_height: 0,
  high_res_blurred_cover_width: 0,
  high_res_cover_height: 0,
  high_res_cover_width: 0,
  id: 0,
  likes_count: 0,
  low_res_blurred_cover_height: 0,
  low_res_blurred_cover_width: 0,
  low_res_cover_height: 0,
  low_res_cover_width: 0,
  name: '',
  preview: '',
  price: '',
  tier: null,
  time_created: '',
  model_name: 'marketplaceitem',
};

type ParamList = {
  artist: string;
  productId: number;
};

const MarketplaceProductDetailsScreen: React.FC = () => {
  const intl = useIntl();
  const navigation = useNavigation();
  const route = useRoute();
  const { artist, productId } = route.params as ParamList;
  const { item, updateMarketplaceItem } = useMarketplaceItem(artist || '', productId);

  const onCloseButtonPress = () =>
    navigation.canGoBack()
      ? navigation.goBack()
      : navigation.navigate(MARKETPLACE_STACK_SCREEN_NAME);

  const { toggleBookmark } = useBookmark({
    product: { ...emptyProduct, ...item },
    updateFeedItem: updateMarketplaceItem,
  });

  const onShareProduct = useCallback(() => {
    shareProduct({ ...emptyProduct, ...item });
  }, [shareProduct, item]);

  const navigateToArtist = useCallback(() => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      artistUsername: item?.artist.username,
    });
  }, [navigation, item]);

  const navigateToProductOrder = useCallback(() => {
    navigation.navigate(MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME, {
      marketplaceProductId: item?.id,
      productId: item?.id,
      artistUsername: item?.artist.username,
      item: JSON.stringify(item as MarketplaceItem),
    });
  }, [navigation, item]);

  return (
    <WebContainer fullWidth padding={0}>
      <View style={{ padding: 0 }}>
        <LogoButton onPress={() => {}}>
          <Logo source={logo} />
        </LogoButton>

        <FeedItemView>
          <View style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}>
            <CachedImage
              style={{ width: '100%', height: '100%' }}
              resizeMode="contain"
              source={{ uri: item?.high_res_cover || item?.low_res_cover }}
            />
          </View>
        </FeedItemView>
        <CommentsView padding="120px 32px 62px 16px">
          <ArticleHeader noPadding>
            <Title>{item?.name}</Title>
            <Price>{parseItemPrice(item?.price, item?.price_currency)}</Price>
          </ArticleHeader>
          {!!item?.description && (
            <Description margin="24px 0 42px 0">{item.description}</Description>
          )}

          <ActionRow isArtist>
            {VARIANT === 'fan' && (
              <ActionTouchable onPress={toggleBookmark}>
                <Bookmark fill={item?.is_bookmarked} />
                <ActionText>{intl.formatMessage(messages.save)}</ActionText>
              </ActionTouchable>
            )}

            {/*<ActionTouchable onPress={onShareProduct} ml={55}>*/}
            {/*  <Share />*/}
            {/*  <ActionText>{intl.formatMessage(messages.share)}</ActionText>*/}
            {/*</ActionTouchable>*/}

            {VARIANT === 'fan' && SHOW_MORE_BUTTON && (
              <ActionTouchable ml={48}>
                <More />
                <ActionText>{intl.formatMessage(messages.more)}</ActionText>
              </ActionTouchable>
            )}
          </ActionRow>
          {VARIANT === 'fan' && (
            <>
              <Divider />
              <ProductOwnerWrapper onPress={navigateToArtist}>
                <ProductOwner
                  padding="15px 0"
                  name={item?.artist?.display_name}
                  imageUrl={item?.artist?.low_res_profile_picture}
                />
                <AntDesign name="right" size={16} color="white" />
              </ProductOwnerWrapper>
              <Divider />
            </>
          )}
          <WebButtonWrapper>
            {VARIANT === 'fan' && item && (
              <ConfirmationButton onPress={navigateToProductOrder}>
                {intl.formatMessage(messages.order)}
              </ConfirmationButton>
            )}
          </WebButtonWrapper>
        </CommentsView>

        <SafeAreaView pointerEvents="box-none">
          <OverlayButton icon="close" onPress={onCloseButtonPress} />
        </SafeAreaView>
      </View>
    </WebContainer>
  );
};

export default MarketplaceProductDetailsScreen;
