import styled, { css } from 'styled-components/native';

import VideoTimestamp from 'components/VideoTimestamp';

interface Props {
  isFullscreen?: boolean;
}

export default styled(VideoTimestamp)<Props>`
  position: absolute;
  bottom: 20px;
  align-self: center;

  ${({ isFullscreen }) =>
    isFullscreen &&
    css`
      bottom: 60px;
    `}
`;
