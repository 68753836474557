import React, { useEffect, useRef, useState } from 'react';
import { TouchableOpacityProps } from 'react-native';
import styled from 'styled-components/native';

interface ButtonProps extends TouchableOpacityProps {
  recording: boolean;
  noHover?: boolean;
}

const Button = styled.TouchableOpacity<ButtonProps>`
  background-color: ${({ theme, recording }) =>
    recording ? theme.colors.errorAccent : theme.colors.text};
  width: 111px;
  height: 111px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `3px solid ${theme.colors.backgroundDarker}`};
`;

const Border = styled.View<{ width: number }>`
  border: ${({ theme, width }) => `${3 * width}px solid ${theme.colors.errorAccent}`};
  width: ${({ width }) => 120 - 3 * width}px;
  height: ${({ width }) => 120 - 3 * width}px;
  border-radius: 100px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
`;

interface RecordButtonProps extends ButtonProps {
  pressed?: boolean;
}

const RecordButton: React.FC<RecordButtonProps> = ({
  pressed,
  recording,
  ...props
}: RecordButtonProps) => {
  const animation = useRef<number>(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (pressed) {
      animation.current = setInterval(() => setWidth((w) => w + 0.1), 100);
    } else {
      clearInterval(animation?.current);
      setWidth(0);
    }
  }, [pressed]);
  useEffect(() => {
    if (width >= 1) {
      clearInterval(animation?.current);
      animation.current = setInterval(
        () =>
          setWidth((w) => {
            if (w <= 0) clearInterval(animation?.current);
            return w - 0.1;
          }),
        100,
      );
    }
  }, [width]);
  return (
    <Border width={width}>
      <Button {...props} recording={recording} activeOpacity={props.noHover ? 1.0 : 0.7} />
    </Border>
  );
};

export default RecordButton;
