import * as React from 'react';
import Svg, { Circle, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function NotificationIcon(props: SvgProps) {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <Circle cx="6" cy="6" r="5" fill="#1DFF78" />
      <Circle cx="6" cy="6" r="5" stroke="#121212" strokeWidth="2" />
      <Circle cx="6" cy="6" r="5" stroke="#2E51DF" strokeOpacity="0.12" strokeWidth="2" />
    </Svg>
  );
}

const StyledNotificationIcon = styled(NotificationIcon)`
  position: absolute;
  top: -2px;
  left: 15px;
`;

export default React.memo(StyledNotificationIcon);
