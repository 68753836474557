import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

type Props = {
  label: string;
};

const TouchableOpacity = styled.View`
  background-color: ${({ theme }) => theme.button.backgroundColorBlue};
  flex-direction: row;
  width: 60%;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  margin-left: 5px;
  font-size: 15px;
`;

const Icon = styled(FontAwesome).attrs((props) => {
  return {
    name: 'image',
    color: props.theme.colors.text,
    size: 20,
  };
})``;

const PickerButton: React.FC<Props> = ({ label }: Props) => {
  return (
    <TouchableOpacity>
      <Icon />
      <Text>{label}</Text>
    </TouchableOpacity>
  );
};

export default PickerButton;
