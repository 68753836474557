import { StackNavigationProp } from '@react-navigation/stack';
import { IntentLauncherResult } from 'expo-intent-launcher';
import * as IntentLauncher from 'expo-intent-launcher';
import React, { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Linking, Platform } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as FORGOT_PASSWORD_SCREEN_NAME } from 'screens/ForgotPasswordScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen/constants';
import { SCREEN_NAME as REGISTER_SCREEN_NAME } from 'screens/RegisterWithEmailScreen/constants';

import FormTitleText from 'components/FormTitleText/FormTitleText';

import mail from './images/mail.png';
import messages from './messages';
import DescriptionText from './styled/DescriptionText';
import DescriptionTextView from './styled/DescriptionTextView';
import MailImage from './styled/MailImage';
import OpenMailButton from './styled/OpenMailButton';
import View from './styled/View';

type ScreenNavigationProp = StackNavigationProp<
  ParamList,
  | typeof FORGOT_PASSWORD_SCREEN_NAME
  | typeof REGISTER_SCREEN_NAME
  | typeof MARKETPLACE_ORDER_REPORT_SCREEN_NAME
>;

interface Props {
  navigation: ScreenNavigationProp;
  title: MessageDescriptor;
  description: MessageDescriptor;
  email?: string;
}

const openMailApp = async (): Promise<any | IntentLauncherResult> => {
  if (Platform.OS === 'android') {
    const activityAction = 'android.intent.action.MAIN';
    const intentParams: IntentLauncher.IntentLauncherParams = {
      flags: 268435456, // Intent.FLAG_ACTIVITY_NEW_TASK
      category: 'android.intent.category.APP_EMAIL',
    };
    return IntentLauncher.startActivityAsync(activityAction, intentParams);
  } else if (Platform.OS === 'ios') {
    Linking.canOpenURL('message:0').then((supported) => {
      if (!supported) {
        console.log('Cant handle url');
      } else {
        return Linking.openURL('message:0');
      }
    });
  }
};

const EmailSentView: React.FC<Props> = ({ navigation, title, description, email }: Props) => {
  const intl = useIntl();
  const handleOpenMailApp = useCallback(() => {
    openMailApp();
  }, []);

  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View>
      {Platform.OS === 'web' ? <MailImage src={mail} /> : <MailImage source={mail} />}
      <FormTitleText>{intl.formatMessage(title)}</FormTitleText>
      <DescriptionTextView>
        <DescriptionText>
          {intl.formatMessage(description, {
            emailAddress: function emailAddress() {
              return <DescriptionText bold>{email}</DescriptionText>;
            },
          })}
        </DescriptionText>
      </DescriptionTextView>
      {!(Platform.OS === 'web') && (
        <>
          <OpenMailButton onPress={handleOpenMailApp}>
            {intl.formatMessage(messages.openMailAppCta)}
          </OpenMailButton>
          <DescriptionText onPress={handleClose}>
            {intl.formatMessage(messages.closeCta)}
          </DescriptionText>
        </>
      )}
    </View>
  );
};

export default EmailSentView;
