import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(() => ({
  labelColor: '#fff',
  iconAlign: 'right',
}))`
  background: #0537ff;
  border-radius: 40px;
  width: 165px;
  height: 44px;
`;
