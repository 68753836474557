import { PathConfigMap, RouteProp } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Platform, View } from 'react-native';

import { ParamList } from 'stacks/types';

import ArtistAudioCreatePostScreen, {
  SCREEN_NAME as ARTIST_AUDIO_CREATE_POST_SCREEN_NAME,
} from 'screens/ArtistAudioCreatePostScreen';
import ArtistAudioRecordScreen, {
  SCREEN_NAME as ARTIST_AUDIO_RECORD_SCREEN_NAME,
} from 'screens/ArtistAudioRecordScreen';
import ArtistAudioTrimScreen, {
  SCREEN_NAME as ARTIST_AUDIO_TRIM_SCREEN_NAME,
} from 'screens/ArtistAudioTrimScreen';
import ArtistEditPostScreen, {
  SCREEN_NAME as ARTIST_EDIT_POST_SCREEN_NAME,
} from 'screens/ArtistEditPostScreen';
import ArtistEditPostSettingsScreen, {
  SCREEN_NAME as ARTIST_EDIT_POST_SETTINGS_SCREEN_NAME,
} from 'screens/ArtistEditPostSettingsScreen';
import ArtistLiveScreen, { SCREEN_NAME as ARTIST_LIVE_SCREEN_NAME } from 'screens/ArtistLiveScreen';
import ArtistPollAddDescriptionScreen, {
  SCREEN_NAME as ARTIST_POLL_ADD_DESCRIPTION_SCREEN_NAME,
} from 'screens/ArtistPollAddDescriptionScreen';
import ArtistPollCreateScreen, {
  SCREEN_NAME as ARTIST_POLL_CREATE_SCREEN_NAME,
} from 'screens/ArtistPollCreateScreen';
import ArtistPostScreen, { SCREEN_NAME as ARTIST_POST_SCREEN_NAME } from 'screens/ArtistPostScreen';
import ArtistPostSettingsScreen, {
  SCREEN_NAME as ARTIST_POST_SETTINGS_SCREEN_NAME,
} from 'screens/ArtistPostSettingsScreen';

import { useTheme } from 'themes';
import darkTheme from 'themes/dark';
import lightTheme from 'themes/light';

import { SCREEN_NAME } from './constants';

export { SCREEN_NAME };

export const linking: PathConfigMap = {
  [ARTIST_POST_SCREEN_NAME]: 'post',
  [ARTIST_AUDIO_RECORD_SCREEN_NAME]: 'audio-record',
  [ARTIST_AUDIO_TRIM_SCREEN_NAME]: 'audio-trim',
  [ARTIST_AUDIO_CREATE_POST_SCREEN_NAME]: 'audio-create-post',
  [ARTIST_POLL_CREATE_SCREEN_NAME]: 'poll-create',
  [ARTIST_POLL_ADD_DESCRIPTION_SCREEN_NAME]: 'poll-add-description',
  [ARTIST_POST_SETTINGS_SCREEN_NAME]: 'post-settings',
};

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation?: ScreenNavigationProp;
  route?: ScreenRouteProp;
  close?: boolean;
  onClose?: () => void;
  screenName?: string;
  screenParams?: Readonly<undefined>;
};

const initScreenOptions = () => {
  return {
    headerShown: false,
    cardStyle: {
      backgroundColor: Platform.OS === 'web' ? '#000' : 'transparent',
    },
    cardOverlayEnabled: true,
    cardOverlay: function CardOverlay() {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
      );
    },
  };
};

const nextScreenOptions = () => {
  return {
    headerShown: false,
    cardStyle: {
      backgroundColor: Platform.OS === 'web' ? '#000' : 'transparent',
    },
    cardStyleInterpolator: ({ current: { progress } }: { current: { progress: any } }) => ({
      cardStyle: {
        opacity: progress.interpolate({
          inputRange: [0, 0.5, 0.9, 1],
          outputRange: [0, 0.25, 0.7, 1],
        }),
      },
      overlayStyle: {
        opacity: progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 0.5],
          extrapolate: 'clamp',
        }),
      },
    }),
  };
};

const NewPostStack = createStackNavigator();

const ArtistNewPostStack: React.FC<Props> = () => {
  const theme = useTheme();
  return (
    <NewPostStack.Navigator
      screenOptions={{
        detachPreviousScreen: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          backgroundColor:
            theme.mode === 'dark' ? darkTheme.colors.background : lightTheme.colors.background,
        },
        headerTitleAlign: 'center',
      }}
    >
      <NewPostStack.Screen
        name={ARTIST_POST_SCREEN_NAME}
        component={ArtistPostScreen}
        options={initScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_EDIT_POST_SCREEN_NAME}
        component={ArtistEditPostScreen}
        options={initScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_AUDIO_RECORD_SCREEN_NAME}
        component={ArtistAudioRecordScreen}
        options={initScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_AUDIO_TRIM_SCREEN_NAME}
        component={ArtistAudioTrimScreen}
        options={() => ({ ...nextScreenOptions(), gestureEnabled: false })}
      />
      <NewPostStack.Screen
        name={ARTIST_AUDIO_CREATE_POST_SCREEN_NAME}
        component={ArtistAudioCreatePostScreen}
        options={nextScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_POST_SETTINGS_SCREEN_NAME}
        component={ArtistPostSettingsScreen}
        options={nextScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_EDIT_POST_SETTINGS_SCREEN_NAME}
        component={ArtistEditPostSettingsScreen}
        options={nextScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_POLL_CREATE_SCREEN_NAME}
        component={ArtistPollCreateScreen}
        options={initScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_POLL_ADD_DESCRIPTION_SCREEN_NAME}
        component={ArtistPollAddDescriptionScreen}
        options={initScreenOptions}
      />
      <NewPostStack.Screen
        name={ARTIST_LIVE_SCREEN_NAME}
        component={ArtistLiveScreen}
        options={{
          headerShown: false,
        }}
      />
    </NewPostStack.Navigator>
  );
};

export default ArtistNewPostStack;
