import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({ labelColor: '#fff' })`
  width: 225px;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.artistAccent};
  border-radius: 40px;
  margin-top: 30px;
`;
