import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as HELP_FORM_SCREEN_NAME } from 'screens/HelpFormScreen/constants';
import PreFormScreen from 'screens/PreFormScreen';

import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation: ScreenNavigationProp;
};

const HelpScreen: React.FC<Props> = ({ navigation }: Props) => (
  <PreFormScreen
    messages={messages}
    navigation={navigation}
    destination={HELP_FORM_SCREEN_NAME}
    breadcumb="Help"
  />
);

export default HelpScreen;
