import React from 'react';
import styled from 'styled-components/native';

const Text = styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.23px;
  text-decoration-line: underline;
  color: #bcffc9;
`;

const TouchableOpacity = styled.TouchableOpacity``;

interface Props {
  onPress: () => void;
  children: any;
}

const Link: React.FC<Props> = ({ children, onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text>{children}</Text>
    </TouchableOpacity>
  );
};

export default Link;
