import React from 'react';
import { useIntl } from 'react-intl';

import ProfileAvatar from 'containers/ProfileAvatar';
import ProfileInformationsForm from 'containers/ProfileInformationsForm';
import ProfilePasswordForm from 'containers/ProfilePasswordForm';

import { useUser } from 'hooks';

import messages from './messages';
import MainView from './styled/MainView';
import Title from './styled/Title';

interface Props {
  containerWidth?: number;
}

const ProfileSettings: React.FC<Props> = ({ containerWidth }: Props) => {
  const intl = useIntl();
  const {
    error,
    isLoading,
    isUpdatingGeneral,
    isUpdatingPassword,
    isUpdatingPicture,
    profile,
    updateInformationError,
    updatePasswordError,
    updateGeneralInformation,
    updatePassword,
  } = useUser();

  return containerWidth ? (
    <MainView>
      <Title>{intl.formatMessage(messages.generalInfo)}</Title>
      <ProfileAvatar
        profilePicture={profile?.profile_picture}
        cover={profile?.cover_image}
        isUpdating={isUpdatingPicture}
        update={updateGeneralInformation}
      />
      <ProfileInformationsForm
        fullName={profile?.full_name}
        email={profile?.email}
        username={profile?.username}
        isUpdating={isUpdatingGeneral}
        updateError={updateInformationError}
        update={updateGeneralInformation}
        width={containerWidth}
      />
      <Title>{intl.formatMessage(messages.password)}</Title>
      <ProfilePasswordForm
        isUpdating={isUpdatingPassword}
        updateError={updatePasswordError}
        update={updatePassword}
        width={containerWidth}
      />
    </MainView>
  ) : (
    <></>
  );
};

export default ProfileSettings;
