import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: column;
  align-self: center;
  justify-content: center;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 567px;
    `}
`;
