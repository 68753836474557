import React from 'react';
import { useIntl } from 'react-intl';

import FeedTopNav from 'components/FeedTopNav';

import messages from './messages';
import NoNetworkSvg from './styled/NoNetworkSvg';
import ScreenView from './styled/ScreenView';
import Title from './styled/Title';
import View from './styled/View';

interface Props {}

const NoNetworkScreen: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <ScreenView>
      <FeedTopNav isArtist={false} showNotificationIcon={false} />
      <View>
        <NoNetworkSvg />
        <Title>{intl.formatMessage(messages.title)}</Title>
      </View>
    </ScreenView>
  );
};

export default NoNetworkScreen;
