import styled, { css } from 'styled-components/native';

import { Padlock } from 'svg';

interface Props {
  isLandscape?: boolean;
}

const SvgComponent = styled(Padlock).attrs(
  ({ isLandscape }: Props) => isLandscape && { width: 24, height: 24 },
)<Props>`
  align-self: center;
  margin-bottom: 35px;
  ${({ isLandscape }) =>
    isLandscape &&
    css`
      margin-bottom: 15px;
    `}
`;

export default SvgComponent;
