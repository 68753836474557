import React from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { FeedItemAudioContentProps } from 'components/FeedItemAudioContent';
import { FeedItemPollOptions } from 'components/FeedItemPollContent/FeedItemPollContent';
import { FeedItemVideoContentProps } from 'components/FeedItemVideoContent';

import { ArtistProduct, PostAspect } from 'types';
import { PollProduct } from 'types/PollProduct';
import { getAspect, pickPreviewImage } from 'utils/product';
import { getProfileLowResPictureUrl } from 'utils/user';

import { POLL_ANSWER_HEIGHT } from './constants';
import Avatar from './styled/Avatar';
import AvatarRow from './styled/AvatarRow';
import Caption from './styled/Caption';
import CommentsCount from './styled/CommentsCount';
import CommentsCountNumber from './styled/CommentsCountNumber';
import ContentView from './styled/ContentView';
import EditButton from './styled/EditButton';
import FeedItemAudioContent from './styled/FeedItemAudioContent';
import FeedItemControls from './styled/FeedItemControls';
import FeedItemControlsOverlayed from './styled/FeedItemControlsOverlayed';
import FeedItemPhotoContent from './styled/FeedItemPhotoContent';
import FeedItemPollContent from './styled/FeedItemPollContent';
import FeedItemVideoContent from './styled/FeedItemVideoContent';
import MediaView from './styled/MediaView';
import More from './styled/More';
import SeeInsights from './styled/SeeInsights';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';

export interface FeedItemProps {
  product?: ArtistProduct;
  aspectRatio?: PostAspect;
  isPlaceholder?: boolean;
  isOnFeed?: boolean;
  isExpanded?: boolean;
  showAvatar?: boolean;
  showControls?: boolean;
  showCaption?: boolean;
  showCommentsBottomCounter?: boolean;
  showEdit?: boolean;
  showMore?: boolean;
  showInsightsButton?: boolean;
  showControlsBelowContent?: boolean;
  reactionsEnabled?: boolean;
  commentingEnabled?: boolean;
  tippingEnabled?: boolean;
  bookmarkingEnabled?: boolean;
  sharingEnabled?: boolean;
  roundedCorners?: boolean;
  videoOptions?: FeedItemVideoContentProps;
  audioOptions?: FeedItemAudioContentProps;
  pollOptions?: FeedItemPollOptions;
  onAvatarPress?: () => void;
  onContentPress?: () => void;
  onLikePress?: () => void;
  onCommentsPress?: () => void;
  onBookmarkPress?: () => void;
  onSharePress?: () => void;
  onUnlockPress?: () => void;
  onTipPress?: () => void;
  onMorePress?: () => void;
  onSeeInsightsPress?: () => void;
  onEditPress?: () => void;
  navigateToProduct?: () => void;
  overlayButtonMarginTop?: number;
}

const FeedItem: React.FC<FeedItemProps> = ({
  product,
  aspectRatio,
  isPlaceholder = false,
  isOnFeed = true,
  isExpanded = false,
  showAvatar = true,
  showControls = true,
  showCaption = true,
  showCommentsBottomCounter = false,
  showEdit = false,
  showMore = true,
  showInsightsButton = false,
  showControlsBelowContent = false,
  reactionsEnabled = false,
  commentingEnabled = false,
  tippingEnabled = false,
  bookmarkingEnabled = false,
  sharingEnabled = true,
  roundedCorners = true,
  videoOptions,
  audioOptions,
  pollOptions,
  onAvatarPress,
  onContentPress,
  onUnlockPress,
  onMorePress,
  onSeeInsightsPress,
  onEditPress,
  navigateToProduct,
  overlayButtonMarginTop,
  onCommentsPress,
  ...restProps
}: FeedItemProps) => {
  const intl = useIntl();

  const isAudioContent = product?.model_name === 'audio';
  const isVideoContent = product?.model_name === 'video';
  const isImageContent = product?.model_name === 'image';
  const isPollContent = product?.model_name === 'poll';

  const aspect: PostAspect = aspectRatio || (product ? getAspect(product) : 'square');
  const cover = product ? pickPreviewImage(product, true) : undefined;
  const artist = product ? product.artist : undefined;
  const access = {
    hasAccess: product?.has_access !== false,
  };

  const controlsOptions = {
    showReactions: true,
    showComments: true,
    showBookmarks: true,
    showSharing: !(Platform.OS === 'web'),
    reactionsEnabled: reactionsEnabled && access.hasAccess && !isPlaceholder,
    commentingEnabled: commentingEnabled && !isPlaceholder,
    tippingEnabled: tippingEnabled && !isPlaceholder,
    bookmarkingEnabled: bookmarkingEnabled && !isPlaceholder,
    sharingEnabled: sharingEnabled && !isPlaceholder,
    reactionsCount: product?.likes_count || 0,
    isTipped: product?.is_tickmarked,
    isReacted: product?.is_liked,
    isBookmarked: product?.is_bookmarked,
    isOnFeed: isOnFeed,
    bookmarksCount: product?.bookmarks_count || 0,
    commentsCount: product?.comments_count || 0,
    showZeroCount: true,
    showTipping: true,
    focusOnCommentAdd: onCommentsPress,
  };

  const formatCommentsCount = (num?: number) => {
    return (num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')) || 0;
  };

  return (
    <View {...restProps}>
      {showAvatar && !isExpanded && (
        <AvatarRow>
          <Avatar
            mode="expanded"
            isFeedItemExpanded={isExpanded}
            isPlaceholder={isPlaceholder}
            name={artist?.display_name}
            username={artist?.username}
            imageUrl={getProfileLowResPictureUrl(artist)}
            isLive={artist?.is_live}
            timestamp={product?.time_created}
            onPress={onAvatarPress}
          />
          <More onPress={onMorePress} isExpanded={isExpanded} />
        </AvatarRow>
      )}
      <MediaView
        roundedCorners={roundedCorners}
        id={`FeedItem.${artist?.display_name || 'placeholder'}.${
          product?.model_name || 'placeholder'
        }.${product?.id}`}
        aspect={aspect}
        isExpanded={isExpanded}
        height={
          product?.answers?.length && `${160 + POLL_ANSWER_HEIGHT * product.answers.length}px`
        }
        isSinglePostVideo={Platform.OS !== 'web' && isExpanded && isVideoContent}
      >
        <>
          {showEdit && (
            <EditButton
              text={intl.formatMessage({
                defaultMessage: 'Edit',
                id: 'feed.item.edit',
              })}
              onPress={onEditPress}
            />
          )}
          {!!product && !isPlaceholder && (
            <>
              {isImageContent && (
                <FeedItemPhotoContent
                  access={access}
                  blurredCover={cover}
                  isExpanded={isExpanded}
                  url={product.high_res_cover || product.file_proxy || product.low_res_cover}
                  mediaWidth={cover?.width}
                  mediaHeight={cover?.height}
                  showOverlay={!showControlsBelowContent}
                  onPress={onContentPress}
                  lockedItemProps={{
                    onUnlockPress,
                    onPress: navigateToProduct,
                    overlayButtonMarginTop,
                    productPaymentModel: product.payment_model,
                    artistDisplayName: product?.artist?.display_name,
                  }}
                />
              )}
              {isPollContent && (
                <FeedItemPollContent
                  product={product as PollProduct}
                  showControlsBelowContent={showControlsBelowContent}
                  access={access}
                  lockedItemProps={{
                    isExpanded,
                    onUnlockPress,
                    onPress: navigateToProduct,
                    showOverlay: !showControlsBelowContent,
                    productPaymentModel: product.payment_model,
                    artistDisplayName: product?.artist?.display_name,
                  }}
                  {...pollOptions}
                />
              )}
              {isVideoContent && (
                <FeedItemVideoContent
                  {...videoOptions}
                  access={access}
                  coverImageUrl={cover?.url}
                  url={product.file_proxy}
                  teaserUrl={product.teaser}
                  mediaWidth={cover?.width}
                  mediaHeight={cover?.height}
                  showOverlay={!showControlsBelowContent}
                  showControlsBottom={showControlsBelowContent}
                  showControls={showControls}
                  lockedItemProps={{
                    isExpanded,
                    onUnlockPress,
                    overlayButtonMarginTop,
                    onPress: navigateToProduct,
                    productPaymentModel: product.payment_model,
                    artistDisplayName: product?.artist?.display_name,
                  }}
                />
              )}
              {isAudioContent && (
                <FeedItemAudioContent
                  {...audioOptions}
                  autoPlay={isExpanded && access.hasAccess}
                  access={access}
                  artistPhotoUrl={product?.artist?.medium_res_profile_picture}
                  url={product.file_proxy}
                  teaserUrl={product.teaser}
                  lockedItemProps={{
                    isExpanded,
                    showOverlay: !showControlsBelowContent,
                    onUnlockPress,
                    onPress: navigateToProduct,
                    productPaymentModel: product.payment_model,
                    artistDisplayName: product?.artist?.display_name,
                  }}
                />
              )}
            </>
          )}
          {showControls && !showControlsBelowContent && product && (
            <FeedItemControlsOverlayed
              {...controlsOptions}
              isTipped={product?.is_tipped}
              product={product}
            />
          )}
        </>
      </MediaView>
      {showInsightsButton && (
        <SeeInsights timestamp={product?.time_created} onPress={onSeeInsightsPress} />
      )}
      {Platform.OS !== 'web' && (
        <ContentView isExpanded={isExpanded}>
          {showControls && showControlsBelowContent && product && (
            <FeedItemControls
              {...controlsOptions}
              isTipped={product?.is_tipped}
              product={product}
            />
          )}
          {showAvatar && isExpanded && (
            <AvatarRow>
              <Avatar
                mode="expanded"
                isFeedItemExpanded={isExpanded}
                isPlaceholder={isPlaceholder}
                name={artist?.display_name}
                username={artist?.username}
                imageUrl={getProfileLowResPictureUrl(artist)}
                isLive={artist?.is_live}
                timestamp={product?.time_created}
                onPress={onAvatarPress}
              />
              {showMore && <More onPress={onMorePress} isExpanded={isExpanded} />}
            </AvatarRow>
          )}
          {(isPlaceholder ||
            (showCaption &&
              product?.description !== undefined &&
              product?.description.length > 0)) && (
            <TouchableOpacity onPress={() => isOnFeed && navigateToProduct?.()}>
              <Caption
                isPlaceholder={isPlaceholder}
                isExpanded={isExpanded}
                text={product?.description}
              />
            </TouchableOpacity>
          )}
          {showCommentsBottomCounter && (
            <CommentsCount>
              Comments
              <CommentsCountNumber>{` (${formatCommentsCount(
                product?.comments_count || 0,
              )})`}</CommentsCountNumber>
            </CommentsCount>
          )}
        </ContentView>
      )}
    </View>
  );
};

FeedItem.displayName = 'FeedItem';

export default FeedItem;
