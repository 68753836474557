export default {
  welcome: {
    fan: {
      id: 'welcome.message.fan',
      defaultMessage: 'Are you a real Stan? ✌️ Join our community and follow artists‍ 🎶',
    },
    fanWebTitle: {
      id: 'welcome.message.fanWeb',
      defaultMessage: 'Are you\na real Stan?',
    },
    fanWebSubtitle: {
      id: 'welcome.message.fanWebSubtitle',
      defaultMessage:
        'Support your favourite artists and get access to exclusive content and experiences via a monthly subscription.',
    },
    artist: {
      id: 'welcome.message.artist',
      defaultMessage: 'Welcome! ‍🎤🎶',
    },
    platformNotSupported: {
      id: 'welcome.message.platformNotSupported',
      defaultMessage:
        'Hi! ‍🙌 Stanbase is a native app only supported on iOS and Android. Please download it from a respective app store.',
    },
    beta: { id: 'welcome.message.beta', defaultMessage: 'beta version' },
  },
  registerCta: {
    fan: {
      id: `welcome.register.fan`,
      defaultMessage: 'Join us',
    },
    fanWeb: {
      id: `welcome.register.fanWeb`,
      defaultMessage: 'Join Stanbase',
    },
    artist: {
      id: `welcome.register.artist`,
      defaultMessage: 'Join Stanbase',
    },
  },
  loginCta: {
    fan: {
      id: `welcome.login.fan`,
      defaultMessage: "I'm already a Stan",
    },
    fanWeb: {
      id: `welcome.login.fanWeb`,
      defaultMessage: 'Log in',
    },
    artist: {
      id: `welcome.login.artist`,
      defaultMessage: "I'm already an Artist",
    },
  },
  artist: {
    register: {
      id: `welcome.artist.register`,
      defaultMessage: "Don't have an account? <cta>Click here</cta>",
    },
  },
  bottomSheet: {
    login: {
      heading: {
        fan: {
          id: `welcome.login.heading`,
          defaultMessage: 'Welcome back, Stan!',
        },
        artist: {
          id: `welcome.login.heading`,
          defaultMessage: 'Log in',
        },
      },
      disclaimer: null,
    },
    register: {
      heading: {
        fan: {
          id: `welcome.register.heading.fan`,
          defaultMessage: 'Join Stanbase!',
        },
        artist: {
          id: `welcome.register.heading.artist`,
          defaultMessage: 'Request access',
        },
      },
      accessRequest: {
        fan: null,
        artist: {
          id: `welcome.register.accessRequest.artist`,
          defaultMessage:
            "If you'd like to join Stanbase as an artist, please reach our to us at <mailto>artist@stanbase.com</mailto> 🤟🎤",
        },
      },
      info: {
        id: `welcome.register.info`,
        defaultMessage: 'Get access to exclusive content from your favourite artists.',
      },
      disclaimer: {
        id: `welcome.register.disclaimer`,
        defaultMessage:
          'By taping Continue with Apple, Google, Facebook or Use your e-mail address I agree to Stanbase’s <termsCta> Terms of use</termsCta>, <paymentTermsCta>Payment Terms of Service</paymentTermsCta>, <privacyPolicyCta>Privacy Policy</privacyPolicyCta> and <nondiscriminationPolicyCta>Nondiscrimination Policy</nondiscriminationPolicyCta>.',
      },
    },
    option: {
      email: {
        id: `welcome.option.email`,
        defaultMessage: 'Use your e-mail address',
      },
      apple: {
        id: `welcome.option.apple`,
        defaultMessage: 'Continue with Apple',
      },
      google: {
        id: `welcome.option.google`,
        defaultMessage: 'Continue with Google',
      },
      facebook: {
        id: `welcome.option.facebook`,
        defaultMessage: 'Continue with Facebook',
      },
      separator: {
        id: `welcome.option.separator`,
        defaultMessage: 'or',
      },
    },
  },
  error: {
    title: {
      id: 'error.login.title',
      defaultMessage: 'Unsuccessful sign in',
    },
    message: {
      id: 'error.login.message',
      defaultMessage: 'There was a login error, please try again using your e-mail address.',
    },
    notInWhitelist: {
      id: 'error.login.notInWhitelist',
      defaultMessage: 'Invite not found. For now, Stanbase works only for invited Stans.',
    },
  },
};
