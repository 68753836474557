import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;

  ${({ theme }) =>
    theme.media.tabletLandscape &&
    css`
      margin-top: 20vh;
    `}
`;
