import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.marketplaceProductDetails.primaryText};
  font-size: 20px;
  font-weight: bold;
  font-family: BasierCircle-Semibold;
  line-height: 25px;
  margin-left: 30px;
`;
