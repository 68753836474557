import styled from 'styled-components/native';

export default styled.Text`
  position: absolute;
  bottom: 50px;
  text-align: center;
  font-size: 13px;
  width: 100%;
  color: #ffffff;
  align-self: center;
  min-width: 80px;
`;
