import Animated from 'react-native-reanimated';
import styled, { css } from 'styled-components/native';

export const Wrapper = styled(Animated.View)<{ fill?: boolean }>`
  ${({ fill }) =>
    fill &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}
`;

export const WrapperWeb = styled.View<{ fill?: boolean; isVisible?: boolean }>`
  ${({ fill }) =>
    fill &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;
