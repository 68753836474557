import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import DiscoverScreen, { SCREEN_NAME as DISCOVER_SCREEN_NAME } from 'screens/DiscoverScreen';

export { SCREEN_NAME } from './constants';

export interface Props {}

export const linking: PathConfigMap = {
  [DISCOVER_SCREEN_NAME]: '',
};

const DiscoverStack = createStackNavigator();

const FanDiscoverStack: React.FC<Props> = () => {
  return (
    <DiscoverStack.Navigator screenOptions={{ title: 'Discover' }}>
      <DiscoverStack.Screen
        name={DISCOVER_SCREEN_NAME}
        component={DiscoverScreen}
        options={{
          title: 'Discover',
          headerShown: false,
          animationEnabled: true,
        }}
      />
    </DiscoverStack.Navigator>
  );
};

export default FanDiscoverStack;
