import styled from 'styled-components/native';

export default styled.TouchableOpacity.attrs(() => {
  return {
    activeOpacity: 0.7,
  };
})`
  background-color: ${({ theme }) => theme.colors.text};
  width: 44px;
  height: 44px;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  right: 30px;
`;
