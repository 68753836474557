import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.23px;
  color: #fff;
  opacity: 0.87;
  margin-top: 30px;
`;
