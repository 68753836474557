import { Platform } from 'react-native';
import { Switch } from 'react-native-paper';
import styled from 'styled-components/native';

export default styled(Switch).attrs(({ theme }) => {
  return {
    ios_backgroundColor: theme.colors.background,
    trackColor: {
      false: theme.colors.background,
      true: Platform.OS !== 'ios' ? '#4caf50' : undefined,
    },
    thumbColor: theme.colors.primaryActive,
    color: theme.colors.primaryActive,
  };
})``;
