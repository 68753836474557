import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.text};
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-left: 15px;
`;
