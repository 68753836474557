import styled, { css } from 'styled-components/native';

interface ImageProps {
  isInsights?: boolean;
}

export default styled.Image<ImageProps>`
  position: relative;

  ${({ isInsights }) =>
    isInsights
      ? css`
          top: 5px;
          width: 88px;
          height: 106px;
        `
      : css`
          top: 9px;
          width: 128px;
          height: 146px;
        `}
`;
