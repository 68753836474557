import moment from 'moment';
import React, { memo, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, Platform } from 'react-native';

import ProductPreview from 'components/ProductPreview';

import { useUnsubscribeModal } from 'context';
import { useSubscriptionModalWeb } from 'context/subscriptionModalWeb';
import { useFollowing, useScreenSize, useSubscriptionStatus, useUser } from 'hooks';
import { Artist, ArtistFeed, ArtistProduct } from 'types';
import { pickPreviewImage } from 'utils/product';
import { getProfileLowResPictureUrl } from 'utils/user';

import messages from './messages';
import Avatar from './styled/Avatar';
import BackButton from './styled/BackButton';
import BecomeStanIcon from './styled/BecomeStanIcon';
import ButtonsView from './styled/ButtonsView';
import FollowButton from './styled/FollowButton';
import Header from './styled/Header';
import IconCheckFollowed from './styled/IconCheckFollowed';
import IconCheckSubscribed from './styled/IconCheckSubscribed';
import ListView from './styled/ListView';
import NextButton from './styled/NextButton';
import ProductPreviewWrapper from './styled/ProductPreviewWrapper';
import Separator from './styled/Separator';
import Shadow from './styled/Shadow';
import SubscribeButton from './styled/SubscribeButton';
import View from './styled/View';
import Wrapper from './styled/Wrapper';

export interface ArtistFeedProps extends ArtistFeed {
  /** If true, will show a placeholder version */
  isLoading?: boolean;
  maxItemsCount?: number;
  onProductPress?: (product: ArtistProduct) => void;
  onArtistPress?: (artist: Artist) => void;
}

const ArtistFeedList: React.FC<ArtistFeedProps> = ({
  isLoading,
  cover_image: coverImage,
  display_name: displayName,
  is_featured: isFeatured,
  is_live: isLive,
  profile_picture: profilePicture,
  featured_feed: featuredFeed,
  low_res_profile_picture: lowResProfilePicture,
  username,
  onProductPress,
  onArtistPress,
  ...rest
}: ArtistFeedProps): JSX.Element => {
  const ref = useRef();
  const fullArtist: Artist = ((featuredFeed || [])[0] as ArtistProduct)?.artist || {
    cover_image: coverImage,
    display_name: displayName,
    is_featured: isFeatured,
    is_live: isLive,
    low_res_profile_picture: lowResProfilePicture,
    profile_picture: profilePicture,
    username,
  };
  const extractKey = (item: unknown): string => `${(item as ArtistProduct).id}`;
  const {
    isLoading: isLoadingFollowing,
    followingStatus,
    follow,
    unfollow,
  } = useFollowing(username);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState<boolean>(false);
  const { isLoading: isLoadingSubscriptionStatus, subscriptionStatus } =
    useSubscriptionStatus(username);
  const { isSignedIn } = useUser();
  const { isUnsubscribing } = useUnsubscribeModal();
  const intl = useIntl();
  const isSubscribed = moment().isBefore(moment(subscriptionStatus?.time_end));
  const isSubscriptionPendingCancelation = isSubscribed && !subscriptionStatus?.is_auto_renew;
  const media = useScreenSize();

  const { setIsOpen, setArtistUsername, setArtistDisplayName } = useSubscriptionModalWeb();

  const isLoadingSubscribed = useMemo(
    () => isLoadingSubscriptionStatus || isUnsubscribing,
    [isLoadingSubscriptionStatus, isUnsubscribing],
  );

  const onFollowPress = async () => {
    if (followingStatus?.is_following) {
      await unfollow();
    } else {
      await follow();
    }
  };

  const onSubscribePress = () => {
    setArtistUsername(fullArtist.username);
    setArtistDisplayName(displayName);
    setIsOpen(true);
  };

  const onNextPress = () => {
    setIsScrolledToEnd(true);
    ref.current.scrollToEnd();
  };

  const onPreviousPress = () => {
    setIsScrolledToEnd(false);
    ref.current.scrollToIndex({ index: 0 });
  };

  const renderFeedItem = ({ item }: { item: unknown }): JSX.Element => {
    const typedItem = item as ArtistProduct;
    const previewImage = pickPreviewImage(typedItem);

    return (
      <ProductPreviewWrapper>
        <ProductPreview
          cover={previewImage}
          hasAccess={typedItem.has_access}
          imageUrl={previewImage.url}
          imageWidth={previewImage.width}
          imageHeight={previewImage.height}
          type={typedItem.model_name}
          question={typedItem.question}
          onPress={() => onProductPress?.(typedItem)}
        />
      </ProductPreviewWrapper>
    );
  };

  return (
    <View {...rest}>
      <Wrapper>
        <Header>
          <Avatar
            mode="minimal-row"
            name={displayName}
            imageUrl={getProfileLowResPictureUrl(fullArtist)}
            isLive={isLive}
            onPress={() => onArtistPress?.(fullArtist)}
            isPlaceholder={isLoading}
          />
          {Platform.OS === 'web' && (
            <ButtonsView>
              {isSignedIn && onSubscribePress && (
                <SubscribeButton
                  disabled={isSubscribed}
                  icon={
                    !isLoadingSubscribed && !isSubscriptionPendingCancelation
                      ? isSubscribed
                        ? IconCheckSubscribed
                        : BecomeStanIcon
                      : undefined
                  }
                  processing={isLoadingSubscribed}
                  onPress={onSubscribePress}
                  iconAlign={isSubscribed ? 'left' : 'right'}
                >
                  {media.tablet &&
                    intl.formatMessage(
                      isSubscriptionPendingCancelation
                        ? messages.pendingCancelation
                        : isSubscribed
                        ? messages.subscribed
                        : messages.subscribe,
                    )}
                </SubscribeButton>
              )}
              {isSignedIn && !isSubscribed && onFollowPress && (
                <FollowButton
                  secondary={!followingStatus?.is_following}
                  icon={
                    !isLoadingFollowing && followingStatus?.is_following
                      ? IconCheckFollowed
                      : undefined
                  }
                  processing={isLoadingFollowing}
                  onPress={onFollowPress}
                >
                  {intl.formatMessage(
                    followingStatus?.is_following ? messages.following : messages.follow,
                  )}
                </FollowButton>
              )}
            </ButtonsView>
          )}
        </Header>
        <ListView>
          <FlatList
            ref={ref}
            data={featuredFeed}
            keyExtractor={extractKey}
            renderItem={renderFeedItem}
            ItemSeparatorComponent={() => <Separator />}
            initialNumToRender={3}
            showsHorizontalScrollIndicator={false}
            horizontal
          />
        </ListView>
        {featuredFeed.length > 3 && !isScrolledToEnd && <NextButton onPress={onNextPress} />}
        {isScrolledToEnd && featuredFeed.length > 3 && <BackButton onPress={onPreviousPress} />}
        <Shadow />
      </Wrapper>
    </View>
  );
};

ArtistFeedList.defaultProps = {
  maxItemsCount: 5,
};

ArtistFeedList.displayName = 'ArtistFeedList';

/**
 * Component description TODO
 */
const ArtistFeedListMemo: React.FC<ArtistFeedProps> = memo(ArtistFeedList);

export default ArtistFeedListMemo;
