import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Text from 'components/Text';

export default styled(Text)`
  text-align: center;
  margin: 0 0 10px 0;
  ${Platform.OS === 'ios' &&
  css`
    position: relative;
    top: 30px;
    margin: 0;
  `};
`;
