import styled from 'styled-components/native';

import { DialogButton } from 'components/Button';

export default styled(DialogButton).attrs(() => ({
  labelStyle: {
    fontSize: 13,
    lineHeight: 18,
  },
}))`
  margin-top: 15px;
`;
