import { ArtistProduct } from 'types/ArtistProduct';

export interface InsightsGraphPoint {
  date: string;
  amount: string;
}

export interface InsightsGraph {
  amount_currency: string;
  growth: string;
  start_date: string;
  end_date: string;
  previous_period_start_date: string;
  previous_period_end_date: string;
  points: InsightsGraphPoint[];
  total_amount: string;
}

export interface ProductWithInsights extends ArtistProduct {
  total_views_count: number;
  total_revenue_amount: string;
  total_revenue_amount_currency: string;
}

export enum InsightsDayRange {
  AllTime = '0',
  Yesterday = '1',
  LastWeek = '7',
  LastMonth = '28',
  Last90Days = '90',
}

export interface ProfileInsights {
  start_date: string;
  end_date: string;
  new_subscribers_count: number;
  total_subscribers_count: number;
  new_subscribers_growth: string;
  followers_changes_count: number;
  total_followers_count: number;
  followers_changes_growth: string;
  subscription_revenue_line_graphs: InsightsGraph[];
  tip_revenue_line_graphs: InsightsGraph[];
  marketplace_revenue_line_graphs: InsightsGraph[];
  highest_revenue_product: ProductWithInsights;
  highest_engagement_product: ProductWithInsights;
}

export interface PostEngagement {
  average_duration: number;
  average_duration_in_percent: number;
  full_duration: number;
}

export interface PostGeneralInsights {
  likes_count: number;
  comments_count: number;
  bookmarks_count: number;
  shares_count: number;
}

export interface PostReach {
  views_count: number;
  percentage_of_views_count_that_are_neither_follower_or_subscriber: number;
  subscriber_views_count: number;
  percentage_of_views_coming_from_subscriber: number;
  followers_views_count: number;
  percentage_of_views_coming_from_follower: number;
}

export interface PostRevenue {
  total_tip_revenue: string;
  average_tip: string;
  tips_count: number;
  new_subscriptions: number;
  subscriptions_revenue: string;
}
