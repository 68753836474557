import styled from 'styled-components/native';

import { OrderStatusTranslation } from 'types';

export default styled.View<{ status: OrderStatusTranslation }>`
  align-self: ${({ theme }) => (theme.media.tablet ? 'flex-end' : 'flex-start')};
  background-color: ${({ status, theme }) => (theme.orderStatus as any)[`${status}Bg`]};
  border-radius: 6px;
  padding: 3px 7px 4px;
`;
