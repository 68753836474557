import packageJson from 'package.json';

type VariantType = 'fan' | 'artist';

export const VARIANT: VariantType = process.env.VARIANT === 'artist' ? 'artist' : 'fan';

export const VERSION = packageJson.version;

export function useAppInfo(): {
  variant: VariantType;
  version: string;
} {
  return {
    variant: VARIANT,
    version: VERSION,
  };
}
