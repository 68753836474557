import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.View`
  flex-direction: row;
  ${mediaWeb(css`
    margin-left: 12px;
  `)}
`;
