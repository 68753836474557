import styled, { css } from 'styled-components/native';

import FullscreenButton from 'components/FullscreenButton';

interface Props {
  showControlsBottom?: boolean;
  isFullscreen?: boolean;
}

export default styled(FullscreenButton)<Props>`
  position: absolute;
  left: 7px;

  ${({ showControlsBottom, isFullscreen }) =>
    showControlsBottom
      ? css`
          left: 15px;
          bottom: ${isFullscreen ? '55px' : '15px'};
        `
      : css`
          top: 7px;
        `}
`;
