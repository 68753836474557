export default {
  openMailAppCta: {
    id: 'mailSent.openMailAppCta',
    defaultMessage: 'Open Mail app',
  },
  closeCta: {
    id: 'mailSent.close',
    defaultMessage: 'Close',
  },
};
