import * as React from 'react';
import { Platform } from 'react-native';

import { OrderStatus } from 'types';
import { parseItemPrice, translateMarketplaceOrderStatus } from 'utils/marketplace';

import ArtistName from './styled/ArtistName';
import Container from './styled/Container';
import HighlightMarker from './styled/HighlightMarker';
import HighlightWrapper from './styled/HighlightWrapper';
import ImagePreview from './styled/ImagePreview';
import ImageWrapper from './styled/ImageWrapper';
import InfoWrapper from './styled/InfoWrapper';
import OrderName from './styled/OrderName';
import OrderPrice from './styled/OrderPrice';
import OrderStatusBadge from './styled/OrderStatusBadge';

export interface OrderPreviewProps {
  /** The status of the order */
  status?: OrderStatus;
  /** The preview image url for the order */
  previewImage?: string;
  /** Artist display name */
  artistName: string;
  /** The order name */
  name: string;
  /** The order price */
  price: string;
  /** The currency of order price */
  currency: string;
  /** Is order updated */
  isUpdated?: boolean;
  /** Turn off marker */
  withoutMarker?: boolean;
  /** Is order waiting for stan's confirmation */
  waitingForConfirmation?: boolean;
  imageSize: string;
  fontSizes?: {
    artist?: number;
    name?: number;
    price?: number;
  };
}

/**
 * Orders preview in Marketplace module
 */

const OrderPreview: React.FC<OrderPreviewProps> = ({
  artistName,
  isUpdated,
  name,
  price,
  currency,
  previewImage,
  status,
  withoutMarker,
  waitingForConfirmation,
  imageSize,
  fontSizes,
  ...rest
}: OrderPreviewProps) => {
  return (
    <Container {...rest} size={imageSize}>
      {!withoutMarker && status && (
        <HighlightWrapper>
          <HighlightMarker status={translateMarketplaceOrderStatus(status)} isActive={isUpdated} />
        </HighlightWrapper>
      )}
      <ImageWrapper size={imageSize}>
        <ImagePreview source={{ uri: previewImage }} />
      </ImageWrapper>
      <InfoWrapper status={status}>
        {status && (
          <OrderStatusBadge status={status} waitingForConfirmation={waitingForConfirmation} />
        )}
        <ArtistName size={fontSizes?.artist}>{artistName}</ArtistName>
        <OrderName size={fontSizes?.name} large={Platform.OS === 'web'}>
          {name}
        </OrderName>
        <OrderPrice size={fontSizes?.price}>{parseItemPrice(price, currency)}</OrderPrice>
      </InfoWrapper>
    </Container>
  );
};

OrderPreview.displayName = 'OrderPreview';

export default OrderPreview;
