import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME } from './constants';
import View from './styled/View';

export { SCREEN_NAME };

type LiveEventBookingsScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type LiveEventBookingsScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: LiveEventBookingsScreenRouteProp;
  navigation: LiveEventBookingsScreenNavigationProp;
};

const LiveEventBookingsScreen: React.FC<Props> = () => {
  return <View></View>;
};

export default LiveEventBookingsScreen;
