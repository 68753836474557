export default {
  title: {
    id: 'join.stanbase.title',
    defaultMessage: 'Join Stanbase! 🎉',
  },
  description: {
    id: 'join.stanbase.description',
    defaultMessage: 'Get access to exclusive content from your favourite artists.',
  },
  buttonText: {
    id: 'join.stanbase.button.text',
    defaultMessage: 'Use your e-mail address',
  },
  ctaText: {
    id: 'join.stanbase.cta.text',
    defaultMessage: 'Already a Stan? <cta>Log in!</cta>',
  },
  disclaimer: {
    id: 'join.stanbase.disclaimer',
    defaultMessage:
      "By taping Continue with Apple, Google, Facebook or Use your e-mail address I agree to Stanbase's <termsCta>Terms of use,</termsCta> <paymentTermsCta>Payment Terms of Service,</paymentTermsCta> <privacyPolicyCta>Privacy Policy</privacyPolicyCta> and <nondiscriminationPolicyCta>Nondiscrimination Policy</nondiscriminationPolicyCta>.",
  },
};
