import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function IconHelp(props: SvgProps): JSX.Element {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.09009 8.99996C9.32519 8.33163 9.78924 7.76807 10.4 7.40909C11.0108 7.05012 11.729 6.9189 12.4273 7.03867C13.1255 7.15844 13.7589 7.52148 14.2152 8.06349C14.6714 8.60549 14.9211 9.29148 14.9201 9.99996C14.9201 12 11.9201 13 11.9201 13"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 17H12.01"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
