import styled from 'styled-components/native';

export default styled.Text`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  font-family: 'BasierCircle-Regular';
  color: ${({ theme }) => theme.colors.text};
  margin: 20px 0 30px 0;
`;
