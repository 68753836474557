import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({
  short: true,
  bold: false,
})`
  border-radius: 40px;
  height: 44px;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 0;
  margin-top: 16px;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      margin-left: 16px;
      margin-top: 0;
      width: 216px;
    `}
`;
