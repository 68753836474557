export default {
  title: {
    id: 'support.form.title',
    defaultMessage: 'Customer support',
  },
  subtitle: {
    id: 'support.form.subtitle',
    defaultMessage:
      'Support is available Monday-Friday 08:00-17:00 CET\n' +
      '\n' +
      'We will come back to you as soon as possible',
  },
  back: {
    id: 'support.form.back',
    defaultMessage: 'Back',
  },
  emailInput: {
    placeholder: {
      id: 'support.form.emailInput.placeholder',
      defaultMessage: 'Your e-mail address',
    },
    error: {
      required: {
        id: 'support.form.emailInput.error.required',
        defaultMessage: 'E-mail address is required',
      },
      invalid: {
        id: 'support.form.emailInput.error.invalid',
        defaultMessage: 'E-mail address is invalid',
      },
    },
  },
  subjectInput: {
    placeholder: {
      id: 'support.form.subjectInput.placeholder',
      defaultMessage: 'Subject',
    },
    error: {
      required: {
        id: 'support.form.subjectInput.error.required',
        defaultMessage: 'Subject is required',
      },
    },
  },
  messageInput: {
    placeholder: {
      id: 'support.form.messageInput.placeholder',
      defaultMessage: 'Description',
    },
    error: {
      required: {
        id: 'support.form.messageInput.error.required',
        defaultMessage: 'Description is required',
      },
    },
  },
  send: {
    id: 'support.form.send',
    defaultMessage: 'Send to support',
  },
  snackbar: {
    sent: {
      id: 'support.form.snackbar.sent',
      defaultMessage: 'Message sent!',
    },
    error: {
      id: 'support.form.snackbar.error',
      defaultMessage: 'Please try again',
    },
    close: {
      id: 'support.form.snackbar.close',
      defaultMessage: 'Close',
    },
  },
};
