export default {
  title: {
    id: 'moreOptions.report.title',
    defaultMessage: 'Report',
  },
  titleSuccess: {
    id: 'moreOptions.report.titleSuccess',
    defaultMessage: 'Thanks for letting us know!',
  },
  description: {
    id: 'moreOptions.report.description',
    defaultMessage:
      'Your report will be sent anonymously. If you see that someone is in danger, call the local emergency service.',
  },
  block: {
    title: {
      id: 'moreOptions.block.title',
      defaultMessage: 'Block',
    },
    description: {
      id: 'moreOptions.block.description',
      defaultMessage:
        'After blocking you will not see their comments and other activity on Stanbase. They won’t be notified about the blocking.',
    },
    snackbar: {
      id: 'moreOptions.block.snackbar',
      defaultMessage:
        'has been blocked.\nYou can unblock them at any time from your profile settings.',
    },
    error: {
      id: 'moreOptions.block.error',
      defaultMessage: 'Error occurred while blocking user.',
    },
  },
  closeSnackbar: {
    id: 'moreOptions.closeSnackbar',
    defaultMessage: 'Close',
  },
  success: {
    id: 'moreOptions.report.descriptionSuccess',
    defaultMessage:
      'Your feedback is important to help us keep the Stanbase community a safe space.',
  },
  share: {
    id: 'moreOptions.share.button',
    defaultMessage: 'Share...',
  },
  cancel: {
    id: 'moreOptions.cancel.button',
    defaultMessage: 'Cancel',
  },
  options: {
    spam: {
      id: 'moreOptions.report.option.spam',
      defaultMessage: "It's spam",
    },
    not_appropriate: {
      id: 'moreOptions.report.option.not_appropriate',
      defaultMessage: "It's inappropriate",
    },
  },
};
