import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { StatusBar } from 'react-native';

import { useSnackbarSet, useSnackbarState } from 'context';

import CtaLabel from './styled/CtaLabel';
import Fade from './styled/Fade';
import Image from './styled/Image';
import Label from './styled/Label';
import PollSymbol from './styled/PollSymbol';

const SnackbarWrapper: React.FC = () => {
  const { visible, actionLabel, actionOnPress, postType, thumbnailUri, label } = useSnackbarState();
  const { setSnackbarVisible } = useSnackbarSet();

  return (
    <Fade
      visible={visible}
      style={{
        top:
          Platform.OS === 'android' && StatusBar.currentHeight ? 20 + StatusBar.currentHeight : 20,
      }}
      onSwipeOut={() => setSnackbarVisible(false)}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center', height: '100%' }}>
        {thumbnailUri && (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {['audio', 'video'].includes(postType || '') && (
              <AntDesign
                style={{ position: 'absolute', zIndex: 1 }}
                color="#fff"
                name="playcircleo"
                size={16}
              />
            )}
            <Image
              blurRadius={['audio', 'poll'].includes(postType || '') ? 10 : 0}
              style={{ backgroundColor: '#000' }}
              source={{ uri: thumbnailUri }}
            />
            {['poll'].includes(postType || '') && <PollSymbol />}
          </View>
        )}
        <View style={!thumbnailUri && { width: '80%' }}>
          <Label style={!thumbnailUri && { marginLeft: 15 }}>{label}</Label>
        </View>
        <View style={{ flex: 1 }} />
        {actionLabel && (
          <TouchableOpacity
            onPress={() => {
              setSnackbarVisible(false);
              actionOnPress?.();
            }}
          >
            <CtaLabel style={{ marginRight: 15 }}>{actionLabel}</CtaLabel>
          </TouchableOpacity>
        )}
      </View>
    </Fade>
  );
};

export default SnackbarWrapper;
