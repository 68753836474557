import React from 'react';
import styled from 'styled-components/native';

import { milliSecondsToTimeString } from 'utils/time';

const Text = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

interface Props {
  positionMillis: number;
}

const Time: React.FC<Props> = ({ positionMillis }: Props) => {
  return <Text>{milliSecondsToTimeString(positionMillis)}</Text>;
};

export default Time;
