import * as React from 'react';
import { FC, useEffect } from 'react';
import { Platform } from 'react-native';
import { useAnimatedProps, useSharedValue, withTiming } from 'react-native-reanimated';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { AnimatedPath } from '../../Animated';

interface CheckmarkSvgProps {
  isAudioFinished: boolean;
  wasAudioPlayed: boolean;
}

const dimmedOpacity = 0.23;

const CheckmarkSvg: FC<CheckmarkSvgProps & SvgProps> = ({
  isAudioFinished,
  wasAudioPlayed,
  ...rest
}: CheckmarkSvgProps) => {
  const opacity1 = useSharedValue(wasAudioPlayed ? 1 : dimmedOpacity);
  const opacity2 = useSharedValue(isAudioFinished ? 1 : dimmedOpacity);

  useEffect(() => {
    opacity1.value = wasAudioPlayed ? 1 : dimmedOpacity;
  }, [opacity1, wasAudioPlayed]);

  useEffect(() => {
    opacity2.value = isAudioFinished ? 1 : dimmedOpacity;
  }, [isAudioFinished, opacity2]);

  const props1 = useAnimatedProps(() => ({
    strokeOpacity: withTiming(opacity1.value, { duration: 300 }),
  }));

  const props2 = useAnimatedProps(() => ({
    strokeOpacity: withTiming(opacity2.value, { duration: 300 }),
  }));

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      {Platform.OS === 'web' ? (
        <>
          <Path
            d="M2 13L6 17L16 5"
            stroke="white"
            strokeOpacity={wasAudioPlayed ? '1' : '0.23'}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M11 16L12 17L22 5"
            stroke="white"
            strokeOpacity={isAudioFinished ? '1' : '0.23'}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <AnimatedPath
            d="M2 13L6 17L16 5"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            animatedProps={props1}
          />
          <AnimatedPath
            d="M11 16L12 17L22 5"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            animatedProps={props2}
          />
        </>
      )}
    </Svg>
  );
};

export default styled(CheckmarkSvg)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 33px;
  bottom: 46px;
`;
