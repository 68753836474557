import styled, { css } from 'styled-components/native';

import CachedImage from 'components/CachedImage';

interface PersonProps {
  width: number;
  height: number;
  bottom: number;
  right: number;
  isStancastingScreen?: boolean;
}

export default styled(CachedImage)<PersonProps>`
  position: absolute;
  ${({ width, height, bottom, right }) => css`
    width: ${width}px;
    height: ${height}px;
    bottom: ${bottom}%;
    right: ${right}%;
  `}

  border-radius: ${({ isStancastingScreen }) => (isStancastingScreen ? 11 : 100)}px;
`;
