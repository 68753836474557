import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import styled from 'styled-components/native';

import { ProductType } from 'types';

interface Props {
  type: ProductType | 'preview';
}

const getGradientProps = (type: ProductType | 'preview'): LinearGradientProps => {
  switch (type) {
    case 'audio':
      return {
        colors: ['rgba(5, 55, 255, 0)', 'rgba(5, 55, 255, 0.42)', 'rgba(5, 55, 255, 1)'],
        start: { x: 0, y: 0 },
        end: { x: 0, y: 1 },
        locations: [0, 0.64, 1],
      };
    case 'poll':
      return {
        colors: ['rgba(5, 55, 255, 0)', 'rgba(5, 55, 255, 0.42)', 'rgba(5, 55, 255, 1)'],
        start: { x: 0, y: 0 },
        end: { x: 0, y: 1 },
        locations: [0, 0.64, 1],
      };
    default:
      return {
        colors: ['rgba(5, 55, 255, 0)', 'rgba(5, 55, 255, 0.42)', 'rgba(5, 55, 255, 1)'],
        start: { x: 0, y: 0 },
        end: { x: 0, y: 1 },
      };
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default styled(LinearGradient).attrs(({ type }) => getGradientProps(type))<Props>`
  position: absolute;
  width: 100%;
  height: 100%;
`;
