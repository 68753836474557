import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { useBackEnd, useCardinalState, WebviewContext } from 'context';
import { useCvvModal } from 'context/cvvModal';
import { PaymentFlow, PaymentType, ProductTypePlural } from 'types';

import messages from './messages/apexx';

import { Cardinal } from 'native_modules';

interface GetJwtTokenProps {
  payment_type: PaymentType;
  artist_username: string;
  model_name_plural?: ProductTypePlural;
  product_id?: number;
  amount?: string;
  marketplace_order_external_id?: string;
}

interface EnrollProps {
  payment_type: PaymentType;
  external_id: string;
  artist_username: string;
  model_name_plural?: string;
  product_id?: number;
  credit_card_external_id: string;
  cardinal_session_id: string;
}

interface VerifyProps {
  payment_type: PaymentType;
  external_id: string;
  artist_username: string;
  model_name_plural?: string;
  product_id?: number;
  pares_status: string;
  eci: string;
  cavv: string;
  processor_transaction_id: string;
  cardinal_jwt: string;
}

type Props = GetJwtTokenProps & {
  credit_card_external_id: string;
};

export const useApexx = () => {
  const intl = useIntl();
  const { axiosInstance } = useBackEnd();
  const { cvv, setCvv } = useCvvModal();
  const { init, encrypt, cca_continue } = Cardinal;
  const { cardinalWebInit = () => {}, cardinalWebCCA = () => {} } =
    Platform.OS === 'web' ? useCardinalState() : {};
  const { openWebView, currentUrl, setIsWebviewOpen, isWebviewOpen } = useContext(WebviewContext);
  const [isPaying, setIsPaying] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<{ message?: string; details?: string }>();
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>();
  const [is3dsv1, setIs3dsv1] = useState<boolean>(false);
  const externalIdRef = useRef<string>();

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (
        !isWebviewOpen &&
        !(process.env.API_URL && currentUrl?.includes(process.env.API_URL)) &&
        isPaying
      ) {
        setIsPaying(false);
        setIs3dsv1(false);
      }
    }
  }, [isWebviewOpen]);

  useEffect(() => {
    const timeout = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const finalizePayment = async () => {
      if (is3dsv1) {
        console.log('finalizing', isPaying);
        if (
          (Platform.OS !== 'web' &&
            process.env.API_URL &&
            currentUrl?.includes(process.env.API_URL)) ||
          (Platform.OS === 'web' && isPaying)
        ) {
          if (Platform.OS !== 'web') {
            setIsWebviewOpen(false);
          }

          const maxRepeat = 40;
          for (let i = 1; i <= maxRepeat; i++) {
            console.log('getting status', externalIdRef.current);
            let res;
            try {
              res = await axiosInstance.get(`/payments/${externalIdRef.current}/3dsv1/status`);
            } catch (e: any) {
              console.log('error:', e?.response?.data);
            }
            console.log('payment status:', res?.data.status);
            if (res?.data.status === 'CONFIRMED') {
              setPaymentSuccess(true);
              setIsPaying(false);
              setIs3dsv1(false);
              break;
            } else if (i === maxRepeat || ['DECLINED', 'FAILED'].includes(res?.data.status)) {
              setPaymentSuccess(false);
              setIsPaying(false);
              setIs3dsv1(false);
              break;
            }
            if (!isPaying) {
              setIs3dsv1(false);
              break;
            }
            await timeout(3000);
          }
        }
      }
    };

    finalizePayment();
  }, [currentUrl, isPaying, is3dsv1]);

  const getJwtToken = async (payload: GetJwtTokenProps) => {
    try {
      const createJwtResponse: {
        data: {
          encoded_cardinal_jwt: string;
          external_id: string;
        };
      } = await axiosInstance.post('/payments/3ds_initialization', payload);
      return createJwtResponse.data;
    } catch (e: any) {
      console.log('jwt', e?.response?.data);
      return null;
    }
  };

  const getSessionId = async (encoded_cardinal_jwt: string) => {
    return Platform.OS === 'web'
      ? await cardinalWebInit(encoded_cardinal_jwt)
      : await init(encoded_cardinal_jwt);
  };

  const enroll = async (data: EnrollProps) => {
    try {
      console.log('enroll with cvv: ' + cvv);
      const enrollResponse: {
        data: {
          external_id: string;
          pareq: string;
          acs_url: string;
          authentication_transaction_id: string;
          flow: PaymentFlow;
        };
      } = await axiosInstance.post(`/payments/3ds_enrol`, {
        ...data,
        cvv: cvv,
      });
      if (enrollResponse.data) {
        setCvv('');
      }
      return enrollResponse.data;
    } catch (e: any) {
      console.log('enroll', e?.response?.data);
      return e?.response?.data;
    }
  };

  const verify = async (data: VerifyProps) => {
    try {
      await axiosInstance.post(`/payments/3ds_verify`, {
        ...data,
      });
      return true;
    } catch (e: any) {
      console.log('verify', e?.response?.data);
      return null;
    }
  };

  const payWithApexx = async (data: Props) => {
    setIsPaying(true);
    setPaymentSuccess(undefined);
    setPaymentError(undefined);
    setIs3dsv1(false);

    const {
      artist_username,
      amount,
      product_id,
      payment_type,
      marketplace_order_external_id,
      model_name_plural,
      credit_card_external_id,
    } = data;

    const createJwtData = await getJwtToken({
      artist_username,
      amount,
      product_id,
      payment_type,
      marketplace_order_external_id,
      model_name_plural,
    });

    if (!createJwtData?.encoded_cardinal_jwt || !createJwtData?.external_id) {
      setPaymentSuccess(false);
      setIsPaying(false);
      setPaymentError({
        message: intl.formatMessage(messages.error.default),
        details: 'jwt create error',
      });
      return;
    }
    console.log('jwt response:', createJwtData);
    const { encoded_cardinal_jwt, external_id } = createJwtData;

    const sessionId: string = await getSessionId(encoded_cardinal_jwt);
    console.log('sessionId:', sessionId);

    const enrollData = await enroll({
      payment_type,
      artist_username,
      cardinal_session_id: sessionId,
      credit_card_external_id,
      external_id: external_id,
      model_name_plural,
      product_id,
    });

    if (enrollData.code) {
      setPaymentSuccess(false);
      setIsPaying(false);
      const { code } = enrollData;
      if (code === 'retry_after_some_time_or_try_another_card') {
        setPaymentError({
          message: intl.formatMessage(messages.error.tryAgain),
          details: 'enroll error',
        });
        return;
      } else if (code === 'not_eligible_for_3ds') {
        setPaymentError({
          message: intl.formatMessage(messages.error.useAnotherCard),
          details: 'enroll error',
        });
        return;
      } else {
        setPaymentError({
          message: intl.formatMessage(messages.error.default),
          details: 'enroll error',
        });
        return;
      }
    }
    console.log('enroll response:', enrollData);

    if (enrollData.flow === 'frictionless') {
      setPaymentSuccess(true);
      setIsPaying(false);
    } else if (enrollData.flow === 'challenge') {
      let authorizationResponse;
      try {
        authorizationResponse =
          Platform.OS === 'web'
            ? await cardinalWebCCA({
                authentication_transaction_id: enrollData.authentication_transaction_id,
                pareq: enrollData.pareq,
                acs_url: enrollData.acs_url,
              })
            : await cca_continue(enrollData.authentication_transaction_id, enrollData.pareq);
        console.log('authorizationResponse:', authorizationResponse);
      } catch (e: any) {
        console.log('challenge flow error:', JSON.stringify(e));
        setPaymentError({ message: intl.formatMessage(messages.error.default), details: e });
        setPaymentSuccess(false);
        setIsPaying(false);
        return;
      }

      const { paResStatus, eciFlag, cavv } = authorizationResponse;
      const verificationData = await verify({
        external_id: external_id,
        artist_username,
        payment_type,
        cardinal_jwt: encoded_cardinal_jwt,
        cavv,
        eci: eciFlag,
        model_name_plural,
        pares_status: paResStatus,
        processor_transaction_id: enrollData.authentication_transaction_id,
        product_id,
      });

      if (!verificationData) {
        setPaymentError({
          message: intl.formatMessage(messages.error.default),
          details: 'verification error',
        });
        setPaymentSuccess(false);
        setIsPaying(false);
        return;
      }

      console.log('verificationResponse:', verificationData);
      setPaymentSuccess(true);
      setIsPaying(false);
    } else if (enrollData.flow === 'fallback_to_3dsv1') {
      setIs3dsv1(true);
      externalIdRef.current = enrollData.external_id;

      if (Platform.OS !== 'web') {
        openWebView?.(
          enrollData.acs_url,
          'POST',
          `PaReq=${encodeURIComponent(enrollData.pareq)}&TermUrl=${encodeURIComponent(
            enrollData.term_url,
          )}`,
        );
      } else {
        const redirectForm: any = document.createElement('form');
        redirectForm.name = 'redirectForm';
        redirectForm.id = 'redirectForm';
        redirectForm.method = 'POST';
        redirectForm.setAttribute('target', '_blank');
        redirectForm.action = enrollData.acs_url;

        const pareqInput: any = document.createElement('input');
        pareqInput.type = 'hidden';
        pareqInput.name = 'PaReq';
        pareqInput.id = 'PaReq';
        pareqInput.value = enrollData.pareq;
        redirectForm.appendChild(pareqInput);

        const termUrlInput: any = document.createElement('input');
        termUrlInput.type = 'hidden';
        termUrlInput.name = 'TermUrl';
        termUrlInput.id = 'TermUrl';
        termUrlInput.value = enrollData.term_url;
        redirectForm.appendChild(termUrlInput);
        document.body.appendChild(redirectForm);

        redirectForm.submit();
      }
    }
  };

  return {
    payWithApexx,
    paymentSuccess,
    paymentError,
    isPaying,
    setPaymentSuccess,
    setPaymentError,
  };
};
