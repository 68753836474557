import {
  getActionFromState,
  getStateFromPath,
  NavigationContainerRef,
} from '@react-navigation/core';
import { LinkingOptions } from '@react-navigation/native';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Linking, Platform } from 'react-native';

import { SCREEN_NAME as LOGIN_STACK_SCREEN_NAME } from 'stacks/common/LoginStack/constants';

import { SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME } from 'screens/LoginWithEmailScreen/constants';
import { SCREEN_NAME as RESET_PASSWORD_SCREEN_NAME } from 'screens/ResetPasswordScreen/constants';

import { useBackEnd } from 'context/backEnd';
import { getQueryParam, getRouteFromDeeplink } from 'utils/path';

export function useRestoreInitialURL(
  navigation: MutableRefObject<NavigationContainerRef | null>,
  linking: LinkingOptions,
): void {
  const { accessToken } = useBackEnd();
  const initialUrlRef = useRef<string | null>();
  console.log(navigation);

  useEffect(() => {
    (async () => {
      initialUrlRef.current = await Linking.getInitialURL();

      // deeplink fix for app closed
      const { route, link } = await getRouteFromDeeplink(linking);

      if (route === 'auth/login/email') {
        navigation?.current?.navigate(LOGIN_STACK_SCREEN_NAME, {
          screen: LOGIN_WITH_EMAIL_SCREEN_NAME,
        });
      } else if (route === 'auth/reset-password') {
        navigation?.current?.navigate(LOGIN_STACK_SCREEN_NAME, {
          screen: RESET_PASSWORD_SCREEN_NAME,
          params: { token: getQueryParam(link, 'token') },
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (navigation.current && accessToken && initialUrlRef.current) {
      console.log('[navigation] restoring URL', initialUrlRef.current);
      let path = initialUrlRef.current.startsWith('http')
        ? initialUrlRef.current.replace(/^.+?[^/:](?=[?/]|$)/g, '')
        : initialUrlRef.current.replace(/.*?:\/\//g, '');
      if (path.indexOf('?') !== -1) {
        path = path.substring(0, path.indexOf('?'));
      }
      if (Platform.OS === 'web') {
        path = path.replace(location.host, '');
      }

      if (!linking.config?.screens) {
        return;
      }

      const config = { screens: linking.config.screens };

      let state = getStateFromPath(path, config);
      console.log('[navigation] config', config);
      console.log('[navigation] path', path);
      console.log('[navigation] state', state);
      while (!state && path.indexOf('/') !== -1) {
        path = path.substring(0, path.lastIndexOf('/'));
        state = getStateFromPath(path, config);
        console.log('[navigation] path', path);
        console.log('[navigation] state', state);
      }

      if (!state) {
        return;
      }

      const action = getActionFromState(state, config);
      console.log('[navigation] action', action);

      if (!action) {
        return;
      }

      navigation.current.dispatch(action);

      initialUrlRef.current = null;
    }
  }, [accessToken]);
}
