import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  font-size: 34px;
  font-weight: bold;
  line-height: 41px;
  margin: 56px 0;
  text-align: center;
`;
