import styled from 'styled-components';

interface Props {
  url?: string;
}

export default styled.div<Props>`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0%;
  bottom: 0%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 37.14%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), url(${({ url }) => url});
  background-size: cover;
`;
