import styled, { css } from 'styled-components/native';

import { AvatarMode } from '../Avatar';

interface TimestampTextProps {
  mode: AvatarMode;
}

export default styled.Text<TimestampTextProps>`
  color: ${(props) => props.theme.colors.text};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  opacity: 0.64;
  margin: 2px 0 0 0;
  font-family: BasierCircle-Regular;

  ${(props) =>
    props.mode === 'row' &&
    css`
      margin: 0;
    `}
`;
