import React from 'react';
import { Modal as RNModal, Platform } from 'react-native';
import { Modal as WModal } from 'react-native-web';

import { useTabBarState } from 'context';

import ModalButton from './ModalButton';
import ModalButtonText from './ModalButtonText';
import ModalCard from './ModalCard';
import ModalView from './ModalView';

export interface OptionType {
  key: string;
  label: string;
  background?: string;
}

interface ModalProps {
  options?: Array<Array<OptionType>>;
  onOptionPress?: (option: OptionType) => void;
  modalVisible?: boolean;
  setModalVisible?: (value: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({
  options,
  onOptionPress,
  modalVisible,
  setModalVisible,
}: ModalProps) => {
  const tabBarState = useTabBarState();
  const Modal = Platform.OS === 'web' ? WModal : RNModal;
  const ModalBottomPadding = (tabBarState.height || 0) + 10;

  return (
    <Modal animationType="fade" transparent={true} visible={modalVisible}>
      <ModalView paddingBottom={ModalBottomPadding}>
        {options?.map((optionArr, index) => (
          <ModalCard key={index} style={{ marginTop: index > 0 ? 10 : 0 }}>
            {optionArr?.map((opt, index, arr) => (
              <ModalButton
                bg={opt?.background}
                key={index}
                style={{ borderBottomWidth: index < arr.length - 1 ? 1 : 0 }}
                onPress={() => {
                  setModalVisible?.(false);
                  onOptionPress?.({ key: opt.key, label: opt.label });
                }}
                pressedOpacity={0.8}
              >
                <ModalButtonText>{opt.label}</ModalButtonText>
              </ModalButton>
            ))}
          </ModalCard>
        ))}
        <ModalCard style={{ marginTop: 10 }}>
          <ModalButton
            solid
            style={{
              borderBottomWidth: 0,
            }}
            onPress={() => {
              setModalVisible?.(false);
            }}
            pressedOpacity={0.8}
          >
            <ModalButtonText>Cancel</ModalButtonText>
          </ModalButton>
        </ModalCard>
      </ModalView>
    </Modal>
  );
};

export default Modal;
