import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { milliSecondsToTimeString } from 'utils/time';

interface Props {
  width: number;
  duration: number;
}

const Label = styled.Text`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.5;
`;

const Divider = styled.View`
  height: 6px;
  border-left-color: ${({ theme }) => theme.colors.text};
  border-left-width: 0.5px;
  opacity: 0.5;
`;

const Timeline: React.FC<Props> = ({ width, duration }: Props) => {
  const labelWidth = 80;

  const getLabels = () => {
    const labels = [];
    const step = (labelWidth / width) * duration;
    for (let x = 0; x <= duration; x += step) {
      labels.push(milliSecondsToTimeString(x));
    }
    return labels;
  };

  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        {getLabels().map((l, index, arr) => {
          const isLast = index === arr.length - 1;
          let dividers = [1, 2, 3, 4];
          if (isLast) {
            const dw = labelWidth / dividers.length;
            const w = width % labelWidth;
            const dc = Math.floor(w / dw);
            const dvdrs = [];
            for (let x = 1; x <= dc; x++) {
              dvdrs.push(x);
            }
            dividers = [...dvdrs];
          }
          return (
            <View key={`label-${index}`}>
              <View style={{ flexDirection: 'row' }}>
                {dividers.map((d, i) => (
                  <Divider
                    key={`div-${i}`}
                    style={{ width: labelWidth / 4, height: i === 0 ? 12 : 6 }}
                  />
                ))}
              </View>
              <Label style={{ width: width / arr.length }}>{l}</Label>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Timeline;
