import styled, { css } from 'styled-components/native';

interface TextProps {
  large?: boolean;
}

export default styled.Text<TextProps>`
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  font-family: BasierCircle-Regular;

  ${({ large }) =>
    large &&
    css`
      font-size: 15px;
      line-height: 15px;
    `}
`;
