import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_POST_INSIGHTS_SCREEN_NAME } from 'screens/ArtistPostInsightsScreen';

import FeedItemVideoContent from 'components/FeedItem/styled/FeedItemVideoContent';
import SeeInsights from 'components/FeedItem/styled/SeeInsights';
import LiveMomentList from 'components/LiveMomentList/LiveMomentList';
import OverlayButton from 'components/OverlayButton';
import ViewersCountBadge from 'components/ViewersCountBadge';

import { VARIANT } from 'context';
import { useMoreOptionsSet } from 'context/moreOptions';
import { useTippingSheet } from 'context/tipping';
import { useArtist, useConfig } from 'hooks';
import { useLivestreamData, useViewerCount } from 'hooks/broadcast';
import { useMyLiveMoments } from 'hooks/liveMoments';
import { Tip } from 'svg';
import { LiveMoment } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import Avatar from './styled/Avatar';
import BottomBar from './styled/BottomBar';
import ContentView from './styled/ContentView';
import LiveStreamVideoPlayer from './styled/LiveStreamVideoPlayer';
import Paragraph from './styled/Paragraph';
import Row from './styled/Row';
import SafeAreaOverlay from './styled/SafeAreaOverlay';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const LiveStreamPlaybackScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const [isLoadingStream, setIsLoadingStream] = useState(true);
  const intl = useIntl();
  const [hasEnded, setHasEnded] = useState(false);
  const [didUserBackToFeed, setDidUserBackToFeed] = useState<boolean>(false);
  const setMoreOptions = useMoreOptionsSet();
  const { setIsOpen: setTippingSheetOpen, setProduct: setTippingProduct } = useTippingSheet();
  const {
    artistUsername,
    url,
    isLive: isLiveParam,
    past_audience,
    time_created,
    liveMomentId,
  } = route.params;
  const { m3u8_url } = useLivestreamData(artistUsername);
  const isLive = useMemo(() => isLiveParam || !!m3u8_url, [isLiveParam, m3u8_url]);
  const { viewerCount } = useViewerCount(artistUsername || '');
  const { config } = useConfig();
  const { liveMoments } = useMyLiveMoments();
  const deactivateRef = useRef<(() => void) | null>();
  const { artist } = useArtist(artistUsername || '');

  const onTipPress = useCallback(() => {
    if (!artist) {
      return;
    }
    setTippingSheetOpen(true);
    setTippingProduct({
      artistUsername: artist.username,
      productId: route.params.liveMomentId,
      type: 'tip',
      modelNamePlural: route.params.isPastLiveMoment ? 'past_live_streams' : 'live_streams',
    });
  }, [artist, setTippingSheetOpen, setTippingProduct]);

  const onMorePress = () =>
    setMoreOptions({
      itemType: VARIANT === 'fan' ? 'artist' : undefined,
      artistUsername: artist?.username,
      isMoreOptionsOpen: true,
      onClose: undefined,
    });

  const onClosePress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      setDidUserBackToFeed(true);
      deactivateKeepAwake();
    }
  };

  useEffect(() => {
    activateKeepAwake();
    return () => {
      deactivateKeepAwake();
    };
  }, []);

  const onStanCastArchivePress = (moment: LiveMoment) => {
    navigation.navigate(SCREEN_NAME, {
      artistUsername: artist?.username,
      url: moment?.mp4_url || '',
      isLive: false,
      isPastLiveMoment: true,
      liveMomentId: moment.id,
      past_audience: moment?.live_stream_viewers_count,
      time_created: moment.time_created,
    });
  };

  const refDeactivate = (deactivateFn: (() => void) | null) => {
    deactivateRef.current = deactivateFn;
  };

  const navigateToInsights = useCallback(() => {
    navigation.navigate(ARTIST_POST_INSIGHTS_SCREEN_NAME, {
      id: liveMomentId,
      model_name: isLive ? 'live_stream' : 'past_live_stream',
    });
  }, [navigation]);

  return (
    <View isWeb={Platform.OS === 'web'}>
      <ContentView>
        {past_audience !== undefined ? (
          <FeedItemVideoContent
            {...{
              playInBackground: !didUserBackToFeed,
              autoPlay: false,
              startMuted: false,
              showVideoTimestamp: true,
              showProgressBar: true,
              refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
                refDeactivate(deactivateFn),
            }}
            access={{ hasAccess: true }}
            coverImageUrl={
              artist?.medium_res_profile_picture ||
              artist?.low_res_profile_picture ||
              artist?.profile_picture
            }
            url={url}
            showOverlay
            showControls
            showControlsBottom
            showFullscreenButton
          />
        ) : (
          <LiveStreamVideoPlayer
            onLoaded={() => setIsLoadingStream(false)}
            onEnded={() => setHasEnded(true)}
            url={url || m3u8_url}
            isLive={isLive}
            cover={
              artist?.medium_res_profile_picture ||
              artist?.low_res_profile_picture ||
              artist?.profile_picture
            }
          />
        )}
        {isLoadingStream && past_audience === undefined && <ActivityIndicator animating />}
        {hasEnded && <Paragraph>{intl.formatMessage(messages.errorNoLongerLive)}</Paragraph>}
      </ContentView>
      {VARIANT === 'artist' && (
        <SeeInsights timestamp={time_created} onPress={navigateToInsights} />
      )}
      <BottomBar>
        <Row>
          <Avatar
            mode="row"
            imageUrl={getProfileLowResPictureUrl(artist)}
            name={artist?.display_name}
            showTimestamp={false}
            isLive
          />
          {VARIANT === 'fan' && config.tippingEnabled && (
            <TouchableOpacity onPress={onTipPress}>
              <Tip />
            </TouchableOpacity>
          )}
        </Row>
        {liveMoments && (
          <LiveMomentList
            data={liveMoments}
            withPlaceholder={false}
            onItemPress={onStanCastArchivePress}
          />
        )}
      </BottomBar>
      <SafeAreaOverlay pointerEvents="box-none">
        <Row>
          <OverlayButton icon="back" onPress={onClosePress} />
          {(isLive || past_audience !== undefined) && (
            <ViewersCountBadge
              past={past_audience !== undefined}
              count={past_audience || viewerCount}
            />
          )}
          <OverlayButton icon="more" onPress={onMorePress} />
        </Row>
      </SafeAreaOverlay>
    </View>
  );
};

export default LiveStreamPlaybackScreen;
