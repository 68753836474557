export default {
  title: {
    id: 'finishSignUp.title',
    defaultMessage: 'Complete Sign Up',
  },
  cta: {
    id: 'finishSignUp.cta',
    defaultMessage: 'Continue',
  },
  logoutCta: {
    id: 'finishSignUp.logoutCta',
    defaultMessage: 'Go back',
  },
  fullNameInput: {
    label: {
      id: 'finishSignUp.fullNameInput.label',
      defaultMessage: 'Full name',
    },
    placeholder: {
      id: 'finishSignUp.fullNameInput.placeholder',
      defaultMessage: 'Name Surname',
    },
    error: {
      required: {
        id: 'finishSignUp.fullNameInput.error.required',
        defaultMessage: 'A full name is required',
      },
      invalid: {
        id: 'finishSignUp.fullNameInput.error.invalid',
        defaultMessage: 'The full name provided seems invalid',
      },
    },
  },
  usernameInput: {
    label: {
      id: 'finishSignUp.usernameInput.label',
      defaultMessage: 'Username',
    },
    error: {
      required: {
        id: 'finishSignUp.usernameInput.error.required',
        defaultMessage: 'A username is required',
      },
      invalid: {
        id: 'finishSignUp.usernameInput.error.invalid',
        defaultMessage: 'The username provided seems invalid',
      },
    },
  },
  birthdayInput: {
    label: {
      id: 'finishSignUp.birthdayInput.label',
      defaultMessage: 'Birthday (dd/mm/yyyy)',
    },
    placeholder: {
      id: 'finishSignUp.birthdayInput.placeholder',
      defaultMessage: 'dd/mm/yyyy',
    },
    error: {
      required: {
        id: 'finishSignUp.birthdayInput.error.required',
        defaultMessage: 'A birthday is required',
      },
      invalid: {
        id: 'finishSignUp.birthdayInput.error.invalid',
        defaultMessage: 'The birthday provided seems invalid',
      },
    },
  },
  error: {
    token_invalid_or_expired: {
      id: 'finishSignUp.error.token.invalid',
      defaultMessage: 'Token is either invalid or expired',
    },
    unique: {
      id: 'finishSignUp.error.unique',
      defaultMessage: 'Username is already taken',
    },
    already_finished_sign_up: {
      id: 'finishSignUp.error.already_finished_sign_up',
      defaultMessage: 'You have already finished the sign up.',
    },
    reset_password_failed: {
      id: 'finishSignUp.error.failed',
      defaultMessage: 'Error has occurred.',
    },
  },
};
