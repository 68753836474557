import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { HelperText } from 'react-native-paper';
import styled, { css } from 'styled-components/native';

//todo: cleanup
export default memo(
  styled<typeof HelperText>(HelperText)`
    ${(props) =>
      props.type === 'info' &&
      css`
        color: black;
      `}
  `,
  isEqual,
);
