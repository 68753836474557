import styled, { css } from 'styled-components/native';

interface ContainerProps {
  isLast?: boolean;
}

export default styled.View<ContainerProps>`
  width: 100%;

  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 15px;
    `}
`;
