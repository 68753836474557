import React, { useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, StyleSheet, View } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { Easing } from 'react-native-reanimated';

import { useTheme } from 'themes';

import SafeArea from './SafeArea';

interface Props {
  children: React.ReactNode;
  visible: boolean;
  style: any;
  onSwipeOut: () => void;
}

const Fade: React.FC<Props> = ({ visible, style, children, onSwipeOut }: Props) => {
  const [shouldHide, setShouldHide] = useState(true);
  const visibility = useRef(new Animated.Value(visible ? 1 : 0)).current;
  const position = useRef(new Animated.Value(-30)).current;
  const { theme } = useTheme();

  useEffect(() => {
    Animated.timing(visibility, {
      toValue: visible ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start(({ finished }) => {
      if (finished && !visible) {
        Animated.timing(position, {
          toValue: -30,
          duration: 0,
          useNativeDriver: false,
          easing: Easing.linear,
        }).start();
        setShouldHide(true);
      }
    });
    if (visible) {
      setShouldHide(false);
      Animated.timing(position, {
        toValue: 10,
        duration: 300,
        useNativeDriver: false,
        easing: Easing.linear,
      }).start();
    }
  }, [visible]);

  const renderLeftActions = () => {
    return <View style={styles.leftAction} />;
  };

  return !shouldHide ? (
    <SafeArea
      style={{
        zIndex: 99,
        position: 'absolute',
        width: '100%',
        ...style,
      }}
    >
      <Swipeable renderLeftActions={renderLeftActions} onSwipeableLeftOpen={onSwipeOut}>
        <Animated.View
          style={{
            height: 60,
            backgroundColor: theme.snackbar.background,
            borderRadius: 5,
            opacity: visibility.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
            width: Dimensions.get('window').width - 20,
            marginLeft: position,
          }}
        >
          {children}
        </Animated.View>
      </Swipeable>
    </SafeArea>
  ) : null;
};

export default Fade;

const styles = StyleSheet.create({
  leftAction: {
    flex: 1,
  },
});
