import styled from 'styled-components/native';

export default styled.View`
  margin: auto;
  width: calc(100% - 15px);
  max-width: 600px;
  align-self: center;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 15px 60px;
`;
