import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const Icon: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M18.25 1.75L1.75 18.25"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.75 1.75L18.25 18.25"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const StyledIcon = styled(Icon)``;

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  top: 32px;
  right: 35px;
`;

interface Props {
  onPress: () => void;
}

const CloseIcon: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <StyledIcon />
    </TouchableOpacity>
  );
};

export default CloseIcon;
