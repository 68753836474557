import styled from 'styled-components/native';

export default styled.TouchableOpacity<{ selected: boolean }>`
margin: ${({ selected }) => (selected ? '0 8px 0 0' : '2px 10px 2px 2px ')}
  border-width: ${({ selected }) => (selected ? '2px' : '1px')};
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.background : theme.colors.primaryInactive};
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 20px 10px;
`;
