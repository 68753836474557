import React from 'react';

import Text from './styled/Text';
import View from './styled/View';

interface BadgeProps {
  text: string | number;
  large?: boolean;
  icon?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ text, large = false, icon, ...restProps }: BadgeProps) => {
  return (
    <View {...restProps} large={large}>
      {icon && icon}
      <Text large={large}>{text}</Text>
    </View>
  );
};

export default Badge;
