import { Fontisto } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

interface IconProps {
  color: string;
}

export default styled((props: any) => <Fontisto name="american-express" {...props} />).attrs({
  size: 14,
})<IconProps>``;
