import { FC } from 'react';
import {
  Easing,
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { AnimatedPressable, AnimatedSvg } from 'components/Animated';

interface IconProps {
  size?: number;
  isFocused?: boolean;
  onPress?: () => void;
}

const ICON_FOCUSED_COLOR = 'rgba(255, 255, 255, 1)';
const ICON_INACTIVE_COLOR = 'rgba(255, 255, 255, 0.52)';
const ICON_BG_FOCUSED_COLOR = 'rgba(255, 255, 255, 0.12)';
const ICON_BG_INACTIVE_COLOR = 'rgba(255, 255, 255, 0)';

const Pressable = styled(AnimatedPressable)`
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background-color: ${ICON_BG_FOCUSED_COLOR};
  border-radius: 8px;
`;

const AnimatedSvgWrapper: FC<IconProps & SvgProps> = ({
  size = 24,
  isFocused,
  onPress,
  children,
  ...rest
}) => {
  const iconColor = useDerivedValue(() => {
    return withTiming(isFocused ? 1 : 0, { duration: 300, easing: Easing.linear });
  }, [isFocused]);

  const svgStyle = useAnimatedStyle(() => ({
    color: interpolateColor(iconColor.value, [0, 1], [ICON_INACTIVE_COLOR, ICON_FOCUSED_COLOR]),
  }));

  const backgroundColor = useDerivedValue(() => {
    return withTiming(isFocused ? 1 : 0, { duration: 300, easing: Easing.linear });
  }, [isFocused]);

  const backgroundStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(
      backgroundColor.value,
      [0, 1],
      [ICON_BG_INACTIVE_COLOR, ICON_BG_FOCUSED_COLOR],
    ),
  }));

  return (
    <Pressable onPress={onPress} style={backgroundStyle}>
      <AnimatedSvg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        // @ts-ignore
        style={svgStyle}
        {...rest}
      >
        {children}
      </AnimatedSvg>
    </Pressable>
  );
};

export const All: FC<IconProps & SvgProps> = (props) => (
  <AnimatedSvgWrapper {...props}>
    <Path
      d="M10 3H3V10H10V3Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 3H14V10H21V3Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 14H14V21H21V14Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 14H3V21H10V14Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </AnimatedSvgWrapper>
);

export const Audio: FC<IconProps & SvgProps> = (props) => (
  <AnimatedSvgWrapper {...props}>
    <Path
      d="M3 18V12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V18"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H18C17.4696 21 16.9609 20.7893 16.5858 20.4142C16.2107 20.0391 16 19.5304 16 19V16C16 15.4696 16.2107 14.9609 16.5858 14.5858C16.9609 14.2107 17.4696 14 18 14H21V19ZM3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H6C6.53043 21 7.03914 20.7893 7.41421 20.4142C7.78929 20.0391 8 19.5304 8 19V16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14H3V19Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </AnimatedSvgWrapper>
);

export const Image: FC<IconProps & SvgProps> = (props) => (
  <AnimatedSvgWrapper {...props}>
    <Path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 15L16 10L5 21"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </AnimatedSvgWrapper>
);

export const Video: FC<IconProps & SvgProps> = (props) => (
  <AnimatedSvgWrapper {...props}>
    <Path
      d="M19.82 2H4.18C2.97602 2 2 2.97602 2 4.18V19.82C2 21.024 2.97602 22 4.18 22H19.82C21.024 22 22 21.024 22 19.82V4.18C22 2.97602 21.024 2 19.82 2Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7 2V22"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17 2V22"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 12H22"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 7H7"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2 17H7"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17 17H22"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17 7H22"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </AnimatedSvgWrapper>
);

export const Poll: FC<IconProps & SvgProps> = (props) => (
  <AnimatedSvgWrapper {...props}>
    <Path
      d="M18 20V10"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 20V4"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6 20V14"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </AnimatedSvgWrapper>
);

export const Marketplace: FC<IconProps & SvgProps> = ({ isFocused, ...rest }) => (
  <AnimatedSvgWrapper isFocused={isFocused} {...rest}>
    {!isFocused ? (
      <>
        <Path
          d="M6.5 22C6.5 22.2761 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.2761 5.5 22C5.5 21.7239 5.72386 21.5 6 21.5C6.27614 21.5 6.5 21.7239 6.5 22Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <Path
          d="M20.5 22C20.5 22.2761 20.2761 22.5 20 22.5C19.7239 22.5 19.5 22.2761 19.5 22C19.5 21.7239 19.7239 21.5 20 21.5C20.2761 21.5 20.5 21.7239 20.5 22Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <Path
          d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4.8 7H22L20 17H6L4 2H1"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <Path
          d="M6.5 22C6.5 22.2761 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.2761 5.5 22C5.5 21.7239 5.72386 21.5 6 21.5C6.27614 21.5 6.5 21.7239 6.5 22Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <Path
          d="M19.5 22C19.5 21.7239 19.7239 21.5 20 21.5C20.2761 21.5 20.5 21.7239 20.5 22C20.5 22.2761 20.2761 22.5 20 22.5C19.7239 22.5 19.5 22.2761 19.5 22Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="3"
        />
        <Path
          d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M22 7H4.80005L6.00005 17H20L22 7Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4.8 7H22L20 17H6L4 2H1"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </AnimatedSvgWrapper>
);
