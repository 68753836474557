import { FC } from 'react';
import styled, { css } from 'styled-components/native';

import TouchableOpacity, {
  TouchableOpacityProps,
} from 'components/TouchableOpacity/TouchableOpacity';

interface Props {
  isLandscape?: boolean;
  unlockText?: string;
}

const StyledTouchable = styled(TouchableOpacity)<Props>`
  border: 2px solid #ffffff;
  border-radius: 40px;
  width: 165px;
  height: 32px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  ${({ isLandscape }) =>
    isLandscape &&
    css`
      margin-top: 15px;
    `}
`;

const Text = styled.Text`
  color: #ffffff;
  font-size: 15px;
  letter-spacing: -0.23px;
`;

interface Props {
  onPress?: () => void;
}

const UnlockButton: FC<Props & TouchableOpacityProps> = ({
  onPress,
  isLandscape,
  unlockText,
  ...rest
}) => (
  <StyledTouchable onPress={onPress} isLandscape={isLandscape} {...rest}>
    <Text>{unlockText}</Text>
  </StyledTouchable>
);

export default UnlockButton;
