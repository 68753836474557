import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { statusBarHeight } from 'utils/screen';

interface Props {
  transparent?: boolean;
}

export default styled.View<Props>`
  width: 100%;
  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.colors.background};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  z-index: 0;

  ${Platform.OS === 'web'
    ? css`
        background-color: transparent;
        z-index: 10;
        align-items: flex-start;
        height: 80px;
      `
    : css`
        padding: ${Platform.OS === 'ios' ? statusBarHeight : 0}px 0 0;
      `}
`;
