import React, { useCallback, useState } from 'react';

import MoreOptionsSheet from 'components/MoreOptionsSheet';

import { ProductType } from 'types';

export interface State {
  productType?: ProductType;
  itemType?: 'comment' | 'post' | 'artist';
  itemId?: number | string;
  isMoreOptionsOpen: boolean;
  isReportBottomSheetOpen: boolean;
  isBlockBottomSheetOpen: boolean;
  artistUsername?: string;
  blockedUsername?: string;
  onClose?: () => void;
}

export interface Patch {
  productType?: ProductType;
  itemType?: 'comment' | 'post' | 'artist';
  itemId?: number | string;
  isMoreOptionsOpen?: boolean;
  isReportBottomSheetOpen?: boolean;
  isBlockBottomSheetOpen?: boolean;
  artistUsername?: string;
  blockedUsername?: string;
  onClose?: () => void;
}

const defaultStateContext: State = {
  isMoreOptionsOpen: false,
  isReportBottomSheetOpen: false,
  isBlockBottomSheetOpen: false,
};

const defaultSetStateContext: (patch: Patch) => void = () => {};

const MoreOptionsStateContext: React.Context<State> =
  React.createContext<State>(defaultStateContext);

const MoreOptionsSetStateContext: React.Context<(patch: Patch) => void> =
  React.createContext<(patch: Patch) => void>(defaultSetStateContext);

export function useMoreOptionsState(): State {
  const context = React.useContext<State>(MoreOptionsStateContext);
  if (context === undefined) {
    throw new Error('useMoreOptionsState must be used within a MoreOptionsProvider');
  }

  return context;
}

export function useMoreOptionsSet(): (patch: Patch) => void {
  const context = React.useContext(MoreOptionsSetStateContext);
  if (context === undefined) {
    throw new Error('useMoreOptionsSet must be used within a MoreOptionsProvider');
  }

  return context;
}

interface MoreOptionsProviderProps {}

export const MoreOptionsProvider: React.FC<MoreOptionsProviderProps> = ({
  children,
}: React.PropsWithChildren<MoreOptionsProviderProps>) => {
  const [state, setState] = useState<State>(defaultStateContext);

  const patchState = useCallback(
    (patch: Patch) => setState((prevState) => ({ ...prevState, ...patch })),
    [],
  );

  return (
    <MoreOptionsStateContext.Provider value={state}>
      <MoreOptionsSetStateContext.Provider value={patchState}>
        {children}
        <MoreOptionsSheet state={state} setState={patchState} />
      </MoreOptionsSetStateContext.Provider>
    </MoreOptionsStateContext.Provider>
  );
};
