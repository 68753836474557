import styled, { css } from 'styled-components/native';

export default styled.View<{ fullWidth?: boolean; isModalOpen?: boolean }>`
  margin-bottom: 25px;
  align-self: center;
  width: 100%;
  padding: 0 10px;
  z-index: ${({ isModalOpen }) => (isModalOpen ? -1 : 10)};

  ${({ theme, fullWidth }) =>
    theme.media.tablet &&
    !fullWidth &&
    css`
      padding: 0 32px;
      width: 90%;
    `}
`;
