import { Entypo } from '@expo/vector-icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import styled from 'styled-components/native';

const TouchableOpacity = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 20px;
  padding: 4px 12px;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.colors.text};
`;

const Play = styled(Entypo).attrs(({ theme }) => ({
  color: theme.colors.text,
  size: 16,
  name: 'controller-play',
}))`
  margin-left: -2px;
`;

const Stop = styled(Entypo).attrs(({ theme }) => ({
  color: theme.colors.text,
  size: 16,
  name: 'controller-stop',
}))`
  margin-left: -2px;
`;

interface Props {
  onPress: () => void;
  isPlaying?: boolean;
}

const PlayButton: React.FC<Props> = ({ onPress, isPlaying }: Props) => {
  const intl = useIntl();
  return (
    <View style={{ flexDirection: 'row', marginBottom: 5 }}>
      <TouchableOpacity onPress={onPress}>
        {isPlaying ? <Stop /> : <Play />}
        <Text>
          {isPlaying
            ? intl.formatMessage({
                id: 'post.audioNote.play',
                defaultMessage: 'Play',
              })
            : intl.formatMessage({
                id: 'post.audioNote.stop',
                defaultMessage: 'Stop',
              })}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default PlayButton;
