import { FontAwesome5 } from '@expo/vector-icons';
import styled from 'styled-components/native';

export default styled(FontAwesome5).attrs((props) => {
  return {
    name: 'play',
    color: props.theme.colors.background,
    size: 24,
  };
})``;
