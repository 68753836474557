import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Keyboard, Platform, TextInput } from 'react-native';

import { useTheme } from 'themes';
import { CommentAddFunc } from 'types';

import Avatar from './styled/Avatar';
import Container from './styled/Container';
import Input from './styled/Input';
import InputWeb from './styled/InputWeb';

export interface CommentAddProps {
  addComment: CommentAddFunc;
  profilePicture?: string;
  displayName?: string;
}

const CommentAdd = (
  { addComment, profilePicture, displayName, ...rest }: CommentAddProps,
  ref: ((instance: unknown) => void) | React.RefObject<unknown> | null | undefined,
): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const { theme } = useTheme();
  const inputRef = useRef<TextInput | null>(null);
  const [height, setHeight] = useState(0);

  const onChange = (text: string) => {
    setInputValue(text);
  };

  const onPost = () => {
    if (inputValue.trim().length > 0) {
      addComment({ text: inputValue, profilePicture, displayName });
      setInputValue('');
      Keyboard.dismiss();
    }
  };

  const focusOnCommentInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    focusOnCommentInput,
  }));

  return (
    <Container {...rest}>
      <Avatar mode="compact" imageUrl={profilePicture} showName={false} />
      {Platform.OS === 'web' ? (
        <InputWeb
          onChangeText={onChange}
          placeholder="Write a comment..."
          placeholderTextColor={theme.colors.primaryInactive}
          multiline
          value={inputValue}
          blurOnSubmit
          onSubmitEditing={onPost}
          returnKeyType="send"
          ref={inputRef}
          onContentSizeChange={(event) => setHeight(event.nativeEvent.contentSize.height)}
          style={{ height: Math.max(35, height) }}
        />
      ) : (
        <Input
          onChangeText={onChange}
          placeholder="Write a comment..."
          placeholderTextColor={theme.colors.primaryInactive}
          multiline
          value={inputValue}
          blurOnSubmit
          onSubmitEditing={onPost}
          returnKeyType="send"
          ref={inputRef}
        />
      )}
      {/* <PostAction disabled={!inputValue} onPress={onPost} text="Post" /> */}
    </Container>
  );
};

CommentAdd.displayName = 'CommentAdd';

export default forwardRef(CommentAdd);
