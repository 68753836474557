export default {
  header: {
    title: {
      id: 'post.header.title',
      defaultMessage: 'Create a Post',
    },
    cancel: {
      id: 'post.header.cancel',
      defaultMessage: 'Cancel',
    },
    next: {
      id: 'post.header.next',
      defaultMessage: 'Next',
    },
    save: {
      id: 'post.header.save',
      defaultMessage: 'Save',
    },
  },
  picker: {
    id: 'post.mediaPicker',
    defaultMessage: 'Add photo/video',
  },
  cropper: {
    id: 'post.cropper',
    defaultMessage: 'Crop photo',
  },
  caption: {
    id: 'post.caption',
    defaultMessage: 'Caption (optional)',
  },
  croppers: {
    horizontal: {
      id: 'post.croppers.horizontal',
      defaultMessage: 'Horizontal',
    },
    vertical: {
      id: 'post.croppers.vertical',
      defaultMessage: 'Vertical',
    },
    square: {
      id: 'post.croppers.square',
      defaultMessage: 'Square',
    },
  },
};
