import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: column;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      flex-direction: row;
      align-self: center;
      margin-top: 32px;
    `}
`;
