import React from 'react';

import { MarketplaceItem } from 'types';
import { parseItemPrice } from 'utils/marketplace';

import ArtistName from './styled/ArtistName';
import ImagePreview from './styled/ImagePreview';
import Price from './styled/Price';
import ProductTitle from './styled/ProductTitle';
import View from './styled/View';

interface Props {
  product: MarketplaceItem;
}
const ArtistMarketplaceProductTile: React.FC<Props> = ({ product }: Props) => (
  <View>
    <ImagePreview src={product.low_res_cover} />
    <ArtistName>{product.artist.display_name}</ArtistName>
    <ProductTitle>{product.name}</ProductTitle>
    <Price>{parseItemPrice(product.price, product.price_currency)}</Price>
  </View>
);

export default ArtistMarketplaceProductTile;
