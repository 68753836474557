import styled, { css } from 'styled-components/native';

interface Props {
  text?: string;
  transparent?: boolean;
}

export default styled.TouchableOpacity<Props>`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.button.backgroundOverlayDark};

  ${({ text, theme }) =>
    !!text &&
    css`
      background-color: ${theme.button.backgroundOverlayLight};
      width: auto;
      height: auto;
    `}
`;
