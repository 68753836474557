import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';

import FeedTopNav from 'screens/HomeScreen/styled/FeedTopNav';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from 'screens/MarketplaceProductOrderScreen/constants';
import { SCREEN_NAME as NOTIFICATIONS_SCREEN_NAME } from 'screens/NotificationsScreen';

import PurchasesList from 'containers/PurchasesList';

import FetchedDataContainer from 'components/FetchedDataContainer';
import ScreenView from 'components/ScreenView';
import DecoratedTitle from 'components/SubNavigation/DecoratedTitle';
import DecoratedWrapper from 'components/SubNavigation/DecoratedWrapper';
import WebContainer from 'components/WebContainer';

import { useNotificationsContext } from 'context/notifications';
import { useFanMarketplaceOrders } from 'hooks';
import { MarketplaceOrder, OrderStatus } from 'types';

import { SCREEN_NAME } from './constants';

export { SCREEN_NAME };

const MarketplaceOrdersScreen: React.FC = () => {
  const navigation = useNavigation();
  const { orders, isLoading, error: ordersError, refresh } = useFanMarketplaceOrders();
  const notifications = useNotificationsContext();

  const onNotificationsPress = useCallback(() => {
    navigation.navigate(NOTIFICATIONS_SCREEN_NAME);
  }, [navigation]);

  const navigateToOrder = (order: MarketplaceOrder) => {
    const {
      product: { id: productId },
    } = order;
    if (order.status !== OrderStatus.WAITING_FOR_PAYMENT) {
      navigation.navigate(MARKETPLACE_ORDER_DETAILS_SCREEN_NAME, {
        marketplaceProductId: productId,
        order: JSON.stringify(order),
        screenTitle: productId,
      });
    } else {
      console.debug('answers', order.questionare_answers);
      navigation.navigate(MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME, {
        marketplaceProductId: productId,
        artistUsername: order.product.artist.username,
        item: JSON.stringify(order.product),
        answers: order.questionare_answers,
        productId,
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      refresh?.();
    }, [refresh]),
  );

  const Wrapper = Platform.OS === 'web' ? WebContainer : ScreenView;

  return (
    <Wrapper>
      {Platform.OS !== 'web' && (
        <>
          <FeedTopNav
            hasUnreadNotifications={notifications.general}
            onNotificationsPress={onNotificationsPress}
            isArtist={false}
          />
          <DecoratedWrapper isActive mobile>
            <DecoratedTitle isActive>Orders</DecoratedTitle>
          </DecoratedWrapper>
        </>
      )}
      <FetchedDataContainer isLoading={isLoading} isError={!!ordersError}>
        <PurchasesList onItemPress={navigateToOrder} orders={orders || []} />
      </FetchedDataContainer>
    </Wrapper>
  );
};

export default MarketplaceOrdersScreen;
