import * as React from 'react';

import Avatar from './styled/Avatar';
import Container from './styled/Container';
import View from './styled/View';

export interface ProductOwnerProps {
  name?: string;
  imageUrl?: string;
  padding?: string;
}

export default function ProductOwner({ name, imageUrl, ...rest }: ProductOwnerProps): JSX.Element {
  return (
    <View {...rest}>
      <Container>
        <Avatar name={name} imageUrl={imageUrl} showTimestamp={false} mode="minimal-row" />
      </Container>
    </View>
  );
}
