import { FC } from 'react';
import { PressableProps } from 'react-native';

import Button from './styled/Button';
import IconMute from './styled/IconMute';
import IconUnmute from './styled/IconUnmute';

export interface MuteButtonProps {
  isMuted?: boolean;
}

const MuteButton: FC<MuteButtonProps & PressableProps> = ({ isMuted, ...rest }) => (
  <Button {...rest}>{isMuted ? <IconUnmute /> : <IconMute />}</Button>
);

export default MuteButton;
