import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import { parseCurrency } from 'utils/currency';

const View = styled.View`
  position: relative;
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.bottomSheet.input.background};
  border: 1px solid ${({ theme }) => theme.bottomSheet.input.border};
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding-left: 19px;
  margin-top: 30px;
  align-self: center;
`;

const InputText = styled.TextInput`
  width: 100%;
  height: 64px;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.bottomSheet.text};
  font-size: 15px;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.bottomSheet.text};
  margin: 0 13px 0 0px;
  font-size: 18px;
`;

interface InputProps {
  value?: string;
  onChangeValue?: (value: string) => void;
}

const Input: React.FC<InputProps> = ({ value, onChangeValue }: InputProps) => {
  return (
    <View>
      <Text></Text>
      <InputText
        secureTextEntry={true}
        maxLength={3}
        placeholder={'CVV'}
        onChangeText={onChangeValue}
        value={value}
        keyboardType="numeric"
        placeholderTextColor="rgba(255, 255, 255, 0.52)"
        autoComplete={'off'}
      />
    </View>
  );
};

export default Input;
