export default {
  subscribed: {
    id: 'subscribedArtists.subscribed',
    defaultMessage: 'Subscribed',
  },
  subscribe: {
    id: 'subscribedArtists.subscribe',
    defaultMessage: 'Subscribe',
  },
  followed: {
    id: 'subscribedArtists.followed',
    defaultMessage: 'Followed',
  },
  perMonth: {
    id: 'subscribedArtists.perMonth',
    defaultMessage: '/mnth',
  },
};
