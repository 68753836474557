import styled, { css } from 'styled-components/native';

interface Props {
  tabWidth?: number;
}

export default styled.View<Props>`
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;

  ${({ theme, tabWidth }) =>
    theme.media.tablet &&
    css`
      width: ${tabWidth}px;
    `}
`;
