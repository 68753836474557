import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin: 25px 0 0;
`;
