import { AxiosError } from 'axios';
import { useCallback } from 'react';
import useSWR, { SWRInfiniteConfiguration, useSWRInfinite } from 'swr';
import { MutatorCallback } from 'swr/dist/types';

import { useBackEnd } from 'context/backEnd';

export interface UseAuthSWRArgs<T> {
  cache?: boolean;
  isPublic?: boolean;
  key: string | null;
  mockValue?: T;
  swrOptions?: {
    refreshInterval?: number;
    shouldRetryOnError?: boolean;
    revalidateOnFocus?: boolean;
  };
  dontCheckValidation?: boolean;
}

export interface UseAuthSWRInfiniteArgs<T> {
  getKey: (pageIndex: number, previousPageData: T | null) => string | null;
  pageSize: number;
  cache?: boolean;
  isPublic?: boolean;
  swrOptions?: SWRInfiniteConfiguration<any, any>;
}

const mockMutate = () => {};

export function useAuthSwr<T>({
  key,
  isPublic,
  mockValue,
  swrOptions,
  dontCheckValidation,
}: UseAuthSWRArgs<T>): {
  data: T | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: AxiosError<any>;
  isAuthenticated: boolean;
  accessToken: string | null;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
} {
  const { fetcher, isAuthenticated, accessToken } = useBackEnd();
  const isActive = isPublic || isAuthenticated;

  const response = mockValue
    ? { data: mockValue, error: null, mutate: mockMutate, isValidating: false }
    : useSWR(isActive ? key : null, fetcher, swrOptions);

  const dataFixed = isActive ? response.data : undefined;
  const errorFixed = isActive ? response.error : undefined;

  return {
    data: dataFixed,
    isLoading: !dataFixed && !errorFixed,
    isValidating:
      dontCheckValidation || swrOptions?.refreshInterval ? false : response.isValidating,
    error: errorFixed as AxiosError,
    isAuthenticated,
    accessToken,
    mutate: response.mutate,
  };
}

export function useAuthSwrInfinite<T>({
  getKey,
  pageSize,
  isPublic,
  swrOptions,
}: UseAuthSWRInfiniteArgs<T[]>): {
  isLoadingInitial: boolean;
  isLoadingMore: boolean;
  isEmpty: boolean;
  hasReachedEnd: boolean;
  isValidating: boolean;
  isRefreshing: boolean;
  error: AxiosError<any>;
  data: T[][] | undefined;
  flattenedData: T[] | undefined;
  fetchMore: () => void;
  refresh: () => void;
  mutate: (
    data?: T[][] | Promise<T[][]> | MutatorCallback<T[][]> | undefined,
    shouldRevalidate?: boolean | undefined,
  ) => Promise<T[][] | undefined>;
} {
  const { fetcher, isAuthenticated } = useBackEnd();
  const isActive = isPublic || isAuthenticated;

  const getKeyWrapped = useCallback(
    (pageIndex: number, previousPageData: T[] | null) => {
      if (!isActive) {
        return null;
      }

      return getKey(pageIndex, previousPageData);
    },
    [isActive, getKey],
  );

  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    getKeyWrapped,
    fetcher,
    swrOptions,
  );

  // `data` is a 3-dimensional array. I.e. array of "pages", where each page is an array of
  // items. We're flattening it to return a single flat array of items from all the pages.
  const flattenedData = data?.reduce?.((prev: T[], current: T[]) => prev.concat(current), []);

  const isLoadingInitial = !isActive || (!data && !error);
  const isLoadingMore =
    isLoadingInitial || (size > 0 && !!data && typeof data[size - 1] === 'undefined');

  const fetchMore = useCallback(() => {
    if (isValidating || isLoadingMore) {
      return;
    }
    setSize(size + 1);
  }, [size, setSize, isValidating, isLoadingMore]);

  const refresh = useCallback(() => {
    mutate();
  }, [mutate]);

  const isEmpty = data?.[0]?.length === 0;
  const hasReachedEnd = isEmpty || (!!data && data[data.length - 1]?.length < pageSize);
  // There is a bug with `isValidating` being an empty string after mutate() sometimes.
  // Ref: https://github.com/vercel/swr/issues/1195
  const isRefreshing = isValidating === true && !!data && data.length === size;

  return {
    isLoadingInitial,
    isLoadingMore,
    // There is a bug with `isValidating` being an empty string after mutate() sometimes.
    // Ref: https://github.com/vercel/swr/issues/1195
    isValidating: isValidating === true,
    isEmpty,
    hasReachedEnd,
    isRefreshing,
    data,
    flattenedData,
    error: isActive ? error : undefined,
    fetchMore,
    refresh,
    mutate,
  };
}
