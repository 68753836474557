import { useNavigation } from '@react-navigation/core';
import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';

import FeedTopNav from 'screens/HomeScreen/styled/FeedTopNav';
import MarketplaceAvailableItemsScreen, {
  SCREEN_NAME as MARKETPLACE_AVAILABLE_SCREEN_NAME,
} from 'screens/MarketplaceAvailableItemsScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME } from 'screens/MarketplaceOrderInformationScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen';
import MarketplaceOrdersScreen, {
  SCREEN_NAME as MARKETPLACE_ORDERS_SCREEN_NAME,
} from 'screens/MarketplaceOrdersScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from 'screens/MarketplaceProductOrderScreen';
import { SCREEN_NAME as NOTIFICATIONS_SCREEN_NAME } from 'screens/NotificationsScreen';

import MarketplaceSubnavigation from 'components/MarketplaceSubnavigation';
import NavigationHeaderFan from 'components/NavigationHeaderFan';
import DecoratedTitle from 'components/SubNavigation/DecoratedTitle';
import DecoratedWrapper from 'components/SubNavigation/DecoratedWrapper';

import { useNotificationsContext } from 'context/notifications';

import NavigationHeaderFanWrapper from './styled/NavigationHeaderFanWrapper';
export { SCREEN_NAME } from './constants';

export interface Props {}

export const linking: PathConfigMap = {
  [MARKETPLACE_AVAILABLE_SCREEN_NAME]: '/',
  [MARKETPLACE_ORDERS_SCREEN_NAME]: '/orders',
};

const MarketplaceStack = createStackNavigator();

const FanMarketplaceStack: React.FC<Props> = () => {
  const navigation = useNavigation();
  const notifications = useNotificationsContext();
  const onNotificationsPress = useCallback(() => {
    navigation.navigate(NOTIFICATIONS_SCREEN_NAME);
  }, [navigation]);
  return (
    <MarketplaceStack.Navigator
      initialRouteName={
        Platform.OS === 'web' ? MARKETPLACE_AVAILABLE_SCREEN_NAME : MARKETPLACE_ORDERS_SCREEN_NAME
      }
      screenOptions={{
        presentation: 'card',
        headerMode: 'screen',
        header: function FanHomeStackHeader() {
          return (
            <>
              {Platform.OS === 'web' ? (
                <>
                  <NavigationHeaderFanWrapper>
                    <NavigationHeaderFan showBackButton={false} />
                  </NavigationHeaderFanWrapper>
                  <MarketplaceSubnavigation />
                </>
              ) : (
                <>
                  <FeedTopNav
                    hasUnreadNotifications={notifications.general}
                    onNotificationsPress={onNotificationsPress}
                    isArtist={false}
                  />
                  <DecoratedWrapper isActive>
                    <DecoratedTitle isActive>Orders</DecoratedTitle>
                  </DecoratedWrapper>
                </>
              )}
            </>
          );
        },
        cardStyle: { backgroundColor: '#161924' },
        safeAreaInsets: {
          top: 0,
        },
      }}
    >
      <MarketplaceStack.Screen
        name={MARKETPLACE_AVAILABLE_SCREEN_NAME}
        component={MarketplaceAvailableItemsScreen}
        options={{
          headerShown: true,
          animationEnabled: false,
        }}
      />
      <MarketplaceStack.Screen
        name={MARKETPLACE_ORDERS_SCREEN_NAME}
        component={MarketplaceOrdersScreen}
        options={{
          headerShown: Platform.OS === 'web',
          animationEnabled: false,
        }}
      />
    </MarketplaceStack.Navigator>
  );
};

export default FanMarketplaceStack;
