import React from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as LOGIN_STACK } from 'stacks/common/LoginStack/constants';

import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen/constants';

import NavigationHeaderFan from 'components/NavigationHeaderFan';

import { useUser } from 'hooks';

import Breadcrumbs from './styled/Breadcrumbs';
import Button from './styled/Button';
import Container from './styled/Container';
import Description from './styled/Description';
import Title from './styled/Title';
import WebContainer from './styled/WebContainer';
import { Props } from './types';

const PreFormScreen: React.FC<Props> = ({
  navigation,
  messages,
  destination,
  breadcumb,
}: Props) => {
  const intl = useIntl();
  const { isSignedIn } = useUser();
  const breadcrumbsLinks = [
    {
      title: 'Stanbase',
      onPress: () => navigation.navigate(isSignedIn ? HOME_SCREEN_NAME : LOGIN_STACK),
    },
    { title: breadcumb },
  ];

  return (
    <WebContainer>
      <NavigationHeaderFan
        navigation={navigation}
        showBackButton={false}
        hideSearchBar
        hideArtistInput
      />
      <Breadcrumbs links={breadcrumbsLinks} />
      <Container>
        <Title>{intl.formatMessage(messages.title)}</Title>
        <Description>{intl.formatMessage(messages.description)}</Description>
        <Button onPress={() => navigation.navigate(destination)} labelColor="#0537FF">
          {intl.formatMessage(messages.button)}
        </Button>
      </Container>
    </WebContainer>
  );
};

export default PreFormScreen;
