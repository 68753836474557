import React, { memo } from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const NoNetworkSvg = (props: SvgProps) => {
  return (
    <Svg width="225" height="187" viewBox="0 0 225 187" fill="none" {...props}>
      <Path
        d="M65.2142 161.581C71.3654 158.224 78.4313 152.435 84.9857 148.922C98.3761 141.739 113.431 137.11 128.244 133.857C140.815 131.082 153.542 128.546 166.196 126.175C173.76 124.737 182.993 123.903 189.283 119.092C189.938 111.188 187.038 101.786 179.105 100.996C171.172 100.205 148.639 105.486 143.897 106.641C133.337 109.156 117.341 112.803 107.003 116.052C89.611 121.489 69.7529 129.623 53.9218 139.094C49.4135 141.791 35.8887 147.15 36.0007 153.577C36.0707 157.308 41.4937 161.511 44.4501 162.667C53.1415 166.041 58.7422 165.125 65.2142 161.581Z"
        fill="#3F3F7C"
        fillOpacity="0.16"
      />
      <Path
        d="M65.1831 110.137C70.7014 107.353 76.6272 105.065 81.7684 102.906C100.898 94.8853 121.177 89.7869 141.724 87.1509C162.064 84.5453 185.485 86.0739 205.113 80.9756C208.226 80.1635 207.185 62.8621 206.045 60.656C203.952 56.6129 199.456 55.1494 195.286 54.2982C185.316 52.2615 174.7 55.4838 164.769 56.4305C148.729 57.9548 132.82 60.4779 116.95 63.153C88.8256 67.8996 57.5884 75.6644 34.5224 93.3219C26.5853 99.4017 14.9851 105.69 18.7218 116.773C20.7982 122.944 27.4696 133.353 35.0426 132.137C44.0072 130.7 47.9129 122.666 53.9861 117.346C57.1592 114.571 61.0563 112.217 65.1831 110.137Z"
        fill="#3F3F7C"
        fillOpacity="0.16"
      />
      <Path
        d="M31.8115 70.5055C48.4141 61.5377 63.985 55.5926 81.3289 49.0741C90.6576 45.5696 102.804 44.0062 112.497 41.2572C127.391 37.0665 141.272 37.9828 156.782 36.4802C172.583 34.969 188.167 33.8051 203.985 33.1885C206.833 33.0799 209.75 33.3969 212.633 33.6227C218.04 33.9935 222.759 29.9855 223.279 24.5812C223.791 19.3699 224.58 14.1586 224.979 8.86921C225.289 4.59769 222.206 0.830566 217.965 0.296676C210.491 -0.663065 199.966 1.01322 194.773 1.00888C183.234 1.00888 172.05 2.78072 160.527 3.33224C143.014 4.17039 125.566 1.8948 108.509 6.68482C69.6941 17.5894 34.1133 29.8793 4.80074 58.5195C0.847321 62.3802 -0.834616 62.9404 0.396493 68.0735C1.13342 71.0787 4.43228 75.7732 7.51005 79.4167C9.36075 81.6093 12.5745 82.0272 14.9227 80.3808C20.3847 76.5722 29.8868 71.5434 31.8115 70.5055Z"
        fill="#3F3F7C"
        fillOpacity="0.16"
      />
      <Path
        d="M152.333 98.3445C145.556 89.8239 132.594 83.5075 119.194 82.2574C104.133 80.7856 89.1425 85.6713 77.8413 95.7346C62.489 108.96 56.03 134.115 62.489 155.499C67.6978 172.814 80.5718 184.011 97.8103 186.204C101.851 186.722 105.92 186.986 109.994 186.993C118.415 187.11 126.788 185.706 134.711 182.848C147.574 177.946 156.347 169.042 160.788 156.387C167.773 136.374 164.461 113.642 152.333 98.3445Z"
        fill="#3A1F21"
      />
      <Path
        d="M133.537 153.887C133.117 153.89 132.704 153.768 132.353 153.536C126.442 149.687 89.1691 120.013 87.59 118.752C86.9645 118.268 86.6479 117.485 86.7612 116.703C86.8746 115.92 87.4004 115.259 88.1374 114.973C88.8744 114.687 89.7085 114.819 90.3205 115.32C90.7043 115.627 129.008 146.123 134.744 149.862C135.552 150.395 135.916 151.396 135.638 152.323C135.36 153.251 134.506 153.887 133.537 153.887Z"
        fill="white"
      />
      <Path
        d="M92.9195 158.877C92.0743 158.875 91.3051 158.388 90.9422 157.625C90.5793 156.862 90.6873 155.958 91.2197 155.302C91.3842 155.093 107.669 134.938 112.121 129.345C115.63 124.904 119.391 120.572 123.087 116.307C124.721 114.41 126.377 112.512 127.945 110.637C128.45 110.038 129.237 109.754 130.009 109.892C130.781 110.03 131.42 110.569 131.686 111.306C131.953 112.044 131.806 112.867 131.3 113.467C129.699 115.364 128.011 117.261 126.409 119.169C122.791 123.369 119.051 127.7 115.597 132.065C111.211 137.679 94.8385 157.857 94.674 158.065C94.2463 158.593 93.5984 158.892 92.9195 158.877Z"
        fill="white"
      />
    </Svg>
  );
};

const StyledNoNetworkSvg = styled(NoNetworkSvg)`
  margin: 0 0 40px 0;
`;

export default memo(StyledNoNetworkSvg);
