import * as React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import CenterView from './styled/CenterView';
import ExclamationIcon from './styled/ExclamationIcon';
import Text from './styled/Text';

export interface FetchedDataContainerProps {
  children?: any;
  errorMessage?: string;
  emptyMessage?: string;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
}

const FetchedDataContainer: React.FC<FetchedDataContainerProps> = ({
  children,
  errorMessage,
  emptyMessage,
  isLoading,
  isError,
  isEmpty,
  ...rest
}) => {
  const intl = useIntl();

  if (isLoading) {
    return (
      <CenterView {...rest}>
        <ActivityIndicator />
      </CenterView>
    );
  }

  if (isEmpty && !isError) {
    return (
      <CenterView {...rest}>
        <Text>{emptyMessage ? emptyMessage : intl.formatMessage(messages.emptyDefaultText)}</Text>
      </CenterView>
    );
  }

  if (isError) {
    return (
      <CenterView {...rest}>
        <ExclamationIcon />
        <Text>
          {errorMessage ? errorMessage : intl.formatMessage(messages.exclamationDefaultText)}
        </Text>
      </CenterView>
    );
  }

  return children ? children : null;
};

FetchedDataContainer.displayName = 'FetchedDataContainer';

export default FetchedDataContainer;
