import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.colors.text};
  margin: 10px 0 20px;
`;
