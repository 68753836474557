import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { Artist, LiveMoment } from 'types';

import { useAuthSwr } from './swr';
import { useFakeLoading } from './useFakeLoading';
import { useUser } from './user';

export interface UseLiveMoments {
  liveMoments: LiveMoment[] | undefined;
  isLoading?: boolean;
  error: AxiosError<any>;
}

export const LIVE_MOMENTS_KEY = '/feed/live_stream_moments';

export const MY_LIVE_MOMENTS_KEY = '/artists/me/past_live_streams';

export const LIVE_MOMENTS_REFRESH_INTERVAL = 10000;

export const LIVE_MOMENTS_FAKE_TIMEOUT = 3000;

export function useLiveMoments(mockValue?: LiveMoment[]): UseLiveMoments {
  const { isFakeLoading } = useFakeLoading(LIVE_MOMENTS_FAKE_TIMEOUT, mockValue);
  const {
    isLoading,
    data: liveMoments,
    error,
  } = useAuthSwr<LiveMoment[]>({
    key: LIVE_MOMENTS_KEY,
    isPublic: true,
    cache: true,
    swrOptions: {
      refreshInterval: LIVE_MOMENTS_REFRESH_INTERVAL,
    },
    mockValue,
  });

  return {
    isLoading: isLoading || isFakeLoading,
    liveMoments,
    error,
  };
}

export interface PastArtistLiveStream {
  file: string;
  low_res_cover: string;
  high_res_cover: string;
  total_views: number;
  tier: any;
  price: string;
  price_currency: string;
  time_created: string;
  id: number;
}

export interface UseMyLiveMoments {
  liveMoments: LiveMoment[] | undefined;
  isLoading: boolean;
  error: AxiosError<any>;
}

export function useMyLiveMoments(mockValue?: PastArtistLiveStream[]): UseMyLiveMoments {
  const { isFakeLoading } = useFakeLoading(LIVE_MOMENTS_FAKE_TIMEOUT, mockValue);
  const {
    isLoading,
    data: liveMoments,
    error,
  } = useAuthSwr<PastArtistLiveStream[]>({
    key: MY_LIVE_MOMENTS_KEY,
    isPublic: true,
    cache: true,
    swrOptions: {
      refreshInterval: LIVE_MOMENTS_REFRESH_INTERVAL,
    },
    mockValue,
  });
  const { profile } = useUser();
  const moments = useMemo(
    () =>
      (liveMoments || []).map((m: PastArtistLiveStream) => ({
        artist: profile as Artist,
        is_live: false,
        live_stream_viewers_count: m.total_views,
        mp4_url: m.file,
        id: m.id,
        time_created: m.time_created,
        isArchive: true,
      })),
    [liveMoments],
  );

  return { liveMoments: moments, isLoading: isLoading || isFakeLoading, error };
}
