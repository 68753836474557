import styled from 'styled-components';

export default styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: rgba(21, 24, 36, 0.2);
  backdrop-filter: blur(40px);
`;
