import styled from 'styled-components/native';

export default styled.View<{ isFirst?: boolean; isDeleteButton?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.optionsGroup.background};
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  padding: 15px;
  width: 100%;

  ${({ isFirst }) =>
    isFirst
      ? ''
      : `
    border-top-color: rgba(245, 245, 245, 0.12);
    border-top-width: 1px;
  `}

  ${({ isDeleteButton }) =>
    isDeleteButton &&
    `
    background-color: red;
  `}
`;
