import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Dimensions, Platform } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_JOIN_STANBASE_SCREEN_NAME } from 'screens/ArtistJoinStanbaseScreen/constants';
import { SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME } from 'screens/LoginWithEmailScreen/constants';
import useWelcomeScreenHandlers from 'screens/WelcomeScreen/hooks/useWelcomeScreenHandlers';

import { useConfig } from 'hooks';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import AppleIcon from './styled/AppleIcon';
import AppleIconSmall from './styled/AppleIconSmall';
import ContentView from './styled/ContentView';
import ContentWrapperSmall from './styled/ContentWrapperSmall';
import DisclaimerText from './styled/DisclaimerText';
import DisclaimerView from './styled/DisclaimerView';
import FacebookIcon from './styled/FacebookIcon';
import FacebookIconSmall from './styled/FacebookIconSmall';
import GoogleIcon from './styled/GoogleIcon';
import GoogleIconSmall from './styled/GoogleIconSmall';
import LoginOptionButton from './styled/LoginOptionButton';
import LoginOptionButtonSmall from './styled/LoginOptionButtonSmall';
import Logo from './styled/Logo';
import SeparatorText from './styled/SeparatorText';
import View from './styled/View';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type NavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: NavigationProp;
};

const WelcomeScreenArtist: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const { config } = useConfig();
  const logoOffset = 90;
  const windowHeight = Dimensions.get('window').height;
  const {
    authError,
    onContinueWithGooglePress,
    onContinueWithFacebookPress,
    onContinueWithApplePress,
    onLogoLoaded,
    contentOpacityRef,
    handleLogoAnimationEnd,
  } = useWelcomeScreenHandlers();

  const getContentHeight = () => {
    let contentHeight = 367;
    if (!(Platform.OS === 'ios' && config.fanAppleAuthEnabled)) {
      contentHeight = contentHeight - 55;
    }
    if (!(Platform.OS !== 'web' && config.fanGoogleAuthEnabled)) {
      contentHeight = contentHeight - 55;
    }
    if (!config.fanFacebookAuthEnabled) {
      contentHeight = contentHeight - 55;
    }

    return contentHeight;
  };

  const onArtistAccessRequestPress = useCallback(() => {
    navigation.navigate(ARTIST_JOIN_STANBASE_SCREEN_NAME);
  }, [navigation]);

  const onContinueWithEmailPress = useCallback(() => {
    navigation.navigate(LOGIN_WITH_EMAIL_SCREEN_NAME);
  }, [navigation]);

  const onAlertOkPress = useCallback(() => {
    console.log('OK Pressed');
  }, []);

  useEffect(() => {
    if (authError) {
      return Alert.alert(intl.formatMessage(messages.error.title), authError, [
        {
          text: 'OK',
          onPress: onAlertOkPress,
        },
      ]);
    }
  }, [authError]);

  return (
    <View>
      <Logo onLoadEnd={onLogoLoaded} handleLogoAnimationEnd={handleLogoAnimationEnd} />
      {windowHeight / 2 - logoOffset > getContentHeight() ? (
        <ContentView style={{ opacity: contentOpacityRef.current }}>
          {config.fanEmailAuthEnabled && (
            <>
              <LoginOptionButton onPress={onContinueWithEmailPress} alternative>
                {intl.formatMessage(messages.bottomSheet.option.email)}
              </LoginOptionButton>
              {(config.fanAppleAuthEnabled ||
                config.fanGoogleAuthEnabled ||
                config.fanFacebookAuthEnabled) && (
                <SeparatorText>
                  {intl.formatMessage(messages.bottomSheet.option.separator)}
                </SeparatorText>
              )}
            </>
          )}
          {Platform.OS === 'ios' && config.artistAppleAuthEnabled && (
            <LoginOptionButton icon={AppleIcon} onPress={onContinueWithApplePress} secondary>
              {intl.formatMessage(messages.bottomSheet.option.apple)}
            </LoginOptionButton>
          )}
          {Platform.OS !== 'web' && config.artistGoogleAuthEnabled && (
            <LoginOptionButton icon={GoogleIcon} onPress={onContinueWithGooglePress} secondary>
              {intl.formatMessage(messages.bottomSheet.option.google)}
            </LoginOptionButton>
          )}
          {config.artistFacebookAuthEnabled && (
            <LoginOptionButton icon={FacebookIcon} onPress={onContinueWithFacebookPress} secondary>
              {intl.formatMessage(messages.bottomSheet.option.facebook)}
            </LoginOptionButton>
          )}
          <DisclaimerView>
            <DisclaimerText large>
              {intl.formatMessage(messages.artist.register, {
                cta: function CtaChunk(chunk) {
                  return (
                    <DisclaimerText large onPress={onArtistAccessRequestPress}>
                      {chunk}
                    </DisclaimerText>
                  );
                },
              })}
            </DisclaimerText>
          </DisclaimerView>
        </ContentView>
      ) : (
        <ContentView style={{ opacity: contentOpacityRef.current }}>
          {config.fanEmailAuthEnabled && (
            <>
              <LoginOptionButton onPress={onContinueWithEmailPress} alternative>
                {intl.formatMessage(messages.bottomSheet.option.email)}
              </LoginOptionButton>
              {(config.artistAppleAuthEnabled ||
                config.artistGoogleAuthEnabled ||
                config.artistFacebookAuthEnabled) && (
                <SeparatorText>
                  {intl.formatMessage(messages.bottomSheet.option.separator)}
                </SeparatorText>
              )}
            </>
          )}
          <ContentWrapperSmall>
            {Platform.OS === 'ios' && config.artistAppleAuthEnabled && (
              <LoginOptionButtonSmall
                icon={AppleIconSmall}
                onPress={onContinueWithApplePress}
                secondary
              />
            )}
            {Platform.OS !== 'web' && config.artistGoogleAuthEnabled && (
              <LoginOptionButtonSmall
                icon={GoogleIconSmall}
                onPress={onContinueWithGooglePress}
                secondary
              />
            )}
            {config.artistFacebookAuthEnabled && (
              <LoginOptionButtonSmall
                icon={FacebookIconSmall}
                onPress={onContinueWithFacebookPress}
                secondary
              />
            )}
          </ContentWrapperSmall>
          <DisclaimerView>
            <DisclaimerText large>
              {intl.formatMessage(messages.artist.register, {
                cta: function CtaChunk(chunk) {
                  return (
                    <DisclaimerText large onPress={onArtistAccessRequestPress}>
                      {chunk}
                    </DisclaimerText>
                  );
                },
              })}
            </DisclaimerText>
          </DisclaimerView>
        </ContentView>
      )}
    </View>
  );
};

export default WelcomeScreenArtist;
