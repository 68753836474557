import { AxiosError } from 'axios';
import * as InAppPurchases from 'expo-in-app-purchases';
import { useEffect, useState } from 'react';
import { cache, mutate } from 'swr';

import { useBackEnd } from 'context/backEnd';
import { useCvvModal } from 'context/cvvModal';
import { useFeedHandlers } from 'context/feed';
import { useApexx } from 'hooks/apexx';
import { useAuthSwr } from 'hooks/swr';
import { ActiveSubscriptions, Artist, CurrentPlan, PaymentMethod, SubscriptionPlan } from 'types';

export const ARTISTS_KEY = '/artists';

export interface SubscriptionStatus {
  is_auto_renew: boolean;
  is_paid_with_apple_iap: boolean;
  cancel_reason?: string;
  time_start?: string;
  time_end?: string;
}

export function useActiveSubscriptions(mockValue?: ActiveSubscriptions): {
  activeSubscriptions: ActiveSubscriptions;
  isLoading: boolean;
  error?: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    data: activeSubscriptions,
    isLoading,
    error,
    mutate,
  } = useAuthSwr<any>({
    key: `${ARTISTS_KEY}/subscribing`,
    isPublic: false,
    cache: false,
    mockValue,
  });

  return {
    activeSubscriptions,
    isLoading,
    error,
    mutate,
  };
}

export function useActiveFollowing(mockValue?: ActiveSubscriptions): {
  isLoading: boolean;
  activeFollowing: any | undefined;
  error: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    data: activeFollowing,
    isLoading,
    error,
    mutate,
  } = useAuthSwr<any>({
    key: `${ARTISTS_KEY}/following`,
    isPublic: false,
    cache: false,
    mockValue,
  });

  return {
    activeFollowing,
    isLoading,
    mutate,
    error,
  };
}

export function useSubscriptionStatus(artistUsername: string): {
  subscriptionStatus?: SubscriptionStatus;
  isLoading: boolean;
  error?: AxiosError<any>;
} {
  const {
    isLoading,
    data: subscriptionStatus,
    error,
  } = useAuthSwr<SubscriptionStatus>({
    key: `${ARTISTS_KEY}/${artistUsername}/subscription`,
    swrOptions: { refreshInterval: 0 },
  });

  return {
    subscriptionStatus,
    isLoading,
    error,
  };
}

export function useSubscriptionIAP(): {
  subscriptionApplePaymentCallback: ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => Promise<any>;
} {
  const { axiosInstance } = useBackEnd();

  const subscriptionApplePaymentCallback = async ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => {
    return await axiosInstance
      .post(`${ARTISTS_KEY}/subscription_plan/payment/apple_iap`, {
        receipt_data: data,
        apple_product_id,
        timestamp,
        transaction_id,
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(
          'subscription payment confirmation:',
          JSON.stringify(e?.response?.data?.detail),
        );
        return null;
      });
  };

  return {
    subscriptionApplePaymentCallback,
  };
}

export function useSubscriptionPlan(artistUsername?: string): {
  subscriptionPlan?: {
    price: number;
    price_currency: string;
    apple_product_id: string;
  };
  isLoading: boolean;
  error?: AxiosError<any>;
} {
  const { isLoading, data, error } = useAuthSwr<SubscriptionPlan>({
    key: artistUsername ? `${ARTISTS_KEY}/${artistUsername}/subscription_plan` : null,
    swrOptions: { refreshInterval: 0 },
  });

  return {
    subscriptionPlan: data
      ? { ...data, price: Number.parseFloat(data.price?.toString()) }
      : undefined,
    isLoading,
    error,
  };
}

export function useSubscribe(): {
  subscribeWithApple: ({
    artistUsername,
    appleProductId,
  }: {
    artistUsername: string;
    appleProductId: string;
  }) => Promise<void>;
  subscribeWithCard: ({
    artistUsername,
    paymentMethod,
    cardinalSessionId,
  }: {
    artistUsername: string;
    paymentMethod: PaymentMethod;
    cardinalSessionId?: string;
  }) => Promise<void>;
  isSubscribing: boolean;
  subscribeData: null;
  subscribeError: any;
  reset: () => void;
} {
  const { refresh: refreshFeed } = useFeedHandlers();
  const { payWithApexx, paymentSuccess, paymentError } = useApexx();
  const { isOpen, setIsOpen, cvv } = useCvvModal();

  const [isSubscribing, setIsSubscribing] = useState(false);
  const [subscribeError, setSubscribeError] = useState<any | null>(null);
  const [subscribeData, setSubscribeData] = useState(null);
  const [artistUsername, setArtistUsername] = useState<string>();

  useEffect(() => {
    const finalizeApexxPayment = async () => {
      if (paymentSuccess === false) {
        console.log('subscription payment error:', paymentError?.message);
        setSubscribeError(paymentError?.message || 'subscription payment error');
        setIsSubscribing(false);
      } else if (paymentSuccess === true) {
        setSubscribeData({ data: 'payment succeed' } as any);
        await Promise.all(
          cache
            .keys()
            .filter((key) => key.startsWith(`${ARTISTS_KEY}/${artistUsername}`))
            .concat(['/artists/featured'])
            .map((key) => mutate(key)),
        );
        mutate(`${ARTISTS_KEY}/${artistUsername}/subscription`);
        refreshFeed();
        setIsSubscribing(false);
      }
    };

    finalizeApexxPayment();
  }, [paymentSuccess, paymentError, artistUsername, refreshFeed]);

  const subscribeWithCard = async ({
    paymentMethod,
    artistUsername,
  }: {
    paymentMethod: PaymentMethod;
    artistUsername: string;
  }) => {
    if (isSubscribing) {
      return;
    }

    setIsSubscribing(true);
    setSubscribeError(null);
    setSubscribeData(null);
    setArtistUsername(artistUsername);

    await payWithApexx({
      artist_username: artistUsername,
      payment_type: 'subscription',
      credit_card_external_id: (paymentMethod.creditCardInfo.external_id || '').toString(),
    });
  };

  const subscribeWithApple = async ({
    appleProductId,
    artistUsername,
  }: {
    appleProductId: string;
    artistUsername: string;
  }) => {
    if (isSubscribing) {
      return;
    }

    setIsSubscribing(true);
    setSubscribeError(null);
    setSubscribeData(null);

    if (appleProductId) {
      console.log('/// ... IAP');
      console.log('/// ... appleProductId', appleProductId);
      const { responseCode, results, errorCode } = await InAppPurchases.getProductsAsync([
        appleProductId,
      ]);
      console.log('/// ...', responseCode, JSON.stringify(results), errorCode);
      if (responseCode === InAppPurchases.IAPResponseCode.OK) {
        console.log('OK');
        console.log('/// ... purchasing an item');
        try {
          await InAppPurchases.purchaseItemAsync(appleProductId);
          console.log('/// ... item purchased');
          setSubscribeData({ result: 'item purchased' } as any);
        } catch (e: any) {
          console.log('/// ... error', JSON.stringify(e));
          setSubscribeError('There was an error during payment');
        }
      }
      if (responseCode === InAppPurchases.IAPResponseCode.ERROR) {
        console.log('ERROR');
        setSubscribeError('There was an error during payment');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
        console.log('DEFERRED');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
        console.log('USER_CANCELED');
      }
    }

    if (subscribeData) {
      console.log('/// ... refreshing feed');
      await Promise.all(
        cache
          .keys()
          .filter((key) => key.startsWith(`${ARTISTS_KEY}/${artistUsername}`))
          .concat(['/artists/featured'])
          .map((key) => mutate(key)),
      );
      mutate(`${ARTISTS_KEY}/${artistUsername}/subscription`);
      refreshFeed();
    }
    setIsSubscribing(false);
  };

  const reset = () => {
    setSubscribeData(null);
    setSubscribeError(null);
    setIsSubscribing(false);
  };

  return {
    subscribeWithApple,
    subscribeWithCard,
    isSubscribing,
    subscribeData,
    subscribeError,
    reset,
  };
}

export function useUnsubscribe(): {
  unsubscribe: (artistUsername: string) => Promise<any>;
  resubscribe: (artistUsername: string) => Promise<any>;
  isUnsubscribing: boolean;
  unsubscribeData: null;
  unsubscribeError: any;
  reset: () => void;
} {
  const { axiosInstance } = useBackEnd();

  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [unsubscribeError, setUnsubscribeError] = useState<any | null>(null);
  const [unsubscribeData, setUnsubscribeData] = useState(null);

  const manageSubscription = async (artistUsername: string, type: 'patch' | 'delete') => {
    if (isUnsubscribing) {
      return;
    }

    setIsUnsubscribing(true);
    setUnsubscribeError(null);
    setUnsubscribeData(null);

    try {
      const response = await axiosInstance[type](`/artists/${artistUsername}/subscription`);
      setUnsubscribeData(response.data);
      await Promise.all(
        cache
          .keys()
          .filter((key) => key.startsWith(`${ARTISTS_KEY}/${artistUsername}`))
          .concat(['/feed', '/artists/featured'])
          .map((key) => mutate(key)),
      );
    } catch (e: any) {
      setUnsubscribeError(e.response);
      throw new Error(e);
    }

    mutate(`${ARTISTS_KEY}/${artistUsername}/subscription`);
    setIsUnsubscribing(false);
    return unsubscribeData;
  };

  const unsubscribe = (artistUsername: string) => manageSubscription(artistUsername, 'delete');

  const resubscribe = (artistUsername: string) => manageSubscription(artistUsername, 'patch');

  const reset = () => {
    setUnsubscribeData(null);
    setUnsubscribeError(null);
    setIsUnsubscribing(false);
  };

  return {
    unsubscribe,
    resubscribe,
    isUnsubscribing: isUnsubscribing,
    unsubscribeData: unsubscribeData,
    unsubscribeError: unsubscribeError,
    reset,
  };
}

export function useArtistSubscription(): {
  currentPlan: CurrentPlan;
  updateSubscriptionPrice: (artist: Artist, newPrice: string, currency: string) => Promise<boolean>;
  isLoading: boolean;
  error?: AxiosError<any>;
} {
  const { axiosInstance } = useBackEnd();
  const KEY = `${ARTISTS_KEY}/me/subscription_plan`;

  const {
    data: currentPlan,
    isLoading,
    error,
  } = useAuthSwr<any>({
    key: KEY,
    isPublic: false,
    cache: false,
  });

  const updateSubscriptionPrice = async (artist: Artist, newPrice: string, currency: string) => {
    try {
      console.log({ artist, price: newPrice, price_currency: currency });
      await axiosInstance.patch(KEY, {
        artist,
        price: newPrice,
        price_currency: currency,
      });
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return {
    currentPlan,
    updateSubscriptionPrice,
    isLoading,
    error,
  };
}
