import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function IconEdit(props: SvgProps): JSX.Element {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M11.3333 1.99955C11.5084 1.82445 11.7163 1.68556 11.9451 1.5908C12.1738 1.49604 12.419 1.44727 12.6667 1.44727C12.9143 1.44727 13.1595 1.49604 13.3883 1.5908C13.617 1.68556 13.8249 1.82445 14 1.99955C14.1751 2.17465 14.314 2.38252 14.4088 2.61129C14.5035 2.84006 14.5523 3.08526 14.5523 3.33288C14.5523 3.58051 14.5035 3.8257 14.4088 4.05448C14.314 4.28325 14.1751 4.49112 14 4.66622L5 13.6662L1.33334 14.6662L2.33334 10.9995L11.3333 1.99955Z"
        stroke="#FFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
