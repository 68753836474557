import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const DeleteIcon: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <Path
        d="M9.125 0.875L0.875 9.125"
        stroke="white"
        strokeOpacity="0.87"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M0.875 0.875L9.125 9.125"
        stroke="white"
        strokeOpacity="0.87"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const TouchableOpacity = styled.TouchableOpacity`
  padding: 4px;
`;

interface Props {
  onDelete: () => void;
}

const DeleteButton: React.FC<Props> = ({ onDelete }: Props) => {
  return (
    <TouchableOpacity onPress={onDelete}>
      <DeleteIcon />
    </TouchableOpacity>
  );
};

export default DeleteButton;
