import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import Svg, { Circle, SvgProps } from 'react-native-svg';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

const PlayingCirclesSvg: FC<SvgProps> = ({ style, ...rest }) => {
  const scale = useRef(new Animated.Value(0.9)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scale, { toValue: 1, useNativeDriver: true, duration: 350 }),
        Animated.timing(scale, { toValue: 0.9, useNativeDriver: true, duration: 350 }),
      ]),
      { iterations: -1 },
    ).start();
  }, []);

  return (
    <AnimatedSvg
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
      style={[style, { transform: [{ scale }] }]}
      {...rest}
    >
      <Circle cx="54" cy="54" r="54" fill="#0734ea" fillOpacity="1" />
      <Circle cx="54" cy="55" r="49" fill="#0830d0" fillOpacity="1" />
      <Circle cx="54" cy="55" r="44" fill="#092cb9" fillOpacity="1" />
      <Circle cx="54" cy="55" r="39" fill="#0a29a5" fillOpacity="1" />
    </AnimatedSvg>
  );
};

export default PlayingCirclesSvg;
