import styled from 'styled-components/native';

export default styled.View`
  margin-top: 50px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`;
