import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(() => ({
  secondary: true,
  labelStyle: {
    fontSize: 13,
  },
}))`
  align-self: flex-start;
  border-radius: 30px;
  min-height: 30px;
  width: auto;
  padding: 0 10px;
`;
