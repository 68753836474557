import styled from 'styled-components/native';

export default styled.Text`
  font-size: 15px;
  letter-spacing: -0.23px;
  line-height: 20px;
  color: ${({ theme }) => theme.onBoarding.description};
  opacity: 0.6;
  text-align: center;
  align-self: center;
  width: 85%;
  height: 85px;
  margin: 0 0 25px 0;
`;
