import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import ErrorsView from './styled/ErrorsView';
import HelperText from './styled/HelperText';

interface Props {
  errors: DeepMap<{ [key: string]: { message?: string } | string }, FieldError>;
}

const FormErrorsView: React.FC<Props> = ({ errors }) => (
  <ErrorsView>
    {Object.keys(errors)
      .filter((key) => !!errors[key] && key !== 'password')
      .map((key) => (
        <HelperText key={key}>{(errors as any)[key].message}</HelperText>
      ))}
  </ErrorsView>
);

export default FormErrorsView;
