import styled from 'styled-components/native';

import TabBar from 'components/TabBar';

export default styled(TabBar).attrs(({ theme }) => ({
  tabTouchableStyle: {
    borderRadius: 40,
    overflow: 'hidden',
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabLabelStyle: {
    fontFamily: 'BasierCircle-Medium',
    fontSize: 13,
    lineHeight: 18,
    textAlign: 'center',
    letterSpacing: -0.08,
  },
  activeLabelColor: theme.colors.text,
  inactiveLabelColor: theme.colors.primaryGhost,
  activeBackground: theme.colors.backgroundLight,
}))`
  background-color: ${({ theme }) => theme.colors.backgroundDarker};
  border-radius: 40px;
  overflow: hidden;
  height: 36px;
`;
