import moment from 'moment';

export function sortByBoolean<T extends { [key: string]: any }>(
  collection: T[],
  propertyName: string,
): T[] {
  if (!collection) return [];

  return collection.sort((itemA: T, itemB: T) =>
    itemA[propertyName] === itemB[propertyName] ? 0 : itemA[propertyName] ? -1 : 1,
  );
}

export function sortByDate<T extends { [key: string]: any }>(
  collection: T[],
  propertyName: string,
): T[] {
  if (!collection) return [];

  return collection.sort((itemA: T, itemB: T) =>
    moment(itemA[propertyName]).diff(itemB[propertyName]),
  );
}
