export default {
  stansCount: {
    id: 'artist.profile.stats.stansCount',
    defaultMessage: '{count, plural, =0 {No Stans} one {# Stan} other {# Stans}}',
  },
  followersCount: {
    id: 'artist.profile.stats.followersCount',
    defaultMessage: '{count, plural, =0 {No Followers} one {# Follower} other {# Followers}}',
  },
  login: {
    id: 'artist.profile.login',
    defaultMessage: 'Sign in to subscribe',
  },
  subscribe: {
    id: 'artist.profile.subscribe',
    defaultMessage: 'Become a Stan',
  },
  pendingCancelation: {
    id: 'artist.profile.pendingCancellation',
    defaultMessage: 'Pending cancellation',
  },
  subscribed: {
    id: 'artist.profile.subscribed',
    defaultMessage: "You're a Stan",
  },
  follow: {
    id: 'artist.profile.follow',
    defaultMessage: 'Follow',
  },
  following: {
    id: 'artist.profile.following',
    defaultMessage: 'Followed',
  },
  subscribeInfo: {
    id: 'artist.profile.subscribeInfo',
    defaultMessage:
      'Become a Stan to get access to {artistName}’s exclusive Stan only content and Stan Store.',
  },
  seeInsights: {
    id: 'artist.profile.seeInsights',
    defaultMessage: 'See Insights',
  },
  stancastArchive: {
    id: 'artist.profile.stancastArchive',
    defaultMessage: 'StanCast archive',
  },
};
