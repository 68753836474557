import styled from 'styled-components/native';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

export default styled(TouchableOpacity)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: rgba(18, 18, 18, 0.52);
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;
