import styled from 'styled-components/native';

export default styled.Text.attrs(() => ({
  numberOfLines: 1,
}))`
  color: ${({ theme }) => theme.orderPreview.primaryText};
  font-family: BasierCircle-Medium;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;
