import styled, { css } from 'styled-components/native';

interface TouchableOpacityProps {
  primary?: boolean;
  secondary?: boolean;
  alternative?: boolean;
  warning?: boolean;
  pay?: boolean;
  short?: boolean;
  input?: boolean;
  inputActive?: boolean;
  disabled?: boolean;
  gradient?: boolean;
}

//copy of TouchableOpacity.native with different component
export default styled.TouchableOpacity<TouchableOpacityProps>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 22px;
  min-height: 44px;
  background-color: ${({ theme }) => theme.colors.primaryActive};

  ${({ short }) =>
    short &&
    css`
      min-height: 36px;
    `}

  ${({ primary, theme }) =>
    primary &&
    css`
      width: auto;
      height: 44px;
      border-radius: 22px;
      background-color: ${theme.button.backgroundColorBlue};
    `}

  ${({ secondary, gradient }) =>
    secondary &&
    !gradient &&
    css`
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.primaryActive};
      border-width: 1px;
    `}

  ${({ alternative, theme }) =>
    alternative &&
    css`
      background-color: ${theme.colors.primaryGhost};
    `}


  ${({ warning, theme }) =>
    warning &&
    css`
      background-color: ${theme.colors.errorAccent};
    `}

  ${({ input, inputActive, theme }) =>
    (input || inputActive) &&
    css`
      border-radius: 4px;
      background-color: ${theme.colors.backgroundHighlight};
      border-color: ${theme.colors.primaryInactive};
      border-width: 1px;
      justify-content: flex-start;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border: 0;
      background-color: ${theme.colors.backgroundHighlight};
    `}

  ${({ pay, theme }) =>
    pay &&
    css`
      background-color: ${theme.colors.payBackground};
    `}

  ${({ pay, disabled }) =>
    pay &&
    disabled &&
    css`
      opacity: 0.6;
    `}
`;
