import { useNavigation } from '@react-navigation/core';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME } from 'screens/LoginWithEmailScreen';

import { VARIANT } from 'context';
import isMobile from 'utils/isMobile';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Button from './styled/Button';
import ContentView from './styled/ContentView';
import Logo from './styled/Logo';
import View from './styled/View';
import WelcomeText from './styled/WelcomeText';
export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type NavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: NavigationProp;
};

const EmailConfirmedScreen: React.FC<Props> = () => {
  const intl = useIntl();
  const navigation = useNavigation();

  const variantSuffix = VARIANT === 'fan' ? '' : 'artist';
  const appUrl = `com.stanbase${variantSuffix}://auth/login/email`;

  const onCtaPress = () =>
    isMobile()
      ? Linking.openURL(appUrl).catch(() => alert(intl.formatMessage(messages.errorCantOpenApp)))
      : navigation.navigate(LOGIN_WITH_EMAIL_SCREEN_NAME);

  return (
    <View>
      <ContentView>
        <Logo />
        <WelcomeText>{intl.formatMessage(messages.confirmation[VARIANT])}</WelcomeText>
        <Button onPress={onCtaPress}>{intl.formatMessage(messages.cta[VARIANT])}</Button>
      </ContentView>
    </View>
  );
};

export default EmailConfirmedScreen;
