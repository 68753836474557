import styled, { css } from 'styled-components/native';

export default styled.View<{ padding?: string }>`
  width: 100vw;
  height: 100vh;
  padding: ${({ padding }) => padding || 0};
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 80vw;
    `}

  ${({ theme }) =>
    theme.media.tabletLandscape &&
    css`
      width: 30%;
    `}
`;
