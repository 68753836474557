import { useNavigation } from '@react-navigation/core';
import * as Haptics from 'expo-haptics';
import React, { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import artistProfileMessages from 'screens/ArtistProfileScreen/messages';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen/constants';

import { useFeedHandlers, useFeedItemUpdate, usePayments } from 'context';
import { useTippingSheet } from 'context/tipping';
import { useBookmark, useConfig, useInsights, useLike } from 'hooks';
import { Bookmark, Comment, Like, Share, Tip } from 'svg';
import { ArtistProduct } from 'types';
import { getModelNamePlural } from 'utils/product';
import { shareProduct } from 'utils/sharing';

import Button from './styled/Button';
import ControlGroup from './styled/ControlGroup';
import CountText from './styled/CountText';
import GroupLeft from './styled/GroupLeft';
import GroupRight from './styled/GroupRight';
import View from './styled/View';

import TippingSubscribeDialog from 'dialogs/TippingSubscribeDialog';

const MINIMUM_NUMBER_TO_TRANSFORM = 1000;

export interface FeedItemProps {
  isReacted: boolean;
  isTipped?: boolean;
  isBookmarked?: boolean;
  isOnFeed?: boolean;
  showReactions?: boolean;
  showComments?: boolean;
  showTipping?: boolean;
  showBookmarks?: boolean;
  showSharing?: boolean;
  showReactionsCount?: boolean;
  showCommentsCount?: boolean;
  bookmarksCount?: number;
  reactionsEnabled?: boolean;
  commentingEnabled?: boolean;
  tippingEnabled?: boolean;
  bookmarkingEnabled?: boolean;
  sharingEnabled?: boolean;
  reactionsCount?: number;
  commentsCount?: number;
  showZeroCount?: boolean;
  onLikePress?: (isReacted: boolean) => void;
  onCommentPress?: () => void;
  onTipPress?: () => void;
  onBookmarkPress?: (isBookmarked: boolean) => void;
  onSharePress?: () => void;
  product: ArtistProduct;
  focusOnCommentAdd?: () => void;
}

const FeedItemControls: React.FC<FeedItemProps> = ({
  isBookmarked,
  isReacted = false,
  isTipped = false,
  isOnFeed = false,
  showReactions = true,
  showComments = true,
  showTipping = true,
  showBookmarks = true,
  showSharing = true,
  showReactionsCount = true,
  showCommentsCount = true,
  reactionsCount = 0,
  reactionsEnabled = false,
  commentingEnabled = false,
  tippingEnabled = false,
  bookmarkingEnabled = false,
  sharingEnabled = false,
  commentsCount = 0,
  showZeroCount = true,
  focusOnCommentAdd,
  product,
  ...restProps
}: FeedItemProps) => {
  const navigation = useNavigation();
  const { config } = useConfig();
  const [tippingDialogOpen, setTippingDialogOpen] = useState(false);
  const intl = useIntl();
  const { setIsOpen: setTippingSheetOpen, setProduct: setTippingProduct } = useTippingSheet();
  const { refresh: refreshFeed } = useFeedHandlers();
  const { requestPayment } = usePayments();
  const { updateFeedItem } = useFeedItemUpdate();
  const { createShare } = useInsights(product.id, product.model_name, product.artist.username);

  const { toggleLike } = useLike({
    product,
    updateFeedItem,
  });

  const { toggleBookmark, isProcessing } = useBookmark({
    product,
    updateFeedItem,
  });

  const navigateToProduct = () => {
    navigation.navigate(SINGLE_POST_SCREEN_NAME, {
      screen: SINGLE_POST_SCREEN_NAME,
      artistUsername: product.artist.username,
      productType: product.model_name,
      productId: product.id,
      product,
    });
  };

  const onSubscribePress = () => {
    setTippingDialogOpen(false);
    requestPayment(
      {
        type: 'subscription',
        artistUsername: product.artist.username,
      },
      intl.formatMessage(artistProfileMessages.paymentSheetTitle),
      intl.formatMessage(artistProfileMessages.paymentSheetDescription, {
        artistName: product.artist?.display_name,
      }),
      refreshFeed,
    );
  };

  const formatNumbers = (num: number) => {
    if (!isOnFeed) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (num < MINIMUM_NUMBER_TO_TRANSFORM) {
      return num;
    }
    const si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' },
    ];
    let i;
    for (i = si.length - 1; i >= 0; i--) {
      if (num >= si[i].v) {
        return (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].s;
      }
    }
  };

  const formatedReactionsCount = useMemo(
    () => formatNumbers(reactionsCount),
    [reactionsCount, isOnFeed],
  );
  const formatedCommentsCount = useMemo(
    () => formatNumbers(commentsCount),
    [commentsCount, isOnFeed],
  );

  const onLikePress = useCallback(() => {
    if (product.has_access) {
      toggleLike();
      Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    }
  }, [product.has_access, toggleLike]);

  const onCommentPress = useCallback(() => {
    if (typeof focusOnCommentAdd === 'function') {
      focusOnCommentAdd();
      Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    } else {
      navigateToProduct();
    }
  }, [navigateToProduct]);

  const onBookmarkPress = useCallback(() => {
    toggleBookmark();
    Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
  }, [toggleBookmark]);

  const onSharePress = useCallback(async () => {
    const { action } = await shareProduct(product);
    if (action === 'sharedAction') {
      createShare?.('web');
    }
  }, [product]);

  const onTipPress = useCallback(() => {
    if (product.has_access) {
      setTippingSheetOpen(true);
      setTippingProduct({
        artistUsername: product.artist.username,
        productId: product.id,
        type: 'tip',
        modelNamePlural: getModelNamePlural(product.model_name),
      });
    } else {
      setTippingDialogOpen(true);
    }
  }, [product.has_access]);

  return (
    <>
      <View {...restProps}>
        <GroupLeft>
          {showReactions && (
            <ControlGroup isOnFeed={isOnFeed}>
              <Button onPress={onLikePress} disabled={!reactionsEnabled} testID="button-reaction">
                <Like liked={isReacted} />
              </Button>
              {showReactionsCount && (showZeroCount || reactionsCount > 0) && (
                <CountText>{formatedReactionsCount}</CountText>
              )}
            </ControlGroup>
          )}
          {showComments && (
            <ControlGroup isOnFeed={isOnFeed}>
              <Button
                onPress={onCommentPress}
                disabled={!commentingEnabled}
                testID="button-comment"
              >
                <Comment />
              </Button>
              {showCommentsCount && (showZeroCount || commentsCount) > 0 && (
                <CountText>{formatedCommentsCount}</CountText>
              )}
            </ControlGroup>
          )}
        </GroupLeft>
        <GroupRight>
          {showTipping && config.tippingEnabled && (
            <ControlGroup rightAlign>
              <Button onPress={onTipPress} disabled={!tippingEnabled} testID="button-tip">
                <Tip tipped={isTipped} />
              </Button>
            </ControlGroup>
          )}
          {showBookmarks && (
            <ControlGroup rightAlign>
              <Button onPress={onBookmarkPress} disabled={isProcessing} testID="button-bookmark">
                <Bookmark isProcessing={isProcessing} fill={isBookmarked} />
              </Button>
            </ControlGroup>
          )}
          {showSharing && (
            <ControlGroup rightAlign>
              <Button onPress={onSharePress} disabled={!sharingEnabled} testID="button-share">
                <Share />
              </Button>
            </ControlGroup>
          )}
        </GroupRight>
      </View>

      <TippingSubscribeDialog
        isOpen={tippingDialogOpen}
        setIsOpen={setTippingDialogOpen}
        onSubscribePress={onSubscribePress}
      />
    </>
  );
};

export default memo(FeedItemControls, isEqual);
