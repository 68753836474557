import { useNavigation } from '@react-navigation/native';
import React, { memo, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Platform, StatusBar, View } from 'react-native';

import { SCREEN_NAME as SEARCH_AND_DISCOVERY_SCREEN_NAME } from 'screens/SearchAndDiscoveryScreen/constants';
import { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import SearchButton from 'components/SearchButton';

import { useScreenSize } from 'hooks';
import { useTheme } from 'themes';
import darkTheme from 'themes/dark';
import lightTheme from 'themes/light';

import ArtistProfilesPopup from './styled/ArtistProfilesPopup';
import BackArrow from './styled/BackArrow';
import ButtonText from './styled/ButtonText';
import Container from './styled/Container';
import ContainerMiddle from './styled/ContainerMiddle';
import Divider from './styled/Divider';
import HeaderBar from './styled/HeaderBar';
import InsetView from './styled/InsetView';
import Logo from './styled/Logo';
import LogoButton from './styled/LogoButton';
import ShowArtistProfileInput from './styled/ShowArtistProfileInput';
import TitleText from './styled/TitleText';
import TouchableOpacity from './styled/TouchableOpacity';
import { NavigationHeaderProps } from './types';

export const HEIGHT = 57; // This is used by KeyboardAvoidingView

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  showGoBack = Platform.OS !== 'web',
  headerMiddleLogo = false,
  headerMiddle,
  headerLeft,
  headerBackImage,
  showArtistInput = false,
  showSearchBar = false,
  isArtistProfilesPopupOpen = false,
  onArtistProfilesPress,
  headerBackTitle,
  headerBackTitleVisible = true,
  headerBackShowIcon = true,
  headerBackOnPress,
  onGoingBack,
  title: passedTitle,
  headerTintColor,
  headerRight,
  headerRightImage,
  headerRightLabel,
  headerRightOnPress,
  headerRightDisabled,
  scene,
  insets,
  transparent = false,
  ...restProps
}: NavigationHeaderProps) => {
  const themes = useTheme();
  const media = useScreenSize();
  const theme = themes.mode === 'light' ? lightTheme : darkTheme;
  const navigation = useNavigation<any>();
  const title = passedTitle || scene?.descriptor.options.title;
  const [leftPosition, setLeftPosition] = useState<number>(0);
  const [topPosition, setTopPosition] = useState<number>(0);

  const onBackPress = () => {
    onGoingBack?.();
    if (typeof scene?.route.params?.goBack === 'function') {
      scene?.route.params?.goBack() || navigation.navigate(WELCOME_SCREEN_NAME);
    } else {
      navigation.canGoBack() ? navigation.goBack() : navigation.navigate(WELCOME_SCREEN_NAME);
    }
  };

  const inset = useMemo(
    () =>
      Platform.OS === 'android' && insets?.top === undefined
        ? StatusBar.currentHeight || 0
        : insets?.top || 0,
    [insets],
  );

  const navigateToSearchPage = () => {
    navigation.navigate(SEARCH_AND_DISCOVERY_SCREEN_NAME);
  };

  const onLogoPress = () => {
    if (Platform.OS === 'web') {
      navigation.navigate(WELCOME_SCREEN_NAME);
    }
  };

  return (
    <HeaderBar {...restProps} transparent={transparent}>
      <Container>
        {showGoBack && (
          <TouchableOpacity inset={inset} onPress={headerBackOnPress || onBackPress}>
            {headerBackImage?.({
              tintColor: headerTintColor || theme.colors.text,
            }) ||
              (headerBackShowIcon && <BackArrow tintColor={headerTintColor} />)}
            {headerBackShowIcon && navigation.canGoBack() && headerBackTitleVisible && (
              <View style={{ marginRight: 2 }} />
            )}
            {headerBackTitleVisible && (
              <ButtonText tintColor={headerTintColor}>{headerBackTitle}</ButtonText>
            )}
          </TouchableOpacity>
        )}
        {headerLeft && <InsetView inset={inset}>{headerLeft()}</InsetView>}
        {showSearchBar && (
          <InsetView inset={inset}>
            {media.tablet && <Divider />}
            <SearchButton onClick={navigateToSearchPage} left={26} />
          </InsetView>
        )}
      </Container>
      <TitleText inset={inset} tintColor={headerTintColor}>
        {title}
      </TitleText>
      {(headerMiddle || headerMiddleLogo) && (
        <ContainerMiddle>
          <InsetView inset={inset}>
            {headerMiddle
              ? headerMiddle()
              : headerMiddleLogo &&
                Platform.OS === 'web' && (
                  <LogoButton onPress={onLogoPress}>
                    <Logo />
                  </LogoButton>
                )}
          </InsetView>
        </ContainerMiddle>
      )}
      <Container>
        {showArtistInput && (
          <InsetView inset={inset}>
            <ShowArtistProfileInput
              onPress={onArtistProfilesPress}
              setLeftPosition={setLeftPosition}
              setTopPosition={setTopPosition}
            />
            {isArtistProfilesPopupOpen && (
              <ArtistProfilesPopup topPosition={topPosition} leftPosition={leftPosition} />
            )}
          </InsetView>
        )}
        {headerRight ? (
          <InsetView inset={inset}>
            {headerRight({
              tintColor: headerTintColor || theme.colors.text,
              image: headerRightImage,
              label: headerRightLabel,
              onPress: headerRightOnPress,
              disabled: headerRightDisabled,
            })}
          </InsetView>
        ) : (
          <TouchableOpacity
            inset={inset}
            disabled={headerRightDisabled}
            onPress={headerRightOnPress}
          >
            <ButtonText tintColor={headerTintColor}>{headerRightLabel}</ButtonText>
            {headerRightLabel && headerRightImage && <View style={{ marginRight: 2 }} />}
            {headerRightImage?.({
              tintColor: headerTintColor || theme.colors.text,
            })}
          </TouchableOpacity>
        )}
      </Container>
    </HeaderBar>
  );
};

export default memo(NavigationHeader, isEqual);
