import { FontAwesome } from '@expo/vector-icons';
import React, { memo } from 'react';
import styled from 'styled-components/native';

const IconPlay: React.FC = ({ ...restProps }) => (
  <FontAwesome name="play" size={24} {...restProps} />
);

export default memo(styled(IconPlay)`
  color: ${(props) => props.theme.colors.primaryActive};
`);
