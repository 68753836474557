export default {
  headerTitle: {
    id: 'settings.header.title',
    defaultMessage: 'Hello {name} 👋',
  },
  tabs: {
    yourArtists: {
      id: 'settings.tabs.yourArtists',
      defaultMessage: 'Your Artists',
    },
    profileSettings: {
      id: 'settings.tabs.profileSettings',
      defaultMessage: 'Profile settings',
    },
    blockedUsers: {
      id: 'settings.tabs.blockedUsers',
      defaultMessage: 'Blocked users',
    },
    notifications: {
      id: 'settings.tabs.notifications',
      defaultMessage: 'Notifications',
    },
    paymentSettings: {
      id: 'settings.tabs.paymentSettings',
      defaultMessage: 'Payment settings',
    },
  },
};
