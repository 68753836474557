import { useEffect, useState } from 'react';

import { useBackEnd } from 'context/backEnd';
import { useAuthSwr } from 'hooks/swr';
import { LiveStream } from 'types';

export const BROADCAST_KEY = '/artists/me/live_stream';

export const useBroadcast = (): {
  createLiveStream: () => Promise<void>;
  deleteLiveStream: () => Promise<void>;
  liveStream?: LiveStream;
  isBusy: boolean;
} => {
  const [isBusy, setIsBusy] = useState(false);
  const [liveStream, setLiveStream] = useState<LiveStream>();
  const { axiosInstance } = useBackEnd();

  useEffect(() => {
    const getLiveStream = async () => {
      try {
        const response = await axiosInstance.get(BROADCAST_KEY);
        console.log('getting live stream:', response.data);
        setLiveStream(response.data);
      } catch (e) {
        console.log('error getting live stream:', e);
      }
    };

    getLiveStream();
  }, []);

  const createLiveStream = async () => {
    setIsBusy(true);
    try {
      const response = await axiosInstance.post(
        BROADCAST_KEY,
        { tier: null },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      console.log('live stream created:', response.data);
      setLiveStream(response.data);
    } catch (e) {
      console.log('creating live stream:', e);
    }
    setIsBusy(false);
  };

  const deleteLiveStream = async () => {
    setIsBusy(true);
    try {
      await axiosInstance.delete(BROADCAST_KEY);
      console.log('live stream deleted');
      setLiveStream(undefined);
    } catch (e) {
      console.log('deleting live stream:', e);
    }
    setIsBusy(false);
  };

  return {
    createLiveStream,
    deleteLiveStream,
    liveStream,
    isBusy,
  };
};

export const useViewerCount = (
  artistUsername?: string,
): {
  viewerCount: number | null | undefined;
} => {
  const { data } = useAuthSwr<{ viewers_count: number }>({
    key: `/artists/${artistUsername || 'me'}/live_stream/view`,
    swrOptions: {
      refreshInterval: 5000,
      shouldRetryOnError: true,
    },
  });

  return {
    viewerCount: data?.viewers_count,
  };
};

export const useLivestreamData = (
  artistUsername?: string,
): {
  m3u8_url: string;
} => {
  const { data } = useAuthSwr<{ m3u8_url: string }>({
    key: `/artists/${artistUsername}/live_stream`,
    swrOptions: {
      refreshInterval: 5000,
      shouldRetryOnError: true,
    },
  });

  return {
    m3u8_url: data?.m3u8_url || '',
  };
};
