import * as React from 'react';

import { Artist } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import Avatar from './styled/Avatar';
import Item from './styled/Item';
import List from './styled/List';
import Separator from './styled/Separator';

export interface ArtistsListProps {
  data: Artist[];
  renderAction?: (artist: Artist) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderItem?: (artist: Artist) => React.ReactNode;
  onArtistPress?: (artist: Artist) => void;
}

const ArtistsList: React.FC<ArtistsListProps> = ({
  data,
  renderAction,
  renderHeader,
  renderFooter,
  renderItem,
  onArtistPress,
}: ArtistsListProps) => {
  const extractKey = (item: unknown): string => `${(item as Artist).username}`;

  const renderArtistItem = ({ item }: { item: unknown }) => {
    const typedItem = item as Artist;

    if (typeof renderItem === 'function') {
      return renderItem(typedItem);
    }

    return (
      <Item>
        <Avatar
          mode="minimal-row-big"
          name={typedItem.display_name}
          isLive={typedItem.is_live}
          imageUrl={getProfileLowResPictureUrl(typedItem)}
          onPress={() => onArtistPress?.(typedItem)}
        />

        {renderAction?.(typedItem)}
      </Item>
    );
  };

  return (
    <List
      data={data}
      extraData={data}
      keyExtractor={extractKey}
      renderItem={renderArtistItem}
      ItemSeparatorComponent={Separator}
      ListHeaderComponent={renderHeader?.()}
      ListFooterComponent={renderFooter?.()}
    />
  );
};

ArtistsList.defaultProps = {
  data: [],
};

ArtistsList.displayName = 'ArtistsList';

export default ArtistsList;
