import styled, { css } from 'styled-components/native';

interface Props {
  fullWidth?: boolean;
  padding?: number;
}

export default styled.View<Props>`
  flex: 1;
  align-self: center;
  width: 100%;
  z-index: -1;

  padding: 0 ${({ padding, theme }) => (padding !== undefined ? padding : theme.viewPadding)}px;

  ${({ theme, fullWidth }) =>
    theme.media.tablet &&
    !fullWidth &&
    css`
      width: 80%;
      padding: 10px 32px;
    `}
`;
