import { useNavigation } from '@react-navigation/core';
import * as Haptics from 'expo-haptics';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';
import artistProfileMessages from 'screens/ArtistProfileScreen/messages';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen/constants';

import FeedItemComponent from 'components/FeedItem';
import { FeedItemAudioContentProps } from 'components/FeedItemAudioContent';
import { FeedItemPollOptions } from 'components/FeedItemPollContent/FeedItemPollContent';
import { FeedItemVideoContentProps } from 'components/FeedItemVideoContent';

import { useFeedHandlers, useFeedItemUpdate, useMoreOptionsSet, usePayments } from 'context';
import { useTippingSheet } from 'context/tipping';
import { useBookmark, useInsights, useLike, useSubscriptionStatus } from 'hooks';
import { ArtistProduct } from 'types';
import { getModelNamePlural } from 'utils/product';
import { shareProduct } from 'utils/sharing';

import messages from './messages';

import TippingSubscribeDialog from 'dialogs/TippingSubscribeDialog';

export interface FeedItemProps {
  product: ArtistProduct;
  isFeedItem?: boolean;
  isExpanded?: boolean;
  showAvatar?: boolean;
  showControls?: boolean;
  showCaption?: boolean;
  showCommentsBottomCounter?: boolean;
  showEdit?: boolean;
  showInsightsButton?: boolean;
  showControlsBelowContent?: boolean;
  roundedCorners?: boolean;
  videoOptions?: FeedItemVideoContentProps;
  audioOptions?: FeedItemAudioContentProps;
  pollOptions?: FeedItemPollOptions;
  reactionsEnabled?: boolean;
  commentingEnabled?: boolean;
  tippingEnabled?: boolean;
  bookmarkingEnabled?: boolean;
  sharingEnabled?: boolean;
  onSeeInsightsPress?: () => void;
  onEditPress?: () => void;
  focusOnCommentAdd?: () => void;
  overlayButtonMarginTop?: number;
  onPaymentDone?: () => void;
}

const FeedItem: React.FC<FeedItemProps> = ({
  product,
  isFeedItem = true,
  isExpanded = false,
  showAvatar = true,
  showControls = true,
  showCaption = true,
  showCommentsBottomCounter = false,
  showEdit = false,
  showInsightsButton = false,
  showControlsBelowContent = false,
  roundedCorners,
  videoOptions,
  audioOptions,
  pollOptions,
  reactionsEnabled = false,
  commentingEnabled = false,
  tippingEnabled = false,
  bookmarkingEnabled = false,
  sharingEnabled = false,
  onSeeInsightsPress,
  onEditPress,
  focusOnCommentAdd,
  overlayButtonMarginTop,
  onPaymentDone,
  ...restProps
}: FeedItemProps) => {
  const navigation = useNavigation();
  const intl = useIntl();
  const { artist } = product;

  const { refresh: refreshFeed } = useFeedHandlers();
  const { updateFeedItem } = useFeedItemUpdate();
  const { createShare } = useInsights(product.id, product.model_name, artist.username);
  const { setIsOpen: setTippingSheetOpen, setProduct: setTippingProduct } = useTippingSheet();
  const [tippingDialogOpen, setTippingDialogOpen] = useState(false);
  const { subscriptionStatus } = useSubscriptionStatus(artist.username);

  const isSubscribed = !!(
    subscriptionStatus?.time_end && moment().isBefore(moment(subscriptionStatus?.time_end))
  );

  const { toggleLike } = useLike({
    product,
    updateFeedItem,
  });

  const { toggleBookmark } = useBookmark({
    product,
    updateFeedItem,
  });

  const { requestPayment } = usePayments();

  const setMoreOptions = useMoreOptionsSet();

  const navigateToArtist = useCallback(() => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      screen: ARTIST_PROFILE_SCREEN_NAME,
      artistUsername: artist.username,
      artist: artist,
    });
  }, [navigation, artist]);

  const navigateToProduct = useCallback(() => {
    navigation.navigate(SINGLE_POST_SCREEN_NAME, {
      screen: SINGLE_POST_SCREEN_NAME,
      artistUsername: artist.username,
      productType: product.model_name,
      productId: product.id,
      product: product,
    });
  }, [navigation, product]);

  const onAvatarPress = useCallback(() => {
    navigateToArtist();
  }, [navigateToArtist]);

  const onContentPress = useCallback(() => {
    navigateToProduct();
  }, [navigateToProduct]);

  const onLikePress = useCallback(() => {
    if (product.has_access) {
      toggleLike();
      Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    }
  }, [toggleLike, product.has_access]);

  const onCommentsPress = useCallback(() => {
    if (typeof focusOnCommentAdd === 'function') {
      focusOnCommentAdd();
      Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    } else {
      navigateToProduct();
    }
  }, [navigateToProduct]);

  const onBookmarkPress = useCallback(() => {
    toggleBookmark();
    Platform.OS !== 'web' && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
  }, [toggleBookmark]);

  const onSharePress = useCallback(async () => {
    const { action } = await shareProduct(product);
    if (action === 'sharedAction') {
      createShare('web');
    }
  }, [product, createShare]);

  const onTipPress = useCallback(() => {
    if (product.has_access) {
      setTippingSheetOpen(true);
      setTippingProduct({
        artistUsername: artist.username,
        productId: product.id,
        type: 'tip',
        modelNamePlural: getModelNamePlural(product.model_name),
      });
    } else {
      setTippingDialogOpen(true);
    }
  }, [product.has_access]);

  const onSubscribePress = () => {
    setTippingDialogOpen(false);
    requestPayment(
      {
        type: 'subscription',
        artistUsername: artist.username,
      },
      intl.formatMessage(artistProfileMessages.paymentSheetTitle),
      intl.formatMessage(artistProfileMessages.paymentSheetDescription, {
        artistName: artist?.display_name,
      }),
      () => {
        refreshFeed();
        onPaymentDone?.();
      },
    );
  };

  const onUnlockPress = useCallback(async () => {
    if (!isSubscribed) {
      requestPayment(
        {
          type: 'subscription',
          artistUsername: artist.username,
        },
        intl.formatMessage(artistProfileMessages.paymentSheetTitle),
        intl.formatMessage(artistProfileMessages.paymentSheetDescription, {
          artistName: artist?.display_name,
        }),
        () => {
          refreshFeed();
          onPaymentDone?.();
        },
      );
    }
    requestPayment(
      {
        artistUsername: artist.username,
        type:
          product?.payment_model === 'requires_payment' && isSubscribed
            ? 'paid_post'
            : 'subscription',
        appleProductId: product?.iap_config?.apple_product_id,
        price: Number(product?.iap_config?.price),
        modelNamePlural: getModelNamePlural(product.model_name),
        productId: product.id,
      },
      intl.formatMessage(
        product?.payment_model === 'requires_payment' && isSubscribed
          ? messages.unlockPaymentRequestTitle
          : messages.subscribePaymentRequestTitle,
        {
          price: Number(product.price),
        },
      ),
      intl.formatMessage(
        product?.payment_model === 'requires_payment' && isSubscribed
          ? messages.unlockPaymentRequestDescription
          : messages.subscribePaymentRequestDescription,
        {
          username: artist.display_name,
        },
      ),
      () => {
        refreshFeed();
        onPaymentDone?.();
      },
    );
  }, [navigateToProduct, product, subscriptionStatus]);

  const onMorePress = useCallback(
    () =>
      setMoreOptions({
        itemType: 'post',
        itemId: product.id,
        productType: product.model_name,
        artistUsername: product.artist.username,
        isMoreOptionsOpen: true,
        onClose: undefined,
      }),
    [product],
  );

  return (
    <>
      <FeedItemComponent
        product={product}
        isOnFeed={isFeedItem}
        isExpanded={isExpanded}
        showAvatar={showAvatar}
        showControls={showControls}
        showCaption={showCaption}
        showCommentsBottomCounter={showCommentsBottomCounter}
        showEdit={showEdit}
        showInsightsButton={showInsightsButton}
        showControlsBelowContent={showControlsBelowContent}
        roundedCorners={roundedCorners}
        videoOptions={videoOptions}
        audioOptions={audioOptions}
        pollOptions={pollOptions}
        reactionsEnabled={reactionsEnabled}
        commentingEnabled={commentingEnabled}
        tippingEnabled={tippingEnabled}
        bookmarkingEnabled={bookmarkingEnabled}
        sharingEnabled={sharingEnabled}
        onAvatarPress={onAvatarPress}
        onContentPress={onContentPress}
        onLikePress={onLikePress}
        onCommentsPress={onCommentsPress}
        onBookmarkPress={onBookmarkPress}
        onSharePress={onSharePress}
        onUnlockPress={onUnlockPress}
        onMorePress={onMorePress}
        onSeeInsightsPress={onSeeInsightsPress}
        onEditPress={onEditPress}
        onTipPress={onTipPress}
        navigateToProduct={navigateToProduct}
        overlayButtonMarginTop={overlayButtonMarginTop}
        {...restProps}
      />
      <TippingSubscribeDialog
        isOpen={tippingDialogOpen}
        setIsOpen={setTippingDialogOpen}
        onSubscribePress={onSubscribePress}
      />
    </>
  );
};

export default FeedItem;
