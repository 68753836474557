import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface SvgComponentProps {
  tipped?: boolean;
}

const SvgComponent: React.FC<SvgComponentProps> = ({ tipped, ...rest }: SvgComponentProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={tipped ? '#0537FF' : 'none'} {...rest}>
      <Path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke={tipped ? '#0537FF' : '#ffffff'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <Path
        d="M16.4939 14.9069C16.1234 16.6498 14.4874 17.7333 12.7756 17.3695L12.1643 17.2395L11.9753 18.1288C11.8604 18.669 11.3294 19.0138 10.7892 18.899L10.3001 18.7951C9.75992 18.6802 9.41507 18.1492 9.5299 17.609L9.71891 16.7198L7.90711 16.3347C7.21957 16.1885 6.78067 15.5127 6.92681 14.8251C7.07296 14.1376 7.7488 13.6987 8.43634 13.8448L13.3049 14.8797C13.6717 14.9576 13.9691 14.7606 14.0485 14.3871C14.1279 14.0137 13.9363 13.7127 13.5695 13.6347L9.90141 12.8551C8.18965 12.4912 7.13578 10.8359 7.50624 9.09306C7.8767 7.35018 9.51273 6.26665 11.2245 6.6305L11.8358 6.76044L12.0248 5.87122C12.1397 5.331 12.6707 4.98616 13.2109 5.10098L13.7 5.20494C14.2402 5.31977 14.585 5.85078 14.4702 6.391L14.2812 7.28022L16.093 7.66533C16.7805 7.81148 17.2194 8.48732 17.0733 9.17486C16.9272 9.86241 16.2513 10.3013 15.5638 10.1552L10.6953 9.12033C10.3285 9.04236 10.031 9.23937 9.95161 9.61284C9.87222 9.98632 10.0638 10.2873 10.4306 10.3652L14.0987 11.1449C15.8105 11.5088 16.8643 13.164 16.4939 14.9069Z"
        fill="white"
      />
    </Svg>
  );
};

export default SvgComponent;
