import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import CenterView from './styled/CenterView';
import FullscreenButton from './styled/FullscreenButton';
import IconReplay from './styled/IconReplay';
import MuteButton from './styled/MuteButton';
import RoundTouchableOpacity from './styled/RoundTouchableOpacity';
import View from './styled/View';

export interface VideoEndScreenProps {
  showReplayButton?: boolean;
  showMuteButton?: boolean;
  showFullscreenButton?: boolean;
  showProgressBar?: boolean;
  isMuted?: boolean;
  onMutePress?: () => void;
  onFullscreenPress?: () => void;
  onReplayPress?: () => void;
}

type Props = VideoEndScreenProps & TouchableOpacityProps;

const VideoEndScreen: React.FC<Props> = ({
  showReplayButton,
  onReplayPress,
  showMuteButton,
  showFullscreenButton,
  showProgressBar,
  isMuted,
  onMutePress,
  onFullscreenPress,
  ...restProps
}: Props) => {
  return (
    <View {...restProps}>
      <CenterView>
        {showReplayButton && (
          <RoundTouchableOpacity transparent onPress={onReplayPress}>
            <IconReplay />
          </RoundTouchableOpacity>
        )}
      </CenterView>
      {showMuteButton && (
        <MuteButton
          showProgressBar={showProgressBar}
          isMuted={!!isMuted}
          onPress={onMutePress}
          testID="MuteButton"
        />
      )}
      {showFullscreenButton && (
        <FullscreenButton onPress={onFullscreenPress} testID="FullscreenButton" />
      )}
    </View>
  );
};

VideoEndScreen.defaultProps = {
  showReplayButton: true,
};

export default VideoEndScreen;
