export default {
  inputText: {
    id: 'changeSubscriptionPrice.input',
    defaultMessage: 'Change price',
  },
  disclaimer: {
    id: 'changeSubscriptionPrice.disclaimer',
    defaultMessage: 'Please be aware that the new price will take effect in 72 hours',
  },
  priceLessThan: {
    id: 'changeSubscriptionPrice.error.priceLess',
    defaultMessage: 'Price must be higher than 0.',
  },
  updateError: {
    id: 'changeSubscriptionPrice.update.error',
    defaultMessage: 'An error occured. Please try again.',
  },
  updateSuccess: {
    id: 'changeSubscriptionPrice.update.success',
    defaultMessage: 'Subscription fee updated.',
  },
  closeButton: {
    id: 'changeSubscriptionPrice.close.button',
    defaultMessage: 'Close',
  },
  inputPlaceholder: {
    id: 'changeSubscriptionPrice.input.placeholder',
    defaultMessage: 'Subscription fee',
  },
};
