import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import { Extrapolation, interpolate, useSharedValue } from 'react-native-reanimated';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_POST_SETTINGS_SCREEN_NAME } from 'screens/ArtistPostSettingsScreen/constants';

import ScreenView from 'containers/ModalScreen';

import AudioControls from 'components/AudioControls';
import { DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET } from 'components/AudioControls/styled/ProgressSvg';
import ErrorText from 'components/ErrorHandling/ErrorText';
import NavigationHeader from 'components/NavigationHeader';
import TextInput from 'components/TextInput';

import { useUser } from 'hooks';
import useAudio from 'hooks/useAudio';
import { containsLink } from 'utils/regex';

import { SCREEN_NAME } from './constants';
import AudioCardView from './styled/AudioCardView';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistAudioScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const [caption, setCaption] = useState<string>('');
  const [durationMillis, setDurationMillis] = useState(0);
  const [positionMillis, setPositionMillis] = useState(0);
  const [wasPlayed, setWasPlayed] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const strokeOffset = useSharedValue(DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET);
  const { filePath, fileBlob } = route.params;
  const isCaptionLink = caption ? containsLink(caption) : false;

  const intl = useIntl();
  const { profile } = useUser();
  const { isPlaying, setIsPlaying, setOnPlaybackStatusUpdate, pause, seek, isLoadingAudio } =
    useAudio({ uri: filePath });

  useEffect(() => {
    setOnPlaybackStatusUpdate((status) => {
      if (status.isLoaded) {
        setPositionMillis(status.positionMillis);
        if (status.durationMillis) {
          setDurationMillis(status.durationMillis);
        }
        if (status.didJustFinish) {
          setIsPlaying(false);
          setIsFinished(true);
        }
      }
    });

    return () => {
      pause();
      setOnPlaybackStatusUpdate(null);
    };
  }, [pause, setIsPlaying, setOnPlaybackStatusUpdate]);

  useEffect(() => {
    if (durationMillis > 0) {
      strokeOffset.value = interpolate(
        positionMillis / durationMillis,
        [0, 1],
        [DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET, 0],
        { extrapolateLeft: Extrapolation.CLAMP, extrapolateRight: Extrapolation.CLAMP },
      );
    }
  }, [durationMillis, positionMillis, strokeOffset]);

  useEffect(() => {
    if (isFinished) {
      strokeOffset.value = DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET;
      seek(0);
    }
  }, [isFinished, seek, strokeOffset]);

  useEffect(() => {
    if (isPlaying) {
      setIsFinished(false);
      setWasPlayed(true);
    }
  }, [isPlaying, strokeOffset]);

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 20, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          onGoingBack={() => pause()}
          headerRightLabel={intl.formatMessage({
            id: 'post.next',
            defaultMessage: 'Next',
          })}
          title={intl.formatMessage({
            id: 'post.audioNote.createAudioNote',
            defaultMessage: 'Create audio note',
          })}
          headerRightOnPress={() => {
            pause();
            navigation.navigate(ARTIST_POST_SETTINGS_SCREEN_NAME, {
              audio: {
                file: fileBlob || {
                  uri: filePath,
                  type: 'audio/mp3',
                  name: Date.now() + '.mp3',
                },
                cover: profile?.profile_picture,
                snackbarThumbnailUri: profile?.profile_picture,
              },
              caption,
            });
          }}
          headerRightDisabled={isCaptionLink}
        />
      }
    >
      <AudioCardView>
        <AudioControls
          isPlaying={isPlaying}
          currentTime={positionMillis}
          wasAudioPlayed={wasPlayed}
          isAudioFinished={isFinished}
          duration={durationMillis}
          showCheckmark={false}
          artistPhotoUrl={profile?.low_res_profile_picture ? profile?.low_res_profile_picture : ''}
          onPlayPress={() => setIsPlaying(true)}
          onPausePress={() => setIsPlaying(false)}
          progressStrokeOffset={strokeOffset}
        />
      </AudioCardView>
      <View style={{ marginVertical: 30 }}>
        <TextInput
          returnKeyType="done"
          placeholder="Caption (optional)"
          value={caption}
          onChangeText={(v: string) => setCaption(v)}
          isError={isCaptionLink}
        />
        {caption && isCaptionLink && (
          <ErrorText style={{ marginTop: 5 }}>Links are not allowed here</ErrorText>
        )}
      </View>
    </ScreenView>
  );
};

export default ArtistAudioScreen;
