export default {
  logout: {
    id: 'settings.logout',
    defaultMessage: 'Log out',
  },
  savedPosts: {
    id: 'settings.savedPosts',
    defaultMessage: 'Saved posts',
  },
  settings: {
    id: 'settings.settings',
    defaultMessage: 'Settings',
  },
};
