import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import ErrorsView from 'containers/ProfileInformationsForm/styled/ErrorsView';
import ErrorText from 'containers/ProfileInformationsForm/styled/ErrorText';
import TextInput from 'containers/ProfileInformationsForm/styled/TextInput';

import FetchedDataContainer from 'components/FetchedDataContainer/FetchedDataContainer';
import Text from 'components/FormScreenStyled/Text';
import KeyboardAvoidingView from 'components/KeyboardAvoidingView';
import Button from 'components/LoginFormButton/LoginFormButton';
import WiseLogo from 'components/WiseLogo';

import { useUser } from 'hooks';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ScreenView from './styled/ScreenView';
import ScrollView from './styled/ScrollView';
import Section from './styled/Section';
import SectionTitle from './styled/SectionTitle';

export { SCREEN_NAME };

const ArtistContactAndDetailsScreen: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    clearErrors,
    errors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    email: string;
    password: string;
  }>({
    mode: 'onChange',
  });
  const { updateEmail, isUpdatingEmail, updateEmailError } = useUser();

  const intl = useIntl();

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(({ email, password }) => {
      updateEmail(email, password, () => setSuccess(true));
    })();
  };

  return (
    <ScreenView>
      <FetchedDataContainer>
        <KeyboardAvoidingView>
          <ScrollView>
            <WiseLogo m="30px 0 20px 0" />
            {success ? (
              <Section m="100px 0 0 0">
                <SectionTitle>{intl.formatMessage(messages.success.title)}</SectionTitle>
                <Text>{intl.formatMessage(messages.success.description)}</Text>
              </Section>
            ) : (
              <Section>
                <Text margin="25px 0 40px 0">{intl.formatMessage(messages.description)}</Text>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: intl.formatMessage(messages.error.emailRequired),
                    pattern: {
                      value: regex.email,
                      message: intl.formatMessage(messages.error.emailIncorrect),
                    },
                  }}
                  render={({ onChange, onBlur, value }) => (
                    <TextInput
                      label={intl.formatMessage(messages.email)}
                      autoCapitalize="none"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value: string) => {
                        clearErrors('email');
                        onChange(value);
                      }}
                      editable
                    />
                  )}
                />
                {!!errors.email && (
                  <ErrorsView>
                    <ErrorText>{errors?.email?.message}</ErrorText>
                  </ErrorsView>
                )}
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextInput
                      label={intl.formatMessage(messages.password)}
                      secureTextEntry={true}
                      returnKeyType="go"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value: any) => {
                        clearErrors('password');
                        onChange(value);
                      }}
                    />
                  )}
                  name="password"
                  rules={{
                    required: intl.formatMessage(messages.error.passwordRequired),
                  }}
                />
                {!!errors.password && (
                  <ErrorsView>
                    <ErrorText>{errors.password.message}</ErrorText>
                  </ErrorsView>
                )}
                {!!updateEmailError && (
                  <ErrorsView>
                    <ErrorText>{updateEmailError}</ErrorText>
                  </ErrorsView>
                )}
                <Button disabled={!isValid || isSubmitting || isUpdatingEmail} onPress={onSubmit}>
                  {intl.formatMessage(messages.submit)}
                </Button>
              </Section>
            )}
          </ScrollView>
        </KeyboardAvoidingView>
      </FetchedDataContainer>
    </ScreenView>
  );
};

ArtistContactAndDetailsScreen.displayName = 'ArtistContactAndDetailsScreen';

export default ArtistContactAndDetailsScreen;
