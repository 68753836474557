import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({
  short: true,
  labelColor: '#ffffff',
  bold: false,
})`
  border-radius: 40px;
  min-height: 32px;
  height: 36px;
  background-color: #0537ff;
  margin-right: 6px;
  font-size: 5px;
  width: 36px;
  justify-content: center;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 150px;
    `}
`;
