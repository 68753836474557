import { AxiosResponse } from 'axios';

import { useBackEnd } from 'context/backEnd';
import { ProductType, ReportReason } from 'types';

interface useReportingType {
  reportPost: (
    postId: number,
    productType: ProductType,
    artistUsername: string,
    reason: ReportReason,
  ) => Promise<AxiosResponse>;
  reportArtist: (artistUsername: string, reason: ReportReason) => Promise<AxiosResponse>;
  reportComment: (commentId: number | string) => Promise<AxiosResponse>;
  blockUser: (commentId: number | string) => Promise<AxiosResponse>;
}

export const useReporting = (): useReportingType => {
  const { axiosInstance } = useBackEnd();

  const reportPost = (
    postId: number,
    productType: ProductType,
    artistUsername: string,
    reason: ReportReason,
  ) => {
    const KEY = `artists/${artistUsername}/products/${productType}s/${postId}/report`;
    return axiosInstance.post(KEY, { reason: { identifier: reason } });
  };

  const reportArtist = (artistUsername: string, reason: ReportReason) => {
    const KEY = `artists/${artistUsername}/report`;
    return axiosInstance.post(KEY, { reason: { identifier: reason } });
  };

  const reportComment = (commentId: number | string) =>
    axiosInstance.post('comment_report', {
      comment: commentId,
    });

  const blockUser = (commentId: number | string) => {
    return axiosInstance.post('fan_block_from_comment', {
      fan: { on_comment: commentId },
    });
  };

  return { reportPost, reportArtist, reportComment, blockUser };
};
