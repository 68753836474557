import { CommonActions, RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_TAB_STACK_SCREEN_NAME } from 'stacks/artist/ArtistTabStack/constants';
import { SCREEN_NAME as HOME_STACK_SCREEN_NAME } from 'stacks/fan/FanHomeStack/constants';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_MY_PROFILE_SCREEN_NAME } from 'screens/ArtistMyProfileScreen/constants';
import { SCREEN_NAME as ARTIST_SINGLE_POST_SCREEN_NAME } from 'screens/ArtistSinglePostScreen/constants';

import ArtistProfileHeader from 'components/ArtistProfileHeader';
import NewMasonry from 'components/NewMasonry';
import OverlayButton from 'components/OverlayButton';

import { usePostsRefreshSet, usePostsRefreshState, WebviewContext } from 'context';
import {
  useArtistAudios,
  useArtistImages,
  useArtistPolls,
  useArtistVideos,
  useConfig,
  useUser,
} from 'hooks';
import { useMyLiveMoments } from 'hooks/liveMoments';
import { Artist, ArtistProduct } from 'types';
import { getCoverImage } from 'utils/user';

import * as Icons from '../ArtistProfileScreen/ArtistProfileScreen.icons';
import * as S from '../ArtistProfileScreen/ArtistProfileScreen.styled';
import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from '../LiveStreamPlaybackScreen/constants';
import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistMyProfileScreen: React.FC<Props> = ({ navigation }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isLoading: isLoadingConfig } = useConfig();
  const isFocused = useIsFocused();
  const intl = useIntl();
  const { profile: myProfile } = useUser();
  const profile = myProfile as Artist;
  const postsRefreshState = usePostsRefreshState();
  const postsRefreshSet = usePostsRefreshSet();
  const { openWebView } = useContext(WebviewContext);
  const { liveMoments } = useMyLiveMoments();
  const {
    isLoading: isLoadingImages,
    isValidating: isValidatingImages,
    images,
    mutate: mutateImages,
  } = useArtistImages();
  const {
    isLoading: isLoadingVideos,
    isValidating: isValidatingVideos,
    videos,
    mutate: mutateVideos,
  } = useArtistVideos();
  const {
    isLoading: isLoadingAudios,
    isValidating: isValidatingAudios,
    audios,
    mutate: mutateAudios,
  } = useArtistAudios();
  const {
    isLoading: isLoadingPolls,
    isValidating: isValidatingPolls,
    mutate: mutatePolls,
    polls,
  } = useArtistPolls();
  const isRefreshing =
    isLoadingConfig ||
    isLoadingImages ||
    isLoadingVideos ||
    isLoadingAudios ||
    isLoadingPolls ||
    isValidatingImages ||
    isValidatingVideos ||
    isValidatingAudios ||
    isValidatingPolls;

  const imagesData = (images || []).map((image) => ({
    ...image,
    model_name: 'image',
  }));
  const videosData = (videos && Array.isArray(videos) ? videos : []).map((video) => ({
    ...video,
    model_name: 'video',
  }));
  const audiosData = (audios && Array.isArray(audios) ? audios : []).map((audio) => ({
    ...audio,
    model_name: 'audio',
  }));
  const pollsData = (polls && Array.isArray(polls) ? polls : []).map((poll) => ({
    ...poll,
    model_name: 'poll',
  }));
  const allData = imagesData
    .concat(videosData)
    .concat(audiosData)
    .concat(pollsData)
    .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());

  useEffect(() => {
    const t = setTimeout(() => {
      if (isFocused) {
        navigation.dispatch((state) => {
          const routes = [];
          const profileRoute = state.routes.find((x) => x.name === ARTIST_MY_PROFILE_SCREEN_NAME);
          if (profileRoute) {
            routes.push(profileRoute);
          } else {
            routes.push({
              name: ARTIST_MY_PROFILE_SCREEN_NAME,
              key: `${ARTIST_MY_PROFILE_SCREEN_NAME}-${Date.now()}`,
            });
          }
          return CommonActions.reset({
            ...state,
            routes,
            index: 0,
          });
        });
      }
    }, 1000);
    return () => clearTimeout(t);
  }, [isFocused]);

  useEffect(() => {
    const sr = postsRefreshState.shouldRefresh;
    if (sr?.length) {
      if (sr.includes('images')) {
        console.log('[ArtistMyProfileScreen] mutating images');
        mutateImages(images, true);
      }
      if (sr.includes('videos')) {
        console.log('[ArtistMyProfileScreen] mutating videos');
        mutateVideos(videos, true);
      }
      if (sr.includes('audios')) {
        console.log('[ArtistMyProfileScreen] mutating audios');
        mutateAudios(audios, true);
      }
      if (sr.includes('polls')) {
        console.log('[ArtistMyProfileScreen] mutating polls');
        mutatePolls(polls, true);
      }
      postsRefreshSet({ ...postsRefreshState, shouldRefresh: [] });
    }
  }, [postsRefreshState.shouldRefresh]);

  const navigateBack = () => navigation.canGoBack() && navigation.goBack();

  const onRefresh = () => {
    // mutateArtist(artist, true);
    mutateImages(images, true);
    mutateVideos(videos, true);
    mutateAudios(audios, true);
    mutatePolls(polls, true);
  };

  const navigateToProduct = (product: ArtistProduct) => {
    if (!profile) {
      return;
    }

    let productWithArtist: ArtistProduct = product;
    if (!product.artist) {
      productWithArtist = {
        ...product,
        artist: { ...profile },
      };
    }

    navigation.navigate(ARTIST_SINGLE_POST_SCREEN_NAME, {
      artistUsername: profile.username,
      productType: product.model_name,
      productId: product.id,
      product: productWithArtist,
    });
  };

  const onLinkPress = useCallback(
    (url: string) => {
      openWebView?.(url);
    },
    [openWebView],
  );

  const onSeeInsightsPress = useCallback(() => {
    navigation.navigate(ARTIST_TAB_STACK_SCREEN_NAME, {
      screen: HOME_STACK_SCREEN_NAME,
    });
  }, [navigation]);

  const onStanCastArchivePress = () => {
    if (!liveMoments || !liveMoments[0] || !profile) return;
    navigation.navigate(LIVE_STREAM_PLAYBACK_SCREEN_NAME, {
      artistUsername: profile.username,
      url: liveMoments[0]?.mp4_url || '',
      isLive: false,
      isPastLiveMoment: true,
      liveMomentId: liveMoments[0].id,
      past_audience: liveMoments[0].live_stream_viewers_count,
      time_created: liveMoments[0].time_created,
    });
  };

  const tabs = [allData, audios, images, videos, polls];

  const renderTab = (index: number) => {
    const data = tabs[index] || [];
    if (data.length === 0)
      return <S.EmptyTabText>{intl.formatMessage(messages.emptyTab)}</S.EmptyTabText>;
    return <NewMasonry data={data as any[]} onProductPreview={navigateToProduct} />;
  };

  const renderHeader = () => (
    <>
      <ArtistProfileHeader
        name={profile?.display_name}
        imageUrl={profile?.profile_picture}
        stansCount={profile?.subscribers_count}
        followersCount={profile?.followers_count}
        isLive={profile?.is_live}
        description={profile?.bio}
        firstLink={profile?.primary_link}
        secondLink={profile?.secondary_link}
        onLinkPress={onLinkPress}
        onSeeInsightsPress={onSeeInsightsPress}
        onStanCastArchivePress={
          !!liveMoments && !!liveMoments[0] ? onStanCastArchivePress : undefined
        }
        isSubscribed
      />
      <S.TabBarWrapper>
        <Icons.All isFocused={activeIndex === 0} onPress={() => setActiveIndex(0)} />
        <Icons.Audio isFocused={activeIndex === 1} onPress={() => setActiveIndex(1)} />
        <Icons.Image isFocused={activeIndex === 2} onPress={() => setActiveIndex(2)} />
        <Icons.Video isFocused={activeIndex === 3} onPress={() => setActiveIndex(3)} />
        <Icons.Poll isFocused={activeIndex === 4} onPress={() => setActiveIndex(4)} />
      </S.TabBarWrapper>
    </>
  );

  return (
    <S.MainView>
      <S.FlatList
        coverImageUrl={getCoverImage(profile)}
        coverImageHeight={320}
        coverSpacerHeight={160}
        tabs={tabs.length}
        renderHeader={renderHeader}
        renderTab={renderTab}
        refreshing={isRefreshing}
        onRefresh={onRefresh}
        topTitle={profile?.display_name}
        activeTabIndex={activeIndex}
        setActiveTabIndex={setActiveIndex}
      />
      <S.OverlayView pointerEvents="box-none">
        <S.OverlayHeaderView>
          <OverlayButton icon="back" onPress={navigateBack} />
        </S.OverlayHeaderView>
      </S.OverlayView>
    </S.MainView>
  );
};

export default ArtistMyProfileScreen;
