import styled from 'styled-components/native';

export default styled.Text`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.26px;
  text-align: center;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primaryActive};
  margin: 0 0 8px 0;
`;
