import React, {
  createRef,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ViewabilityConfigCallbackPairs, ViewToken } from 'react-native';

import LiveMomentItem from 'components/LiveMomentItem';

import { LiveMoment } from 'types';
import { sortByBoolean } from 'utils/sort';

import Container from './styled/Container';
import ItemSeparator from './styled/ItemSeparator';
import List from './styled/List';

export interface AvatarListProps {
  data?: LiveMoment[];
  withPlaceholder?: boolean;
  onItemPress?: (artist: LiveMoment) => void;
}

const LiveMomentList: React.FC<AvatarListProps> = ({
  data = [],
  withPlaceholder,
  onItemPress,
  ...rest
}: AvatarListProps): JSX.Element | null => {
  const dataCollection = useMemo(() => {
    return withPlaceholder
      ? [...Array(3)].map((_: any, index: number) => ({
          artist: {
            username: `user${index}`,
          },
          id: index,
        }))
      : sortByBoolean<LiveMoment>(data || [], 'is_live');
  }, [withPlaceholder, data]);

  const itemsLength = dataCollection.length;
  const itemsReferences = useRef<any[]>([]);
  const activeItemsIndexes = useRef<any[]>([]);

  useEffect(() => {
    itemsReferences.current = [...Array(itemsLength)].map(
      (_, itemIndex) => itemsReferences.current[itemIndex] || createRef(),
    );
  }, [itemsLength]);

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      info.changed.forEach((changed) => {
        if (typeof changed.index === 'number') {
          if (changed.isViewable) {
            activeItemsIndexes.current.push(changed.index);
            itemsReferences.current[changed.index]?.current?.play?.();
          } else {
            activeItemsIndexes.current = activeItemsIndexes.current.filter(
              (itemIndex) => itemIndex !== changed.index,
            );
            itemsReferences.current[changed.index]?.current?.stop?.();
          }
        }
      });
    },
    [],
  );

  const viewabilityConfigPairs: MutableRefObject<ViewabilityConfigCallbackPairs> = useRef([
    {
      viewabilityConfig: {
        minimumViewTime: 20,
        waitForInteraction: false,
        itemVisiblePercentThreshold: 70,
      },
      onViewableItemsChanged,
    },
  ]);

  const extractKey = (liveMoment: LiveMoment, index: number): string => {
    return `live_moment.${liveMoment?.artist?.username || ''}.${index}`;
  };

  const renderItem = ({ item, index }: { item: unknown; index: number }): JSX.Element => (
    <LiveMomentItem
      ref={itemsReferences.current[index]}
      item={item as LiveMoment}
      onPress={onItemPress}
      withPlaceholder={withPlaceholder}
    />
  );

  const filteredData = useMemo(() => {
    return dataCollection.filter(
      (item: any) => !!withPlaceholder || !!item?.m3u8_url || !!item?.mp4_url,
    );
  }, [dataCollection]);

  if (!withPlaceholder && (!data || !data.length || !filteredData)) return null;

  return (
    <Container>
      <List
        {...rest}
        data={filteredData}
        windowSize={8}
        keyExtractor={extractKey}
        renderItem={renderItem}
        viewabilityConfigCallbackPairs={viewabilityConfigPairs.current}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={ItemSeparator}
        horizontal
      />
    </Container>
  );
};

LiveMomentList.displayName = 'LiveMomentList';

export default memo(LiveMomentList);
