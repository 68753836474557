import React, { useMemo } from 'react';
import styled from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

import { useArtistsFeed } from 'hooks';
import { ArtistFeed } from 'types';

import { followedArtistsPositions } from '../facesPositions';
import FacesView from './FacesView';
import Person from './Person';

const StanbaseLogo = styled.Image`
  position: absolute;
  width: 145px;
  height: 145px;
  bottom: 23%;
  right: 28%;
`;

interface Props {}

const FollowedArtists: React.FC<Props> = () => {
  const { data, isLoading } = useArtistsFeed();

  const profileImagesUrls = useMemo(
    () =>
      data
        ? data.map((profile: ArtistFeed) =>
            profile.medium_res_profile_picture
              ? profile.medium_res_profile_picture
              : profile.low_res_profile_picture,
          )
        : [],
    [data],
  );

  const renderFaces = () => {
    let imageIndex = 0;
    return followedArtistsPositions.map((face, index) => {
      if (imageIndex < profileImagesUrls.length) {
        face.source = profileImagesUrls[imageIndex];
        imageIndex++;
      } else {
        imageIndex = 0;
        face.source = profileImagesUrls[imageIndex];
        imageIndex++;
      }
      if (face.source) {
        return (
          <Person
            source={{ uri: face.source }}
            resizeMode="cover"
            width={face.width}
            height={face.height}
            bottom={face.bottom}
            right={face.right}
            key={`OnBoardingFace-${index}`}
          />
        );
      }
    });
  };

  return (
    <FacesView>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <StanbaseLogo source={require('../images/StanbaseLogo.png')} resizeMode="contain" />
          {renderFaces()}
        </>
      )}
    </FacesView>
  );
};

export default FollowedArtists;
