export default {
  cta: {
    id: 'blockedUsers.cta',
    defaultMessage: 'Unblock',
  },
  dialog: {
    title: {
      id: 'blockedUsers.dialog.title',
      defaultMessage: 'Unblock <name></name>',
    },
    description: {
      id: 'blockedUsers.dialog.description',
      defaultMessage:
        'You and <name></name> will now be able see each other comments on Stanbase. They won’t be notified that you unblocked them.',
    },
    cancel: {
      id: 'blockedUsers.dialog.cancel',
      defaultMessage: 'Cancel',
    },
  },
  snackbar: {
    title: {
      id: 'blockedUsers.snackbar.title',
      defaultMessage: '<name></name> unblocked.',
    },
    close: {
      id: 'blockedUsers.snackbar.close',
      defaultMessage: 'Close',
    },
  },
  error: {
    unblock: {
      id: 'blockedUsers.error.unblock',
      defaultMessage: 'Unexpected error has occurred.',
    },
  },
};
