import styled from 'styled-components/native';

export const Wrapper = styled.Pressable`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  width: 204px;
  padding: 0 8px;
`;

export const IconView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const Label = styled.Text`
  font-family: 'Tahoma';
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
`;
