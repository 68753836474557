import styled, { css } from 'styled-components/native';

import VideoProgressBar from 'components/VideoProgressBar';

interface Props {
  isFullscreen?: boolean;
}

export default styled(VideoProgressBar)<Props>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;

  ${({ isFullscreen }) =>
    isFullscreen &&
    css`
      bottom: 40px;
      left: 20px;
      right: 20px;
    `}
`;
