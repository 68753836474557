import styled, { css } from 'styled-components/native';

interface Props {
  mode: 'left' | 'right';
}

export default styled.View<Props>`
  flex: 1;
  max-width: 362px;
  ${({ mode }) => {
    if (mode === 'left') {
      return css`
        padding-right: 30px;
      `;
    } else if (mode === 'right') {
      return css`
        padding-left: 30px;
      `;
    }
  }}
`;
