import styled from 'styled-components/native';

import { OrderStatusTranslation } from 'types';

export default styled.View<{ status: OrderStatusTranslation }>`
  background-color: ${({ status, theme }) => (theme.orderStatus as any)[`${status}Bg`]};
  padding: 15px 12px 50px;
  text-align: center;
  width: 100%;
  align-self: flex-end;
`;
