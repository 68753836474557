import styled from 'styled-components/native';

export default styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    flexGrow: 1,
  },
}))<{ isWeb?: boolean }>`
  ${({ isWeb }) =>
    isWeb &&
    `
      max-width: 680px;
      padding: 136px 0;
      margin: 0 auto;
      width: 100%;
    `};
`;
