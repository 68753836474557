import React from 'react';
import styled from 'styled-components/native';

type Props = {
  icon?: any;
  label?: string;
  onPress?: any;
  noPadding?: boolean;
  disabled?: boolean;
};

const TouchableOpacity = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.button.backgroundOverlayDark};
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 3px 10px;
`;

const Text = styled.Text`
  color: #fff;
  margin-left: 5px;
  font-size: 13px;
  font-family: 'BasierCircle-Regular';
`;

const OptionButton: React.FC<Props> = ({ icon, label, onPress, noPadding, disabled }: Props) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        noPadding && {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        },
      ]}
    >
      {icon}
      {label && <Text>{label}</Text>}
    </TouchableOpacity>
  );
};

export default OptionButton;
