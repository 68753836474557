import React from 'react';
import Animated from 'react-native-reanimated';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';
import Fade from 'components/Transitions/Fade/Fade';

import logo from './images/logo.png';
import ArtistPhoto from './styled/ArtistPhoto';
import ButtonView from './styled/ButtonView';
import CardSvg from './styled/CardSvg';
import CardView from './styled/CardView';
import CheckmarkSvg from './styled/CheckmarkSvg';
import DurationText from './styled/DurationText';
import Logo from './styled/Logo';
import PlayingCirclesSvg from './styled/PlayingCirclesSvg';
import PlayButton from './styled/PlayPauseButton';
import ProgressSvg from './styled/ProgressSvg';
import StaticCirclesSvg from './styled/StaticCirclesSvg';
import View from './styled/View';

export interface AudioControlsProps {
  artistPhotoUrl?: string;
  isPlaying?: boolean;
  duration: number;
  currentTime: number;
  wasAudioPlayed: boolean;
  isAudioFinished: boolean;
  showCheckmark?: boolean;
  onPlayPress?: () => void | undefined;
  onPausePress?: () => void | undefined;
  progressStrokeOffset: Animated.SharedValue<number>;
}

const AudioControls: React.FC<AudioControlsProps> = ({
  artistPhotoUrl,
  isPlaying = false,
  duration,
  currentTime,
  wasAudioPlayed,
  isAudioFinished,
  showCheckmark = true,
  onPausePress,
  onPlayPress,
  progressStrokeOffset,
  ...restProps
}: AudioControlsProps) => {
  const formatMillis = (ms: number) => {
    const date = new Date(ms);
    return `${date.getMinutes().toFixed(0).padStart(2, '0')}:${date
      .getSeconds()
      .toFixed(0)
      .padStart(2, '0')}`;
  };

  const showPlayButton = !isPlaying || isAudioFinished;

  return (
    <View {...restProps}>
      <CardView>
        <CardSvg />
        <Logo source={logo} />
        <Fade isVisible={duration > 0}>
          <DurationText>{`${formatMillis(currentTime)} / ${formatMillis(duration)}`}</DurationText>
        </Fade>
        {showCheckmark && (
          <CheckmarkSvg wasAudioPlayed={wasAudioPlayed} isAudioFinished={isAudioFinished} />
        )}
        <ButtonView>
          {showPlayButton && (
            <Fade style={{ position: 'absolute' }}>
              <StaticCirclesSvg />
            </Fade>
          )}
          {!showPlayButton && (
            <Fade style={{ position: 'absolute' }}>
              <PlayingCirclesSvg />
            </Fade>
          )}
          <Fade isVisible={!showPlayButton} style={{ position: 'absolute' }}>
            <TouchableOpacity onPress={onPausePress}>
              <ArtistPhoto source={{ uri: artistPhotoUrl }} />
            </TouchableOpacity>
          </Fade>
          <Fade isVisible={showPlayButton} style={{ position: 'absolute' }}>
            <PlayButton onPress={onPlayPress} />
          </Fade>
          <ProgressSvg duration={duration} strokeOffset={progressStrokeOffset} />
        </ButtonView>
      </CardView>
    </View>
  );
};

export default AudioControls;
