import styled, { css } from 'styled-components/native';

export default styled.TouchableOpacity`
  align-self: flex-start;
  background-color: ${({ theme }) => theme.buttonWhitelabel.backgroundColor};
  height: 20px;
  justify-content: center;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;
