import React from 'react';
import styled from 'styled-components/native';

const Text = styled.Text`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.06px;
  color: #ffffff;
`;
const TouchableOpacity = styled.TouchableOpacity`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 27px;
  background: ${({ theme }) => theme.bottomSheet.badge};
  border-radius: 40px;
  align-self: center;
  margin: 31px auto 0 auto;
`;

interface Props {
  onRemove?: () => void;
}

const RemoveButton: React.FC<Props> = ({ onRemove }: Props) => {
  return (
    <TouchableOpacity onPress={onRemove}>
      <Text>Remove</Text>
    </TouchableOpacity>
  );
};

export default RemoveButton;
