import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.bottomSheet.text};
  font-weight: bold;
  font-size: 22px;
  letter-spacing: -0.26px;
  width: 66px;
  text-align: center;
  z-index: 3;
`;
