import { AxiosError, AxiosResponse } from 'axios';

import { useBackEnd } from 'context';
import { useAuthSwr } from 'hooks/swr';
import { BlockedUser } from 'types';

interface useBlockedUsersInterface {
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
  isLoading: boolean;
  unblock: (username: string) => Promise<AxiosResponse>;
  blockedUsers: BlockedUser[] | undefined;
  error: AxiosError;
}

export const useBlockedUsers = (): useBlockedUsersInterface => {
  const { axiosInstance } = useBackEnd();
  const {
    data: blockedUsers,
    isLoading,
    error,
    mutate,
  } = useAuthSwr<BlockedUser[]>({ key: '/auth/blocked_fans' });

  const unblock = (username: string) => axiosInstance.delete(`/auth/${username}/blocked_fan`);

  return {
    blockedUsers,
    isLoading,
    error,
    mutate,
    unblock,
  };
};
