export default {
  orderNumber: {
    id: 'marketplace.list.order',
    defaultMessage: 'Order no.:<id></id>',
  },
  delete: {
    id: 'marketplace.list.delete',
    defaultMessage: 'Delete',
  },
};
