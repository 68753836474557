import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TouchableOpacity, View } from 'react-native';

import { ParamList } from 'stacks/types';

import NodeCameraView, {
  NodeCameraStatus,
  NodeCameraViewRef,
} from 'components/NodeMediaClient/NodeCameraView';
import OptionModal, { ModalButton } from 'components/OptionModal';
import ViewersCountBadge from 'components/ViewersCountBadge';

import { useSnackbarSet } from 'context';
import { useBroadcast, useViewerCount } from 'hooks/broadcast';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Button from './styled/Button';
import CameraButtonView from './styled/CameraButtonView';
import CameraView from './styled/CameraView';
import CamRev from './styled/CamRev';
import CloseLivestreamIcon from './styled/CloseLivestreamIcon';
import Fade from './styled/Fade';
import Header from './styled/Header';
import ModalTextInput from './styled/ModalTextInput';
import Row from './styled/Row';
import SafeArea from './styled/SafeArea';
import StatusText from './styled/StatusText';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistLiveScreen: React.FC<Props> = ({ navigation }: Props) => {
  const { control, getValues } = useForm<{
    caption: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      caption: '',
    },
  });
  const vb = useRef<NodeCameraViewRef>(null);
  const intl = useIntl();
  const { createLiveStream, deleteLiveStream, liveStream } = useBroadcast();
  const [isGettingLive, setIsGettingLive] = useState(false);
  const [isEndModalOpen, setEndModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { viewerCount } = useViewerCount();
  const { setSnackbar } = useSnackbarSet();

  const outputUrl = useMemo(
    () =>
      liveStream?.stream_key
        ? `rtmps://global-live.mux.com:443/app/${liveStream?.stream_key}`
        : undefined,
    [liveStream?.stream_key],
  );

  const isLive = useMemo(() => !!outputUrl, [outputUrl]);

  const startBroadcast = () => {
    setMessage(intl.formatMessage(messages.connecting));
    setIsGettingLive(true);
    createLiveStream();
  };

  useEffect(() => {
    let timeout: number;
    if (outputUrl) {
      timeout = setTimeout(() => vb.current?.start(), 100);
    }
    return () => clearTimeout(timeout);
  }, [outputUrl]);

  const onCloseButtonPress = () => {
    if (isLive) {
      setEndModalOpen(true);
    } else {
      navigation.navigate('Home');
    }
  };

  const stopLivestream = () => {
    vb.current?.stop();
    setEndModalOpen(false);
    setSaveModalOpen(true);
  };

  const onSaveModalDontSavePress = () => {
    deleteLiveStream();
    navigation.navigate('Home');
  };

  const onEndModalCancelPress = () => {
    setEndModalOpen(false);
  };

  const saveLivestream = () => {
    console.debug('stream source', liveStream?.hls_signed_source);
    console.debug('caption', getValues('caption'));
    //todo: save livestream

    // navigation.navigate(ARTIST_POST_SETTINGS_SCREEN_NAME, {
    //   photoVideo: { media: { uri: liveStream?.hls_signed_source } },
    //   caption,
    // });
    deleteLiveStream();
    setSnackbar({
      label: intl.formatMessage(messages.snackbar.label),
      actionLabel: intl.formatMessage(messages.snackbar.cta),
      visible: true,
      dismissAfter: 4000,
    });
    navigation.navigate('Home');
  };

  const onLiveStreamStatus = (code: NodeCameraStatus) => {
    if (code === 2001) {
      setMessage(intl.formatMessage(messages.start));
      setTimeout(() => {
        setIsGettingLive(false);
        setMessage('');
      }, 2000);
    }
    if (isGettingLive && code === 2002) {
      setMessage(intl.formatMessage(messages.connectionError));
      setTimeout(() => {
        setIsGettingLive(false);
        setMessage('');
      }, 3000);
    }
  };

  return (
    <>
      <SafeArea>
        <Header>
          <View>
            {isLive && (
              <Fade>
                <ViewersCountBadge count={viewerCount} />
              </Fade>
            )}
          </View>
          <TouchableOpacity onPress={onCloseButtonPress}>
            <CloseLivestreamIcon />
          </TouchableOpacity>
        </Header>
        <CameraView>
          <NodeCameraView
            style={{ flex: 1 }}
            ref={vb}
            outputUrl={outputUrl}
            camera={{ cameraId: 1, cameraFrontMirror: true }}
            audio={{ bitrate: 32000, profile: 1, samplerate: 44100 }}
            video={{
              preset: 2,
              bitrate: 400000,
              profile: 1,
              fps: 24,
              videoFrontMirror: false,
            }}
            autopreview={true}
            onStatus={onLiveStreamStatus}
          />
          <CameraButtonView>
            {!isLive && !isGettingLive && <Button onPress={startBroadcast} />}
          </CameraButtonView>
          <CamRev onPress={() => vb.current?.switchCamera()} />
        </CameraView>
      </SafeArea>
      {isGettingLive && (
        <Fade>
          <StatusText>{message}</StatusText>
        </Fade>
      )}
      <OptionModal isVisible={isEndModalOpen} title={intl.formatMessage(messages.end)}>
        <ModalButton warning onPress={stopLivestream}>
          {intl.formatMessage(messages.endFinal)}
        </ModalButton>
        <ModalButton bold onPress={onEndModalCancelPress}>
          {intl.formatMessage(messages.cancel)}
        </ModalButton>
      </OptionModal>
      <OptionModal isVisible={isSaveModalOpen} title={intl.formatMessage(messages.addCaption)}>
        <Controller
          name="caption"
          control={control}
          render={({ onChange, onBlur, value }) => (
            <ModalTextInput
              placeholder={intl.formatMessage(messages.captionPlaceholder)}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
            />
          )}
        />
        <Row>
          <ModalButton bold onPress={onSaveModalDontSavePress}>
            {intl.formatMessage(messages.dontSave)}
          </ModalButton>
          <ModalButton onPress={saveLivestream}>{intl.formatMessage(messages.save)}</ModalButton>
        </Row>
      </OptionModal>
    </>
  );
};

export default ArtistLiveScreen;
