export default {
  headerTitle: {
    id: 'profile.dashboard.header.title',
    defaultMessage: 'Hello,\n{fullName}! 👋',
  },
  headerTitleNoName: {
    id: 'profile.dashboard.header.title.noName',
    defaultMessage: 'Hello! 👋',
  },
  headerTitleArtist: {
    id: 'profile.dashboard.header.title.artist',
    defaultMessage: 'Settings',
  },
  liveEventBookings: {
    id: 'profile.dashboard.settings.liveEventBookings',
    defaultMessage: 'Live event bookings',
  },
  bookmarks: {
    id: 'profile.dashboard.settings.bookmarks',
    defaultMessage: 'Bookmarks',
  },
  subscriptions: {
    id: 'profile.dashboard.settings.subscriptions',
    defaultMessage: 'Subscriptions',
  },
  blockedUsers: {
    id: 'profile.dashboard.settings.blockedUsers',
    defaultMessage: 'Blocked Users',
  },
  profile: {
    id: 'profile.dashboard.settings.profile',
    defaultMessage: 'Profile Settings',
  },
  contactAndPayments: {
    id: 'profile.dashboard.settings.contactAndPayments',
    defaultMessage: 'Contact Details & Payments',
  },
  notifications: {
    id: 'profile.dashboard.settings.notifications',
    defaultMessage: 'Notifications',
  },
  paymentMethods: {
    id: 'profile.dashboard.settings.paymentMethods',
    defaultMessage: 'Payment Methods',
  },
  subscriptionPrice: {
    id: 'profile.dashboard.settings.subscriptionPrice',
    defaultMessage: 'Subscription Price',
  },
  help: {
    id: 'profile.dashboard.settings.help',
    defaultMessage: 'Help',
  },
  privacyPolicy: {
    id: 'profile.dashboard.settings.privacyPolicy',
    defaultMessage: 'Legal & Privacy',
  },
  logOut: {
    id: 'profile.dashboard.settings.logOut',
    defaultMessage: 'Logout',
  },
};
