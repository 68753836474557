import { LinkingOptions } from '@react-navigation/native';
import { Linking } from 'react-native';

import {
  linking as newPostStackConfigMap,
  SCREEN_NAME as ARTIST_NEW_POST_STACK_SCREEN_NAME,
} from 'stacks/artist/ArtistNewPostStack';
import {
  pathConfigMap as artistTabStackPathConfigMap,
  SCREEN_NAME as ARTIST_TAB_STACK_SCREEN_NAME,
} from 'stacks/artist/ArtistTabStack';
import {
  pathConfigMap as loginStackConfigMap,
  SCREEN_NAME as LOGIN_STACK_SCREEN_NAME,
} from 'stacks/common/LoginStack';

import { SCREEN_NAME as ARTIST_SINGLE_POST_SCREEN_NAME } from 'screens/ArtistSinglePostScreen';
import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from 'screens/LiveStreamPlaybackScreen';
import { SCREEN_NAME as MARKETPLACE_NEW_MESSAGE_SCREEN } from 'screens/MarketplaceNewMessageScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME } from 'screens/MarketplaceOrderInformationScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen';

export const linking: LinkingOptions<any> = {
  prefixes: ['https://artist.stanbase.com', `com.stanbase.artist://`],
  subscribe(listener: (arg: string) => any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);
    Linking.addEventListener('url', onReceiveURL);
    return () => {
      Linking.removeEventListener('url', onReceiveURL);
    };
  },
  config: {
    screens: {
      [ARTIST_TAB_STACK_SCREEN_NAME]: {
        path: '/',
        screens: artistTabStackPathConfigMap,
      },
      [ARTIST_NEW_POST_STACK_SCREEN_NAME]: {
        path: '/new-post',
        screens: newPostStackConfigMap,
      },
      [LOGIN_STACK_SCREEN_NAME]: {
        path: '/auth',
        screens: loginStackConfigMap,
      },
      [ARTIST_SINGLE_POST_SCREEN_NAME]: '/artist-single-post',
      [MARKETPLACE_ORDER_DETAILS_SCREEN_NAME]: 'marketplace/order-details/:orderId',
      [MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME]: 'marketplace/order-details/:orderId/information',
      [MARKETPLACE_ORDER_REPORT_SCREEN_NAME]: 'marketplace/order-details/:orderId/report',
      [MARKETPLACE_NEW_MESSAGE_SCREEN]: 'marketplace/order-details/:orderId/new-message',
      [LIVE_STREAM_PLAYBACK_SCREEN_NAME]: '/live-stream',
    },
  },
};
