import { RouteProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as WELCOME_SCREEN_ARTIST_NAME } from 'screens/WelcomeScreen';

import KeyboardAvoidingView from 'components/KeyboardAvoidingView';

import { useArtistOnBoarding } from 'context';
import { useBackEnd } from 'context';
import usePasswordChecks from 'hooks/usePasswordChecks';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import ErrorsView from './styled/ErrorsView';
import ErrorText from './styled/ErrorText';
import ScreenView from './styled/ScreenView';
import ScrollView from './styled/ScrollView';
import SubmitButton from './styled/SubmitButton';
import Subtitle from './styled/Subtitle';
import TextInput from './styled/TextInput';
import Title from './styled/Title';
import WiseLogo from './styled/WiseLogo';

export { SCREEN_NAME };

type ArtistOnBoardingScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ArtistOnBoardingScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ArtistOnBoardingScreenRouteProp;
};

const ArtistOnBoardingScreen: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const [submitError, setSubmitError] = useState<string>();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const repeatPasswordInputRef = useRef(null);
  const { validatePassword, passwordChecks } = usePasswordChecks({
    missingUpperOrLowerCase: intl.formatMessage(
      messages.passwordInput.error.missingUpperOrLowerCase,
    ),
    missingNumber: intl.formatMessage(messages.passwordInput.error.missingNumber),
    missingSpecialCharacter: intl.formatMessage(
      messages.passwordInput.error.missingSpecialCharacter,
    ),
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const { axiosInstance } = useBackEnd();
  const { oldPassword, setOldPassword, oldEmail, setOldEmail } = useArtistOnBoarding();
  const navigation = useNavigation();

  useEffect(() => {
    let timeout;
    if (isSubmitSuccess) {
      timeout = setTimeout(() => {
        navigation.navigate(WELCOME_SCREEN_ARTIST_NAME);
      }, 6000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSubmitSuccess]);

  const {
    control,
    handleSubmit,
    errors,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    email: string;
    password: string;
    repeatPassword: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      repeatPassword: '',
    },
  });

  const onValidSubmit = async (args: {
    email: string;
    password: string;
    repeatPassword: string;
  }) => {
    setSubmitError(undefined);
    const { email, password, repeatPassword } = args;
    try {
      const response = await axiosInstance.post('/auth/artist/onboarding', {
        email: oldEmail,
        new_email: email,
        password: oldPassword,
        new_password1: password,
        new_password2: repeatPassword,
      });
      setSubmitSuccess(true);
      setOldPassword('');
      setOldEmail('');
    } catch (e) {
      setErrorMessage(e.response.data.detail);
      console.debug(e.response.data);
    }
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  return (
    <ScreenView>
      {isSubmitSuccess ? (
        <>
          <WiseLogo />
          <Title>Success!</Title>
          <Subtitle>
            Please confirm your address by clicking the link in the e-mail you received. This will
            also update your login credentials.
          </Subtitle>
        </>
      ) : (
        <KeyboardAvoidingView>
          <ScrollView>
            <Title>Hi !</Title>
            <Subtitle>
              Stanbase use the clever new way to send money abroad to pay our artists straight in to
              their bank accounts.
            </Subtitle>
            <Subtitle>
              Please confirm the email address you would like to use to receive your money.
            </Subtitle>
            <Subtitle>
              The email address you type below will become your new username for the Stanbase Artist
              app.
            </Subtitle>
            <Container>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextInput
                    ref={emailInputRef}
                    label={intl.formatMessage(messages.emailInput.label)}
                    placeholder={intl.formatMessage(messages.emailInput.placeholder)}
                    keyboardType="email-address"
                    returnKeyType="next"
                    autoCapitalize="none"
                    value={value}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      clearErrors('email');
                      onChange(value);
                    }}
                    onSubmitEditing={() => (passwordInputRef.current as any)?.focus()}
                    error={!!errors.email}
                  />
                )}
                name="email"
                rules={{
                  required: intl.formatMessage(messages.emailInput.error.required),
                  pattern: {
                    value: regex.email,
                    message: intl.formatMessage(messages.emailInput.error.invalid),
                  },
                }}
              />
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextInput
                    ref={passwordInputRef}
                    label={'New Password'}
                    placeholder={'New Password'}
                    secureTextEntry={true}
                    returnKeyType="go"
                    value={value}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value);
                    }}
                    error={!!errors.password}
                    onSubmitEditing={onSubmit}
                  />
                )}
                name="password"
                rules={{
                  validate: {
                    combined: validatePassword,
                  },
                }}
              />
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextInput
                    ref={repeatPasswordInputRef}
                    label={'Confirm Password'}
                    placeholder={'Confirm Password'}
                    secureTextEntry={true}
                    returnKeyType="go"
                    value={value}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value);
                    }}
                    error={!!errors.repeatPassword}
                    onSubmitEditing={onSubmit}
                  />
                )}
                name="repeatPassword"
                rules={{
                  validate: {
                    combined: validatePassword,
                  },
                }}
              />

              {!!submitError && (
                <ErrorsView>
                  <ErrorText>{submitError}</ErrorText>
                </ErrorsView>
              )}

              {!!errorMessage && (
                <ErrorsView>
                  <ErrorText>{errorMessage}</ErrorText>
                </ErrorsView>
              )}

              <SubmitButton disabled={!isValid} processing={isSubmitting} onPress={onSubmit}>
                Confirm
              </SubmitButton>
            </Container>
          </ScrollView>
        </KeyboardAvoidingView>
      )}
    </ScreenView>
  );
};

ArtistOnBoardingScreen.displayName = 'ArtistOnBoardingScreen';

export default ArtistOnBoardingScreen;
