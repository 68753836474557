import React from 'react';
import { useIntl } from 'react-intl';

import Item from 'components/ArtistsList/styled/Item';
import List from 'components/ArtistsList/styled/List';
import FetchedDataContainer from 'components/FetchedDataContainer';

import { useSnackbarSet } from 'context';
import { useBlockedUsers } from 'hooks';
import { BlockedUser } from 'types';

import messages from './messages';
import Action from './styled/Action';
import Avatar from './styled/Avatar';
import MainView from './styled/MainView';
import Separator from './styled/Separator';

interface Props {
  containerWidth?: number;
}

const BlockedUsers: React.FC<Props> = ({ containerWidth }: Props) => {
  const intl = useIntl();
  const { blockedUsers, mutate, error, isLoading, unblock } = useBlockedUsers();
  const { setSnackbar } = useSnackbarSet();
  const extractKey = (item: unknown): string => `${(item as BlockedUser).username}`;

  const shortenName = (name: string) => (name.length > 22 ? `${name.substring(0, 22)}...` : name);

  const onUnblockPress = async (username: string) => {
    let snackbarTitle;
    const shortenUsername = { name: () => shortenName(username) };
    try {
      await unblock(username);
      await mutate();
      snackbarTitle = intl.formatMessage(messages.snackbar.title, shortenUsername);
    } catch (e) {
      console.error(e);
      snackbarTitle = intl.formatMessage(messages.error.unblock);
    } finally {
      setSnackbar({
        visible: true,
        label: snackbarTitle,
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
    }
  };

  const renderItem = ({ item }: { item: any }) => {
    const typedItem = item as BlockedUser;
    const name = shortenName(typedItem.full_name || typedItem.username);

    return (
      <Item containerWidth={containerWidth}>
        <Avatar mode="minimal-row-big" name={name} imageUrl={typedItem.profile_picture} />
        <Action
          onPress={() => {
            onUnblockPress(typedItem.username);
          }}
        >
          Unblock
        </Action>
      </Item>
    );
  };

  return (
    <MainView>
      <FetchedDataContainer isLoading={isLoading} isError={!!error}>
        <List
          data={blockedUsers}
          keyExtractor={extractKey}
          renderItem={renderItem}
          ItemSeparatorComponent={Separator}
        />
      </FetchedDataContainer>
    </MainView>
  );
};

export default BlockedUsers;
