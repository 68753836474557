import { useEffect, useState } from 'react';

import { getTimestampText } from 'utils/date';

export interface UseTimeAgo {
  timeAgoText: string;
}

export function useTimeAgo(timestamp: Date | undefined, interval: number | undefined): UseTimeAgo {
  const [timeAgoText, setTimeAgoText] = useState<string>(getTimestampText(timestamp));

  useEffect(() => {
    setTimeAgoText(getTimestampText(timestamp));

    const intervalHandler = setInterval(() => {
      setTimeAgoText(getTimestampText(timestamp));
    }, interval);

    return () => {
      clearInterval(intervalHandler);
    };
  }, [timestamp]);

  return {
    timeAgoText,
  };
}
