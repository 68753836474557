import styled from 'styled-components/native';

export default styled.Text<{ selected: boolean }>`
  color: ${({ theme, selected }) => (selected ? theme.colors.text : theme.colors.primaryGhost)};
  font-size: 15px;
  font-family: 'BasierCircle-Medium';
  letter-spacing: -0.23px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.backgroundDark : 'transparent'};
  width: 100%;
  padding: 8px 15px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
`;
