import styled from 'styled-components/native';

export default styled.View<{ voted?: boolean; index?: number }>`
  position: relative;
  border-color: ${({ theme }) => theme.pollContent.primary};
  border-width: 2px;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.pollContent.background};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  height: 50px;
`;
