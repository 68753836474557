import styled from 'styled-components/native';

import { MenuItem } from '../NavigationMenu';

interface Props {
  item: MenuItem;
}

export default styled.Text<Props>`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: #ffffff;
`;
