import { useBackEnd } from 'context/backEnd';

const SUPPORT_KEY = '/support/request';

interface MessagePayload {
  email: string;
  subject: string;
  description: string;
}

export function useSupport(): {
  sendMessage: (payload: MessagePayload) => Promise<any>;
} {
  const { axiosInstance } = useBackEnd();

  const sendMessage = async (payload: MessagePayload) => {
    return await axiosInstance.post(SUPPORT_KEY, payload);
  };

  return { sendMessage };
}
