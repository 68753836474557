import React from 'react';
import Svg, { G, Path, Polygon, SvgProps } from 'react-native-svg';
import styled from 'styled-components';

const WiseLogo: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg id="Layer_1" x="0px" y="0px" viewBox="0 0 527.8 128.2" {...props}>
      <G id="Blue_White">
        <G id="Wise_11_">
          <Path
            fill="#FFFFFF"
            d="M522.1,56.8c0-30-20.9-51.1-50.2-51.1c-37.3,0-62.3,27.1-62.3,65.8c0,30.2,21.1,51.1,50.9,51.1
			c27.1,0,47.3-13.1,55.6-34H486c-4,6.5-12.5,10.6-22.9,10.6c-16,0-26-10.6-26.9-25.6h84.2C521.5,67.9,522.1,63,522.1,56.8z
			 M438.5,53.3c2.7-13.8,16.1-24.8,32.3-24.8c14,0,24.6,10.2,24.6,24.8H438.5z"
          />
          <Path
            fill="#FFFFFF"
            d="M328.7,119.9l5.4-28.8c16.5,3.3,19-6,23.3-28.4l2.1-11.1c6-31.3,18.1-46.9,52.7-42.7l-5.4,28.8
			c-16.5-3.3-19.4,9.2-23.1,28.6l-2.1,11.1C375.6,109.1,363.1,124.1,328.7,119.9z"
          />
          <Polygon fill="#FFFFFF" points="288.3,120 308.7,8.4 335.6,8.4 315.3,120 		" />
          <Polygon
            fill="#FFFFFF"
            points="134.2,8.4 160.3,8.4 168.8,89.1 203,8.4 228.9,8.4 238,89.9 271,8.4 297,8.4 249.7,120 220.1,120 
			210.5,43.2 177.8,120 149.2,120 		"
          />
        </G>
        <Polygon
          id="FF_8_"
          fill="#00B9FF"
          points="36.7,38 5.7,67.6 58.4,67.6 63.9,54.7 37.2,54.7 53.9,38 44.2,21.3 89.5,21.3 47.7,120 
		63.4,120 110.7,8.4 19.3,8.4 	"
        />
      </G>
    </Svg>
  );
};

const StyledWiseLogo = styled(WiseLogo)`
  width: 180px;
  height: 60px;
  margin-bottom: 150px;
  align-self: center;
`;

export default StyledWiseLogo;
