import React, { memo, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Platform } from 'react-native';
import { Extrapolation, interpolate, useSharedValue } from 'react-native-reanimated';

import LockedItem from 'components/LockedItem';
import { LockedItemProps } from 'components/LockedItem/LockedItem';

import useAudio from 'hooks/useAudio';

import { DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET } from '../AudioControls/styled/ProgressSvg';
import AudioControls from './styled/AudioControls';
import View from './styled/View';

export interface FeedItemAudioContentProps {
  access?: {
    hasAccess: boolean;
  };
  artistPhotoUrl?: string;
  url?: string;
  autoPlay?: boolean;
  teaserUrl?: string;
  dataIndex?: number;
  onLoad?: () => void;
  onIsPlayingChange?: (isPlaying: boolean) => void;
  refActivate?: (index: number, activateFn: (() => void) | null) => void;
  refDeactivate?: (index: number, deactivateFn: (() => void) | null) => void;
  lockedItemProps?: LockedItemProps;
}

const FeedItemAudioContent: React.FC<FeedItemAudioContentProps> = ({
  artistPhotoUrl,
  access,
  url,
  autoPlay = false,
  teaserUrl,
  dataIndex,
  onIsPlayingChange,
  refActivate,
  refDeactivate,
  lockedItemProps,
  ...restProps
}: FeedItemAudioContentProps) => {
  const strokeOffset = useSharedValue(DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET);
  const [durationMillis, setDurationMillis] = useState(0);
  const [positionMillis, setPositionMillis] = useState(0);
  const [wasPlayed, setWasPlayed] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const uri = useMemo(
    () => (access?.hasAccess !== false ? url : teaserUrl) || '',
    [access?.hasAccess, teaserUrl, url],
  );
  const { pause, isLoadingAudio, isPlaying, setIsPlaying, setOnPlaybackStatusUpdate, seek } =
    useAudio(
      {
        uri,
      },
      { autoPlay: false },
    );

  useEffect(() => {
    if (durationMillis > 0) {
      strokeOffset.value = interpolate(
        positionMillis / durationMillis,
        [0, 1],
        [DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET, 0],
        { extrapolateLeft: Extrapolation.CLAMP, extrapolateRight: Extrapolation.CLAMP },
      );
    }
  }, [durationMillis, positionMillis, strokeOffset]);

  useEffect(() => {
    setOnPlaybackStatusUpdate((status) => {
      if (status.isLoaded) {
        setPositionMillis(status.positionMillis);
        if (status.durationMillis) {
          setDurationMillis(status.durationMillis);
        }
        if (status.didJustFinish) {
          setIsPlaying(false);
          setIsFinished(true);
        }
      }
    });

    return () => {
      pause();
      setOnPlaybackStatusUpdate(null);
    };
  }, [pause, setIsPlaying, setOnPlaybackStatusUpdate]);

  useEffect(() => {
    if (isFinished) {
      strokeOffset.value = DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET;
      seek(0);
    }
  }, [isFinished, seek, strokeOffset]);

  useEffect(() => {
    if (!isLoadingAudio && autoPlay && access?.hasAccess) {
      setIsPlaying(true);
    }
  }, [access?.hasAccess, autoPlay, isLoadingAudio, setIsPlaying]);

  useEffect(() => {
    onIsPlayingChange?.(isPlaying);
    if (isPlaying) {
      setIsFinished(false);
      setWasPlayed(true);
    }
  }, [isPlaying, onIsPlayingChange, strokeOffset]);

  useEffect(() => {
    const effect = async () => {
      if (Platform.OS === 'web') return;
      refActivate &&
        typeof dataIndex !== 'undefined' &&
        refActivate(dataIndex, () => {
          setIsPlaying(true);
        });
      refDeactivate &&
        typeof dataIndex !== 'undefined' &&
        refDeactivate(dataIndex, () => {
          setIsPlaying(false);
        });
    };
    effect();
    return () => {
      refActivate && typeof dataIndex !== 'undefined' && refActivate(dataIndex, null);
      refDeactivate && typeof dataIndex !== 'undefined' && refDeactivate(dataIndex, null);
    };
  }, [dataIndex, refActivate, refDeactivate, setIsPlaying]);

  return (
    <View {...restProps}>
      {access?.hasAccess === false ? (
        <LockedItem type="audio" {...lockedItemProps} />
      ) : (
        <AudioControls
          isPlaying={isPlaying}
          currentTime={positionMillis}
          duration={durationMillis}
          wasAudioPlayed={wasPlayed}
          isAudioFinished={isFinished}
          onPlayPress={() => setIsPlaying(true)}
          onPausePress={() => setIsPlaying(false)}
          progressStrokeOffset={strokeOffset}
          artistPhotoUrl={artistPhotoUrl}
        />
      )}
    </View>
  );
};

export default memo(FeedItemAudioContent, isEqual);
