import styled from 'styled-components/native';

export default styled.View<{ isLive?: boolean; withPlaceholder?: boolean }>`
  align-items: center;
  background-color: ${({ withPlaceholder, theme }) =>
    withPlaceholder ? theme.colors.backgroundDark : 'transparent'};
  border-radius: 10px;
  height: 164px;
  overflow: hidden;
  width: 164px;
`;
