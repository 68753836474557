import styled from 'styled-components/native';

import TouchableOpacity from 'components/Button/styled/TouchableOpacity';

export default styled(TouchableOpacity)`
  margin: 20px 0 0;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
`;
