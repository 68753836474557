import styled, { css } from 'styled-components/native';

export default styled.Text`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.52);
  letter-spacing: -0.23px;
  text-align: center;
  margin-top: 25px;
  font-family: 'BasierCircle-Regular';

  ${({ onPress }) =>
    onPress &&
    css`
      color: #bcffc9;
    `}
`;
