import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { mutate as globalMutate } from 'swr';

import { generateProductKey } from 'hooks/artists';
import { useAuthSwrInfinite } from 'hooks/swr';
import { ArtistProduct, Feed } from 'types';
import { FeedItemUpdateFn } from 'types/Feed';

export const FEED_BASE_KEY = '/feed';

interface FeedState {
  feed?: Feed;
  isLoadingInitial: boolean;
  isLoadingMore: boolean;
  isEmpty: boolean;
  hasReachedEnd: boolean;
  isValidating: boolean;
  isRefreshing: boolean;
  error?: AxiosError<any>;
}

interface FeedHandlers {
  fetchMore: () => void;
  refresh: () => void;
}

interface FeedItemUpdate {
  updateFeedItem: FeedItemUpdateFn;
}

const DEFAULT_FEED_STATE: FeedState = {
  feed: [],
  isLoadingInitial: false,
  isLoadingMore: false,
  isEmpty: true,
  hasReachedEnd: false,
  isValidating: false,
  isRefreshing: false,
};

const DEFAULT_FEED_HANDLERS: FeedHandlers = {
  fetchMore: () => {},
  refresh: () => {},
};

const DEFAULT_FEED_ITEM_UPDATE: FeedItemUpdate = {
  updateFeedItem: () => {},
};

const FeedStateContext = React.createContext<FeedState>(DEFAULT_FEED_STATE);
const FeedHandlersContext = React.createContext<FeedHandlers>(DEFAULT_FEED_HANDLERS);
const FeedItemUpdateContext = React.createContext<FeedItemUpdate>(DEFAULT_FEED_ITEM_UPDATE);

export function useFeedState(): FeedState {
  const context = React.useContext<FeedState>(FeedStateContext);
  if (context === undefined) {
    throw new Error('useFeedState must be used within a FeedProvider');
  }

  return context;
}

export function useFeedHandlers(): FeedHandlers {
  const context = React.useContext(FeedHandlersContext);
  if (context === undefined) {
    throw new Error('useFeedHandlers must be used within a FeedProvider');
  }

  return context;
}

export function useFeedItemUpdate(): FeedItemUpdate {
  const context = React.useContext(FeedItemUpdateContext);
  if (context === undefined) {
    throw new Error('useFeedItemUpdate must be used within a FeedProvider');
  }

  return context;
}

interface Props {
  children: React.ReactNode;
}

export const FeedProvider: React.FC<Props> = ({ children }: Props) => {
  const getKey = useCallback((pageIndex: number, previousPageData: Feed | null) => {
    // reached the end
    if (previousPageData && previousPageData.length === 0) {
      return null;
    }

    // first page, so we don't provide the timestamp
    if (pageIndex === 0 || previousPageData === null) {
      return FEED_BASE_KEY;
    }

    // all consecutive pages
    return `${FEED_BASE_KEY}?time_created=${
      previousPageData[previousPageData.length - 1].time_created
    }`;
  }, []);

  const {
    isLoadingInitial,
    isLoadingMore,
    isValidating,
    isRefreshing,
    isEmpty,
    hasReachedEnd,
    data,
    flattenedData: feed,
    error,
    fetchMore,
    refresh,
    mutate,
  } = useAuthSwrInfinite({
    getKey,
    pageSize: 20,
    isPublic: false,
    cache: true,
    swrOptions: {
      revalidateAll: false,
    },
  });

  const updateFeedItem: FeedItemUpdateFn = async (
    product,
    overrideFeedItemProps,
    revalidate = true,
  ) => {
    if (!data) {
      return;
    }

    // `data` is paged data, i.e. it's a Feed[] array, a Feed per "page" (pagination)
    const newData = [...data];
    let foundItem = false;

    for (let i = 0; i < data.length; ++i) {
      const batch = data[i];

      const itemIndex = batch.findIndex(
        (p) => product.id.toString() === p.id.toString() && product.model_name === p.model_name,
      );

      if (itemIndex !== -1) {
        const newProduct: ArtistProduct = {
          ...newData[i][itemIndex],
          // ...product,
          ...overrideFeedItemProps,
        };

        // Update single item
        // console.log(
        //   '[feed] update single item',
        //   generateProductKey(product.artist.username, product.model_name, product.id),
        //   newProduct,
        //   revalidate,
        // );
        globalMutate(
          generateProductKey(product.artist.username, product.model_name, product.id),
          newProduct,
          revalidate,
        );

        // Update feed
        newData[i][itemIndex] = newProduct;
        mutate(newData);

        foundItem = true;
        break;
      }
    }

    if (!foundItem) {
      //product isn't on user's feed so we only update single item
      // console.log(
      //   '[feed] update global',
      //   generateProductKey(product.artist.username, product.model_name, product.id),
      //   product,
      //   {
      //     ...product,
      //     ...overrideFeedItemProps,
      //   },
      // );
      globalMutate(
        generateProductKey(product.artist.username, product.model_name, product.id),
        {
          ...product,
          ...overrideFeedItemProps,
        },
        revalidate,
      );
    }
  };

  return (
    <FeedStateContext.Provider
      value={{
        feed,
        isLoadingInitial,
        isLoadingMore,
        isValidating,
        isEmpty,
        isRefreshing,
        hasReachedEnd,
        error,
      }}
    >
      <FeedHandlersContext.Provider
        value={{
          fetchMore,
          refresh,
        }}
      >
        <FeedItemUpdateContext.Provider
          value={{
            updateFeedItem,
          }}
        >
          {children}
        </FeedItemUpdateContext.Provider>
      </FeedHandlersContext.Provider>
    </FeedStateContext.Provider>
  );
};
