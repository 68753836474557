import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface SvgComponentProps {
  fill?: boolean;
  isProcessing?: boolean;
  height?: number;
  width?: number;
}

const SvgComponent: React.FC<SvgComponentProps> = ({
  fill,
  isProcessing,
  height = 20,
  width = 16,
  ...rest
}: SvgComponentProps) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill={(!isProcessing && !fill && 'none') || (!isProcessing && fill && '#fff')}
      {...rest}
    >
      <Path
        d="M15 19l-7-5-7 5V3a2 2 0 012-2h10a2 2 0 012 2v16z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
