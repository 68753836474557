import styled from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  flex: 1;
  height: 100%;
  max-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
`;
