import { Artist, Comment, UserProfile } from 'types';

export const getProfileLowResPictureUrl = (user?: UserProfile | Artist | Comment): string => {
  const untypedUser = user as any;

  if (untypedUser?.low_res_profile_picture) {
    return untypedUser?.low_res_profile_picture;
  }

  return untypedUser?.profile_picture || untypedUser?.cover_image || '';
};

export const getCoverImage = (user?: UserProfile | Artist | Comment): string => {
  const untypedUser = user as any;
  return (
    untypedUser?.cover_image ||
    untypedUser?.profile_picture ||
    untypedUser?.low_res_profile_picture ||
    ''
  );
};

export const getAge = (date: string): number => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isAgeRestrictedUser = (profile: UserProfile | undefined): boolean => {
  if (!profile) return false;
  return profile?.date_of_birth ? getAge(profile.date_of_birth) < 18 : true;
};
