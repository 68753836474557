export default {
  subscribeWithApple: {
    id: 'payment.prompt.subscribeWithApple',
    defaultMessage: 'Subscribe',
  },
  tipWithApple: {
    id: 'payment.prompt.tipWithApple',
    defaultMessage: 'Pay for tip',
  },
  payForPostWithApple: {
    id: 'payment.prompt.payForPostWithApple',
    defaultMessage: 'Pay for post',
  },
  priceFixed: {
    id: 'payment.prompt.price.fixed',
    defaultMessage: '{currency}{price}',
  },
  priceMonthly: {
    id: 'payment.prompt.price.monthly',
    defaultMessage: '{currency}{price}/month',
  },
  disclaimer: {
    id: 'payment.prompt.disclaimer',
    defaultMessage:
      'By purchasing you agree to our general <termsCta>Terms of Use</termsCta> and <privacyPolicyCta>Privacy Policy</privacyPolicyCta>.',
  },
  payButtonLabelGeneric: {
    id: 'payment.prompt.payButtonLabelGeneric',
    defaultMessage: 'Pay',
  },
  payButtonLabel: {
    id: 'payment.prompt.payButtonLabel',
    defaultMessage: 'Pay with {paymentMethod}',
  },
  paymentMethodsListTitle: {
    id: 'payment.prompt.paymentMethodsListTitle',
    defaultMessage: 'Payment method',
  },
  addMethod: {
    id: 'payment.prompt.addMethod',
    defaultMessage: '+ Add card',
  },
  methodName: {
    id: 'payment.prompt.methodName',
    defaultMessage: '•••• {lastFourDigits}',
  },
  card: {
    id: 'payment.prompt.card',
    defaultMessage: 'Card •••• {lastFourDigits}',
  },
  result: {
    error: {
      id: 'payment.prompt.result.error',
      defaultMessage: 'An error has occurred. Please try again.',
    },
    successSubscription: {
      id: 'payment.prompt.result.successSubscription',
      defaultMessage: 'The subscription has been activated.',
    },
    successPayment: {
      id: 'payment.prompt.result.successPayment',
      defaultMessage: 'Payment completed.',
    },
    successTipPayment: {
      id: 'payment.prompt.result.successTipPayment',
      defaultMessage: 'Tip payment completed.',
    },
  },
  badgeBack: {
    id: 'subscription.badgeBack',
    defaultMessage: 'Give me my Stan badge back!',
  },
  subscriptionDisabled: {
    id: 'subscription.disabled',
    defaultMessage:
      "Sorry but it's not possible to become a Stan within the app. Please use our web app instead.",
  },
  whyBecomeStanTitle: {
    id: 'subscription.whyBecomeStanTitle',
    defaultMessage: 'Why become a Stan?',
  },
  whyBecomeStanDescription: {
    id: 'subscription.whyBecomeStanDescription',
    defaultMessage:
      'By being a Stan you receive access to exclusive content published by the Artist.',
  },
  beingAStan: {
    id: 'subscription.beingAStan',
    defaultMessage: 'Being a Stan of {displayName} is only {price}/month.',
  },
  restrictionMessage: {
    id: 'subscription.restriction.text',
    defaultMessage:
      '{artistName} profile content may contain adult themes that are not age appropriate, are you sure you want to proceed?',
  },
  restrictionButton: {
    id: 'subscription.restriction.button',
    defaultMessage: 'Continue',
  },
};
