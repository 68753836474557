import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/index.native';

import { MarketplaceItem } from 'types';
import { parseItemPrice } from 'utils/marketplace';

import messages from './messages';
import BottomRow from './styled/BottomRow';
import DescriptionView from './styled/DescriptionView';
import Image from './styled/Image';
import ImageView from './styled/ImageContainer';
import ImagePlaceholder from './styled/ImagePlaceholder';
import LowStockBox from './styled/LowStockBox';
import LowStockText from './styled/LowStockText';
import MainView from './styled/MainView';
import Price from './styled/Price';
import Stock from './styled/Stock';
import Title from './styled/Title';
import TouchableOpacity from './styled/TouchableOpacity';

interface Props {
  product: MarketplaceItem;
}

const ArtistMarketplaceProduct: React.FC<Props> = ({ product }: Props) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [isImageLoaded, setImageLoaded] = useState(false);

  const isLowStock = useMemo(
    () => !product.is_unlimited_stock && product.sold_count / product.stock >= 0.7,
    [product.is_unlimited_stock, product.stock, product.sold_count],
  );

  const onPress = useCallback(
    () =>
      navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
        productId: product.id,
        artist: product.artist.username,
      }),
    [navigate, product],
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <MainView>
        <ImageView>
          {!isImageLoaded && <ImagePlaceholder />}
          <Image
            source={{ uri: product.high_res_cover || product.low_res_cover }}
            onLoad={() => setImageLoaded(true)}
          />
          {isLowStock && (
            <LowStockBox>
              <LowStockText>{intl.formatMessage(messages.lowStock)}</LowStockText>
            </LowStockBox>
          )}
        </ImageView>
        <DescriptionView>
          <Title>{product.name}</Title>
          <BottomRow>
            <Price>{parseItemPrice(product.price, product.price_currency)}</Price>
            <Stock>
              {intl.formatMessage(messages.stock, { sold: product.sold_count })}
              {product.is_unlimited_stock ? '∞' : product.stock + product.sold_count}
            </Stock>
          </BottomRow>
        </DescriptionView>
      </MainView>
    </TouchableOpacity>
  );
};

export default ArtistMarketplaceProduct;
