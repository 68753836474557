import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import OptionsGroup, { Option } from 'components/OptionsGroup';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import MainView from './styled/MainView';
import Paragraph from './styled/Paragraph';
import Section from './styled/Section';
import SectionTitle from './styled/SectionTitle';

export { SCREEN_NAME };

type NotificationSettingsScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type NotificationSettingsScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: NotificationSettingsScreenRouteProp;
  navigation: NotificationSettingsScreenNavigationProp;
};

const NotificationSettingsScreen: React.FC<Props> = () => {
  const intl = useIntl();

  const [notificationState, setNotificationState] = useState({
    artistActivity: {
      push: true,
      email: true,
    },
    liveMoment: {
      push: true,
    },
    recommendations: {
      push: true,
      email: false,
    },
  });

  useEffect(() => {
    console.debug(notificationState);
    //todo: call notification settings api
  }, [notificationState]);

  const artistActivityOptions: Option[] = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.push),
        value: notificationState.artistActivity.push,
        type: 'switch',
        onAction: () =>
          setNotificationState((prevState) => ({
            ...prevState,
            artistActivity: { ...prevState.artistActivity, push: !prevState.artistActivity.push },
          })),
      },
      {
        label: intl.formatMessage(messages.email),
        value: notificationState.artistActivity.email,
        type: 'switch',
        onAction: () =>
          setNotificationState((prevState) => ({
            ...prevState,
            artistActivity: { ...prevState.artistActivity, email: !prevState.artistActivity.email },
          })),
      },
    ],
    [notificationState.artistActivity],
  );

  const liveMomentsOptions: Option[] = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.push),
        value: notificationState.liveMoment.push,
        type: 'switch',
        onAction: () =>
          setNotificationState((prevState) => ({
            ...prevState,
            liveMoment: { ...prevState.artistActivity, push: !prevState.liveMoment.push },
          })),
      },
    ],
    [notificationState.liveMoment],
  );

  const recommendationsOptions: Option[] = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.push),
        value: notificationState.recommendations.push,
        type: 'switch',
        onAction: () =>
          setNotificationState((prevState) => ({
            ...prevState,
            recommendations: {
              ...prevState.recommendations,
              push: !prevState.recommendations.push,
            },
          })),
      },
      {
        label: intl.formatMessage(messages.email),
        value: notificationState.recommendations.email,
        type: 'switch',
        onAction: () =>
          setNotificationState((prevState) => ({
            ...prevState,
            recommendations: {
              ...prevState.recommendations,
              email: !prevState.recommendations.email,
            },
          })),
      },
    ],
    [notificationState.recommendations],
  );

  return (
    <MainView>
      <Section>
        <SectionTitle>{intl.formatMessage(messages.artistActivity.title)}</SectionTitle>
        <Paragraph>{intl.formatMessage(messages.artistActivity.description)}</Paragraph>
        <OptionsGroup options={artistActivityOptions} />
      </Section>
      <Section>
        <SectionTitle>{intl.formatMessage(messages.liveMoment.title)}</SectionTitle>
        <Paragraph>{intl.formatMessage(messages.liveMoment.description)}</Paragraph>
        <OptionsGroup options={liveMomentsOptions} />
      </Section>
      <Section>
        <SectionTitle>{intl.formatMessage(messages.recommendations.title)}</SectionTitle>
        <Paragraph>{intl.formatMessage(messages.recommendations.description)}</Paragraph>
        <OptionsGroup options={recommendationsOptions} />
      </Section>
    </MainView>
  );
};

export default NotificationSettingsScreen;
