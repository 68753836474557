import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as INVESTOR_RELATIONS_FORM } from 'screens/InvestorRelationsFormScreen/constants';
import PreFormScreen from 'screens/PreFormScreen';

import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation: ScreenNavigationProp;
};

const InvestorRelationsScreen: React.FC<Props> = ({ navigation }: Props) => (
  <PreFormScreen
    messages={messages}
    navigation={navigation}
    destination={INVESTOR_RELATIONS_FORM}
    breadcumb="Investor Relations"
  />
);

export default InvestorRelationsScreen;
