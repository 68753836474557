import { css } from 'styled-components';
import styled from 'styled-components/native';

interface Props {
  short?: boolean;
}

export default styled.View<Props>`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  ${({ short }) =>
    short &&
    css`
      padding: 8px 10px;
    `}
`;
