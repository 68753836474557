import styled from 'styled-components/native';

export default styled.View`
  background: ${({ theme }) => theme.artistSearch.background};
  border-radius: 10px;
  margin-top: 4px;
  z-index: 999;
  &:first-child {
    margin-top: 0;
  }
`;
