import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import ArtistMyProfileScreen, {
  SCREEN_NAME as ARTIST_MY_PROFILE_SCREEN_NAME,
} from 'screens/ArtistMyProfileScreen';

import { SCREEN_NAME } from './constants';

export { SCREEN_NAME };

export const linking: PathConfigMap = {
  [ARTIST_MY_PROFILE_SCREEN_NAME]: '',
};

const ProfileStack = createStackNavigator();

const ArtistProfileStack: React.FC = () => {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name={ARTIST_MY_PROFILE_SCREEN_NAME}
        component={ArtistMyProfileScreen}
        options={{ headerShown: false }}
      />
    </ProfileStack.Navigator>
  );
};

export default ArtistProfileStack;
