import styled from 'styled-components/native';

export default styled.View`
  background-color: ${({ theme }) => theme.bottomSheet.background};
  width: 100%;
  height: 246px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;
