export default {
  orders: {
    id: 'marketplace.screen.orders',
    defaultMessage: 'Your orders',
  },
  products: {
    id: 'marketplace.screen.products',
    defaultMessage: "Your Artists' products",
  },
};
