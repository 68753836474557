import * as React from 'react';

import Container from './styled/Container';
import Description from './styled/Description';
import InnerContainer from './styled/InnerContainer';
import Title from './styled/Title';

export interface OrdertitleDescriptionProps {
  /** The title */
  title: string;
  /** The description */
  description?: string;
  icon?: () => React.ReactNode;
}

/**
 * Orders text and description
 */
const OrdertitleDescription: React.FC<OrdertitleDescriptionProps> = ({
  title,
  description,
  icon,
  ...rest
}: OrdertitleDescriptionProps) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <InnerContainer>
      {icon && icon()}
      <Description>{description ? description : '-'}</Description>
    </InnerContainer>
  </Container>
);

OrdertitleDescription.displayName = 'OrdertitleDescription';

export default OrdertitleDescription;
