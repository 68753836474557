import React from 'react';
import { Image, ImageProps } from 'react-native';

import { useMemoizedImage } from 'hooks/useMemoizedImage';

const ImageComponent: React.FC<ImageProps> = ({ source, ...rest }: ImageProps) => {
  const src = useMemoizedImage(source);

  return <Image {...rest} source={src} />;
};

export default ImageComponent;
