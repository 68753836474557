import styled, { css } from 'styled-components/native';

export default styled.View`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.colors.background};
  flex-direction: column;
  height: 100%;

  ${({ theme }) =>
    theme.media.tabletLandscape &&
    css`
      flex-direction: row;
    `}
`;
