import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.orderDetailsHeader.primaryText};
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.23px;
  padding: 0 15px;
`;
