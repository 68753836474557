export default {
  purchaseQuestionnaire: {
    fan: {
      id: 'marketplaceOrderDetails.purchaseQuestionnaire.fan',
      defaultMessage: 'Purchase questionnaire',
    },
    artist: {
      id: 'marketplaceOrderDetails.purchaseQuestionnaire.artist',
      defaultMessage: 'Questions for buyer',
    },
  },
  orderDetails: {
    id: 'marketplaceOrderDetails.orderDetails',
    defaultMessage: 'Order details',
  },
  amountPaid: {
    id: 'marketplaceOrderDetails.amountPaid',
    defaultMessage: 'Amount paid',
  },
  paymentMethod: {
    id: 'marketplaceOrderDetails.paymentMethod',
    defaultMessage: 'Payment method',
  },
  card: {
    id: 'marketplaceOrderDetails.card',
    defaultMessage: 'Card •••• {lastFourDigits}',
  },
  extraInfo: {
    id: 'marketplaceOrderDetails.extraInfo',
    defaultMessage: 'Need extra info to complete order? <cta>Ask buyer.</cta>',
  },
  conversation: {
    id: 'marketplaceOrderDetails.conversation',
    defaultMessage: 'Conversation',
  },
  conversationCompleted: {
    COMPLETED: {
      id: 'marketplaceOrderDetails.conversationCompleted.COMPLETED',
      defaultMessage:
        'The order is already completed so you can’t continue conversation with the Artist.',
    },
    DECLINED: {
      id: 'marketplaceOrderDetails.conversationCompleted.DECLINED',
      defaultMessage:
        'The order is already declined so you can’t continue conversation with the Artist.',
    },
  },
  attachment: {
    upload: {
      id: 'marketplaceOrderDetails.attachment.upload',
      defaultMessage: 'Upload attachment',
    },
    change: {
      id: 'marketplaceOrderDetails.attachment.change',
      defaultMessage: 'Change attachment',
    },
    title: {
      id: 'marketplaceOrderDetails.attachment.title',
      defaultMessage: 'Order attachment',
    },
    missing: {
      id: 'marketplaceOrderDetails.attachment.missing',
      defaultMessage: 'Attachment not sent yet.',
    },
    error: {
      upload: {
        id: 'marketplaceOrderDetails.attachment.error.upload',
        defaultMessage: 'Error uploading the attachment.',
      },
      update: {
        id: 'marketplaceOrderDetails.attachment.error.update',
        defaultMessage: 'Unexpected error occurred.',
      },
    },
  },
  fan: {
    confirm: {
      id: 'marketplaceOrderDetails.fan.confirm',
      defaultMessage: 'Confirm completing the order by Artist',
    },
    continueOrder: {
      id: 'marketplaceOrderDetails.fan.continueOrder',
      defaultMessage: 'Continue order',
    },
  },
  artist: {
    confirm: {
      id: 'marketplaceOrderDetails.fan.confirm',
      defaultMessage: 'Ask Stan to mark order as completed',
    },
    continueOrder: {
      id: 'marketplaceOrderDetails.fan.continueOrder',
      defaultMessage: 'Ask Stan to mark order as completed',
    },
  },
  awaitingConfirmation: {
    id: 'marketplaceOrderDetails.awaitingConfirmation',
    defaultMessage: "Awaiting Stan's confirmation",
  },
  editFile: {
    save: {
      id: 'marketplaceOrderDetails.editFile.save',
      defaultMessage: 'Save',
    },
    cancel: {
      id: 'marketplaceOrderDetails.editFile.cancel',
      defaultMessage: 'Cancel',
    },
    title: {
      id: 'marketplaceOrderDetails.editFile.title',
      defaultMessage: 'Edit file name',
    },
    label: {
      id: 'marketplaceOrderDetails.editFile.label',
      defaultMessage: 'File name',
    },
    error: {
      required: {
        id: 'marketplaceOrderDetails.editFile.error.required',
        defaultMessage: 'File name is required.',
      },
      invalid: {
        id: 'marketplaceOrderDetails.editFile.error.invalid',
        defaultMessage: 'File name contains illegal characters.',
      },
    },
  },
  message: {
    ownSuffix: {
      id: 'marketplaceOrderDetails.message.ownSuffix',
      defaultMessage: '(you)',
    },
  },
  back: {
    id: 'marketplaceOrderDetails.back',
    defaultMessage: 'Back',
  },
  orderNumber: {
    id: 'marketplaceOrderDetails.orderNumber',
    defaultMessage: 'Order number',
  },
  help: {
    id: 'marketplaceOrderDetails.help',
    defaultMessage: 'Help',
  },
  report: {
    id: 'marketplaceOrderDetails.report',
    defaultMessage: 'Report order',
  },
};
