import React, { Dispatch, SetStateAction } from 'react';
import { List, Menu } from 'react-native-paper';
import styled from 'styled-components/native';

import DropdownArrow from './DropdownArrow';

interface StyledProps {}

const View = styled.View`
  width: 35%;
  margin-right: 5%;
`;

const StyledItem = styled(List.Item).attrs(({ theme }: StyledProps) => {
  return {
    mode: 'flat',
    placeholderTextColor: theme.colors.primaryInactive,
    underlineColor: 'transparent',
    theme: {
      colors: {
        borderColor: theme.textInput.borderColor,
        placeholder: theme.colors.primaryInactive,
        text: theme.colors.primaryGhost,
        primary: theme.colors.text,
        background: theme.textInput.background,
      },
    },
  };
})`
  border-radius: 6px;
  width: 100%;
  height: 68px;
  border: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
  background: rgba(255, 255, 255, 0.08);
  padding-top: 17px;
`;

const StyledMenu = styled(Menu).attrs(({ theme }: StyledProps) => {})``;

interface Props {
  expanded: boolean;
  activeCurrency: 'USD' | 'GBP' | 'EUR';
  onSelectCurrency: (currency: string) => void;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

const Dropdown: React.FC<Props> = ({
  expanded,
  activeCurrency,
  onSelectCurrency,
  setExpanded,
}: Props) => {
  const handlePress = () => setExpanded(!expanded);

  const rennderItems = () => {
    return ['GBP', 'EUR', 'USD'].map((currency) => {
      return (
        <Menu.Item title={currency} key={currency} onPress={() => onSelectCurrency(currency)} />
      );
    });
  };

  return (
    <View>
      <StyledMenu
        onDismiss={handlePress}
        visible={expanded}
        anchor={
          <StyledItem
            title={activeCurrency}
            onPress={handlePress}
            right={(props) => <DropdownArrow />}
          />
        }
      >
        {rennderItems()}
      </StyledMenu>
    </View>
  );
};

export default Dropdown;
