import styled, { css } from 'styled-components';

interface Props {
  isAddingNewCard?: boolean;
}

export default styled.div<Props>`
  width: 216px;
  height: 248px;
  background: linear-gradient(0deg, rgba(46, 81, 223, 0.24), rgba(46, 81, 223, 0.24)), #121212;
  border-radius: 14px;
  margin-right: 16px;
  margin-top: 60px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  ${({ isAddingNewCard }) =>
    isAddingNewCard &&
    css`
      cursor: pointer;
      background: ${({ theme }) => theme.button.backgroundColorBlue};
      justify-content: center;
      align-items: center;
    `}
`;
