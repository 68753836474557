const EUR = 'EUR';
const USD = 'USD';
const GBP = 'GBP';

export const PRIMARY_CURRENCY = USD;

export const parseCurrency = (code: string = PRIMARY_CURRENCY): string => {
  switch (code) {
    case GBP:
      return '£';
    case USD:
      return '$';
    case EUR:
      return '€';
    default:
      return `${code} `;
  }
};
