import Animated from 'react-native-reanimated';
import styled from 'styled-components/native';

export default styled(Animated.View)`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(18, 18, 18, 0.6);
  z-index: 1;
`;
