import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgElement: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M4.57583 1.58325L4.5 10.3333C4.5 10.6427 4.62292 10.9394 4.84171 11.1582C5.0605 11.377 5.35725 11.4999 5.66667 11.4999H14.4167"
        stroke="black"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.58337 4.57583L10.3334 4.5C10.6428 4.5 10.9395 4.62292 11.1583 4.84171C11.3771 5.0605 11.5 5.35725 11.5 5.66667V14.4167"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgElement;
