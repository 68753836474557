import styled, { css } from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

interface Props {
  short?: boolean;
}

export default styled(ActivityIndicator).attrs({
  size: 16,
})<Props>`
  margin-right: 20px;
  margin-left: -20px;
  width: 0;
  ${({ short }) =>
    short &&
    css`
      margin-right: 13px;
      margin-left: -13px;
    `}
`;
