import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import EmailSentView from 'components/EmailSentView';
import NavigationHeader from 'components/NavigationHeader';

import { useSnackbarSet } from 'context/snackbar';
import { useFanMarketplaceCreateOrderReport } from 'hooks';
import { Send } from 'svg';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import ScreenView from './styled/ScreenView';
import TextInput from './styled/TextInput';
import Title from './styled/Title';

export { SCREEN_NAME };

type MarketplaceOrderReportRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type MarketplaceOrderReportNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: MarketplaceOrderReportRouteProp;
  navigation: MarketplaceOrderReportNavigationProp;
};

const MarketplaceOrderReportScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const { artistUsername, orderExternalId, marketplaceProductId } = route.params;
  const intl = useIntl();
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const {
    control,
    handleSubmit,
    clearErrors,
    errors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    email: string;
    message: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      message: '',
    },
  });
  const { createOrderReport } = useFanMarketplaceCreateOrderReport(
    artistUsername,
    marketplaceProductId,
    orderExternalId,
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setSnackbar } = useSnackbarSet();

  const onValidSubmit = async ({ email, message }: { email: string; message: string }) => {
    try {
      await createOrderReport(email, message);
      setIsSubmitted(true);
    } catch (e) {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.errors.other),
        actionLabel: intl.formatMessage(messages.snackbarClose),
      });
    }
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  return (
    <ScreenView>
      {!isSubmitted ? (
        <>
          <NavigationHeader
            insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
            navigation={navigation}
            headerRightImage={() => <Send />}
            headerRightOnPress={onSubmit}
            headerRightDisabled={!isValid && !isSubmitting}
            title={intl.formatMessage(messages.title)}
          />

          <Container>
            <Title>{intl.formatMessage(messages.actionTitle)}</Title>

            <Controller
              control={control}
              name="message"
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  multiline
                  ref={messageRef}
                  label={intl.formatMessage(messages.message)}
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('email');
                    clearErrors('message');
                    onChange(value);
                  }}
                />
              )}
              rules={{
                required: intl.formatMessage(messages.errors.message.required),
              }}
            />
            <Controller
              control={control}
              name="email"
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={emailRef}
                  label={intl.formatMessage(messages.email)}
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('email');
                    clearErrors('message');
                    onChange(value);
                  }}
                  isError={errors.email}
                />
              )}
              rules={{
                required: intl.formatMessage(messages.errors.email.required),
                pattern: {
                  value: regex.email,
                  message: intl.formatMessage(messages.errors.email.invalid),
                },
              }}
            />
          </Container>
        </>
      ) : (
        <EmailSentView
          navigation={navigation}
          title={messages.success.title}
          description={messages.success.description}
        />
      )}
    </ScreenView>
  );
};

export default MarketplaceOrderReportScreen;
