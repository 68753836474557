import styled from 'styled-components/native';

export default styled.Text<{ expired?: boolean }>`
  ${({ expired }) => expired && `opacity: 0.5;`}
  font-family: BasierCircle-Regular;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #ffffff;
`;
