import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_TAB_STACK_SCREEN_NAME } from 'stacks/artist/ArtistTabStack/constants';
import { SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as MARKETPLACE_SCREEN_NAME } from 'screens/AritstMarketplaceScreen/constants';
import { SCREEN_NAME as ARTIST_ADD_PRODUCT_SCREEN_NAME } from 'screens/ArtistAddProductScreen/constants';

import LoadingScreenOverlay from '../../components/LoadingScreenOverlay';
import ProductOwner from 'components/ProductOwner';
import ScrollViewWithCover from 'components/ScrollViewWithCover';

import { VARIANT } from 'context';
import { useBookmark, useMarketplaceItem } from 'hooks';
import { Bookmark, More, Share } from 'svg';
import { MarketplaceItem } from 'types';
import { parseItemPrice } from 'utils/marketplace';
import { shareProduct } from 'utils/sharing';

import { SCREEN_NAME as HOME_SCREEN } from '../HomeScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from '../MarketplaceProductOrderScreen/constants';
import { SCREEN_NAME } from './constants';
import messages from './messages';
import ActionRow from './styled/ActionRow';
import ActionText from './styled/ActionText';
import ActionTouchable from './styled/ActionTouchable';
import Answer from './styled/Answer';
import ArticleHeader from './styled/ArticleHeader';
import ConfirmationButton from './styled/ConfirmationButton';
import ContentInnerWrapper from './styled/ContentInnerWrapper';
import ContentWrapper from './styled/ContentWrapper';
import Description from './styled/Description';
import Divider from './styled/Divider';
import EditButton from './styled/EditButton';
import InsightsBarView from './styled/InsightsBarView';
import OverlayButton from './styled/OverlayButton';
import Price from './styled/Price';
import Question from './styled/Question';
import QuestionnaireTitle from './styled/QuestionareTitle';
import SafeAreaView from './styled/SafeAreaView';
import ScreenView from './styled/ScreenView';
import SoldCount from './styled/SoldCount';
import Title from './styled/Title';

export { SCREEN_NAME };

type MarketplaceProductDetailsRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type MarketplaceProductDetailsNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: MarketplaceProductDetailsRouteProp;
  navigation: MarketplaceProductDetailsNavigationProp;
};

const SHOW_MORE_BUTTON = false;

const MarketplaceProductDetailsScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { productId, artist } = route.params;
  const { item, updateMarketplaceItem } = useMarketplaceItem(artist || '', productId);

  const { toggleBookmark } = useBookmark({
    product: { ...item, model_name: 'marketplaceitem' },
    updateFeedItem: updateMarketplaceItem,
  });

  const onCloseButtonPress = useCallback(() => {
    if (navigation.canGoBack() && VARIANT === 'fan') {
      return navigation.goBack();
    }
    if (VARIANT == 'fan') navigation.navigate(HOME_SCREEN);
    navigation.navigate(ARTIST_TAB_STACK_SCREEN_NAME, { screen: MARKETPLACE_SCREEN_NAME });
    return navigation.navigate(
      VARIANT == 'fan' ? MARKETPLACE_STACK_SCREEN_NAME : MARKETPLACE_SCREEN_NAME,
    );
  }, [navigation]);

  const onEditButtonPress = useCallback(() => {
    if (!item) return;
    navigation.navigate(ARTIST_ADD_PRODUCT_SCREEN_NAME, { item });
  }, [item, navigation]);

  const onShareProduct = useCallback(() => {
    if (!item) return;
    shareProduct({ ...item, model_name: 'marketplaceitem' });
  }, [shareProduct, item]);

  const navigateToProductOrder = useCallback(() => {
    if (!item) return;
    navigation.navigate(MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME, {
      marketplaceProductId: item.id,
      artistUsername: item.artist.username,
      item: item as MarketplaceItem,
    });
  }, [navigation, item]);

  if (!item) return <LoadingScreenOverlay />;
  return (
    <ScreenView>
      <SafeAreaView>
        <ScrollViewWithCover
          coverUrl={item?.high_res_cover || item?.low_res_cover || ''}
          renderOverCover={
            VARIANT === 'artist'
              ? () => (
                  <>
                    <EditButton
                      text={intl.formatMessage(messages.edit)}
                      onPress={onEditButtonPress}
                    />
                  </>
                )
              : undefined
          }
          renderHeader={() => (
            <>
              {VARIANT === 'artist' && (
                <InsightsBarView>
                  <SoldCount>
                    {item
                      ? intl.formatMessage(messages.soldCount, {
                          sold: () => item.sold_count,
                          stock: () =>
                            item.is_unlimited_stock ? '∞' : item.stock + item.sold_count,
                        })
                      : ''}
                  </SoldCount>
                  {/*todo: enable marketplace product insights*/}
                  {/*<ActionText>See insights</ActionText>*/}
                </InsightsBarView>
              )}
              {VARIANT === 'fan' && (
                <ProductOwner
                  name={item?.artist?.display_name}
                  imageUrl={item?.artist?.low_res_profile_picture}
                />
              )}
              <ArticleHeader>
                <Title>{item?.name}</Title>
                <Price>{parseItemPrice(item?.price, item?.price_currency)}</Price>
              </ArticleHeader>
            </>
          )}
          renderContent={() => (
            <ContentWrapper>
              <ContentInnerWrapper>
                {!!item?.description && <Description>{item?.description}</Description>}

                <ActionRow isArtist={VARIANT === 'artist'}>
                  {VARIANT === 'fan' && (
                    <ActionTouchable onPress={toggleBookmark}>
                      <Bookmark fill={item?.is_bookmarked} />
                      <ActionText>{intl.formatMessage(messages.save)}</ActionText>
                    </ActionTouchable>
                  )}

                  <ActionTouchable onPress={onShareProduct}>
                    <Share />
                    <ActionText>{intl.formatMessage(messages.share)}</ActionText>
                  </ActionTouchable>

                  {VARIANT === 'fan' && SHOW_MORE_BUTTON && (
                    <ActionTouchable>
                      <More />
                      <ActionText>{intl.formatMessage(messages.more)}</ActionText>
                    </ActionTouchable>
                  )}
                </ActionRow>

                {VARIANT === 'artist' && (
                  <>
                    <Divider />
                    <QuestionnaireTitle>
                      {intl.formatMessage(messages.purchaseQuestionnaire)}
                    </QuestionnaireTitle>
                    <Question isActive>
                      {intl.formatMessage(messages.questionnaireDescription)}
                    </Question>
                    {item?.questionares?.map((item, index) => (
                      <>
                        <Question>
                          {intl.formatMessage(messages.question, { number: index + 1 })}
                        </Question>
                        <Answer>{item.question}</Answer>
                      </>
                    ))}
                  </>
                )}
              </ContentInnerWrapper>

              {VARIANT === 'fan' && (
                <ConfirmationButton onPress={navigateToProductOrder}>
                  {intl.formatMessage(messages.order)}
                </ConfirmationButton>
              )}
            </ContentWrapper>
          )}
        />
        <OverlayButton icon="close" onPress={onCloseButtonPress} />
      </SafeAreaView>
    </ScreenView>
  );
};

export default MarketplaceProductDetailsScreen;
