import styled, { css } from 'styled-components/native';

export default styled.View`
  width: 100%;
  height: auto;
  background: #141824;
  display: flex;
  flex-direction: column;
  padding: 0;
  ${({ theme }) =>
    theme.media.tabletLandscape &&
    css`
      padding: 0 20%;
    `}
`;
