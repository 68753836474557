import { NavigationContainerRef } from '@react-navigation/native';
import React, { MutableRefObject, useMemo, useState } from 'react';

import CvvModal from 'components/CvvModal';

export interface cvvModalState {
  isOpen: boolean;
  cvv: string;
  setIsOpen: (value: boolean) => void;
  setCvv: (value: string) => void;
}

const DEFAULT_STATE: cvvModalState = {
  isOpen: false,
  cvv: '',
  setIsOpen: () => {},
  setCvv: () => {},
};

export const CvvModalContext = React.createContext(DEFAULT_STATE);

export function useCvvModal(): cvvModalState {
  const context = React.useContext<cvvModalState>(CvvModalContext);
  if (context === undefined) {
    throw new Error('useCvvModal must be used within a CvvModalProvider');
  }
  return context;
}

interface Props {
  navigationRef?: MutableRefObject<NavigationContainerRef | null>;
  children: React.ReactNode;
}

export const CvvModalProvider: React.FC<Props> = ({ navigationRef, children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cvv, setCvv] = useState<string>('');

  const value = useMemo<cvvModalState>(
    () => ({
      isOpen,
      cvv,
      setIsOpen,
      setCvv,
    }),
    [isOpen, cvv, setIsOpen, setCvv],
  );

  return (
    <CvvModalContext.Provider value={value}>
      {children}
      {/* <CvvModal isOpen={isOpen} cvv={cvv} setCvv={setCvv} setIsOpen={setIsOpen} /> */}
    </CvvModalContext.Provider>
  );
};
