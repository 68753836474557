import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

interface UseLocalStorageReturn {
  value: any;
  setValueWrapper: (value: any) => Promise<void>;
  deleteValue: () => void;
  loading: boolean;
}

export function useLocalStorage(key: string, defaultValue: any = null): UseLocalStorageReturn {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState<boolean>(true);

  const setValueWrapper = async (value: any) => {
    setValue(value);
    (async () => {
      await AsyncStorage.setItem(key, JSON.stringify(value));
    })();
  };

  const deleteValue = () => {
    setValue(null);
    (async () => {
      await AsyncStorage.removeItem(key);
    })();
  };

  useEffect(() => {
    (async () => {
      const localStorageValue = await AsyncStorage.getItem(key);
      if (localStorageValue === null) {
        setValueWrapper(defaultValue);
        return;
      }

      try {
        const parsedValue = JSON.parse(localStorageValue as string);
        setValue(parsedValue);
      } catch (e) {
        setValue(localStorageValue as string);
      }
      setLoading(false);
    })();
  }, []);

  return { value, setValueWrapper, deleteValue, loading };
}
