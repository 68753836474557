import * as React from 'react';
import { useIntl } from 'react-intl';

import NotificationsGroupItem from 'components/NotificationsGroupItem';

import { NotificationsGroupType, SingleNotification } from 'types';
import { formatDate, isToday, isYesterday } from 'utils/date';

import messages from './messages';
import Container from './styled/Container';
import Label from './styled/Label';
import LabelContainer from './styled/LabelContainer';
import LabelSuffix from './styled/LabelSuffix';
import Wrapper from './styled/Wrapper';

export interface NotificationsGroupProps extends NotificationsGroupType {
  dateKey: string;
  notifications: SingleNotification[];
  isLast?: boolean;
  navigateToProduct: (product: any) => void;
  navigateToMarketplaceProduct: (product: any) => void;
}

const NotificationsGroup: React.FC<NotificationsGroupProps> = ({
  navigateToProduct,
  navigateToMarketplaceProduct,
  notifications,
  isLast,
  ...rest
}: NotificationsGroupProps): JSX.Element => {
  const date = notifications[0].time_created;
  const intl = useIntl();
  const label = isToday(date)
    ? intl.formatMessage(messages.today)
    : isYesterday(date)
    ? intl.formatMessage(messages.yesterday)
    : formatDate(date, 'DD MMM YYYY');
  const labelSuffix = isYesterday(date) ? `(${formatDate(date, 'DD MMM YYYY')})` : null;

  return (
    <Container {...rest} isLast={isLast}>
      <LabelContainer>
        <Label>{label}</Label>
        {labelSuffix && <LabelSuffix>{labelSuffix}</LabelSuffix>}
      </LabelContainer>
      <Wrapper>
        {notifications.map((notification: SingleNotification, index: number) => (
          <NotificationsGroupItem
            isFirst={index === 0}
            key={`notifications-group-item-${index}`}
            navigateToProduct={navigateToProduct}
            navigateToMarketplaceProduct={navigateToMarketplaceProduct}
            notification={notification}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

NotificationsGroup.displayName = 'NotificationsGroup';

export default NotificationsGroup;
