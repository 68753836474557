import styled, { css } from 'styled-components/native';
interface PlaceholderWordProps {
  numChars: number;
  singleLine?: boolean;
  firstChild?: boolean;
  lastChild?: boolean;
}

export default styled.View<PlaceholderWordProps>`
  width: ${(props) => props.numChars * 10}px;
  height: 9px;
  border-radius: 4.5px;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  opacity: 0.64;
  margin: 9px 5px;

  ${(props) =>
    props.singleLine &&
    css`
      margin: 0 5px;

      ${props.firstChild &&
      css`
        margin: 0 5px 0 0;
      `}

      ${props.lastChild &&
      css`
        margin: 0 0 0 5px;
      `}
    `}
`;
