export default {
  needsConfirmation: {
    id: 'order_status.needsConfirmation',
    defaultMessage: 'Awaiting confirmation',
  },
  fan: {
    purchased: {
      id: 'order_status.fan.purchased',
      defaultMessage: 'Purchased',
    },
    waiting: {
      id: 'order_status.fan.waiting',
      defaultMessage: 'Waiting for payment',
    },
    inprogress: {
      id: 'order_status.fan.inprogress',
      defaultMessage: 'In progress',
    },
    completed: {
      id: 'order_status.fan.completed',
      defaultMessage: 'Completed',
    },
    declined: {
      id: 'order_status.fan.declined',
      defaultMessage: 'Declined by Artist',
    },
    canceled: {
      id: 'order_status.fan.canceled',
      defaultMessage: 'Canceled',
    },
  },
  artist: {
    purchased: {
      id: 'order_status.artist.purchased',
      defaultMessage: 'Purchased',
    },
    waiting: {
      id: 'order_status.artist.waiting',
      defaultMessage: 'Waiting for payment',
    },
    inprogress: {
      id: 'order_status.artist.inprogress',
      defaultMessage: 'Ongoing',
    },
    completed: {
      id: 'order_status.artist.completed',
      defaultMessage: 'Completed',
    },
    declined: {
      id: 'order_status.artist.declined',
      defaultMessage: 'Declined',
    },
    canceled: {
      id: 'order_status.fan.canceled',
      defaultMessage: 'Canceled',
    },
  },
};
