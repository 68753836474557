import styled from 'styled-components/native';

export default styled.TouchableOpacity.attrs(() => {
  return {
    activeOpacity: 0.7,
  };
})`
  background-color: ${({ theme }) => theme.button.text};
  width: 56px;
  height: 56px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
`;
