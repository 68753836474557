import React, { useRef } from 'react';

import { CacheContext } from 'context/ImageCache/ImageCache';

interface Props {
  children: React.ReactNode;
}

export const ImageCacheProvider: React.FC<Props> = ({ children }: Props) => {
  const cache = useRef<Set<string>>(new Set()).current;

  return <CacheContext.Provider value={{ cache }}>{children}</CacheContext.Provider>;
};
