import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({ labelColor: '#fff' })`
  background-color: #0537ff;
  margin-top: 46px;
  margin-bottom: 32px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.38;
    `}
`;
