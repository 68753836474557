export default {
  fan: {
    declined: {
      id: 'order_status_description.fan.declined',
      defaultMessage: `Artist declined your order. Your funds will be returned to your account within {returnFundsDays, plural, =0 {days} one {# day} other {# days}}.`,
    },
    completed: {
      id: 'order_status_description.fan.completed',
      defaultMessage: `Order marked as completed at {confirmationDate} at {confirmationTime}`,
    },
  },
  artist: {
    declined: {
      id: 'order_status_description.artist.declined',
      defaultMessage: `Order declined at {confirmationDate} at {confirmationTime}`,
    },
    completed: {
      id: 'order_status_description.artist.completed',
      defaultMessage: `Order marked as completed at {confirmationDate} at {confirmationTime}`,
    },
  },
};
