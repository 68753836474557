import React, { useEffect, useState } from 'react';
import { Animated, LayoutChangeEvent, ViewProps } from 'react-native';
import {
  GestureEvent,
  HandlerStateChangeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
} from 'react-native-gesture-handler';

import Background from './styled/Background';
import Dot from './styled/Dot';
import ProgressBox from './styled/ProgressBox';

interface VideoProgressBar {
  durationMillis?: number;
  positionMillis?: number;
  setPositionMillis?: (positionMillis: number) => void;
}

type Props = VideoProgressBar & ViewProps;

const VideoProgressBar: React.FC<Props> = ({
  durationMillis = 1,
  positionMillis = 0,
  setPositionMillis,
  ...restProps
}: Props) => {
  const [barWidth, setBarWidth] = useState(0);
  const dotPositionAnim = new Animated.Value(0);
  const bufferWidthAnim = new Animated.Value(0);
  const [dotInitialX, setDotInitialX] = useState(0);

  const getDotPosition = () => {
    return (positionMillis / durationMillis) * barWidth;
  };

  useEffect(() => {
    const p = getDotPosition();
    dotPositionAnim.setValue(p);
    bufferWidthAnim.setValue(p);
  }, [positionMillis, durationMillis]);

  const onGestureEvent = (event: GestureEvent<PanGestureHandlerEventPayload>) => {
    const {
      nativeEvent: { translationX },
    } = event;
    const pX = dotInitialX + translationX;
    const pm = Math.round((pX / barWidth) * durationMillis);
    const p = (pm / durationMillis) * barWidth;
    dotPositionAnim.setValue(p);
    bufferWidthAnim.setValue(p);
  };

  const onGestureBegan = () => {
    setDotInitialX(getDotPosition());
  };

  const onGestureEnded = (event: HandlerStateChangeEvent<Record<string, unknown>>) => {
    const {
      nativeEvent: { translationX },
    } = event;
    const pX = dotInitialX + (translationX as number);
    setPositionMillis?.(Math.round((pX / barWidth) * durationMillis));
  };

  return (
    <ProgressBox
      {...restProps}
      onLayout={(event: LayoutChangeEvent) => setBarWidth(event.nativeEvent.layout.width)}
    >
      {!!durationMillis && (
        <Animated.View
          style={{
            position: 'absolute',
            left: 0,
            width: bufferWidthAnim,
            backgroundColor: '#fff',
            height: '100%',
            zIndex: 2,
            borderRadius: 14,
          }}
        />
      )}
      <Background />
      {!!durationMillis && (
        <PanGestureHandler
          onGestureEvent={onGestureEvent}
          onBegan={onGestureBegan}
          onEnded={onGestureEnded}
        >
          <Animated.View
            style={{
              position: 'absolute',
              width: 20,
              height: 20,
              bottom: -13,
              marginLeft: -8,
              zIndex: 3,
              left: dotPositionAnim,
            }}
          >
            <Dot />
          </Animated.View>
        </PanGestureHandler>
      )}
    </ProgressBox>
  );
};

export default VideoProgressBar;
