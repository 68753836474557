import { FC, useEffect, useState } from 'react';

import * as S from './Fade.styled';
import { FadeProps } from './Fade.types';

const FadeWeb: FC<FadeProps> = ({ fill, isVisible, children, ...rest }) => {
  const [_isVisible, setIsVisible] = useState(isVisible || false);

  useEffect(() => {
    if (isVisible === undefined) {
      setIsVisible(true);
    } else {
      setIsVisible(isVisible);
    }
  }, [isVisible]);

  return (
    <S.WrapperWeb
      fill={fill}
      isVisible={_isVisible}
      pointerEvents={_isVisible ? undefined : 'none'}
      {...rest}
    >
      {children}
    </S.WrapperWeb>
  );
};

export default FadeWeb;
