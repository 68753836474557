import axios, { AxiosInstance, AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Subject } from 'rxjs';
import * as Sentry from 'sentry-expo';

import { useLocalStorage } from 'hooks/localStorage';

interface BackEndState {
  accessToken: string | null;
  refreshToken: string | null;
  hasFinishedSignUp: boolean;
  isAuthenticated: boolean;
  axiosInstance: AxiosInstance;
  fetcher: (url: string) => Promise<any>;
  loginRequest: Subject<void>;
  setAuthData: (data: any, saveToStorage?: boolean) => void;
  verifyFinishSignUp: () => void;
  verified: boolean;
  resetPasswordRequired: boolean;
  onResetPasswordSuccess: () => void;
}

const CONSOLE_GROUP = 'context/backEnd';

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  timeout: 90000,
});

const generateAuthHeader = (accessToken: string | null) => {
  return accessToken ? `Bearer ${accessToken}` : undefined;
};

const setAxiosAccessToken = (accessToken: string | null) => {
  const authHeader = generateAuthHeader(accessToken);
  axiosInstance.defaults.headers.get['Authorization'] = authHeader;
  axiosInstance.defaults.headers.post['Authorization'] = authHeader;
  axiosInstance.defaults.headers.patch['Authorization'] = authHeader;
  axiosInstance.defaults.headers.put['Authorization'] = authHeader;
  axiosInstance.defaults.headers.patch['Authorization'] = authHeader;
  axiosInstance.defaults.headers.delete['Authorization'] = authHeader;
};

const fetcherInstance = (url: string) => axiosInstance.get(url).then((res) => res.data);

const DEFAULT_STATE: BackEndState = {
  accessToken: null,
  refreshToken: null,
  hasFinishedSignUp: true,
  isAuthenticated: false,
  axiosInstance,
  fetcher: fetcherInstance,
  loginRequest: new Subject<void>(),
  setAuthData: () => {},
  verifyFinishSignUp: () => {},
  verified: false,
  resetPasswordRequired: false,
  onResetPasswordSuccess: () => {},
};

const BackEndContext = React.createContext<BackEndState>(DEFAULT_STATE);

export function useBackEnd(): BackEndState {
  const context = React.useContext<BackEndState>(BackEndContext);
  if (context === undefined) {
    throw new Error('useBackEnd must be used within a BackEndProvider');
  }

  return context;
}

let tokenRefreshPromise: Promise<AxiosResponse<any>>;
let isRefreshingToken = false;

const performTokenRefresh = async (accessToken: string, refreshToken: string) => {
  if (isRefreshingToken) {
    // If the token is already being refreshed, return the existing promise.
    console.log('Token refresh request already in progress, returning existing promise');
    return tokenRefreshPromise;
  }

  console.log('Starting new token refresh request with', {
    accessToken,
    refreshToken,
  });
  isRefreshingToken = true;
  tokenRefreshPromise = new Promise((resolve, reject) => {
    axiosInstance
      .post('/auth/access_token/refresh', {
        access_token: accessToken,
        refresh_token: refreshToken,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        isRefreshingToken = false;
      });
  });

  return tokenRefreshPromise;
};

interface Props {
  children: React.ReactNode;
}

interface AuthData {
  access_token: string;
  refresh_token: string;
  has_finished_sign_up: boolean;
}

function debugAlert(text: string) {
  if (process.env.ENV !== 'production' && process.env.ENV !== 'staging') {
    alert(text);
  }
}

export const BackEndProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    value: authLocalStorage,
    setValueWrapper: setAuthLocalStorage,
    loading,
  } = useLocalStorage('auth', DEFAULT_STATE);
  const [backEnd, setBackEnd] = useState<BackEndState>(DEFAULT_STATE);
  const { accessToken, refreshToken } = backEnd;
  const loginRequest = useMemo(() => new Subject<void>(), []);

  const setAuth = (authData: AuthData | null, saveToStorage = true) => {
    const accessToken = authData?.access_token || null;
    const finishSignUp = authData?.has_finished_sign_up || null;
    setAxiosAccessToken(accessToken);
    setBackEnd((prevState) => ({
      ...prevState,
      accessToken,
      refreshToken: authData?.refresh_token || null,
      hasFinishedSignUp: !!finishSignUp,
      isAuthenticated: !!accessToken,
      verified: true,
    }));

    if (saveToStorage) {
      setAuthLocalStorage(authData);
    }
  };

  useEffect(() => {
    if (!loading && authLocalStorage) {
      setAuth(authLocalStorage, false);
    }
  }, [authLocalStorage, loading]);

  useEffect(() => {
    const requestLogin = () => {
      setAuth(null);
      loginRequest.next();
    };

    const requestPasswordChange = () => {
      setBackEnd({ ...backEnd, resetPasswordRequired: true });
      loginRequest.next();
    };

    if (loading) {
      return;
    }

    const interceptorId = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        (Sentry.Native || Sentry.Browser).captureException(error);
        console.group(CONSOLE_GROUP);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.code === 'access_token_expired'
        ) {
          console.log('Access token expired', accessToken, refreshToken);
          if (authLocalStorage.access_token && authLocalStorage.refresh_token) {
            try {
              // Performing token refresh
              console.log('Requesting token refresh');
              const refreshData = await performTokenRefresh(
                authLocalStorage.access_token,
                authLocalStorage.refresh_token,
              );
              console.log('Refresh data', refreshData.data);

              await setAuth({
                ...refreshData.data,
                has_finished_sign_up: backEnd.hasFinishedSignUp,
              });
              setAxiosAccessToken(refreshData.data.access_token);
              // Re-try the same request again
              console.log('Retrying the same request with config', error.config);
              console.groupEnd();
              return axiosInstance.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: generateAuthHeader(refreshData.data.access_token),
                },
              });
            } catch (e) {
              console.log('Token refresh failed. Requesting re-login.');
              debugAlert('Automatic sign-in failed, please sign in manually.');
              requestLogin();
            }
          } else {
            console.log('No authToken or refreshToken stored. Requesting redirect to login.');
            // debugAlert('No authentication data found. Please sign in.');
            requestLogin();
          }
        } else if (
          error?.response?.status === 401 &&
          error?.response?.data?.code === 'must_reset_or_change_password'
        ) {
          console.log('Back end requested user to change password');
          requestPasswordChange();
        } else if (
          (error?.response?.data && error?.response?.data?.code === 'force_login') ||
          (error?.response?.status === 401 &&
            error.config.url.indexOf('/login') === -1 &&
            !!accessToken)
        ) {
          console.log('Back end requested user re-login');
          if (error?.response?.data && error?.response?.data?.code) {
            debugAlert(
              `Authentication exception: ${error.response.status} ${error.response.data.code}. Please sign in again.`,
            );
          } else {
            debugAlert(`Authentication exception: ${error.response.status}. Please sign in again.`);
          }
          requestLogin();
        }
        console.groupEnd();
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptorId);
    };
  }, [accessToken, refreshToken, axiosInstance, loading, authLocalStorage]);

  useEffect(() => {
    setBackEnd((prevState) => ({
      ...prevState,
      loginRequest,
    }));
  }, [loginRequest]);

  const verifyFinishSignUp = async () => {
    const response = await backEnd.axiosInstance.get('/auth/user');
    if (response.data.has_finished_sign_up) {
      setBackEnd((prevState) => {
        setAuthLocalStorage({
          access_token: prevState.accessToken,
          refresh_token: prevState.refreshToken,
          has_finished_sign_up: true,
        });
        return {
          ...prevState,
          hasFinishedSignUp: true,
        };
      });
    }
  };

  const onResetPasswordSuccess = () =>
    setBackEnd((prev) => ({ ...prev, resetPasswordRequired: false }));

  const backEndStateValue: BackEndState = {
    ...backEnd,
    verifyFinishSignUp,
    setAuthData: setAuth,
    onResetPasswordSuccess,
  };

  return <BackEndContext.Provider value={backEndStateValue}>{children}</BackEndContext.Provider>;
};
