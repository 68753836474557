import styled from 'styled-components/native';

interface Props {
  textColor: string;
}

export default styled.Text<Props>`
  font-family: BasierCircle-Regular;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ textColor }) => textColor};
`;
