import styled from 'styled-components/native';

import ContentWithComments from 'containers/ContentWithComments';

export default styled(ContentWithComments)`
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
`;
