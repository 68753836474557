import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  stroke?: string;
  fill?: string;
  dotColor?: string;
}

const SvgComponent: React.FC<Props> = ({
  stroke = '#fff',
  fill = 'none',
  dotColor = '#fff',
}: Props) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.59 13.41l-7.17 7.17a1.998 1.998 0 01-2.83 0L2 12V2h10l8.59 8.59a2 2 0 010 2.82z"
        fill={fill}
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7 7h.01"
        stroke={dotColor}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
