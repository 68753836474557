import * as InAppPurchases from 'expo-in-app-purchases';
import { useEffect, useState } from 'react';

import { useBackEnd, useFeedHandlers } from 'context';
import { useApexx, useAuthSwr } from 'hooks';
import { ArtistProduct, IapConfig, PaymentMethod, ProductTypePlural } from 'types';
import { getModelNamePlural } from 'utils/product';

const IAP_CONFIGS_KEY = '/artists/products/iap_configs';

export const useFeeBasedPostIapConfigsList = () => {
  const [feeBasedPostIapConfigs, setFeeBasedPostIapConfigs] = useState<IapConfig[]>([]);

  const {
    data,
    error,
    isLoading: isLoadingFeeBasedPostIapConfigs,
  } = useAuthSwr<IapConfig[]>({
    key: IAP_CONFIGS_KEY,
    isPublic: false,
    cache: false,
  });

  useEffect(() => {
    // console.log('iap configs:', data);
    if (data) {
      const configs = [...data.sort((c1, c2) => (Number(c1.price) > Number(c2.price) ? 1 : -1))];
      setFeeBasedPostIapConfigs(configs);
    }
  }, [data]);

  return {
    feeBasedPostIapConfigs,
    isLoadingFeeBasedPostIapConfigs,
    error,
  };
};

export const useFeeBasedPostIapConfig = ({ product }: { product: ArtistProduct }) => {
  const pluralName = getModelNamePlural(product.model_name);
  const GET_CONFIG_KEY = `/artists/${product?.artist?.username}/products/${pluralName}/${product.id}/iap_config`;
  const { axiosInstance } = useBackEnd();
  const [feeBasedPostIapConfig, setFeeBasedPostIapConfig] = useState<IapConfig>();

  useEffect(() => {
    const getConfig = async () => {
      try {
        const response = await axiosInstance(GET_CONFIG_KEY);
        setFeeBasedPostIapConfig(response.data);
      } catch (e: any) {
        console.log('Error getting iap config', e?.response?.data);
      }
    };

    if (product) {
      getConfig();
    }
  }, [product]);

  return {
    feeBasedPostIapConfig,
  };
};

export const useFeeBasedPostPayment = () => {
  const { axiosInstance } = useBackEnd();
  const [isPayingForPost, setIsPayingForPost] = useState<boolean>(false);
  const [feeBasedPostPaymentError, setFeeBasedPostPaymentError] = useState<any>(null);
  const [feeBasedPostPaymentData, setFeeBasedPostPaymentData] = useState<any>(null);
  const { payWithApexx, paymentSuccess, paymentError, isPaying } = useApexx();
  const { fetchMore, refresh: refreshFeed } = useFeedHandlers();

  useEffect(() => {
    const finalizeApexxPayment = async () => {
      console.log('finalizing payment');
      if (paymentSuccess === false) {
        console.log('Fee based post payment error:', paymentError?.message);
        setFeeBasedPostPaymentError(paymentError?.message || 'subscription payment error');
        setIsPayingForPost(false);
      } else if (paymentSuccess === true) {
        setFeeBasedPostPaymentData({ data: 'payment succeed' } as any);
        setIsPayingForPost(false);
      }
    };

    finalizeApexxPayment();
  }, [paymentSuccess, paymentError]);

  const payForFeeBasedPostWithCard = async ({
    paymentMethod,
    artistUsername,
    modelNamePlural,
    productId,
    amount,
  }: {
    paymentMethod: PaymentMethod;
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    amount: number;
  }) => {
    if (isPayingForPost) {
      return;
    }

    setIsPayingForPost(true);
    setFeeBasedPostPaymentError(null);
    setFeeBasedPostPaymentData(null);

    const res = await payWithApexx({
      artist_username: artistUsername,
      payment_type: 'fee_based_product',
      model_name_plural: modelNamePlural,
      product_id: productId,
      amount: amount.toString(),
      credit_card_external_id: (paymentMethod.creditCardInfo.external_id || '').toString(),
    });
  };

  const initializeTransactionWithApple = async ({
    artistUsername,
    modelNamePlural,
    productId,
    appleProductId,
    amount,
  }: {
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    appleProductId: string;
    amount: number;
  }) => {
    try {
      console.log(
        `/artists/${artistUsername}/products/${modelNamePlural}/${productId}/fee_based_payment/apple_iap`,
        { amount, apple_product_id: appleProductId },
      );
      await axiosInstance.post(
        `/artists/${artistUsername}/products/${modelNamePlural}/${productId}/fee_based_payment/apple_iap`,
        { amount, apple_product_id: appleProductId },
      );
      return true;
    } catch (e: any) {
      console.log('transaction initialization error:', e.response.data);

      setFeeBasedPostPaymentError('There was an error during payment');
    }
    return false;
  };

  const payForPostWithApple = async ({
    artistUsername,
    modelNamePlural,
    productId,
    appleProductId,
    amount,
  }: {
    artistUsername: string;
    modelNamePlural: ProductTypePlural;
    productId: number;
    appleProductId: string;
    amount: number;
  }) => {
    if (isPayingForPost) {
      return false;
    }

    setIsPayingForPost(true);
    setFeeBasedPostPaymentError(null);
    setFeeBasedPostPaymentData(null);
    let isSuccess = false;

    if (
      appleProductId &&
      (await initializeTransactionWithApple({
        artistUsername,
        modelNamePlural,
        productId,
        appleProductId,
        amount,
      }))
    ) {
      console.log('/// ... IAP');
      console.log('/// ... appleProductId', appleProductId);
      const { responseCode, results, errorCode } = await InAppPurchases.getProductsAsync([
        appleProductId,
      ]);
      console.log('/// ...', responseCode, JSON.stringify(results), errorCode);
      if (responseCode === InAppPurchases.IAPResponseCode.OK) {
        console.log('OK');
        console.log('/// ... purchasing an item');
        try {
          await InAppPurchases.purchaseItemAsync(appleProductId);
          console.log('/// ... item purchased');
          setFeeBasedPostPaymentData({ result: 'item purchased' } as any);
          isSuccess = true;
        } catch (e: any) {
          console.log('/// ... error', JSON.stringify(e));
          setFeeBasedPostPaymentError('There was an error during payment');
        }
      }
      if (responseCode === InAppPurchases.IAPResponseCode.ERROR) {
        console.log('ERROR');
        setFeeBasedPostPaymentError('There was an error during payment');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
        console.log('DEFERRED');
      }
      if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
        console.log('USER_CANCELED');
      }
    } else {
      const message = 'Cannot initialize transaction';
      console.log(message);
      setFeeBasedPostPaymentError('There was an error during payment');
    }
    setIsPayingForPost(false);
    return isSuccess;
  };

  return {
    payForFeeBasedPostWithCard,
    payForPostWithApple,
    setFeeBasedPostPaymentError,
    setFeeBasedPostPaymentData,
    feeBasedPostPaymentError,
    feeBasedPostPaymentData,
    isPayingForPost,
  };
};

export function useFeeBasedPostIAP(): {
  feeBasedPostApplePaymentCallback: ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => Promise<any>;
} {
  const { axiosInstance } = useBackEnd();

  const feeBasedPostApplePaymentCallback = async ({
    data,
    apple_product_id,
    timestamp,
    transaction_id,
  }: {
    data?: string;
    apple_product_id: string;
    timestamp: number;
    transaction_id: string;
  }) => {
    return await axiosInstance
      .post(`/fee_based_payment/apple_iap/confirm`, {
        receipt_data: data,
        apple_product_id,
        timestamp,
        transaction_id,
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log('fee based payment confirmation:', JSON.stringify(e?.response?.data?.detail));
        return null;
      });
  };

  return {
    feeBasedPostApplePaymentCallback,
  };
}
