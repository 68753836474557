export default {
  title: {
    id: 'marketplaceOrderReport.title',
    defaultMessage: 'Report order',
  },
  actionTitle: {
    id: 'marketplaceOrderReport.actionTitle',
    defaultMessage: 'Tell us what happened',
  },
  message: {
    id: 'marketplaceOrderReport.message',
    defaultMessage: 'Message...',
  },
  email: {
    id: 'marketplaceOrderReport.email',
    defaultMessage: 'E-mail address for receviving response',
  },
  success: {
    title: {
      id: 'marketplaceOrderReport.success.title',
      defaultMessage: 'Thank you',
    },
    description: {
      id: 'marketplaceOrderReport.success.description',
      defaultMessage: 'We will contact you via e-mail to proceed with reporting.',
    },
  },
  snackbarClose: {
    id: 'marketplaceOrderReport.snackbarClose',
    defaultMessage: 'Close',
  },
  errors: {
    other: {
      id: 'marketplaceOrderReport.errors.other',
      defaultMessage: 'Close',
    },
    message: {
      required: {
        id: 'marketplaceOrderReport.errors.message.required',
        defaultMessage: 'This field is required.',
      },
    },
    email: {
      required: {
        id: 'marketplaceOrderReport.errors.email.required',
        defaultMessage: 'This field is required.',
      },
      invalid: {
        id: 'marketplaceOrderReport.errors.email.invalid',
        defaultMessage: 'Please enter a valid email.',
      },
    },
  },
};
