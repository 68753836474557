import React, { useRef } from 'react';
import { Animated } from 'react-native';

import Container from './styled/Container';
import ContentContainer from './styled/ContentContainer';
import CoverContainer from './styled/CoverContainer';
import CoverFiller from './styled/CoverFiller';
import CoverImage from './styled/CoverImage';
import FlatList from './styled/FlatList';
import HeaderContainer from './styled/HeaderContainer';
import HeaderInnerContainer from './styled/HeaderInnerContainer';
import HeaderInnerFiller from './styled/HeaderInnerFiller';
import HeaderInnerWrapper from './styled/HeaderInnerWrapper';

export interface ScrollViewWithCoverProps {
  coverUrl: string;
  renderHeader: () => JSX.Element;
  renderContent: () => JSX.Element;
  renderOverCover?: () => JSX.Element;
}

export default function ScrollViewWithCover({
  coverUrl,
  renderHeader,
  renderContent,
  renderOverCover,
}: ScrollViewWithCoverProps): JSX.Element {
  const scrollPositionY = useRef(new Animated.Value(0));

  const coverOpacity = scrollPositionY.current.interpolate({
    inputRange: [0, 375],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const headerOpacity = scrollPositionY.current.interpolate({
    inputRange: [300, 350],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const headerInnerOpacity = scrollPositionY.current.interpolate({
    inputRange: [200, 300],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });

  const extractKey = (item: unknown, index: number) => ['filler', 'header', 'spacer'][index];

  const renderItem = (info: { item: unknown; index: number }) => {
    switch (info.item) {
      case 'spacer':
        return (
          <>
            <CoverFiller height={375} style={{ opacity: coverOpacity }} />
            {renderOverCover?.()}
          </>
        );
      case 'header':
        return (
          <HeaderInnerWrapper>
            <HeaderInnerFiller style={{ opacity: coverOpacity }} />
            <HeaderInnerContainer style={{ opacity: headerInnerOpacity }}>
              {renderHeader()}
            </HeaderInnerContainer>
          </HeaderInnerWrapper>
        );
      case 'content':
        return <ContentContainer>{renderContent()}</ContentContainer>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <CoverContainer height={375}>
        <CoverImage source={{ uri: coverUrl }} />
      </CoverContainer>
      <HeaderContainer style={{ opacity: headerOpacity }}>{renderHeader()}</HeaderContainer>
      <FlatList
        keyExtractor={extractKey}
        data={['spacer', 'header', 'content']}
        renderItem={renderItem}
        scrollEnabled={true}
        nestedScrollEnabled={true}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollPositionY.current } } }],
          { useNativeDriver: true },
        )}
      />
    </Container>
  );
}
