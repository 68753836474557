import { Video } from 'expo-av';
import styled, { css } from 'styled-components/native';

import NavigationMenu from 'components/NavigationMenu';
import ResponsiveText from 'components/ResponsiveText/ResponsiveText';
import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

const typography = {
  headerBig: css`
    font-family: BasierCircle-Medium;
    font-style: normal;
    font-size: 60px;
    color: #fff;
  `,
  buttonLabel: css`
    font-family: BasierCircle-Medium;
    font-style: normal;
    font-size: 16px;
    color: #fff;
  `,
  listItem: css`
    font-family: BasierCircle-Medium;
    font-style: normal;
    font-size: 20px;
    color: #fff;
  `,
};

export const VideoWrapper = styled.View``;

export const BackgroundVideo = styled(Video)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export const NavHeaderWrapper = styled.View<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 0 15px;
        `
      : css`
          padding: 0 50px;
        `}
`;

export const NavigationMenuTop = styled(NavigationMenu)`
  margin-top: 40px;
`;

export const HeaderSectionContainer = styled.View`
  position: relative;
  height: 100vh;
`;

export const HeaderSectionInner = styled.View<{ isMobile: boolean }>`
  padding: 15px;
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: auto auto;
        `
      : css`
          margin: 20vh auto 0;
        `}
`;

export const Row = styled.View`
  flex-direction: row;
`;

export const HeaderTextRow = styled.View<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          display: grid;
          grid-auto-flow: row;
          row-gap: 10px;
        `
      : css`
          flex-direction: row;
        `}
`;

export const HeaderBig = styled.Text`
  ${typography.headerBig}
`;

export const HeaderBigWithBg = styled.Text`
  ${typography.headerBig};
  background-color: ${({ theme }) => theme.colors.backgroundBlue};
  padding: 0 24px;
  border-radius: 56px;
  margin-left: 14px;
  width: min-content;
`;

export const EmojiWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const EmojiImage = styled.Image`
  height: 66px;
  width: 66px;
`;

export const HeaderSmallWrapper = styled.View<{ isMobile: boolean }>`
  margin-top: 43px;
  flex-direction: row;
  ${({ isMobile }) =>
    isMobile &&
    css`
      text-align: center;
    `}
`;

export const HeaderSmall = styled(ResponsiveText).attrs({
  mobile: {
    fontFamily: 'BasierCircle-Medium',
    fontSize: 20,
    color: '#fff',
  },
  tabletLandscape: {
    fontSize: 20,
  },
})``;

export const HeaderSmallItalic = styled(ResponsiveText).attrs({
  mobile: {
    fontFamily: 'BasierCircle-MediumItalic',
    fontSize: 20,
    color: '#fff',
  },
  tabletLandscape: {
    fontSize: 20,
  },
})``;

export const HeaderSmallWithUnderline = styled(HeaderSmall)`
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
`;

export const HeaderButtonsWrapper = styled.View`
  margin-top: 42px;
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: center;
`;

export const TransparentButton = styled(TouchableOpacity)`
  background-color: transparent;
  padding: 12px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-radius: 56px;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const ButtonLabel = styled.Text`
  text-align: center;
  ${typography.buttonLabel};
`;

export const HeaderSectionBottomTextWrapper = styled.View<{ isMobile: boolean }>`
  flex-direction: row;

  justify-content: center;
  ${({ isMobile }) =>
    isMobile
      ? css`
          text-align: center;
          padding: 40px 0;
        `
      : css`
          position: absolute;
          bottom: 10%;
          flex: 1;
          width: 100%;
        `}
`;

export const DoubleColumnSection = styled.View<{ isSingleCol: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isSingleCol }) => (isSingleCol ? '1fr' : '1fr 1fr')};
`;

export const ListSectionWrapper = styled.View`
  background-color: #0a0177;
  min-height: 960px;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const FanImageSection = styled.View`
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #2c1bf2;
  min-height: max(50vh, 500px);
`;

export const ImageLeft = styled.Image`
  height: 65%;
  aspect-ratio: 235 / 658;
`;

export const ImageMiddle = styled.Image`
  height: 75%;
  aspect-ratio: 389 / 784;
`;

export const ImageRight = styled.Image`
  height: 65%;
  aspect-ratio: 244 / 658;
`;

export const ListSectionContent = styled.View`
  max-width: 605px;
  margin-top: 15%;
`;

export const ListSectionHeaderWrapper = styled.View``;

export const ListSectionHeaderText = styled.Text<{ isColored?: boolean; marginTop?: boolean }>`
  ${typography.headerBig};

  ${({ isColored }) =>
    isColored &&
    css`
      color: #867cfb;
    `}
`;

export const ListSectionItemsWrapper = styled.View`
  margin-top: 50px;
`;

export const ListSectionItem = styled.View<{ isHighlighted: boolean }>`
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  opacity: ${({ isHighlighted }) => (isHighlighted ? 1 : 0.5)};
  transition: opacity 0.3s ease;
`;

export const ListSectionLine = styled.View<{ isHighlighted: boolean }>`
  width: 100%;
  height: 1px;
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
  transition: background-color 0.3s ease;
`;

export const ListItemText = styled.Text`
  ${typography.listItem};
  margin-left: 10px;
`;

export const ListItemImage = styled.Image`
  width: 55px;
  height: 55px;
`;

export const StoreButtonRow = styled.View`
  flex-direction: row;
  width: 263px;
  justify-content: space-between;
  margin-top: 40px;
`;

export const ImageBackgroundSection = styled.ImageBackground`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 1200px;
`;

export const ImageBackgroundSectionContent = styled.View`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const ImageBackgroundText = styled(ResponsiveText).attrs({
  mobile: {
    fontFamily: 'BasierCircle-Regular',
    fontSize: 24,
    color: '#fff',
  },
  tabletLandscape: {
    fontSize: 52,
  },
})`
  text-align: center;
  max-width: 1267px;
`;

export const ImageBackgroundButton = styled(TransparentButton)`
  position: relative;
  top: 75px;
`;

export const ArtistImageSection = styled(FanImageSection)`
  justify-content: center;
`;

export const ImageSingleCentered = styled.Image`
  position: absolute;
  bottom: 0;
  height: 95%;
  aspect-ratio: 823 / 920;
`;

export const Footer = styled.View<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          display: grid;
          padding: 25px 15px;
          gap: 25px;
        `
      : css`
          padding: 100px 50px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
`;

export const CopyRightText = styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 16px;
  color: #fff;
`;
