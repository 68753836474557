export default {
  generalInfo: {
    id: 'settings.profileSettings.generalInfo',
    defaultMessage: 'General informations',
  },
  password: {
    id: 'settings.profileSettings.password',
    defaultMessage: 'Password',
  },
};
