import React, { useMemo } from 'react';
import styled from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

import { useArtistsFeed } from 'hooks';
import { ArtistFeed } from 'types';

import { stancastingArtistsPosition } from '../facesPositions';
import FacesView from './FacesView';
import Person from './Person';

const Background = styled.Image`
  width: 100%;
  height: 100%;
`;

interface Props {}

const StancastingArtists: React.FC<Props> = () => {
  const { data, isLoading } = useArtistsFeed();

  const profileImagesUrls = useMemo(
    () =>
      data
        ? data.map((profile: ArtistFeed) =>
            profile.medium_res_profile_picture
              ? profile.medium_res_profile_picture
              : profile.low_res_profile_picture,
          )
        : [],
    [data],
  );

  const renderFaces = () => {
    let imageIndex = 0;
    return stancastingArtistsPosition.map((face, index) => {
      if (imageIndex < profileImagesUrls.length) {
        face.source = profileImagesUrls[imageIndex];
        imageIndex++;
      } else {
        imageIndex = 0;
        face.source = profileImagesUrls[imageIndex];
        imageIndex++;
      }
      if (face.source) {
        return (
          <Person
            source={{ uri: face.source }}
            resizeMode="cover"
            width={face.width}
            height={face.height}
            bottom={face.bottom}
            right={face.right}
            isStancastingScreen={true}
            key={`OnBoardingFace-${index}`}
          />
        );
      }
    });
  };

  return (
    <FacesView>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Background
            source={require('../images/StancastingBackground.png')}
            resizeMode="contain"
          />
          {renderFaces()}
        </>
      )}
    </FacesView>
  );
};

export default StancastingArtists;
