import { useEffect, useMemo, useState } from 'react';

import { MAX_DISPLAY_LENGTH } from 'components/CommentItem/CommentItem.constants';

import { Comment } from 'types';

export const useExpandedComment = (comment: Comment) => {
  const [isExpanded, setIsExpanded] = useState(
    !(comment && comment.text.length > MAX_DISPLAY_LENGTH),
  );

  const displayText = useMemo(() => {
    if (!comment) return '';
    const commentLength = comment.text.length || 0;
    if (!isExpanded) {
      return commentLength > MAX_DISPLAY_LENGTH
        ? `${comment.text.substring(0, MAX_DISPLAY_LENGTH)}...`
        : comment.text;
    } else {
      return comment.text;
    }
  }, [comment?.text, isExpanded]);

  useEffect(() => {
    setIsExpanded(!(comment && comment.text.length > MAX_DISPLAY_LENGTH));
  }, [comment]);

  return { isExpanded, setIsExpanded, displayText };
};
