import React from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'components/Dialog';

import { VARIANT } from 'context';

import messages from './messages';
import Button from './styled/Button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirmation: () => void;
  isDecline?: boolean;
}

const ConfirmCompletingOrderDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onConfirmation,
  isDecline = false,
}: Props) => {
  const intl = useIntl();
  const dialogMessages =
    messages[VARIANT === 'artist' ? (isDecline ? 'artistDecline' : 'artistConfirm') : 'fanConfirm'];

  return (
    <Dialog
      visible={isOpen}
      title={intl.formatMessage(dialogMessages.title)}
      subtitle={intl.formatMessage(dialogMessages.description)}
    >
      <Button primary warning={isDecline} onPress={onConfirmation}>
        {intl.formatMessage(dialogMessages.confirm)}
      </Button>
      <Button secondary onPress={() => setIsOpen(false)}>
        {intl.formatMessage(dialogMessages.dismiss)}
      </Button>
    </Dialog>
  );
};

export default ConfirmCompletingOrderDialog;
