import styled from 'styled-components/native';

interface ViewProps {
  isArtist?: boolean;
}

export default styled.View<ViewProps>`
  flex-direction: row;
  justify-content: ${({ isArtist }) => (isArtist ? 'flex-start' : 'space-around')};
  margin-bottom: 30px;
`;
