import { PathConfigMap, RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_ADD_PRODUCT_SCREEN } from 'screens/ArtistAddProductScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';

import ArtistMarketplaceListItem from 'components/ArtistMarketplaceListItem';
import ArtistMarketplaceProduct from 'components/ArtistMarketplaceProduct';

import { useArtistMarketplaceProducts } from 'hooks';
import { useArtistMarketplaceOrders, useArtistMarketplaceUpdateOrder } from 'hooks/marketplace';
import { useTheme } from 'themes';
import { MarketplaceItem, MarketplaceOrder, OrderStatus } from 'types';

import { SCREEN_NAME as MARKETPLACE_SCREEN_NAME, SCREEN_NAME } from './constants';
import MarketplaceFlatlist from './MarketplaceFlatlist';
import messages from './messages';
import AddProductButton from './styled/AddProductButton';
import EmptyTabMessage from './styled/EmptyTabMessage';
import EmptyTabView from './styled/EmptyTabView';
import HandsShopping from './styled/HandsShopping';
import MainView from './styled/MainView';
import MarketplaceProductsHandsScene from './styled/MarketplaceProductsHandsScene';
import ScreenView from './styled/ScreenView';
import ShoppingCartPhone from './styled/ShoppingCartPhone';
import Tab from './styled/Tab';
import TabBar from './styled/TabBar';
import TabBarWrapper from './styled/TabBarWrapper';
import Title from './styled/Title';

export { SCREEN_NAME };

export const MARKETPLACE_SCREEN_NAME_ONGOING = `${SCREEN_NAME}_ONGOING`;
export const MARKETPLACE_SCREEN_NAME_CLOSED = `${SCREEN_NAME}_CLOSED`;
type MarketplaceScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type MarketplaceScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: MarketplaceScreenRouteProp;
  navigation: MarketplaceScreenNavigationProp;
};

export const linking: PathConfigMap = {
  [MARKETPLACE_SCREEN_NAME]: 'marketplace',
  [MARKETPLACE_SCREEN_NAME_ONGOING]: 'marketplace-ongoing',
  [MARKETPLACE_SCREEN_NAME_CLOSED]: 'marketplace-closed',
};

const ArtistMarketplaceScreen: React.FC<Props> = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const { theme } = useTheme();
  const {
    products,
    refresh: refreshProducts,
    isLoading: isLoadingProducts,
  } = useArtistMarketplaceProducts();
  const {
    orders: closedOrders,
    isLoading: isLoadingClosedOrders,
    refresh: refreshClosedOrders,
  } = useArtistMarketplaceOrders('closed');
  const {
    orders: ongoingOrders,
    isLoading: isLoadingOngoingOrders,
    refresh: refreshOngoingOrders,
  } = useArtistMarketplaceOrders('ongoing');
  const { updateOrder, deleteOrder } = useArtistMarketplaceUpdateOrder();

  const onAddProductPress = useCallback(() => {
    navigate(ARTIST_ADD_PRODUCT_SCREEN);
  }, [navigate]);

  const navigateToOrderDetails = useCallback(
    (order: MarketplaceOrder) => {
      navigate(MARKETPLACE_ORDER_DETAILS_SCREEN_NAME, {
        order: JSON.stringify(order),
        marketplaceProductId: order.product.id,
        screenTitle: order.external_id,
      });
    },
    [navigate],
  );

  const onDeletePress = useCallback(
    async (order: MarketplaceOrder) => {
      await deleteOrder(order.product.id, order.external_id);
      refreshClosedOrders();
    },
    [deleteOrder, refreshClosedOrders],
  );

  const onOngoingOrderPress = useCallback(
    (order: MarketplaceOrder) => {
      if (order.status === OrderStatus.PURCHASED) {
        updateOrder(order.product.id, order.external_id, OrderStatus.IN_PROGRESS).finally(() =>
          navigateToOrderDetails(order),
        );
      } else {
        navigateToOrderDetails(order);
      }
    },
    [navigateToOrderDetails, updateOrder],
  );

  const renderProductsEmptyContent = () => (
    <EmptyTabView>
      <MarketplaceProductsHandsScene />
      <EmptyTabMessage>{intl.formatMessage(messages.noProducts)}</EmptyTabMessage>
      <AddProductButton onPress={onAddProductPress}>
        {intl.formatMessage(messages.addProduct)}
      </AddProductButton>
    </EmptyTabView>
  );

  const renderEmptyOngoingOrders = () => (
    <EmptyTabView>
      <HandsShopping />
      <EmptyTabMessage>{intl.formatMessage(messages.noOngoingOrders)}</EmptyTabMessage>
    </EmptyTabView>
  );

  const renderEmptyClosedOrders = () => (
    <EmptyTabView>
      <ShoppingCartPhone />
      <EmptyTabMessage>{intl.formatMessage(messages.noClosedOrders)}</EmptyTabMessage>
    </EmptyTabView>
  );

  const renderClosedOrderItem = ({ item, index }: { item: MarketplaceOrder; index: number }) => (
    <ArtistMarketplaceListItem
      order={item}
      index={index}
      key={`orders-list-item-${index}`}
      deletable
      onPress={navigateToOrderDetails}
      onDeletePress={onDeletePress}
    />
  );

  const renderOngoingOrderItem = ({ item, index }: { item: MarketplaceOrder; index: number }) => (
    <ArtistMarketplaceListItem
      order={item}
      index={index}
      key={`orders-list-item-${index}`}
      onPress={onOngoingOrderPress}
      isNew={item.status == OrderStatus.PURCHASED}
      hideStatus
    />
  );

  const extractOrdersKey = (item: MarketplaceOrder) => item.external_id;
  const extractItemsKey = (item: MarketplaceItem) => item.id.toString();

  const tabs = [
    {
      label: intl.formatMessage(messages.products),
      name: SCREEN_NAME,
      renderTab: function renderProductsTab() {
        return (
          <MarketplaceFlatlist
            data={products}
            renderItem={({ item }: { item: MarketplaceItem }) => (
              <ArtistMarketplaceProduct product={item} key={`product-${item.id}`} />
            )}
            renderEmptyContent={renderProductsEmptyContent}
            extractKey={extractItemsKey}
            isLoading={isLoadingProducts}
            refresh={refreshProducts}
            numOfColumns={2}
          />
        );
      },
    },
    {
      label: intl.formatMessage(messages.ongoingOrders),
      name: MARKETPLACE_SCREEN_NAME_ONGOING,
      renderTab: function renderOngoingOrdersTab() {
        return (
          <MarketplaceFlatlist
            data={ongoingOrders}
            renderItem={renderOngoingOrderItem}
            renderEmptyContent={renderEmptyOngoingOrders}
            extractKey={extractOrdersKey}
            isLoading={isLoadingOngoingOrders}
            refresh={refreshOngoingOrders}
          />
        );
      },
    },
    {
      label: intl.formatMessage(messages.closedOrders),
      name: MARKETPLACE_SCREEN_NAME_CLOSED,
      renderTab: function renderClosedOrdersTab() {
        return (
          <MarketplaceFlatlist
            data={closedOrders}
            renderItem={renderClosedOrderItem}
            renderEmptyContent={renderEmptyClosedOrders}
            extractKey={extractOrdersKey}
            isLoading={isLoadingClosedOrders}
            refresh={refreshClosedOrders}
          />
        );
      },
    },
  ];

  const onTabPress = useCallback(
    (index: number) => navigate(Object.keys(linking)[index]),
    [navigate],
  );

  const renderTabs = () => (
    <Tab.Navigator
      sceneContainerStyle={{ backgroundColor: theme.colors.background }}
      swipeEnabled={false}
      tabBar={({ state }) => {
        return (
          <TabBarWrapper>
            <TabBar
              activeBackground={theme.colors.backgroundLight}
              tabs={tabs.map((tab) => tab.label)}
              tabLabelStyle={{
                fontFamily: 'BasierCircle-Regular',
                fontSize: 15,
                lineHeight: 20,
                textAlign: 'center',
                letterSpacing: -0.23,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              activeLabelColor={theme.colors.text}
              inactiveLabelColor={theme.colors.backgroundDark}
              activeIndex={state.index}
              onTabPress={onTabPress}
            />
          </TabBarWrapper>
        );
      }}
    >
      {tabs.map((tab, tabIndex) => (
        <Tab.Screen key={`Tab ${tabIndex + 1}`} name={tab.name}>
          {() => {
            const { renderTab } = tab;
            return renderTab();
          }}
        </Tab.Screen>
      ))}
    </Tab.Navigator>
  );

  return (
    <ScreenView>
      <MainView>
        <Title>{intl.formatMessage(messages.title)}</Title>
        {renderTabs()}
      </MainView>
    </ScreenView>
  );
};

export default ArtistMarketplaceScreen;
