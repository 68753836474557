import styled from 'styled-components/native';

export default styled.Text`
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.26px;
  width: 230px;
`;
