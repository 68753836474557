import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import ProductPreview from 'components/ProductPreview';

import { useScreenSize } from 'hooks/responsive';
import { ArtistProduct } from 'types';
import { pickPreviewImage } from 'utils/product';

import Column from './styled/Column';
import ProductContainer from './styled/ProductContainer';
import Wrapper from './styled/Wrapper';

interface Props {
  data?: ArtistProduct[];
  isLoading?: boolean;
  onProductPreview?: (product: ArtistProduct) => void;
}

const NewMasonry: React.FC<Props> = ({ data, isLoading, onProductPreview }: Props) => {
  const [containerWidth, setContainerWidth] = useState<number>();
  const media = useScreenSize();
  const COLUMN_MIN_WIDTH_MOBILE = 150;
  const COLUMN_MIN_WIDTH_TABLET = 300;

  useEffect(() => {
    if (Platform.OS === 'web') {
      setContainerWidth(window.innerWidth - 100);
    }
  }, []);

  const COLUMN_MIN_WIDTH = useMemo(() => {
    return media.tablet ? COLUMN_MIN_WIDTH_TABLET : COLUMN_MIN_WIDTH_MOBILE;
  }, []);

  const columnsNumber = useMemo(() => {
    if (containerWidth) {
      return Math.floor(containerWidth / COLUMN_MIN_WIDTH);
    }
  }, [media.mobile, media.tablet, containerWidth]);

  const columnWidth = useMemo(() => {
    if (containerWidth && columnsNumber) {
      return containerWidth / columnsNumber;
    }
  }, [containerWidth, columnsNumber]);

  const coverBoundary = useMemo(() => (columnWidth || COLUMN_MIN_WIDTH) * 2, [columnWidth]);

  const sortByHeight = (elements: any, columns: number) => {
    const sorted: any = [];
    for (let i = 0; i < columns; i++) {
      sorted.push({ height: 0, elements: [] });
    }
    if (!isLoading) {
      elements?.forEach((element: ArtistProduct) => {
        const previewElement = pickPreviewImage(element);
        previewElement.height =
          element.model_name === 'poll' || element.model_name === 'audio'
            ? columnWidth
            : previewElement.height || 0;

        if (previewElement.height && previewElement.height >= coverBoundary) {
          previewElement.height = coverBoundary;
        }

        const smallestNumberInArr = Math.min(...sorted.map((s: { height: number }) => s.height));
        const smallestIndex = sorted.indexOf(
          sorted.find((s: { height: number }) => s.height === smallestNumberInArr),
        );

        sorted[smallestIndex].elements.push(element);
        sorted[smallestIndex].height += previewElement.height;
      });
    }
    return sorted;
  };

  const sortedByHeight = useMemo(() => {
    if (columnsNumber) {
      return sortByHeight(data, columnsNumber);
    }
    return [];
  }, [data, isLoading, columnsNumber]);

  const renderMasonry = useCallback(() => {
    if (!isLoading) {
      return sortedByHeight.map(
        (
          { elements: group }: { elements: ArtistProduct[]; height: number },
          groupIndex: number,
        ) => {
          return (
            <Column
              isFirst={groupIndex === 0}
              isLast={groupIndex === sortedByHeight.length - 1}
              key={`masonry-column-${groupIndex}`}
              columnWidth={columnWidth}
            >
              {group.map((itemData: any, elementIndex: number) => {
                const typedItemData = itemData as ArtistProduct;
                const previewImage = pickPreviewImage(typedItemData);

                previewImage.height =
                  typedItemData.model_name === 'poll' || typedItemData.model_name === 'audio'
                    ? columnWidth
                    : previewImage.height;
                if (previewImage?.height && previewImage.height >= coverBoundary) {
                  previewImage.height = coverBoundary;
                }
                return (
                  <ProductContainer key={`masonry-item-${elementIndex}-column-${groupIndex}`}>
                    <ProductPreview
                      cover={previewImage}
                      hasAccess={typedItemData.has_access}
                      imageUrl={previewImage.url}
                      imageHeight={previewImage.height as number}
                      imageWidth={previewImage.width}
                      type={typedItemData.model_name as any}
                      question={typedItemData.question}
                      masonryColumnWidth={columnWidth as number}
                      onPress={() => onProductPreview?.(typedItemData)}
                      isMasonry
                    />
                  </ProductContainer>
                );
              })}
            </Column>
          );
        },
      );
    }
  }, [data, isLoading, sortedByHeight, columnWidth]);

  return (
    <Wrapper
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => {
        if (Platform.OS !== 'web') {
          setContainerWidth(width);
        }
      }}
    >
      {renderMasonry()}
    </Wrapper>
  );
};

export default memo(NewMasonry);
