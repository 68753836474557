import React from 'react';

import DeleteButton from './styled/DeleteButton';
import Name from './styled/Name';
import Thumbnail from './styled/Thumbnail';
import Wrapper from './styled/Wrapper';

interface Props {
  artistThumbnailUrl: string;
  artistFullname: string;
  onArtistClick?: () => void;
  onDeleteClick: () => void;
}

const ArtistHistoryResult: React.FC<Props> = ({
  artistThumbnailUrl,
  artistFullname,
  onArtistClick,
  onDeleteClick,
}: Props) => {
  return (
    <Wrapper onPress={onArtistClick}>
      <Thumbnail
        source={{
          uri: artistThumbnailUrl,
        }}
      />
      <Name>{artistFullname}</Name>
      <DeleteButton onDelete={onDeleteClick} />
    </Wrapper>
  );
};

export default ArtistHistoryResult;
