import { format } from 'date-fns';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { VARIANT } from 'context/appInfo';
import { OrderStatus } from 'types';
import { translateMarketplaceOrderStatus } from 'utils/marketplace';

import messages from './messages';
import Container from './styled/Container';
import Text from './styled/Text';

export type OrderStatusDescriptionOption = 'declined' | 'completed';

export interface OrderStatusDescriptionProps {
  /** The status of the order */
  status: OrderStatus;
  /** The number of days left to return funds */
  returnFundsDays?: number;
  /** The date of status change */
  date: string;
}

/**
 * Orders status description
 */
const OrderStatusDescription: React.FC<OrderStatusDescriptionProps> = ({
  status,
  returnFundsDays = 12,
  date,
  ...rest
}: OrderStatusDescriptionProps) => {
  const intl = useIntl();
  const parsedDate = new Date(date);
  const confirmationDate = format(parsedDate, 'dd/MMM/yyyy');
  const confirmationTime = format(parsedDate, 'H:m');
  const translatedStatus = translateMarketplaceOrderStatus(status);

  return (
    <Container status={translatedStatus} {...rest}>
      <Text status={translatedStatus}>
        {intl.formatMessage(messages[VARIANT][translatedStatus as OrderStatusDescriptionOption], {
          returnFundsDays,
          confirmationDate,
          confirmationTime,
        })}
      </Text>
    </Container>
  );
};

OrderStatusDescription.displayName = 'OrderStatusDescription';

export default OrderStatusDescription;
