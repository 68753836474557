import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

interface Props {
  overlayButtonMarginTop?: number;
  isExpanded?: boolean;
}

export default styled(LinearGradient).attrs(() => ({
  colors: ['transparent', 'transparent'],
  start: { x: 0, y: 0 },
  end: { x: 0, y: 1 },
}))<Props>`
  left: 0;
  top: 0;
  padding: ${({ overlayButtonMarginTop, isExpanded }) =>
      isExpanded ? overlayButtonMarginTop || 0 : 10}px
    10px 50px;
  width: 100%;
`;
