import React, { useMemo } from 'react';
import styled from 'styled-components/native';

import BackButton from './BackButton';
import { RefreshPageSvg } from './Icons';
import NavigationButton from './NavigationButton';

const View = styled.SafeAreaView`
  width: 100%;
  background-color: ${({ theme }) => theme.webview.background};
  border-bottom-width: 1px;
  border-bottom-color: #9e9e9e;
  z-index: 10;
`;

const PaddingView = styled.View`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const TextUrl = styled.Text`
  width: 40%;
  font-size: 15px;
  color: ${({ theme }) => theme.webview.text};
  margin-right: 18px;
  text-align: center;
`;

interface TopNavbarProps {
  url: string;
  onRefresh: () => void;
  onBackToApp: () => void;
}

const TopNavbar: React.FC<TopNavbarProps> = ({ url, onBackToApp, onRefresh }: TopNavbarProps) => {
  const memoizedUrl = useMemo(() => {
    if (url.includes('https://')) {
      return url.substring(8);
    }
    if (url.includes('http://')) {
      return url.substring(7);
    }
    return url;
  }, [url]);

  return (
    <View>
      <PaddingView>
        <BackButton onPress={onBackToApp} />
        <TextUrl>🔒{memoizedUrl}</TextUrl>
        <NavigationButton icon={<RefreshPageSvg />} onPress={onRefresh} />
      </PaddingView>
    </View>
  );
};

export default TopNavbar;
