import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.View<{ isFirst: boolean; read: boolean }>`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;
  width: 100%;

  ${mediaWeb(css`
    padding: 9px 14px;
  `)};

  ${({ isFirst, theme }) =>
    isFirst
      ? ''
      : `
    border-top-color: ${theme.notificationsGroup.border};
    border-top-width: 1px;
  `}

  background-color: ${({ theme, read }) =>
    read ? theme.notificationsGroup.readBackground : theme.notificationsGroup.unreadBackground};
`;
