import { memo } from 'react';
import styled from 'styled-components/native';

import { statusBarHeight } from 'utils/screen';

export default memo(styled.SafeAreaView`
  flex: 1;
  background-color: ${(props) => props.theme.colors.background};
  padding-top: ${statusBarHeight}px;
`);
