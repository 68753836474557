export default {
  unlockPaymentRequestTitle: {
    id: 'feed.unlock.title',
    defaultMessage: 'Become a Stan',
  },
  unlockPaymentRequestDescription: {
    id: 'feed.unlock.description',
    defaultMessage: 'Subscribe to {username} and get access to exclusive Stan only content!',
  },
  emptyMessage: {
    id: 'feed.comments.empty',
    defaultMessage: 'There are no comments yet.',
  },
};
