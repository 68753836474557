import React, { useMemo, useState } from 'react';
import { Dimensions, LayoutChangeEvent } from 'react-native';

import LockedItem from 'components/LockedItem';

import { ProductIcon } from 'svg';
import { Cover, PreviewImage, ProductType } from 'types';
import { countDefaultPreviewWidth } from 'utils/product';

import audioCard from './images/audio_card.png';
import AudioCardImg from './styled/AudioCardImg';
import AudioCardView from './styled/AudioCardView';
import BackgroundFade from './styled/BackgroundFade';
import BackgroundImage from './styled/BackgroundImage';
import BackgroundImageView from './styled/BackgroundImageView';
import IconLockedSmall from './styled/IconLockedSmall';
import ImageGradient from './styled/ImageGradient';
import OverlayView from './styled/OverlayView';
import ProductTypeIconView from './styled/ProductTypeIconView';
import QuestionText from './styled/QuestionText';
import TouchableOpacity from './styled/TouchableOpacity';

interface ProductPreviewProps {
  isInsights?: boolean;
  cover?: PreviewImage;
  hasAccess?: boolean;
  type: ProductType;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight: number;
  onPress?: () => void;
  question?: string;
  autoSize?: boolean;
  isMasonry?: boolean;
  masonryColumnWidth: number;
}

const ProductPreview: React.FC<ProductPreviewProps> = ({
  isInsights,
  cover,
  hasAccess,
  imageUrl,
  type,
  imageWidth,
  imageHeight,
  onPress,
  question,
  autoSize,
  isMasonry,
  masonryColumnWidth,
  ...restProps
}: ProductPreviewProps) => {
  const [bgImageHeight, setBgImageHeight] = useState(countDefaultPreviewWidth());
  const [viewWidth, setViewWidth] = useState(countDefaultPreviewWidth());
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const onLayout = (event: LayoutChangeEvent) => {
    setViewWidth(event.nativeEvent.layout.width);
    if (!imageWidth || !imageHeight) {
      return;
    }
    setBgImageHeight(imageHeight);
  };

  const onImageLoad = () => {
    try {
      setIsImageLoaded(true);
    } catch (e) {
      // component has already unmounted
    }
  };

  const styleProps = {
    style: autoSize
      ? {
          height: Dimensions.get('screen').width * 0.44,
          width: Dimensions.get('screen').width * 0.44,
        }
      : {
          height:
            type !== 'poll' && type !== 'audio'
              ? isMasonry
                ? imageHeight * 0.85
                : bgImageHeight
              : isMasonry
              ? masonryColumnWidth
              : viewWidth,
        },
  };

  const shouldDisplayGradient = useMemo(() => type !== 'poll' && type !== 'audio', [type]);

  const shouldDisplayImage = useMemo(
    () =>
      (hasAccess && imageUrl && type !== 'poll' && type !== 'audio') || type == 'marketplaceitem',
    [hasAccess, imageUrl, type],
  );

  return (
    <TouchableOpacity
      {...restProps}
      {...styleProps}
      onLayout={isMasonry ? undefined : onLayout}
      onPress={onPress}
    >
      {shouldDisplayImage && (
        <BackgroundImageView>
          <BackgroundImage source={{ uri: imageUrl }} onLoad={onImageLoad} />
        </BackgroundImageView>
      )}
      {hasAccess && type === 'poll' && (
        <OverlayView>{<QuestionText>{`Q: ${question}`}</QuestionText>}</OverlayView>
      )}
      {type === 'audio' && (
        <OverlayView>
          <AudioCardView>
            <BackgroundFade isVisible={isImageLoaded}>
              <AudioCardImg
                source={audioCard}
                onLoad={onImageLoad}
                blurRadius={0}
                isInsights={isInsights}
              />
            </BackgroundFade>
          </AudioCardView>
        </OverlayView>
      )}
      {!hasAccess && type !== 'marketplaceitem' && (
        <LockedItem type={type} cover={cover as Cover} smallItem />
      )}
      {shouldDisplayGradient && (
        <>
          <ImageGradient dark />
          <ImageGradient />
        </>
      )}
      {!hasAccess && <IconLockedSmall />}
      <ProductTypeIconView>
        <ProductIcon type={type} />
      </ProductTypeIconView>
    </TouchableOpacity>
  );
};

export default ProductPreview;
