import React from 'react';
import { Platform, StyleProp, TextStyle } from 'react-native';
import styled from 'styled-components/native';

import ActivityIndicator from './styled/ActivityIndicator';
import BlurView from './styled/BlurView';
import ButtonContentView from './styled/ButtonContentView';
import DialogTouchableOpacity from './styled/DialogTouchableOpacity';
import Label from './styled/Label';

const OverlayView = styled.View`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  border-radius: 30px;
`;

export interface ButtonProps {
  bold?: boolean;
  /** Accented button reserved for primary interactions */
  primary?: boolean;
  secondary?: boolean;
  alternative?: boolean;
  /** Orange warning styled button */
  warning?: boolean;
  short?: boolean;
  pay?: boolean;
  input?: boolean;
  inputActive?: boolean;
  icon?: React.ComponentType;
  iconAlign?: 'left' | 'right';
  disabled?: boolean;
  processing?: boolean;
  onPress?: () => void;
  labelColor?: string;
  gradient?: boolean;
  blurAmount?: number;
  children?: React.ReactNode;
  labelStyle?: StyleProp<TextStyle>;
}

//copy of Button with changed TouchableOpacity, normal Button doesn't work in react-native Modal
const Button: React.FC<ButtonProps> = ({
  bold = true,
  primary,
  secondary,
  alternative,
  warning,
  labelColor,
  gradient,
  blurAmount,
  short,
  pay,
  input,
  inputActive,
  icon,
  iconAlign = 'left',
  disabled,
  processing,
  onPress,
  children,
  labelStyle,
  ...restProps
}: ButtonProps) => {
  const IconComponent = icon;
  const isDisabled = disabled || processing;

  const renderContent = () => (
    <ButtonContentView short={short}>
      {IconComponent && iconAlign === 'right' && <IconComponent />}
      <Label
        bold={bold}
        primary={primary}
        secondary={secondary}
        alternative={alternative}
        warning={warning}
        labelColor={labelColor}
        pay={pay}
        input={input}
        inputActive={inputActive}
        disabled={isDisabled}
        style={labelStyle}
      >
        {React.Children.toArray(children)}
      </Label>
      {IconComponent && iconAlign === 'left' && <IconComponent />}
      {processing && <ActivityIndicator short={short} />}
      {gradient && secondary && <OverlayView />}
    </ButtonContentView>
  );

  return (
    <DialogTouchableOpacity
      {...restProps}
      gradient={gradient}
      primary={primary}
      secondary={secondary}
      alternative={alternative}
      warning={warning}
      pay={pay}
      short={short}
      input={input}
      inputActive={inputActive}
      disabled={isDisabled}
      onPress={isDisabled ? undefined : onPress}
    >
      {!!blurAmount && Platform.OS !== 'android' ? (
        <BlurView blurAmount={blurAmount}>{renderContent()}</BlurView>
      ) : (
        renderContent()
      )}
    </DialogTouchableOpacity>
  );
};

export default Button;
