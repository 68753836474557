import { NotificationsGroupType, SingleNotification } from 'types';

import { getDateKey } from './date';

export function groupNotifications(notifications?: SingleNotification[]): NotificationsGroupType[] {
  if (!notifications) return [];

  const pregroupedNotifications: any = notifications.reduce(
    (groups: any, notification: SingleNotification) => {
      const { time_created: timestamp } = notification;
      const objectKey = getDateKey(timestamp);

      if (!groups[objectKey]) {
        groups[objectKey] = [];
      }

      groups[objectKey].push(notification);

      return groups;
    },
    {},
  );

  const groupedNotifications: NotificationsGroupType[] = Object.keys(pregroupedNotifications).map(
    (groupKey: string) => ({
      dateKey: groupKey,
      notifications: pregroupedNotifications[groupKey],
    }),
  );

  return groupedNotifications;
}
