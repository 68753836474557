import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { WebviewContext } from 'context/webview';
import { hashtag, link } from 'utils/regex';

import HashtagText from './styled/HashtagText';
import LinkText from './styled/LinkText';
import PlaceholderText from './styled/PlaceholderText';
import Text from './styled/Text';
import View from './styled/View';

export interface CaptionProps {
  text?: string | undefined;
  maxLength?: number;
  isPlaceholder?: boolean;
  isExpanded?: boolean;
  shouldFormatLink?: boolean;
  onHashtagPress?: any;
}

interface TextReplace {
  patternText: string;
  temporaryText: string | undefined;
  type: string;
}

interface PatternDetection {
  text: string | undefined;
  pattern: RegExp;
  type: string;
}

const HASHTAG_TAG = 'HashtagText';
const LINK_TAG = 'LinkText';

const Caption: React.FC<CaptionProps> = ({
  text,
  maxLength,
  isPlaceholder,
  isExpanded = false,
  shouldFormatLink = false,
  onHashtagPress,
  ...restProps
}: CaptionProps) => {
  const intl = useIntl();
  const { openWebView } = useContext(WebviewContext);

  const displayText = useMemo(() => {
    if (text && text.length > (maxLength as number) && !isExpanded) {
      return `${text.substring(0, maxLength).trimEnd()}...`;
    }
    return text;
  }, [text, maxLength, isExpanded]);

  const trimWWW = (link: string) => {
    if (link.includes('www.')) {
      link = link.substring(4, link.length);
    }
    return link;
  };

  const trimLink = (link: string) => {
    if (link.includes('https://')) {
      link = link.substring(8, link.length);
      link = trimWWW(link);
    }
    if (link.includes('http://')) {
      link = link.substring(7, link.length);
      link = trimWWW(link);
    }
    link = trimWWW(link);
    return link;
  };

  const textReplace = ({ patternText, temporaryText, type }: TextReplace) => {
    if (type === 'hashtag' && temporaryText) {
      return temporaryText.replace(
        patternText,
        `<${HASHTAG_TAG}>${trimLink(patternText)}</${HASHTAG_TAG}>`,
      );
    }
    if (type === 'link' && temporaryText) {
      return temporaryText.replace(
        patternText,
        `<${LINK_TAG}>${trimLink(patternText)}</${LINK_TAG}>`,
      );
    }
  };

  const patternDetection = ({ text, pattern, type }: PatternDetection) => {
    // const match = text ? text.match(pattern) : '';
    // if (match && text) {
    //   const tempPatternArray = match;
    //   if (type === 'hashtag') {
    //     tempPatternArray?.forEach((patternText) => {
    //       text = textReplace({ patternText: patternText, temporaryText: text, type: 'hashtag' });
    //     });
    //   }
    //   if (type === 'link' && shouldFormatLink) {
    //     tempPatternArray?.forEach((patternText) => {
    //       text = textReplace({ patternText: patternText, temporaryText: text, type: 'link' });
    //     });
    //   }
    // }
    return text;
  };

  const modifyText = (text: string | undefined) => {
    if (text) {
      const afterHashtagDetection = patternDetection({
        text: text,
        pattern: hashtag,
        type: 'hashtag',
      });
      const afterLinksDetection = patternDetection({
        text: afterHashtagDetection,
        pattern: link,
        type: 'link',
      });
      return afterLinksDetection;
    }
  };

  const onLinkPress = useCallback(
    (link) => {
      if (link.includes('https://') || link.includes('http://')) {
        return openWebView(link);
      }
      return openWebView(`https://${link}`);
    },
    [openWebView],
  );

  const memoizedText = useMemo(() => modifyText(displayText), [displayText]);

  return (
    <View {...restProps}>
      {isPlaceholder ? (
        <PlaceholderText minNumWords={5} maxNumWords={9} />
      ) : (
        <Text>
          {memoizedText &&
            intl.formatMessage(
              { id: 'post.caption', defaultMessage: memoizedText },
              {
                [HASHTAG_TAG]: function HashtagChunk(hashtag) {
                  return (
                    <HashtagText onPress={() => onHashtagPress?.(hashtag[0])}>
                      {hashtag}
                    </HashtagText>
                  );
                },
                [LINK_TAG]: function LinkChunk(link) {
                  return <LinkText onPress={() => onLinkPress(link)}>{link}</LinkText>;
                },
              },
            )}
        </Text>
      )}
    </View>
  );
};

Caption.defaultProps = {
  maxLength: 160,
  isPlaceholder: false,
  onHashtagPress: undefined,
};

export default memo(Caption);
