import React from 'react';
import { GestureResponderEvent } from 'react-native';

import Fade from 'components/TabBarIcon/styled/Fade';

import NotificationIcon from './styled/NotificationIcon';
import WrapperTouchable from './styled/WrapperTouchable';
import WrapperView from './styled/WrapperView';

interface TabBarIconProps {
  iconActive: React.ReactNode;
  iconInactive: React.ReactNode;
  focused: boolean;
  hasNotifications?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
}

const TabBarIcon: React.FC<TabBarIconProps> = ({
  iconActive,
  iconInactive,
  focused,
  hasNotifications = false,
  onPress,
  ...restProps
}: TabBarIconProps) => {
  const renderContent = () => {
    return (
      <>
        {focused && <Fade>{iconActive}</Fade>}
        {!focused && <Fade>{iconInactive}</Fade>}
        {hasNotifications && (
          <Fade>
            <NotificationIcon />
          </Fade>
        )}
      </>
    );
  };

  if (onPress) {
    return (
      <WrapperTouchable onPress={onPress} {...restProps}>
        {renderContent()}
      </WrapperTouchable>
    );
  }

  return <WrapperView {...restProps}>{renderContent()}</WrapperView>;
};

export default TabBarIcon;
