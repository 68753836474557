import styled, { css } from 'styled-components/native';

interface ItemLabelProps {
  active?: boolean;
  activeLabelColor?: string;
  inactiveLabelColor?: string;
}

export default styled.View<ItemLabelProps>`
  position: relative;
  font-size: 15px;
  padding: 7px 14px;

  ${({ active, theme, activeLabelColor, inactiveLabelColor }) => css`
    color: ${active
      ? activeLabelColor || theme.colors.background
      : inactiveLabelColor || theme.colors.primaryInactive};
  `}
`;
