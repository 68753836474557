import { css } from 'styled-components';
import styled from 'styled-components/native';

interface Props {
  mid?: boolean;
  isArtist?: boolean;
  solid?: boolean;
  top?: boolean;
  bottom?: boolean;
}

export default styled.TouchableOpacity<Props>`
  height: 56px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ solid, theme }) =>
    solid ? theme.button.modalSolid : theme.button.modalGhost};

  ${({ top, bottom, mid }) =>
    top
      ? css`
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
          margin: 0;
        `
      : bottom
      ? css`
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          margin: 0 0 4px;
        `
      : mid
      ? css`
          border-radius: 0px;
        `
      : css`
          margin-top: 8px;
          border-radius: 14px;
        `}
`;
