import styled from 'styled-components/native';

export default styled.View`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.text};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
