import styled from 'styled-components/native';

export default styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  z-index: 10;
  padding: 25px 35px;
  position: fixed;
  top: 0;
`;
