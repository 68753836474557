import { useCallback, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { WebviewInterface, WebviewUrlMethod } from 'context/webview';

const googleDocsEmbedUri = 'https://docs.google.com/gview?embedded=true&url=';
const isPdf = (url: string): boolean => url.toLowerCase().endsWith('.pdf');

export const useWebview = (maxDisplayUrlLength = 24): WebviewInterface => {
  const [isWebviewOpen, setIsWebviewOpen] = useState<boolean>(false);
  const [webviewUrlToOpen, setWebviewUrlToOpen] = useState<string>('');
  const [webviewUrlMethod, setWebviewUrlMethod] = useState<WebviewUrlMethod>();
  const [webviewUrlBody, setWebviewUrlBody] = useState<string>();
  const [currentUrl, setCurrentUrl] = useState<string>();
  const displayUrl = useMemo(() => {
    let url = webviewUrlToOpen;
    if (Platform.OS === 'android' && isPdf(webviewUrlToOpen)) {
      url = url.substring(googleDocsEmbedUri.length);
    }
    return url.length > maxDisplayUrlLength ? `${url.substring(0, maxDisplayUrlLength)}...` : url;
  }, [webviewUrlToOpen, maxDisplayUrlLength]);

  const prepareUrl = (url: string): string =>
    Platform.OS === 'android' && isPdf(url) ? `${googleDocsEmbedUri}${url}` : url;

  const openWebView = useCallback(
    (url: string, method?: WebviewUrlMethod, body?: string) => {
      setWebviewUrlToOpen(prepareUrl(url));
      setWebviewUrlMethod(method);
      setWebviewUrlBody(body);
      setIsWebviewOpen(true);
    },
    [setIsWebviewOpen, setWebviewUrlToOpen, setWebviewUrlMethod, setWebviewUrlBody],
  );

  return {
    isWebviewOpen,
    setIsWebviewOpen,
    webviewUrlToOpen,
    setWebviewUrlToOpen,
    webviewUrlMethod,
    webviewUrlBody,
    openWebView,
    displayUrl,
    currentUrl,
    setCurrentUrl,
  };
};
