import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

interface Props {
  isArtist?: boolean;
  solid?: boolean;
  isTop?: any;
  isBottom?: any;
}

export default styled.View<Props>`
  height: 56px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.button.modalSolid};
  margin: 4px 0;

  ${Platform.OS === 'web' &&
  css`
    width: 350px;
  `}

  ${({ isTop, isBottom }) =>
    isTop
      ? css`
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
          margin: 0;
        `
      : isBottom
      ? css`
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          margin: 0 0 4px;
        `
      : css`
          border-radius: 14px;
        `}
`;
