import styled from 'styled-components/native';

export default styled.View`
  background-color: ${({ theme }) => theme.artistDashboard.buttonBackground};
  width: 49px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 5px;
  margin-top: 8px;
`;
