import * as React from 'react';

import { OrderStatus } from 'types';

import ActionWrapper from './styled/ActionWrapper';
import ArrowIcon from './styled/ArrowIcon';
import ArtistName from './styled/ArtistName';
import Container from './styled/Container';
import ImagePreview from './styled/ImagePreview';
import ImageWrapper from './styled/ImageWrapper';
import InfoWrapper from './styled/InfoWrapper';
import OrderName from './styled/OrderName';
import OrderStatusBadge from './styled/OrderStatusBadge';
import StatusWrapper from './styled/StatusWrapper';
import TouchableOpacity from './styled/TouchableOpacity';

export interface OrderDetailsHeaderProps {
  /** The status of the order */
  status?: OrderStatus;
  /** The preview image url for the order */
  previewImage?: string;
  /** Artist display name */
  artistName: string;
  /** The order name */
  name: string;
  /** is order waiting for confirmation for stan */
  waitingForConfirmation?: boolean;
  /** On press function */
  onPress?: any;
}

/**
 * Orders preview in Marketplace module
 */
const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({
  artistName,
  name,
  previewImage,
  status,
  onPress,
  waitingForConfirmation,
  ...rest
}: OrderDetailsHeaderProps) => {
  const renderContent = () => (
    <Container {...rest}>
      <ImageWrapper>{previewImage && <ImagePreview source={{ uri: previewImage }} />}</ImageWrapper>
      <InfoWrapper>
        <ArtistName>{artistName}</ArtistName>
        <OrderName>{name}</OrderName>
      </InfoWrapper>
      {status && (
        <StatusWrapper>
          <OrderStatusBadge status={status} waitingForConfirmation={waitingForConfirmation} />
        </StatusWrapper>
      )}
      {!status && !!onPress && (
        <ActionWrapper>
          <ArrowIcon />
        </ActionWrapper>
      )}
    </Container>
  );

  if (!onPress) {
    return renderContent();
  }

  return <TouchableOpacity onPress={onPress}>{renderContent()}</TouchableOpacity>;
};

OrderDetailsHeader.displayName = 'OrderDetailsHeader';

export default OrderDetailsHeader;
