import React from 'react';
import styled from 'styled-components/native';

import Button from 'components/Button';

const ButtonWrapper = styled.View`
  flex: 1;
`;

export default styled(({ ...rest }) => (
  <ButtonWrapper>
    <Button {...rest} />
  </ButtonWrapper>
)).attrs({ primary: true })``;
