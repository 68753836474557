export default {
  title: {
    id: 'artist.post.insights.title',
    defaultMessage: 'Insights',
  },
  general: {
    title: {
      id: 'artist.post.insights.general',
      defaultMessage: 'General Insights',
    },
  },
  tips: {
    title: {
      id: 'artist.post.insights.tips.title',
      defaultMessage: 'Tips Revenue',
    },
    average: {
      id: 'artist.post.insights.tips.average',
      defaultMessage: 'avg {value}',
    },
    footer: {
      id: 'artist.post.insights.tips.footer',
      defaultMessage: '{value} Tips Total',
    },
    financialData: {
      id: 'artist.graph.financialData',
      defaultMessage: 'Financial data not available until 36 days after profile launch',
    },
  },
  stans: {
    title: {
      id: 'artist.post.insights.stans.title',
      defaultMessage: 'New Stans',
    },
    perMonth: {
      id: 'artist.post.insights.stans.perMonth',
      defaultMessage: '{value}/mth',
    },
    footer: {
      id: 'artist.post.insights.stans.footer',
      defaultMessage: 'Total Stans',
    },
  },
  reach: {
    title: {
      id: 'artist.post.insights.reach.title',
      defaultMessage: 'Post Reach',
    },
    stans: {
      id: 'artist.post.insights.reach.stans',
      defaultMessage: 'Stans Reach',
    },
    followers: {
      id: 'artist.post.insights.reach.followers',
      defaultMessage: 'Followers Reach',
    },
    following: {
      id: 'artist.post.insights.reach.following',
      defaultMessage: "{value}% of viewers aren't subscribed yet",
    },
  },
  engagement: {
    title: {
      id: 'artist.post.insights.engagement.title',
      defaultMessage: 'Post Engagement',
    },
    info: {
      id: 'artist.post.insights.engagement.info',
      defaultMessage: 'average time spent with recording',
    },
    footer: {
      id: 'artist.post.insights.engagement.footer',
      defaultMessage: 'Recording length: {value}',
    },
  },
};
