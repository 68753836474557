import styled from 'styled-components/native';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

export default styled(TouchableOpacity)`
  position: absolute;
  top: 30px;
  left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
