import { Ionicons } from '@expo/vector-icons';
import React, { memo } from 'react';
import styled from 'styled-components/native';

const Icon = (props: any) => <Ionicons name="checkmark-circle" {...props} />;

export default memo(styled(Icon).attrs({
  size: 16,
})`
  color: ${({ theme }) => theme.pollContent.primary};
  margin-left: 7px;
`);
