import { Platform } from 'react-native';
import styled from 'styled-components/native';

interface Props {
  customWidth?: string;
}

export default styled.View<Props>`
  border-radius: 13px;
  overflow: hidden;
  width: ${({ customWidth }) =>
    Platform.OS === 'web' ? '300px' : customWidth ? customWidth : '100%'};
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.background};
`;
