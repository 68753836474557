import * as SplashScreen from 'expo-splash-screen';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Alert, LogBox, Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import AppContainer from 'containers/App';

import { BackEndProvider } from 'context/backEnd';
import { useFontBasierCircle, useFontBasierSquare, useMiscFonts } from 'hooks/fonts';

import 'intl';
import 'intl/locale-data/jsonp/en';

SplashScreen.preventAutoHideAsync();
WebBrowser.maybeCompleteAuthSession();

if (process.env.ENV !== 'local') {
  if (!process.env.SENTRY_DSN?.startsWith('https://')) {
    Alert.alert('Sentry', 'No or invalid DSN provided.');
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enableInExpoDevelopment: true,
    enableAutoSessionTracking: true,
    sessionTrackingIntervalMillis: 10000, // Sessions close after app is 10 seconds in the background.
    environment: process.env.ENV,
    debug: process.env.DEBUG === 'true',
  });

  const PlatformSentry = Platform.OS === 'web' ? Sentry.Browser : Sentry.Native;
  PlatformSentry.setTag('variant', process.env.VARIANT);
}

export default function App(): JSX.Element {
  useFontBasierCircle();
  useFontBasierSquare();
  useMiscFonts();

  LogBox.ignoreLogs(['on the ref of an Animated component']);

  return (
    <BackEndProvider>
      <AppContainer />
    </BackEndProvider>
  );
}
