import React from 'react';
import styled from 'styled-components/native';

const Text = styled.Text`
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.08px;
  color: #ffffff;
`;

const PendingIcon: React.FC = () => {
  return <Text>🚫</Text>;
};

export default PendingIcon;
