import { memo } from 'react';
import styled, { css } from 'styled-components/native';

interface ContentViewProps {
  isExpanded?: boolean;
}

export default memo(styled.View<ContentViewProps>`
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      padding: 0 15px;
      margin-top: 20px;
    `}
`);
