import styled from 'styled-components/native';

export default styled.Text.attrs(() => ({
  numberOfLines: 1,
}))`
  color: ${({ theme }) => theme.orderAttachment.primaryText};
  font-family: BasierCircle-Regular;
  font-size: 17px;
  line-height: 20px;
  margin-right: 10px;
`;
