import { Platform } from 'react-native';

export type UploadFile =
  | File
  | {
      uri: string;
      type: string;
      name: string;
    };

export function getImageMime(uri: string): string {
  if (Platform.OS === 'web') {
    return uri.split(';')[0].split('data:')[1];
  }
  return 'image/jpeg';
}

export async function getImageFile(uri: string): Promise<UploadFile> {
  return new Promise((resolve, reject) => {
    const name = `${Date.now()}.jpg`;

    if (Platform.OS === 'web') {
      fetch(uri)
        .then((response) => response.blob())
        .then((blob) => {
          resolve(new File([blob], name));
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      resolve({
        type: getImageMime(uri),
        name,
        uri,
      });
    }
  });
}
