import React from 'react';
import styled from 'styled-components/native';

const StyledTouchableOpacity = styled.TouchableOpacity`
  margin-top: 40px;
`;

const Text = styled.Text`
  letter-spacing: -0.23px;
  font-size: 15px;
  color: ${({ theme }) => theme.bottomSheet.textButton};
`;

interface Button {
  text?: string;
  onNextPress?: () => void;
}

const Button: React.FC<Button> = ({ text, onNextPress }: Button) => {
  return (
    <StyledTouchableOpacity onPress={onNextPress}>
      <Text>{text}</Text>
    </StyledTouchableOpacity>
  );
};

export default Button;
