import toWav from 'audiobuffer-to-wav';
import { Platform } from 'react-native';

import { execute as FFMPEG_execute } from 'utils/ffmpeg';

const execute = async (command: string) => FFMPEG_execute(command);

const millisecondsToTime = (duration: number) => {
  const milliseconds: number | string = Number(duration % 1000).toFixed(0);

  let seconds: number | string = Math.floor((duration / 1000) % 60),
    minutes: number | string = Math.floor((duration / (1000 * 60)) % 60),
    hours: number | string = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours === '00'
    ? minutes + ':' + seconds + '.' + milliseconds
    : hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
};

export const cutAudioMobile = async (options: {
  from: number;
  to: number;
  inputFilePath: string;
  outputFilePathWithoutExt: string;
  inputFileExt: string;
}): Promise<string> => {
  const _from = millisecondsToTime(options.from);
  const _to = millisecondsToTime(options.to - options.from);
  console.log('from', _from, 'to', _to);
  const cmd = `-y -ss ${_from} -i "${options.inputFilePath}" -t ${_to} -c copy "${
    options.outputFilePathWithoutExt + '.' + options.inputFileExt
  }"`;
  console.log('cutting...');
  await execute(cmd);
  if (Platform.OS === 'android' && options.inputFileExt === 'aac') {
    console.log('converting...');
    const convertCmd = `-y -i "${
      options.outputFilePathWithoutExt + '.' + options.inputFileExt
    }" -vn -ar 44100 -ac 2 -b:a 128k "${options.outputFilePathWithoutExt + '.mp3'}"`;
    await execute(convertCmd);
    return options.outputFilePathWithoutExt + '.mp3';
  }
  return options.outputFilePathWithoutExt + '.' + options.inputFileExt;
};

export const AudioBufferSlice = async (
  arrayBuffer: ArrayBuffer,
  begin: number,
  end: number,
): Promise<Blob> => {
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  const buffer = await audioContext.decodeAudioData(arrayBuffer);
  const channels = buffer.numberOfChannels;
  const rate = buffer.sampleRate;

  begin = begin / 1000;
  end = end / 1000;

  const startOffset = rate * begin;
  const endOffset = rate * end;
  const frameCount = endOffset - startOffset;
  let newArrayBuffer;

  try {
    newArrayBuffer = audioContext.createBuffer(channels, endOffset - startOffset, rate);
    const channelArray = new Float32Array(frameCount);
    const offset = 0;

    for (let channel = 0; channel < channels; channel++) {
      buffer.copyFromChannel(channelArray, channel, startOffset);
      newArrayBuffer.copyToChannel(channelArray, channel, offset);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  const wav = toWav(newArrayBuffer);

  return new Blob([wav]);
};
