import React from 'react';
import { useIntl } from 'react-intl';

import DialogButton from 'components/Button/DialogButton';
import Dialog from 'components/Dialog';

import messages from './messages';
import Description from './styled/Description';
import Text from './styled/Text';

interface Props {
  isVisible: boolean;
  onPress: () => void;
}

const AgeRestrictionDialog: React.FC<Props> = ({ isVisible, onPress }: Props) => {
  const intl = useIntl();
  return (
    <Dialog title={intl.formatMessage(messages.title)} visible={isVisible} customWidth={'80%'}>
      <Description>{intl.formatMessage(messages.description)}</Description>
      <Text>{intl.formatMessage(messages.secondDescription)}</Text>
      <DialogButton onPress={onPress} secondary={true} short={true}>
        {intl.formatMessage(messages.closeDialog)}
      </DialogButton>
    </Dialog>
  );
};

export default AgeRestrictionDialog;
