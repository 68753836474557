import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const SvgElement = (props: SvgProps) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M4.57583 1.58325L4.5 10.3333C4.5 10.6427 4.62292 10.9394 4.84171 11.1582C5.0605 11.377 5.35725 11.4999 5.66667 11.4999H14.4167"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.58337 4.57583L10.3334 4.5C10.6428 4.5 10.9395 4.62292 11.1583 4.84171C11.3771 5.0605 11.5 5.35725 11.5 5.66667V14.4167"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 7px;
  left: 7px;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.button.backgroundOverlayDark};
  border-radius: 6px;
`;

interface CroppButtonProps {
  onPress?: () => void;
}

const CroppButton: React.FC<CroppButtonProps> = ({ onPress }: CroppButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <SvgElement />
    </TouchableOpacity>
  );
};

export default CroppButton;
