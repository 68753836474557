export default {
  title: {
    id: 'register.email.title',
    defaultMessage: 'Create account',
  },
  description: {
    id: 'register.email.description',
    defaultMessage: 'Get access to exclusive content from your favourite artists.',
  },
  emailInput: {
    label: {
      id: 'register.emailInput.label',
      defaultMessage: 'E-mail address',
    },
    placeholder: {
      id: 'register.emailInput.placeholder',
      defaultMessage: 'email@domain.com',
    },
    error: {
      required: {
        id: 'register.emailInput.error.required',
        defaultMessage: 'An email address is required',
      },
      invalid: {
        id: 'register.emailInput.error.invalid',
        defaultMessage: 'Provided e-mail address is invalid',
      },
    },
  },
  passwordInput: {
    label: {
      id: 'register.passwordInput.label',
      defaultMessage: 'Password',
    },
    placeholder: {
      id: 'register.passwordInput.placeholder',
      defaultMessage: 'Password',
    },
    error: {
      invalidLength: {
        id: 'register.passwordInput.error.invalidLength',
        defaultMessage: 'must be at least 8 characters long',
      },
      missingUpperOrLowerCase: {
        id: 'register.passwordInput.error.missingUpperOrLowerCase',
        defaultMessage: 'One upper/lower case letter',
      },
      missingNumber: {
        id: 'register.passwordInput.error.missingNumber',
        defaultMessage: 'One numeric character',
      },
      missingSpecialCharacter: {
        id: 'register.passwordInput.error.missingSpecialCharacter',
        defaultMessage: 'One special character',
      },
    },
  },
  registerCtaLabel: {
    id: 'register.cta.label',
    defaultMessage: 'Sign up',
  },
  disclaimer: {
    id: 'register.disclaimer',
    defaultMessage:
      'By taping Sign up you agree to our general <underlineText><termsCta> Terms of use</termsCta>, <paymentTermsCta>Payment Terms of Service</paymentTermsCta>, <privacyPolicyCta>Privacy Policy</privacyPolicyCta></underlineText> and <nondiscriminationPolicyCta>Nondiscrimination Policy</nondiscriminationPolicyCta>.',
  },
  error: {
    already_exist: {
      id: 'register.error.already_exist',
      defaultMessage:
        'An account with this e-mail address already exists. Please try signing in instead.',
    },
    invalid: {
      id: 'register.error.invalid',
      defaultMessage: 'The e-mail address provided seems to be invalid.',
    },
    password_too_short: {
      id: 'register.error.password_too_short',
      defaultMessage: 'The chosen password is too short.',
    },
    number_required: {
      id: 'register.error.number_required',
      defaultMessage: 'Password must contain at least one number.',
    },
    mixed_case_character_required: {
      id: 'register.error.mixed_case_character_required',
      defaultMessage: 'Password must contain at least one lower- and one upper-case character.',
    },
    non_alphanumeric_character_required: {
      id: 'register.error.mixed_case_character_required',
      defaultMessage: 'Password must contain at least one special character.',
    },
    password_too_common: {
      id: 'register.error.password_too_common',
      defaultMessage: 'Chosen password is too common.',
    },
    not_whitelisted: {
      id: 'register.error.not_whitelisted',
      defaultMessage: 'Invite not found. For now, Stanbase works only for invited Stans.',
    },
    unknown: {
      id: 'register.error.unknown',
      defaultMessage: 'Error creating account. Please try again later.',
    },
  },
  success: {
    alertTitle: {
      id: 'register.success.alert.title',
      defaultMessage: 'Account created 🎉',
    },
    alertMessage: {
      id: 'register.success.alert.message',
      defaultMessage:
        'Before you sign in, you will need to confirm your e-mail address. Please do this by clicking the link in the e-mail you received.',
    },
    alertDismissButtonLabel: {
      id: 'register.success.alert.dismissButtonLabel',
      defaultMessage: 'OK',
    },
    title: {
      id: 'register.success.title',
      defaultMessage: 'Confirm e-mail',
    },
    description: {
      id: 'register.success.description',
      defaultMessage:
        'The link to confirm your e-mail has been sent to <emailAddress></emailAddress>. Check your inbox and confirm registration to continue.',
    },
  },
};
