import styled, { css } from 'styled-components/native';

export default styled.View`
  margin-top: 12px;
  align-self: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 567px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
    `}
`;
