import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as ImagePickerExpo from 'expo-image-picker';
import * as VideoThumbnails from 'expo-video-thumbnails';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Image, Platform, View } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { Options as PickerOptions } from 'react-native-image-crop-picker';
import { useTheme } from 'styled-components/native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_POST_SETTINGS_SCREEN_NAME } from 'screens/ArtistPostSettingsScreen';

import ScreenView from 'containers/ModalScreen';

import ErrorText from 'components/ErrorHandling/ErrorText';
import NavigationHeader from 'components/NavigationHeader';
import TextInput from 'components/TextInput';

import { useImagePicker } from 'hooks/imagePicker';
import { ArtistProduct, Media } from 'types';
import { containsLink } from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import Cropper from './styled/Cropper';
import CroppersView from './styled/CroppersView';
import FeedItem from './styled/FeedItem';
import InnerContainer from './styled/InnerContainer';
import Options from './styled/Options';
import PickerButton from './styled/PickerButton';
import PickerView from './styled/PickerView';
import { ImageRatio } from './types';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const pickerNativeOptions: PickerOptions = {
  cropping: false,
  mediaType: 'any',
  forceJpg: true,
  compressVideoPreset: 'HighestQuality',
};

const pickerWebOptions = {
  mediaTypes: ImagePickerExpo.MediaTypeOptions.All,
  allowsEditing: false,
  quality: 1,
};

const ArtistPostScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const [media, setMedia] = useState<Media>();
  const [editedImageUri, setEditedImageUri] = useState<string>();
  const [videoCover, setVideoCover] = useState<string>();
  const [caption, setCaption] = useState<string>('');
  const [ratioType, setRatioType] = useState<ImageRatio>();
  const [originWidth, setOriginWidth] = useState<number>(0);
  const [originHeight, setOriginHeight] = useState<number>(0);
  const [isCropMode, setCropMode] = useState(false);
  const isFocused = useIsFocused();
  const activateRef = useRef<(() => void) | null>();
  const deactivateRef = useRef<(() => void) | null>();
  const theme = useTheme();

  const { isPickingMedia, launchImagePicker } = useImagePicker({ onMediaChange: setMedia });

  const onPickerPress = useCallback(
    () => launchImagePicker({ pickerNativeOptions, pickerWebOptions }),
    [launchImagePicker],
  );

  const setRatio = (width: number, height: number) => {
    setOriginWidth(width);
    setOriginHeight(height);
    if (width > height) {
      setRatioType('landscape');
    } else if (height > width) {
      setRatioType('portrait');
    } else {
      setRatioType('square');
    }
  };

  const setUriAndSize = (m: Media) => {
    const uri = getMediaUri(m);
    setEditedImageUri(uri);
    Image.getSize(uri, setRatio);
  };

  useEffect(() => {
    if (media) {
      if (getMediaType() === 'video') {
        if (Platform.OS !== 'web') {
          getVideoCover();
        }
        setRatio(media.width || 0, media.height || 0);
      } else {
        if (Platform.OS !== 'web') {
          setUriAndSize(media);
        }
      }
    }
  }, [media]);

  useEffect(() => {
    if (!isFocused) {
      deactivateRef.current?.();
    }
  }, [isFocused]);

  const refActivate = (activateFn: (() => void) | null) => {
    activateRef.current = activateFn;
  };

  const refDeactivate = (deactivateFn: (() => void) | null) => {
    deactivateRef.current = deactivateFn;
  };

  const getMediaUri = (m: { path?: string; uri?: string }) => {
    return m.path || m.uri || '';
  };

  const getMediaType = () => {
    if (Platform.OS === 'web') {
      return media?.uri?.includes('data:image') ? 'image' : 'video';
    }
    return media?.mime?.includes('image') ? 'image' : 'video';
  };

  const getVideoCover = async () => {
    try {
      if (media && Platform.OS !== 'web') {
        const { uri } = await VideoThumbnails.getThumbnailAsync(getMediaUri(media), {
          time: 0,
        });
        setVideoCover(uri);
      }
      return '';
    } catch (e) {
      console.warn(e);
    }
  };

  const onCrop = async (type: ImageRatio) => {
    let width = originWidth;
    let height = originHeight;
    switch (type) {
      case 'square':
        if (width >= height) {
          width = height;
        } else {
          height = width;
        }
        break;
      case 'landscape':
        height = theme.card.portraitRatio * width;
        break;
      case 'portrait':
        width = theme.card.portraitRatio * height;
    }
    if (media) {
      const image = await ImagePicker.openCropper({
        path: getMediaUri(media),
        width: width,
        height: height,
        forceJpg: true,
        mediaType: 'photo',
        cropperToolbarTitle: intl.formatMessage(messages.cropper),
        cropperStatusBarColor: theme.colors.background,
        cropperToolbarColor: theme.colors.background,
        cropperActiveWidgetColor: theme.button.backgroundColorBlue,
        cropperToolbarWidgetColor: theme.colors.text,
      });
      setEditedImageUri(getMediaUri(image));
      setRatioType(type);
    }
  };

  const onHeaderRightPress = useCallback(() => {
    if (isCropMode) {
      setCropMode(false);
    } else {
      navigation.navigate(ARTIST_POST_SETTINGS_SCREEN_NAME, {
        photoVideo: {
          media,
          editedImageUri,
          videoCover,
        },
        caption,
      });
    }
  }, [isCropMode, media, editedImageUri, videoCover, caption]);

  const onHeaderBackPress = useCallback(() => {
    if (isCropMode) {
      if (media) {
        setUriAndSize(media);
      }
      setCropMode(false);
    } else {
      navigation.goBack();
    }
  }, [isCropMode, navigation, media]);

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          title={isCropMode ? '' : intl.formatMessage(messages.header.title)}
          headerBackTitle={intl.formatMessage(messages.header.cancel)}
          headerBackShowIcon={false}
          headerRightLabel={intl.formatMessage(
            isCropMode ? messages.header.save : messages.header.next,
          )}
          headerRightDisabled={!media || containsLink(caption)}
          headerRightOnPress={onHeaderRightPress}
          headerBackOnPress={onHeaderBackPress}
        />
      }
    >
      <Container>
        <InnerContainer>
          {media ? (
            <View>
              {!isCropMode && (
                <Options
                  mediaType={getMediaType()}
                  onRemove={() => {
                    setMedia(undefined);
                    setEditedImageUri(undefined);
                    setVideoCover(undefined);
                  }}
                  onAddMedia={(m: Media) => setMedia(m)}
                  setCropMode={setCropMode}
                />
              )}
              <FeedItem
                showAvatar={false}
                product={
                  {
                    model_name: getMediaType(),
                    file_proxy:
                      getMediaType() === 'video'
                        ? getMediaUri(media)
                        : editedImageUri || getMediaUri(media),
                  } as ArtistProduct
                }
                aspectRatio={ratioType}
                videoOptions={{
                  access: { hasAccess: true },
                  coverImageUrl: videoCover,
                  autoPlay: false,
                  startMuted: false,
                  shouldLoop: false,
                  autoHideControls: false,
                  refActivate: (index: number, activateFn: (() => void) | null) =>
                    refActivate(activateFn),
                  refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
                    refDeactivate(deactivateFn),
                }}
                showCaption={false}
                showControls={false}
                showCommentsBottomCounter={false}
                showControlsBelowContent={false}
              />
            </View>
          ) : (
            <PickerView disabled={isPickingMedia} onPress={onPickerPress}>
              <PickerButton label={intl.formatMessage(messages.picker)} />
            </PickerView>
          )}
          {!isCropMode && (
            <>
              <TextInput
                returnKeyType="done"
                placeholder={intl.formatMessage(messages.caption)}
                value={caption || ''}
                onChangeText={(v: string) => setCaption(v)}
              />
              {caption && containsLink(caption) && (
                <ErrorText>Links are not allowed here</ErrorText>
              )}
            </>
          )}
        </InnerContainer>
        {media && getMediaType() === 'image' && Platform.OS !== 'web' && isCropMode && (
          <CroppersView>
            <Cropper
              onSelect={onCrop}
              type="portrait"
              selected={ratioType === 'portrait'}
              label={intl.formatMessage(messages.croppers.vertical)}
            />
            <Cropper
              onSelect={onCrop}
              type="landscape"
              selected={ratioType === 'landscape'}
              label={intl.formatMessage(messages.croppers.horizontal)}
            />
            <Cropper
              onSelect={onCrop}
              type="square"
              selected={ratioType === 'square'}
              label={intl.formatMessage(messages.croppers.square)}
            />
          </CroppersView>
        )}
      </Container>
    </ScreenView>
  );
};

export default ArtistPostScreen;
