import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import BottomSheet from 'components/BottomSheet';
import Button from 'components/Button';
import ModalOptions from 'components/ModalOptions';

import { Patch, State } from 'context/moreOptions';
import { useSnackbarSet } from 'context/snackbar';
import { useReporting } from 'hooks';
import { ReportReason } from 'types';
import { shareArtist } from 'utils/sharing';

import { VARIANT } from '../../context';
import messages from './messages';
import ChevronRight from './styled/ChevronRight';
import Divider from './styled/Divider';
import ForArtistView from './styled/ForArtistView';
import OptionBackground from './styled/OptionBackground';
import OptionButton from './styled/OptionButton';
import OptionsText from './styled/OptionsText';
import ReportButtonLabel from './styled/ReportButtonLabel';
import ReportButtonSheet from './styled/ReportButtonSheet';
import ReportDescription from './styled/ReportDescription';
import ReportSuccessDescription from './styled/ReportSuccessDescription';
import ReportSuccessTitle from './styled/ReportSuccessTitle';
import ReportSuccessView from './styled/ReportSuccessView';
import ReportView from './styled/ReportView';
import SuccessIcon from './styled/SuccessIcon';
import Title from './styled/Title';
import View from './styled/View';

interface MoreOptionsSheetProps {
  state: State;
  setState: (patch: Patch) => void;
}

const MoreOptionsSheet: React.FC<MoreOptionsSheetProps> = ({
  state,
  setState,
  ...restProps
}: MoreOptionsSheetProps) => {
  const intl = useIntl();
  const [isReported, setIsReported] = useState<boolean>(false);
  const { setSnackbar } = useSnackbarSet();
  const handleReportTimeoutId = useRef<number>();
  const { reportPost, reportArtist, reportComment, blockUser } = useReporting();

  useEffect(() => {
    return () => {
      clearTimeout(handleReportTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      state.itemType === 'comment' ? handleReport() : setIsReported(false);
    }
  }, [state.itemType]);

  const handleClose = () => {
    setState({
      isMoreOptionsOpen: false,
      isReportBottomSheetOpen: false,
      isBlockBottomSheetOpen: false,
    });
    state.onClose?.();
  };

  const handleReportOptionClick = () => {
    setState({
      isMoreOptionsOpen: false,
      isReportBottomSheetOpen: true,
    });
    if (state.itemType === 'comment' && Platform.OS === 'web') {
      handleReport();
    }
  };

  const shareUrl = () => {
    setState({
      isMoreOptionsOpen: false,
      isReportBottomSheetOpen: false,
    });
    if (state.itemType === 'artist' && state.artistUsername) {
      return shareArtist(state.artistUsername);
    }
  };

  const handleReport = async (reason?: ReportReason) => {
    const reasonError = new Error('Reports of type different than comment requires reason');
    setIsReported(true);
    try {
      switch (state.itemType) {
        case 'artist':
          if (state.artistUsername) {
            if (!reason) throw reasonError;
            await reportArtist(state.artistUsername, reason);
          }
          break;
        case 'post':
          if (state.itemId && state.productType && state.artistUsername) {
            if (!reason) throw reasonError;
            await reportPost(
              state.itemId as number,
              state.productType,
              state.artistUsername,
              reason,
            );
          }
          break;
        case 'comment':
          if (state.itemId) {
            await reportComment(state.itemId);
          }
          break;
      }
    } finally {
      handleReportTimeoutId.current = setTimeout(() => {
        handleClose();
        setIsReported(false);
      }, 3000);
    }
  };

  const handleBlock = async () => {
    try {
      handleClose();
      if (state.itemId) await blockUser(state.itemId);
      setSnackbar({
        visible: true,
        label: `${state.blockedUsername} ${intl.formatMessage(messages.block.snackbar)}`,
        actionLabel: intl.formatMessage(messages.closeSnackbar),
      });
    } catch (e) {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.block.error),
        actionLabel: intl.formatMessage(messages.closeSnackbar),
      });
    }
  };

  const renderReportingOptions = () => (
    <ReportView>
      <Title>{intl.formatMessage(messages.title)}</Title>
      <ReportDescription>{intl.formatMessage(messages.description)}</ReportDescription>
      <Divider />
      <ReportButtonSheet onPress={() => handleReport(ReportReason.SPAM)}>
        <ReportButtonLabel>{intl.formatMessage(messages.options.spam)}</ReportButtonLabel>
        <ChevronRight />
      </ReportButtonSheet>
      <ReportButtonSheet onPress={() => handleReport(ReportReason.NOT_APPROPRIATE)}>
        <ReportButtonLabel>
          {intl.formatMessage(messages.options.not_appropriate)}
        </ReportButtonLabel>
        <ChevronRight />
      </ReportButtonSheet>
    </ReportView>
  );

  const renderReportSuccess = () => (
    <ReportSuccessView>
      <SuccessIcon />
      <ReportSuccessTitle>{intl.formatMessage(messages.titleSuccess)}</ReportSuccessTitle>
      <ReportSuccessDescription>{intl.formatMessage(messages.success)}</ReportSuccessDescription>
    </ReportSuccessView>
  );

  const setReportOpen = useCallback(
    (val: boolean) => setState({ isReportBottomSheetOpen: val }),
    [setState],
  );

  const setBlockOpen = useCallback(
    (val: boolean) => setState({ isBlockBottomSheetOpen: val }),
    [setState],
  );

  return (
    <View {...restProps}>
      <ModalOptions
        isVisible={state.isMoreOptionsOpen}
        isDimmerVisible={state.isMoreOptionsOpen || state.isReportBottomSheetOpen}
        onBackgroundPress={handleClose}
      >
        <ForArtistView>
          {state.itemType === 'artist' && (
            <OptionBackground isTop={VARIANT === 'fan'}>
              <OptionButton onPress={shareUrl} isTop={VARIANT === 'fan'}>
                <OptionsText>{intl.formatMessage(messages.share)}</OptionsText>
              </OptionButton>
            </OptionBackground>
          )}
          {Platform.OS === 'web' && state.itemType === 'comment' && (
            <OptionBackground isTop>
              <OptionButton isTop onPress={handleBlock}>
                <OptionsText>{intl.formatMessage(messages.block.title)}</OptionsText>
              </OptionButton>
            </OptionBackground>
          )}
          {state.itemType !== 'post' && VARIANT === 'fan' && <Divider />}
          {VARIANT === 'fan' && (
            <OptionBackground isBottom={state.itemType !== 'post'}>
              <OptionButton onPress={handleReportOptionClick} isBottom={state.itemType !== 'post'}>
                <OptionsText>{intl.formatMessage(messages.title)}</OptionsText>
              </OptionButton>
            </OptionBackground>
          )}
          <OptionBackground>
            <OptionButton onPress={handleClose} solid>
              <OptionsText>{intl.formatMessage(messages.cancel)}</OptionsText>
            </OptionButton>
          </OptionBackground>
        </ForArtistView>
      </ModalOptions>
      <BottomSheet
        isOpen={state.isReportBottomSheetOpen}
        setIsOpen={setReportOpen}
        showDimmer={false}
      >
        {state.itemType === 'comment'
          ? renderReportSuccess()
          : isReported
          ? renderReportSuccess()
          : renderReportingOptions()}
      </BottomSheet>
      <BottomSheet isOpen={state.isBlockBottomSheetOpen} setIsOpen={setBlockOpen}>
        <ReportView>
          <Title>{`${intl.formatMessage(messages.block.title)} ${state.blockedUsername}`}</Title>
          <ReportDescription>{intl.formatMessage(messages.block.description)}</ReportDescription>
          <Button primary onPress={handleBlock}>
            {intl.formatMessage(messages.block.title)}
          </Button>
        </ReportView>
      </BottomSheet>
    </View>
  );
};

export default MoreOptionsSheet;
