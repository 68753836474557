import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const SvgElement = (props: SvgProps) => {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        d="M9.91669 1.75009C10.0699 1.59689 10.2518 1.47535 10.452 1.39244C10.6521 1.30952 10.8667 1.26685 11.0834 1.26685C11.3 1.26685 11.5146 1.30952 11.7147 1.39244C11.9149 1.47535 12.0968 1.59689 12.25 1.75009C12.4032 1.9033 12.5248 2.08519 12.6077 2.28537C12.6906 2.48554 12.7333 2.70009 12.7333 2.91676C12.7333 3.13343 12.6906 3.34798 12.6077 3.54816C12.5248 3.74833 12.4032 3.93022 12.25 4.08343L4.37502 11.9584L1.16669 12.8334L2.04169 9.62509L9.91669 1.75009Z"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 7px;
  right: 7px;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.button.backgroundOverlayDark};
  border-radius: 6px;
`;

interface EditButtonProps {
  onPress?: () => void;
}

const EditButton: React.FC<EditButtonProps> = ({ onPress }: EditButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <SvgElement />
    </TouchableOpacity>
  );
};

export default EditButton;
