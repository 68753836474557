import { ThemedStyledProps } from 'styled-components';
import styled from 'styled-components/native';

interface ScrollViewProps extends ThemedStyledProps<any, any> {
  isWeb?: boolean;
}

export default styled.ScrollView.attrs((props: ScrollViewProps) => ({
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: props.isWeb ? 0 : '8%',
  },
}))<ScrollViewProps>`
  flex: 1;
  background-color: ${(props) => props.theme.colors.background};
`;
