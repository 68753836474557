import styled from 'styled-components/native';

import OverlayButton from 'components/OverlayButton';

export default styled(OverlayButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
`;
