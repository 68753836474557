import { memo } from 'react';
import styled from 'styled-components/native';

import { VARIANT } from 'context';

export default memo(styled.View`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  background-color: ${VARIANT === 'fan' ? '#0537ff' : '#121212'};
`);
