import React, { useCallback, useEffect, useState } from 'react';
import { GestureResponderEvent, LayoutChangeEvent } from 'react-native';

import Bar from './styled/Bar';
import BarBackgroundWrapper from './styled/BarBackgroundWrapper';
import BarForegroundWrapper from './styled/BarForegroundWrapper';
import BarsWrapperView from './styled/BarsWrapperView';
import ColumnView from './styled/ColumnView';
import ContainerView from './styled/ContainerView';
import MaskView from './styled/MaskView';
import Touchable from './styled/Touchable';
import RootView from './styled/View';

export interface WaveformProps {
  data?: number[];
  progress?: number;
  barWidth?: number;
  barGap?: number;
  barColor?: string;
  minBarHeight?: number;
  onSeek?: (progress: number) => void;
  renderControl?: () => JSX.Element;
}

const Waveform: React.FC<WaveformProps> = ({
  data,
  progress = 0,
  barWidth = 2,
  barGap = 9,
  barColor,
  minBarHeight = 0.05,
  onSeek,
  renderControl,
  ...restProps
}: WaveformProps) => {
  const [columnWidth, setColumnWidth] = useState<number>(100);
  const [[leftColumnBars, rightColumnBars], setColumnsBars] = useState<number[][]>([[], []]);
  const totalBarsLength = leftColumnBars?.length + rightColumnBars?.length;

  const generateRandomData = (numSamples: number) => {
    return [...new Array(numSamples)].map((_, index) =>
      Math.max(Math.abs(Math.sin((index * 10) / numSamples) * Math.random()), minBarHeight),
    );
  };

  const sample = (data: number[], progress: number) => {
    return data[Math.round(progress * (data.length - 1))];
  };

  const setWaveform = () => {
    const numBars = Math.ceil((2 * columnWidth) / (barWidth + barGap));
    const waveformData = data || generateRandomData(numBars);
    const heights = [...new Array(numBars)].map((_, index) =>
      sample(waveformData, index / numBars),
    );
    const halfIndex = Math.ceil(heights.length / 2);
    setColumnsBars([heights.splice(0, halfIndex), heights.splice(-halfIndex)]);
  };

  const getProgress = useCallback(
    (barIndex: number) => barIndex / totalBarsLength,
    [totalBarsLength],
  );

  const onLayout = (evt: LayoutChangeEvent) => {
    setColumnWidth(Math.max(evt.nativeEvent.layout.width, 1));
  };

  const onBarLeftPress = useCallback(
    (evt: GestureResponderEvent) => {
      const offset = evt.nativeEvent.locationX || (evt.nativeEvent as any as MouseEvent).offsetX;
      const currentProgress = offset / (2 * columnWidth);

      onSeek?.(currentProgress);
    },
    [columnWidth],
  );

  const onBarRightPress = useCallback(
    (evt: GestureResponderEvent) => {
      const offset = evt.nativeEvent.locationX || (evt.nativeEvent as any as MouseEvent).offsetX;
      const currentProgress = offset / (2 * columnWidth) + 0.5;

      onSeek?.(currentProgress);
    },
    [columnWidth],
  );

  useEffect(() => {
    setWaveform();
  }, [columnWidth, data]);

  return (
    <RootView {...restProps}>
      <ContainerView>
        <Touchable onPress={onBarLeftPress}>
          <ColumnView onLayout={onLayout} pointerEvents="box-only">
            <BarsWrapperView>
              {progress < 1 && (
                <BarBackgroundWrapper>
                  {leftColumnBars?.map((height: number, index: number) => (
                    <Bar
                      barColor={barColor}
                      key={index}
                      width={barWidth}
                      height={height}
                      isActive={false}
                      progress={getProgress(index)}
                    />
                  ))}
                </BarBackgroundWrapper>
              )}
              <MaskView width={Math.max(Math.round(progress * 2 * columnWidth), 1)}>
                <BarForegroundWrapper width={columnWidth}>
                  {leftColumnBars?.map((height: number, index: number) => (
                    <Bar
                      key={index}
                      width={barWidth}
                      height={height}
                      isActive={true}
                      progress={getProgress(index)}
                    />
                  ))}
                </BarForegroundWrapper>
              </MaskView>
            </BarsWrapperView>
          </ColumnView>
        </Touchable>

        {/* <ControlView>{renderControl?.()}</ControlView> */}

        <Touchable onPress={onBarRightPress}>
          <ColumnView pointerEvents="box-only">
            <BarsWrapperView>
              <BarBackgroundWrapper>
                {rightColumnBars?.map((height: number, index: number) => (
                  <Bar
                    barColor={barColor}
                    key={index}
                    width={barWidth}
                    height={height}
                    isActive={false}
                    progress={getProgress(index)}
                  />
                ))}
              </BarBackgroundWrapper>
              <MaskView
                width={
                  progress > 0.5 ? Math.max(Math.round((progress - 0.5) * 2 * columnWidth), 1) : 0
                }
              >
                <BarForegroundWrapper width={columnWidth}>
                  {rightColumnBars?.map((height: number, index: number) => (
                    <Bar
                      key={index}
                      width={barWidth}
                      height={height}
                      isActive={true}
                      progress={getProgress(index)}
                    />
                  ))}
                </BarForegroundWrapper>
              </MaskView>
            </BarsWrapperView>
          </ColumnView>
        </Touchable>
      </ContainerView>
    </RootView>
  );
};

export default Waveform;
