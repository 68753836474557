import React from 'react';

import Fade from 'components/Transitions/Fade/Fade';

import ActivityIndicator from './styled/ActivityIndicator';
import CenterView from './styled/CenterView';
import Dimmer from './styled/Dimmer';
import View from './styled/View';

export interface ModalActivityIndicatorProps {
  isVisible: boolean;
}

const ModalActivityIndicator: React.FC<ModalActivityIndicatorProps> = ({
  isVisible,
  ...restProps
}: ModalActivityIndicatorProps) => {
  return (
    <View {...restProps} pointerEvents="box-none">
      {isVisible && (
        <Fade>
          <Dimmer pointerEvents="box-only" />
        </Fade>
      )}
      <CenterView pointerEvents="none">
        <ActivityIndicator animating={isVisible} />
      </CenterView>
    </View>
  );
};

export default ModalActivityIndicator;
