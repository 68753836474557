import React, { createContext } from 'react';

import { useLocalStorage } from 'hooks/localStorage';

interface AgeRestrictions {
  feed: boolean;
  singlePost: boolean;
}

interface State {
  loading: boolean;
  isVisible: AgeRestrictions;
  setAgeRestrictionStorage: (value: AgeRestrictions) => Promise<AgeRestrictions | void>;
  deleteAgeRestrictionStorage: any;
}

interface Props {
  children: React.ReactNode;
}

const defaultContext: State = {
  loading: true,
  isVisible: { feed: true, singlePost: true },
  setAgeRestrictionStorage: (value) => Promise.resolve(value),
  deleteAgeRestrictionStorage: () => null,
};

export const AgeRestrictionContext = createContext(defaultContext);

export const AgeRestrictionContextProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    value: ageRestrictionStorage,
    setValueWrapper: setAgeRestrictionStorage,
    deleteValue: deleteAgeRestrictionStorage,
    loading,
  } = useLocalStorage('isAgeRestrictionClosed', defaultContext.isVisible);

  return (
    <AgeRestrictionContext.Provider
      value={{
        loading: loading,
        isVisible: ageRestrictionStorage,
        setAgeRestrictionStorage,
        deleteAgeRestrictionStorage,
      }}
    >
      {children}
    </AgeRestrictionContext.Provider>
  );
};
