import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack';
import React from 'react';

import ArtistProfileStack, {
  SCREEN_NAME as ARTIST_PROFILE_STACK_SCREEN_NAME,
} from 'stacks/artist/ArtistProfileStack';

import ArtistChangeSubscriptionPriceScreen, {
  SCREEN_NAME as ARTIST_CHANGE_SUBSCRIPTION_PRICE_SCREEN_NAME,
} from 'screens/ArtistChangeSubscriptionPriceScreen';
import ArtistContactAndDetailsScreen, {
  SCREEN_NAME as ARTIST_CONTACT_AND_DETAILS_SCREEN_NAME,
} from 'screens/ArtistContactAndDetailsScreen';
import NotificationsSettingsScreen, {
  SCREEN_NAME as NOTIFICATIONS_SETTINGS_SCREEN_NAME,
} from 'screens/NotificationsSettingsScreen';
import ProfileDashboardScreen, {
  SCREEN_NAME as PROFILE_DASHBOARD_SCREEN_NAME,
} from 'screens/ProfileDashboardScreen';
import ProfileSettingsScreen, {
  SCREEN_NAME as PROFILE_SETTINGS_SCREEN_NAME,
} from 'screens/ProfileSettingsScreen';

import NavigationHeader from 'components/NavigationHeader';

export { SCREEN_NAME } from './constants';

export interface Props {}

export const linking: PathConfigMap = {
  [PROFILE_DASHBOARD_SCREEN_NAME]: '/',
  [NOTIFICATIONS_SETTINGS_SCREEN_NAME]: '/notifications',
  [PROFILE_SETTINGS_SCREEN_NAME]: '/profile',
  [ARTIST_PROFILE_STACK_SCREEN_NAME]: '/my-artist',
  [ARTIST_CONTACT_AND_DETAILS_SCREEN_NAME]: '/contact-and-details',
};

const SettingsStack = createStackNavigator();

const ArtistSettingsStack: React.FC<Props> = () => {
  return (
    <SettingsStack.Navigator
      initialRouteName={PROFILE_DASHBOARD_SCREEN_NAME}
      headerMode="screen"
      screenOptions={{
        title: 'Settings',
        header: function ArtistSettingsStackHeader(props: StackHeaderProps) {
          return <NavigationHeader {...props} />;
        },
      }}
    >
      <SettingsStack.Screen
        name={PROFILE_DASHBOARD_SCREEN_NAME}
        component={ProfileDashboardScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
      <SettingsStack.Screen
        name={NOTIFICATIONS_SETTINGS_SCREEN_NAME}
        component={NotificationsSettingsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: 'Notifications',
        }}
      />
      <SettingsStack.Screen
        name={ARTIST_CONTACT_AND_DETAILS_SCREEN_NAME}
        component={ArtistContactAndDetailsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: 'Contact Details & Payments',
        }}
      />
      <SettingsStack.Screen
        name={PROFILE_SETTINGS_SCREEN_NAME}
        component={ProfileSettingsScreen}
        options={{
          headerShown: true,
          animationEnabled: true,
          title: 'Profile settings',
        }}
      />
      <SettingsStack.Screen
        name={ARTIST_CHANGE_SUBSCRIPTION_PRICE_SCREEN_NAME}
        component={ArtistChangeSubscriptionPriceScreen}
        options={{ headerShown: true, animationEnabled: true, title: 'Subscription Price' }}
      />
      <SettingsStack.Screen
        name={ARTIST_PROFILE_STACK_SCREEN_NAME}
        component={ArtistProfileStack}
        options={{ headerShown: false, animationEnabled: true }}
      />
    </SettingsStack.Navigator>
  );
};

export default ArtistSettingsStack;
