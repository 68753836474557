import { useState } from 'react';
import { Alert } from 'react-native';

import { VARIANT } from 'context/appInfo';
import { useBackEnd } from 'context/backEnd';
import { Artist, UserProfile } from 'types';
import { UploadFile } from 'utils/imageUpload';

import { useAuthSwr } from './swr';

export const USER_KEY = `/auth/${VARIANT === 'artist' ? 'artist' : 'user'}`;

export type UserGeneralInformation = {
  username?: string;
  fullName?: string;
  bio?: string;
  secondary_link?: string;
  profilePicture?: UploadFile;
  cover?: UploadFile;
  updateFinalCallback?: () => void;
  state_age_restriction_single_post_is_seen?: boolean;
  state_age_restriction_feed_is_seen?: boolean;
};

export type User = {
  isSignedIn: boolean;
  isLoading: boolean;
  isUpdatingPicture: boolean;
  isUpdatingGeneral: boolean;
  isUpdatingPassword: boolean;
  isUpdatingEmail: boolean;
  profile?: UserProfile | Artist;
  error: any;
  updateInformationError?: string;
  updatePasswordError?: string;
  updateEmailError?: string;
  updatePassword: (oldPassword: string, newPassword: string, onSuccess?: () => void) => void;
  updateEmail: (email: string, password: string, onSuccess?: () => void) => void;
  updateGeneralInformation: (generalInformation: UserGeneralInformation) => void;
};

export function useUser(): User {
  const {
    data: profile,
    error,
    isLoading,
    mutate,
  } = useAuthSwr<UserProfile>({
    key: USER_KEY,
    isPublic: false,
    cache: false,
    swrOptions: { refreshInterval: 0, shouldRetryOnError: false },
  });
  const { axiosInstance } = useBackEnd();
  const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false);
  const [isUpdatingEmail, setIsUpdatingEmail] = useState<boolean>(false);
  const [isUpdatingGeneral, setIsUpdatingGeneral] = useState<boolean>(false);
  const [isUpdatingPicture, setIsUpdatingPicture] = useState<boolean>(false);
  const [updatePasswordError, setUpdatePasswordError] = useState<string>('');
  const [updateEmailError, setUpdateEmailError] = useState<string | undefined>();
  const [updateInformationError, setUpdateInformationError] = useState<string>('');

  const updatePassword = (oldPassword: string, newPassword: string, onSuccess?: () => void) => {
    setIsUpdatingPassword(true);
    setUpdatePasswordError('');
    return axiosInstance
      .post('/auth/change_password', {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: newPassword,
      })
      .then(() => {
        onSuccess?.();
        setIsUpdatingPassword(false);
      })
      .catch(({ response }) => {
        setUpdatePasswordError(response?.data?.detail);
        setIsUpdatingPassword(false);
      });
  };

  const updateEmail = (email: string, password: string, onSuccess?: () => void) => {
    setIsUpdatingEmail(true);
    setUpdateEmailError(undefined);
    return axiosInstance
      .post('/auth/change_email', {
        email,
        password,
      })
      .then(() => {
        onSuccess?.();
        setIsUpdatingEmail(false);
      })
      .catch(({ response }) => {
        setUpdateEmailError(response?.data?.detail);
        setIsUpdatingEmail(false);
      });
  };

  const updateGeneralInformation = ({
    username,
    fullName,
    profilePicture,
    cover,
    bio,
    secondary_link,
    updateFinalCallback,
    state_age_restriction_feed_is_seen,
    state_age_restriction_single_post_is_seen,
  }: UserGeneralInformation) => {
    const updateData = new FormData();
    const updateLoadingStateFunc =
      profilePicture || cover ? setIsUpdatingPicture : setIsUpdatingGeneral;

    updateLoadingStateFunc(true);
    setUpdateInformationError('');

    if (username) {
      updateData.append('username', username);
    }

    if (fullName) {
      updateData.append(VARIANT === 'artist' ? 'display_name' : 'full_name', fullName);
    }

    if (profilePicture) {
      updateData.append('profile_picture', profilePicture as any);
    }

    if (cover) {
      updateData.append('cover_image', cover as any);
    }

    if (bio) {
      updateData.append('bio', bio);
    }

    if (secondary_link) {
      updateData.append('secondary_link', secondary_link);
    }

    if (state_age_restriction_feed_is_seen) {
      updateData.append(
        'state_age_restriction_feed_is_seen',
        state_age_restriction_feed_is_seen as any,
      );
    }

    if (state_age_restriction_single_post_is_seen) {
      updateData.append(
        'state_age_restriction_single_post_is_seen',
        state_age_restriction_single_post_is_seen as any,
      );
    }

    axiosInstance
      .patch(USER_KEY, updateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        updateLoadingStateFunc(false);
        updateFinalCallback?.();
        console.log('updated');
        mutate();
      })
      .catch(({ response }) => {
        if (response.data.username) {
          Alert.alert(
            'Error',
            response.data.username[0].detail.charAt(0).toUpperCase() +
              response.data.username[0].detail.slice(1),
            [{ text: 'OK' }],
          );
        }
        updateLoadingStateFunc(false);
        setUpdateInformationError(response?.data?.detail);
      });
  };

  return {
    isSignedIn: !!profile && !error,
    profile: profile
      ? {
          ...profile,
          first_name: profile.first_name || profile.full_name?.split(' ')[0] || '',
          last_name: profile.last_name || profile.full_name?.split(' ')[0] || '',
        }
      : undefined,
    isLoading,
    isUpdatingPassword,
    isUpdatingGeneral,
    isUpdatingPicture,
    error,
    updateInformationError,
    updatePasswordError,
    updateGeneralInformation,
    updatePassword,
    updateEmail,
    isUpdatingEmail,
    updateEmailError,
  };
}
