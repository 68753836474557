import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const SearchIcon: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <G opacity="0.87">
        <Path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21 21L16.65 16.65"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export default styled(SearchIcon)`
  position: absolute;
  right: 16px;
  top: 41px;
`;
