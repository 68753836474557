import { Audio, AVPlaybackStatus } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

import Button from './styled/Button';
import Mic from './styled/Mic';
import Play from './styled/Play';
import Stop from './styled/Stop';

interface Props {
  recStatus?: Audio.RecordingStatus | undefined;
  pickedFile?: DocumentPicker.DocumentResult;
  setRecStatus: React.Dispatch<SetStateAction<Audio.RecordingStatus | undefined>>;
  onRecordingStatusUpdate: () => void;
  onRecordingStarted: () => void;
  onRecordingStopped: (blob: Blob) => void;
  playbackStatus: AVPlaybackStatus | undefined;
  onPlayPausePress: () => void;
}

const WebRecorder: React.FC<Props> = ({
  recStatus,
  pickedFile,
  setRecStatus,
  playbackStatus,
  onPlayPausePress,
  onRecordingStatusUpdate,
  onRecordingStarted,
  onRecordingStopped,
}: Props) => {
  const [mediaRecorder, setMediaRecorder] = useState();
  const [chunks, setChunks] = useState<Blob[]>([]);
  const timeslice = 100;

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(function (stream) {
          const mr = new MediaRecorder(stream);
          mr.ondataavailable = ({ data }: { data: Blob }) => {
            onRecordingStatusUpdate?.();
            setChunks((prevState) => {
              return [...prevState, data];
            });
            setRecStatus((prevState: Audio.RecordingStatus | undefined) => {
              return {
                canRecord: prevState?.canRecord || true,
                isDoneRecording: prevState?.isDoneRecording || false,
                isRecording: prevState?.isRecording || false,
                durationMillis: (prevState?.durationMillis || 0) + timeslice,
              };
            });
          };
          setMediaRecorder(mr);
        })
        .catch(function (err) {
          alert('The following error occurred: ' + err);
        });
    } else {
      alert('Recording audio is not supported on your browser.');
    }
  }, []);

  const onStopRecording = useCallback(
    (e) => {
      const blob = new Blob(chunks, { type: e.target.mimeType });
      setChunks([]);
      onRecordingStopped(blob);
    },
    [chunks],
  );

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.onstop = onStopRecording;
    }
  }, [onStopRecording]);

  const startRecording = function () {
    mediaRecorder?.start(timeslice);
    setRecStatus({
      canRecord: true,
      isDoneRecording: false,
      isRecording: true,
      durationMillis: 0,
    });
    onRecordingStarted?.();
    console.log('recorder started');
  };

  const stopRecording = function () {
    if (mediaRecorder?.state !== 'inactive') {
      mediaRecorder?.stop();
      setRecStatus((prevState: Audio.RecordingStatus | undefined) => {
        return {
          canRecord: true,
          isDoneRecording: true,
          isRecording: false,
          durationMillis: prevState?.durationMillis || 0,
        };
      });
      console.log('recorder stopped');
    }
  };

  const onActionPress = () => {
    if (pickedFile) {
      onPlayPausePress();
    } else if (recStatus?.isRecording) {
      stopRecording();
    } else if ((playbackStatus as any)?.isPlaying) {
      onPlayPausePress();
    } else if (recStatus?.isDoneRecording) {
      onPlayPausePress();
    } else {
      startRecording();
    }
  };

  return mediaRecorder ? (
    <Button recording={!!recStatus?.isRecording && !pickedFile} onPress={onActionPress}>
      {recStatus?.isRecording || (playbackStatus as any)?.isPlaying ? (
        <Stop />
      ) : recStatus?.isDoneRecording || !!pickedFile ? (
        <Play />
      ) : (
        <Mic />
      )}
    </Button>
  ) : null;
};

export default WebRecorder;
