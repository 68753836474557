import { FC } from 'react';
import { useIntl } from 'react-intl';
import { FadeIn, FadeOut, SlideInDown, SlideOutDown } from 'react-native-reanimated';

import PressableBackground from 'components/BottomSheet/styled/PressableBackground';
import { ButtonProps } from 'components/Button';

import messages from './messages';
import ButtonsView from './styled/ButtonsView';
import Dimmer from './styled/Dimmer';
import OptionButton from './styled/OptionButton';
import View from './styled/View';

export interface ModalOption {
  label: string;
  value: any;
  buttonOptions?: ButtonProps;
}

interface ModalOptionsProps {
  options?: ModalOption[];
  dismissOption?: ModalOption;
  isVisible?: boolean;
  isDimmerVisible?: boolean;
  children?: any;
  onOptionPress?: (option: ModalOption) => void;
  onDismiss?: (option: ModalOption) => void;
  onBackgroundPress?: () => void;
}

const ModalOptions: FC<ModalOptionsProps> = ({
  isVisible,
  options,
  dismissOption: dismissOptionProp,
  onOptionPress,
  onDismiss,
  children,
  isDimmerVisible = isVisible,
  onBackgroundPress,
  ...restProps
}) => {
  const intl = useIntl();

  const dismissOption = dismissOptionProp || {
    label: intl.formatMessage(messages.dismissLabel),
    value: false,
  };

  return (
    <View {...restProps} pointerEvents={isVisible ? 'auto' : 'none'}>
      {isDimmerVisible && (
        <Dimmer
          entering={FadeIn.springify().stiffness(100).mass(0.4).damping(13)}
          exiting={FadeOut.springify().stiffness(100).mass(0.4).damping(13)}
        />
      )}
      {onBackgroundPress && <PressableBackground onPress={onBackgroundPress} />}
      {isVisible && (
        <ButtonsView
          pointerEvents="box-none"
          entering={SlideInDown.springify().stiffness(100).mass(0.4).damping(13)}
          exiting={SlideOutDown.springify().stiffness(100).mass(0.4).damping(13)}
        >
          {options &&
            options.map((option, index) => (
              <OptionButton
                key={index}
                {...option.buttonOptions}
                onPress={() => onOptionPress?.(option)}
              >
                {option.label}
              </OptionButton>
            ))}
          {children}
          {options && (
            <OptionButton
              {...dismissOption.buttonOptions}
              onPress={() => onDismiss?.(dismissOption)}
            >
              {dismissOption.label}
            </OptionButton>
          )}
        </ButtonsView>
      )}
    </View>
  );
};

export default ModalOptions;
