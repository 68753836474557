import styled, { css } from 'styled-components/native';

export default styled.View`
  position: absolute;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      justify-content: center;
    `}
`;
