import React, { useState } from 'react';
import { PressableProps } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';

interface OptionsButtonProps {
  isVisible?: boolean;
  isHovered?: boolean;
  isOwnComment?: boolean;
}

const StyledSvg = styled(Svg)`
  fill: ${({ theme }) => theme.colors.text};
  fill-opacity: 0.52;
`;

const StyledPath = styled(Path)`
  fill: ${({ theme }) => theme.colors.text};
  fill-opacity: 0.52;
`;

const Wrapper = styled.Pressable<OptionsButtonProps>`
  margin-left: 7px;
  margin-bottom: -2px;
  transition: opacity 300ms ease;
  opacity: ${({ isVisible, isHovered }) => (isVisible ? (isHovered ? 0.5 : 1) : 0)};
`;

export default function OptionsButton({
  isVisible,
  isOwnComment,
  ...rest
}: OptionsButtonProps & PressableProps) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Wrapper
      isHovered={isHovered}
      isVisible={isVisible}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      {isOwnComment ? (
        <Svg width="17px" height="17px" viewBox="0 0 24 24" fill="none">
          <StyledPath d="M17.23 9.78L15.01 12L17.23 14.22C17.52 14.51 17.52 14.99 17.23 15.28C17.08 15.43 16.89 15.5 16.7 15.5C16.51 15.5 16.32 15.43 16.17 15.28L13.95 13.06L11.73 15.28C11.58 15.43 11.39 15.5 11.2 15.5C11.01 15.5 10.82 15.43 10.67 15.28C10.38 14.99 10.38 14.51 10.67 14.22L12.89 12L10.67 9.78C10.38 9.49 10.38 9.01 10.67 8.72C10.96 8.43 11.44 8.43 11.73 8.72L13.95 10.94L16.17 8.72C16.46 8.43 16.94 8.43 17.23 8.72C17.52 9.01 17.52 9.49 17.23 9.78ZM21.32 7V17C21.32 17.96 20.54 18.75 19.57 18.75H7.64C7.02999 18.75 6.48 18.44 6.16 17.93L2.87 12.66C2.62 12.26 2.62 11.74 2.87 11.33L6.16 6.07C6.48 5.56 7.04 5.25 7.64 5.25H19.58C20.54 5.25 21.33 6.04 21.33 7H21.32ZM19.82 7C19.82 6.86 19.71 6.75 19.57 6.75H7.64C7.54999 6.75 7.47 6.79 7.43 6.87L4.22 12L7.43 17.13C7.48 17.2 7.56 17.25 7.64 17.25H19.58C19.72 17.25 19.83 17.14 19.83 17V7H19.82Z" />
        </Svg>
      ) : (
        <StyledSvg height="17px" width="17px" viewBox="0 0 32.055 32.055">
          <Path
            d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
		C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
		s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
		c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"
          />
        </StyledSvg>
      )}
    </Wrapper>
  );
}
