import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  max-width: 350px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
  transition: transform 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 50px 15px;
`;
