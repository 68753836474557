import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function CardSvg(props: SvgProps) {
  return (
    <Svg width="212" height="267" viewBox="0 0 212 267" fill="none" {...props}>
      <G>
        <Path
          d="M34 229H178C183.523 229 188 224.523 188 219V51.0625V42.3827L171 25.8575L155 10H34C28.4772 10 24 14.4772 24 20V219C24 224.523 28.4772 229 34 229Z"
          fill="#0537FF"
        />
      </G>
      <Path
        d="M155 10L171 26L188 42H161C157.686 42 155 39.3137 155 36V10Z"
        fill="#121212"
        fillOpacity="0.12"
      />
    </Svg>
  );
}

export default styled(CardSvg)`
  width: 212px;
  height: 267px;
`;
