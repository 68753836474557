import styled from 'styled-components/native';

export default styled.Text`
  font-size: 15px;
  letter-spacing: -0.23px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryActive};
  margin-bottom: 1.8%;
  text-align: center;
  width: 100%;
`;
