import styled, { css } from 'styled-components/native';

export default styled.Text`
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  margin: 0 15px 0 0;
  opacity: 1;
  width: 200px;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 368px;
    `}
`;
