import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
`;

interface Props {
  children: React.ReactNode;
  overlayPress?: () => void;
  isOpen?: boolean;
}

const Overlay: React.FC<Props> = ({ children, overlayPress, isOpen }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const onCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (ref.current) {
        const target = e.target as HTMLDivElement;
        if (target.className === ref.current.className) {
          overlayPress?.();
        }
      }
    },
    [ref.current],
  );

  if (!isOpen) return <></>;
  return (
    <StyledOverlay onClick={onCloseModal} ref={ref}>
      {children}
    </StyledOverlay>
  );
};

export default Overlay;
