import { Feather } from '@expo/vector-icons';
import React from 'react';
import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

interface IconProps {
  color: string;
}

export default styled((props: any) => <Feather name="image" {...props} />).attrs({
  size: Math.floor(Dimensions.get('screen').width * 0.15),
})<IconProps>`
  color: ${({ theme }) => theme.colors.primaryGhost};
  position: absolute;
`;
