export default {
  title: {
    id: 'forgotPassword.title',
    defaultMessage: 'Reset password',
  },
  emailInput: {
    label: {
      id: 'forgotPassword.emailInput.label',
      defaultMessage: 'E-mail address',
    },
    placeholder: {
      id: 'forgotPassword.emailInput.placeholder',
      defaultMessage: 'email@domain.com',
    },
    error: {
      required: {
        id: 'forgotPassword.emailInput.error.required',
        defaultMessage: 'An email address is required',
      },
      invalid: {
        id: 'forgotPassword.emailInput.error.invalid',
        defaultMessage: 'Provided e-mail address is invalid',
      },
    },
  },
  success: {
    id: 'forgotPassword.success',
    defaultMessage:
      'The link to reset your password has been sent to <emailAddress></emailAddress>',
  },
  error: {
    forgot_password_failed: {
      id: 'forgotPassword.error.failed',
      defaultMessage: 'Error has occurred.',
    },
  },
};
