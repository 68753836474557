import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-family: 'BasierCircle-Medium';
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  margin-top: 13px;
`;
