import { Entypo, MaterialIcons, Octicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';

import { SCREEN_NAME as FAN_MARKETPLACE_STACK_NAME } from 'stacks/fan/FanMarketplaceStack/constants';
import { SCREEN_NAMES } from 'stacks/fan/FanMarketplaceStack/types';

import { SCREEN_NAME as HOME_SCREEN } from 'screens/HomeScreen';
import { SCREEN_NAME as SAVED_POSTS_SCREEN_NAME } from 'screens/SavedPostsScreen';
import { SCREEN_NAME as SEARCH_AND_DISCOVERY_SCREEN_NAME } from 'screens/SearchAndDiscoveryScreen/constants';
import { SCREEN_NAME as SETTINGS_SCREEN_NAME } from 'screens/SettingsScreen/constants';
import { SCREEN_NAME as WELCOME_SCREEN } from 'screens/WelcomeScreen';

import Notifications from 'containers/Notifications';

import IconMarketplace from 'components/Icons/IconMarketplace';
import LogoImage from 'components/LogoImage';
import NavigationHeader from 'components/NavigationHeader';
import { NavigationHeaderProps } from 'components/NavigationHeader/types';

import { useNotificationsContext } from 'context/notifications';
import { useAuth, useScreenSize, useUser } from 'hooks';
import { Bookmark } from 'svg';
import { getFingerprint } from 'utils/fingerprint';

import messages from './messages';
import AvatarButton from './styled/AvatarButton';
import BackButton from './styled/BackButton';
import BackIcon from './styled/BackIcon';
import DropdownWrapper from './styled/DropdownWrapper';
import FeedIcon from './styled/FeedIcon';
import LeftContainer from './styled/LeftContainer';
import Logo from './styled/Logo';
import Menu from './styled/Menu';
import MenuItem from './styled/MenuItem';
import NotificationsButton from './styled/NotificationsButton';
import Row from './styled/Row';
import SearchButton from './styled/SearchButton';
import SettingsButton from './styled/SettingsButton';

type Props = Omit<NavigationHeaderProps, 'insets'> & {
  showBackButton?: boolean;
  customBackAction?: () => void;
  hideSearchBar?: boolean;
  hideArtistInput?: boolean;
  hideMenuItems?: boolean;
};

const isMarketplaceRoute = (route: string) => SCREEN_NAMES.includes(route);
const NavigationHeaderFan: React.FC<Props> = ({
  showBackButton = true,
  customBackAction,
  hideSearchBar,
  hideArtistInput,
  hideMenuItems,
  ...props
}: Props) => {
  const { logOut } = useAuth();
  const { isSignedIn, profile } = useUser();
  const intl = useIntl();
  const media = useScreenSize();
  const navigation = useNavigation();
  const { name: routeName } = useRoute();
  const notifications = useNotificationsContext();
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isSettingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [isArtistProfilesPopupOpen, setIsArtistsProfilesPopupOpen] = useState(false);

  const windowHeight = Dimensions.get('window').height;

  const onArtistProfilesPress = useCallback(() => {
    setIsArtistsProfilesPopupOpen(!isArtistProfilesPopupOpen);
    setSettingsDropdownOpen(false);
    setNotificationModalOpen(false);
  }, [isArtistProfilesPopupOpen]);

  const onLogoPress = () => {
    navigation.navigate(isSignedIn ? HOME_SCREEN : WELCOME_SCREEN);
  };

  const navigateToSearchPage = () => {
    navigation.navigate(SEARCH_AND_DISCOVERY_SCREEN_NAME);
  };

  const renderLogo = () => {
    return (
      <LeftContainer>
        <Logo onPress={onLogoPress}>
          <LogoImage />
        </Logo>
        {((showBackButton && navigation?.canGoBack()) || customBackAction) && (
          <BackButton icon={BackIcon} onPress={() => navigation?.goBack()} />
        )}
      </LeftContainer>
    );
  };

  const renderMenuItems = useCallback(() => {
    return (
      !hideMenuItems && (
        <Menu>
          <MenuItem
            text="Feed"
            icon={<FeedIcon isActive={routeName === HOME_SCREEN} />}
            isActive={routeName === HOME_SCREEN}
            onPress={() => navigation.navigate(HOME_SCREEN)}
          />
          <MenuItem
            text="Marketplace"
            icon={<IconMarketplace size="18" focused={isMarketplaceRoute(routeName)} />}
            isActive={isMarketplaceRoute(routeName)}
            onPress={() => navigation.navigate(FAN_MARKETPLACE_STACK_NAME)}
          />
        </Menu>
      )
    );
  }, [routeName, navigation]);

  const renderLogoutButtons = () => {
    return (
      isSignedIn && (
        <>
          <Row>
            {!media.tablet && <SearchButton onClick={navigateToSearchPage} />}
            <NotificationsButton
              hasNotifications={notifications.general}
              focused
              onPress={() => {
                setSettingsDropdownOpen(false);
                setIsArtistsProfilesPopupOpen(false);
                setNotificationModalOpen((prev) => !prev);
              }}
            />
            <AvatarButton
              imageUrl={profile?.profile_picture}
              onPress={() => {
                setIsArtistsProfilesPopupOpen(false);
                setNotificationModalOpen(false);
                setSettingsDropdownOpen((prev) => !prev);
              }}
            />
          </Row>
          <DropdownWrapper
            isOpen={isNotificationModalOpen}
            width={332}
            height={windowHeight < 700 ? windowHeight * 0.75 : 650}
          >
            <Notifications />
          </DropdownWrapper>
          <DropdownWrapper isOpen={isSettingsDropdownOpen} width={220}>
            <SettingsButton
              leftIcon={() => <Bookmark height={12} width={12} />}
              rightIcon={() => <Entypo name="chevron-small-right" size={20} color="#fff" />}
              onPress={() => navigation.navigate(SAVED_POSTS_SCREEN_NAME)}
            >
              {intl.formatMessage(messages.savedPosts)}
            </SettingsButton>
            <SettingsButton
              leftIcon={() => <Octicons name="settings" size={16} color="#fff" />}
              rightIcon={() => <Entypo name="chevron-small-right" size={20} color="#fff" />}
              onPress={() => navigation.navigate(SETTINGS_SCREEN_NAME)}
            >
              {intl.formatMessage(messages.settings)}
            </SettingsButton>
            <SettingsButton
              leftIcon={() => <MaterialIcons name="logout" size={16} color="#fff" />}
              onPress={async () => {
                const browserId = await getFingerprint();
                logOut(null, browserId);
              }}
            >
              {intl.formatMessage(messages.logout)}
            </SettingsButton>
          </DropdownWrapper>
        </>
      )
    );
  };

  return (
    <NavigationHeader
      {...props}
      insets={{ top: !media.tablet ? 20 : 40 }}
      showArtistInput={!hideArtistInput && media.tablet}
      showSearchBar={!hideSearchBar && media.tablet}
      isArtistProfilesPopupOpen={isArtistProfilesPopupOpen}
      onArtistProfilesPress={onArtistProfilesPress}
      headerLeft={renderLogo}
      headerMiddle={renderMenuItems}
      headerRight={renderLogoutButtons}
      headerMiddleLogo={false}
    />
  );
};

export default NavigationHeaderFan;
