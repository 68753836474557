import React, { FC } from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

const PlayButtonSvg = (props: SvgProps) => {
  return (
    <Svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
      <Path
        d="M30 58.5716C45.7796 58.5716 58.5715 45.7797 58.5715 30.0001C58.5715 14.2206 45.7796 1.42871 30 1.42871C14.2205 1.42871 1.42859 14.2206 1.42859 30.0001C1.42859 45.7797 14.2205 58.5716 30 58.5716Z"
        fill="white"
      />

      <Path
        d="M24.2856 18.5713L41.4285 29.9999L24.2856 41.4284V18.5713Z"
        fill="#0537FF"
        stroke="#0537FF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Button = styled(TouchableOpacity)``;

interface PlayButtonProps {
  onPress?: () => void;
}

const PlayButton: FC<PlayButtonProps> = ({ onPress }) => (
  <Button onPress={onPress}>
    <PlayButtonSvg />
  </Button>
);

export default PlayButton;
