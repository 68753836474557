import React from 'react';
import styled, { css } from 'styled-components/native';

import ProfileImageBackground from './ProfileImageBackground';

const Image = styled.Image`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 123px;
      height: 123px;
      border-radius: 123px;
    `}
`;

interface Props {
  imageUrl?: string;
}

const ProfileImage: React.FC<Props> = ({ imageUrl }: Props) => {
  return (
    <ProfileImageBackground>
      <Image source={{ uri: imageUrl }} />
    </ProfileImageBackground>
  );
};

export default ProfileImage;
