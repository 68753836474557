import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-family: BasierCircle-Medium;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  margin-top: 30px;
`;
