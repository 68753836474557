import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { LayoutChangeEvent } from 'react-native';

import NavigationHeaderFan from 'components/NavigationHeaderFan';

import { useUser } from 'hooks';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Header from './styled/Header';
import Tabs from './styled/Tabs';
import WebContainer from './styled/WebContainer';
import BlockedUsers from './tabsContent/BlockedUsers';
import Notifications from './tabsContent/Notifications';
import PaymentSettings from './tabsContent/PaymentSettings';
import ProfileSettings from './tabsContent/ProfileSettings';
import YourArtists from './tabsContent/YourArtists';

export { SCREEN_NAME };

const SettingsScreen: React.FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabsWidth, setTabsWidth] = useState<number>();
  const { profile } = useUser();
  const intl = useIntl();

  const tabs = useMemo(() => {
    return [
      {
        label: intl.formatMessage(messages.tabs.yourArtists),
        content: function renderContent() {
          return <YourArtists />;
        },
      },
      {
        label: intl.formatMessage(messages.tabs.profileSettings),
        content: function renderContent() {
          return <ProfileSettings containerWidth={tabsWidth} />;
        },
      },
      {
        label: intl.formatMessage(messages.tabs.blockedUsers),
        content: function renderContent() {
          return <BlockedUsers containerWidth={tabsWidth} />;
        },
      },
      // {
      //   label: intl.formatMessage(messages.tabs.notifications),
      //   content: function renderContent() {
      //     return <Notifications />;
      //   },
      // },
      {
        label: intl.formatMessage(messages.tabs.paymentSettings),
        content: function renderContent() {
          return <PaymentSettings tabWidth={tabsWidth} />;
        },
      },
    ];
  }, [tabsWidth]);

  const onLayout = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    setTabsWidth(width);
  };

  return (
    <WebContainer>
      <NavigationHeaderFan />
      <Header>{intl.formatMessage(messages.headerTitle, { name: profile?.first_name })}</Header>
      <Tabs
        onLayout={onLayout}
        tabs={tabs}
        onTabIndexChange={setCurrentTabIndex}
        currentTabIndex={currentTabIndex}
      />
      {tabs[currentTabIndex].content?.()}
    </WebContainer>
  );
};

export default SettingsScreen;
