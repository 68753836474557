import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Bold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 56px;
`;
