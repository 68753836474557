import styled from 'styled-components/native';

export default styled.Text<{ tintColor?: string; inset?: number }>`
  padding: ${({ inset }) => inset || 0}px 15px 15px 15px;
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme, tintColor }) => tintColor || theme.colors.text};
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
`;
