import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.View`
  border-radius: 14px;
  overflow: hidden;
  ${mediaWeb(css`
    border-radius: 0;
  `)};
`;
