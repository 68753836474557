import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused: boolean;
  hasNotifications?: boolean;
  size?: string;
}

interface SizedSvg extends SvgProps {
  size?: string;
}

export const InactiveMarketplaceIcon: React.FC<SvgProps> = ({ size, ...props }: SizedSvg) => {
  return (
    <Svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.5 22C6.5 22.2761 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.2761 5.5 22C5.5 21.7239 5.72386 21.5 6 21.5C6.27614 21.5 6.5 21.7239 6.5 22Z"
        fill="white"
        stroke="white"
      />
      <Path
        d="M20.5 22C20.5 22.2761 20.2761 22.5 20 22.5C19.7239 22.5 19.5 22.2761 19.5 22C19.5 21.7239 19.7239 21.5 20 21.5C20.2761 21.5 20.5 21.7239 20.5 22Z"
        fill="white"
        stroke="white"
      />
      <Path
        d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.8 7H22L20 17H6L4 2H1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const ActiveSvgComponent = ({ size, ...props }: SizedSvg) => {
  return (
    <Svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M6.5 22C6.5 22.2761 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.2761 5.5 22C5.5 21.7239 5.72386 21.5 6 21.5C6.27614 21.5 6.5 21.7239 6.5 22Z"
        fill="white"
        stroke="white"
      />
      <Path
        d="M19.5 22C19.5 21.7239 19.7239 21.5 20 21.5C20.2761 21.5 20.5 21.7239 20.5 22C20.5 22.2761 20.2761 22.5 20 22.5C19.7239 22.5 19.5 22.2761 19.5 22Z"
        fill="white"
        stroke="white"
        strokeWidth="3"
      />
      <Path
        d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M22 7H4.80005L6.00005 17H20L22 7Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.8 7H22L20 17H6L4 2H1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const StyledIconActive = styled(ActiveSvgComponent)`
  opacity: 1;
`;

export const StyledIconInactive = styled(InactiveMarketplaceIcon)`
  opacity: 0.52;
`;

const Icon = ({ size, ...props }: IconProps) => (
  <TabBarIcon
    iconActive={<ActiveSvgComponent size={size} />}
    iconInactive={<StyledIconInactive size={size} />}
    {...props}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
