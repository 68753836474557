import styled, { css } from 'styled-components/native';

export default styled.View`
  width: 100vw;
  height: 100vh;
  padding: 10vh 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2d38;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 80vw;
    `}

  ${({ theme }) =>
    theme.media.tabletLandscape &&
    css`
      width: 70vw;
    `}
`;
