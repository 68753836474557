export default {
  title: {
    id: 'support.help',
    defaultMessage: 'Help',
  },
  description: {
    id: 'support.description',
    defaultMessage:
      'If you need any help with Stanbase mobile app or web app please let us know. We will try to help you as soon as possible!',
  },
  button: {
    id: 'support.submitRequest',
    defaultMessage: 'Submit a request',
  },
};
