import styled, { css } from 'styled-components/native';

import { ScreenSize } from 'types';

interface Props {
  screenSize?: ScreenSize;
}

export default styled.Text<Props>`
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
  color: #121212;
  text-align: center;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      text-align: left;
    `}
`;
