import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface SvgComponentProps {
  fill?: boolean;
}

const SvgComponent: React.FC<SvgComponentProps> = ({ fill, ...rest }: SvgComponentProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill ? '#fff' : 'none'} {...rest}>
      <Path
        d="M22 2L11 13"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M22 2L15 22L11 13L2 9L22 2Z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
