import React from 'react';
import styled from 'styled-components/native';

const TouchableOpacity = styled.TouchableOpacity``;

const Image = styled.Image`
  width: 26px;
  height: 26px;
  border-radius: 26px;
  border-width: 1px;
  border-color: #fff;
`;

interface Props {
  onPress?: () => void;
  imageUrl?: string;
}

const AvatarButton: React.FC<Props> = ({ onPress, imageUrl }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Image source={{ uri: imageUrl }} />
    </TouchableOpacity>
  );
};

export default AvatarButton;
