import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ArtistFeedList from 'components/ArtistFeedList';
import FetchedDataContainer from 'components/FetchedDataContainer';

import { useArtistsFeed } from 'hooks';
import { Artist, ArtistFeed, ArtistProduct } from 'types';

import messages from './messages';
import EmptyMessage from './styled/EmptyMessage';
import FlatList from './styled/FlatList';
import Separator from './styled/Separator';

export interface ArtistsFeedProps {
  navigateToProduct?: (product: ArtistProduct) => void;
  navigateToArtist: (artist: Artist) => void;
}

const ArtistsFeed: React.FC<ArtistsFeedProps> = ({
  navigateToProduct,
  navigateToArtist,
}: ArtistsFeedProps): JSX.Element => {
  const { data, error, isLoading } = useArtistsFeed();
  const intl = useIntl();

  const formattedData = useMemo(() => {
    return data?.filter((artist) => {
      return artist.featured_feed.length > 0;
    });
  }, [data]);

  const extractKey = (typedItem: unknown) => `${(typedItem as ArtistFeed).username}`;

  const renderArtistFeedItem = (args: { item: unknown; index: number }) => {
    const { item } = args;
    const typedItem = item as ArtistFeed;
    return (
      <ArtistFeedList
        {...typedItem}
        maxItemsCount={5}
        isLoading={isLoading}
        onProductPress={navigateToProduct}
        onArtistPress={navigateToArtist}
      />
    );
  };

  return (
    <FetchedDataContainer isError={!!error}>
      <FlatList
        data={formattedData}
        keyExtractor={extractKey}
        renderItem={renderArtistFeedItem}
        ItemSeparatorComponent={() => <Separator />}
        initialNumToRender={3}
        ListEmptyComponent={() => (
          <EmptyMessage>{intl.formatMessage(messages.emptyMessage)}</EmptyMessage>
        )}
      />
    </FetchedDataContainer>
  );
};

ArtistsFeed.displayName = 'ArtistsFeed';

export default ArtistsFeed;
