import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgComponent: React.FC = (props) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <G
        clipPath="url(#prefix__clip0)"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M7.333 3.333L4 6H1.333v4H4l3.333 2.667V3.333z" fill="#fff" />
        <Path d="M15.333 6l-4 4M11.333 6l4 4" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SvgComponent;
