export default {
  title: {
    id: 'ageRestriction.modal.title',
    defaultMessage: 'Content Control',
  },
  description: {
    id: 'ageRestriction.modal.description',
    defaultMessage: 'Some published content or comments might be inappropriate for your age.',
  },
  secondDescription: {
    id: 'ageRestriction.modal.secondDescription',
    defaultMessage:
      'If you see something that could be upsetting or offensive to you or other Stans please report this to the Stanbase team.',
  },
  closeDialog: {
    id: 'ageRestriction.modal.button.close',
    defaultMessage: 'Close',
  },
};
