import { Audio, AVPlaybackStatus } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Linking, Platform } from 'react-native';

import { milliSecondsToTimeString } from 'utils/time';

import WebRecorder from '../WebRecorder';
import AudioPickerButton from './AudioPickerButton';
import Button from './Button';
import Mic from './Mic';
import Play from './Play';
import Plus from './Plus';
import Scissors from './Scissors';
import Stop from './Stop';
import Time from './Time';
import TrimButton from './TrimButton';
import TrimButtonText from './TrimButtonText';

interface Props {
  recording?: Audio.Recording;
  setRecording: any;
  setWebBlob: any;
  file: any;
  setPath: any;
  generateWavePath: () => string;
  recStatus?: Audio.RecordingStatus;
  setRecStatus: any;
  waveformWidth: number;
  pickedFile: DocumentPicker.DocumentResult | undefined;
  onTrimPress: () => void;
  setPickedFile: (pickedFile: DocumentPicker.DocumentResult | undefined) => void;
  soundToPlay: Audio.Sound;
}

const Controls: React.FC<Props> = ({
  soundToPlay,
  recording,
  setRecording,
  setWebBlob,
  file,
  setPath,
  generateWavePath,
  recStatus,
  pickedFile,
  setRecStatus,
  waveformWidth,
  onTrimPress,
  setPickedFile,
}: Props) => {
  const intl = useIntl();
  const [playbackStatus, setPlaybackStatus] = useState<AVPlaybackStatus>();
  const [isRecordPressed, setIsRecordPressed] = useState(false);

  useEffect(() => {
    const unmount = () => {
      recording?.stopAndUnloadAsync();
      recording?.setOnRecordingStatusUpdate(null);
      setRecording(undefined);
      setIsRecordPressed(false);
    };
    return () => unmount();
  }, []);

  const reloadSound = async () => {
    try {
      const pickedUri = pickedFile?.type === 'success' ? pickedFile.uri : recording?.getURI();
      await soundToPlay.unloadAsync();
      await soundToPlay.loadAsync({ uri: pickedUri || file || '' });
      soundToPlay.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
      await soundToPlay.setProgressUpdateIntervalAsync(250);
      const status = await soundToPlay.getStatusAsync();
      setPlaybackStatus(status);
    } catch (e) {}
  };

  useEffect(() => {
    if (Platform.OS !== 'web' && (recStatus?.isDoneRecording || pickedFile?.type === 'success')) {
      reloadSound();
    }
  }, [file, pickedFile, recStatus?.isDoneRecording]);

  // recording handlers

  const onRecordingStatusUpdate = (isRecording?: boolean) => {
    if (isRecording) setPath(generateWavePath());
  };

  const prepareAndStartRecording = async () => {
    console.log('Requesting permissions..');
    const permissions = await Audio.requestPermissionsAsync();
    if (permissions.granted) {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      console.log('Starting Recording..');
      const recording = new Audio.Recording();
      await recording.prepareToRecordAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);
      recording.setProgressUpdateInterval(100);
      recording.setOnRecordingStatusUpdate((status) => {
        setRecStatus(status);
        onRecordingStatusUpdate(status.isRecording);
      });
      await recording.startAsync();
      setRecording(recording);
      setIsRecordPressed(false);
      console.log('Recording started');
    } else {
      Alert.alert(
        'Permissions',
        'App needs to access your microphone, do you want to open settings now?',
        [
          { text: 'Open settings', onPress: Linking.openSettings },
          { text: 'Cancel', style: 'cancel' },
        ],
      );
    }
  };

  const startRecording = async (counter = 0) => {
    console.log('Trying to start recording #' + counter);
    try {
      await prepareAndStartRecording();
    } catch (err) {
      console.error('Failed to start recording', err);
      if (counter < 10) {
        startRecording(counter + 1);
      }
    }
  };

  const stopRecording = async () => {
    if (!recording) return;
    const state = await recording?.getStatusAsync();
    if (!state?.durationMillis || state?.durationMillis < 1000)
      await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log('Stopping recording..');
    await recording?.stopAndUnloadAsync();
    const uri = recording?.getURI();
    recording?.setOnRecordingStatusUpdate(null);
    setTimeout(() => onPlayPausePress(), 500);
    console.log('Recording stopped and stored at', uri);
  };

  // Playback handlers

  const onPlayPausePress = async () => {
    await Audio.setAudioModeAsync({ allowsRecordingIOS: false });
    try {
      if (!playbackStatus?.isLoaded) await reloadSound();
      if ((playbackStatus as any)?.isPlaying) {
        console.log('pausing');
        await soundToPlay.pauseAsync();
      } else {
        await soundToPlay.setPositionAsync(0);
        await soundToPlay.setProgressUpdateIntervalAsync(100);
        console.log('playing');
        await soundToPlay.playAsync();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onPlaybackStatusUpdate = async (status: AVPlaybackStatus) => {
    setPlaybackStatus(status);
    setPath(generateWavePath());
  };

  const onPressOut = () => {
    setIsRecordPressed(false);
    if (recStatus?.isRecording) stopRecording();
    else onPlayPausePress();
  };

  const onAudioPickerPress = async () => {
    const audioFileResult = await DocumentPicker.getDocumentAsync({
      type: 'audio/*',
    });

    if (audioFileResult.type === 'success' && audioFileResult.file && Platform.OS === 'web') {
      const pickedBlob = new Blob([audioFileResult.file], { type: audioFileResult.file.type });
      setWebBlob(pickedBlob);
      setPickedFile(audioFileResult);
    }

    if (
      (Platform.OS === 'android' || Platform.OS === 'ios') &&
      audioFileResult.type === 'success'
    ) {
      setPickedFile(audioFileResult);
    }
  };

  return (
    <>
      <Time>
        {(playbackStatus as any)?.durationMillis
          ? milliSecondsToTimeString(
              (playbackStatus as any)?.isPlaying
                ? (playbackStatus as any)?.positionMillis
                : (playbackStatus as any)?.durationMillis || recStatus?.durationMillis,
            )
          : recStatus?.isRecording
          ? intl.formatMessage({
              id: 'post.audioNote.isRecording',
              defaultMessage: 'Recording',
            })
          : intl.formatMessage({
              id: 'post.audioNote.startRecording',
              defaultMessage: 'Hold to Record',
            })}
      </Time>
      {Platform.OS === 'web' ? (
        <>
          <WebRecorder
            key={`waveform-${waveformWidth}`}
            recStatus={recStatus}
            setRecStatus={setRecStatus}
            playbackStatus={playbackStatus}
            onPlayPausePress={onPlayPausePress}
            pickedFile={pickedFile}
            onRecordingStatusUpdate={onRecordingStatusUpdate}
            onRecordingStarted={() => {}}
            onRecordingStopped={(wb) => setWebBlob(wb)}
          />
          <AudioPickerButton onPress={() => onAudioPickerPress()}>
            <Plus />
          </AudioPickerButton>
          {/* {recStatus?.isDoneRecording && (
            <TrimButton onPress={onTrimPress}>
              <Scissors />
              <TrimButtonText>
                {intl.formatMessage({
                  id: "post.audioNote.trim",
                  defaultMessage: "Trim",
                })}
              </TrimButtonText>
            </TrimButton>
          )} */}
        </>
      ) : (
        <>
          <Button
            pressed={isRecordPressed}
            recording={!!recStatus?.isRecording}
            noHover={!pickedFile && !recStatus?.isDoneRecording}
            onPressIn={() => !recStatus?.isDoneRecording && !pickedFile && setIsRecordPressed(true)}
            onLongPress={() => !recStatus?.isDoneRecording && !pickedFile && startRecording()}
            onPressOut={onPressOut}
          >
            {recStatus?.isRecording || (playbackStatus as any)?.isPlaying ? (
              <Stop />
            ) : recStatus?.isDoneRecording || !!pickedFile ? (
              <Play />
            ) : (
              <Mic />
            )}
          </Button>
          <AudioPickerButton onPress={() => onAudioPickerPress()}>
            <Plus />
          </AudioPickerButton>
          {recStatus?.isDoneRecording && (
            <TrimButton onPress={onTrimPress}>
              <Scissors />
              <TrimButtonText>
                {intl.formatMessage({
                  id: 'post.audioNote.trim',
                  defaultMessage: 'Trim',
                })}
              </TrimButtonText>
            </TrimButton>
          )}
        </>
      )}
    </>
  );
};

export default Controls;
