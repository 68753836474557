import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const Icon = styled(Ionicons).attrs({
  name: 'camera-reverse-sharp',
  color: '#fff',
  size: 35,
})``;

const TouchableOpacity = styled.TouchableOpacity.attrs(() => {
  return {
    activeOpacity: 0.7,
  };
})`
  position: absolute;
  right: 25px;
  bottom: 35px;
`;

interface Props {
  onPress?: () => void;
}

const CamRev: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Icon />
    </TouchableOpacity>
  );
};

export default CamRev;
