import styled from 'styled-components/native';

export default styled.View<{ isOpen: boolean; width?: number; height?: number }>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.colors.background};
  top: 100px;
  right: ${({ isOpen }) => (isOpen ? 0 : -9999)}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.24);
  overflow: hidden;
`;
