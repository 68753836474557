import styled from 'styled-components/native';

export default styled.TouchableOpacity<{ transparent?: boolean }>`
  min-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(18, 18, 18, 0.52)')};
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;
