import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.23px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 32px;
`;
