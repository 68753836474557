import styled from 'styled-components/native';

interface Props {
  paddingLeft?: boolean;
}

export default styled.View<Props>`
  flex: 1;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? '2.2%' : 0)};
`;
