import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Audio } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions, Platform, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_AUDIO_CREATE_POST_SCREEN_NAME } from 'screens/ArtistAudioCreatePostScreen/constants';
import { SCREEN_NAME as ARTIST_AUDIO_TRIM_SCREEN_NAME } from 'screens/ArtistAudioTrimScreen/constants';

import ScreenView from 'containers/ModalScreen';

import NavigationHeader from 'components/NavigationHeader';

import { useSnackbarSet } from 'context/snackbar';
import { useTheme } from 'themes';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Controls from './styled/Controls';
import RecordingContainer from './styled/RecordingContainer';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistAudioRecordScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { theme } = useTheme();
  const [recording, setRecording] = useState<Audio.Recording>();
  const [pickedFile, setPickedFile] = useState<DocumentPicker.DocumentResult>();
  const [webBlob, setWebBlob] = useState<Blob>();
  const [recStatus, setRecStatus] = useState<Audio.RecordingStatus>();
  const [waveformWidth, setWaveformWidth] = useState(0);
  const soundToPlay = useRef(new Audio.Sound()).current;
  const screenWidth = Dimensions.get('screen').width;
  const waveOffset = 50;
  const waveWidth = screenWidth + waveOffset * 2;
  const { setSnackbar } = useSnackbarSet();

  const generateWavePath = () => {
    const w = 60;
    const points = screenWidth / w;
    let path = 'M 0,100 c 20,-50 20,-75 40,-50 ';
    for (let i = points; i > 0; i--) {
      const dy = -1 * (60 - Math.floor(Math.random() * 50));
      path += `s ${w / 2},${dy} ${w},0 `;
    }
    path += 's 30,-75 60,50';
    return path;
  };

  const [svgPath, setPath] = useState<string>(generateWavePath());

  const onTrimPress = () => {
    soundToPlay.stopAsync();
    navigation.navigate(ARTIST_AUDIO_TRIM_SCREEN_NAME, {
      file: recording?.getURI() || webBlob,
    });
  };

  useEffect(() => {
    if (pickedFile?.type === 'success') {
      setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.labelSnackbar),
        actionLabel: intl.formatMessage(messages.closeSnackbar),
      });
    }
  }, [pickedFile]);

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          onGoingBack={() => soundToPlay.stopAsync()}
          title={intl.formatMessage({
            id: 'post.audioNote.createAudioNote',
            defaultMessage: 'Create Audio Note',
          })}
          headerBackTitle={intl.formatMessage({
            id: 'post.cancel',
            defaultMessage: 'Cancel',
          })}
          headerBackImage={() => <View />}
          headerRightLabel={intl.formatMessage({
            id: 'post.next',
            defaultMessage: 'Next',
          })}
          headerRightDisabled={!(recording || webBlob || pickedFile) || recStatus?.isRecording}
          headerRightOnPress={() => {
            soundToPlay.stopAsync();

            const pickedUri = pickedFile?.type === 'success' ? pickedFile.uri : '';

            navigation.navigate(ARTIST_AUDIO_CREATE_POST_SCREEN_NAME, {
              filePath:
                Platform.OS === 'web'
                  ? webBlob
                    ? URL.createObjectURL(webBlob)
                    : ''
                  : route.params?.file || pickedUri || recording?.getURI(),
              fileBlob: webBlob,
            });
          }}
        />
      }
    >
      <View
        onLayout={(e) => setWaveformWidth(e.nativeEvent.layout.width)}
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          marginHorizontal: -1 * (waveOffset + 15),
          marginBottom: -20,
        }}
      >
        {React.useMemo(
          () => (
            <Svg height={100} width={waveWidth} viewBox={`0 0 ${waveWidth} 100`}>
              <Path d={svgPath} fill={theme.audioNote.wave} stroke="none" />
            </Svg>
          ),
          [svgPath],
        )}
      </View>
      <RecordingContainer>
        <Controls
          soundToPlay={soundToPlay}
          recording={recording}
          setRecording={setRecording}
          setWebBlob={setWebBlob}
          file={
            Platform.OS === 'web'
              ? webBlob
                ? URL.createObjectURL(webBlob)
                : ''
              : route.params?.file || recording?.getURI()
          }
          pickedFile={pickedFile}
          setPath={setPath}
          generateWavePath={generateWavePath}
          recStatus={recStatus}
          setRecStatus={setRecStatus}
          setPickedFile={setPickedFile}
          waveformWidth={waveformWidth}
          onTrimPress={onTrimPress}
        />
      </RecordingContainer>
    </ScreenView>
  );
};

export default ArtistAudioRecordScreen;
