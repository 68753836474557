import { useHeaderHeight } from '@react-navigation/elements';
import React from 'react';

import { VARIANT } from 'context';
import { safeAreaInsets } from 'utils/safeAreaInsets';

import * as S from './KeyboardAvoidingView.styled';

interface Props {
  children?: React.ReactNode;
  behavior?: 'padding' | 'height';
  //** Add flex: 1 to container
  fill?: boolean;
}

const KeyboardAvoidingView: React.FC<Props> = ({
  children,
  behavior = 'padding',
  fill = true,
  ...rest
}) => {
  const headerHeight = useHeaderHeight();

  return (
    <S.KeyboardAvoidingView
      keyboardVerticalOffset={
        VARIANT === 'artist' ? headerHeight + safeAreaInsets.safeAreaInsetsTop : headerHeight
      }
      behavior={behavior}
      fill={fill}
      {...rest}
    >
      {children}
    </S.KeyboardAvoidingView>
  );
};

export default KeyboardAvoidingView;
