import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';
import { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import Text from 'components/FormScreenStyled/Text';
import LogoImage from 'components/LogoImage';
import WiseLogo from 'components/WiseLogo';

import { useBackEnd } from 'context';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import LogoWrapper from './styled/LogoWrapper';
import ScreenView from './styled/ScreenView';
import Section from './styled/Section';
import SectionTitle from './styled/SectionTitle';

export { SCREEN_NAME };

const ArtistConfirmEmailScreen: React.FC = () => {
  const intl = useIntl();
  const navigation = useNavigation();
  const { isAuthenticated } = useBackEnd();
  return (
    <ScreenView>
      <LogoWrapper
        onPress={() =>
          navigation.navigate(isAuthenticated ? HOME_SCREEN_NAME : WELCOME_SCREEN_NAME)
        }
      >
        <LogoImage />
      </LogoWrapper>
      <WiseLogo m="200px 0 20px 0" />
      <Section m="100px 0 0 0">
        <SectionTitle>{intl.formatMessage(messages.title)}</SectionTitle>
        <Text>{intl.formatMessage(messages.description)}</Text>
      </Section>
    </ScreenView>
  );
};

ArtistConfirmEmailScreen.displayName = 'ArtistConfirmEmailScreen';

export default ArtistConfirmEmailScreen;
