import { AxiosResponse } from 'axios';

import { useBackEnd } from 'context/backEnd';
import { MarketplaceItem } from 'types';

export interface ProductDetails {
  title: string;
  description: string;
  price: string;
  amount: string;
  path?: string;
  questionares?: string[];
  isUnlimited?: boolean;
}

interface UseProduct {
  addProduct: (details: ProductDetails) => Promise<MarketplaceItem>;
  updateProduct: (id: number, details: ProductDetails) => Promise<MarketplaceItem>;
  deleteProduct: (id: number) => Promise<AxiosResponse>;
}

export function useProduct(): UseProduct {
  const { axiosInstance } = useBackEnd();
  const KEY = 'artists/me/products/marketplace_items';
  const getBody = (
    { title, description, price, amount, path, questionares, isUnlimited }: ProductDetails,
    isUpdate = false,
  ): FormData => {
    const requestBody = new FormData();
    if (price) {
      requestBody.append('price', price);
    }
    if (path) {
      requestBody.append('file', {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        uri: path,
        type: 'image/jpeg',
        name: `${Date.now()}.jpg`,
      });
    }
    if (questionares) {
      requestBody.append(
        `questionares_${isUpdate ? 'update' : 'create'}`,
        JSON.stringify(questionares),
      );
    }
    if (description) {
      requestBody.append('description', description);
    }
    if (!isUnlimited && amount) {
      requestBody.append('stock', amount);
    }
    if (title) {
      requestBody.append('name', title);
    }
    requestBody.append('is_unlimited_stock', JSON.stringify(isUnlimited));
    return requestBody;
  };

  async function addProduct(details: ProductDetails) {
    try {
      const response = await axiosInstance.post(KEY, getBody(details), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateProduct(id: number, details: ProductDetails) {
    try {
      const response = await axiosInstance.patch(KEY.concat(`/${id}`), getBody(details, true), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deleteProduct(id: number) {
    return await axiosInstance.delete(KEY.concat(`/${id}`));
  }

  return { addProduct, updateProduct, deleteProduct };
}
