import { Video } from 'expo-av';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { LiveMoment } from 'types';

import ArtistName from './styled/ArtistName';
import BorderBox from './styled/BorderBox';
import Container from './styled/Container';
import EyeIcon from './styled/EyeIcon';
import LiveBadge from './styled/LiveBadge';
import LivePlayer from './styled/LivePlayer';
import PreviewImage from './styled/PreviewImage';
import TouchableOpacity from './styled/TouchableOpacity';
import VideoPlayer from './styled/Video';
import VideoContainer from './styled/VideoContainer';
import VideoInnerContainer from './styled/VideoInnerContainer';
import VideoPlayerContainer from './styled/VideoPlayerContainer';

export interface LiveMomentItemProps {
  item: LiveMoment;
  onPress?: (liveMoment: LiveMoment) => void;
  withPlaceholder?: boolean;
}

function LiveMomentItem({ item, onPress, withPlaceholder }: LiveMomentItemProps, ref: any) {
  const videoRef = useRef<Video>(null);
  const [isStreamLoaded, setStreamLoaded] = useState(false);

  useImperativeHandle(ref, () => ({
    play: async () => {
      if (item.isArchive) return;
      try {
        await videoRef.current?.setIsMutedAsync(true);
        await videoRef.current?.playAsync();
      } catch (e) {
        console.error(e);
      }
    },
    stop: async () => {
      try {
        await videoRef.current?.pauseAsync();
      } catch (e) {
        console.error(e);
      }
    },
  }));

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.pauseAsync();
    }
  }, []);

  return (
    <TouchableOpacity onPress={() => onPress?.(item)}>
      <Container>
        <VideoContainer withPlaceholder={withPlaceholder} isLive={item?.is_live}>
          {!withPlaceholder &&
            ![null, undefined].includes(item?.live_stream_viewers_count as any) &&
            (item?.is_live || item?.isArchive) && (
              <LiveBadge
                highlight={!item?.isArchive}
                icon={<EyeIcon />}
                text={`${item?.live_stream_viewers_count} ${item?.isArchive ? 'views' : ''}`}
              />
            )}
          <VideoInnerContainer>
            <VideoPlayerContainer isLive={item?.is_live}>
              {item?.is_live ? (
                <>
                  {Platform.OS !== 'web' && (
                    <LivePlayer
                      inputUrl={item?.m3u8_url}
                      autoplay={true}
                      bufferTime={300}
                      maxBufferTime={1000}
                      scaleMode={'ScaleAspectFill'}
                      audioEnable={false}
                      onStatus={(code: number) => {
                        if (code === 1001) {
                          setStreamLoaded(true);
                        }
                      }}
                    />
                  )}
                  {!isStreamLoaded && (
                    <PreviewImage
                      source={{
                        uri:
                          item.artist.low_res_profile_picture ||
                          item.artist.medium_res_profile_picture,
                      }}
                    />
                  )}
                </>
              ) : (
                <VideoPlayer
                  source={{ uri: item?.mp4_url || '' }}
                  resizeMode={Video.RESIZE_MODE_COVER}
                  shouldPlay={!item.isArchive}
                  ref={videoRef}
                  isMuted={true}
                />
              )}
            </VideoPlayerContainer>
          </VideoInnerContainer>
          {!withPlaceholder && <BorderBox isLive={item?.is_live} isArchived={item.isArchive} />}
        </VideoContainer>

        <ArtistName isLive={item?.is_live} numberOfLines={1} withPlaceholder={withPlaceholder}>
          {item.isArchive
            ? moment(item?.time_created).format('DD MMM YYYY, hh:mm')
            : item?.artist.display_name}
        </ArtistName>
      </Container>
    </TouchableOpacity>
  );
}

LiveMomentItem.displayName = 'LiveMomentItem';

export default forwardRef(LiveMomentItem);
