import { AntDesign } from '@expo/vector-icons';
import styled from 'styled-components/native';

export default styled(AntDesign).attrs(({ theme, tintColor }) => {
  return {
    name: 'arrowleft',
    color: tintColor || theme.colors.text,
    size: 22,
  };
})``;
