import React, { useMemo, useState } from 'react';

interface DefaultState {
  oldPassword: null | string;
  setOldPassword: React.Dispatch<React.SetStateAction<string | null>>;
  oldEmail: null | string;
  setOldEmail: React.Dispatch<React.SetStateAction<string | null>>;
}

const DEFAULT_STATE: DefaultState = {
  oldPassword: null,
  setOldPassword: () => {},
  oldEmail: null,
  setOldEmail: () => {},
};

export const OnBoardingArtistContext = React.createContext(DEFAULT_STATE);

interface Props {
  children: React.ReactNode;
}

export const OnBoardingArtistContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [oldPassword, setOldPassword] = useState<null | string>(null);
  const [oldEmail, setOldEmail] = useState<null | string>(null);

  const value = useMemo(() => {
    return {
      oldPassword,
      setOldPassword,
      oldEmail,
      setOldEmail,
    };
  }, [oldPassword, oldEmail, setOldPassword, setOldEmail]);

  return (
    <OnBoardingArtistContext.Provider value={value}>{children}</OnBoardingArtistContext.Provider>
  );
};

export function useArtistOnBoarding(): DefaultState {
  const context = React.useContext<DefaultState>(OnBoardingArtistContext);
  if (context === undefined) {
    throw new Error('useArtistOnBoarding must be used within a OnBoardingArtistContextProvider');
  }

  return context;
}
