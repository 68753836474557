import styled from 'styled-components/native';

export default styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 40,
  },
}))`
  flex: 1;
`;
