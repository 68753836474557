import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  hasNotifications: boolean | undefined;
  focused: boolean;
}

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={props.fill || 'none'} {...props}>
      <Path
        d="M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={props.stroke || '#fff'}
      />
    </Svg>
  );
}

const Icon = ({ hasNotifications, ...rest }: IconProps) => (
  <TabBarIcon
    hasNotifications={hasNotifications}
    iconActive={<SvgComponent {...rest} />}
    iconInactive={<SvgComponent {...rest} />}
    {...rest}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
