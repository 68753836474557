import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import { useTimeAgo } from 'hooks/useTimeAgo';

const TimestampText = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  letter-spacing: -0.08px;
  opacity: 0.64;
`;

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.button.backgroundColorBlue};
  padding: 15px;
`;

const TouchableOpacity = styled.TouchableOpacity``;

const ButtonText = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.23px;
`;

export interface SeeInsightsProps {
  timestamp?: string;
  timestampUpdateInterval?: number;
  onPress?: () => void;
}

const SeeInsights: React.FC<SeeInsightsProps> = ({
  timestamp,
  timestampUpdateInterval = 5000,
  onPress,
  ...restProps
}: SeeInsightsProps) => {
  const timestampDate = useMemo(() => (timestamp ? new Date(timestamp) : undefined), [timestamp]);
  const { timeAgoText } = useTimeAgo(timestampDate, timestampUpdateInterval);
  const intl = useIntl();

  return (
    <View {...restProps}>
      <TouchableOpacity onPress={onPress}>
        <ButtonText>
          {intl.formatMessage({
            id: 'feed.item.seeInsights',
            defaultMessage: 'See Insights',
          })}
        </ButtonText>
      </TouchableOpacity>
      <TimestampText>
        {intl.formatMessage({
          id: 'feed.item.posted',
          defaultMessage: 'Posted',
        })}{' '}
        {timeAgoText}
      </TimestampText>
    </View>
  );
};

export default SeeInsights;
