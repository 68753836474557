export default {
  join: {
    id: 'artistJoinStanbase.join',
    defaultMessage: 'So you want to join Stanbase?',
  },
  fill: {
    id: 'artistJoinStanbase.fill',
    defaultMessage: 'Fill out the details below and we’ll be in touch.',
  },
  send: {
    id: 'artistJoinStanbase.send',
    defaultMessage: 'Send request',
  },
  error: {
    tryAgain: {
      id: 'artistJoinStanbase.error.tryAgain',
      defaultMessage: 'There was an error, please try again later.',
    },
    artistNameRequired: {
      id: 'artistJoinStanbase.error.artistNameRequired',
      defaultMessage: 'Please provide artist name.',
    },
    emailRequired: {
      id: 'artistJoinStanbase.error.emailRequired',
      defaultMessage: 'Please provide e-mail address.',
    },
    emailIncorrect: {
      id: 'artistJoinStanbase.error.emailIncorrect',
      defaultMessage: 'Provided e-mail address is incorrect.',
    },
  },
};
