import styled from 'styled-components/native';

export default styled.View<{ paddingBottom?: number }>`
  background-color: rgba(0, 0, 0, 0.5);
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: 25px;
  padding-right: 25px;
`;
