import Modal from 'react-native-modal';
import styled from 'styled-components/native';

export default styled(Modal)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  flex: 1;
`;
