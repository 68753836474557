import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import 'components/LoginFormButton';
import 'components/LoginFormButton';
import EmailSentView from 'components/EmailSentView';
import FormErrorsView, { FormPasswordErrorsView } from 'components/FormErrorsView';
import FormTitleText from 'components/FormTitleText/FormTitleText';
import LoginFormButton from 'components/LoginFormButton';
import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { WebviewContext } from 'context/webview';
import { useAuth } from 'hooks';
import usePasswordChecks from 'hooks/usePasswordChecks';
import {
  nondiscriminationPolicyURL,
  paymentTermsURL,
  privacyPolicyURL,
  termsURL,
} from 'utils/links';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
export { SCREEN_NAME } from './constants';

import messages from './messages';
import ContentView from './styled/ContentView';
import DisclaimerText from './styled/DisclaimerText';
import DisclaimerView from './styled/DisclaimerView';
import MainView from './styled/MainView';
import RegistrationView from './styled/RegistrationView';
import Text from './styled/Text';
import TextInput from './styled/TextInput';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const RegisterWithEmailScreen: React.FC<Props> = ({ navigation }: Props) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<{
    email: string;
    password: string;
    register: any;
  }>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { isLoading, authError, register, logIn } = useAuth();
  const intl = useIntl();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const { validatePassword, passwordChecks } = usePasswordChecks({
    missingUpperOrLowerCase: intl.formatMessage(
      messages.passwordInput.error.missingUpperOrLowerCase,
    ),
    missingNumber: intl.formatMessage(messages.passwordInput.error.missingNumber),
    missingSpecialCharacter: intl.formatMessage(
      messages.passwordInput.error.missingSpecialCharacter,
    ),
  });
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const { openWebView } = useContext(WebviewContext);

  const doSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [isValid, handleSubmit]);

  const onSubmit = useCallback(
    async (args: { email: string; password: string }) => {
      if (isLoading) {
        return;
      }

      const response = await register(args.email, args.password);
      if (response) {
        setEmail(args.email);
        setSubmitSuccess(true);
      }
    },
    [isLoading, register],
  );

  const onTermsCtaPress = useCallback(() => {
    openWebView(termsURL);
  }, [openWebView]);

  const onPrivacyPolicyCta = useCallback(() => {
    openWebView(privacyPolicyURL);
  }, [openWebView]);

  const onPaymentTermsPress = useCallback(() => {
    openWebView(paymentTermsURL);
  }, [openWebView]);

  const onNondiscriminationPolicyPress = useCallback(() => {
    openWebView(nondiscriminationPolicyURL);
  }, [openWebView]);

  useEffect(() => {
    (emailInputRef.current as any)?.focus();
    validatePassword(control.getValues().password);
  }, []);

  useEffect(() => {
    if (!authError) {
      return;
    }

    const errorMessage =
      authError.data.detail === 'Not whitelisted.'
        ? messages.error.not_whitelisted
        : (messages.error as any)[
            (authError.data.email || authError.data.password || [{}])[0].code
          ] || messages.error.unknown;

    setError('registration', {
      message: intl.formatMessage(errorMessage),
    });

    alert(intl.formatMessage(errorMessage));
  }, [authError]);
  return (
    <MainView>
      <WebContainer>
        <NavigationHeader showGoBack insets={{ top: 35 }} headerMiddleLogo={true} />
        <RegistrationView isSubmitSuccess={isSubmitSuccess}>
          {!isSubmitSuccess ? (
            <>
              <ContentView>
                <FormTitleText>{intl.formatMessage(messages.title)}</FormTitleText>
                <Text>{intl.formatMessage(messages.description)}</Text>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextInput
                      ref={emailInputRef}
                      label={intl.formatMessage(messages.emailInput.label)}
                      placeholder={intl.formatMessage(messages.emailInput.placeholder)}
                      keyboardType="email-address"
                      returnKeyType="next"
                      autoCapitalize="none"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        clearErrors('email');
                        clearErrors('registration');
                        onChange(value);
                      }}
                      onSubmitEditing={() => (passwordInputRef.current as any)?.focus()}
                      error={!!errors.email}
                    />
                  )}
                  name="email"
                  rules={{
                    required: intl.formatMessage(messages.emailInput.error.required),
                    pattern: {
                      value: regex.email,
                      message: intl.formatMessage(messages.emailInput.error.invalid),
                    },
                  }}
                />

                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextInput
                      ref={passwordInputRef}
                      label={intl.formatMessage(messages.passwordInput.label)}
                      placeholder={intl.formatMessage(messages.passwordInput.placeholder)}
                      secureTextEntry={true}
                      returnKeyType="go"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        clearErrors('registration');
                        onChange(value);
                      }}
                      onSubmitEditing={doSubmit}
                      error={!!errors.password}
                    />
                  )}
                  name="password"
                  rules={{
                    validate: {
                      combined: validatePassword,
                    },
                  }}
                />

                <FormPasswordErrorsView passwordChecks={passwordChecks} />

                <LoginFormButton
                  disabled={!isValid}
                  processing={isSubmitting || isLoading}
                  onPress={doSubmit}
                >
                  {intl.formatMessage(messages.registerCtaLabel)}
                </LoginFormButton>

                {isSubmitted && <FormErrorsView errors={errors} />}
              </ContentView>
              <DisclaimerView>
                <DisclaimerText>
                  {intl.formatMessage(messages.disclaimer, {
                    termsCta: function TermsCtaChunk(chunk) {
                      return <DisclaimerText onPress={onTermsCtaPress}>{chunk}</DisclaimerText>;
                    },
                    privacyPolicyCta: function PrivacyCtaChunk(chunk) {
                      return <DisclaimerText onPress={onPrivacyPolicyCta}>{chunk}</DisclaimerText>;
                    },
                    paymentTermsCta: function paymentTermsCtaChunk(chunk) {
                      return <DisclaimerText onPress={onPaymentTermsPress}>{chunk}</DisclaimerText>;
                    },
                    underlineText: function underlineTextChunk(chunk) {
                      return <DisclaimerText underline>{chunk}</DisclaimerText>;
                    },
                    nondiscriminationPolicyCta: function nondiscriminationPolicyCtaChunk(chunk) {
                      return (
                        <DisclaimerText onPress={onNondiscriminationPolicyPress}>
                          {chunk}
                        </DisclaimerText>
                      );
                    },
                  })}
                </DisclaimerText>
              </DisclaimerView>
            </>
          ) : (
            <EmailSentView
              navigation={navigation}
              title={messages.success.title}
              description={messages.success.description}
              email={email}
            />
          )}
        </RegistrationView>
      </WebContainer>
    </MainView>
  );
};

export default RegisterWithEmailScreen;
