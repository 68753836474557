export default {
  header: {
    title: {
      id: 'newMessage.header.title',
      defaultMessage: 'New message',
    },
    cancel: {
      id: 'newMessage.header.cancel',
      defaultMessage: 'Cancel',
    },
    save: {
      id: 'newMessage.header.send',
      defaultMessage: 'Send',
    },
  },
  description: {
    id: 'newMessage.description',
    defaultMessage:
      'Need any extra info? Get in touch with your Stan to iron out the details and ask any questions.',
  },
  input: {
    label: {
      id: 'newMessage.input.label',
      defaultMessage: 'Message',
    },
    error: {
      other: {
        id: 'newMessage.input.error.other',
        defaultMessage: 'Could not send the message. Please try again.',
      },
      required: {
        id: 'newMessage.input.error.required',
        defaultMessage: 'Message cannot be empty.',
      },
    },
  },
};
