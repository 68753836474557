import React from 'react';
import styled from 'styled-components/native';

import Subtitle from './Subtitle';
import Text from './Text';

const View = styled.View`
  margin-right: 24px;
`;

interface Props {
  subtitle?: string;
  text?: string;
}

const Field: React.FC<Props> = ({ subtitle, text }: Props) => {
  return (
    <View>
      <Subtitle>{subtitle}</Subtitle>
      <Text>{text}</Text>
    </View>
  );
};

export default Field;
