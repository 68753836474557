function patchUrl(url: string) {
  return url.indexOf('/proxy') === -1
    ? url
    : `${process.env.API_URL?.replace(/\/api\/v\d+/, '')}${url}`;
}

function addAuthToUrl(url: string, accessToken?: string | null) {
  return accessToken ? `${url}?token=${accessToken}` : url;
}

export function getProxyUrl(url: string, accessToken: string | null): string {
  return addAuthToUrl(patchUrl(url), accessToken);
}
