import styled from 'styled-components/native';

import { OrderStatusTranslation } from 'types';

export default styled.View<{ status?: OrderStatusTranslation; isActive?: boolean }>`
  background-color: ${({ isActive, status, theme }) =>
    isActive
      ? status === 'declined'
        ? theme.orderPreview.highlightMarkSecondary
        : theme.orderPreview.highlightMark
      : 'transparent'};
  border-radius: 5px;
  height: 9px;
  width: 9px;
`;
