import { Platform } from 'react-native';
import styled from 'styled-components/native';

interface Props {
  columnWidth?: number;
  isFirst?: boolean;
  isLast?: boolean;
}

export default styled.View<Props>`
  flex-direction: column;
  width: ${({ columnWidth }) => (columnWidth ? columnWidth : 300)}px;
  padding: 0 ${Platform.OS === 'web' ? 8 : 5}px;
  padding-left: ${({ isFirst }) => (isFirst ? 0 : Platform.OS === 'web' ? 8 : 5)}px;
  padding-right: ${({ isLast }) => (isLast ? 0 : Platform.OS === 'web' ? 8 : 5)}px;
  align-items: flex-start;
`;
