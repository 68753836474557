export default {
  stock: {
    id: 'artist.marketplace.products.stock',
    defaultMessage: 'Sold {sold} of ',
  },
  lowStock: {
    id: 'artist.marketplace.products.lowStock',
    defaultMessage: 'LOW STOCK',
  },
};
