import styled from 'styled-components/native';

interface ViewProps {
  center?: boolean;
}

const View = styled.ScrollView.attrs<ViewProps>(({ center }: ViewProps) => {
  return {
    horizontal: true,
    contentContainerStyle: {
      alignItems: 'center',
      flex: 1,
      justifyContent: center ? 'space-between' : 'flex-start',
    },
  };
})<ViewProps>``;

export default View;
