import { SCREEN_NAME as CONTACT_SCREEN_NAME } from 'screens/ContactScreen';
import { SCREEN_NAME as INVESTOR_RELATIONS_SCREEN_NAME } from 'screens/InvestorRelationsScreen';
import { SCREEN_NAME as PRESS_SCREEN_NAME } from 'screens/PressScreen';

import { MenuItem } from 'components/NavigationMenu/types';

export const menuItems: MenuItem[] = [
  { title: 'Investor Relations', screen: INVESTOR_RELATIONS_SCREEN_NAME },
  { title: 'Press', screen: PRESS_SCREEN_NAME },
  { title: 'Contact', screen: CONTACT_SCREEN_NAME },
];
