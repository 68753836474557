import styled from 'styled-components/native';

export default styled.TextInput`
  background-color: ${({ theme }) => theme.textInput.background};
  border-radius: 20px;
  border-color: ${({ theme }) => theme.textInput.borderColor};
  border-width: 0.4px;
  color: ${({ theme }) => theme.textInput.text};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
  font-family: BasierCircle-Regular;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
`;
