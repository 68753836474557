import React from 'react';
import styled from 'styled-components/native';

import DecoratedTitle from './DecoratedTitle';
import DecoratedWrapper from './DecoratedWrapper';

type SubNavigationItem = {
  name: string | JSX.Element;
  id: string;
};

export type SubNavigationProps = {
  items: SubNavigationItem[];
  activeId?: string;
  onSelect: (id: string) => void;
};

// margin-left is set temporarily to -45px. Remember to change it to 14px after uncommenting searchbutton
const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-left: -45px;
`;

const SubNavigation: React.FC<SubNavigationProps> = ({
  items,
  activeId,
  onSelect,
}: SubNavigationProps) => (
  <Wrapper>
    {items.map(({ name, id }) => (
      <DecoratedWrapper
        isActive={id === activeId}
        onPress={() => {
          onSelect(id);
        }}
        key={id}
      >
        <DecoratedTitle isActive={id === activeId}>{name}</DecoratedTitle>
      </DecoratedWrapper>
    ))}
  </Wrapper>
);

export default SubNavigation;
