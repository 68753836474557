import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as LOGIN_SCREEN } from 'screens/LoginWithEmailScreen';
import { SCREEN_NAME as REGISTRATION_SCREEN } from 'screens/RegisterWithEmailScreen';

import ContinueWithView from 'components/ContinueWithView';
import LoginFormButton from 'components/LoginFormButton';
import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { WebviewContext } from 'context/webview';
import {
  nondiscriminationPolicyURL,
  paymentTermsURL,
  privacyPolicyURL,
  termsURL,
} from 'utils/links';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import AlreadyStanText from './styled/AlreadyStanText';
import AlreadyStanView from './styled/AlreadyStanView';
import ContentView from './styled/ContentView';
import DisclaimerText from './styled/DisclaimerText';
import DisclaimerView from './styled/DisclaimerView';
import JoinView from './styled/JoinView';
import MainView from './styled/MainView';
import Text from './styled/Text';
import Title from './styled/Title';
export { SCREEN_NAME };

interface Props {}

const JoinStanbaseScreen: React.FC<Props> = () => {
  const intl = useIntl();
  const navigation = useNavigation();
  const { openWebView } = useContext(WebviewContext);

  const onRegistrationPress = useCallback(() => {
    navigation.navigate(REGISTRATION_SCREEN);
  }, []);

  const onLoginPress = useCallback(() => {
    navigation.navigate(LOGIN_SCREEN);
  }, []);

  const onTermsCtaPress = useCallback(() => {
    openWebView(termsURL);
  }, [openWebView]);

  const onPrivacyPolicyCta = useCallback(() => {
    openWebView(privacyPolicyURL);
  }, [openWebView]);

  const onPaymentTermsPress = useCallback(() => {
    openWebView(paymentTermsURL);
  }, [openWebView]);

  const onNondiscriminationPolicyPress = useCallback(() => {
    openWebView(nondiscriminationPolicyURL);
  }, [openWebView]);

  return (
    <MainView>
      <WebContainer>
        <NavigationHeader showGoBack insets={{ top: 35 }} headerMiddleLogo={true} />

        <JoinView>
          <ContentView>
            <Title>{intl.formatMessage(messages.title)}</Title>
            <Text>{intl.formatMessage(messages.description)}</Text>
            <LoginFormButton onPress={onRegistrationPress}>
              {intl.formatMessage(messages.buttonText)}
            </LoginFormButton>
          </ContentView>
          <ContinueWithView />
          <AlreadyStanView>
            <AlreadyStanText>
              {intl.formatMessage(messages.ctaText, {
                cta: function CtaChunk(chunk) {
                  return (
                    <AlreadyStanText underline={true} onPress={onLoginPress}>
                      {chunk}
                    </AlreadyStanText>
                  );
                },
              })}
            </AlreadyStanText>
          </AlreadyStanView>
          <DisclaimerView>
            <DisclaimerText>
              {intl.formatMessage(messages.disclaimer, {
                termsCta: function TermsCtaChunk(chunk) {
                  return <DisclaimerText onPress={onTermsCtaPress}>{chunk}</DisclaimerText>;
                },
                privacyPolicyCta: function PrivacyCtaChunk(chunk) {
                  return <DisclaimerText onPress={onPrivacyPolicyCta}>{chunk}</DisclaimerText>;
                },
                paymentTermsCta: function paymentTermsCtaChunk(chunk) {
                  return <DisclaimerText onPress={onPaymentTermsPress}>{chunk}</DisclaimerText>;
                },
                nondiscriminationPolicyCta: function nondiscriminationPolicyCtaChunk(chunk) {
                  return (
                    <DisclaimerText onPress={onNondiscriminationPolicyPress}>
                      {chunk}
                    </DisclaimerText>
                  );
                },
              })}
            </DisclaimerText>
          </DisclaimerView>
        </JoinView>
      </WebContainer>
    </MainView>
  );
};

export default JoinStanbaseScreen;
