import styled, { css } from 'styled-components/native';

import PlaceholderText from 'components/PlaceholderText';

import { AvatarMode } from '../Avatar';

interface TimestampPlaceholderProps {
  mode: AvatarMode;
}

const TimestampPlaceholder = styled(PlaceholderText)<TimestampPlaceholderProps>`
  ${(props) =>
    props.mode !== 'row' &&
    css`
      margin: 10px 0 0 0;
    `}
`;

TimestampPlaceholder.defaultProps = {
  minNumWords: 3,
  maxNumWords: 3,
  singleLine: true,
};

export default TimestampPlaceholder;
