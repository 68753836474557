import styled from 'styled-components/native';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

export default styled(TouchableOpacity)<{ solid?: boolean; bg?: string }>`
  justify-content: center;
  align-items: center;
  background-color: ${({ solid, theme, bg }) =>
    bg || (solid ? theme.button.modalSolid : theme.button.modalGhost)};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.backgroundDark};
  height: 56px;
`;
