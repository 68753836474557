import React, { FC } from 'react';
import { Platform } from 'react-native';
import Animated, { useAnimatedProps, withSpring } from 'react-native-reanimated';
import Svg, { Circle } from 'react-native-svg';
import styled from 'styled-components/native';

import { SPRING_CONFIG } from 'utils/animated';

const View = styled.View`
  position: relative;
  align-items: center;
  justify-content: center;
`;

const StyledSvg = styled(Svg)`
  position: absolute;
  transform: rotate(90deg);
`;

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

interface ProgressProps {
  duration: number;
  strokeOffset: Animated.SharedValue<number>;
}

export const DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET = 47 * Math.PI * 2;

const ProgressSvg: FC<ProgressProps> = ({ duration, strokeOffset }) => {
  const animatedCircleProps = useAnimatedProps(
    () => ({
      strokeDashoffset:
        duration === 0 || strokeOffset.value === DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET
          ? DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET
          : withSpring(strokeOffset.value, SPRING_CONFIG.NO_BOUNCE),
    }),
    [duration],
  );

  return (
    <View pointerEvents="none">
      <StyledSvg width="60px" height="60px" fill="none" viewBox="0 0 100 100">
        <Circle strokeWidth="3" stroke="#455cbb" r="47" cx="50" cy="50" />
        {Platform.OS === 'web' ? (
          <Circle
            strokeDashoffset={strokeOffset.value}
            strokeWidth="3"
            stroke="#fff"
            r="47"
            cx="50"
            cy="50"
            strokeDasharray={`${DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET}`}
          />
        ) : (
          <AnimatedCircle
            animatedProps={animatedCircleProps}
            strokeWidth="3"
            stroke="#fff"
            r="47"
            cx="50"
            cy="50"
            strokeDasharray={`${DEFAULT_PROGRESS_CIRCLE_STROKE_OFFSET}`}
          />
        )}
      </StyledSvg>
    </View>
  );
};

export default ProgressSvg;
