import { ActivityIndicator } from 'react-native-paper';
import styled from 'styled-components/native';

const LoadingIndicator = styled(ActivityIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
`;

LoadingIndicator.defaultProps = {
  color: '#ffffff',
  size: 50,
};

export default LoadingIndicator;
