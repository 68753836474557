import { memo } from 'react';
import isEqual from 'react-fast-compare';
import styled, { css } from 'styled-components/native';

import HelperText from 'components/HelperText';

interface HelperTextProps {
  spacer?: boolean;
  check?: boolean;
  pass?: boolean;
}

export default memo(
  styled(HelperText)<HelperTextProps>`
    font-size: 13px;
    line-height: 18px;
    padding: 0;
    color: ${({ theme }) => theme.colors.primaryInactive};

    ${({ check }) =>
      check &&
      css`
        margin: 0;
      `}

    ${({ spacer }) =>
      spacer &&
      css`
        margin-right: 9px;
      `}

  ${({ check, pass }) =>
      check &&
      pass &&
      css`
        color: ${({ theme }) => theme.textInput.valid};
      `}
  `,
  isEqual,
);
