import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

const SvgComponent: React.FC = () => {
  return (
    <Svg width={64} height={64} viewBox="0 0 64 64" fill="none">
      <Path
        d="M50.667 21.333H13.333A5.333 5.333 0 008 26.667V56a4 4 0 004 4h40a4 4 0 004-4V26.667a5.333 5.333 0 00-5.333-5.334zM18.667 21.333v-2.666a13.333 13.333 0 0126.666 0v2.666"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx={32} cy={37.333} stroke="#fff" strokeWidth={2} r={5.333} />
      <Path stroke="#fff" strokeWidth={2} strokeLinecap="round" d="M32.5 43.667v6" />
    </Svg>
  );
};

export default SvgComponent;
