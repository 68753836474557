import { LinearGradient } from 'expo-linear-gradient';
import { Platform } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';
import styled from 'styled-components/native';

import CachedImage from 'components/CachedImage';

import { statusBarPadding } from 'utils/screen';

export const ContentView = styled(Animated.View)`
  display: flex;
  flex-direction: row;
`;

export const CoverView = styled.View<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  overflow: hidden;
`;

export const CoverImageGradient = styled(LinearGradient)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
`;

export const CoverRelativeView = styled.View`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CoverImage = styled(CachedImage)`
  width: 100%;
  height: 100%;
`;

export const Dimmer = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const HeaderText = styled.Text`
  color: ${({ theme }) => theme.colors.text};
  font-family: 'BasierCircle-Medium';
  letter-spacing: -0.23px;
  font-size: 15px;
  width: 100%;
  text-align: center;
`;

export const HeaderView = styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${statusBarPadding}px 0 ${Platform.OS === 'ios' ? 5 : 20}px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Flatlist = styled(Animated.createAnimatedComponent(FlatList))`
  flex: 1;
  height: 100%;
`;
