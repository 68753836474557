import styled from 'styled-components/native';

import Badge from 'components/Badge';

export default styled(Badge)<{ highlight?: boolean }>`
  background-color: ${({ theme, highlight }) =>
    highlight ? theme.colors.errorAccent : theme.colors.progressBackgorund};
  min-width: 60px;
  height: 25px;
`;
