import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { ParamList } from 'stacks/types';

import FormScreen from 'screens/FormScreen';

import { SCREEN_NAME } from './constants';
import messages from './messages';

export { SCREEN_NAME } from './constants';

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  navigation: ScreenNavigationProp;
};

const ContactFormScreen: React.FC<Props> = ({ navigation }: Props) => (
  <FormScreen messages={messages} navigation={navigation} />
);

export default ContactFormScreen;
