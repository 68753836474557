import React, { useEffect, useRef } from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const Text = styled.Text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: rgba(255, 255, 255, 0.52);
  margin-left: 10px;
`;

const TouchableOpacity = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 216px;
  height: 32px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 4px;
  margin-right: 34px;
  padding: 8px;
`;

const ArrowIcon: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M4 6L8 10L12 6"
        stroke="white"
        strokeOpacity="0.52"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

interface Props {
  setTopPosition: (arg: number) => void;
  setLeftPosition: (arg: number) => void;
  onPress?: () => void;
}

const ShowArtistProfileInput: React.FC<Props> = ({
  setTopPosition,
  setLeftPosition,
  onPress,
}: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setTopPosition(ref.current.getBoundingClientRect().top);
      setLeftPosition(ref.current.getBoundingClientRect().left);
    }
  }, [ref]);

  return (
    <TouchableOpacity onPress={onPress} ref={ref}>
      <Text>Show Artist profile...</Text>
      <ArrowIcon />
    </TouchableOpacity>
  );
};
export default ShowArtistProfileInput;
