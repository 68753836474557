import styled from 'styled-components/native';

export default styled.Text`
  font-weight: bold;
  font-family: BasierCircle-Regular;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 20px 0;
  text-align: center;
`;
