import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextInput as RNTextInput, View } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_POLL_ADD_DESCRIPTION_SCREEN_NAME } from 'screens/ArtistPollAddDescriptionScreen/constants';

import ScreenView from 'containers/ModalScreen';

import ErrorText from 'components/ErrorHandling/ErrorText';
import NavigationHeader from 'components/NavigationHeader';

import { containsLink } from 'utils/regex';

import { SCREEN_NAME } from './constants';
import Label from './styled/Label';
import OptionLetter from './styled/OptionLetter';
import OptionLetterBox from './styled/OptionLetterBox';
import Section from './styled/Section';
import TextInput from './styled/TextInput';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const QUESTION_MAX_CHAR_LIMIT = 60;
const ANSWER_MAX_CHAR_LIMIT = 36;

const ArtistPollCreateScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const [question, setQuestion] = useState<string>('');
  const [answers, setAnswers] = useState([
    { value: '', isNew: false },
    { value: '', isNew: false },
  ]);
  const answersInputsRefs = useRef<RNTextInput[]>([]);

  const isQuestionCharLimit = question.length > QUESTION_MAX_CHAR_LIMIT;
  const isQuestionLink = containsLink(question);
  const isAnswersError = answers
    .map((a) => a.value)
    .some((v) => v.length > ANSWER_MAX_CHAR_LIMIT || containsLink(v));

  const getOptionLetter = (index: number) => {
    switch (index) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
    }
  };

  const renderInput = ({
    index,
    secondary,
    letter,
    value,
    placeholder,
    onKeyPress,
    onChangeText,
    onSubmitEditing,
  }: {
    index: number;
    secondary?: boolean;
    letter?: string;
    value: string;
    placeholder: string;
    onKeyPress: (key: string) => void;
    onChangeText: (v: string) => void;
    onSubmitEditing: () => void;
  }) => {
    return (
      <>
        <View style={{ justifyContent: 'center' }}>
          <TextInput
            ref={(el: RNTextInput) => (answersInputsRefs.current[index] = el)}
            isAnswer
            secondary={secondary}
            placeholder={placeholder}
            value={value}
            onKeyPress={({ nativeEvent }: any) => onKeyPress(nativeEvent.key)}
            onChangeText={(v: string) => onChangeText(v)}
            onSubmitEditing={onSubmitEditing}
            returnKeyType="next"
            isError={
              answers[index].value.length > ANSWER_MAX_CHAR_LIMIT ||
              containsLink(answers[index].value)
            }
          />
          <OptionLetterBox>
            <OptionLetter>{letter}</OptionLetter>
          </OptionLetterBox>
        </View>
        {answers[index].value.length > ANSWER_MAX_CHAR_LIMIT ? (
          <ErrorText>Answer is too long</ErrorText>
        ) : containsLink(answers[index].value) ? (
          <ErrorText>Links are not allowed here</ErrorText>
        ) : null}
      </>
    );
  };

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          title={intl.formatMessage({
            id: 'post.poll.createPoll',
            defaultMessage: 'Create Poll',
          })}
          headerBackTitle={intl.formatMessage({
            id: 'post.cancel',
            defaultMessage: 'Cancel',
          })}
          headerBackImage={() => <View />}
          headerRightLabel={intl.formatMessage({
            id: 'post.next',
            defaultMessage: 'Next',
          })}
          headerRightDisabled={
            !(question && !answers.some((a) => !a.isNew && !a.value.length)) ||
            isQuestionLink ||
            isQuestionCharLimit ||
            isAnswersError
          }
          headerRightOnPress={() => {
            navigation.navigate(ARTIST_POLL_ADD_DESCRIPTION_SCREEN_NAME, {
              question,
              answers: answers.filter((a) => !a.isNew).map((a) => a.value),
            });
          }}
        />
      }
    >
      <Section>
        <Label>Question</Label>
        <TextInput
          placeholder={intl.formatMessage({
            id: 'post.poll.askAnything',
            defaultMessage: 'Ask Anything',
          })}
          value={question}
          onChangeText={(v: string) => setQuestion(v)}
          onSubmitEditing={() => answersInputsRefs.current[0].focus()}
          blurOnSubmit={false}
          returnKeyType="next"
          isError={isQuestionCharLimit}
        />
        {isQuestionCharLimit ? (
          <ErrorText>Question is too long</ErrorText>
        ) : isQuestionLink ? (
          <ErrorText>Links are not allowed here</ErrorText>
        ) : null}
      </Section>
      <Section>
        <Label>Answers</Label>
        {answers.map((a, i) => {
          return renderInput({
            index: i,
            secondary: a.isNew,
            letter: a.isNew ? undefined : getOptionLetter(i),
            value: a.value,
            placeholder: a.isNew
              ? intl.formatMessage({
                  id: 'post.poll.addNextOption',
                  defaultMessage: 'Add next option',
                })
              : `${intl.formatMessage({
                  id: 'post.poll.option',
                  defaultMessage: 'Option',
                })} ${i + 1}`,
            onKeyPress: (key) => {
              if (
                key === 'Backspace' &&
                !a.value &&
                answers.filter((ans) => !ans.isNew).length > 2
              ) {
                const _answers = answers.filter((value, index) => index !== i);
                if (_answers.length < 4 && !_answers.some((ans) => ans.isNew)) {
                  _answers.push({ value: '', isNew: true });
                }
                setAnswers(_answers);
              }
            },
            onChangeText: (v) => {
              const _answers = [...answers];
              _answers[i] = { value: v, isNew: false };

              if (i === answers.length - 1 && answers.length < 4 && !!v) {
                _answers.push({ value: '', isNew: true });
              }
              setAnswers(_answers);
            },
            onSubmitEditing: () => {
              if (i !== answers.length - 1) {
                answersInputsRefs.current[i + 1].focus();
              }
            },
          });
        })}
      </Section>
    </ScreenView>
  );
};

export default ArtistPollCreateScreen;
