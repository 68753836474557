import styled from 'styled-components/native';

interface Props {
  containerWidth?: number;
}

export default styled.View<Props>`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;

  width: ${({ containerWidth }) => (containerWidth ? `${containerWidth - 70}px` : 'auto')};
`;
