export default {
  error: {
    default: {
      id: 'apexx.payment.error.default',
      defaultMessage: 'There was an error during payment',
    },
    tryAgain: {
      id: 'apexx.payment.error.tryAgain',
      defaultMessage: 'Please try again later or use another card',
    },
    useAnotherCard: {
      id: 'apexx.payment.error.useAnotherCard',
      defaultMessage: 'Please use another card',
    },
  },
};
