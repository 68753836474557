import AppTheme from './theme';

const lightTheme: AppTheme = {
  isDark: false,
  colors: {
    artistAccent: 'rgba(5, 55, 255, 1)',
    background: '#cccccc',
    backgroundBlue: 'rgb(5,55,255)',
    backgroundLight: '#9E9E9E',
    backgroundDark: 'rgba(255, 255, 255, 0.09)',
    backgroundDarker: 'rgba(255, 255, 255, 0.04)',
    backgroundHighlight: '#bbbbbb',
    text: '#333333',
    textActive: '#BCFFC9',
    hashtagText: '#bcfdc9',
    linkText: '#bcfdc9',
    primaryActive: '#333333',
    primaryInactive: 'rgba(51, 51, 51, 0.4)',
    primaryGhost: 'rgba(51, 51, 51, 0.24)',
    postsBackground: 'rgba(18, 18, 18, 0.6)',
    progressBackgorund: '#212545',
    progressStart: '#58aae0',
    progressEnd: '#6f52e2',
    payBackground: '#cccccc',
    errorAccent: '#FC440F',
    textWhite: '#ffffff',
  },
  card: {
    borderRadius: '14px',
    portraitRatio: 11 / 16,
    landscapeRatio: 16 / 11,
    background: '#292e43',
  },
  button: {
    backgroundColor: 'rgb(102,102,102)',
    backgroundColorBlue: '#0537FF',
    backgroundColorGhost: 'rgba(255, 255, 255, 0.12)',
    backgroundOverlayDark: 'rgba(18, 18, 18, 0.52)',
    backgroundOverlayLight: '#3e3e3e',
    text: '#fff',
    textGhost: 'rgba(255, 255, 255, 0.32)',
    textGhostSecondary: 'rgba(255, 255, 255, 0.4)',
    modalGhost: 'rgba(41, 46, 67, 1)',
    modalSolid: 'rgba(41, 46, 67, 1)',
  },
  buttonWhitelabel: {
    backgroundColor: 'transparent',
    text: '#333',
  },
  searchBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    iconColor: 'rgba(0, 0, 0, 0.52)',
  },
  textInput: {
    background: '#ffffff',
    borderColor: 'rgba(221, 216, 210, 0.2)',
    text: '#2c2c2c',
    valid: '#1DFF78',
  },
  mediaPicker: {
    border: '#9e9e9e',
  },
  modalSelector: {
    cancelBackground: 'rgb(44,44,46)',
    optionBackground: 'rgb(79,79,79)',
  },
  optionsGroup: {
    background: '#ffffff',
    text: '#333333',
    descriptionText: '#9e9e9e',
  },
  profileAvatar: {
    actionBackground: '#f5f5f5',
  },
  lockedItem: {
    actionBackground: 'rgba(254, 254, 254, 0.12)',
    actionText: '#ffffff',
    lockBackground: 'rgba(0, 26, 100, 0.5)',
  },
  notificationsGroup: {
    indicatorBackground: '#1DFF78',
    unreadBackground: 'rgba(46, 81, 223, 0.16)',
    readBackground: '#161c33',
    border: 'rgba(245, 245, 245, 0.12)',
  },
  pollContent: {
    primary: '#ffffff',
    active: '#121212',
    background: '#272934',
    answer: '#5b5c65',
  },
  tabBar: {
    background: '#161a2a',
  },
  holdingPage: {
    textPrimary: 'rgba(255, 255, 255, 0.87)',
    textSecondary: 'rgba(255, 255, 255, 0.52)',
  },
  bottomSheet: {
    background: '#292e43',
    text: '#ffffff',
    secondText: 'rgba(255, 255, 255, 0.52)',
    textButton: 'rgba(255, 255, 255, 0.38)',
    badge: '#fc440f',
    activeDot: '#ffffff',
    unactiveDot: 'rgba(255, 255, 255, 0.52)',
    input: {
      background: 'rgba(255, 255, 255, 0.08)',
      border: 'rgba(255, 255, 255, 0.16)',
    },
  },
  webview: {
    background: '#0537FF',
    text: '#222222',
  },
  artistDashboard: {
    primary: '#ffffff',
    brighterText: 'rgba(255, 255, 255, 0.87)',
    text: 'rgba(255, 255, 255, 0.52)',
    dashedLine: 'rgba(255, 255, 255, 0.24)',
    percent: '#1DFF78',
    graphLine: '#495AFF',
    background: 'rgba(255, 255, 255, 0.09)',
    buttonBackground: '#0537FF',
  },
  orderStatus: {
    waitingBg: 'rgba(255, 202, 185, 0.16)',
    waitingText: '#FFCAB9',
    inprogressBg: 'rgba(255, 205, 5, 0.16)',
    inprogressText: '#FFCD05',
    purchasedBg: 'rgba(199, 198, 255, 0.16)',
    purchasedText: '#C7C6FF',
    completedBg: 'rgba(29, 255, 120, 0.16)',
    completedText: '#1DFF78',
    declinedBg: 'rgba(252, 68, 15, 0.16)',
    declinedText: '#FC440F',
  },
  orderPreview: {
    highlightMark: '#1DFF78',
    highlightMarkSecondary: '#FC440F',
    imageBg: 'rgba(255, 255, 255, 0.09)',
    primaryText: '#ffffff',
    secondaryText: '#9e9e9e',
  },
  orderDetailsHeader: {
    primaryText: 'rgba(255, 255, 255, 0.87)',
    secondaryText: '#9E9E9E',
    border: 'rgba(158, 158, 158, 0.24)',
  },
  orderSectionTitle: {
    primaryText: '#ffffff',
  },
  orderAttachment: {
    primaryText: '#ffffff',
    secondaryText: 'rgba(255, 255, 255, 0.52)',
  },
  marketplace: {
    decoratedTitleBg: 'rgba(255, 255, 255, 0.12)',
    decoratedTitleText: '#ffffff',
    border: 'rgba(158, 158, 158, 0.24)',
  },
  marketplaceOrderDetails: {
    actionBg: '#0537FF',
    actionText: '#ffffff',
  },
  marketplaceProductDetails: {
    primaryText: '#ffffff',
    textGhost: 'rgba(255, 255, 255, 0.64)',
  },
  snackbar: {
    background: '#fafafa',
    cta: 'rgba(5, 55, 255, 1)',
  },
  paymentSheet: {
    highlightBackground: '#1DFF78',
    highlightText: '#121212',
  },
  insights: {
    primary: '#ffffff',
    background: 'rgba(255, 255, 255, 0.09)',
    greenText: '#BCFFC9',
    plusPercent: '#1DFF78',
    minusPercent: '#FF673A',
    horizontalChart: '#9EA1FF',
    redButton: '#FC440F',
    pieChart: {
      women: '#707BFF',
      men: '#9EA1FF',
      unknown: '#C7C6FF',
    },
  },
  continueWith: {
    background: '#192143',
  },
  userAvatar: {
    border: 'rgba(255, 255, 255, 0.52)',
  },
  artistSearch: {
    background: '#2E3554',
  },
  onBoarding: {
    description: '#f5f5f5',
  },
  audioNote: {
    wave: '#23293f',
  },
};

export default lightTheme;
