import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { SCREEN_NAME as FAN_DISCOVER_STACK_NAME } from 'stacks/fan/FanDiscoverStack/constants';
import { SCREEN_NAME as FAN_TAB_STACK_NAME } from 'stacks/fan/FanTabStack/constants';

import { SCREEN_NAME as DISCOVER_SCREEN_NAME } from 'screens/DiscoverScreen/constants';
import { SCREEN_NAME as SEARCH_AND_DISCOVERY_SCREEN_NAME } from 'screens/SearchAndDiscoveryScreen/constants';

import messages from './messages';
import Button from './styled/Button';
import Icon from './styled/Icon';
import Text from './styled/Text';
import View from './styled/View';

interface EmptyFeedViewProps {}

const EmptyFeedView: React.FC<EmptyFeedViewProps> = ({ ...restProps }) => {
  const intl = useIntl();
  const navigation = useNavigation();

  const onCtaPress = () => {
    if (Platform.OS !== 'web') {
      return navigation.navigate(FAN_TAB_STACK_NAME, {
        screen: FAN_DISCOVER_STACK_NAME,
        params: { screen: DISCOVER_SCREEN_NAME },
      });
    }
    navigation.navigate(SEARCH_AND_DISCOVERY_SCREEN_NAME);
  };

  return (
    <View {...restProps}>
      <Icon />
      <Text>{intl.formatMessage(messages.message)}</Text>
      <Button onPress={onCtaPress}>{intl.formatMessage(messages.ctaLabel)}</Button>
    </View>
  );
};

export default EmptyFeedView;
