import styled, { css } from 'styled-components/native';

export default styled.View`
  ${({ theme }) =>
    theme.media.tablet &&
    css`
      align-items: center;
      width: 400px;
      align-self: center;
      flex: 1;
      justify-content: center;
      margin-top: -155px;
    `}
`;
