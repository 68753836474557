import { css } from 'styled-components';
import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button)`
  border-radius: 40px;
  margin: 5px 0;
  width: 90%;
  ${({ alternative, theme }) =>
    alternative &&
    css`
      background-color: ${theme.button.backgroundColorBlue};
    `}
`;
