import { Dimensions } from 'react-native';

import { ArtistProduct, PostAspect, PreviewImage, ProductType, ProductTypePlural } from 'types';

export function getAspect(product: ArtistProduct): PostAspect {
  const width = product.high_res_cover_width || product.high_res_blurred_cover_width || 0;
  const height = product.high_res_cover_height || product.high_res_blurred_cover_height || 0;

  if (width > height) {
    return 'landscape';
  } else if (width < height) {
    return 'portrait';
  }
  return 'square';
}

export function pickPreviewImage(product: ArtistProduct, highRes = false): PreviewImage {
  const fallback = {
    isBlurred: false,
    url: product.artist?.profile_picture as string,
    width: undefined,
    height: undefined,
  };

  if (
    product.has_access ||
    product.model_name === 'video' ||
    product.model_name === 'marketplaceitem'
  ) {
    if (highRes) {
      if (product.high_res_cover) {
        return {
          isBlurred: false,
          url: product.high_res_cover,
          width: product.high_res_cover_width,
          height: product.high_res_cover_height,
        };
      } else {
        return fallback;
      }
    } else {
      if (product.low_res_cover) {
        return {
          isBlurred: false,
          url: product.low_res_cover,
          width: product.low_res_cover_width,
          height: product.low_res_cover_height,
        };
      } else {
        return fallback;
      }
    }
  } else {
    if (highRes) {
      if (product.high_res_blurred_cover) {
        return {
          isBlurred: true,
          url: product.high_res_blurred_cover,
          width: product.high_res_blurred_cover_width,
          height: product.high_res_blurred_cover_height,
        };
      } else {
        return fallback;
      }
    } else {
      if (product.low_res_blurred_cover) {
        return {
          isBlurred: true,
          url: product.low_res_blurred_cover,
          width: product.low_res_blurred_cover_width,
          height: product.low_res_blurred_cover_height,
        };
      } else {
        return fallback;
      }
    }
  }
}

export const countDefaultPreviewWidth = (): number => Dimensions.get('screen').width * 0.5 * 0.6;

export const getModelNamePlural = (modelName: ProductType): ProductTypePlural => {
  return modelName == 'marketplaceitem'
    ? 'marketplace_items'
    : (`${modelName}s` as ProductTypePlural);
};
