import styled, { css } from 'styled-components/native';

import FullscreenButton from 'components/FullscreenButton';

interface Props {
  showProgressBar?: boolean;
}

export default styled(FullscreenButton)<Props>`
  position: absolute;

  ${({ showProgressBar }) =>
    showProgressBar
      ? css`
          left: 15px;
          bottom: 15px;
        `
      : css`
          top: 7px;
          left: 7px;
        `}
`;
