import React, { useCallback, useRef, useState } from 'react';

import ModalActivityIndicator from 'components/ModalActivityIndicator';
import ModalOptions, { ModalOption } from 'components/ModalOptions';

interface ModalsState {
  requestChoice: (
    options: ModalOption[],
    dismissOption?: ModalOption,
  ) => Promise<ModalOption | null>;
  showActivityIndicator: () => void;
  hideActivityIndicator: () => void;
}

const DEFAULT_STATE: ModalsState = {
  requestChoice: () => Promise.resolve(null),
  showActivityIndicator: () => {},
  hideActivityIndicator: () => {},
};

const ModalsContext = React.createContext<ModalsState>(DEFAULT_STATE);

export function useModals(): ModalsState {
  const context = React.useContext<ModalsState>(ModalsContext);
  if (context === undefined) {
    throw new Error('useModals must be used within a ModalsProvider');
  }

  return context;
}

interface ModalsProviderProps {
  children: React.ReactNode;
}

export const ModalsProvider: React.FC<ModalsProviderProps> = ({
  children,
}: ModalsProviderProps) => {
  const [options, setOptions] = useState<ModalOption[]>([]);
  const [dismissOption, setDismissOption] = useState<ModalOption>();
  const [isOpen, setIsOpen] = useState(false);
  const [numActivities, setNumActivities] = useState(0);
  const currentResolve = useRef<(result: ModalOption) => void>();
  const currentReject = useRef<() => void>();

  const requestChoice = useCallback(
    (options: ModalOption[], dismissOption?: ModalOption) => {
      if (isOpen) {
        return Promise.resolve(null);
      }

      return new Promise<ModalOption>((resolve, reject) => {
        currentResolve.current = resolve;
        currentReject.current = reject;
        setOptions(options);
        setDismissOption(dismissOption);
        setIsOpen(true);
      });
    },
    [
      isOpen,
      currentResolve.current,
      currentReject.current,
      setOptions,
      setDismissOption,
      setIsOpen,
    ],
  );

  const showActivityIndicator = useCallback(
    () => setNumActivities(numActivities + 1),
    [setNumActivities, numActivities],
  );

  const hideActivityIndicator = useCallback(
    () => setNumActivities(Math.max(numActivities - 1, 0)),
    [setNumActivities, numActivities],
  );

  const onOptionPress = useCallback(
    (option: ModalOption) => {
      setIsOpen(false);
      currentResolve.current?.(option);
    },
    [setIsOpen, currentResolve.current],
  );

  const onDismiss = useCallback(() => {
    setIsOpen(false);
    currentReject.current?.();
  }, [setIsOpen, currentReject.current]);

  return (
    <ModalsContext.Provider
      value={{
        requestChoice,
        showActivityIndicator,
        hideActivityIndicator,
      }}
    >
      {children}
      <ModalOptions
        options={options}
        dismissOption={dismissOption}
        isVisible={isOpen}
        onOptionPress={onOptionPress}
        onDismiss={onDismiss}
      />
      <ModalActivityIndicator isVisible={numActivities > 0} />
    </ModalsContext.Provider>
  );
};
