import styled from 'styled-components/native';

export default styled.View`
  border-radius: ${({ theme }) => theme.card.borderRadius};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
