import { PathConfigMap } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import ArtistNotificationsStack, {
  linking as artistNotificationsStackLinking,
  SCREEN_NAME as ARTIST_NOTIFICATIONS_STACK_SCREEN_NAME,
} from 'stacks/artist/ArtistNotificationsStack';
import ArtistSettingsStack, {
  linking as artistSettingsStackLinking,
  SCREEN_NAME as ARTIST_SETTINGS_STACK_SCREEN_NAME,
} from 'stacks/artist/ArtistSettingsStack';
import FanHomeStack, {
  linking as fanHomeStackLinking,
  SCREEN_NAME as HOME_STACK_SCREEN_NAME,
} from 'stacks/fan/FanHomeStack';

import ArtistMarketplaceScreen, {
  linking as artistMarketplaceStackLinking,
  SCREEN_NAME as MARKETPLACE_SCREEN_NAME,
} from 'screens/AritstMarketplaceScreen';

import IconNotifications from 'components/FeedTopNav/styled/NotificationsButton';
import IconMarketplace from 'components/Icons/IconMarketplace';
import More from 'components/More';
import Tab from 'components/TabNavigator';

import { useNotificationsContext } from 'context/notifications';
import { Home as IconHome } from 'svg';

import { SCREEN_NAME } from './constants';
import IconProfile from './styled/IconProfile';

export { SCREEN_NAME };

export const pathConfigMap: PathConfigMap = {
  [HOME_STACK_SCREEN_NAME]: {
    path: 'home',
    screens: fanHomeStackLinking,
  },
  [ARTIST_NOTIFICATIONS_STACK_SCREEN_NAME]: {
    path: 'notifications',
    screens: artistNotificationsStackLinking,
  },
  [MARKETPLACE_SCREEN_NAME]: {
    path: 'marketplace',
    screens: artistMarketplaceStackLinking,
  },
  [ARTIST_SETTINGS_STACK_SCREEN_NAME]: {
    path: 'settings',
    screens: artistSettingsStackLinking,
  },
};

interface Props {}

const EmptyScreen: React.FC = () => null;

const ArtistTabStack: React.FC<Props> = () => {
  const intl = useIntl();
  const notifications = useNotificationsContext();

  const getIconProps = (focused: boolean) => {
    return {
      stroke: focused ? '#fff' : 'rgba(255,255,255,0.15)',
      fill: focused ? '#fff' : 'none',
    };
  };

  return (
    <Tab.Navigator initialRouteName={HOME_STACK_SCREEN_NAME}>
      <Tab.Screen
        name={HOME_STACK_SCREEN_NAME}
        component={FanHomeStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.home',
            defaultMessage: 'Home',
          }),
          tabBarIcon: function tabBarIcon({ focused }) {
            return <IconHome {...getIconProps(focused)} />;
          },
        }}
      />
      <Tab.Screen
        name={MARKETPLACE_SCREEN_NAME}
        component={ArtistMarketplaceScreen}
        options={{
          title: intl.formatMessage({
            id: 'tab.marketplace',
            defaultMessage: 'Marketplace',
          }),
          tabBarIcon: function tabBarIcon(iconProps) {
            return (
              <IconMarketplace
                hasNotifications={
                  notifications.marketplaceOrder || notifications.marketplaceMessage
                }
                {...iconProps}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name={'More'}
        component={EmptyScreen}
        options={{
          tabBarButton: function ArtistTabBarIconMore() {
            return (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <More />
              </View>
            );
          },
        }}
      />
      <Tab.Screen
        name={ARTIST_NOTIFICATIONS_STACK_SCREEN_NAME}
        component={ArtistNotificationsStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.notifications',
            defaultMessage: 'Notifications',
          }),
          tabBarIcon: function tabBarIcon({ focused }) {
            return (
              <IconNotifications
                hasNotifications={notifications.general}
                {...getIconProps(focused)}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name={ARTIST_SETTINGS_STACK_SCREEN_NAME}
        component={ArtistSettingsStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.profile',
            defaultMessage: 'Profile',
          }),
          tabBarIcon: function TabBarIconProfile(iconProps) {
            return <IconProfile {...iconProps} />;
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default ArtistTabStack;
