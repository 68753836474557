import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Fade from 'components/Transitions/Fade/Fade';

export default styled(Fade)`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  ${Platform.OS !== 'web' &&
  css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;
