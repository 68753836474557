export default {
  title: {
    selection: { id: 'tipSheet.title.selection', defaultMessage: 'Tip post' },
    payment: {
      id: 'tipSheet.title.payment',
      defaultMessage: 'Choose payment method',
    },
  },
  button: {
    id: 'tipSheet.button',
    defaultMessage: 'Continue',
  },
  buttonNext: {
    id: 'tipSheet.button.next',
    defaultMessage: 'Next',
  },
  inputPlaceholder: {
    id: 'tipSheet.input.placeholder',
    defaultMessage: 'Price',
  },
  customAmountTitle: {
    id: 'tipSheet.amount.title',
    defaultMessage: 'Your proposition',
  },
  completedTipTitle: {
    id: 'tipSheet.completed.title',
    defaultMessage: 'Thanks!',
  },
  completedTipDescription: {
    id: 'tipSheet.completed.description',
    defaultMessage: 'You will find your order in Purchased tab.',
  },
  errorAlertTitle: {
    id: 'tipSheet.error.title',
    defaultMessage: 'Invalid value',
  },
  errorAlertDescription: {
    id: 'tipSheet.error.description',
    defaultMessage: 'Given value is not a valid number.',
  },
};
