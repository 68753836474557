import React from 'react';
import styled from 'styled-components/native';

import CachedImage from 'components/CachedImage';

const Image = styled(CachedImage)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

const AvatarWrapper = styled.View`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  bottom: -6px;
  right: -6px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.52);
  background-color: rgba(255, 255, 255, 0.52);
`;

const AvatarImage = styled(CachedImage)`
  flex: 1;
`;

const View = styled.View`
  width: 45px;
  height: 45px;
  align-self: center;
  background: ${({ theme }) => theme.orderPreview.imageBg};
  border-radius: 6px;
`;

interface ImageProps {
  cover: string;
  avatar: string;
}

const CoverWithAvatar: React.FC<ImageProps> = ({ avatar, cover }: ImageProps) => {
  return (
    <View>
      <Image source={{ uri: cover }} />
      <AvatarWrapper>
        <AvatarImage source={{ uri: avatar }} />
      </AvatarWrapper>
    </View>
  );
};

export default CoverWithAvatar;
