import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const Icon = (props: any) => <FontAwesome name="apple" size={16} color="black" {...props} />;

export default styled(Icon)`
  color: ${({ theme }) => theme.colors.text};
  margin-right: 8px;
`;
