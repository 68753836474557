import React, { useEffect, useRef } from 'react';

import * as CardinalWeb from 'CardinalWeb';

interface ValidationResponseData {
  Validated: boolean;
  Payment: {
    Type: 'CCA' | 'Paypal' | 'Wallet' | 'VisaCheckout' | 'ApplePay' | 'DiscoverWallet';
    ProcessorTransactionId: string;
    ExtendedData: {
      Enrolled: 'Y' | 'N' | 'U' | 'B';
      CAVV: string;
      ECIFlag: string;
      ThreeDSVersion: '1.0' | '2.0' | '1.0.2' | '2.1.0' | '2.2.0';
      PAResStatus: 'Y' | 'N' | 'U' | 'A';
      SignatureVerification: 'Y' | 'N';
      XID: string;
    };
  };
  ActionCode: 'SUCCESS' | 'NOACTION' | 'FAILURE' | 'ERROR';
  ErrorNumber: number;
  ErrorDescription: string;
}

interface CCAPayload {
  acs_url: string;
  pareq: string;
  authentication_transaction_id: string;
}

interface AuthorizationResponse {
  paResStatus: string;
  eciFlag: string;
  cavv: string;
  xid: string;
  jwt: string;
}

interface CardinalState {
  cardinalWebInit: (encodedCardinalJwt: string) => Promise<string>;
  cardinalWebCCA: (payload: CCAPayload) => Promise<AuthorizationResponse>;
  cardinalBinProcess: (cardNumber: string) => Promise<{ Status: boolean }>;
}

const DEFAULT_STATE: CardinalState = {
  cardinalWebInit: () => new Promise((resolve) => resolve('')),
  cardinalWebCCA: () =>
    new Promise((resolve) => resolve({ cavv: '', eciFlag: '', jwt: '', paResStatus: '', xid: '' })),
  cardinalBinProcess: () => new Promise((resolve) => resolve({ Status: true })),
};

const CardinalStateContext = React.createContext<CardinalState>(DEFAULT_STATE);

export function useCardinalState(): CardinalState {
  const context = React.useContext<CardinalState>(CardinalStateContext);
  if (context === undefined) {
    throw new Error('useCardinalState must be used within a CardinalProvider');
  }

  return context;
}

interface Props {
  children: React.ReactNode;
}

export const CardinalProvider: React.FC<Props> = ({ children }: Props) => {
  const initResolveRef = useRef<(value: string) => void>();
  const ccaResolveRef = useRef<(value: AuthorizationResponse) => void>();
  const ccaRejectRef = useRef<(value: string) => void>();

  const cardinalWebInit = async (encodedCardinalJwt: string): Promise<string> => {
    return new Promise((resolve) => {
      initResolveRef.current = resolve;
      CardinalWeb.setup('init', {
        jwt: encodedCardinalJwt,
      });
    });
  };

  const cardinalBinProcess = async (cardNumber: string) => {
    return await CardinalWeb.trigger('bin.process', cardNumber);
  };

  const cardinalWebCCA = async ({
    acs_url,
    authentication_transaction_id,
    pareq,
  }: CCAPayload): Promise<AuthorizationResponse> => {
    return new Promise((resolve, reject) => {
      ccaResolveRef.current = resolve;
      ccaRejectRef.current = reject;
      CardinalWeb.continue(
        'cca',
        {
          AcsUrl: acs_url,
          Payload: pareq,
        },
        {
          OrderDetails: {
            TransactionId: authentication_transaction_id,
          },
        },
      );
    });
  };

  useEffect(() => {
    const cardinalWebInit = async () => {
      CardinalWeb.configure();

      CardinalWeb.on('payments.setupComplete', (setupCompleteData: { sessionId: string }) => {
        console.log('CARDINAL SETUP COMPLETE', setupCompleteData);
        initResolveRef.current?.(setupCompleteData.sessionId);
      });

      CardinalWeb.on('payments.validated', function (data: ValidationResponseData, jwt: string) {
        console.log('PAYMENT VALIDATED', data);
        let message = '';
        switch (data.ActionCode) {
          case 'SUCCESS':
          case 'NOACTION':
            // Handle no actionable outcome or successful transaction, send JWT to backend to verify
            console.log('Transaction succeeded');
            ccaResolveRef.current?.({
              jwt,
              cavv: data.Payment.ExtendedData.CAVV,
              eciFlag: data.Payment.ExtendedData.ECIFlag,
              paResStatus: data.Payment.ExtendedData.PAResStatus,
              xid: data.Payment.ExtendedData.XID,
            });
            break;
          case 'FAILURE':
            // Handle failed transaction attempt
            message = 'Transaction failed attempt';
            console.log(message);
            ccaRejectRef.current?.(message);
            break;
          case 'ERROR':
            // Handle service level error
            message = 'Transaction error';
            console.log(message);
            ccaRejectRef.current?.(message);
            break;
        }
      });
    };
    cardinalWebInit();
  }, []);

  return (
    <CardinalStateContext.Provider value={{ cardinalWebInit, cardinalWebCCA, cardinalBinProcess }}>
      {children}
    </CardinalStateContext.Provider>
  );
};
