export default {
  exclamationDefaultText: {
    id: 'fetchedDataContainer.exclamationDefaultText',
    defaultMessage: 'Could not fetch data.',
  },
  emptyDefaultText: {
    id: 'fetchedDataContainer.emptyDefaultText',
    defaultMessage: 'There is no data.',
  },
};
