import styled from 'styled-components/native';

export default styled.View`
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background-color: ${({ theme }) => theme.textInput.valid};
  position: absolute;
  left: 7px;
`;
