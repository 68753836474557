import { memo } from 'react';
import styled from 'styled-components/native';

import FeedItemControls from 'components/FeedItemControls';

export default memo(styled(FeedItemControls)`
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 12px;
  z-index: 25;
`);
