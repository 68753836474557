import { useNavigation } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { Platform } from 'react-native';

import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';

import FetchedDataContainer from 'components/FetchedDataContainer';
import MarketplaceProducts from 'components/MarketplaceProducts';
import WebContainer from 'components/WebContainer';

import { useFollowedArtistsMarketplaceItems } from 'hooks/marketplace';
import { MarketplaceItem } from 'types';

import { SCREEN_NAME } from './constants';

export { SCREEN_NAME };

const MarketplaceAvailableItemsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { items, isLoading, error, refresh } = useFollowedArtistsMarketplaceItems();

  useFocusEffect(
    React.useCallback(() => {
      refresh?.();
    }, [refresh]),
  );

  const navigateToOrder = (order: MarketplaceItem) =>
    navigation.navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
      productId: order.id,
      artist: order.artist.username,
    });

  const list = () => (
    <FetchedDataContainer isLoading={isLoading} isError={!!error}>
      {items && <MarketplaceProducts view="grid" data={items} onProductPress={navigateToOrder} />}
    </FetchedDataContainer>
  );

  return Platform.OS === 'web' ? <WebContainer fullWidth>{list()}</WebContainer> : list();
};

export default MarketplaceAvailableItemsScreen;
