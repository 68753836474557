import React from 'react';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';

const SvgComponent: React.FC = styled(({ ...rest }) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
      <Path d="M23.5 7H0.5V16.5H23.5V7Z" fill="#E6E6E6" />
      <Path
        d="M2 20.5H22C22.8285 20.5 23.5 19.8285 23.5 19V16.5H0.5V19C0.5 19.8285 1.1715 20.5 2 20.5Z"
        fill="#E79800"
      />
      <Path
        d="M22 3.5H2C1.1715 3.5 0.5 4.1715 0.5 5V7.5H23.5V5C23.5 4.1715 22.8285 3.5 22 3.5Z"
        fill="#1A1876"
      />
      <Path d="M9.61902 14.3998H10.9235L11.74 9.61182H10.435L9.61902 14.3998Z" fill="#1A1876" />
      <Path
        d="M14.3715 11.5343C13.9155 11.3128 13.636 11.1648 13.639 10.9408C13.639 10.7418 13.8755 10.5288 14.3865 10.5288C14.8045 10.5223 15.1415 10.6073 15.4805 10.7673L15.6575 9.72932C15.399 9.63232 14.994 9.52832 14.488 9.52832C13.1985 9.52832 12.29 10.1778 12.2825 11.1083C12.275 11.7963 12.931 12.1803 13.426 12.4093C13.934 12.6438 14.105 12.7938 14.1025 13.0033C14.0995 13.3243 13.697 13.4708 13.3215 13.4708C12.7425 13.4708 12.4505 13.3813 11.925 13.1433L11.742 14.2153C12.047 14.3488 12.6105 14.4648 13.196 14.4708C14.568 14.4708 15.4585 13.8288 15.4685 12.8348C15.472 12.2903 15.1245 11.8758 14.3715 11.5343Z"
        fill="#1A1876"
      />
      <Path
        d="M19.0035 9.6167H17.995C17.6825 9.6167 17.449 9.7022 17.3115 10.0137L15.3735 14.4017H16.744C16.744 14.4017 16.968 13.8117 17.019 13.6822C17.169 13.6822 18.5 13.6842 18.6905 13.6842C18.7295 13.8517 18.8495 14.4017 18.8495 14.4017H20.0605L19.0035 9.6167ZM17.3945 12.7032C17.4485 12.5652 17.981 11.1977 18.0875 10.9077C18.264 11.7332 18.092 10.9322 18.478 12.7032H17.3945Z"
        fill="#1A1876"
      />
      <Path
        d="M8.5245 9.6153L7.2465 12.8803L7.1105 12.2168L6.653 10.0163C6.574 9.7133 6.345 9.6228 6.0615 9.6123H3.9565L3.94 9.7118C4.452 9.8358 4.9095 10.0148 5.311 10.2368L6.4715 14.3953L7.8525 14.3938L9.907 9.6148H8.5245V9.6153Z"
        fill="#1A1876"
      />
    </Svg>
  );
})`
  margin-right: 5px;
`;

export default SvgComponent;
