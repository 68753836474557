export default {
  title: {
    id: 'artist.marketplace.screen.title',
    defaultMessage: 'Your Stan Store',
  },
  // products
  products: {
    id: 'artist.marketplace.tab.products.title',
    defaultMessage: 'Products',
  },
  noProducts: {
    id: 'artist.marketplace.tab.products.noProducts',
    defaultMessage: "You're not offering your fans any products yet.",
  },
  addProduct: {
    id: 'artist.marketplace.tab.products.addProduct',
    defaultMessage: 'Add product',
  },
  // ongoingOrders
  ongoingOrders: {
    id: 'artist.marketplace.tab.ongoingOrders.title',
    defaultMessage: 'Ongoing orders',
  },
  noOngoingOrders: {
    id: 'artist.marketplace.tab.ongoingOrders.noOngoingOrders',
    defaultMessage: 'There is no ongoing orders at the moment.',
  },
  // closedOrders
  closedOrders: {
    id: 'artist.marketplace.tab.closedOrders.title',
    defaultMessage: 'Closed orders',
  },
  noClosedOrders: {
    id: 'artist.marketplace.tab.closedOrders.noClosedOrders',
    defaultMessage: 'You don’t have closed orders yet.',
  },
};
