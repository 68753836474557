import styled, { css } from 'styled-components/native';

export default styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 5px;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      margin-bottom: 12px;
    `}
`;
