import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import TabBarIcon from 'components/TabBarIcon';

interface IconProps extends SvgProps {
  focused?: boolean;
}

function SvgComponent(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M18 20V10"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 20V4"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6 20V14"
        stroke="#fff"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledIconActive = styled(SvgComponent)`
  opacity: 1;
`;

const StyledIconInactive = styled(SvgComponent)`
  opacity: 0.52;
`;

const Icon = ({ focused, ...rest }: IconProps) => (
  <TabBarIcon
    focused={!!focused}
    iconActive={<StyledIconActive />}
    iconInactive={<StyledIconInactive />}
    {...rest}
  />
);

const MemoIcon = React.memo(Icon);
export default MemoIcon;
