import { Entypo } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Animated, Dimensions, LayoutChangeEvent, Platform, View } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  PanGestureHandlerStateChangeEvent,
  State,
} from 'react-native-gesture-handler';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_AUDIO_RECORD_SCREEN_NAME } from 'screens/ArtistAudioRecordScreen';

import ScreenView from 'containers/ModalScreen';

import NavigationHeader from 'components/NavigationHeader';

import { useAudio } from 'hooks';
import { useTheme } from 'themes';
import { milliSecondsToTimeString } from 'utils/time';

import { SCREEN_NAME } from './constants';
import PlayerControls from './styled/PlayerControls';
import Subtitle from './styled/Subtitle';
import Timeline from './styled/Timeline';
import Title from './styled/Title';
import Waveform from './styled/Waveform';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistAudioTrimScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { theme } = useTheme();

  const bottomTrimmerHeight = 70;
  const [waveformTrimWidth, setWaveformTrimWidth] = useState(1);
  const [trimmingSide, setTrimmingSide] = useState<string>();
  const leftTrimPositionAnim = useRef(new Animated.Value(0)).current;
  const trimFromX = useRef(0);
  const rightTrimPositionAnim = useRef(new Animated.Value(0)).current;
  const trimToX = useRef(waveformTrimWidth);
  const [trimmingAreaPosition, setTrimmingAreaPosition] = useState({
    left: 0,
    right: waveformTrimWidth,
  });

  const [trimmedAudioFile, setTrimmedAudioFile] = useState<Blob>();
  const [trimmedAudioPath, setTrimmedAudioPath] = useState<string>();
  const [onPlaybackStatusUpdateStateUpdater, setOnPlaybackStatusUpdateStateUpdater] = useState(0);
  const [playerControlsUpdater, setPlayerControlsUpdater] = useState(0);

  const playbackOffsetAnimated = useRef(new Animated.Value(0)).current;
  const currentPositionX = useRef(0);
  const [shouldPlayAfterSeek, setShouldPlayAfterSeek] = useState(false);
  const soundUpdateInterval = 250;
  const {
    sound,
    isLoaded,
    durationMillis,
    playAudio,
    stopAudio,
    pauseAudio,
    setOnAudioStatusUpdate,
  } = useAudio(
    Platform.OS === 'web'
      ? URL.createObjectURL(trimmedAudioFile || route.params.file)
      : trimmedAudioPath || route.params.file,
    soundUpdateInterval,
  );

  const getWaveformWidth = useCallback(() => {
    if (durationMillis <= 10000) {
      return Dimensions.get('screen').width;
    } else {
      return Dimensions.get('screen').width * 2;
    }
  }, [durationMillis]);

  const waveformWidth = useMemo(() => getWaveformWidth(), [getWaveformWidth]);

  useEffect(() => {
    rightTrimPositionAnim.setValue(waveformTrimWidth);
    trimToX.current = waveformTrimWidth;
  }, [waveformTrimWidth, durationMillis]);

  useEffect(() => {
    leftTrimPositionAnim.addListener((x) => {
      trimFromX.current = x.value;
      if (isLoaded && durationMillis && durationMillis !== Infinity) {
        // stopAudio();
      }
    });
    return () => leftTrimPositionAnim.removeAllListeners();
  }, [waveformTrimWidth, durationMillis, isLoaded]);

  useEffect(() => {
    rightTrimPositionAnim.addListener((x) => {
      trimToX.current = x.value;
      if (isLoaded && durationMillis && durationMillis !== Infinity) {
        stopAudio();
      }
    });

    return () => rightTrimPositionAnim.removeAllListeners();
  }, [waveformTrimWidth, durationMillis, isLoaded]);

  // Bottom trimmer handlers

  const onTrimmerGestureStateChange = useCallback(
    ({ nativeEvent: { oldState, state, x } }: PanGestureHandlerStateChangeEvent) => {
      if (oldState === State.BEGAN && state === State.ACTIVE) {
        if (Math.abs(x - trimFromX.current) < 30) {
          setTrimmingSide('left');
        } else if (Math.abs(x - trimToX.current) < 30) {
          setTrimmingSide('right');
        } else if (x > trimFromX.current && x < trimToX.current) {
          setTrimmingSide('inside');
        }
      } else {
        setPlayerControlsUpdater(playerControlsUpdater + 1);
        setTrimmingSide(undefined);
        setTrimmingAreaPosition({
          left: trimFromX.current,
          right: trimToX.current,
        });
      }
    },
    [trimFromX.current, trimToX.current, playerControlsUpdater],
  );

  const onTrimmerPanGestureEvent = useCallback(
    ({ nativeEvent }: PanGestureHandlerGestureEvent) => {
      if (trimmingSide === 'left') {
        const stop = trimToX.current - 40;
        let value = nativeEvent.x;
        if (value > stop) {
          value = stop;
        } else if (value < 0) {
          value = 0;
        }
        Animated.timing(leftTrimPositionAnim, {
          toValue: value,
          duration: 1,
          useNativeDriver: false,
        }).start();
      } else if (trimmingSide === 'right') {
        const stop = waveformTrimWidth;
        let value = nativeEvent.x;
        if (value > stop) {
          value = stop;
        } else if (value < trimFromX.current + 40) {
          value = trimFromX.current + 40;
        }
        Animated.timing(rightTrimPositionAnim, {
          toValue: value,
          duration: 1,
          useNativeDriver: false,
        }).start();
      } else if (trimmingSide === 'inside') {
        const transX = nativeEvent.translationX;
        if (
          !(
            trimmingAreaPosition.left + transX < 0 ||
            trimmingAreaPosition.right + transX > waveformTrimWidth
          )
        ) {
          Animated.timing(rightTrimPositionAnim, {
            toValue: trimmingAreaPosition.right + transX,
            duration: 1,
            useNativeDriver: false,
          }).start();
          Animated.timing(leftTrimPositionAnim, {
            toValue: trimmingAreaPosition.left + transX,
            duration: 1,
            useNativeDriver: false,
          }).start();
        }
      }
      setOnPlaybackStatusUpdateStateUpdater(onPlaybackStatusUpdateStateUpdater + 1);
    },
    [trimmingSide, waveformTrimWidth, trimFromX.current, trimToX.current],
  );

  const onWaveformTrimLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setWaveformTrimWidth(nativeEvent.layout.width);
  }, []);

  // Playback handlers

  const getNewOffsetXPosition = (translationX: number) => {
    const ww = waveformWidth;
    let newPosition = currentPositionX.current + translationX;
    if (newPosition < -ww) {
      newPosition = -ww;
    } else if (newPosition > 0) {
      newPosition = 0;
    }
    return newPosition;
  };

  const onPlaybackPanGestureEvent = useCallback(
    ({ nativeEvent }: PanGestureHandlerGestureEvent) => {
      const newOffsetXPosition = getNewOffsetXPosition(nativeEvent.translationX);
      Animated.timing(playbackOffsetAnimated, {
        toValue: newOffsetXPosition,
        duration: 1,
        useNativeDriver: false,
      }).start();
    },
    [],
  );

  const onPlaybackGestureEventStateChange = useCallback(
    async ({
      nativeEvent: { oldState, state, translationX },
    }: PanGestureHandlerStateChangeEvent) => {
      if (oldState === State.BEGAN && state === State.ACTIVE) {
        if (((await sound.getStatusAsync()) as any).isPlaying) {
          setShouldPlayAfterSeek(true);
          await pauseAudio();
        }
      }
      if (oldState === State.ACTIVE && state === State.END) {
        const newOffsetXPosition = getNewOffsetXPosition(translationX);
        currentPositionX.current = newOffsetXPosition;
        const newPositionMillis = (-newOffsetXPosition / waveformWidth) * durationMillis;
        Animated.timing(playbackOffsetAnimated, {
          toValue: newOffsetXPosition,
          duration: 1,
          useNativeDriver: false,
        }).start();
        if (shouldPlayAfterSeek) {
          await playAudio(newPositionMillis);
          setShouldPlayAfterSeek(false);
        }
        await sound.setPositionAsync(newPositionMillis);
      }
    },
    [waveformWidth, durationMillis, shouldPlayAfterSeek],
  );

  const onTrimming = () => {
    trimFromX.current = 0;
    currentPositionX.current = 0;
    setPlayerControlsUpdater(playerControlsUpdater + 1);
    Animated.timing(playbackOffsetAnimated, {
      toValue: 0,
      duration: 1,
      useNativeDriver: false,
    }).start();
    Animated.timing(leftTrimPositionAnim, {
      toValue: 0,
      duration: 1,
      useNativeDriver: false,
    }).start();
    Animated.timing(rightTrimPositionAnim, {
      toValue: 0,
      duration: 1,
      useNativeDriver: false,
    }).start();
  };

  const onDelete = async () => {
    await stopAudio();
    trimFromX.current = 0;
    // await sound.setPositionAsync(0);
    currentPositionX.current = 0;
    trimToX.current = waveformTrimWidth;
    setOnPlaybackStatusUpdateStateUpdater(onPlaybackStatusUpdateStateUpdater + 1);
    setPlayerControlsUpdater(playerControlsUpdater + 1);
    Animated.timing(playbackOffsetAnimated, {
      toValue: 0,
      duration: 1,
      useNativeDriver: false,
    }).start();
    Animated.timing(leftTrimPositionAnim, {
      toValue: 0,
      duration: 1,
      useNativeDriver: false,
    }).start();
    Animated.timing(rightTrimPositionAnim, {
      toValue: waveformTrimWidth,
      duration: 1,
      useNativeDriver: false,
    }).start();
  };

  const rewind = (newPosition: number) => {
    currentPositionX.current = newPosition;
    sound.setPositionAsync((-newPosition / waveformWidth) * durationMillis);
    Animated.timing(playbackOffsetAnimated, {
      toValue: newPosition,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const onRewindBackward = () => {
    rewind(currentPositionX.current + (15000 / durationMillis) * waveformWidth);
  };

  const onRewindForward = () => {
    rewind(currentPositionX.current - (15000 / durationMillis) * waveformWidth);
  };

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          onGoingBack={() => stopAudio()}
          headerBackTitle={intl.formatMessage({
            id: 'post.cancel',
            defaultMessage: 'Cancel',
          })}
          headerBackImage={() => <View />}
          headerRightLabel={intl.formatMessage({
            id: 'post.audioNote.save',
            defaultMessage: 'Save',
          })}
          headerRightDisabled={!trimmedAudioPath}
          headerRightOnPress={() => {
            stopAudio();
            navigation.navigate(ARTIST_AUDIO_RECORD_SCREEN_NAME, {
              file: trimmedAudioPath,
            });
          }}
        />
      }
    >
      <View style={{ marginVertical: 20 }}>
        <Title>
          {intl.formatMessage({
            id: 'post.audioNote.title',
            defaultMessage: 'Audio note',
          })}
        </Title>
        <Subtitle>
          {intl.formatMessage({
            id: 'post.audioNote.duration',
            defaultMessage: 'Duration',
          })}
          : {milliSecondsToTimeString(durationMillis)}
        </Subtitle>
      </View>
      <View style={{ flex: 1 }}>
        {useMemo(
          () => (
            <PanGestureHandler
              onGestureEvent={onPlaybackPanGestureEvent}
              onHandlerStateChange={onPlaybackGestureEventStateChange}
            >
              <View
                style={{
                  width: Dimensions.get('screen').width,
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  marginLeft: -15,
                }}
              />
            </PanGestureHandler>
          ),
          [onPlaybackGestureEventStateChange, onPlaybackPanGestureEvent],
        )}
        <View style={{ flex: 1, marginHorizontal: -15, marginVertical: 20 }}>
          <View
            style={{
              position: 'absolute',
              left: Dimensions.get('screen').width / 2,
              borderLeftWidth: 1,
              borderColor: theme.colors.text,
              height: '100%',
              justifyContent: 'space-between',
              zIndex: 1,
            }}
          >
            <View
              style={{
                backgroundColor: theme.colors.text,
                width: 6,
                height: 6,
                borderRadius: 5,
                marginLeft: -3.5,
                marginTop: -6,
              }}
            />
            <View
              style={{
                backgroundColor: theme.colors.text,
                width: 6,
                height: 6,
                borderRadius: 5,
                marginLeft: -3.5,
                marginBottom: -6,
              }}
            />
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: theme.colors.background,
              overflow: 'visible',
              paddingHorizontal: Dimensions.get('screen').width / 2,
              justifyContent: 'center',
            }}
          >
            <Animated.View
              style={{
                width: waveformWidth,
                // height: 50,
                height: '100%',
                justifyContent: 'center',
                backgroundColor: theme.colors.backgroundHighlight,
                marginLeft: playbackOffsetAnimated,
              }}
            >
              <>
                {useMemo(() => {
                  console.log('[AudioNoteTrimScreen] main waveform render');
                  return (
                    <Waveform
                      style={{
                        height: 50,
                        width: '100%',
                      }}
                      barGap={2}
                      barWidth={2}
                      barColor={theme.colors.text}
                    />
                  );
                }, [])}
                {useMemo(() => {
                  // console.log("[AudioNoteTrimScreen] main waveform render");
                  // console.log("durationMillis", durationMillis);
                  // console.log("waveformWidth", waveformWidth);
                  return durationMillis ? (
                    <View style={{ position: 'absolute', bottom: -29 }}>
                      <Timeline width={waveformWidth} duration={durationMillis} />
                    </View>
                  ) : null;
                }, [durationMillis])}
              </>
            </Animated.View>
          </View>
        </View>
      </View>

      {/* Controls */}
      <View style={{ marginVertical: 20 }}>
        <PlayerControls
          sound={sound}
          setOnAudioStatusUpdate={setOnAudioStatusUpdate}
          stopAudio={stopAudio}
          pauseAudio={pauseAudio}
          playAudio={playAudio}
          file={route.params.file}
          isLoaded={isLoaded}
          durationMillis={durationMillis}
          waveformWidth={waveformWidth}
          playbackOffsetAnimated={playbackOffsetAnimated}
          trimFromMillis={Math.round((trimFromX.current / waveformTrimWidth) * durationMillis)}
          trimToMillis={Math.round((trimToX.current / waveformTrimWidth) * durationMillis)}
          currentPositionXRef={currentPositionX}
          setTrimmedAudioPath={setTrimmedAudioPath}
          setTrimmedAudioFile={setTrimmedAudioFile}
          trimmedAudioFile={trimmedAudioFile}
          trimmedAudioPath={trimmedAudioPath}
          onTrimming={onTrimming}
          onDelete={onDelete}
          soundUpdateInterval={soundUpdateInterval}
          onPlaybackStatusUpdateStateUpdater={onPlaybackStatusUpdateStateUpdater}
          playerControlsUpdater={playerControlsUpdater}
          onRewindBackward={onRewindBackward}
          onRewindForward={onRewindForward}
        />
      </View>

      {/* Bottom trimmer */}

      <View style={{ marginVertical: 22 }}>
        {useMemo(
          () => (
            <>
              <PanGestureHandler
                hitSlop={{ left: 15, right: 15, top: 20, bottom: 20 }}
                onGestureEvent={onTrimmerPanGestureEvent}
                onHandlerStateChange={onTrimmerGestureStateChange}
              >
                <View
                  style={{
                    top: -2,
                    width: waveformTrimWidth,
                    height: bottomTrimmerHeight,
                    position: 'absolute',
                    zIndex: 2,
                    opacity: 0.4,
                  }}
                />
              </PanGestureHandler>
              <Animated.View
                style={{
                  zIndex: 1,
                  top: -2,
                  position: 'absolute',
                  left: leftTrimPositionAnim,
                  right: Animated.subtract(
                    new Animated.Value(waveformTrimWidth),
                    rightTrimPositionAnim,
                  ),
                  height: bottomTrimmerHeight + 4,
                  width: Animated.subtract(
                    new Animated.Value(waveformTrimWidth),
                    Animated.add(
                      leftTrimPositionAnim,
                      Animated.subtract(
                        new Animated.Value(waveformTrimWidth),
                        rightTrimPositionAnim,
                      ),
                    ),
                  ),
                  borderWidth: 2,
                  borderColor: theme.colors.backgroundLight,
                  backgroundColor: 'rgba(150,150,150,0.3)',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    backgroundColor: theme.colors.backgroundLight,
                    width: 15,
                    justifyContent: 'center',
                  }}
                >
                  <Entypo
                    name="chevron-left"
                    color={theme.colors.background}
                    size={26}
                    style={{ marginLeft: -5 }}
                  />
                </View>
                <View
                  style={{
                    backgroundColor: theme.colors.backgroundLight,
                    width: 15,
                    justifyContent: 'center',
                  }}
                >
                  <Entypo
                    name="chevron-right"
                    color={theme.colors.background}
                    size={26}
                    style={{ marginLeft: -5 }}
                  />
                </View>
              </Animated.View>
            </>
          ),
          [
            trimmedAudioFile,
            trimmedAudioPath,
            waveformTrimWidth,
            onTrimmerPanGestureEvent,
            onTrimmerGestureStateChange,
          ],
        )}
        {useMemo(() => {
          console.log('[AudioNoteTrimScreen] trimming waveform render');
          return (
            <View style={{ zIndex: 0 }} onLayout={onWaveformTrimLayout}>
              <Waveform
                barWidth={1}
                barGap={1}
                barColor={'#fff'} //theme.colors.text
                style={{
                  height: bottomTrimmerHeight,
                  paddingVertical: 10, //bottomTrimmerPadding,
                  marginHorizontal: 18,
                }}
              />
            </View>
          );
        }, [onWaveformTrimLayout])}
      </View>
    </ScreenView>
  );
};

export default ArtistAudioTrimScreen;
