import { Animated } from 'react-native';
import styled from 'styled-components/native';

export default styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.background};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
`;
