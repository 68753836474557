import React from 'react';
import { ImageSourcePropType } from 'react-native';

import Image from './styled/Image';

interface Props {
  mediaWidth?: number;
  mediaHeight?: number;
  source: ImageSourcePropType;
  onLoad?: () => void;
}

const PinchableImage: React.FC<Props> = ({ source, onLoad }: Props) => {
  return <Image source={source} onLoad={onLoad} />;
};

export default PinchableImage;
