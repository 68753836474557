import React, { useEffect, useRef } from 'react';
import { ScrollView as ScrollViewProps } from 'react-native';

import ScrollView from './styled/ScrollView';
import View from './styled/View';

interface Props {
  children: React.ReactNode;
  background?: () => React.ReactNode;
  fullWidth?: boolean;
  isModalOpen?: boolean;
  padding?: number;
}

const WebContainer: React.FC<Props> = ({
  children,
  background,
  isModalOpen,
  fullWidth,
  padding,
  ...props
}: Props) => {
  const ref = useRef<ScrollViewProps>(null);

  useEffect(() => {
    if (ref && ref.current) {
      if (isModalOpen) ref.current.scrollTo({ x: 0, y: 0, animated: false });
    }
  }, [isModalOpen, ref]);

  return (
    <ScrollView
      style={{ flexGrow: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      {...props}
      scrollEnabled={!isModalOpen}
      ref={ref}
    >
      {background?.()}
      <View fullWidth={fullWidth} padding={padding}>
        {children}
      </View>
    </ScrollView>
  );
};

export default WebContainer;
