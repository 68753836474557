import React from 'react';
import { Platform } from 'react-native';
import { default as styledWeb } from 'styled-components';
import styled from 'styled-components/native';

import { useScreenSize } from 'hooks';

interface StyledProps {
  isActive: boolean;
}

const Container =
  Platform.OS === 'web'
    ? styledWeb.div<StyledProps>`
height: 75px;
position: relative;
margin-top: 15px;
margin-left: 32px;
${({ isActive }) =>
  isActive &&
  `
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 4px 4px 0px 0px;
  }
`}
`
    : styled.TouchableOpacity<StyledProps>`
        height: 75px;
        position: relative;
        margin-top: 15px;
        margin-left: 32px;
        ${({ isActive }) =>
          isActive &&
          `
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 4px 4px 0px 0px;
  }
`}
      `;

const TouchableOpacity = styled.TouchableOpacity`
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.Text<StyledProps>`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: ${({ isActive }) => (isActive ? '#ffffff' : 'rgba(255, 255, 255, 0.52)')};
  margin-left: 10px;
`;

interface Props {
  icon: React.ReactNode;
  text: string;
  isActive: boolean;
  onPress: () => void;
}

const MenuItem: React.FC<Props> = ({ icon, text, isActive, onPress }: Props) => {
  const { tabletLandscape } = useScreenSize();
  return (
    <Container isActive={isActive}>
      <TouchableOpacity onPress={onPress}>
        {icon}
        {tabletLandscape && <Text isActive={isActive}>{text}</Text>}
      </TouchableOpacity>
    </Container>
  );
};

export default MenuItem;
