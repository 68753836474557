import styled from 'styled-components/native';

export default styled.View`
  width: 100%;
  aspect-ratio: 1;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 6px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;
