import React, { useState } from 'react';
import styled from 'styled-components/native';

import Frame1 from '../images/frame1.png';
import Frame2 from '../images/frame2.png';
import Frame3 from '../images/frame3.png';
import Frame4 from '../images/frame4.png';

const View = styled.View`
  width: 100%;
  height: 560px;
  padding: 62px;
  background-color: rgb(5, 55, 255);
  justify-content: center;
  align-items: center;
`;

const Image1 = styled.Image.attrs({ resizeMode: 'cover' })`
  position: absolute;
  top: 0;
  left: 0;
`;

const Image2 = styled.Image.attrs({ resizeMode: 'cover' })`
  position: absolute;
  top: 0;
  right: 0;
`;

const Image3 = styled.Image.attrs({ resizeMode: 'cover' })`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Image4 = styled.Image.attrs({ resizeMode: 'cover' })`
  position: absolute;
  bottom: 0;
  right: 0;
`;

interface Props {
  children: React.ReactNode;
}

const StancastBckground: React.FC<Props> = ({ children }: Props) => {
  const [scale, setScale] = useState<number>();

  const getImageSize = (originalWidth: number, originalHeight: number) => {
    return { width: originalWidth * (scale || 1), height: originalHeight * (scale || 1) };
  };

  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => {
        const ratio = width / 332;
        if (ratio < 1) {
          setScale(ratio);
        } else {
          setScale(1);
        }
      }}
    >
      {scale !== undefined && (
        <>
          <Image1 source={Frame1} style={getImageSize(305, 250)} />
          <Image2 source={Frame2} style={getImageSize(271, 441)} />
          <Image3 source={Frame3} style={getImageSize(332, 221)} />
          <Image4 source={Frame4} style={getImageSize(254, 225)} />
        </>
      )}
      {children}
    </View>
  );
};

export default StancastBckground;
