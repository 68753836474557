import styled, { css } from 'styled-components/native';

export default styled.View`
  z-index: 5;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 15px;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      justify-content: space-between;
    `}
`;
