import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack';
import React from 'react';

import HomeScreen, { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';

import NavigationHeader from 'components/NavigationHeader';

import { useTheme } from 'themes';

export { SCREEN_NAME } from './constants';

export interface Props {}

export const linking: PathConfigMap = {
  Home: '',
};

const HomeStack = createStackNavigator();

const FanHomeStack: React.FC<Props> = () => {
  const { theme } = useTheme();
  return (
    <HomeStack.Navigator
      initialRouteName={HOME_SCREEN_NAME}
      screenOptions={{
        presentation: 'card',
        headerMode: 'screen',
        header: function FanHomeStackHeader(props: StackHeaderProps) {
          return <NavigationHeader {...props} />;
        },
        cardStyle: { backgroundColor: theme.colors.background },
      }}
    >
      <HomeStack.Screen
        name={HOME_SCREEN_NAME}
        component={HomeScreen}
        options={{ headerShown: false, animationEnabled: true }}
      />
    </HomeStack.Navigator>
  );
};

export default FanHomeStack;
