import React, { useMemo } from 'react';

import { useTimeAgo } from 'hooks';
import { OrderStatus } from 'types';

import ContentView from './styled/ContentView';
import CoverWithAvatar from './styled/CoverWithAvatar';
import DateView from './styled/Date';
import OrderStatusBadge from './styled/OrderStatusBadge';
import Text from './styled/Text';
import TextView from './styled/TextView';
import Title from './styled/Title';
import View from './styled/View';

interface Props {
  title: string;
  description: string;
  timestamp?: string;
  avatar: string;
  cover: string;
  status?: OrderStatus;
}

const ArtistMarketplaceOrderHeader: React.FC<Props> = ({
  title,
  description,
  timestamp,
  avatar,
  cover,
  status,
}: Props) => {
  const timestampDate = useMemo(() => (timestamp ? new Date(timestamp) : undefined), [timestamp]);
  const { timeAgoText } = useTimeAgo(timestampDate, 5000);

  const formattedTitle = useMemo(() => {
    const maxLength = 28;
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
  }, [title]);

  return (
    <View>
      <CoverWithAvatar avatar={avatar} cover={cover} />
      <ContentView>
        <TextView>
          <Title>{formattedTitle}</Title>
          <Text>{description}</Text>
        </TextView>
        <DateView>
          {timestamp && <Text>{timeAgoText}</Text>}
          {status && <OrderStatusBadge status={status} />}
        </DateView>
      </ContentView>
    </View>
  );
};

export default ArtistMarketplaceOrderHeader;
