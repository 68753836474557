import styled, { css } from 'styled-components/native';

interface Props {
  underline?: boolean;
}

export default styled.Text<Props>`
  color: ${({ theme }) => theme.colors.primaryInactive};
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0ch.06px;
  margin: 0 auto;

  ${({ onPress, underline }) =>
    (onPress || underline) &&
    css`
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.primaryInactive};
    `}
`;
