import React, { FC } from 'react';
import { Pressable, PressableProps } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import BlurView from './styled/BlurView';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

export interface TouchableOpacityProps {
  blur?: number;
  pressedOpacity?: number;
  testID?: string;
}

const TouchableOpacity: FC<TouchableOpacityProps & PressableProps> = ({
  blur,
  style,
  onPressIn,
  onPressOut,
  pressedOpacity = 0.7,
  children,
  ...rest
}) => {
  const opacity = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value, { duration: 120, easing: Easing.inOut(Easing.quad) }),
  }));

  return (
    <AnimatedPressable
      onPressIn={(e) => {
        opacity.value = pressedOpacity;
        onPressIn?.(e);
      }}
      onPressOut={(e) => {
        opacity.value = 1;
        onPressOut?.(e);
      }}
      style={[style, animatedStyle]}
      {...rest}
    >
      <>
        {blur && <BlurView blurAmount={blur} />}
        {children}
      </>
    </AnimatedPressable>
  );
};

export default TouchableOpacity;
