import styled from 'styled-components/native';

interface Props {
  isPreview?: boolean;
}

export default styled.Image.attrs<Props>(({ isPreview }) => ({
  blurRadius: isPreview ? 50 : 75,
  resizeMode: 'cover',
}))`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
