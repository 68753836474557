import moment from 'moment';

export function getTimestampText(timestamp: Date | undefined): string {
  return moment(timestamp).fromNow();
}

export function getDateKey(timestamp: Date | undefined): string {
  return formatDate(timestamp, 'MM-DD-YYYY');
}

export function isToday(timestamp: Date | undefined): boolean {
  return moment(timestamp).isSame(new Date(), 'day');
}

export function isYesterday(timestamp: Date | undefined): boolean {
  return moment(timestamp).diff(moment(new Date()), 'days') === -1;
}

export function formatDate(timestamp: Date | undefined, formatPattern: string): string {
  return moment(timestamp).format(formatPattern);
}

export const formatDateRange = (from: Date, to: Date): string => {
  const pattern = 'Do MMM';
  return `${moment(from).format(pattern)} - ${moment(to).format(pattern)}`;
};
