import { PathConfigMap } from '@react-navigation/native';
import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import ArtistJoinRequestSentScreen, {
  SCREEN_NAME as ARTIST_JOIN_REQUEST_SENT_SCREEN_NAME,
} from 'screens/ArtistJoinRequestSentScreen';
import ArtistJoinStanbaseScreen, {
  SCREEN_NAME as ARTIST_JOIN_STANBASE_SCREEN_NAME,
} from 'screens/ArtistJoinStanbaseScreen';
import ArtistOnBoardingScreen, {
  SCREEN_NAME as ARTIST_ON_BOARDING_SCREEN_NAME,
} from 'screens/ArtistOnBoardingScreen';
import EmailConfirmedScreen, {
  SCREEN_NAME as EMAIL_CONFIRMED_SCREEN_NAME,
} from 'screens/EmailConfirmedScreen';
import ForgotPasswordScreen, {
  SCREEN_NAME as FORGOT_PASSWORD_SCREEN_NAME,
} from 'screens/ForgotPasswordScreen';
import JoinStanbaseScreen, {
  SCREEN_NAME as JOIN_STANBASE_SCREEN_NAME,
} from 'screens/JoinStanbaseScreen';
import LoginWithEmailScreen, {
  SCREEN_NAME as LOGIN_WITH_EMAIL_SCREEN_NAME,
} from 'screens/LoginWithEmailScreen';
import RegisterWithEmailScreen, {
  SCREEN_NAME as REGISTER_WITH_EMAIL_SCREEN_NAME,
} from 'screens/RegisterWithEmailScreen';
import ResetPasswordScreen, {
  SCREEN_NAME as RESET_PASSWORD_SCREEN_NAME,
} from 'screens/ResetPasswordScreen';
import WelcomeScreen, { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import NavigationHeader from 'components/NavigationHeader';

import { VARIANT } from 'context';
import { useConfig } from 'hooks';

export { SCREEN_NAME } from './constants';

export interface Props {}

export const pathConfigMap: PathConfigMap = {
  [WELCOME_SCREEN_NAME]: 'welcome',
  [LOGIN_WITH_EMAIL_SCREEN_NAME]: 'login/email',
  [FORGOT_PASSWORD_SCREEN_NAME]: 'forgot-password',
  [RESET_PASSWORD_SCREEN_NAME]: 'reset-password',
  ...(VARIANT === 'artist'
    ? {
        [ARTIST_JOIN_STANBASE_SCREEN_NAME]: 'join-stanbase-artist',
        [ARTIST_JOIN_REQUEST_SENT_SCREEN_NAME]: 'join-request-sent',
        [ARTIST_ON_BOARDING_SCREEN_NAME]: '/onboarding',
      }
    : {}),
  ...(VARIANT === 'fan'
    ? {
        [REGISTER_WITH_EMAIL_SCREEN_NAME]: 'register/email',
      }
    : {}),
  ...(Platform.OS === 'web'
    ? {
        [EMAIL_CONFIRMED_SCREEN_NAME]: 'emailconfirmed',
        [JOIN_STANBASE_SCREEN_NAME]: 'join-stanbase',
      }
    : {}),
};

const LoginStack = createStackNavigator();

const LoginStackScreen: React.FC<Props> = () => {
  const { config } = useConfig();
  const IS_PLATFORM_SUPPORTED = Platform.OS !== 'web' || config.webVersionEnabled;

  return (
    <LoginStack.Navigator
      initialRouteName={WELCOME_SCREEN_NAME}
      screenOptions={{
        headerMode: 'screen',
        header: function CommonLoginStackHeader(props: StackHeaderProps) {
          return <NavigationHeader {...props} />;
        },
      }}
    >
      <LoginStack.Screen
        name={WELCOME_SCREEN_NAME}
        component={WelcomeScreen}
        options={{
          headerShown: false,
          animationEnabled: false,
        }}
      />
      {IS_PLATFORM_SUPPORTED && (
        <>
          <LoginStack.Screen
            name={LOGIN_WITH_EMAIL_SCREEN_NAME}
            component={LoginWithEmailScreen}
            options={{
              headerShown: Platform.OS !== 'web',
              animationEnabled: true,
            }}
          />
          <LoginStack.Screen
            name={FORGOT_PASSWORD_SCREEN_NAME}
            component={ForgotPasswordScreen}
            options={{
              headerShown: Platform.OS !== 'web',
              animationEnabled: true,
            }}
          />
          <LoginStack.Screen
            name={RESET_PASSWORD_SCREEN_NAME}
            component={ResetPasswordScreen}
            options={{
              headerShown: Platform.OS !== 'web',
              animationEnabled: true,
            }}
          />
        </>
      )}
      {IS_PLATFORM_SUPPORTED && VARIANT === 'fan' && (
        <LoginStack.Screen
          name={REGISTER_WITH_EMAIL_SCREEN_NAME}
          component={RegisterWithEmailScreen}
          options={{
            headerShown: Platform.OS !== 'web',
            animationEnabled: true,
          }}
        />
      )}
      {Platform.OS === 'web' && (
        <>
          <LoginStack.Screen
            name={EMAIL_CONFIRMED_SCREEN_NAME}
            component={EmailConfirmedScreen}
            options={{
              headerShown: false,
              animationEnabled: true,
            }}
          />
          <LoginStack.Screen
            name={JOIN_STANBASE_SCREEN_NAME}
            component={JoinStanbaseScreen}
            options={{
              headerShown: false,
              animationEnabled: true,
            }}
          />
        </>
      )}
      {VARIANT === 'artist' && (
        <>
          <LoginStack.Screen
            name={ARTIST_JOIN_STANBASE_SCREEN_NAME}
            component={ArtistJoinStanbaseScreen}
            options={{
              headerShown: true,
              animationEnabled: true,
            }}
          />
          <LoginStack.Screen
            name={ARTIST_JOIN_REQUEST_SENT_SCREEN_NAME}
            component={ArtistJoinRequestSentScreen}
            options={{
              headerShown: false,
              animationEnabled: true,
            }}
          />
          <LoginStack.Screen
            name={ARTIST_ON_BOARDING_SCREEN_NAME}
            component={ArtistOnBoardingScreen}
            options={{
              headerShown: false,
              animationEnabled: true,
            }}
          />
        </>
      )}
    </LoginStack.Navigator>
  );
};

export default LoginStackScreen;
