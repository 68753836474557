import { css } from 'styled-components';
import styled from 'styled-components/native';

interface Props {
  variant?: 'highlight' | 'success';
  size: 'extra-large' | 'large' | 'medium' | 'small' | 'extra-small';
}

export default styled.Text<Props>`
  color: ${({ theme, variant }) =>
    variant == 'highlight'
      ? theme.insights.primary
      : variant == 'success'
      ? theme.insights.plusPercent
      : theme.artistDashboard.text};
  font-family: 'BasierCircle-Medium';
  margin-right: 5px;
  ${({ size }) =>
    size == 'extra-large' &&
    css`
      font-size: 34px;
      height: 38px;
      letter-spacing: 0.41px;
      margin-right: 10px;
    `}
  ${({ size }) =>
    size == 'large' &&
    css`
      font-size: 28px;
      letter-spacing: 0.38px;
    `}
  ${({ size }) =>
    size == 'medium' &&
    css`
      font-size: 22px;
      letter-spacing: -0.26px;
    `}
  ${({ size }) =>
    size == 'small' &&
    css`
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.43px;
    `}
  ${({ size }) =>
    size == 'extra-small' &&
    css`
      font-size: 11px;
      letter-spacing: 0.06px;
    `}
`;
