import { Octicons } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const Icon: React.FC = ({ ...restProps }) => <Octicons name="search" size={20} {...restProps} />;

export default styled(Icon)`
  color: ${(props) => props.theme.searchBar.iconColor};
  left: 28px;
  position: absolute;
  top: 13px;
`;
