export default {
  subscribePaymentRequestTitle: {
    id: 'feed.unlock.title',
    defaultMessage: 'Become a Stan',
  },
  subscribePaymentRequestDescription: {
    id: 'feed.unlock.description',
    defaultMessage: 'Subscribe to {username} and get access to exclusive Stan only content!',
  },
  unlockPaymentRequestTitle: {
    id: 'feed.unlock.title',
    defaultMessage: 'Unlock for £{price}',
  },
  unlockPaymentRequestDescription: {
    id: 'feed.unlock.description',
    defaultMessage: 'Unlock the post and get access to exclusive content!',
  },
};
