import { AxiosError, AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Alert } from 'react-native';

import { SCREEN_NAME as ARTIST_PROFILE_STACK_NAME } from 'stacks/artist/ArtistProfileStack/constants';

import { SCREEN_NAME as ARTIST_SINGLE_POST_SCREEN_NAME } from 'screens/ArtistSinglePostScreen/constants';

import { VARIANT } from 'context/appInfo';
import { useBackEnd } from 'context/backEnd';
import { usePostsRefreshSet, usePostsRefreshState } from 'context/postsRefresh';
import { SnackbarState, useSnackbarPostStatus } from 'context/snackbar';
import { useSnackbarSet } from 'context/snackbar';
import { useAuthSwr } from 'hooks/swr';
import { Artist, ArtistProduct, IapConfig, ProductType } from 'types';
import { getProxyUrl } from 'utils/proxy';

export const ARTISTS_KEY = '/artists';

export type ArtistImage = ArtistProduct;

export type ArtistVideo = ArtistProduct;

export type ArtistAudio = ArtistProduct;

export type ArtistPoll = ArtistProduct;

export interface ArtistLiveStream {
  m3u8_url: string;
}

interface UploadHandler {
  intl: IntlShape;
  axiosInstance: AxiosInstance;
  setSnackbar: (snackbar: SnackbarState) => void;
  endpoint: string;
  bodyFormData: FormData;
  thumbnailUri: string;
  setIsUploading: any;
  postType: ProductType;
  modelNamePlural: 'audios' | 'images' | 'videos' | 'polls';
  navigation: any;
  iap_config: IapConfig;
}

const uploadHandler: (data: UploadHandler) => Promise<ArtistProduct | null> = async ({
  intl,
  axiosInstance,
  setSnackbar,
  endpoint,
  bodyFormData,
  thumbnailUri,
  setIsUploading,
  postType,
  modelNamePlural,
  navigation,
  iap_config,
}) => {
  setSnackbar({
    visible: true,
    postType,
    thumbnailUri: thumbnailUri,
    label: intl.formatMessage({
      id: 'post.posting',
      defaultMessage: 'Posting...',
    }),
  });
  try {
    let failed = false;
    const response: { data: ArtistProduct } | null = await axiosInstance
      .post(endpoint, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .catch((error) => {
        // console.log(JSON.stringify(error.response.data, null, 2));
        failed = true;
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setSnackbar({
          visible: true,
          postType,
          thumbnailUri: thumbnailUri,
          label: intl.formatMessage({
            id: 'post.failed',
            defaultMessage: 'Failed',
          }),
          actionLabel: intl.formatMessage({
            id: 'snackbar.dismiss',
            defaultMessage: 'Dismiss',
          }),
          dismissAfter: 5000,
        });
        return null;
      });
    // console.log(JSON.stringify(response?.data));
    if (!failed) {
      setSnackbar({
        product: response?.data,
        modelNamePlural,
        iap_config,
        navigation,
        visible: true,
        postType,
        thumbnailUri: thumbnailUri,
        label: intl.formatMessage(
          {
            id: 'post.processing',
            defaultMessage: 'Processing... {progress}%',
          },
          { progress: 0 },
        ),
      });
      setIsUploading(false);
      return response?.data || null;
    }
  } catch (e) {
    Alert.alert('Something went wrong');
  }
  setIsUploading(false);
  return null;
};

export function useAllArtists(): {
  artists: Artist[] | undefined;
  isLoading: boolean;
  error: AxiosError<any>;
} {
  const {
    isLoading,
    data: artists,
    error,
  } = useAuthSwr<Artist[]>({
    key: ARTISTS_KEY,
    isPublic: true,
    cache: true,
  });

  return {
    artists,
    isLoading,
    error,
  };
}

export function useArtist(id: string): {
  artist: Artist | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    isLoading,
    isValidating,
    data: artist,
    error,
    mutate,
  } = useAuthSwr<Artist>({
    key: `${ARTISTS_KEY}/${id}`,
    isPublic: true,
    cache: true,
  });

  return {
    artist,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function generateProductKey(
  artistUsername: string | undefined,
  productType: ProductType,
  productId: number,
): string {
  let productPath: string;
  switch (productType) {
    case 'image':
      productPath = 'images';
      break;
    case 'audio':
      productPath = 'audios';
      break;
    case 'video':
      productPath = 'videos';
      break;
    case 'poll':
      productPath = 'polls';
      break;
    case 'marketplaceitem':
      productPath = 'marketplace_items';
      break;
    default:
      throw new Error(`Unsupported product type ${productType}`);
  }
  return `${ARTISTS_KEY}/${
    VARIANT === 'artist' ? 'me' : artistUsername
  }/products/${productPath}/${productId}`;
}

export function useArtistProduct({
  artistUsername,
  productType,
  productId,
  mockValue,
}: {
  artistUsername: string;
  productType: ProductType;
  productId: number;
  mockValue?: ArtistProduct;
}): {
  product: ArtistProduct | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
} {
  const {
    isLoading,
    isValidating,
    data: product,
    error,
    mutate,
  } = useAuthSwr<ArtistProduct>({
    key: generateProductKey(artistUsername, productType, productId),
    mockValue,
  });

  return {
    product,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function useArtistImages(artistUsername?: string): {
  images: ArtistImage[] | null;
  isLoading: boolean;
  isValidating: boolean;
  error?: AxiosError;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    isLoading,
    isValidating,
    data: images,
    error,
    accessToken,
    mutate,
  } = useAuthSwr<ArtistImage[]>({
    key: `${ARTISTS_KEY}/${artistUsername || 'me'}/products/images`,
  });

  const imagesData =
    images && Array.isArray(images)
      ? images.map((image: ArtistImage) => ({
          ...image,
          file_proxy: artistUsername
            ? getProxyUrl(image.file_proxy, accessToken)
            : image.file_proxy,
        }))
      : null;

  return {
    images: imagesData,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function useArtistImageUpload(): {
  upload: (postData: any, navigation: any) => Promise<void>;
  update: ({
    product,
    description,
    tier,
    comment_tier,
    price,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
    price: number;
  }) => Promise<boolean>;
  remove: (id: number) => Promise<boolean>;
  isUploading: boolean;
} {
  const { axiosInstance } = useBackEnd();
  const [isUploading, setIsUploading] = useState(false);
  const { setSnackbar } = useSnackbarSet();
  const snackbarPostStatus = useSnackbarPostStatus();
  const intl = useIntl();

  const upload = async (postData: any, navigation: any) => {
    if (snackbarPostStatus !== 'IN_PROGRESS') {
      setIsUploading(true);
      const bodyFormData = new FormData();
      for (const [key, value] of Object.entries(postData)) {
        if (!(key === 'tier' && !value) && key !== 'thumbnailUri' && key !== 'iap_config') {
          bodyFormData.append(key, value as any);
        }
      }
      uploadHandler({
        intl,
        axiosInstance,
        setSnackbar,
        endpoint: `/artists/me/products/images`,
        bodyFormData,
        setIsUploading,
        thumbnailUri: postData.thumbnailUri,
        postType: 'image',
        modelNamePlural: 'images',
        navigation,
        iap_config: postData.iap_config,
      });
    } else {
      Alert.alert('Unable to upload', 'Please wait until another post is uploaded.');
    }
  };

  const update = async ({
    product,
    description,
    tier,
    comment_tier,
    price,
    iap_config,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
    price: number;
    iap_config: IapConfig;
  }) => {
    setIsUploading(true);
    const updateData: any = { description, tier, comment_tier, price };
    if (iap_config) {
      updateData.iap_config = iap_config;
    }
    return await axiosInstance
      .patch(`/artists/me/products/images/${product.id}`, updateData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  const remove = async (id: number) => {
    setIsUploading(true);
    return await axiosInstance
      .delete(`/artists/me/products/images/${id}`)
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  return {
    upload,
    update,
    remove,
    isUploading,
  };
}

export function useArtistImage(
  artistUsername: string,
  imageId: number,
): {
  image?: ArtistImage;
  isLoading: boolean;
  error: AxiosError;
} {
  const {
    isLoading,
    data: image,
    error,
  } = useAuthSwr<ArtistImage>({
    key: `${ARTISTS_KEY}/${artistUsername}/products/images/${imageId}`,
  });

  return {
    image,
    isLoading,
    error,
  };
}

export function useArtistVideos(artistUsername?: string): {
  videos?: ArtistVideo[];
  isLoading: boolean;
  isValidating: boolean;
  error?: AxiosError;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    isLoading,
    isValidating,
    data: videos,
    error,
    mutate,
  } = useAuthSwr<ArtistVideo[]>({
    key: `${ARTISTS_KEY}/${artistUsername || 'me'}/products/videos`,
  });

  return {
    videos,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function useArtistVideoUpload(): {
  update: ({
    product,
    description,
    tier,
    comment_tier,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
  }) => Promise<boolean>;
  upload: (postData: any, navigation: any) => Promise<void>;
  remove: (id: number) => Promise<boolean>;
  isUploading: boolean;
} {
  const { axiosInstance } = useBackEnd();
  const [isUploading, setIsUploading] = useState(false);
  const { setSnackbar } = useSnackbarSet();
  const snackbarPostStatus = useSnackbarPostStatus();
  const intl = useIntl();

  const upload = async (postData: any, navigation: any) => {
    if (snackbarPostStatus !== 'IN_PROGRESS') {
      setIsUploading(true);
      setSnackbar({
        visible: true,
        postType: 'video',
        thumbnailUri: postData.thumbnailUri,
        label: intl.formatMessage({
          id: 'post.posting',
          defaultMessage: 'Posting...',
        }),
      });
      try {
        // Getting resumable session url
        const rus = await axiosInstance.post(
          `/resumable_upload_session`,
          { scope: 'create_video', file_extension: postData.ext },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );

        // Uploading video to Cloud Storage
        await fetch(rus?.data?.url, {
          body: postData.video,
          headers: {
            'Content-Type': postData.mime,
          },
          method: 'PUT',
        });

        // Saving new post data
        const bodyFormData = new FormData();
        for (const [key, value] of Object.entries(postData)) {
          if (
            !(key === 'tier' && !value) &&
            key !== 'video' &&
            key !== 'mime' &&
            key !== 'ext' &&
            key !== 'iap_config' &&
            key !== 'uri'
          ) {
            bodyFormData.append(key, value as string);
          }
        }
        bodyFormData.append('file_resumable_upload_session_id', rus?.data?.id);

        uploadHandler({
          intl,
          axiosInstance,
          setSnackbar,
          endpoint: `/artists/me/products/videos`,
          bodyFormData,
          setIsUploading,
          thumbnailUri: postData.thumbnailUri,
          postType: 'video',
          modelNamePlural: 'videos',
          navigation,
          iap_config: postData.iap_config,
        });
      } catch (e) {
        console.log(e);
        Alert.alert('Something went wrong');
      }
    } else {
      Alert.alert('Unable to upload', 'Please wait until another post is uploaded.');
    }
  };

  const update = async ({
    product,
    description,
    tier,
    comment_tier,
    price,
    iap_config,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
    price: number;
    iap_config: IapConfig;
  }) => {
    setIsUploading(true);
    const updateData: any = { description, tier, comment_tier, price };
    if (iap_config) {
      updateData.iap_config = iap_config;
    }
    return await axiosInstance
      .patch(`/artists/me/products/videos/${product.id}`, updateData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  const remove = async (id: number) => {
    setIsUploading(true);
    return await axiosInstance
      .delete(`/artists/me/products/videos/${id}`)
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  return {
    update,
    upload,
    remove,
    isUploading,
  };
}

export function useArtistVideo(
  artistUsername: string,
  videoId: number,
): {
  video?: ArtistVideo;
  isLoading: boolean;
  error?: AxiosError;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    isLoading,
    data: video,
    error,
    mutate,
  } = useAuthSwr<ArtistVideo>({
    key: `${ARTISTS_KEY}/${artistUsername}/products/videos/${videoId}`,
  });
  return {
    video,
    isLoading,
    error,
    mutate,
  };
}

export function useArtistAudios(artistUsername?: string): {
  audios?: ArtistAudio[];
  isLoading: boolean;
  isValidating: boolean;
  error?: AxiosError;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>);
} {
  const {
    isLoading,
    isValidating,
    data: audios,
    error,
    mutate,
  } = useAuthSwr<ArtistAudio[]>({
    key: `${ARTISTS_KEY}/${artistUsername || 'me'}/products/audios`,
  });

  return {
    audios,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function useArtistAudioUpload(): {
  upload: (postData: any, navigation: any) => Promise<void>;
  update: ({
    product,
    description,
    tier,
    comment_tier,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
  }) => Promise<boolean>;
  remove: (id: number) => Promise<boolean>;
  isUploading: boolean;
} {
  const { axiosInstance } = useBackEnd();
  const [isUploading, setIsUploading] = useState(false);
  const { setSnackbar } = useSnackbarSet();
  const snackbarPostStatus = useSnackbarPostStatus();
  const intl = useIntl();

  const upload = async (postData: any, navigation: any) => {
    if (snackbarPostStatus !== 'IN_PROGRESS') {
      setIsUploading(true);
      const bodyFormData = new FormData();
      for (const [key, value] of Object.entries(postData)) {
        if (!(key === 'tier' && !value) && key !== 'snackbarThumbnailUri' && key !== 'iap_config') {
          bodyFormData.append(key, value as any);
        }
      }
      uploadHandler({
        intl,
        axiosInstance,
        setSnackbar,
        endpoint: `/artists/me/products/audios`,
        bodyFormData,
        setIsUploading,
        thumbnailUri: postData.snackbarThumbnailUri,
        postType: 'audio',
        modelNamePlural: 'audios',
        navigation,
        iap_config: postData.iap_config,
      });
    } else {
      Alert.alert('Unable to upload', 'Please wait until another post is uploaded.');
    }
  };

  const update = async ({
    product,
    description,
    tier,
    comment_tier,
    price,
    iap_config,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
    price: number;
    iap_config: IapConfig;
  }) => {
    setIsUploading(true);
    const updateData: any = { description, tier, comment_tier, price };
    if (iap_config) {
      updateData.iap_config = iap_config;
    }
    return await axiosInstance
      .patch(`/artists/me/products/audios/${product.id}`, updateData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  const remove = async (id: number) => {
    setIsUploading(true);
    return await axiosInstance
      .delete(`/artists/me/products/audios/${id}`)
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  return {
    upload,
    update,
    remove,
    isUploading,
  };
}

export function useArtistAudio(
  artistUsername: string,
  audioId: number,
): {
  audio: ArtistAudio | null;
  isLoading: boolean;
  error?: AxiosError;
} {
  const {
    isLoading,
    data: audio,
    error,
    accessToken,
  } = useAuthSwr<ArtistAudio>({
    key: `${ARTISTS_KEY}/${artistUsername}/products/audios/${audioId}`,
  });
  const audioData = audio
    ? {
        ...audio,
        file_proxy: getProxyUrl(audio.file_proxy, accessToken),
      }
    : null;

  return {
    audio: audioData,
    isLoading,
    error,
  };
}

export function useArtistLiveStream(artistUsername: string): {
  liveStream?: ArtistLiveStream;
  isLoading: boolean;
  error: AxiosError<any>;
} {
  const {
    isLoading,
    data: liveStream,
    error,
  } = useAuthSwr<ArtistLiveStream>({
    key: `${ARTISTS_KEY}/${artistUsername}/live_stream`,
  });

  return {
    liveStream,
    isLoading,
    error,
  };
}

export function useArtistPolls(artistUsername?: string): {
  polls?: ArtistProduct[];
  isLoading: boolean;
  isValidating: boolean;
  error: AxiosError<any>;
  mutate: (() => void) | ((data?: any, shouldRevalidate?: boolean | undefined) => Promise<void>);
} {
  const {
    isLoading,
    isValidating,
    data: polls,
    error,
    mutate,
  } = useAuthSwr<ArtistPoll[]>({
    key: `${ARTISTS_KEY}/${artistUsername || 'me'}/products/polls`,
  });

  return {
    polls,
    isLoading,
    isValidating,
    error,
    mutate,
  };
}

export function useArtistPollUpload(): {
  upload: (postData: any, navigation: any) => Promise<void>;
  update: ({
    product,
    description,
    tier,
    comment_tier,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
  }) => Promise<boolean>;
  remove: (id: number) => Promise<boolean>;
  isUploading: boolean;
} {
  const { axiosInstance } = useBackEnd();
  const [isUploading, setIsUploading] = useState(false);
  const { setSnackbar } = useSnackbarSet();
  const snackbarPostStatus = useSnackbarPostStatus();
  const intl = useIntl();
  const postsRefreshSet = usePostsRefreshSet();
  const postsRefreshState = usePostsRefreshState();

  const upload = async (postData: any, navigation: any) => {
    if (snackbarPostStatus !== 'IN_PROGRESS') {
      setIsUploading(true);
      const bodyFormData = new FormData();
      for (const [key, value] of Object.entries(postData)) {
        if (!(key === 'tier' && !value) && key !== 'snackbarThumbnailUri' && key !== 'iap_config') {
          if (Array.isArray(value) && key === 'answers_create') {
            value.forEach((a, i) => {
              bodyFormData.append(`${key}[${i}]`, a);
            });
          } else {
            bodyFormData.append(key, value as any);
          }
        }
      }
      const product = await uploadHandler({
        intl,
        axiosInstance,
        setSnackbar,
        endpoint: `/artists/me/products/polls`,
        bodyFormData,
        setIsUploading,
        thumbnailUri: postData.snackbarThumbnailUri,
        postType: 'poll',
        modelNamePlural: 'polls',
        navigation,
        iap_config: postData.iap_config,
      });
      if (!postData.cover) {
        postsRefreshSet({
          ...postsRefreshState,
          shouldRefresh: [...postsRefreshState.shouldRefresh, 'polls'],
        });
        setSnackbar({
          visible: true,
          postType: 'poll',
          thumbnailUri: postData.snackbarThumbnailUri,
          label: intl.formatMessage({
            id: 'post.done',
            defaultMessage: 'Done',
          }),
          actionLabel: intl.formatMessage({
            id: 'post.showPost',
            defaultMessage: 'Show post',
          }),
          actionOnPress: () => {
            if (product) {
              navigation.navigate(ARTIST_PROFILE_STACK_NAME, {
                screen: ARTIST_SINGLE_POST_SCREEN_NAME,
                params: {
                  artistUsername: product.artist.username,
                  productType: 'poll',
                  productId: product.id,
                  product: product,
                },
              });
            }
          },
          dismissAfter: 5000,
        });
      }
    } else {
      Alert.alert('Unable to upload', 'Please wait until another post is uploaded.');
    }
  };

  const update = async ({
    product,
    description,
    tier,
    comment_tier,
    price,
    iap_config,
  }: {
    product: ArtistProduct;
    description: string;
    tier: null | 1;
    comment_tier: null | 1 | 2 | 3;
    price: number;
    iap_config: IapConfig;
  }) => {
    setIsUploading(true);
    const updateData: any = { description, tier, comment_tier, price };
    if (iap_config) {
      updateData.iap_config = iap_config;
    }
    return await axiosInstance
      .patch(`/artists/me/products/polls/${product.id}`, updateData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  const remove = async (id: number) => {
    setIsUploading(true);
    return await axiosInstance
      .delete(`/artists/me/products/polls/${id}`)
      .then(() => {
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        setIsUploading(false);
        return false;
      });
  };

  return {
    upload,
    update,
    remove,
    isUploading,
  };
}

export function useSearchArtistsHistory(): {
  searchArtistsHistory: any;
  addArtistToHistory: (artistUsername: string) => Promise<boolean>;
  deleteArtistFromHistory: (artistUsername: string) => Promise<boolean>;
} {
  const { axiosInstance } = useBackEnd();
  const { data: searchArtistsHistory, mutate: mutateHistory } = useAuthSwr<ArtistLiveStream>({
    key: `${ARTISTS_KEY}/all/searches`,
  });

  const addArtistToHistory = async (artistUsername: string) => {
    return await axiosInstance
      .post(`${ARTISTS_KEY}/all/searches`, { username: artistUsername })
      .then(() => {
        mutateHistory();
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        return false;
      });
  };

  const deleteArtistFromHistory = async (artistUsername: string) => {
    return await axiosInstance
      .delete(`${ARTISTS_KEY}/all/searches/${artistUsername}`)
      .then(() => {
        mutateHistory();
        return true;
      })
      .catch((error) => {
        Alert.alert('Error', error?.response?.data?.detail || 'Something went wrong');
        return false;
      });
  };

  return { searchArtistsHistory, addArtistToHistory, deleteArtistFromHistory };
}

interface UseArtistIsLive {
  setIsLive: (isLive: boolean) => void;
  isLive: boolean;
  isReady: boolean;
}

export function useArtistIsLive(): UseArtistIsLive {
  const [live, setLive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { axiosInstance } = useBackEnd();

  const updateData = new FormData();

  const { mutate: mutateHistory } = useAuthSwr<ArtistLiveStream>({
    key: `${ARTISTS_KEY}/all/searches`,
  });

  const setIsLive = async (isLive: boolean) => {
    updateData.append('is_owner_published', isLive as any);
    return await axiosInstance
      .patch('/auth/artist', updateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        mutateHistory();
        getIsLive();
        return true;
      });
  };

  useEffect(() => {
    getIsLive();
  }, []);

  const getIsLive = async () => {
    const {
      data: { is_owner_published },
    } = await axiosInstance.get('/auth/artist');
    setLive(is_owner_published);
    setIsReady(true);
  };

  return { setIsLive, isLive: live, isReady };
}
