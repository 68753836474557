export const milliSecondsToTimeString = (milliSec?: number, format?: string): string => {
  const ms = milliSec || 0;
  const hours = Math.floor(ms / 3600000);
  const restMilliseconds = ms - hours * 3600000;
  const minute = Math.floor(restMilliseconds / 60000);
  const second = Math.floor(restMilliseconds / 1000) % 60;
  if (format === 'h?:m:ss') {
    const minuteStr = hours && minute < 9 ? `0${minute}` : `${minute}`;
    const secondStr = second > 9 ? `${second}` : `0${second}`;
    return `${hours ? `${hours}:` : ''}${minuteStr}:${secondStr}`;
  } else {
    const hoursStr = hours > 9 ? `${hours}` : `0${hours}`;
    const minuteStr = minute > 9 ? `${minute}` : `0${minute}`;
    const secondStr = second > 9 ? `${second}` : `0${second}`;
    return `${hoursStr}:${minuteStr}:${secondStr}`;
  }
};

export const secondsToTimeString = (sec?: number): string => {
  const ms = sec ? sec * 1000 : 0;
  const hours = Math.floor(ms / 3600000);
  const restMilliseconds = ms - hours * 3600000;
  const minute = Math.floor(restMilliseconds / 60000);
  const second = Math.floor(restMilliseconds / 1000) % 60;
  const minuteStr = minute > 9 ? `${minute}` : `0${minute}`;
  const secondStr = second > 9 ? `${second}` : `0${second}`;
  return `${hours ? `${hours}:` : ''}${minuteStr}:${secondStr}`;
};
