import { useMemo } from 'react';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ProductType } from 'types';

interface Props {
  type: ProductType;
}

const getPaths = (type: ProductType): string[] => {
  switch (type) {
    case 'image':
      return [
        'M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z',
        'M5.66663 6.66666C6.21891 6.66666 6.66663 6.21894 6.66663 5.66666C6.66663 5.11437 6.21891 4.66666 5.66663 4.66666C5.11434 4.66666 4.66663 5.11437 4.66663 5.66666C4.66663 6.21894 5.11434 6.66666 5.66663 6.66666Z',
        'M14 9.99999L10.6667 6.66666L3.33337 14',
      ];
    case 'video':
      return [
        'M13.2134 1.33333H2.78671C1.98405 1.33333 1.33337 1.98401 1.33337 2.78666V13.2133C1.33337 14.016 1.98405 14.6667 2.78671 14.6667H13.2134C14.016 14.6667 14.6667 14.016 14.6667 13.2133V2.78666C14.6667 1.98401 14.016 1.33333 13.2134 1.33333Z',
        'M4.66663 1.33333V14.6667',
        'M11.3334 1.33333V14.6667',
        'M1.33337 8H14.6667',
        'M1.33337 4.66667H4.66671',
        'M1.33337 11.3333H4.66671',
        'M11.3334 11.3333H14.6667',
        'M11.3334 4.66667H14.6667',
      ];
    case 'poll':
      return ['M12 13.3334V6.66669', 'M8 13.3334V2.66669', 'M4 13.3333V9.33331'];
    case 'audio':
      return [
        'M2 12V8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8V12',
        'M14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H12C11.6464 14 11.3072 13.8595 11.0572 13.6095C10.8071 13.3594 10.6667 13.0203 10.6667 12.6667V10.6667C10.6667 10.3131 10.8071 9.97392 11.0572 9.72387C11.3072 9.47382 11.6464 9.33334 12 9.33334H14V12.6667ZM2 12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H4C4.35362 14 4.69276 13.8595 4.94281 13.6095C5.19286 13.3594 5.33333 13.0203 5.33333 12.6667V10.6667C5.33333 10.3131 5.19286 9.97392 4.94281 9.72387C4.69276 9.47382 4.35362 9.33334 4 9.33334H2V12.6667Z',
      ];
    case 'marketplaceitem':
      return [
        'M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41V13.41Z',
        'M7 7H7.01',
      ];
    default:
      return [];
  }
};

const SvgComponent: React.FC<Props> = ({ type }: Props) => {
  const pathArray = useMemo(() => getPaths(type), [type]);
  const viewboxSize = useMemo(() => {
    return type === 'marketplaceitem' ? 24 : 16;
  }, [type]);

  return (
    <Svg width={16} height={16} viewBox={`0 0 ${viewboxSize} ${viewboxSize}`} fill="none">
      <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        {pathArray.map((d, index) => (
          <Path
            key={index}
            d={d}
            stroke={'rgba(255, 255, 255, 0.52)'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ))}
      </Svg>
    </Svg>
  );
};

export default SvgComponent;
