import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

import { useScreenSize } from 'hooks';

const SearchIcon: React.FC<SvgProps> = (props: SvgProps) => {
  const media = useScreenSize();

  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <G opacity={media.tablet ? 0.52 : 1}>
        <Path
          d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.0001 14L11.1001 11.1"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

const TouchableOpacity = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
`;

const Text = styled.Text`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: rgba(255, 255, 255, 0.52);
  margin-left: 10px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 25px;
  height: 25px;
`;

interface Props {
  onClick: () => void;
}

const SearchButton: React.FC<Props> = ({ onClick }: Props) => {
  const media = useScreenSize();
  return (
    <TouchableOpacity onPress={onClick}>
      {media.tablet ? <SearchIcon /> : <StyledSearchIcon />}
      {media.tablet && <Text>Search</Text>}
    </TouchableOpacity>
  );
};

export default SearchButton;
