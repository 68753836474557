import styled, { css } from 'styled-components/native';

export default styled.View<Props>`
  background-color: #121212;
  border: 2px solid rgba(255, 255, 255, 0.52);
  box-sizing: border-box;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  justify-content: center;
  align-items: center;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 140px;
      height: 140px;
      border-radius: 70px;
    `}
`;
