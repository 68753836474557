import styled from 'styled-components/native';

export default styled.Text<{ isLive?: boolean; withPlaceholder?: boolean }>`
  background-color: ${({ withPlaceholder, theme }) =>
    withPlaceholder ? theme.colors.backgroundDark : 'transparent'};
  border-radius: ${({ withPlaceholder }) => (withPlaceholder ? '8px' : '0')};
  color: ${({ isLive, theme }) =>
    isLive ? theme.colors.primaryActive : 'rgba(255, 255, 255, 0.52)'};
  font-size: 15px;
  font-weight: bold;
  height: 20px;
  margin-top: 10px;
  overflow: hidden;
  max-width: 164px;
  width: 100%;
`;
