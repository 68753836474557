import { memo } from 'react';
import styled, { css } from 'styled-components/native';

import Avatar, { AvatarProps } from 'components/Avatar';

export default memo(styled(Avatar)<AvatarProps>`
  ${({ mode }) =>
    mode === 'row' &&
    css`
      margin: 0;
    `}
`);
