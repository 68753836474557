import styled from 'styled-components';

export default styled.div`
  background: linear-gradient(270deg, #161c33 -6.41%, rgba(22, 28, 51, 0) 20%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 14px;
  pointer-events: none;
`;
