import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.marketplaceProductDetails.primaryText};
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  font-family: BasierCircle-Semibold;
  line-height: 25px;
`;
