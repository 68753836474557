import { memo } from 'react';
import isEqual from 'react-fast-compare';
import styled, { css } from 'styled-components/native';

interface Props {
  underline?: boolean;
}

export default memo(
  styled.Text<Props>`
    color: ${({ theme }) => theme.colors.primaryInactive};
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0ch.06px;
    width: 80%;
    margin: 0 auto;

    ${({ onPress, underline }) =>
      (onPress || underline) &&
      css`
        color: ${({ theme }) => theme.colors.textActive};
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.textActive};
      `}
  `,
  isEqual,
);
