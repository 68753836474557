import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import NavigationHeader from 'components/NavigationHeader';
import ScreenView from 'components/ScreenView';

import { VARIANT } from 'context/appInfo';
import { useMarketPlaceConversation } from 'hooks/marketplace';
import { useUser } from 'hooks/user';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import Description from './styled/Description';
import ErrorText from './styled/ErrorText';
import TextInput from './styled/TextInput';

export { SCREEN_NAME };

type MarketplaceNewMessageRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type MarketplaceNewMessageNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: MarketplaceNewMessageRouteProp;
  navigation: MarketplaceNewMessageNavigationProp;
};

const MarketplaceNewMessageScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { sendMessage } = useMarketPlaceConversation();
  const { profile } = VARIANT === 'artist' ? useUser() : { profile: null };
  const artistUsername = VARIANT === 'artist' ? profile?.username : route.params.artistUsername;
  const { marketplaceProductId, orderExternalId, onMessageAdded } = route.params;
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    message: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });

  const doSubmit = useCallback(() => {
    if (!isValid || isProcessing) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [handleSubmit, setIsProcessing]);

  const onSubmit = useCallback(
    async ({ message }: { message: string }) => {
      setIsProcessing(true);
      setError(null);
      try {
        if (artistUsername) {
          await sendMessage(artistUsername, marketplaceProductId, orderExternalId, message);
          onMessageAdded?.();
          navigation.goBack();
        }
      } catch (e) {
        setError(intl.formatMessage(messages.input.error.other));
      } finally {
        setIsProcessing(false);
      }
    },
    [artistUsername, marketplaceProductId, orderExternalId, navigation],
  );

  return (
    <ScreenView>
      <NavigationHeader
        insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
        navigation={navigation}
        title={intl.formatMessage(messages.header.title)}
        headerBackTitle={intl.formatMessage(messages.header.cancel)}
        headerBackShowIcon={false}
        headerRightLabel={intl.formatMessage(messages.header.save)}
        headerRightDisabled={!isValid || isSubmitting}
        headerRightOnPress={doSubmit}
      />
      <Container>
        {VARIANT == 'artist' && (
          <Description>{intl.formatMessage(messages.description)}</Description>
        )}
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInput
              label={intl.formatMessage(messages.input.label)}
              returnKeyType="go"
              autoCapitalize="none"
              value={value}
              onBlur={onBlur}
              multiline
              onChangeText={(value: any) => {
                clearErrors('message');
                onChange(value);
              }}
            />
          )}
          name="message"
          rules={{
            required: intl.formatMessage(messages.input.error.required),
          }}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Container>
    </ScreenView>
  );
};

export default MarketplaceNewMessageScreen;
