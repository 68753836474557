import { Platform } from 'react-native';
import styled from 'styled-components/native';

export default styled.Text`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textActive};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.textActive};
  margin: ${Platform.OS !== 'web' ? '20px' : 0} 0 3% 0;
  width: 100%;
  text-align: center;
`;
