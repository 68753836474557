import { NavigationContainerRef } from '@react-navigation/native';
import React, { MutableRefObject, useContext, useEffect, useMemo, useState } from 'react';

import ConfirmPaymentModalWeb from 'components/ConfirmPaymentModalWeb';

interface ModalState {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setArtistUsername: (value: string) => void;
  setArtistDisplayName: (value: string) => void;
}

const DEFAULT_STATE: ModalState = {
  isOpen: false,
  setIsOpen: () => {},
  setArtistUsername: () => {},
  setArtistDisplayName: () => {},
};

export const SubscriptionModalContext = React.createContext(DEFAULT_STATE);

export function useSubscriptionModalWeb(): ModalState {
  const context = useContext<ModalState>(SubscriptionModalContext);
  if (context === undefined) {
    throw new Error('useSubscriptionModalWeb must be used within SubscriptionModalWebProvider');
  }
  return context;
}

interface Props {
  navigationRef: MutableRefObject<NavigationContainerRef | null>;
  children: React.ReactNode;
}

export const SubscriptionModalWebProvider: React.FC<Props> = ({
  children,
  navigationRef,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [artistUsername, setArtistUsername] = useState<string>('');
  const [artistDisplayName, setArtistDisplayName] = useState<string>('');

  useEffect(() => {
    if (!isOpen) {
      setArtistUsername('');
      setArtistDisplayName('');
    }
  }, [isOpen]);

  const value = useMemo<ModalState>(
    () => ({ isOpen, setIsOpen, setArtistUsername, setArtistDisplayName }),
    [],
  );

  return (
    <SubscriptionModalContext.Provider value={value}>
      {children}
      {isOpen && (
        <ConfirmPaymentModalWeb
          navigationRef={navigationRef}
          artistUsername={artistUsername}
          artistFullName={artistDisplayName}
          setIsOpen={setIsOpen}
        />
      )}
    </SubscriptionModalContext.Provider>
  );
};
