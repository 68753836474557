import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Animated, Easing, Pressable } from 'react-native';

import imageSrc from './images/logo-text.png';
import messages from './messages';
import ArtistTagText from './styled/ArtistTagText';
import ArtistTagView from './styled/ArtistTagView';
import Image from './styled/Image';
import Mask from './styled/Mask';
import Wrapper from './styled/Wrapper';

interface LogoProps {
  isArtist?: boolean;
}

const Logo: React.FC<LogoProps> = ({ isArtist, ...rest }: LogoProps) => {
  const intl = useIntl();
  const handsWidth = 38;
  const fullWidth = useMemo(() => (isArtist ? 209 : 152), [isArtist]);
  const opacity = useRef(new Animated.Value(1)).current;
  const translateX = useRef(new Animated.Value(0)).current;
  const translateXMask = useRef(new Animated.Value(fullWidth)).current;

  const startAnimation = useCallback(() => {
    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 0,
        useNativeDriver: true,
        duration: 500,
        easing: Easing.out(Easing.cubic),
      }),
      Animated.parallel([
        Animated.timing(translateX, { toValue: fullWidth / 2, useNativeDriver: true, duration: 0 }),
        Animated.timing(translateXMask, {
          toValue: fullWidth / 2 + handsWidth,
          useNativeDriver: true,
          duration: 0,
        }),
      ]),
      Animated.timing(opacity, { toValue: 1, useNativeDriver: true, duration: 1000 }),
      Animated.parallel([
        Animated.timing(translateX, {
          toValue: 0,
          useNativeDriver: true,
          duration: 1000,
          delay: 250,
        }),
        Animated.timing(translateXMask, {
          toValue: fullWidth,
          useNativeDriver: true,
          duration: 1000,
          delay: 250,
        }),
      ]),
    ]).start();
  }, [fullWidth]);

  return (
    <Pressable {...rest} onPress={startAnimation}>
      <Wrapper style={{ opacity, transform: [{ translateX }] }}>
        <Image source={imageSrc} />
        {isArtist && (
          <ArtistTagView>
            <ArtistTagText>{intl.formatMessage(messages.feedTopBadge)}</ArtistTagText>
          </ArtistTagView>
        )}
      </Wrapper>
      <Mask style={{ transform: [{ translateX: translateXMask }] }} />
    </Pressable>
  );
};

export default Logo;
