import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 100%;
  opacity: 0.38;
  z-index: 1;
  border-radius: 14px;
`;
