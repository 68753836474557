import styled from 'styled-components/native';

import TextInput from 'components/TextInput';

import media from 'utils/responsive';

export default styled(TextInput)`
  min-width: 250px;
  max-width: 370px;
  margin: 5px auto;

  ${media.tablet} {
    width: calc(50% - 16px);
    margin: 25px 8px 0;
  }
`;
