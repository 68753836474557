import React from 'react';

import Fade from 'components/Transitions/Fade/Fade';

import ActivityIndicator from './styled/ActivityIndicator';
import View from './styled/View';

export interface LoadingScreenOverlayProps {}

const LoadingScreenOverlay: React.FC<LoadingScreenOverlayProps> = ({ ...restProps }) => {
  return (
    <Fade {...restProps}>
      <View>
        <ActivityIndicator animating />
      </View>
    </Fade>
  );
};

export default LoadingScreenOverlay;
