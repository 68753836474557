import cardValidator from 'card-validator';
import { Platform } from 'react-native';

const ECRYPT_PUBLIC_KEY =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7UGdqIxLyamdeNUFOuD5cce2PnXo9rcL3ahBMcB1hQ6SYgXj/jrjJf84meLh0aIvW2WZzcw2coqK+lIlTqkOLiVbSTvUg/xchMsf0o1a4YJbGFW3Awfsaq3oiTsvjOyVZA/0Joy23saMlWxkZGSsu9RJx1yO92c+CFRhvYRm4I7Sf2oKZkKQjoWQYRwUWcKyI4zIv9ObCaiAXH/je/Jmug6lifnvmYb0J4UCTOrAHR9Y5DjvF3szCt/UQnWP/76G4P/x+fouAFZ7iW48LGHQUmbRUSbeW5TmTRKJGFgyZUDqumjlqtqPn4hOJyxbwIualevRET+qJ3XhkDH/53MQkQIDAQAB';

export const parseDigits = (str: string): string => (str.match(/\d+/g) || []).join('');

export const formatCardNumber = (str: string): string => {
  const result = cardValidator.number(str);
  if (result.card && result.card.gaps) {
    let formattedStr = parseDigits(str);
    let offset = 0;

    for (let i = 0; i < result.card.gaps.length; ++i) {
      const gapIndex = result.card.gaps[i] + offset;
      if (gapIndex > str.length - 1) {
        break;
      }
      formattedStr =
        formattedStr.slice(0, gapIndex) + ' ' + formattedStr.slice(gapIndex, formattedStr.length);
      ++offset;
    }

    if (result.card.lengths) {
      const maxLength = result.card.lengths[result.card.lengths.length - 1];
      return formattedStr.substring(0, maxLength + result.card.gaps.length);
    }

    return formattedStr;
  }
  return str;
};

export const getLastFourDigits = (str: string): string => {
  return parseDigits(str.substr(str.length - 4, 4));
};

export const getMaskedNumber = (str: string): string => {
  const formattedNumber = formatCardNumber(str.substring(0, str.length - 4));
  const maskedNumber = formattedNumber
    .split(' ')
    .map((part) => new Array(part.length).fill('•').join(''))
    .join(' ');
  const lastFourDigits = str.substr(str.length - 4, 4);
  return `${maskedNumber} ${lastFourDigits}`;
};

export const formatCardExpiry = (str: string): string => {
  let formattedStr = parseDigits(str);
  if (formattedStr.length > 2) {
    formattedStr = formattedStr.slice(0, 2) + '/' + formattedStr.slice(2, formattedStr.length);
  }
  return formattedStr.substring(0, 5);
};

/**
 * Encrypts Credit Card data using APEXX CSE (Client Side Encryption)
 * @param publicKey
 * @param cardHolderName
 * @param cardNumber
 * @param expiryMonth
 * @param expiryYear
 * @param cvv
 * @returns
 */
export const encryptCardData = async (data: {
  card_holder_name: string;
  card_number: string;
  expiry_month: string;
  expiry_year: string;
  cvv: string;
}) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { JSEncrypt } = require('jsencrypt');
    const encrypt = new JSEncrypt({});
    encrypt.setPublicKey(ECRYPT_PUBLIC_KEY);
    return encrypt.encrypt(JSON.stringify(data));
  } else {
    return null;
  }
};
