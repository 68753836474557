import styled, { css } from 'styled-components/native';

interface TextProps {
  active?: boolean;
  activeLabelColor?: string;
  inactiveLabelColor?: string;
}

export default styled.Text<TextProps>`
  ${({ active, theme, activeLabelColor, inactiveLabelColor }) => css`
    color: ${active
      ? activeLabelColor || theme.colors.background
      : inactiveLabelColor || theme.colors.primaryInactive};
  `}
`;
