import { FC, useState } from 'react';
import { TouchableOpacityProps } from 'react-native';

import ArrowIcon from 'components/OptionGroupItem/styled/ArrowIcon';

import Content from './styled/Content';
import Fade from './styled/Fade';
import ImageBackground from './styled/ImageBackground';
import ImageWrapper from './styled/ImageWrapper';
import Label from './styled/Label';
import LabelMainText from './styled/LabelMainText';
import LabelSecondaryText from './styled/LabelSecondaryText';
import TouchableOpacity from './styled/TouchableOpacity';

interface Props {
  label?: string;
  imageSrc?: string;
}

const ProfileDashboardButton: FC<Props & TouchableOpacityProps> = ({
  label,
  imageSrc,
  ...rest
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <TouchableOpacity {...rest}>
      <Content>
        <ImageWrapper>
          <Fade isVisible={isImageLoaded}>
            <ImageBackground source={{ uri: imageSrc }} onLoad={() => setIsImageLoaded(true)} />
          </Fade>
        </ImageWrapper>
        <Label>
          <LabelMainText>{label}</LabelMainText>
          <LabelSecondaryText>Show profile</LabelSecondaryText>
        </Label>
      </Content>
      <ArrowIcon />
    </TouchableOpacity>
  );
};

export default ProfileDashboardButton;
