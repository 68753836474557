import styled, { css } from 'styled-components/native';

export default styled.Text`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  letter-spacing: 0.41px;
  margin-right: 6px;
  font-size: 28px;
  line-height: 36px;
  text-align: center;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      font-size: 34px;
      line-height: 41px;
      text-align: left;
    `}
`;
