import { useNavigation, useRoute } from '@react-navigation/core';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as FAN_MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack';

import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDERS_SCREEN_NAME } from 'screens/MarketplaceOrdersScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';

import ArtistMarketplaceOrderHeader from 'components/ArtistMarketplaceOrderHeader';
import NavigationHeader from 'components/NavigationHeader/NavigationHeader';
import OptionsGroup from 'components/OptionsGroup';

import { useSnackbarSet, VARIANT, WebviewContext } from 'context';
import {
  useArtistMarketplaceUpdateOrder,
  useFanMarketplaceUpdateOrder,
  useMarketplaceOrderControls,
} from 'hooks/marketplace';
import { useTheme } from 'themes';
import { OrderStatus } from 'types';
import { helpURL } from 'utils/links';
import { parseItemPrice } from 'utils/marketplace';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Avatar from './styled/Avatar';
import ConfirmationButton from './styled/ConfirmationButton';
import Container from './styled/Container';
import HeaderWrapper from './styled/HeaderWrapper';
import IconCancel from './styled/IconCancel';
import IconDecline from './styled/IconDecline';
import IconFlag from './styled/IconFlag';
import IconHelp from './styled/IconHelp';
import IconWrapper from './styled/IconWrapper';
import OptionGroupItem from './styled/OptionGroupItem';
import OrderDetailsHeader from './styled/OrderDetailsHeader';
import OrderStatusDescription from './styled/OrderStatusDescription';
import Row from './styled/Row';
import ScreenView from './styled/ScreenView';

import ConfirmCompletingOrderDialog from 'dialogs/ConfirmCompletingOrderDialog';

export { SCREEN_NAME };

interface Params {
  order: string;
  marketplaceProductId: number;
}

const MarketplaceOrderInformationScreen: React.FC = () => {
  const navigation = useNavigation<any>();
  const route = useRoute();
  const intl = useIntl();
  const { theme } = useTheme();
  const { order: paramOrder, marketplaceProductId } = route.params as Params;
  const {
    order,
    isDialogOpen,
    setIsDialogOpen,
    onDialogConfirmPress,
    onActionPress,
    showCTA,
    showStatus,
    refresh,
  } = useMarketplaceOrderControls(JSON.parse(paramOrder));
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
  const { updateOrder: updateOrderArtist } = useArtistMarketplaceUpdateOrder();
  const { updateOrder: updateOrderFan } = useFanMarketplaceUpdateOrder(
    order?.product.artist?.username || '',
    marketplaceProductId,
    order?.external_id || '',
  );
  const { openWebView } = useContext(WebviewContext);
  const { setSnackbar } = useSnackbarSet();

  const navigateToOrderReport = () => {
    navigation.navigate(MARKETPLACE_ORDER_REPORT_SCREEN_NAME, {
      orderExternalId: order?.external_id,
      artistUsername: order?.product.artist?.username,
      marketplaceProductId,
    });
  };

  const navigateToOrderDetails = useCallback(() => {
    navigation.navigate(MARKETPLACE_ORDER_DETAILS_SCREEN_NAME, {
      marketplaceProductId,
      order: JSON.stringify(order),
    });
  }, [navigation, order]);

  const navigateToArtist = () => {
    navigation.navigate(ARTIST_PROFILE_SCREEN_NAME, {
      artistUsername: order?.product.artist.username,
    });
  };

  const navigateToProductDetails = useCallback(() => {
    if (!order) return;
    navigation.navigate(MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME, {
      artist: order.product.artist.username,
      productId: order.product.id,
    });
  }, [navigation, order]);

  const confirmButtonCopy = useMemo(() => {
    if (VARIANT === 'artist' && order?.is_artist_asked_user_for_confirmation)
      return intl.formatMessage(messages.awaitingConfirmation);
    switch (order?.status) {
      case OrderStatus.WAITING_FOR_PAYMENT:
        return intl.formatMessage(messages[VARIANT].continueOrder);
      default:
        return intl.formatMessage(messages[VARIANT].confirm);
    }
  }, [order?.status, order?.is_artist_asked_user_for_confirmation]);

  const onCancelPress = () => {
    if (VARIANT === 'artist') {
      setCancelDialogOpen(true);
    } else {
      updateOrderFan(OrderStatus.CANCEL).then(() => {
        navigation.navigate(FAN_MARKETPLACE_STACK_SCREEN_NAME, {
          screen: FAN_MARKETPLACE_STACK_SCREEN_NAME,
          params: {
            screen: MARKETPLACE_ORDERS_SCREEN_NAME,
          },
        });
        setSnackbar({
          visible: true,
          label: intl.formatMessage(messages.cancelNotification),
          actionLabel: 'Close',
          dismissAfter: 3000,
        });
      });
    }
  };

  const onDecline = useCallback(() => {
    if (!order) return;
    updateOrderArtist(order.product.id, order?.external_id, OrderStatus.DECLINED).then(() =>
      refresh(),
    );
    setCancelDialogOpen(false);
  }, [updateOrderArtist, refresh]);

  const onHelpPress = useCallback(() => {
    openWebView?.(helpURL);
  }, [openWebView]);

  /**
   * show decline for artist and cancel order for stan
   */
  const showCancel = useMemo(() => {
    if (VARIANT === 'fan') {
      return order?.status === OrderStatus.WAITING_FOR_PAYMENT;
    } else {
      return order?.status === OrderStatus.PURCHASED || order?.status === OrderStatus.IN_PROGRESS;
    }
  }, [order?.status]);

  const options = useMemo(() => {
    const cancel = {
      labelPrefixComponent: (
        <IconWrapper>{VARIANT === 'fan' ? <IconCancel /> : <IconDecline />}</IconWrapper>
      ),
      label: intl.formatMessage(messages[VARIANT].cancel),
      noArrow: true,
      onAction: onCancelPress,
    };
    const report = {
      labelPrefixComponent: (
        <IconWrapper>
          <IconFlag />
        </IconWrapper>
      ),
      label: intl.formatMessage(messages.report),
      labelColor: theme.colors.errorAccent,
      noArrow: true,
      onAction: navigateToOrderReport,
    };
    return showCancel ? [cancel, report] : [report];
  }, [showCancel]);

  return (
    <ScreenView>
      <Container stickyHeaderIndices={[0]}>
        <NavigationHeader
          insets={{ top: 15 }}
          navigation={navigation}
          title={order?.number}
          headerBackOnPress={navigateToOrderDetails}
        />
        <HeaderWrapper>
          {VARIANT === 'fan' ? (
            <OrderDetailsHeader
              artistName={order?.product.artist.display_name || ''}
              name={order?.product.name || ''}
              previewImage={order?.product.low_res_cover || ''}
              onPress={navigateToProductDetails || ''}
            />
          ) : (
            <ArtistMarketplaceOrderHeader
              cover={order?.product.low_res_cover || ''}
              avatar={order?.user.low_res_profile_picture || ''}
              title={order?.product.name || ''}
              description={parseItemPrice(order?.payment?.amount, order?.payment?.amount_currency)}
            />
          )}
        </HeaderWrapper>

        {showCTA && (
          <ConfirmationButton
            disabled={VARIANT === 'artist' && order?.is_artist_asked_user_for_confirmation}
            onPress={onActionPress}
          >
            {confirmButtonCopy}
          </ConfirmationButton>
        )}

        <Row>
          {VARIANT === 'fan' ? (
            <OptionGroupItem
              labelPrefixComponent={
                <Avatar
                  mode="minimal-row"
                  imageUrl={order?.product.artist.low_res_profile_picture}
                  showTimestamp={false}
                  showName={false}
                />
              }
              label={order?.product.artist.display_name || ''}
              onAction={navigateToArtist}
            />
          ) : (
            <OptionGroupItem
              labelPrefixComponent={
                <Avatar
                  mode="minimal-row"
                  imageUrl={order?.user.low_res_profile_picture}
                  showTimestamp={false}
                  showName={false}
                />
              }
              label={order?.user.full_name || ''}
              noArrow
            />
          )}
        </Row>

        <Row>
          <OptionGroupItem
            labelPrefixComponent={
              <IconWrapper>
                <IconHelp />
              </IconWrapper>
            }
            label={intl.formatMessage(messages.help)}
            onAction={onHelpPress}
          />
        </Row>

        <Row>
          <OptionsGroup itemStyle={{ height: 62 }} options={options} />
        </Row>

        {showStatus && order && (
          <OrderStatusDescription
            status={order?.status}
            returnFundsDays={12}
            date={order?.time_updated}
          />
        )}
      </Container>

      <ConfirmCompletingOrderDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onConfirmation={onDialogConfirmPress}
      />
      {VARIANT === 'artist' && (
        <ConfirmCompletingOrderDialog
          isOpen={isCancelDialogOpen}
          setIsOpen={setCancelDialogOpen}
          onConfirmation={onDecline}
          isDecline
        />
      )}
    </ScreenView>
  );
};

export default MarketplaceOrderInformationScreen;
