import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { VARIANT } from 'context';
import { useConfig, useScreenSize, useUser } from 'hooks';

import messages from './messages';
import ArtistNameBadge from './styled/ArtistNameBadge';
import ArtistNameBox from './styled/ArtistNameBox';
import ArtistNameText from './styled/ArtistNameText';
import BecomeStanIcon from './styled/BecomeStanIcon';
import ButtonsView from './styled/ButtonsView';
import ContentWrapper from './styled/ContentWrapper';
import DescriptionText from './styled/DescriptionText';
import DescriptionView from './styled/DescriptionView';
import FollowButton from './styled/FollowButton';
import GlobeIcon from './styled/GlobeIcon';
import IconCheckSubscribed from './styled/IconCheckSubscribed';
import InfoView from './styled/InfoView';
import LinkIcon from './styled/LinkIcon';
import LinksView from './styled/LinksView';
import LinkText from './styled/LinkText';
import LinkView from './styled/LinkView';
import LiveBadge from './styled/LiveBadge';
import LiveViewersCountView from './styled/LiveViewersCountView';
import ProfileImage from './styled/ProfileImage';
import SubscribeButton from './styled/SubscribeButton';
import View from './styled/View';
import { ArtistProfileHeaderProps } from './types';

type Props = ArtistProfileHeaderProps & {
  onLoginPress?: () => void;
};

const ArtistProfileHeader: React.FC<Props> = ({
  name,
  username,
  description,
  firstLink,
  secondLink,
  imageUrl,
  isLoadingSubscribed = false,
  isSubscribed = false,
  isSubscriptionPendingCancelation = false,
  isLoadingFollowing = false,
  isFollowing = false,
  isLive = false,
  onSubscribePress,
  onFollowPress,
  onLoginPress,
  onLinkPress,
  ...restProps
}: Props) => {
  const intl = useIntl();
  const { isSignedIn } = useUser();
  const { config } = useConfig();
  const navigation = useNavigation();
  const { tablet: isTablet } = useScreenSize();

  const detectHttpOrHttps = (link?: string) => {
    return link?.replace('https://', '').replace('http://', '').replace('www.', '');
  };

  const modifiedFirstLink = useMemo(() => detectHttpOrHttps(firstLink), [firstLink]);
  const modifiedSecondLink = useMemo(() => detectHttpOrHttps(secondLink), [secondLink]);

  const renderLiveBadge = () => (
    <LiveViewersCountView>
      <LiveBadge isLive={isLive} artistUsername={username ?? ''} navigation={navigation} />
    </LiveViewersCountView>
  );

  return (
    <View {...restProps}>
      <InfoView>
        <ProfileImage imageUrl={imageUrl} />
        <ContentWrapper>
          {isTablet && renderLiveBadge()}
          <ArtistNameBox>
            <ArtistNameText>{name}</ArtistNameText>
            {VARIANT !== 'artist' && (
              <ArtistNameBadge
                onSubscribePress={onSubscribePress}
                variant={
                  isSubscriptionPendingCancelation
                    ? 'canceled'
                    : isSubscribed
                    ? 'subscribed'
                    : 'locked'
                }
              />
            )}
          </ArtistNameBox>
          {!isTablet && renderLiveBadge()}
          {!!description && (
            <DescriptionView>
              <DescriptionText>{description}</DescriptionText>
            </DescriptionView>
          )}
          <LinksView>
            {!!modifiedFirstLink && !!firstLink && config.artistProfileShowPrimaryUrl && (
              <LinkView>
                <GlobeIcon />
                <LinkText onPress={() => onLinkPress?.(firstLink)}>{modifiedFirstLink}</LinkText>
              </LinkView>
            )}
            {!!modifiedSecondLink && !!secondLink && config.artistProfileShowSecondaryUrl && (
              <LinkView>
                <LinkIcon />
                <LinkText onPress={() => onLinkPress?.(secondLink)}>{modifiedSecondLink}</LinkText>
              </LinkView>
            )}
          </LinksView>
        </ContentWrapper>
      </InfoView>

      <ButtonsView>
        {!isSignedIn && (
          <SubscribeButton icon={BecomeStanIcon} onPress={onLoginPress} iconAlign={'right'}>
            {intl.formatMessage(messages.login)}
          </SubscribeButton>
        )}
        {isSignedIn && !isSubscribed && onSubscribePress && (
          <SubscribeButton
            disabled={isSubscribed}
            icon={
              !isLoadingSubscribed && !isSubscriptionPendingCancelation
                ? isSubscribed
                  ? IconCheckSubscribed
                  : BecomeStanIcon
                : undefined
            }
            processing={isLoadingSubscribed}
            onPress={onSubscribePress}
            iconAlign={isSubscribed ? 'left' : 'right'}
          >
            {intl.formatMessage(
              isSubscriptionPendingCancelation
                ? messages.pendingCancelation
                : isSubscribed
                ? messages.subscribed
                : messages.subscribe,
            )}
          </SubscribeButton>
        )}
        {isSignedIn && !isSubscribed && onFollowPress && (
          <FollowButton
            secondary={!isFollowing}
            processing={isLoadingFollowing}
            onPress={onFollowPress}
          >
            {intl.formatMessage(isFollowing ? messages.following : messages.follow)}
          </FollowButton>
        )}
      </ButtonsView>
    </View>
  );
};

export default ArtistProfileHeader;
