import React from 'react';

import Text from './styled/Text';
import TouchableOpacity from './styled/TouchableOpacity';

export interface ButtonWhitelabelProps {
  onPress?: () => void;
  text?: string;
  disabled?: boolean;
}

const ButtonWhitelabel: React.FC<ButtonWhitelabelProps> = ({
  disabled,
  text,
  onPress = () => {},
  ...rest
}: ButtonWhitelabelProps) => (
  <TouchableOpacity {...rest} disabled={disabled} onPress={disabled ? () => {} : onPress}>
    <Text>{text}</Text>
  </TouchableOpacity>
);

ButtonWhitelabel.displayName = 'ButtonWhitelabel';

export default ButtonWhitelabel;
