import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs({
  short: true,
  labelColor: '#ffffff',
  bold: false,
})`
  border-radius: 40px;
  height: 44px;
  background-color: #0537ff;
  padding-left: 45px;
  padding-right: 45px;
  width: auto;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      width: 335px;
    `}
`;
