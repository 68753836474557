import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  position: absolute;
  top: 27px;
  left: 32px;
  width: 142px;
  height: 30px;
  z-index: 100;
`;
