import styled, { css } from 'styled-components/native';

import handsup from '../images/handsup.png';

const StyledBecameStanIcon = styled.Image.attrs({
  resizeMode: 'contain',
  source: handsup,
})`
  width: 18px;
  height: 16px;
  margin-left: 0;

  ${({ theme }) =>
    theme.media.tablet &&
    css`
      margin-left: 7px;
    `}
`;

export default StyledBecameStanIcon;
