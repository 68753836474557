import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

interface Props {
  dark?: boolean;
}

export default styled(LinearGradient).attrs(({ dark }: Props) => ({
  colors: dark ? ['rgba(0, 0, 0, 0.6)', 'transparent'] : ['rgba(0, 0, 0, 0.2)', 'transparent'],
  locations: [0, dark ? 0.3714 : 1],
}))<Props>`
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 26;
`;
