import styled, { css } from 'styled-components/native';

interface ButtonProps {
  disabled?: boolean;
}

export default styled.TouchableOpacity<ButtonProps>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;
