import styled from 'styled-components/native';

import { PadlockSmall } from 'svg';

const SvgComponent = styled(PadlockSmall)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 27;
`;

export default SvgComponent;
