import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import styled, { css } from 'styled-components/native';

interface Props {
  onPress?: () => void;
  isExpanded?: boolean;
}

const TouchableOpacity = styled.TouchableOpacity<Props>`
  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      margin-right: 10px;
    `}
`;

const Icon = styled(AntDesign).attrs(() => ({
  name: 'ellipsis1',
  color: '#9e9e9e',
  size: 26,
}))``;

const More: React.FC<Props> = ({ onPress, isExpanded }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} isExpanded={isExpanded}>
      <Icon />
    </TouchableOpacity>
  );
};

export default More;
