import styled, { css } from 'styled-components/native';

import TextInput from 'components/TextInput';

interface TextInputProps {
  inline?: boolean;
  first?: boolean;
  last?: boolean;
}

export default styled(TextInput)<TextInputProps>`
  position: relative;
  width: 100%;
  margin: 0 0 25px 0;

  ${({ inline }) =>
    inline &&
    css`
      flex: 1;
      width: auto;
    `}

  ${({ first }) =>
    first &&
    css`
      margin-right: 7px;
    `}

  ${({ last }) =>
    last &&
    css`
      margin-left: 7px;
    `}
`;
