import React, { ReactNode } from 'react';
import { TouchableOpacityProps } from 'react-native';

import BackIcon from './styled/BackIcon';
import CloseIcon from './styled/CloseIcon';
import IconView from './styled/IconView';
import MoreIcon from './styled/MoreIcon';
import Text from './styled/Text';
import TouchableOpacity from './styled/TouchableOpacity';

type OverlayButtonProps = TouchableOpacityProps & {
  icon?: ReactNode | PredefinedIcon;
  text?: string;
  transparent?: boolean;
};

type PredefinedIcon = 'close' | 'back' | 'more';

const ICONS_MAP: Record<PredefinedIcon, React.ReactNode> = {
  close: <CloseIcon />,
  back: <BackIcon />,
  more: <MoreIcon />,
};

const OverlayButton: React.FC<OverlayButtonProps> = ({
  icon,
  text,
  onPress,
  transparent,
  ...restProps
}: OverlayButtonProps) => {
  let iconNode = icon;

  if (typeof icon === 'string' && !!ICONS_MAP[icon as PredefinedIcon]) {
    iconNode = ICONS_MAP[icon as PredefinedIcon];
  }

  return (
    <TouchableOpacity
      text={text}
      onPress={onPress}
      {...restProps}
      transparent={transparent}
      testID="TouchableOpacity"
    >
      {icon && <IconView>{iconNode}</IconView>}
      {text && <Text>{text}</Text>}
    </TouchableOpacity>
  );
};

export default OverlayButton;
