export default {
  message: {
    id: 'feed.empty.message',
    defaultMessage:
      'You are currently a fan but not quite a Stan. Subscribe to an artist to see their latest posts and exclusive content.',
  },
  ctaLabel: {
    id: 'feed.empty.ctaLabel',
    defaultMessage: 'Start Searching',
  },
};
