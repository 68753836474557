import * as React from 'react';
import Svg, { Circle, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function StaticCirclesSvg(props: SvgProps) {
  return (
    <Svg width="88" height="88" viewBox="0 0 88 88" fill="none" {...props}>
      <Circle cx="44" cy="44" r="44" fill="#0734ea" fillOpacity="1" />
      <Circle cx="44" cy="44" r="39" fill="#092cb6" fillOpacity="1" />
      <Circle cx="44" cy="44" r="34" fill="#0b258e" fillOpacity="1" />
    </Svg>
  );
}

export default styled(StaticCirclesSvg)``;
