import React, { createContext } from 'react';

import { useLocalStorage } from 'hooks/localStorage';

interface State {
  isOnBoardingStorageLoading: boolean;
  onBoardingStorage: boolean;
  setOnBoardingStorage: (value: any) => Promise<void>;
  deleteOnBoardingStorage: any;
}

interface Props {
  children: React.ReactNode;
}

const defaultContext: State = {
  isOnBoardingStorageLoading: true,
  onBoardingStorage: true,
  setOnBoardingStorage: (value) => Promise.resolve(value),
  deleteOnBoardingStorage: () => null,
};

export const OnBoardingContext = createContext(defaultContext);

export const OnBoardingContextProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    value: onBoardingStorage,
    setValueWrapper: setOnBoardingStorage,
    deleteValue: deleteOnBoardingStorage,
    loading: isOnBoardingStorageLoading,
  } = useLocalStorage('shouldMoveToOnBoarding', true);

  return (
    <OnBoardingContext.Provider
      value={{
        isOnBoardingStorageLoading,
        onBoardingStorage,
        setOnBoardingStorage,
        deleteOnBoardingStorage,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};
