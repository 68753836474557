import styled from 'styled-components/native';

import ScreenView from 'components/ScreenView';

export default styled(ScreenView)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  justify-content: space-between;
  flex-direction: column;
`;
