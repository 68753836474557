import styled from 'styled-components/native';

export default styled.Text`
  width: 100%;
  text-align: center;
  font-size: 17px;
  line-height: 22px;
  font-family: 'BasierCircle-Regular';
  color: ${({ theme }) => theme.colors.text};
  padding: 18px 16px 15px;
`;
