export default {
  artistLabel: {
    id: 'commentItem.artistLabel',
    defaultMessage: 'Artist',
  },
  report: {
    id: 'commentItem.report',
    defaultMessage: 'Report',
  },
  block: {
    id: 'commentItem.block',
    defaultMessage: 'Block',
  },
  delete: {
    id: 'commentItem.delete',
    defaultMessage: 'Delete',
  },
  showMore: {
    id: 'commentItem.showMore',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'commentItem.showLess',
    defaultMessage: 'Show less',
  },
};
