import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_NEW_POST_STACK } from 'stacks/artist/ArtistNewPostStack/constants';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_EDIT_POST_SCREEN } from 'screens/ArtistEditPostScreen/constants';
import { SCREEN_NAME as ARTIST_MY_PROFILE_SCREEN } from 'screens/ArtistMyProfileScreen/constants';
import { SCREEN_NAME as ARTIST_POST_INSIGHTS_SCREEN_NAME } from 'screens/ArtistPostInsightsScreen/constants';
import messages from 'screens/SinglePostScreen/messages';
import FeedItemPlaceholder from 'screens/SinglePostScreen/styled/FeedItemPlaceholder';

import KeyboardAvoidingView from 'components/KeyboardAvoidingView';

import { useFeedItemUpdate } from 'context';
import { useArtistProduct, useConfig, useUser } from 'hooks';
import { ArtistProduct, Comments } from 'types';
import { shareProduct } from 'utils/sharing';
import { getProfileLowResPictureUrl } from 'utils/user';

import { SCREEN_NAME } from './constants';
import ContentWithComments from './styled/ContentWithComments';
import FeedItem from './styled/FeedItem';
import OverlayButton from './styled/OverlayButton';
import SafeAreaView from './styled/SafeAreaView';
import View from './styled/View';

export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistSinglePostScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const isFocused = useIsFocused();
  const { config } = useConfig();
  const { artistUsername, productType, productId } = route.params;
  const restoredProduct = route.params.product;
  const { product, mutate } = useArtistProduct({
    artistUsername,
    productType,
    productId,
  });
  const [overlayButtonMarginTop, setOverlayButtonMarginTop] = useState<number>(15);
  const { updateFeedItem } = useFeedItemUpdate();
  const { profile } = useUser();
  const activateRef = useRef<(() => void) | null>();
  const deactivateRef = useRef<(() => void) | null>();

  const [displayProduct, setDisplayProduct] = useState<ArtistProduct | undefined>(restoredProduct);

  useEffect(() => {
    if (!isFocused) {
      deactivateRef.current?.();
    }
  }, [isFocused]);

  const refActivate = (activateFn: (() => void) | null) => {
    activateRef.current = activateFn;
  };

  const refDeactivate = (deactivateFn: (() => void) | null) => {
    deactivateRef.current = deactivateFn;
  };

  const onCloseButtonPress = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(ARTIST_MY_PROFILE_SCREEN);
  }, [navigation]);

  const onSharePress = useCallback(async () => {
    if (!displayProduct) {
      return;
    }

    shareProduct(displayProduct);
  }, [displayProduct]);

  const onEditPress = useCallback(() => {
    navigation.navigate(ARTIST_NEW_POST_STACK, {
      screen: ARTIST_EDIT_POST_SCREEN,
      params: {
        product: displayProduct,
      },
    });
  }, [navigation, displayProduct]);

  const navigateToPostInsights = useCallback(() => {
    displayProduct &&
      navigation.navigate(ARTIST_POST_INSIGHTS_SCREEN_NAME, {
        id: displayProduct.id,
        model_name: displayProduct.model_name,
      });
  }, [navigation, displayProduct]);

  const renderContent = useCallback(() => {
    if (!displayProduct) {
      return <FeedItemPlaceholder />;
    }

    return (
      <FeedItem
        product={displayProduct}
        isExpanded
        videoOptions={{
          autoPlay: true,
          startMuted: false,
          showVideoTimestamp: true,
          showProgressBar: true,
          dataIndex: displayProduct.id,
          refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
            refDeactivate(deactivateFn),
        }}
        audioOptions={{
          dataIndex: displayProduct.id,
          refDeactivate: (index: number, deactivateFn: (() => void) | null) =>
            refDeactivate(deactivateFn),
        }}
        pollOptions={{
          answerable: false,
          showResults: true,
        }}
        showCommentsBottomCounter={
          config.singlePostCommentsEnabled && displayProduct.has_comment_access_code !== 'disabled'
        }
        roundedCorners={false}
        showCaption
        showControls
        showControlsBelowContent
        showInsightsButton
        showEdit
        showMore={false}
        onSeeInsightsPress={navigateToPostInsights}
        onSharePress={onSharePress}
        onEditPress={onEditPress}
        overlayButtonMarginTop={overlayButtonMarginTop}
      />
    );
  }, [displayProduct, onSharePress, onEditPress, navigateToPostInsights]);

  const onCommentsChange = (comments: Comments) => {
    mutate({ ...product, comments_count: comments.length });
  };

  useEffect(() => {
    if (product) {
      setDisplayProduct(product);
      updateFeedItem(product, { comments_count: product.comments_count });
    }
  }, [product?.comments_count]);

  return (
    <KeyboardAvoidingView>
      <View>
        <ContentWithComments
          commentAccess={displayProduct?.has_comment_access_code}
          artistUsername={artistUsername}
          artistDisplayName={displayProduct?.artist.display_name}
          productId={productId}
          productModelName={productType}
          profilePicture={getProfileLowResPictureUrl(profile)}
          displayName={displayProduct?.artist.display_name}
          emptyListMessage={
            !displayProduct?.comments_count ? intl.formatMessage(messages.emptyMessage) : ''
          }
          ContentComponent={renderContent()}
          onCommentsChange={onCommentsChange}
        />

        <SafeAreaView pointerEvents="box-none">
          <OverlayButton
            icon="close"
            onPress={onCloseButtonPress}
            onLayout={(event: { nativeEvent: { layout: { y: number } } }) => {
              if (event.nativeEvent.layout.y > overlayButtonMarginTop) {
                setOverlayButtonMarginTop(event.nativeEvent.layout.y);
              }
            }}
          />
        </SafeAreaView>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ArtistSinglePostScreen;
