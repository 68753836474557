import styled, { css } from 'styled-components/native';

import { mediaWeb } from 'utils/styling';

export default styled.FlatList`
  padding: 0 15px;
  ${mediaWeb(css`
    padding: 0;
  `)}
`;
