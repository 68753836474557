import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Item from 'components/ArtistsList/styled/Item';
import List from 'components/ArtistsList/styled/List';
import Separator from 'components/ArtistsList/styled/Separator';
import Dialog from 'components/Dialog';
import FetchedDataContainer from 'components/FetchedDataContainer';

import { useSnackbarSet } from 'context';
import { useBlockedUsers } from 'hooks';
import { BlockedUser } from 'types';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Action from './styled/Action';
import Avatar from './styled/Avatar';
import CancelButton from './styled/CancelButton';
import MainView from './styled/MainView';
import UnblockButton from './styled/UnblockButton';

export { SCREEN_NAME };

const BlockedUsersScreen: React.FC = () => {
  const intl = useIntl();
  const [isModalVisible, setModalVisible] = useState(false);
  const [user, setUser] = useState<BlockedUser | null>(null);
  const [isUnblocking, setIsUnblocking] = useState(false);
  const { setSnackbar } = useSnackbarSet();

  const { blockedUsers, mutate, error, isLoading, unblock } = useBlockedUsers();

  const extractKey = (item: unknown): string => `${(item as BlockedUser).username}`;

  const shortenName = (name: string) => (name.length > 22 ? `${name.substring(0, 22)}...` : name);

  const userValues = useMemo(
    () => ({
      name: () => shortenName(user?.full_name || user?.username || ''),
    }),
    [user],
  );

  const onUnblockPress = useCallback(async () => {
    let snackbarTitle;
    try {
      setIsUnblocking(true);
      user && (await unblock(user.username));
      await mutate();
      snackbarTitle = intl.formatMessage(messages.snackbar.title, userValues);
    } catch (e) {
      snackbarTitle = intl.formatMessage(messages.error.unblock);
    } finally {
      setSnackbar({
        visible: true,
        label: snackbarTitle,
        actionLabel: intl.formatMessage(messages.snackbar.close),
        dismissAfter: 5000,
      });
      setIsUnblocking(false);
      setModalVisible(false);
    }
  }, [mutate, unblock, user, userValues, intl]);

  const renderItem = ({ item }: { item: any }) => {
    const typedItem = item as BlockedUser;
    const name = shortenName(typedItem.full_name || typedItem.username);

    return (
      <Item>
        <Avatar mode="minimal-row-big" name={name} imageUrl={typedItem.profile_picture} />
        <Action
          onPress={() => {
            setUser(typedItem);
            setModalVisible(true);
          }}
        >
          {intl.formatMessage(messages.cta)}
        </Action>
      </Item>
    );
  };

  return (
    <>
      <MainView>
        <FetchedDataContainer isLoading={isLoading} isError={!!error}>
          <List
            data={blockedUsers}
            keyExtractor={extractKey}
            renderItem={renderItem}
            ItemSeparatorComponent={Separator}
          />
        </FetchedDataContainer>
      </MainView>
      <Dialog
        visible={isModalVisible}
        title={intl.formatMessage(messages.dialog.title, userValues)}
        subtitle={intl.formatMessage(messages.dialog.description, userValues)}
      >
        <UnblockButton processing={isUnblocking} onPress={onUnblockPress}>
          {intl.formatMessage(messages.cta)}
        </UnblockButton>
        <CancelButton onPress={() => setModalVisible(false)}>
          {intl.formatMessage(messages.dialog.cancel)}
        </CancelButton>
      </Dialog>
    </>
  );
};

export default BlockedUsersScreen;
