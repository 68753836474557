import { Animated } from 'react-native';
import styled from 'styled-components/native';

import { VARIANT } from 'context/appInfo';

export default styled(Animated.View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${VARIANT === 'fan' ? '15px 0 25px' : '15px 15px 25px'};
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
