import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

import messages from '../messages';

const AnimatedView = styled(Animated.View)`
  height: 25px;
`;

const TouchableOpacity = styled.TouchableOpacity`
  align-self: flex-end;
  margin: 0 15px 15px 0;
`;
const Text = styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  font-size: 15px;
  letter-spacing: -0.23px;
  height: 25px;
`;

interface Props {
  currentIndex: number;
  length: number;
  onPress?: () => void;
}

const SkipButton: React.FC<Props> = ({ currentIndex, length, onPress }: Props) => {
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const intl = useIntl();

  const toAnimationValue = useMemo(
    () => (currentIndex < length - 1 ? 1 : 0),
    [currentIndex, length],
  );

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: toAnimationValue,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [toAnimationValue]);

  return (
    <AnimatedView
      style={[
        {
          opacity: fadeAnim,
        },
      ]}
    >
      <TouchableOpacity onPress={onPress} disabled={toAnimationValue ? false : true}>
        <Text>{intl.formatMessage(messages.skip)}</Text>
      </TouchableOpacity>
    </AnimatedView>
  );
};

export default SkipButton;
