import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import FormErrorsView from 'components/FormErrorsView';
import FormTitleText from 'components/FormTitleText/FormTitleText';
import LoginFormButton from 'components/LoginFormButton';
import NavigationHeader from 'components/NavigationHeader';
import WebContainer from 'components/WebContainer';

import { useBackEnd, useModals } from 'context';
import { useAuth, useUser } from 'hooks';
import regex from 'utils/regex';
import { formatBirthday } from 'utils/string';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import ContentView from './styled/ContentView';
import FinishView from './styled/FinishView';
import MainView from './styled/MainView';
import TextInput from './styled/TextInput';
export { SCREEN_NAME } from './constants';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

interface FormArgs {
  fullName: string;
  username: string;
  birthday: string;
  finishSignUp: any;
}

const FinishSignUpScreen: React.FC<Props> = () => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    reset,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<FormArgs>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      username: '',
      birthday: '',
    },
  });
  const { isLoading, finishSignUp, authError } = useAuth();
  const intl = useIntl();
  const fullNameInputRef = useRef(null);
  const { logOut } = useAuth();
  const { verifyFinishSignUp } = useBackEnd();
  const usernameInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const { profile, isLoading: isUserLoading } = useUser();
  const { showActivityIndicator, hideActivityIndicator } = useModals();

  const doSubmit = () => {
    if (!isValid) {
      return;
    }
    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (args: FormArgs) => {
    if (isLoading) {
      return;
    }
    const response = await finishSignUp(args);
    if (response) {
      verifyFinishSignUp();
    }
  };

  useEffect(() => {
    (fullNameInputRef.current as any)?.focus();
  }, []);

  useEffect(() => {
    if (isUserLoading) {
      showActivityIndicator();
    } else {
      hideActivityIndicator();
      if (profile?.full_name) {
        reset({ fullName: profile?.full_name, username: '', birthday: '' });
        (usernameInputRef.current as any)?.focus();
      }
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (!authError) {
      return;
    }
    if (authError.status === 400) {
      setError('finishSignUp', { message: intl.formatMessage(messages.error.unique) });
    } else {
      setError('finishSignUp', {
        message: intl.formatMessage(
          (messages.error as any)[(authError.data.username || [{}])[0].code] ||
            messages.error.reset_password_failed,
        ),
      });
    }
  }, [authError]);

  return (
    <MainView>
      <WebContainer>
        <StatusBar style="light" translucent />
        <NavigationHeader
          insets={{ top: 35 }}
          showGoBack
          headerMiddleLogo={true}
          headerBackOnPress={logOut}
        />
        <FinishView>
          <ContentView>
            <FormTitleText>{intl.formatMessage(messages.title)}</FormTitleText>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={fullNameInputRef}
                  label={intl.formatMessage(messages.fullNameInput.label)}
                  placeholder={intl.formatMessage(messages.fullNameInput.placeholder)}
                  keyboardType="default"
                  returnKeyType="next"
                  autoCapitalize="words"
                  value={value}
                  onBlur={onBlur}
                  spellCheck={false}
                  autoCorrect={false}
                  onChangeText={(value: string) => {
                    clearErrors('fullName');
                    clearErrors('finishSignUp');
                    onChange(value);
                  }}
                  onSubmitEditing={() => (usernameInputRef.current as any)?.focus()}
                  error={!!errors.fullName}
                />
              )}
              name="fullName"
              rules={{
                required: intl.formatMessage(messages.fullNameInput.error.required),
                pattern: {
                  value: regex.fullName,
                  message: intl.formatMessage(messages.fullNameInput.error.invalid),
                },
              }}
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={usernameInputRef}
                  label={intl.formatMessage(messages.usernameInput.label)}
                  keyboardType="default"
                  returnKeyType="next"
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('username');
                    clearErrors('finishSignUp');
                    onChange(value);
                  }}
                  onSubmitEditing={() => (dobInputRef.current as any)?.focus()}
                  error={!!errors.username}
                />
              )}
              name="username"
              rules={{
                required: intl.formatMessage(messages.usernameInput.error.required),
                pattern: {
                  value: regex.username,
                  message: intl.formatMessage(messages.usernameInput.error.invalid),
                },
              }}
            />
            <Controller
              control={control}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  ref={dobInputRef}
                  label={intl.formatMessage(messages.birthdayInput.label)}
                  placeholder={intl.formatMessage(messages.birthdayInput.placeholder)}
                  keyboardType="number-pad"
                  returnKeyType="go"
                  autoCapitalize="none"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('birthday');
                    clearErrors('finishSignUp');
                    onChange(formatBirthday(value));
                  }}
                  onSubmitEditing={doSubmit}
                  error={!!errors.birthday}
                />
              )}
              name="birthday"
              rules={{
                required: intl.formatMessage(messages.birthdayInput.error.required),
                pattern: {
                  value: regex.date,
                  message: intl.formatMessage(messages.birthdayInput.error.invalid),
                },
              }}
            />
            {errors && <FormErrorsView errors={errors} />}
            <LoginFormButton
              disabled={!isValid}
              processing={isSubmitting || isLoading}
              onPress={doSubmit}
            >
              {intl.formatMessage(messages.cta)}
            </LoginFormButton>
          </ContentView>
        </FinishView>
      </WebContainer>
    </MainView>
  );
};

export default FinishSignUpScreen;
