import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={165} height={253} viewBox="0 0 165 253" fill="none" {...props}>
      <Path
        d="M98.078 250.455l-.006.001c-8.537 1.502-33.458 2.761-44.549 2.512a252.673 252.673 0 00-3.912-.047l-1.003-.005c-10.855-.059-15.457-.447-20.513-2.341-6.855-2.569-11.273-7.686-13.183-16.147-2.045-9.067-3.506-18.833-4.824-31.191-.492-4.609-.748-7.283-1.639-16.889-.182-1.964-.311-3.348-.441-4.722-1.163-12.299-2.212-25.878-3.716-47.22.142 2.022-1.42-20.281-1.836-26.087-.58-8.085-1.078-14.694-1.561-20.648-1.2-14.999-1.378-30 .621-36.014C3.95 44.15 10.493 38.73 18.264 37.76c19.753-2.972 44.577-5.219 57.745-4.944 1.178.025 2.22.035 4.482.05 6.668.045 9.774.125 13.7.447 12.438 1.023 21.123 4.222 26.117 11.1 2.722 3.756 4.332 9.18 5.338 16.493.252 1.831.462 3.744.666 5.964.078.85.152 1.706.244 2.815-.017-.204.17 2.052.219 2.627.061.72.114 1.317.167 1.874l.081.883c1.661 18.207 1.71 18.732 2.396 25.534.467 4.633.929 8.918 1.405 13.013 4.322 36.676 6.913 67.614 7.882 97.621.442 13.542-1.573 22.75-8.575 28.78-3.322 2.846-8.732 5.128-15.829 7.049-2.922.79-6.007 1.486-9.353 2.14-1.26.246-2.499.476-3.899.725-.575.103-2.567.452-2.972.525z"
        fill="#0537FF"
        fillOpacity={0.52}
      />
      <Path
        d="M94.404 243.532a238.518 238.518 0 01-29.526 1.443c-14.395-.315-26.508 1.609-30.42-15.715-3.48-15.424-4.762-35.401-6.258-51.207-2.431-25.708-4.383-61.366-6.474-87.206-.563-6.966-2.191-30.907.496-37.184 2.687-6.278 9.277-8.227 14.014-9.122 11.386-2.206 25.26-3.923 38.283-3.65 11.849.25 31.23-.986 39.134 9.952 4.647 6.418 5.036 22.092 5.788 29.68 1.125 11.8 2.175 23.965 3.589 35.94 3.472 29.472 6.201 63.024 7.168 92.878.273 8.293-.248 18.957-7.284 25.011-6.574 5.631-22.829 8.483-28.51 9.18z"
        fill="#121212"
      />
      <Path
        d="M94.404 243.532a238.518 238.518 0 01-29.526 1.443c-14.395-.315-26.508 1.609-30.42-15.715-3.48-15.424-4.762-35.401-6.258-51.207-2.431-25.708-4.383-61.366-6.474-87.206-.563-6.966-2.191-30.907.496-37.184 2.687-6.278 9.277-8.227 14.014-9.122 11.386-2.206 25.26-3.923 38.283-3.65 11.849.25 31.23-.986 39.134 9.952 4.647 6.418 5.036 22.092 5.788 29.68 1.125 11.8 2.175 23.965 3.589 35.94 3.472 29.472 6.201 63.024 7.168 92.878.273 8.293-.248 18.957-7.284 25.011-6.574 5.631-22.829 8.483-28.51 9.18z"
        fill="#2E51DF"
        fillOpacity={0.09}
      />
      <Path
        opacity={0.52}
        d="M15.854 42.772c-4.05 2.717-5.351 9.639-5.167 20.566.046 2.773.178 5.513.42 9.32L11.323 76c.075 1.19.125 2.074.16 2.848.326 7.085.82 15.305 1.573 26.643l.346 5.165.729 10.781c.27 4.026.478 7.181.66 10.066 1.702 27.144 4.275 56.1 7.721 88.183.945 8.864 2.92 17.168 5.905 22.973a1.651 1.651 0 002.227.713 1.66 1.66 0 00.712-2.234c-2.761-5.37-4.65-13.312-5.555-21.806-3.442-32.038-6.01-60.947-7.708-88.038a2569.03 2569.03 0 00-.662-10.08l-.73-10.782c-.127-1.898-.24-3.568-.345-5.162-.752-11.317-1.245-19.517-1.57-26.574-.035-.796-.087-1.697-.163-2.905-.035-.563-.181-2.807-.215-3.343-.239-3.76-.369-6.456-.415-9.166-.163-9.712 1.01-15.948 3.7-17.752.758-.51.962-1.54.454-2.301a1.65 1.65 0 00-2.293-.457zM8.757 100.039a410.462 410.462 0 01-.708-13.777 1.656 1.656 0 00-1.71-1.6c-.912.031-1.627.799-1.595 1.714.156 4.564.395 9.208.713 13.888a1.656 1.656 0 001.763 1.542 1.657 1.657 0 001.537-1.767zM10.432 122.878c-.41-4.438-.728-9.15-.97-14.199a1.655 1.655 0 00-1.731-1.576 1.656 1.656 0 00-1.573 1.736c.245 5.095.567 9.855.981 14.345a1.654 1.654 0 103.293-.306z"
        fill="#C7C6FF"
      />
      <G opacity={0.87} fill="#BCFFC9">
        <Path d="M123.049 21.19l8.525-18.236a2.075 2.075 0 00-.994-2.757 2.064 2.064 0 00-2.749.997l-8.525 18.235a2.076 2.076 0 00.994 2.757 2.064 2.064 0 002.749-.997zM156.636 12.454l-18.198 13.06a2.076 2.076 0 00-.478 2.893 2.063 2.063 0 002.884.48l18.198-13.062a2.076 2.076 0 00.478-2.892 2.063 2.063 0 00-2.884-.48zM146.395 50.503l16.578-.324A2.07 2.07 0 00165 48.066a2.07 2.07 0 00-2.107-2.032l-16.578.323a2.07 2.07 0 00-2.027 2.113 2.07 2.07 0 002.107 2.033z" />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.443 114.486c-.888-5.04 6-7.313 8.263-2.726 4.745 9.615 8.92 11.6 14.392 8.075 2.043-1.316 4.763-.711 6.074 1.352 1.312 2.062.719 4.801-1.324 6.116-5.526 3.56-10.797 4.001-15.545 1.629l-.427-.222 6.36 36.063c1.413 8.02-3.796 15.664-11.676 17.239l-.335.063-4.328.763c-5.677 1.001-11.097-2.827-12.106-8.55-.992-5.625 2.646-10.987 8.16-12.12l.292-.056 10.279-1.813-8.079-45.813z"
        fill="#BCFFC9"
        opacity={0.87}
      />
    </Svg>
  );
};

export default SvgComponent;
