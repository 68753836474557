import { Audio, AVPlaybackStatus } from 'expo-av';
import { useEffect, useRef, useState } from 'react';

export function useAudio(
  file: string,
  soundUpdateInterval?: number,
): {
  sound: Audio.Sound;
  isLoaded: boolean;
  durationMillis: number;
  playAudio: (fromMillis: number) => Promise<unknown>;
  pauseAudio: () => Promise<void>;
  stopAudio: () => Promise<void>;
  setOnAudioStatusUpdate: (
    onAudioStatusUpdate: ((status: AVPlaybackStatus) => void) | null,
  ) => void;
} {
  const sound = useRef(new Audio.Sound()).current;
  const [isLoaded, setIsLoaded] = useState(false);
  const [durationMillis, setDurationMillis] = useState(0);

  useEffect(() => {
    loadAudio();
  }, [file]);

  const setAudioInfo = (status: AVPlaybackStatus) => {
    const durMillis = (status as any).durationMillis;
    const il = status.isLoaded;
    if (durationMillis !== durMillis && durMillis !== undefined) {
      setDurationMillis(durMillis);
    }
    if (isLoaded !== il) {
      setIsLoaded(il);
    }
  };

  const loadAudio = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        if (isLoaded) {
          unloadAudio();
        }
        await Audio.setAudioModeAsync({ allowsRecordingIOS: false });
        const status = await sound.loadAsync(
          { uri: file },
          {
            progressUpdateIntervalMillis: soundUpdateInterval || 250,
          },
        );
        setAudioInfo(status);
        resolve(null);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  const setOnAudioStatusUpdate = (
    onAudioStatusUpdate: ((status: AVPlaybackStatus) => void) | null,
  ) => {
    sound.setOnPlaybackStatusUpdate((status: AVPlaybackStatus) => {
      setAudioInfo(status);
      onAudioStatusUpdate?.(status);
    });
  };

  const unloadAudio = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        setAudioInfo(await sound.unloadAsync());
        resolve(null);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  const setAudioPosition = (millis: number) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      if (!isLoaded) return resolve(null);
      try {
        await sound.setStatusAsync({ positionMillis: millis });
        resolve(null);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  const playAudio = (fromMillis: number) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        if (!isLoaded) {
          await loadAudio();
        }
        await setAudioPosition(fromMillis || 0);
        setAudioInfo(await sound.playAsync());
        resolve(null);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  const pauseAudio = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<void>(async (resolve, reject) => {
      if (!isLoaded) return resolve();
      try {
        await sound.pauseAsync();
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  const stopAudio = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<void>(async (resolve, reject) => {
      if (!isLoaded) return resolve();
      try {
        await sound.stopAsync();
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  return {
    sound,
    isLoaded,
    durationMillis,
    playAudio,
    pauseAudio,
    stopAudio,
    setOnAudioStatusUpdate,
  };
}
