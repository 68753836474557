import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Medium;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.41px;
  color: #121212;
  margin-bottom: 16px;
`;
