import React, { useCallback, useState } from 'react';

import { useSubscriptionStatus, useUnsubscribe } from 'hooks';
import { Artist } from 'types';

import { useFeedHandlers } from './feed';

import UnsubscribedDialog from 'dialogs/UnsubscribedDialog';
import UnsubscribeDialog from 'dialogs/UnsubscribeDialog';

interface UnsubscribeState {
  unsubscribeModalOpen: (artist?: Artist) => void;
  isUnsubscribing: boolean;
}

const DEFAULT_STATE: UnsubscribeState = {
  unsubscribeModalOpen: () => {},
  isUnsubscribing: false,
};

const UnsubscribeContext = React.createContext<UnsubscribeState>(DEFAULT_STATE);

export function useUnsubscribeModal(): UnsubscribeState {
  const context = React.useContext<UnsubscribeState>(UnsubscribeContext);
  if (context === undefined) {
    throw new Error('useUnsubscribe must be used within a UnsubscribeProvider');
  }

  return context;
}

interface Props {
  children: React.ReactNode;
}

export const UnsubscribeProvider: React.FC<Props> = ({ children }: Props) => {
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);
  const [isUnsubscribedOpen, setIsUnsubscribedOpen] = useState(false);
  const [artist, setArtist] = useState<Artist>();
  const { isUnsubscribing, unsubscribe, unsubscribeData } = useUnsubscribe();
  const { refresh: refreshFeed } = useFeedHandlers();
  const { subscriptionStatus, isLoading: isLoadingSubscriptionStatus } = useSubscriptionStatus(
    artist?.username || '',
  );

  const onUnsubscribed = useCallback(() => {
    setIsUnsubscribedOpen(true);
    refreshFeed();
  }, [refreshFeed]);

  return (
    <UnsubscribeContext.Provider
      value={{
        unsubscribeModalOpen: (artist) => {
          setArtist(artist);
          setIsUnsubscribeOpen(true);
        },
        isUnsubscribing,
      }}
    >
      {children}
      <UnsubscribeDialog
        artist={artist}
        isOpen={isUnsubscribeOpen}
        setIsOpen={setIsUnsubscribeOpen}
        unsubscribe={unsubscribe}
        onUnsubscribed={onUnsubscribed}
        isLoadingSubscriptionStatus={!artist || isLoadingSubscriptionStatus}
        subscriptionStatus={subscriptionStatus}
      />
      <UnsubscribedDialog
        artist={artist}
        isOpen={isUnsubscribedOpen}
        setIsOpen={setIsUnsubscribedOpen}
        unsubscribeData={unsubscribeData}
      />
    </UnsubscribeContext.Provider>
  );
};
