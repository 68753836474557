import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.08px;
  color: #ffffff;
  opacity: 0.87;
  margin-bottom: 40px;
`;
