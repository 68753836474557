import React from 'react';
import { useIntl } from 'react-intl';

import BecomeStanIcon from 'components/ArtistProfileHeader/styled/BecomeStanIcon';
import Dialog from 'components/Dialog';

import messages from './messages';
import Button from './styled/Button';
import SubscribeButton from './styled/SubscribeButton';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubscribePress: () => void;
}

const TippingSubscribeDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onSubscribePress,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      visible={isOpen}
      onPressOutside={() => setIsOpen(false)}
      subtitle={intl.formatMessage(messages.description)}
    >
      <SubscribeButton icon={BecomeStanIcon} iconAlign={'right'} onPress={onSubscribePress}>
        {intl.formatMessage(messages.subscribe)}
      </SubscribeButton>
      <Button secondary onPress={() => setIsOpen(false)}>
        {intl.formatMessage(messages.dismiss)}
      </Button>
    </Dialog>
  );
};

export default TippingSubscribeDialog;
