import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  top: 7px;
  right: 7px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  z-index: 26;
`;
