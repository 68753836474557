import styled from 'styled-components/native';

import Subtitle from 'components/Subtitle';
import Header from 'components/TippingSheet/styled/Title';

export default styled.View`
  align-self: center;
  flex: 1;
  justify-content: center;
`;

export const Title = styled(Header)`
  font-family: BasierCircle-Regular;
  margin: 0 0 12px;
  align-self: center;
`;

export const Description = styled(Subtitle)`
  font-size: 15px;
`;

export const Details = styled.TouchableOpacity`
  font-family: BasierCircle-Regular;
  align-self: center;
  font-size: 13px;
  margin-top: 16px;
  color: ${({ theme }) => theme.orderSectionTitle.primaryText};
`;
