import React from 'react';
import styled from 'styled-components/native';

const View = styled.View`
  width: 94px;
  height: 25px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bottomSheet.badge};
  border-radius: 40px;
  margin: 12px 0 0 0;
  z-index: 3;
`;

const Text = styled.Text`
  color: ${({ theme }) => theme.bottomSheet.text};
  letter-spacing: -0.08px;
  font-size: 13px;
`;

interface BadgeProps {
  text?: string;
}

const Badge: React.FC<BadgeProps> = ({ text }: BadgeProps) => {
  return (
    <View>
      <Text>{text}</Text>
    </View>
  );
};

export default Badge;
