import { useCallback } from 'react';

import { useApexx } from 'hooks';
import { PaymentMethod } from 'types';

export interface UseMarketplacePayment {
  payForOrder: (
    artistUsername: string,
    productId: number,
    orderExternalId: string,
    paymentMethod: PaymentMethod,
  ) => void;
  paymentSuccess?: boolean;
  paymentInProgress: boolean;
  paymentError?: { message?: string; details?: string };
}

export interface CreateCardinalJWTData {
  encoded_cardinal_jwt: string;
  external_id: string;
}

export interface CreatePaymentRequestData {
  authentication_transaction_id: string;
  acs_url: string;
  external_id: string;
  pareq: string;
}

export function useMarketplacePayment(): UseMarketplacePayment {
  const { payWithApexx, paymentSuccess, paymentError, isPaying } = useApexx();

  const payForOrder = (
    artistUsername: string,
    productId: number,
    orderExternalId: string,
    paymentMethod: PaymentMethod,
  ) => {
    payWithApexx({
      artist_username: artistUsername,
      payment_type: 'marketplace_item',
      credit_card_external_id: (paymentMethod.creditCardInfo.external_id || '').toString(),
      product_id: productId,
      marketplace_order_external_id: orderExternalId,
    });
  };

  return {
    payForOrder,
    paymentSuccess,
    paymentError,
    paymentInProgress: isPaying,
  };
}
