import styled from 'styled-components/native';

import ActivityIndicator from 'components/ActivityIndicator';

import darkTheme from 'themes/dark';

export default styled(ActivityIndicator).attrs({
  color: darkTheme.colors.text,
  size: 24,
})`
  padding: 30px 0 15px;
`;
