import { useNavigation } from '@react-navigation/native';
import React from 'react';

import Item from './styled/Item';
import Separator from './styled/Separator';
import TouchableOpacity from './styled/TouchableOpacity';
import View from './styled/View';
import { MenuItem } from './types';

interface Props {
  menuItems: MenuItem[];
}

const NavigationMenu: React.FC<Props> = ({ menuItems, ...rest }: Props) => {
  const navigation = useNavigation();

  return (
    <View {...rest}>
      {menuItems.map((item, index) => (
        <View key={`${item.title}-${index}`}>
          <TouchableOpacity onPress={() => navigation.navigate(item.screen)}>
            <Item item={item}>{item.title}</Item>
          </TouchableOpacity>
          {index < menuItems.length - 1 && <Separator>•</Separator>}
        </View>
      ))}
    </View>
  );
};

export default NavigationMenu;
