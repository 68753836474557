import styled from 'styled-components/native';

import TextInput from 'components/TextInput';

export default styled(TextInput)`
  height: 56px;
  width: 100%;
  margin-top: 25px;
  background: transparent;
`;
