import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const Icon = (props: any) => <FontAwesome name="exclamation" {...props} />;

export default styled(Icon).attrs({
  size: 35,
})`
  color: ${({ theme }) => theme.colors.primaryActive};
  margin-bottom: 15px;
`;
