import { NavigationContainerRef, ParamListBase } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { SCREEN_NAME as HOME_STACK_SCREEN_NAME } from 'stacks/fan/FanHomeStack';
import { ParamList } from 'stacks/types';

import {
  MARKETPLACE_SCREEN_NAME_ONGOING,
  SCREEN_NAME as MARKETPLACE_SCREEN_NAME,
} from 'screens/AritstMarketplaceScreen';
import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from 'screens/LiveStreamPlaybackScreen/constants';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen/constants';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen/constants';

import { DeviceToken } from 'types';

import { VARIANT } from './appInfo';

interface PushNotificationsState {
  deviceToken: DeviceToken;
}

const DEFAULT_STATE: PushNotificationsState = {
  deviceToken: null,
};

const PUSH_NOTIFICATIONS_ENABLED = !Constants.platform?.web;

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const PushNotificationsContext = React.createContext<PushNotificationsState>(DEFAULT_STATE);

export function usePushNotifications(): PushNotificationsState {
  const context = React.useContext<PushNotificationsState>(PushNotificationsContext);
  if (context === undefined) {
    throw new Error('usePushNotifications must be used within a PushNotificationsProvider');
  }

  return context;
}

interface Props {
  navigationRef: MutableRefObject<NavigationContainerRef<any> | null>;
  isReady?: boolean;
  children: React.ReactNode;
}

type Params = [string, ParamListBase[keyof ParamList]] | undefined;

export const PushNotificationsProvider: React.FC<Props> = ({
  navigationRef,
  children,
  isReady,
}) => {
  const [initialRoute, setInitialRoute] = useState<Params>();
  const [deviceToken, setDeviceToken] = useState<DeviceToken>(null);
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  const registerForPushNotificationsAsync = async () => {
    let token;
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#0537ff',
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      console.log('Failed to get push token for push notification!');
      return 'd728lSKxR9O-ut2Ql-M3gh:APA91bEGgYcOBbt8bNggWK6qwnLvZR8FnCytx8XHjEWBUvSFZIeYhArqLrwnSd5YWaF99j7GuZiKzs49z4nAAIYUC6BZg-ZAMTnchgUSihcVF2CagvAo0-723pPHnUZNaz8nHrG4AGcQ';
    }

    return token;
  };

  const onNotificationReceived = async (data: any) => {
    try {
      console.debug('new notification', JSON.stringify(data));
      // await Notifications.setBadgeCountAsync(1);
    } catch (error) {
      console.error(error);
    }
  };

  const onNotificationPress = async (lastNotificationResponse: any) => {
    const data =
      lastNotificationResponse.notification.request.trigger?.payload ||
      lastNotificationResponse.notification.request.trigger?.remoteMessage?.data;
    try {
      console.debug('clicked', data);
      if (VARIANT === 'artist') {
        switch (data.product_type) {
          case 'marketplaceitem':
            setInitialRoute([MARKETPLACE_SCREEN_NAME, {}]);
            break;
          case 'marketplaceitempayment':
            setInitialRoute([MARKETPLACE_SCREEN_NAME, { screen: MARKETPLACE_SCREEN_NAME_ONGOING }]);
            break;
          case 'subscription':
            setInitialRoute([HOME_STACK_SCREEN_NAME, {}]);
            break;
          default:
            return null;
        }
      } else if (data.product_type === 'live_stream' && data.username) {
        setInitialRoute([
          LIVE_STREAM_PLAYBACK_SCREEN_NAME,
          {
            artistUsername: data.username,
          },
        ]);
      } else if (data.username && data.product_id && data.product_type) {
        if (data.product_type !== 'marketplaceitem') {
          setInitialRoute([
            SINGLE_POST_SCREEN_NAME,
            {
              screen: SINGLE_POST_SCREEN_NAME,
              artistUsername: data.username,
              productType: data.product_type,
              productId: data.product_id,
            },
          ]);
        } else {
          setInitialRoute([
            MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME,
            {
              productId: data.product_id,
              artist: data.username,
            },
          ]);
        }
      } else if (data.order_external_id && data.marketplace_item_id) {
        setInitialRoute([
          MARKETPLACE_ORDER_DETAILS_SCREEN_NAME,
          {
            order: {
              product: { id: data.marketplace_item_id },
              external_id: data.order_external_id,
            },
            marketplaceProductId: data.marketplace_item_id,
            screenTitle: data.order_external_id,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (PUSH_NOTIFICATIONS_ENABLED) {
      registerForPushNotificationsAsync().then((token) => {
        console.debug('device token', token);
        token && setDeviceToken(token);
      });
      const foregroundReceivedNotificationSubscription =
        Notifications.addNotificationReceivedListener((notification) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onNotificationReceived(notification);
        });
      return () => {
        foregroundReceivedNotificationSubscription.remove();
      };
    } else {
      console.log('[App] Push notifications not available');
    }
  }, []);

  useEffect(() => {
    if (PUSH_NOTIFICATIONS_ENABLED && lastNotificationResponse) {
      onNotificationPress(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastNotificationResponse,
      );
    }
  }, [lastNotificationResponse]);

  useEffect(() => {
    if (!initialRoute || !isReady) return;
    navigationRef?.current?.navigate(...initialRoute);
    setInitialRoute(undefined);
  }, [isReady, initialRoute]);

  return (
    <PushNotificationsContext.Provider value={{ deviceToken }}>
      {children}
    </PushNotificationsContext.Provider>
  );
};
