export default {
  today: {
    id: 'notificationsGroup.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'notificationsGroup.yesterday',
    defaultMessage: 'Yesterday',
  },
};
