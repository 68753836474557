import { Entypo } from '@expo/vector-icons';
import React from 'react';
import styled from 'styled-components/native';

const Icon = styled(Entypo).attrs(({ theme }) => ({
  color: theme.colors.text,
  size: 34,
  name: 'ccw',
}))`
  transform: rotateY(180deg) rotateZ(-90deg);
`;

const TouchableOpacity = styled.TouchableOpacity``;

const Text = styled.Text`
  position: absolute;
  color: ${({ theme }) => theme.colors.text};
  font-size: 12px;
  bottom: 8px;
  left: 10px;
`;

interface Props {
  onPress: () => void;
}

const SeekForward: React.FC<Props> = ({ onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Icon />
      <Text>15</Text>
    </TouchableOpacity>
  );
};

export default SeekForward;
