import styled from 'styled-components/native';

export default styled.View<{ web?: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  ${({ web }) =>
    web
      ? `
      padding: 10px 0;
      `
      : `
  padding: 0 15px;
  `}
  width: 100%;
`;
