import styled from 'styled-components/native';

import CachedImage from 'components/CachedImage';

const ArtistPhoto = styled(CachedImage)`
  width: 58px;
  height: 58px;
  border-radius: 30px;
  background-color: #121212;
`;

export default ArtistPhoto;
