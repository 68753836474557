import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_POST_SETTINGS_SCREEN_NAME } from 'screens/ArtistPostSettingsScreen/constants';

import ScreenView from 'containers/ModalScreen';

import { POLL_ANSWER_HEIGHT } from 'components/FeedItem';
import MediaView from 'components/FeedItem/styled/MediaView';
import FeedItemPollContent from 'components/FeedItemPollContent';
import NavigationHeader from 'components/NavigationHeader';

import { PollProduct } from 'types/PollProduct';

import { SCREEN_NAME } from './constants';
import TextInput from './styled/TextInput';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ArtistPollAddDescriptionScreen: React.FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { question, answers } = route.params;
  const [caption, setCaption] = useState<string>();

  const product = useMemo(
    () => ({
      question,
      answers: answers.map((a, index) => ({
        id: index,
        text: a,
      })),
    }),
    [question, answers],
  );

  return (
    <ScreenView
      header={
        <NavigationHeader
          insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
          navigation={navigation}
          title={intl.formatMessage({
            id: 'post.poll.createPoll',
            defaultMessage: 'Create poll',
          })}
          headerBackTitle={intl.formatMessage({
            id: 'post.cancel',
            defaultMessage: 'Cancel',
          })}
          headerBackImage={() => <View />}
          headerRightLabel={intl.formatMessage({
            id: 'post.next',
            defaultMessage: 'Next',
          })}
          headerRightOnPress={() => {
            navigation.navigate(ARTIST_POST_SETTINGS_SCREEN_NAME, {
              poll: {
                question,
                answers,
              },
              caption,
            });
          }}
        />
      }
    >
      <MediaView roundedCorners height={120 + POLL_ANSWER_HEIGHT * product.answers.length}>
        <FeedItemPollContent answerable={false} product={product as PollProduct} />
      </MediaView>
      <TextInput
        placeholder={intl.formatMessage({
          id: 'post.poll.caption',
          defaultMessage: 'Caption (optional)',
        })}
        value={caption}
        onChangeText={(v: string) => setCaption(v)}
      />
    </ScreenView>
  );
};

export default ArtistPollAddDescriptionScreen;
