import { LinkingOptions } from '@react-navigation/native';
import { Linking } from 'react-native';

export function join(parts: string[], separator = '/'): string {
  const replace = new RegExp(separator + '{1,}', 'g');
  return trimTrailingSlash(parts.join(separator).replace(replace, separator));
}

export function trimTrailingSlash(path: string): string {
  return path !== '/' && path.endsWith('/') ? path.substring(0, path.length - 1) : path;
}

export function isRemotePath(path: string): boolean {
  return path.startsWith('http://') || path.startsWith('https://') || path.startsWith('//');
}

export function getFileName(path: string): string {
  const ps = path.split('/');
  return ps[ps.length - 1];
}

export function getFileExt(path: string): string {
  const fns = path.split('.');
  return fns[fns.length - 1];
}

export function getFileNameWithoutExt(path: string): string {
  return getFileName(path).replace(new RegExp(`.${getFileExt(path)}$`), '');
}

export function getFileDirectory(path: string): string {
  return path.replace(new RegExp(`.${getFileName(path)}$`), '');
}

export function getQueryParam(url: string | null, param: string): string | null {
  const result = url?.match(new RegExp('(\\?|&)' + param + '(\\[\\])?=([^&]*)'));
  return result ? result[3] : null;
}

export function getLocation(href: string): null | {
  protocol: string;
  hostname: string;
  search: string;
  port: string;
  host: string;
  href: string;
  hash: string;
  pathname: string;
} {
  const match = href.match(
    /^(https?:|com.stanbase:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]?[^?#]*)(\?[^#]*|)(#.*|)$/,
  );
  return (
    match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  );
}

interface RouteFromLink {
  link: string | null;
  route: string | undefined;
}

export const getRouteFromDeeplink = async ({
  prefixes,
}: LinkingOptions): Promise<RouteFromLink> => {
  const link = await Linking.getInitialURL();
  const route = link?.replace(prefixes.find((prefix) => link?.startsWith(prefix)) || link, '');

  return {
    link,
    route,
  };
};
