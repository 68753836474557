import { ActivityIndicator } from 'react-native-paper';
import styled from 'styled-components/native';

/**
 * This component is used across various screens to indicate that there's some activity happening.
 * It does not show the progress, but it keeps animating until the activity is complete.
 */
const ActivityIndicatorStyled = styled(ActivityIndicator)``;

ActivityIndicatorStyled.defaultProps = {
  color: '#ffffff',
  size: 50,
};

export default ActivityIndicatorStyled;
