export default {
  report: {
    id: 'marketplaceOrderInformation.report',
    defaultMessage: 'Report order',
  },
  help: {
    id: 'marketplaceOrderInformation.help',
    defaultMessage: 'Help',
  },
  fan: {
    confirm: {
      id: 'marketplaceOrderDetails.fan.confirm',
      defaultMessage: 'Confirm completing the order by Artist',
    },
    continueOrder: {
      id: 'marketplaceOrderDetails.fan.continueOrder',
      defaultMessage: 'Continue order',
    },
    cancel: {
      id: 'marketplaceOrderDetails.fan.cancel',
      defaultMessage: 'Cancel order',
    },
  },
  artist: {
    confirm: {
      id: 'marketplaceOrderDetails.fan.confirm',
      defaultMessage: 'Ask Stan to mark order as completed',
    },
    continueOrder: {
      id: 'marketplaceOrderDetails.fan.continueOrder',
      defaultMessage: 'Ask Stan to mark order as completed',
    },
    cancel: {
      id: 'marketplaceOrderDetails.fan.cancel',
      defaultMessage: 'Decline order',
    },
  },
  awaitingConfirmation: {
    id: 'marketplaceOrderDetails.awaitingConfirmation',
    defaultMessage: "Awaiting Stan's confirmation",
  },
  cancelNotification: {
    id: 'marketplaceOrderDetails.cancelNotification',
    defaultMessage: 'Order has been cancelled',
  },
};
