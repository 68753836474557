import { AxiosError } from 'axios';

import { useAuthSwr } from 'hooks/swr';
import { Config } from 'types';

export const CONFIG_KEY = '/settings';

const DEFAULT_CONFIG: Config = {
  fanRegistrationEnabled: false,
  fanEmailAuthEnabled: true,
  fanAppleAuthEnabled: true,
  fanGoogleAuthEnabled: true,
  fanFacebookAuthEnabled: true,
  artistAppleAuthEnabled: false,
  artistGoogleAuthEnabled: false,
  artistFacebookAuthEnabled: false,
  commentsAddNewEnabled: false,
  feedBookmarksEnabled: false,
  feedCommentsEnabled: false,
  feedLiveMomentsEnabled: false,
  feedReactionsEnabled: false,
  feedSharingEnabled: false,
  feedAutoPlayVideos: false,
  singlePostCommentsEnabled: false,
  liveMomentsEnabled: false,
  stanningEnabled: false,
  followingEnabled: false,
  forceTheme: '',
  fanAppMaintenanceMode: false,
  artistAppMaintenanceMode: false,
  fanMarketplaceEnabled: true,
  tippingEnabled: true,
  webVersionEnabled: true,
  onBoardingUseBackendFaces: false,
  artistProfileShowPrimaryUrl: true,
  artistProfileShowSecondaryUrl: true,
};

export function useConfig(
  mockValue?: Config,
  refreshInterval = 30000,
): {
  config: Config;
  isLoading: boolean;
  error?: AxiosError<any>;
} {
  const {
    isLoading,
    data: config,
    error,
  } = useAuthSwr<{ config: Config }>({
    key: `${CONFIG_KEY}`,
    isPublic: true,
    cache: true,
    mockValue: mockValue ? { config: mockValue } : undefined,
    swrOptions: { refreshInterval, revalidateOnFocus: false },
  });

  return {
    config: config?.config
      ? {
          ...DEFAULT_CONFIG,
          ...config.config,
        }
      : DEFAULT_CONFIG,
    isLoading,
    error,
  };
}
