import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as LOGIN_SCREEN_NAME } from 'screens/LoginWithEmailScreen';

import FormContentView from 'components/FormContentView';
import FormErrorsView, { FormPasswordErrorsView } from 'components/FormErrorsView';
import Text from 'components/FormScreenStyled/Text';
import FormTitleText from 'components/FormTitleText/FormTitleText';
import LoginFormButton from 'components/LoginFormButton';
import ScreenView from 'components/ScreenView';

import { useAuth } from 'hooks/auth';
import usePasswordChecks from 'hooks/usePasswordChecks';

import { SCREEN_NAME } from './constants';
export { SCREEN_NAME } from './constants';
import { useBackEnd } from 'context';

import messages from './messages';
import TextInput from './styled/TextInput';

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

const ResetPasswordScreen: React.FC<Props> = ({ navigation, route }: Props) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<{
    password: string;
    resetPassword: any;
  }>({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  });
  const { isLoading, authError, resetPassword } = useAuth();
  const intl = useIntl();
  const passwordInputRef = useRef(null);
  const { resetPasswordRequired, onResetPasswordSuccess } = useBackEnd();
  const { validatePassword, passwordChecks } = usePasswordChecks({
    missingUpperOrLowerCase: intl.formatMessage(
      messages.passwordInput.error.missingUpperOrLowerCase,
    ),
    missingNumber: intl.formatMessage(messages.passwordInput.error.missingNumber),
    missingSpecialCharacter: intl.formatMessage(
      messages.passwordInput.error.missingSpecialCharacter,
    ),
  });

  const doSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }
    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  }, [handleSubmit]);

  const onSubmit = useCallback(
    async (args: { password: string }) => {
      if (isLoading) {
        return;
      }
      const response = await resetPassword(args.password, route.params.token);
      if (response) {
        onResetPasswordSuccess();
        navigation.navigate(LOGIN_SCREEN_NAME);
      }
    },
    [isLoading, resetPassword],
  );

  useEffect(() => {
    (passwordInputRef.current as any)?.focus();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    setError('resetPassword', {
      message: intl.formatMessage(
        (messages.error as any)[authError.data.code] || messages.error.reset_password_failed,
      ),
    });
  }, [authError]);

  return (
    <ScreenView>
      <StatusBar style="light" translucent />
      <FormContentView>
        <FormTitleText>{intl.formatMessage(messages.title)}</FormTitleText>
        {resetPasswordRequired && <Text>{intl.formatMessage(messages.resetPasswordRequired)}</Text>}
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInput
              ref={passwordInputRef}
              label={intl.formatMessage(messages.passwordInput.label)}
              placeholder={intl.formatMessage(messages.passwordInput.placeholder)}
              secureTextEntry={true}
              returnKeyType="go"
              value={value}
              onBlur={onBlur}
              onChangeText={(value: any) => {
                clearErrors('password');
                onChange(value);
              }}
              onSubmitEditing={doSubmit}
            />
          )}
          name="password"
          rules={{
            validate: {
              combined: validatePassword,
            },
          }}
          defaultValue=""
        />

        <FormPasswordErrorsView passwordChecks={passwordChecks} />

        <LoginFormButton
          disabled={!isValid}
          processing={isSubmitting || isLoading}
          onPress={doSubmit}
        >
          {intl.formatMessage(messages.resetPasswordCta)}
        </LoginFormButton>

        {isSubmitted && <FormErrorsView errors={errors} />}
      </FormContentView>
    </ScreenView>
  );
};

export default ResetPasswordScreen;
