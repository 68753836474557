import styled, { css } from 'styled-components/native';

interface TextProps {
  active?: boolean;
}

export default styled.Text<TextProps>`
  margin-left: 10px;
  ${({ active, theme }) => css`
    color: ${active ? theme.colors.background : theme.colors.primaryInactive};
  `}
`;
