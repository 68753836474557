import styled from 'styled-components/native';

interface Props {
  isDescription?: boolean;
}

export default styled.Text<Props>`
  font-family: 'BasierCircle-Regular';
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin-bottom: 20px;

  ${({ isDescription }) =>
    isDescription &&
    `
    font-size: 17px;
    line-height: 19px;
  `}
`;
