import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import Button from 'components/Button';

import PriceBox from './PriceBox';

type Props = {
  onPriceSet: any;
  price?: number;
  options: number[];
};

const StyledButton = styled(Button).attrs({
  primary: true,
})`
  margin-top: 30px;
  width: 100%;
`;

const PriceList: React.FC<Props> = ({ onPriceSet, price, options }: Props) => {
  const [selectedPrice, setSelectedPrice] = useState<number | undefined>(price);

  useEffect(() => {
    setSelectedPrice(price);
  }, [price]);

  return (
    <>
      <View style={{ flexDirection: 'row' }}>
        {options.map((o, i) => (
          <PriceBox
            index={i}
            onPress={() => setSelectedPrice(o)}
            key={`price-${i}`}
            selected={selectedPrice === o}
            price={o}
            boxStyle={i === options.length - 1 && { marginRight: 0 }}
          />
        ))}
      </View>
      <StyledButton onPress={() => onPriceSet(selectedPrice)}>Confirm</StyledButton>
    </>
  );
};

export default PriceList;
