import * as React from 'react';

import { OptionGroupItemProps } from 'components/OptionGroupItem/OptionGroupItem';

import OptionGroupItem from '../OptionGroupItem';
import Container from './styled/Container';
import Description from './styled/Description';
import Label from './styled/Label';
import Wrapper from './styled/Wrapper';

export interface OptionsGroupProps {
  description?: string;
  label?: string;
  options: OptionGroupItemProps[];
  itemStyle?: any;
}

const OptionsGroup: React.FC<OptionsGroupProps> = ({
  description,
  label,
  options,
  itemStyle,
  ...rest
}: OptionsGroupProps): JSX.Element => (
  <Container {...rest}>
    {label && <Label>{label}</Label>}
    {description && <Description>{description}</Description>}
    <Wrapper>
      {options.map((option: OptionGroupItemProps, index: number) => (
        <OptionGroupItem
          {...option}
          {...{ style: itemStyle }}
          isFirst={index === 0}
          key={`option-group-item-${index}`}
        />
      ))}
    </Wrapper>
  </Container>
);

OptionsGroup.displayName = 'OptionsGroup';

export default OptionsGroup;
