import React, { useCallback } from 'react';
import { Linking } from 'react-native';

import { VARIANT } from 'context/appInfo';
import { artistAppStoreURL, fanAppStoreURL } from 'utils/links';

import Image from './styled/Image';
import TouchableOpacity from './styled/TouchableOpacity';

const DownloadButtonAppStore: React.FC = (props) => {
  const onDownloadPress = useCallback(() => {
    Linking.openURL(VARIANT === 'fan' ? fanAppStoreURL : artistAppStoreURL);
  }, [fanAppStoreURL, artistAppStoreURL]);

  return (
    <TouchableOpacity onPress={onDownloadPress} {...props}>
      <Image
        resizeMode="contain"
        source={{
          uri: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1363910400&h=50d4d8d56d3bc4252bcaa4f5b8d7250a',
        }}
      />
    </TouchableOpacity>
  );
};

export default DownloadButtonAppStore;
