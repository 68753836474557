import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(({ theme }) => ({
  bold: false,
  labelColor: theme.marketplaceOrderDetails.actionText,
}))`
  background-color: ${({ theme }) => theme.marketplaceOrderDetails.actionBg};
  border-radius: 40px;
  flex: 1;
  padding-bottom: 15px;
  padding-top: 15px;
`;
