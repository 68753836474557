import { NavigationContainerRef } from '@react-navigation/native';
import cardValidator from 'card-validator';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREEN_NAME } from 'screens/EditPaymentMethodScreen';

import SubscriptionActivatedModal from 'components/SubscriptionActivatedModal';

import { usePayments } from 'context/payments';
import { useCvvModal } from 'context/cvvModal';
import { WebviewContext } from 'context/webview';
import { useSubscribe, useSubscriptionPlan } from 'hooks';
import { PaymentMethod } from 'types';
import { parseCurrency } from 'utils/currency';
import { privacyPolicyURL, termsURL } from 'utils/links';

import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import AddCreditCardView from './styled/AddCreditCardView';
import AddPaymentMethodCtaText from './styled/AddPaymentMethodCtaText';
import AddPaymentMethodTouchable from './styled/AddPaymentMethodTouchable';
import ContentInnerView from './styled/ContentInnerView';
import CreditCardIcon from './styled/CreditCardIcon';
import Description from './styled/Description';
import DisclaimerText from './styled/DisclaimerText';
import Divider from './styled/Divider';
import ErrorText from './styled/ErrorText';
import IconCheck from './styled/IconCheck';
import OverlayWeb from './styled/Overlay';
import PayButton from './styled/PayButton';
import PaymentMethodName from './styled/PaymentMethodName';
import PaymentMethodNameView from './styled/PaymentMethodNameView';
import PaymentMethodsHeaderView from './styled/PaymentMethodsHeaderView';
import PaymentMethodsListTitleText from './styled/PaymentMethodsListTitleText';
import PaymentMethodsListView from './styled/PaymentMethodsListView';
import PaymentMethodsView from './styled/PaymentMethodsView';
import PaymentMethodTouchable from './styled/PaymentMethodTouchable';
import PaymentMethodView from './styled/PaymentMethodView';
import PriceText from './styled/PriceText';
import ResultIconCircle from './styled/ResultIconCircle';
import ResultIconError from './styled/ResultIconError';
import ResultIconWrapper from './styled/ResultIconWrapper';
import TitleText from './styled/TitleText';
import TitleView from './styled/TitleView';
import CvvTextInput from './styled/CvvTextInput'

interface Props {
  artistUsername?: string;
  artistFullName: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  navigationRef: MutableRefObject<NavigationContainerRef | null>;
}

const ConfirmPaymentModal: React.FC<Props> = ({
  artistUsername,
  artistFullName,
  setIsOpen,
  navigationRef,
}: Props) => {
  const intl = useIntl();
  const [error, setError] = useState<string>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
  const { subscribeWithCard, isSubscribing, subscribeData, subscribeError } = useSubscribe();
  const { isLoading: isLoadingSubscriptionStatus, subscriptionPlan } =
    useSubscriptionPlan(artistUsername);
  const { isLoadingPaymentMethods, paymentMethods } = usePayments();
  const { openWebView } = useContext(WebviewContext);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { cvv, setCvv } = useCvvModal();

  useEffect(() => {
    const onSuccessfulPayment = async () => {
      setSubscribed(true);
      setTimeout(() => {
        setIsProcessing(false);
        setSubscribed(false);
        setIsOpen(false);
      }, 4000);
    };

    const onPaymentError = async () => {
      console.log('subscribeError', subscribeError);
      setError(subscribeError);
      setTimeout(() => {
        setIsProcessing(false);
        setError(undefined);
      }, 4000);
    };

    if (!isProcessing && isSubscribing) {
      setIsProcessing(true);
    } else if (isProcessing && !isSubscribing) {
      if (!subscribeData) {
        onPaymentError();
      } else {
        onSuccessfulPayment();
      }
    }
  }, [isSubscribing, isProcessing, subscribeData, subscribeError]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length !== 0) {
      setSelectedPaymentMethod(
        paymentMethods.find((pm) => pm.creditCardInfo.is_primary) || paymentMethods[0],
      );
    }
  }, [paymentMethods]);

  const onMethodPress = (method: PaymentMethod) => {
    setCvv('');
    setSelectedPaymentMethod(method);
  };

  const onAddPaymentMethod = () => {
    navigationRef.current?.navigate(ADD_PAYMENT_METHOD_SCREEN_NAME);
    setIsOpen(false);
  };

  const onTermsCtaPress = useCallback(() => {
    if (openWebView) {
      openWebView(termsURL);
    }
  }, [openWebView]);

  const onPrivacyPolicyCtaPress = useCallback(() => {
    if (openWebView) {
      openWebView(privacyPolicyURL);
    }
  }, [openWebView]);

  const renderPaymentMethod = (method: PaymentMethod) => (
    <PaymentMethodTouchable
      key={method.creditCardInfo.external_id || method.creditCardInfo.number}
      onPress={() => onMethodPress(method)}
    >
      <PaymentMethodView>
        <PaymentMethodNameView>
          <CreditCardIcon
            cardType={cardValidator.number(method.creditCardInfo.number).card?.type}
          />
          <PaymentMethodName>
            {intl.formatMessage(messages.card, {
              lastFourDigits: method.creditCardInfo.last_four_digits,
            })}
          </PaymentMethodName>
        </PaymentMethodNameView>
        {selectedPaymentMethod?.creditCardInfo.external_id ===
          method.creditCardInfo.external_id && <IconCheck />}
      </PaymentMethodView>
    </PaymentMethodTouchable>
  );

  const onPayPress = async () => {
    if (!selectedPaymentMethod) {
      return;
    }

    if (!artistUsername) throw new Error('Artist username not found');
    await subscribeWithCard({
      artistUsername: artistUsername,
      paymentMethod: selectedPaymentMethod,
    });
  };

  const renderForm = () => (
    <ContentInnerView>
      <TitleView>
        <TitleText>{intl.formatMessage(messages.becomeStan)} </TitleText>
        {!isLoadingSubscriptionStatus ? (
          <PriceText>
            {intl.formatMessage(messages.priceMonthly, {
              currency: parseCurrency(subscriptionPlan?.price_currency as string),
              price: subscriptionPlan?.price,
            })}
          </PriceText>
        ) : (
          <ActivityIndicator />
        )}
      </TitleView>
      <Description>{intl.formatMessage(messages.description, { artistFullName })}</Description>
      <Divider />
      <>
        <PaymentMethodsView>
          <PaymentMethodsHeaderView>
            <PaymentMethodsListTitleText>
              {intl.formatMessage(messages.paymentMethodsListTitle)}
            </PaymentMethodsListTitleText>
            <AddPaymentMethodTouchable onPress={onAddPaymentMethod}>
              <AddPaymentMethodCtaText>
                {intl.formatMessage(messages.addMethod)}
              </AddPaymentMethodCtaText>
            </AddPaymentMethodTouchable>
          </PaymentMethodsHeaderView>
          <PaymentMethodsListView
            style={{
              height: paymentMethods ? Math.min(paymentMethods.length * 40 + 20, 110) : 50,
            }}
          >
            {isLoadingPaymentMethods && <ActivityIndicator animating />}
            {!isLoadingPaymentMethods && paymentMethods && paymentMethods.map(renderPaymentMethod)}
          </PaymentMethodsListView>
        </PaymentMethodsView>
        <CvvTextInput value={cvv} onChangeValue={setCvv} />
        <DisclaimerText>
          {intl.formatMessage(messages.disclaimer, {
            termsCta: function TermsCtaChunk(chunk) {
              return <DisclaimerText onPress={onTermsCtaPress}>{chunk}</DisclaimerText>;
            },
            privacyPolicyCta: function PrivacyCtaChunk(chunk) {
              return <DisclaimerText onPress={onPrivacyPolicyCtaPress}>{chunk}</DisclaimerText>;
            },
          })}
        </DisclaimerText>
      </>
      <PayButton
        disabled={paymentMethods.length === 0}
        processing={isLoadingSubscriptionStatus || isProcessing}
        onPress={onPayPress}
      >
        {intl.formatMessage(
          selectedPaymentMethod ? messages.payButtonLabel : messages.payButtonLabelGeneric,
          {
            paymentMethod: intl.formatMessage(messages.methodName, {
              lastFourDigits: selectedPaymentMethod
                ? selectedPaymentMethod.creditCardInfo.last_four_digits
                : '',
            }),
          },
        )}
      </PayButton>
    </ContentInnerView>
  );

  return (
    <OverlayWeb overlayPress={() => setIsOpen(false)} isOpen>
      {!subscribed && !error ? (
        <AddCreditCardView>{renderForm()}</AddCreditCardView>
      ) : error ? (
        <AddCreditCardView>
          <ResultIconWrapper>
            <ResultIconCircle>
              <ResultIconError />
            </ResultIconCircle>
          </ResultIconWrapper>
          <ErrorText>{error}</ErrorText>
        </AddCreditCardView>
      ) : (
        <SubscriptionActivatedModal artistUsername={artistUsername} />
      )}
    </OverlayWeb>
  );
};

export default ConfirmPaymentModal;
