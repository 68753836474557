import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

const StyledImage = styled.Image`
  background-color: ${({ theme }) => theme.orderPreview.imageBg};
  aspect-ratio: 1;
  border-radius: 6px;
`;

const ImagePreview: React.FC<{ src: string | undefined }> = ({
  src,
}: {
  src: string | undefined;
}) => (
  <View style={{ aspectRatio: 1 }}>
    <StyledImage resizeMode="cover" source={{ uri: src }} />
  </View>
);

export default ImagePreview;
