import React, { memo } from 'react';

import ArtistsList from 'components/ArtistsList';

import { Artist } from 'types';

import Header from './styled/Header';
import Title from './styled/Title';

export interface ArtistsSearchResultsProps {
  data: Artist[];
  onResultPress: (artist: Artist) => void;
}

const ArtistsSearchResults = ({ data, onResultPress }: ArtistsSearchResultsProps): JSX.Element => (
  <ArtistsList
    data={data}
    renderHeader={() => <Header>{data.length > 0 && <Title>Top results</Title>}</Header>}
    onArtistPress={onResultPress}
  />
);

ArtistsSearchResults.displayName = 'ArtistsSearchResults';

export default memo(ArtistsSearchResults);
