import { useNavigation } from '@react-navigation/native';
import { AxiosResponse } from 'axios';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Google from 'expo-auth-session/providers/google';
import * as Facebook from 'expo-facebook';
import * as GoogleSignIn from 'expo-google-sign-in';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { cache, mutate } from 'swr';

import { SCREEN_NAME as ARTIST_ON_BOARDING_SCREEN_NAME } from 'screens/ArtistOnBoardingScreen/constants';

import { useArtistOnBoarding, usePushNotifications } from 'context';
import { VARIANT } from 'context/appInfo';
import { useBackEnd } from 'context/backEnd';
import { useModals } from 'context/modals';
import { DeviceToken } from 'types';
import { androidClientId, googleWebClientId, iosClientId, redirectUri } from 'utils/googleServices';

export const FACEBOOK_APP_ID = '280826860287655';

export const USER_KEY = `/auth/${VARIANT === 'artist' ? 'artist' : 'user'}`;

type RegistrationData = { email: string };

type LoginData = { hasFinishedSignUp: boolean };

type LoginWithEmailRequestData = {
  email: string;
  password: string;
  apple_token?: DeviceToken;
  fcm_registration_token?: DeviceToken;
  device_id?: string;
};

type LoginWithSocialRequestData = {
  token: string;
  apple_token?: DeviceToken;
  authorization_code?: string;
  fcm_registration_token?: DeviceToken;
  device_id?: string;
  full_name?: string;
};

export type FinishSignUpArgs = {
  fullName: string;
  username: string;
  birthday: string;
};

export function useAuth(): {
  isLoading: boolean;
  authError: any | null;
  authErrorData: any | null;
  logIn: (email: string, password: string) => Promise<void>;
  loginData: LoginData | null;
  logOut: () => Promise<void>;
  logoutData: any;
  register: (email: string, password: string) => Promise<AxiosResponse | null | undefined>;
  registrationData: RegistrationData | null;
  signInWithApple: () => void;
  signInWithGoogle: () => void;
  signInWithFacebook: () => void;
  signInWithFacebookWeb: (
    FBLoginResponse: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
    deviceId: string,
  ) => void;
  forgotPassword: (email: string) => Promise<AxiosResponse | null | undefined>;
  resetPassword: (password: string, token: string) => Promise<AxiosResponse | null | undefined>;
  finishSignUp: (args: FinishSignUpArgs) => Promise<AxiosResponse | null | undefined>;
  resendVerificationEmail: (email: string) => Promise<AxiosResponse | null | undefined>;
} {
  const { axiosInstance, setAuthData } = useBackEnd();
  const { deviceToken } = usePushNotifications();
  const { setOldPassword, setOldEmail } = useArtistOnBoarding();
  const navigation = useNavigation();

  const [authErrorData, setAuthErrorData] = useState<any | null>(null);
  const [authError, setAuthError] = useState<any | null>(null);
  const [isLoading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState<LoginData | null>(null);

  const [logoutData, setLogoutData] = useState(null);

  const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);

  const { showActivityIndicator, hideActivityIndicator } = useModals();

  const [, googleResponse, googlePromptAsync] = Google.useAuthRequest(
    {
      iosClientId,
      androidClientId,
      webClientId: googleWebClientId,
      clientSecret: Platform.OS === 'web' ? process.env.GOOGLE_OAUTH_SECRET : undefined,
      redirectUri,
      responseType: 'id_token',
    },
    { useProxy: false },
  );

  useEffect(() => {
    if (Platform.OS === 'android') {
      GoogleSignIn.initAsync({
        clientId: androidClientId,
        webClientId: googleWebClientId,
        scopes: [
          'openid',
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ],
      });
    }
  }, []);

  useEffect(() => {
    console.log('test', Platform.OS !== 'android');
    if (Platform.OS !== 'android') {
      const type = googleResponse?.type;
      //Alert.alert('googleResponse', JSON.stringify(googleResponse));
      try {
        if (type === 'success' && googleResponse?.params?.id_token) {
          const token = googleResponse?.params?.id_token;
          const decodedToken: any = jwt_decode(token);
          const signIn = async () => {
            const loginRequestData: LoginWithSocialRequestData = {
              token,
            };
            if (decodedToken?.name) {
              loginRequestData.full_name = decodedToken.name;
            }
            if (deviceToken) {
              const deviceTokenKey =
                Platform.OS === 'ios' ? 'apple_token' : 'fcm_registration_token';
              loginRequestData[deviceTokenKey] = deviceToken;
            }
            const deviceId = await DeviceInfo.getUniqueId();
            if (deviceId) {
              loginRequestData.device_id = deviceId;
            }
            try {
              const response = await axiosInstance.post(
                `/auth/${VARIANT === 'artist' ? 'artist/' : ''}socials/google`,
                loginRequestData,
              );
              await setAuthData(response.data);
              setLoginData(response.data);
              await mutate(USER_KEY);
            } catch (e) {
              console.log('backend login error', e);
            } finally {
              setLoading(false);
              hideActivityIndicator();
            }
          };
          signIn();
        } else {
          hideActivityIndicator();
          setLoading(false);
        }
      } catch (e) {}
    }
  }, [googleResponse, deviceToken]);

  const logIn = async (email: string, password: string) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    showActivityIndicator();
    setAuthError(null);
    setAuthErrorData(null);
    setLoginData(null);

    try {
      const loginRequestData: LoginWithEmailRequestData = {
        email,
        password,
      };

      if (deviceToken && Platform.OS !== 'web') {
        const deviceTokenKey = Platform.OS === 'ios' ? 'apple_token' : 'fcm_registration_token';
        loginRequestData[deviceTokenKey] = deviceToken;
      }
      const deviceId = await DeviceInfo.getUniqueId();
      if (deviceId && Platform.OS !== 'web') {
        loginRequestData.device_id = deviceId;
      }

      const response = await axiosInstance.post(
        `/auth/${VARIANT === 'artist' ? 'artist/' : ''}login`,
        loginRequestData,
      );
      setLoginData(response.data);
      await setAuthData(response.data);
    } catch (e) {
      setOldEmail(email);
      setOldPassword(password);
      setAuthErrorData(e);
      setAuthError(e.response);
      if (e.response.data.code === 'email_is_not_verified' && VARIANT === 'artist') {
        navigation.navigate(ARTIST_ON_BOARDING_SCREEN_NAME);
      }
    }

    mutate(USER_KEY);
    setLoading(false);
    hideActivityIndicator();
  };

  const logOut = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    showActivityIndicator();
    setAuthError(null);
    setAuthErrorData(null);
    setLogoutData(null);

    try {
      const data: { apple_token?: string; fcm_registration_token?: string; device_id?: string } =
        {};
      if (deviceToken && Platform.OS !== 'web') {
        const deviceTokenKey = Platform.OS === 'ios' ? 'apple_token' : 'fcm_registration_token';
        data[deviceTokenKey] = deviceToken;
      }
      const deviceId = await DeviceInfo.getUniqueId();
      if (deviceId) {
        data.device_id = deviceId;
      }
      const response = await axiosInstance.post('/auth/logout', data);
      setLogoutData(response.data);
      if (Platform.OS === 'android') {
        await GoogleSignIn.signOutAsync();
      }
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
    }
    await setAuthData(null);
    cache.clear();
    setLoading(false);
    hideActivityIndicator();
  };

  const register = async (email: string, password: string) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    setAuthError(null);
    setAuthErrorData(null);
    setRegistrationData(null);

    try {
      const response = await axiosInstance.post('/auth/user', {
        email,
        password,
      });
      setRegistrationData(response.data);
      setLoading(false);
      return response;
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
      setLoading(false);
      return null;
    }
  };

  const signInWithGoogle = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    showActivityIndicator();
    setAuthError(null);
    setAuthErrorData(null);
    setLoginData(null);
    //using deprecated package as workaround until android bug its fixed...
    if (Platform.OS === 'android') {
      try {
        const result: any = await GoogleSignIn.signInAsync();
        const loginRequestData: LoginWithSocialRequestData = {
          token: result.user.auth.idToken,
          full_name: result.user.displayName,
        };
        if (deviceToken) {
          const deviceTokenKey = 'fcm_registration_token';
          loginRequestData[deviceTokenKey] = deviceToken;
        }
        const deviceId = await DeviceInfo.getUniqueId();
        if (deviceId) {
          loginRequestData.device_id = deviceId;
        }
        const response = await axiosInstance.post(
          `/auth/${VARIANT === 'artist' ? 'artist/' : ''}socials/google`,
          loginRequestData,
        );
        await setAuthData(response.data);
        setLoginData(response.data);
        await mutate(USER_KEY);
      } catch (e) {
        console.log(e);
        //Alert.alert('error', JSON.stringify(e));
      } finally {
        setLoading(false);
        hideActivityIndicator();
      }
    } else {
      googlePromptAsync();
    }
  };

  const signInWithApple = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    showActivityIndicator();
    setAuthError(null);
    setAuthErrorData(null);
    setLoginData(null);

    try {
      const credentials = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });

      if (credentials && credentials.identityToken && credentials.authorizationCode) {
        // console.log(
        //   "[apple]",
        //   "signing in with token",
        //   credentials.identityToken,
        //   "and authorizationCode",
        //   credentials.authorizationCode
        // );

        const loginRequestData: LoginWithSocialRequestData = {
          token: credentials.identityToken,
          authorization_code: credentials.authorizationCode,
        };
        if (credentials.fullName?.givenName && credentials.fullName?.familyName) {
          loginRequestData.full_name = `${credentials.fullName?.givenName} ${credentials.fullName?.familyName}`;
        }
        if (deviceToken) {
          const deviceTokenKey = Platform.OS === 'ios' ? 'apple_token' : 'fcm_registration_token';
          loginRequestData[deviceTokenKey] = deviceToken;
        }
        const deviceId = await DeviceInfo.getUniqueId();
        if (deviceId) {
          loginRequestData.device_id = deviceId;
        }

        const response = await axiosInstance.post(
          `/auth/${VARIANT === 'artist' ? 'artist/' : ''}socials/apple`,
          loginRequestData,
        );

        // console.log("[apple] server response", response.data);

        await setAuthData(response.data);
        setLoginData(response.data);
      } else {
        hideActivityIndicator();
        throw new Error('Incomplete Apple Sign In SDK response');
      }
    } catch (error) {
      console.log('[apple] error:' + error?.message);
      if (
        error?.code !== 'ERR_CANCELED' &&
        error?.code !== 'ERR_APPLE_AUTHENTICATION_REQUEST_FAILED'
      ) {
        setAuthErrorData(error);
        setAuthError(
          'Invite Only Stanbase Artist app is currently invite only. Please contact us if you would like to join the club.',
          // error?.message ||
          // 'There was a login error, please try again using your e-mail address.',
        );
      }
    }

    mutate(USER_KEY);
    setLoading(false);
    hideActivityIndicator();
  };

  const signInWithFacebookWeb = async (
    FBLoginResponse: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
    deviceId: string,
  ) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    setAuthError(null);
    showActivityIndicator();
    setAuthErrorData(null);
    setLoginData(null);

    const typedResponse = FBLoginResponse as ReactFacebookLoginInfo;

    try {
      if (typedResponse.accessToken) {
        const response = await axiosInstance.post(
          `/auth/${VARIANT === 'artist' ? 'artist/' : ''}socials/facebook`,
          {
            token: typedResponse.accessToken,
            full_name: typedResponse.name,
            device_id: deviceId,
          },
        );
        await setAuthData(response.data);
        setLoginData(response.data);
        mutate(USER_KEY);
      }
    } catch (e) {
      console.log('[facebook] error:' + e?.message);
      setAuthErrorData(e);
      setAuthError(
        'Invite Only Stanbase Artist app is currently invite only. Please contact us if you would like to join the club.',
      );
    } finally {
      setLoading(false);
      hideActivityIndicator();
    }
  };

  const signInWithFacebook = async (deviceToken?: DeviceToken, deviceId?: string) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    showActivityIndicator();
    setAuthError(null);
    setAuthErrorData(null);
    setLoginData(null);

    try {
      await Facebook.initializeAsync({
        appId: FACEBOOK_APP_ID,
      });
      const response = (await Facebook.logInWithReadPermissionsAsync({
        permissions: ['public_profile', 'email'],
        behavior: 'browser',
      })) as any;
      const { type, token } = response;

      if (response.type === 'success' && token) {
        // console.log("[facebook] success", token);

        const loginRequestData: LoginWithSocialRequestData = {
          token,
        };
        if (deviceToken) {
          const deviceTokenKey = Platform.OS === 'ios' ? 'apple_token' : 'fcm_registration_token';
          loginRequestData[deviceTokenKey] = deviceToken;
        }
        if (deviceId) {
          loginRequestData.device_id = deviceId;
        }
        const response = await axiosInstance.post(
          `/auth/${VARIANT === 'artist' ? 'artist/' : ''}socials/facebook`,
          loginRequestData,
        );

        // console.log("[facebook] server response", response.data);

        await setAuthData(response.data);
        setLoginData(response.data);
      } else if (type !== 'cancel') {
        hideActivityIndicator();
        throw new Error('Incomplete Facebook Sign In SDK response');
      }
    } catch (e) {
      console.log('[facebook] error:' + e?.message);
      setAuthErrorData(e);
      setAuthError(
        'Invite Only Stanbase Artist app is currently invite only. Please contact us if you would like to join the club.',
        // e?.message ||
        // 'There was a login error, please try again using your e-mail address.',
      );
    }

    mutate(USER_KEY);
    setLoading(false);
    hideActivityIndicator();
  };

  const forgotPassword = async (email: string) => {
    if (isLoading) return;
    setLoading(true);
    setAuthError(null);
    setAuthErrorData(null);

    try {
      const response = await axiosInstance.post('/auth/forgot_password', {
        email,
      });
      setLoading(false);
      return response;
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
      setLoading(false);
      return null;
    }
  };

  const resetPassword = async (password: string, token: string) => {
    if (isLoading) return;
    setLoading(true);
    setAuthError(null);
    setAuthErrorData(null);

    try {
      const response = await axiosInstance.post('/auth/reset_password', {
        new_password1: password,
        new_password2: password,
        token,
      });
      setLoading(false);
      return response;
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
      setLoading(false);
      return null;
    }
  };

  const finishSignUp = async (args: FinishSignUpArgs) => {
    if (isLoading) return;
    setLoading(true);
    setAuthError(null);
    setAuthErrorData(null);

    try {
      const response = await axiosInstance.put('/auth/user/finish', {
        full_name: args.fullName,
        username: args.username,
        date_of_birth: format(parse(args.birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      });
      setLoading(false);
      return response;
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
      setLoading(false);
      console.debug(e);
      return null;
    }
  };

  const resendVerificationEmail = async (email: string) => {
    if (isLoading) return;
    setLoading(true);
    setAuthError(null);
    setAuthErrorData(null);

    try {
      const response = await axiosInstance.post('/auth/resend_verification_email', {
        email,
      });
      setLoading(false);
      return response;
    } catch (e) {
      setAuthErrorData(e);
      setAuthError(e.response);
      setLoading(false);
      return null;
    }
  };

  return {
    isLoading,
    authError,
    authErrorData,

    logIn,
    loginData,

    logOut,
    logoutData,

    register,
    registrationData,

    signInWithApple,
    signInWithGoogle,
    signInWithFacebook,

    signInWithFacebookWeb,

    forgotPassword,

    resetPassword,

    finishSignUp,
    resendVerificationEmail,
  };
}
