import { Dimensions, Platform, StatusBar } from 'react-native';

import { safeAreaInsets } from './safeAreaInsets';

export const statusBarHeight =
  Platform.OS === 'android' ? StatusBar.currentHeight || 0 : safeAreaInsets.safeAreaInsetsTop;
export const statusBarPadding = Platform.OS === 'android' ? statusBarHeight + 15 : statusBarHeight;

export const screenWidth = Dimensions.get('screen').width;
export const screenHeight = Dimensions.get('screen').height;
