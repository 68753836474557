import { AntDesign } from '@expo/vector-icons';
import styled from 'styled-components/native';

export default styled(AntDesign).attrs(({ theme }) => {
  return {
    name: 'close',
    color: theme.colors.text,
    size: 22,
  };
})``;
