import styled from 'styled-components';

export default styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: linear-gradient(180deg, rgba(21, 24, 36, 0) 54.55%, #151824 100%);
`;
