import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Bold';
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.41px;
  color: #ffffff;
  margin: 56px 0;
`;
