import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SCREEN_NAME as ARTIST_PROFILE_STACK_NAME } from 'stacks/artist/ArtistProfileStack';
import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_SINGLE_POST_SCREEN_NAME } from 'screens/ArtistSinglePostScreen';

import ScreenView from 'containers/ModalScreen';

import BottomSheet from 'components/BottomSheet';
import NavigationHeader from 'components/NavigationHeader';
import { OptionGroupItemProps } from 'components/OptionGroupItem/OptionGroupItem';
import OptionsGroup from 'components/OptionsGroup';

import { useBackEnd, useFeedItemUpdate, useSnackbarSet } from 'context';
import {
  useArtistAudioUpload,
  useArtistImageUpload,
  useArtistPollUpload,
  useArtistVideoUpload,
  useFeeBasedPostIapConfigsList,
} from 'hooks';
import { PRIMARY_CURRENCY } from 'utils/currency';
import { getModelNamePlural } from 'utils/product';

import { SCREEN_NAME } from './constants';
import BottomSheetSubTitle from './styled/BottomSheetSubTitle';
import BottomSheetTitle from './styled/BottomSheetTitle';
import PriceList from './styled/PriceList';
import Section from './styled/Section';
import Subtitle from './styled/Subtitle';
import Title from './styled/Title';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

type SettingsOption = 'all' | 'stans' | 'stans&followers' | undefined;

const ArtistEditPostSettingsScreen: FC<Props> = ({ route, navigation }: Props) => {
  const intl = useIntl();
  const { product, caption } = route?.params || null;
  const { updateFeedItem } = useFeedItemUpdate();
  const [freeFor, setFreeFor] = useState<SettingsOption>(
    product.tier ? 'stans' : product.price ? undefined : 'all',
  );
  const [canComment, setCanComment] = useState<SettingsOption>(
    product.comment_tier === 1 ? 'stans' : product.comment_tier === 3 ? undefined : 'all',
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState<number | undefined>(
    product.price ? Number(product.price) : undefined,
  );
  const artistImageUpload = useArtistImageUpload();
  const artistVideoUpload = useArtistVideoUpload();
  const artistAudioUpload = useArtistAudioUpload();
  const artistPollUpload = useArtistPollUpload();
  const { setSnackbar } = useSnackbarSet();
  const { feeBasedPostIapConfigs } = useFeeBasedPostIapConfigsList();
  const { axiosInstance } = useBackEnd();

  const feeOptions: OptionGroupItemProps[] = [
    {
      label: intl.formatMessage({
        id: 'post.settings.postAvailability.all',
        defaultMessage: 'All (free)',
      }),
      value: freeFor === 'all',
      type: 'switch',
      onAction: (v: boolean) => setFreeFor(v ? 'stans' : 'all'),
    },
    {
      label: intl.formatMessage({
        id: 'post.settings.postAvailability.onlyStans',
        defaultMessage: 'Stans only',
      }),
      value: freeFor === 'stans',
      type: 'switch',
      onAction: (v: boolean) => {
        setFreeFor(v ? 'all' : 'stans');
        if (!v) setCanComment('stans');
      },
    },
    // {
    //   label: intl.formatMessage({
    //     id: "post.settings.postAvailability.stansAndFollowers",
    //     defaultMessage: "Free for Stans and Followers",
    //   }),
    //   value: freeFor === "stans&followers",
    //   type: "switch",
    //   onAction: (v: boolean) => setFreeFor(v ? "all" : "stans&followers"),
    // },
    // {
    //   label: intl.formatMessage({
    //     id: 'post.settings.postAvailability.feeBased',
    //     defaultMessage: 'Fee-based for all',
    //   }),
    //   labelSuffixText: price ? `${parseCurrency()}${price}` : undefined,
    //   onAction: () => setIsModalOpen(true),
    // },
  ];

  const commentsOptions: OptionGroupItemProps[] = [
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.all',
        defaultMessage: 'All',
      }),
      value: canComment === 'all',
      type: 'switch',
      onAction: (v: boolean) => {
        setCanComment(v ? undefined : 'all');
        setFreeFor(v ? 'stans' : 'all');
      },
    },
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.onlyStans',
        defaultMessage: 'Stans only',
      }),
      value: canComment === 'stans',
      type: 'switch',
      onAction: (v: boolean) => setCanComment(v ? undefined : 'stans'),
    },
    // {
    //   label: intl.formatMessage({
    //     id: "post.settings.stansAndFollowers",
    //     defaultMessage: "Stans and Followers",
    //   }),
    //   value: canComment === "stans&followers",
    //   type: "switch",
    //   onAction: (v: boolean) =>
    //     setCanComment(v ? undefined : "stans&followers"),
    // },
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.off',
        defaultMessage: 'Comments turn off all',
      }),
      value: !canComment,
      type: 'switch',
      onAction: (v: boolean) => setCanComment(v ? 'all' : undefined),
    },
  ];

  useEffect(() => {
    if (freeFor) {
      setPrice(undefined);
    }
  }, [freeFor]);

  const onPriceSet = (p: number) => {
    if (p) {
      setPrice(p);
      setFreeFor(undefined);
    }
    setIsModalOpen(false);
  };

  const uploadMedia = async () => {
    const data: any = {};
    if (freeFor === 'stans') {
      data.tier = 1;
    } else {
      data.tier = null;
    }
    if (canComment === 'stans') {
      data.comment_tier = 1;
    } else if (!canComment) {
      data.comment_tier = 3;
    } else {
      data.comment_tier = null;
    }
    if (price) {
      data.price = price.toFixed(2).toString();
      data.price_currency = PRIMARY_CURRENCY;
      data.iap_config = feeBasedPostIapConfigs.find((c) => Number(c.price) === Number(data.price));
    } else {
      data.price = null;
    }
    data.description = caption || '';
    const mediaType = product.model_name;
    let updated = false;
    if (mediaType === 'poll') {
      updated = await artistPollUpload.update({
        product,
        ...data,
      });
      if (updated) {
        setSnackbar({
          visible: true,
          label: intl.formatMessage({
            id: 'post.pollUpdated',
            defaultMessage: 'Poll has been updated.',
          }),
        });
      }
    } else if (mediaType === 'audio') {
      updated = await artistAudioUpload.update({
        product,
        ...data,
      });
      if (updated) {
        setSnackbar({
          visible: true,
          label: intl.formatMessage({
            id: 'post.audioNoteUpdated',
            defaultMessage: 'Audio note has been updated.',
          }),
          dismissAfter: 5000,
        });
      }
    } else if (mediaType === 'image') {
      updated = await artistImageUpload.update({
        product,
        ...data,
      });
      if (updated) {
        setSnackbar({
          visible: true,
          label: intl.formatMessage({
            id: 'post.postUpdated',
            defaultMessage: 'Post has been updated.',
          }),
          dismissAfter: 5000,
        });
      }
    } else if (mediaType === 'video') {
      updated = await artistVideoUpload.update({
        product,
        ...data,
      });
      if (updated) {
        setSnackbar({
          visible: true,
          label: intl.formatMessage({
            id: 'post.postUpdated',
            defaultMessage: 'Post has been updated.',
          }),
          dismissAfter: 5000,
        });
      }
    }
    if (updated) {
      if (data.price) {
        try {
          axiosInstance.patch(
            `/artists/me/products/${getModelNamePlural(product?.model_name)}/${
              product?.id
            }/iap_config`,
            {
              id: feeBasedPostIapConfigs.find((c) => Number(c.price) === Number(data.price))?.id,
            },
          );
        } catch (e) {
          console.log(JSON.stringify(e.response.data));
        }
      }
      updateFeedItem(product, data);
      navigation.navigate(ARTIST_PROFILE_STACK_NAME, {
        screen: ARTIST_SINGLE_POST_SCREEN_NAME,
      });
    }
  };

  return (
    <>
      <ScreenView
        header={
          <NavigationHeader
            insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
            navigation={navigation}
            title={intl.formatMessage({
              id: 'post.postSettings',
              defaultMessage: 'Post settings',
            })}
            headerRightLabel={intl.formatMessage({
              id: 'post.post',
              defaultMessage: 'Post',
            })}
            headerRightDisabled={
              artistImageUpload.isUploading ||
              artistVideoUpload.isUploading ||
              artistAudioUpload.isUploading ||
              artistPollUpload.isUploading
            }
            headerRightOnPress={uploadMedia}
          />
        }
      >
        <Section>
          <Title>
            {intl.formatMessage({
              id: 'post.settings.postAvailability.title',
              defaultMessage: 'Post availability',
            })}
          </Title>
          <Subtitle>
            {intl.formatMessage({
              id: 'post.settings.postAvailability.subtitle',
              defaultMessage:
                'Decide if your post is free for all or there is an extra fee for everyone.',
            })}
          </Subtitle>
          <OptionsGroup options={feeOptions} />
        </Section>
        <Section>
          <Title>
            {intl.formatMessage({
              id: 'post.settings.comments.title',
              defaultMessage: 'Comments',
            })}
          </Title>
          <Subtitle>
            {intl.formatMessage({
              id: 'post.settings.comments.subtitle',
              defaultMessage: 'Decide who can comment your post.',
            })}
          </Subtitle>
          <OptionsGroup options={commentsOptions} />
        </Section>
      </ScreenView>
      <BottomSheet isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <BottomSheetTitle>
          {intl.formatMessage({
            id: 'post.settings.setUpFee',
            defaultMessage: 'Premium Content Fee',
          })}
        </BottomSheetTitle>
        <BottomSheetSubTitle>
          {intl.formatMessage({
            id: 'post.settings.specifyPayment',
            defaultMessage: 'Select the Premium Payment amount for access to this post',
          })}
        </BottomSheetSubTitle>
        <PriceList
          onPriceSet={onPriceSet}
          price={price}
          options={feeBasedPostIapConfigs?.map((c) => Number(c.price))}
        />
      </BottomSheet>
    </>
  );
};

export default ArtistEditPostSettingsScreen;
