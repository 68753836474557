import { Feather } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Platform, View } from 'react-native';
import { Options as PickerOptions } from 'react-native-image-crop-picker';

import { useImagePicker } from 'hooks';

import OptionButton from './OptionButton';

type Props = {
  onRemove: () => void;
  onAddMedia: (m: any) => void;
  mediaType: 'image' | 'video';
  setCropMode: (value: boolean) => void;
};

const Options: React.FC<Props> = ({ onRemove, onAddMedia, mediaType, setCropMode }: Props) => {
  const intl = useIntl();
  const { launchImagePicker, isPickingMedia } = useImagePicker({
    onMediaChange: onAddMedia,
  });

  const pickerNativeOptions = {
    cropping: false,
    mediaType: mediaType === 'image' ? 'photo' : 'video',
    forceJpg: true,
  } as PickerOptions;

  const pickerWebOptions = {
    mediaTypes:
      mediaType === 'image'
        ? ImagePicker.MediaTypeOptions.Images
        : ImagePicker.MediaTypeOptions.Videos,
    allowsEditing: false,
    quality: 1,
  };

  const onCropButtonPress = useCallback(() => {
    setCropMode(true);
  }, [setCropMode]);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        width: '100%',
        zIndex: 10,
        paddingHorizontal: 10,
        paddingTop: 30,
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        {Platform.OS !== 'web' && mediaType === 'image' && (
          <>
            <OptionButton
              onPress={onCropButtonPress}
              disabled={isPickingMedia}
              label={intl.formatMessage({
                id: 'post.crop',
                defaultMessage: 'Crop',
              })}
              icon={<Feather name="crop" size={15} color="#fff" />}
            />
            <View style={{ width: 10 }} />
          </>
        )}
        <OptionButton
          disabled={isPickingMedia}
          label={
            mediaType === 'image'
              ? intl.formatMessage({
                  id: 'post.changePhoto',
                  defaultMessage: 'Change photo',
                })
              : intl.formatMessage({
                  id: 'post.changeVideo',
                  defaultMessage: 'Change video',
                })
          }
          icon={<Feather name="plus" size={18} color="#fff" style={{ marginHorizontal: -3 }} />}
          onPress={() => launchImagePicker({ pickerNativeOptions, pickerWebOptions })}
        />
      </View>
      <OptionButton
        disabled={isPickingMedia}
        noPadding
        icon={
          <Feather
            name="x"
            size={18}
            color="#fff"
            style={{ marginHorizontal: 3 }}
            onPress={onRemove}
          />
        }
      />
    </View>
  );
};

export default Options;
