import cardValidator from 'card-validator';
import { useContext, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { mutate } from 'swr';

import { useBackEnd } from 'context/backEnd';
import { useCardinalState } from 'context/cardinal';
import { WebviewContext } from 'context/webview';
import { BillingAddress, CreditCardInfo, PaymentFlow, PaymentMethod } from 'types';
import { encryptCardData, getLastFourDigits } from 'utils/payments';

import { useAuthSwr } from './swr';

import { Cardinal } from 'native_modules';

type CreditCard = CreditCardInfo & { billing_address: BillingAddress };

const CREDIT_CARDS_KEY = '/auth/user/credit-cards';

export const usePaymentMethods = (): {
  isLoading: boolean;
  isAdding: boolean;
  paymentMethods: PaymentMethod[];
  addError?: any;
  addSuccess?: boolean;
  setAddError: (error: any) => void;
  setAddSuccess: (success?: boolean) => void;
  validate: (paymentMethod: PaymentMethod) => boolean;
  add: (paymentMethod: PaymentMethod) => Promise<void>;
  remove: (paymentMethod: PaymentMethod) => Promise<boolean>;
} => {
  const {
    isLoading,
    data: creditCards,
    error,
  } = useAuthSwr<CreditCard[]>({
    key: CREDIT_CARDS_KEY,
    isPublic: false,
    cache: true,
  });
  const { axiosInstance } = useBackEnd();
  const { init, encrypt, cca_continue } = Cardinal;
  const {
    cardinalWebInit = () => {},
    cardinalWebCCA = () => {},
    cardinalBinProcess = () => {},
  } = Platform.OS === 'web' ? useCardinalState() : {};
  const { openWebView, currentUrl, setIsWebviewOpen, isWebviewOpen } = useContext(WebviewContext);

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [addError, setAddError] = useState<string>();
  const [addSuccess, setAddSuccess] = useState<boolean>();
  const externalIdRef = useRef<string>();

  useEffect(() => {
    if (
      !isWebviewOpen &&
      !(process.env.API_URL && currentUrl?.includes(process.env.API_URL)) &&
      isAdding
    ) {
      setIsAdding(false);
    }
  }, [isWebviewOpen]);

  useEffect(() => {
    if (addSuccess) {
      mutate(CREDIT_CARDS_KEY);
    }
  }, [addSuccess]);

  useEffect(() => {
    const finalizePayment = async () => {
      if (process.env.API_URL && currentUrl?.includes(process.env.API_URL)) {
        setIsWebviewOpen(false);

        const maxRepeat = 3;
        for (let i = 1; i <= maxRepeat; i++) {
          const res = await axiosInstance.get(`/payments/${externalIdRef.current}/3dsv1/status`);
          console.log('payment status:', res.data.status);
          if (res.data.status === 'CONFIRMED') {
            setAddSuccess(true);
            setIsAdding(false);
            break;
          } else if (i === maxRepeat || ['DECLINED', 'FAILED'].includes(res.data.status)) {
            setAddSuccess(false);
            setIsAdding(false);
          }
        }
      }
    };

    finalizePayment();
  }, [currentUrl]);

  useEffect(() => {
    setPaymentMethods(
      creditCards?.map((cc) => ({
        billingAddress: cc.billing_address,
        creditCardInfo: {
          cardholder_name: cc.cardholder_name,
          cvv: cc.cvv,
          encrypted_data: cc.encrypted_data,
          expiry_month: cc.expiry_month,
          expiry_year: cc.expiry_year,
          first_six_digits: cc.first_six_digits,
          external_id: cc.external_id,
          is_primary: cc.is_primary,
          last_four_digits: cc.last_four_digits,
          number: cc.number,
        },
      })) || [],
    );
  }, [creditCards]);

  const validate = (paymentMethod: PaymentMethod) => {
    const numberValidation = cardValidator.number(paymentMethod.creditCardInfo.number);
    const expMonthValidation = cardValidator.expirationMonth(
      paymentMethod.creditCardInfo.expiry_month,
    );
    const expYearValidation = cardValidator.expirationYear(
      paymentMethod.creditCardInfo.expiry_year,
    );
    const expDateValidation = cardValidator.expirationDate(
      `${paymentMethod.creditCardInfo.expiry_month}/${paymentMethod.creditCardInfo.expiry_year}`,
    );
    const cvvValidation = cardValidator.cvv(paymentMethod.creditCardInfo.cvv);
    return (
      numberValidation.isValid &&
      expMonthValidation.isValid &&
      expYearValidation.isValid &&
      expDateValidation.isValid &&
      cvvValidation.isValid
    );
  };

  const add = async ({
    creditCardInfo: { number: cardNumber, cardholder_name, expiry_month, expiry_year, cvv },
    billingAddress,
  }: PaymentMethod) => {
    setIsAdding(true);
    setAddSuccess(undefined);
    setAddError(undefined);

    if (!cardNumber) {
      setAddSuccess(false);
      setIsAdding(false);
      return;
    }

    const cardData = {
      card_holder_name: cardholder_name,
      card_number: cardNumber,
      expiry_month,
      expiry_year,
      cvv: cvv || '',
    };
    const stringifiedCardData = JSON.stringify(cardData);

    const encrypted_data =
      Platform.OS === 'web' ? await encryptCardData(cardData) : await encrypt(stringifiedCardData);

    const payload = {
      credit_card: {
        last_four_digits: getLastFourDigits(cardNumber),
        first_six_digits: cardNumber.slice(0, 6),
        expiry_month,
        expiry_year,
        cardholder_name,
      },
    };

    if (
      !validate({
        creditCardInfo: { ...payload.credit_card, number: cardNumber, cvv },
        billingAddress,
      })
    ) {
      setAddSuccess(false);
      setIsAdding(false);
      return;
    }

    try {
      const createJwtResponse: {
        data: {
          encoded_cardinal_jwt: string;
          credit_card_verification_payment_external_id: string;
        };
      } = await axiosInstance.post('/auth/user/credit-cards', payload);
      console.log('jwt response:', createJwtResponse.data);

      const { encoded_cardinal_jwt, credit_card_verification_payment_external_id } =
        createJwtResponse.data;
      const sessionId =
        Platform.OS === 'web'
          ? await cardinalWebInit(encoded_cardinal_jwt)
          : await init(encoded_cardinal_jwt);
      console.log('sessionId:', sessionId);

      if (Platform.OS === 'web') {
        const binProcessResponse = await cardinalBinProcess(cardNumber);
        console.log('bin.process response:', binProcessResponse);
      }

      console.log('encrypted_data', encrypted_data);

      const enrollResponse: {
        data: {
          credit_card_verification_payment_external_id: string;
          pareq: string;
          acs_url: string;
          authentication_transaction_id: string;
          flow: PaymentFlow;
          term_url: string;
        };
      } = await axiosInstance.post(
        `/auth/user/credit-cards/verification/${createJwtResponse.data.credit_card_verification_payment_external_id}/3ds-enrol`,
        {
          cardinal_session_id: sessionId,
          credit_card_encrypted_data: encrypted_data,
        },
      );

      console.log('enroll response:', JSON.stringify(enrollResponse.data, null, 2));

      if (enrollResponse.data.flow === 'frictionless') {
        setAddSuccess(true);
        setIsAdding(false);
      } else if (enrollResponse.data.flow === 'challenge') {
        let authorizationResponse;
        try {
          authorizationResponse =
            Platform.OS === 'web'
              ? await cardinalWebCCA({
                  authentication_transaction_id: enrollResponse.data.authentication_transaction_id,
                  pareq: enrollResponse.data.pareq,
                  acs_url: enrollResponse.data.acs_url,
                })
              : await cca_continue(
                  enrollResponse.data.authentication_transaction_id,
                  enrollResponse.data.pareq,
                );
          console.log('authorizationResponse:', authorizationResponse);
        } catch (e: any) {
          console.log('adding credit card challenge flow error:', JSON.stringify(e));
          setAddError(e);
          setAddSuccess(false);
          setIsAdding(false);
          return;
        }

        const { paResStatus, eciFlag, cavv, xid } = authorizationResponse;
        const verificationResponse = await axiosInstance.post(
          `/auth/user/credit-cards/verification/${credit_card_verification_payment_external_id}/3ds-verify`,
          {
            pares_status: paResStatus,
            eci: eciFlag,
            cavv,
            processor_transaction_id: enrollResponse.data.authentication_transaction_id,
            cardinal_jwt: encoded_cardinal_jwt,
          },
        );
        console.log('verificationResponse:', verificationResponse.data);
        setAddSuccess(true);
        setIsAdding(false);
      } else if (enrollResponse.data.flow === 'fallback_to_3dsv1') {
        if (Platform.OS !== 'web') {
          externalIdRef.current = enrollResponse.data.credit_card_verification_payment_external_id;
          openWebView?.(
            enrollResponse.data.acs_url,
            'POST',
            `PaReq=${encodeURIComponent(enrollResponse.data.pareq)}&TermUrl=${encodeURIComponent(
              enrollResponse.data.term_url,
            )}`,
          );
        }
      }
    } catch (e: any) {
      console.log('adding credit card error:', JSON.stringify(e.response.data));
      setAddError(e.response.data);
      setAddSuccess(false);
      setIsAdding(false);
      return;
    }
  };

  const remove = async (paymentMethod: PaymentMethod) => {
    const deleted = await axiosInstance
      .delete(`/auth/user/credit-cards/${paymentMethod.creditCardInfo.external_id}`)
      .then(() => true)
      .catch((e) => {
        console.log(e);
        return false;
      });
    if (deleted) {
      mutate(CREDIT_CARDS_KEY);
    }
    return deleted;
  };

  return {
    isLoading,
    isAdding,
    paymentMethods,
    validate,
    add,
    addError,
    addSuccess,
    setAddError,
    setAddSuccess,
    remove,
  };
};
