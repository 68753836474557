import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import styled from 'styled-components/native';

const Tab = createMaterialTopTabNavigator();

const TabNavigatorStyled = styled(Tab.Navigator)``;

TabNavigatorStyled.defaultProps = {};

export default {
  Navigator: TabNavigatorStyled,
  Screen: Tab.Screen,
};
