import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import AddCardText from './styled/AddCardText';
import Field from './styled/Field';
import PlusIcon from './styled/PlusIcon';
import RemoveButton from './styled/RemoveButton';
import RowView from './styled/RowView';
import View from './styled/View';

interface Props {
  creditCardsLength?: number;
  isAddingNewCard?: boolean;
  cardNumber?: string;
  fullName?: string;
  expiryDate?: string;
  cvvKey?: string;
  onRemoveCard?: () => void;
  onAddNewCard?: () => void;
}

const CreditCardWeb: React.FC<Props> = ({
  creditCardsLength,
  isAddingNewCard,
  cardNumber,
  fullName,
  expiryDate,
  cvvKey,
  onAddNewCard,
  onRemoveCard,
}: Props) => {
  const intl = useIntl();

  const renderContent = () => {
    if (!isAddingNewCard) {
      return (
        <>
          <RowView>
            <Field subtitle={intl.formatMessage(messages.cardName)} text={cardNumber} />
          </RowView>
          <RowView>
            <Field subtitle={intl.formatMessage(messages.cardNumber)} text={fullName} />
          </RowView>
          <RowView>
            <Field subtitle={intl.formatMessage(messages.cardExpiryDate)} text={expiryDate} />
            <Field subtitle={intl.formatMessage(messages.cardCvv)} text={cvvKey} />
          </RowView>
          <RemoveButton onRemove={onRemoveCard} />
        </>
      );
    }
    return (
      <>
        <PlusIcon />
        <AddCardText>
          {creditCardsLength
            ? intl.formatMessage(messages.addCta)
            : intl.formatMessage(messages.addFirstCardCta)}
        </AddCardText>
      </>
    );
  };

  return (
    <View isAddingNewCard={isAddingNewCard} onClick={onAddNewCard}>
      {renderContent()}
    </View>
  );
};

export default CreditCardWeb;
