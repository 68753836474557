import React from 'react';
import styled from 'styled-components/native';

const TouchableOpacity = styled.TouchableOpacity`
  margin: 12px 14px;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #ffffff;
  flex: 1;
`;

const LeftIconWrapper = styled.View`
  width: 24px;
`;

interface Props {
  children: string;
  onPress: () => void;
  leftIcon: () => React.ReactElement;
  rightIcon?: () => React.ReactElement;
}

const SettingsButton: React.FC<Props> = ({ children, onPress, leftIcon, rightIcon }: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <LeftIconWrapper>{leftIcon?.()}</LeftIconWrapper>
      <Label>{children}</Label>
      {rightIcon?.()}
    </TouchableOpacity>
  );
};

export default SettingsButton;
