export default {
  header: {
    title: {
      create: {
        defaultMessage: 'Add product',
        id: 'marketplace.add.product.title.create',
      },
      edit: {
        defaultMessage: 'Edit product',
        id: 'marketplace.add.product.title.edit',
      },
    },
    leftButton: {
      defaultMessage: 'Cancel',
      id: 'marketplace.add.product.cancel.text',
    },
    rightButton: {
      defaultMessage: 'Publish',
      id: 'marketplace.add.product.go.publish',
    },
    rightButtonSave: {
      defaultMessage: 'Save',
      id: 'marketplace.edit.product.save',
    },
  },
  body: {
    title: {
      defaultMessage: 'Product information',
      id: 'marketplace.add.product.body.title',
    },
    placeholders: {
      title: {
        defaultMessage: 'Title',
        id: 'marketplace.add.product.placeholder.title',
      },
      description: {
        defaultMessage: 'Description',
        id: 'marketplace.add.product.placeholder.description',
      },
      price: {
        defaultMessage: 'Price',
        id: 'marketplace.add.product.placeholder.price',
      },
      amount: {
        defaultMessage: 'How many of this item are you selling?',
        id: 'marketplace.add.product.placeholder.amount',
      },
    },
    checkbox: {
      title: {
        defaultMessage: 'Unlimited product',
        id: 'marketplace.add.product.checkbox.title',
      },
      description: {
        defaultMessage:
          'By selecting this option, this product will be made available for purchase until you decide to remove it.',
        id: 'marketplace.add.product.checkbox.description',
      },
    },
  },
  footer: {
    title: {
      defaultMessage: 'Questions for buyer',
      id: 'marketplace.add.product.footer.title',
    },
    description: {
      defaultMessage: 'This is the space to request any additional information you may need.',
      id: 'marketplace.add.product.footer.description',
    },
  },
  modal: {
    takePhoto: {
      defaultMessage: 'Take a Photo',
      id: 'marketplace.add.product.modal.takePhoto',
    },
    chooseImage: {
      defaultMessage: 'Choose from Gallery',
      id: 'marketplace.add.product.modal.chooseImage',
    },
    useEmoji: {
      defaultMessage: 'Use emoji',
      id: 'marketplace.add.product.modal.useEmoji',
    },
    cancel: {
      defaultMessage: 'Cancel',
      id: 'marketplace.add.product.modal.cancel',
    },
  },
  delete: {
    defaultMessage: 'Delete item',
    id: 'marketplace.add.product.delete',
  },
  close: {
    defaultMessage: 'Close',
    id: 'marketplace.add.product.close',
  },
  error: {
    other: {
      id: 'marketplace.add.product.error.other',
      defaultMessage: 'Unexpected error has occured.',
    },
  },
};
