import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';

import ScreenView from 'containers/ModalScreen';

import BottomSheet from 'components/BottomSheet';
import NavigationHeader from 'components/NavigationHeader';
import { OptionGroupItemProps } from 'components/OptionGroupItem/OptionGroupItem';
import OptionsGroup from 'components/OptionsGroup';

import {
  useArtistAudioUpload,
  useArtistImageUpload,
  useArtistVideoUpload,
  useFeeBasedPostIapConfigsList,
  useUser,
} from 'hooks';
import { useArtistPollUpload } from 'hooks/artists';
import { ProductType } from 'types';
import { PRIMARY_CURRENCY } from 'utils/currency';

import { SCREEN_NAME } from './constants';
import BottomSheetSubTitle from './styled/BottomSheetSubTitle';
import BottomSheetTitle from './styled/BottomSheetTitle';
import PriceList from './styled/PriceList';
import Section from './styled/Section';
import Subtitle from './styled/Subtitle';
import Title from './styled/Title';

export { SCREEN_NAME };

type ScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;
type ScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ScreenRouteProp;
  navigation: ScreenNavigationProp;
};

type Media = {
  // common
  height: number;
  width: number;
  // web
  cancelled: boolean;
  uri: string;
  // native
  path: string;
  mime: string;
};

type SettingsOption = 'all' | 'stans' | 'stans&followers' | undefined;

const ArtistPostSettingsScreen: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const { photoVideo, caption, audio, poll } = route?.params || null;
  const [freeFor, setFreeFor] = useState<SettingsOption>('all');
  const [canComment, setCanComment] = useState<SettingsOption>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState<number>();
  const artistImageUpload = useArtistImageUpload();
  const artistVideoUpload = useArtistVideoUpload();
  const artistAudioUpload = useArtistAudioUpload();
  const artistPollUpload = useArtistPollUpload();
  const { profile } = useUser();
  const { feeBasedPostIapConfigs } = useFeeBasedPostIapConfigsList();

  const feeOptions: OptionGroupItemProps[] = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'post.settings.postAvailability.all',
          defaultMessage: 'All (free)',
        }),
        value: freeFor === 'all',
        type: 'switch',
        onAction: (v: boolean) => {
          setPrice(undefined);
          setFreeFor(v ? 'stans' : 'all');
        },
      },
      {
        label: intl.formatMessage({
          id: 'post.settings.postAvailability.onlyStans',
          defaultMessage: 'Stans only',
        }),
        value: freeFor === 'stans',
        type: 'switch',
        onAction: (v: boolean) => {
          setPrice(undefined);
          setFreeFor(v ? 'all' : 'stans');
          if (!v) setCanComment('stans');
        },
      },
      // {
      //   label: intl.formatMessage({
      //     id: "post.settings.postAvailability.stansAndFollowers",
      //     defaultMessage: "Free for Stans and Followers",
      //   }),
      //   value: freeFor === "stans&followers",
      //   type: "switch",
      //   onAction: (v: boolean) => setFreeFor(v ? "all" : "stans&followers"),
      // },
      // {
      {
        label: intl.formatMessage({
          id: 'post.settings.postAvailability.feeBased',
          defaultMessage: 'Fee-based for all',
        }),
        labelSuffixText: price ? `£${price}` : undefined,
        onAction: () => setIsModalOpen(true),
      },
    ],
    [price, freeFor],
  );

  const commentsOptions: OptionGroupItemProps[] = [
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.all',
        defaultMessage: 'All',
      }),
      value: canComment === 'all',
      type: 'switch',
      onAction: (v: boolean) => {
        setCanComment(v ? undefined : 'all');
        setFreeFor(v ? 'stans' : 'all');
      },
    },
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.onlyStans',
        defaultMessage: 'Stans only',
      }),
      value: canComment === 'stans',
      type: 'switch',
      onAction: (v: boolean) => setCanComment(v ? undefined : 'stans'),
    },
    // {
    //   label: intl.formatMessage({
    //     id: "post.settings.stansAndFollowers",
    //     defaultMessage: "Stans and Followers",
    //   }),
    //   value: canComment === "stans&followers",
    //   type: "switch",
    //   onAction: (v: boolean) =>
    //     setCanComment(v ? undefined : "stans&followers"),
    // },
    {
      label: intl.formatMessage({
        id: 'post.settings.comments.off',
        defaultMessage: 'Comments turn off all',
      }),
      value: !canComment,
      type: 'switch',
      onAction: (v: boolean) => setCanComment(v ? 'all' : undefined),
    },
  ];

  const onPriceSet = (p: number) => {
    if (p) {
      setPrice(p);
      setFreeFor(undefined);
    }
    setIsModalOpen(false);
  };

  const uploadMedia = async () => {
    const data: any = {};
    if (freeFor === 'stans') {
      data.tier = 1;
    }
    if (canComment === 'stans') {
      data.comment_tier = 1;
    } else if (!canComment) {
      data.comment_tier = 3;
    }

    if (price) {
      data.price = price.toFixed(2).toString();
      data.price_currency = PRIMARY_CURRENCY;
      data.iap_config = feeBasedPostIapConfigs.find((c) => Number(c.price) === Number(data.price));
    }
    if (caption) {
      data.description = caption;
    }
    const mediaType = getMediaType();
    if (mediaType === 'poll') {
      if (poll?.backgroundImage) {
        data.cover = await getImageUploadFile(poll?.backgroundImage);
      }
      artistPollUpload.upload(
        {
          ...data,
          question: poll?.question,
          answers_create: poll?.answers,
          snackbarThumbnailUri: poll?.backgroundImage || profile?.profile_picture,
        },
        navigation,
      );
      navigation.navigate(HOME_SCREEN_NAME);
    } else if (mediaType === 'audio') {
      if (audio?.cover) {
        data.cover = await getImageUploadFile(audio?.cover);
      }
      artistAudioUpload.upload(
        {
          ...data,
          file: Platform.OS === 'web' ? new File([audio?.file], Date.now() + '.mp3') : audio?.file,
          snackbarThumbnailUri: audio?.snackbarThumbnailUri,
        },
        navigation,
      );
      navigation.navigate(HOME_SCREEN_NAME);
    } else if (mediaType === 'image') {
      artistImageUpload.upload(
        {
          ...data,
          file: await getImageUploadFile(
            photoVideo?.editedImageUri || getMediaUri(photoVideo?.media),
          ),
          thumbnailUri: photoVideo?.editedImageUri || getMediaUri(photoVideo?.media),
        },
        navigation,
      );
      navigation.navigate(HOME_SCREEN_NAME);
    } else {
      artistVideoUpload.upload(
        {
          ...data,
          video: await getVideoUploadFile(getMediaUri(photoVideo?.media)),
          thumbnailUri: photoVideo?.videoCover,
          mime: getMime(getMediaUri(photoVideo?.media)),
          ext: getExtension(getMediaUri(photoVideo?.media)),
        },
        navigation,
      );
      navigation.navigate(HOME_SCREEN_NAME);
    }
  };

  const getImageUploadFile = async (img: string) => {
    try {
      const name = `${Date.now()}.jpg`;
      if (Platform.OS === 'web') {
        return await fetch(img)
          .then((res) => res.blob())
          .then((blob) => new File([blob], name));
      } else {
        return {
          uri: img,
          type: audio || poll ? 'image/jpeg' : getMime(img),
          name: name,
        };
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getVideoUploadFile = async (video: string) => {
    try {
      const name = `${Date.now()}.${getExtension(getMediaUri(photoVideo?.media))}`;
      return Platform.OS === 'web'
        ? await fetch(video)
            .then((res) => res.blob())
            .then((blob) => new File([blob], name))
        : await fetch(video).then((res) => res.blob());
    } catch (e) {
      console.log(e);
    }
  };

  const getMime = (uri: string) => {
    try {
      if (Platform.OS === 'web') {
        if (getMediaType() === 'video') {
          return 'video/mp4';
        }
        return uri.split(';')[0].split('data:')[1];
      }
      return photoVideo?.media?.mime;
    } catch (e) {
      console.log(e);
    }
  };

  const getMediaUri = (m: Media) => {
    return m.path || m.uri;
  };

  const getMediaType: () => ProductType = () => {
    if (poll) {
      return 'poll';
    } else if (audio) {
      return 'audio';
    } else if (Platform.OS === 'web') {
      return photoVideo?.media?.uri.includes('data:image') ? 'image' : 'video';
    }
    return photoVideo?.media?.mime.includes('image') ? 'image' : 'video';
  };

  const getExtension = (uri: string) => {
    if (Platform.OS !== 'web') {
      const splittedUri = uri.split('.');
      return splittedUri[splittedUri.length - 1];
    } else {
      if (getMediaType() === 'video') {
        return 'mp4';
      }
      return uri.split(';')[0].split('/')[1];
    }
  };

  return (
    <>
      <ScreenView
        header={
          <NavigationHeader
            insets={{ top: 15, bottom: 0, left: 0, right: 0 }}
            navigation={navigation}
            title={intl.formatMessage({
              id: 'post.postSettings',
              defaultMessage: 'Post settings',
            })}
            headerRightLabel={intl.formatMessage({
              id: 'post.post',
              defaultMessage: 'Post',
            })}
            headerRightDisabled={
              artistImageUpload.isUploading ||
              artistVideoUpload.isUploading ||
              artistAudioUpload.isUploading
            }
            headerRightOnPress={uploadMedia}
          />
        }
      >
        <Section>
          <Title>
            {intl.formatMessage({
              id: 'post.settings.postAvailability.title',
              defaultMessage: 'Post availability',
            })}
          </Title>
          <Subtitle>
            {intl.formatMessage({
              id: 'post.settings.postAvailability.subtitle',
              defaultMessage:
                'Decide if your post is free for all or there is an extra fee for everyone. ',
            })}
          </Subtitle>
          <OptionsGroup options={feeOptions} />
        </Section>
        <Section>
          <Title>
            {intl.formatMessage({
              id: 'post.settings.comments.title',
              defaultMessage: 'Comments',
            })}
          </Title>
          <Subtitle>
            {intl.formatMessage({
              id: 'post.settings.comments.subtitle',
              defaultMessage: 'Decide who can comment your post.',
            })}
          </Subtitle>
          <OptionsGroup options={commentsOptions} />
        </Section>
      </ScreenView>
      <BottomSheet isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <BottomSheetTitle>
          {intl.formatMessage({
            id: 'post.settings.setUpFee',
            defaultMessage: 'Premium Content Fee',
          })}
        </BottomSheetTitle>
        <BottomSheetSubTitle>
          {intl.formatMessage({
            id: 'post.settings.specifyPayment',
            defaultMessage: 'Select the Premium Payment amount for access to this post',
          })}
        </BottomSheetSubTitle>
        <PriceList
          onPriceSet={onPriceSet}
          price={price}
          options={feeBasedPostIapConfigs?.map((c) => Number(c.price))}
        />
      </BottomSheet>
    </>
  );
};

export default ArtistPostSettingsScreen;
