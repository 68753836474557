import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 20px;
  padding: 6px 15px;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 15px;
  bottom: 40px;
`;
