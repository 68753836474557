import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Text from 'components/FormScreenStyled/Text';
import Button from 'components/LoginFormButton';

import { useSnackbarSet } from 'context/snackbar';
import { useProfileInsights } from 'hooks';
import { useArtistSubscription } from 'hooks/subscriptions';
import { InsightsDayRange } from 'types';
import { PRIMARY_CURRENCY } from 'utils/currency';

import messages from './messages';
import Input from './styled/Input';
import ScreenView from './styled/ScreenView';
export { SCREEN_NAME } from './constants';

interface Props {}

const ArtistChangeSubscriptionPriceScreen: React.FC<Props> = () => {
  const [price, setPrice] = useState<string>('');
  const { data } = useProfileInsights(InsightsDayRange.LastMonth);
  const { currentPlan, updateSubscriptionPrice } = useArtistSubscription();
  const { setSnackbar } = useSnackbarSet();
  const intl = useIntl();

  const onUpdatePress = useCallback(async () => {
    if (parseInt(price) < 0) {
      return setSnackbar({
        visible: true,
        label: intl.formatMessage(messages.priceLessThan),
        actionLabel: intl.formatMessage(messages.closeButton),
      });
    }
    const isUpdateSuccess = await updateSubscriptionPrice(
      currentPlan.artist,
      price,
      PRIMARY_CURRENCY,
    );
    return setSnackbar({
      visible: true,
      label: isUpdateSuccess
        ? intl.formatMessage(messages.updateSuccess)
        : intl.formatMessage(messages.updateError),
      actionLabel: intl.formatMessage(messages.closeButton),
    });
  }, [price]);

  console.log(data);

  return (
    <ScreenView>
      {/* <SubscriptionRevenue
        totalIncome={data?.subscription_revenue_line_graphs[0].total_amount}
        subscriptionPrice={`${currentPlan?.price}`}
        growth={data?.subscription_revenue_line_graphs[0].growth}
        start_date={data?.subscription_revenue_line_graphs[0].start_date}
        end_date={data?.subscription_revenue_line_graphs[0].end_date}
      /> */}
      <Input
        placeholder={intl.formatMessage(messages.inputPlaceholder)}
        isPrice={true}
        type="numeric"
        value={price}
        onChangeValue={setPrice}
      />
      <Button disabled={!price} onPress={onUpdatePress}>
        {intl.formatMessage(messages.inputText)}
      </Button>
      <Text>{intl.formatMessage(messages.disclaimer)}</Text>
    </ScreenView>
  );
};

export default ArtistChangeSubscriptionPriceScreen;
