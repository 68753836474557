import styled from 'styled-components/native';

export default styled.View<{ width: number }>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
`;
