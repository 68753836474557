import styled from 'styled-components/native';

export default styled.View`
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.09);
  border-radius: 12px;
  margin: 0 auto;
  padding-top: 30px;
`;
