import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Button from 'components/Button';

interface Props {
  isRestricted?: boolean;
  isMarketplace?: boolean;
}

export default styled(Button).attrs({
  primary: true,
})<Props>`
  margin: 25px auto 0;
  width: ${({ isMarketplace }) => (isMarketplace && Platform.OS === 'web' ? 450 : '100%')};
  ${({ isRestricted }) =>
    isRestricted &&
    css`
      width: 250px;
    `}
`;
