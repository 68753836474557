export default {
  title: {
    id: 'press.title',
    defaultMessage: 'Press',
  },
  description: {
    id: 'press.description',
    defaultMessage: 'For press related questions, please click the button below',
  },
  button: {
    id: 'press.button',
    defaultMessage: 'Contact Us',
  },
};
