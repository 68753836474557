export default {
  connecting: {
    id: 'liveMoment.connecting',
    defaultMessage: 'Connecting',
  },
  start: {
    id: 'live.youreLive',
    defaultMessage: "You're live!",
  },
  connectionError: {
    id: 'live.connectionError',
    defaultMessage: 'Connection Error',
  },
  end: {
    id: 'liveMoment.end?',
    defaultMessage: 'Are you sure that you want to end Live moment?',
  },
  endFinal: {
    id: 'liveMoment.end',
    defaultMessage: 'End now',
  },
  cancel: {
    id: 'liveMoment.cancel',
    defaultMessage: 'Cancel',
  },
  addCaption: {
    id: 'liveMoment.addCaption',
    defaultMessage: 'Add caption to your Live moment',
  },
  captionPlaceholder: {
    id: 'liveMoment.captionPlaceholder',
    defaultMessage: 'Caption',
  },
  save: {
    id: 'liveMoment.save',
    defaultMessage: 'Save',
  },
  dontSave: {
    id: 'liveMoment.dontSave',
    defaultMessage: 'Skip',
  },
  snackbar: {
    label: {
      id: 'liveMoment.snackbar.label',
      defaultMessage: 'Live moment will be available for your Audience for 24h on your profile.',
    },
    cta: {
      id: 'liveMoment.snackbar.cta',
      defaultMessage: 'Close',
    },
  },
};
