import React from 'react';
import styled from 'styled-components/native';

const View = styled.View`
  background-color: #121212;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 0.4px solid rgba(255, 255, 255, 0.52);
  justify-content: center;
  align-items: center;
`;

const Image = styled.Image`
  width: 88px;
  height: 88px;
  border-radius: 44px;
`;

const TouchableOpacity = styled.TouchableOpacity``;

interface Props {
  imageUrl: string;
  onPress: () => void;
}

const Avatar: React.FC<Props> = ({ imageUrl, onPress }: Props) => {
  return (
    <View>
      <TouchableOpacity onPress={onPress}>
        <Image source={{ uri: imageUrl }} />
      </TouchableOpacity>
    </View>
  );
};

export default Avatar;
