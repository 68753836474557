import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.errorAccent};
  text-align: center;
  ${Platform.OS !== 'web' &&
  css`
    text-align: center;
  `}
`;
