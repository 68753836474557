import styled from 'styled-components/native';

import TouchableOpacity from 'components/TouchableOpacity/TouchableOpacity';

export default styled(TouchableOpacity)`
  display: flex;
  flex-direction: column;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  border-width: 2px;
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  backdrop-filter: blur(0px);
  z-index: 0;
`;
//backdrop-filter to fix webkit blur filter with border radius bug on web
