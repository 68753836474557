import * as React from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { MarketplaceOrder, OrderStatus } from 'types';

import messages from './messages';
import EmptyContainer from './styled/EmptyContainer';
import EmptyImage from './styled/EmptyImage';
import EmptyText from './styled/EmptyText';
import List from './styled/List';
import OrderPreview from './styled/OrderPreview';
import OrderPreviewTouchable from './styled/OrderPreviewTouchable';
import Separator from './styled/Separator';

export interface PurchasesListProps {
  onItemPress: any;
  orders: MarketplaceOrder[];
  refresh?: () => void;
  isRefreshing?: boolean;
}

const PurchasesList: React.FC<PurchasesListProps> = ({
  onItemPress,
  orders,
  refresh,
  isRefreshing,
}: PurchasesListProps) => {
  const intl = useIntl();

  const extractKey = (item: unknown, index: number): string =>
    `marketplace-purchase-${(item as MarketplaceOrder).external_id}-${index}`;

  const renderItem = ({ item }: { item: any }): JSX.Element => {
    const {
      product: {
        name: productName,
        artist: { display_name: artistDisplayName },
        low_res_cover: productCover,
      },
      payment: { amount, amount_currency },
      status: orderStatus,
      is_artist_asked_user_for_confirmation: waitingForConfirmation,
    } = item as MarketplaceOrder;

    return (
      <OrderPreviewTouchable onPress={() => onItemPress(item)}>
        <OrderPreview
          artistName={artistDisplayName}
          name={productName}
          price={amount}
          currency={amount_currency}
          status={orderStatus}
          previewImage={productCover}
          waitingForConfirmation={waitingForConfirmation && orderStatus !== OrderStatus.COMPLETED}
          isUpdated={false}
          imageSize={Platform.OS === 'web' ? '100px' : '104px'}
          fontSizes={{
            artist: 13,
            name: 17,
            price: 15,
          }}
        />
      </OrderPreviewTouchable>
    );
  };

  if (orders.length === 0) {
    return (
      <>
        <EmptyContainer>
          <EmptyImage />
          <EmptyText>{intl.formatMessage(messages.empty)}</EmptyText>
        </EmptyContainer>
      </>
    );
  }

  return (
    <List
      data={orders}
      keyExtractor={extractKey}
      renderItem={renderItem}
      refreshing={isRefreshing}
      onRefresh={refresh}
      ItemSeparatorComponent={() => <Separator />}
    />
  );
};

PurchasesList.displayName = 'PurchasesList';

export default PurchasesList;
