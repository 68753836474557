import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {}

const SvgComponent: React.FC<Props> = (props: Props) => {
  return (
    <Svg viewBox="0 0 317 330" fill="none" {...props}>
      <Path
        d="M217.162 62.3219C195.621 49.9076 171.828 44.9419 148.357 47.9547C124.886 50.9674 102.832 61.8158 84.5172 79.3327C68.852 94.3132 59.2599 117.147 57.5094 143.637C55.8661 168.507 61.3974 193.651 72.6804 212.651C72.7031 212.691 72.7287 212.729 72.7578 212.764C82.1832 225.017 96.5325 237.581 109.304 244.773C123.385 252.698 136.264 255.639 150.286 255.639C158.771 255.639 167.678 254.556 177.514 252.859C217.365 245.958 252.006 213.05 259.824 174.598C267.379 137.558 250.744 81.6667 217.162 62.3219Z"
        fill={'rgba(255, 255, 255, 0.01)'}
      />
      <Path
        d="M208.712 96.19C208.793 95.7797 209.084 95.4421 209.477 95.3004C212.444 94.2323 215.479 93.3642 218.544 92.7062C218.691 92.6729 218.841 92.6372 219.037 92.5913C221.563 91.9941 222.434 91.8441 223.718 91.9239C225.531 92.0364 227.053 92.8128 228.336 94.3984C230.177 96.6776 231.397 99.7083 232.017 103.101C232.374 105.103 231.99 107.959 230.977 109.58C229.845 111.417 228.003 113.048 225.343 114.826C224.868 115.143 224.373 115.462 223.8 115.821C223.576 115.962 223.347 116.104 223.062 116.28C222.962 116.342 222.489 116.634 222.362 116.713C221.299 117.37 220.403 117.967 218.643 119.172C218.45 119.304 218.45 119.304 218.257 119.437C215.585 121.266 214.176 122.171 212.423 123.114C209.625 124.543 206.977 126.249 204.52 128.206C203.652 128.898 202.395 128.132 202.609 127.044L208.712 96.19Z"
        fill="#73AAEB"
      />
      <Path
        d="M216.384 166.709C224.212 165.35 238.803 160.857 241.609 157.95C244.352 155.144 245.371 151.3 245.33 146.081C245.318 144.587 245.24 143.251 245.053 140.906L244.994 140.161C244.544 135.227 243.61 130.363 242.204 125.626C240.572 119.897 238.032 118.59 231.019 118.971C223.761 119.449 216.562 120.452 209.464 121.972C208.848 122.105 208.442 122.693 208.537 123.316L215.003 165.715C215.104 166.375 215.726 166.823 216.384 166.709Z"
        fill="#73AAEB"
      />
      <Path
        d="M242.222 138.396C240.418 138.634 238.33 138.886 235.835 139.168C233.985 139.377 232.105 139.583 229.232 139.892C221.209 140.754 219.594 140.932 216.6 141.3C215.948 141.38 215.483 141.975 215.564 142.627C215.644 143.28 216.238 143.744 216.891 143.664C219.867 143.298 221.48 143.121 229.487 142.26C232.364 141.95 234.248 141.745 236.103 141.535C238.611 141.251 240.713 140.998 242.534 140.757C243.186 140.671 243.644 140.072 243.559 139.42C243.472 138.768 242.874 138.31 242.222 138.396Z"
        fill="#121212"
      />
      <Path
        d="M242.222 138.396C240.418 138.634 238.33 138.886 235.835 139.168C233.985 139.377 232.105 139.583 229.232 139.892C221.209 140.754 219.594 140.932 216.6 141.3C215.948 141.38 215.483 141.975 215.564 142.627C215.644 143.28 216.238 143.744 216.891 143.664C219.867 143.298 221.48 143.121 229.487 142.26C232.364 141.95 234.248 141.745 236.103 141.535C238.611 141.251 240.713 140.998 242.534 140.757C243.186 140.671 243.644 140.072 243.559 139.42C243.472 138.768 242.874 138.31 242.222 138.396Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M199.996 152.143C195.604 146.67 191.213 142.661 186.623 140.287C180.187 136.951 172.788 134.801 165.977 134.553C157.853 134.257 150.913 136.625 145.959 141.97C144.876 143.142 144.012 144.614 143.197 146.592L139.749 135.374C139.498 134.561 138.501 134.265 137.847 134.81C135.566 136.714 132.269 141.34 128.173 148.033C125.416 152.537 122.79 157.15 120.138 161.986C119.459 163.228 118.76 164.617 118.041 166.151C116.272 169.925 114.413 174.489 112.363 179.979C111.391 182.586 110.412 185.299 109.256 188.571C108.778 189.923 106.988 195.03 106.58 196.184C100.855 212.364 97.4137 230.097 93.596 258.26C92.847 263.793 92.2385 272.008 91.7526 282.209C91.4251 289.083 91.1727 311.331 90.9863 318.693C90.9208 321.27 90.8684 323.663 90.8279 325.81C90.8136 326.562 90.8017 326.23 90.7922 326.805C90.7886 327.007 90.7868 327.124 90.7839 327.331C90.7815 327.481 90.7815 327.481 90.7809 327.513C90.7714 328.176 91.3066 328.72 91.9705 328.72L186.599 328.821C187.257 328.822 187.791 328.289 187.791 327.631C187.791 302.291 189.139 255.423 191.528 244.23C193.444 235.272 196.946 224.282 200.899 214.434C202.755 209.802 204.964 204.79 207.94 198.352C208.833 198.214 209.72 198.039 210.599 197.828C214.839 196.782 216.086 196.455 218.268 195.748C226.723 193.012 231.799 189.126 232.703 182.829C233.439 177.71 231.094 170.805 227.448 167.309C224.834 164.803 221.202 164.149 216.378 164.72C214.94 164.89 213.538 165.141 211.509 165.563C211.203 165.627 210.188 165.84 210.044 165.87C209.841 165.912 209.656 165.951 209.483 165.986C205.78 159.99 203.127 156.047 199.996 152.143Z"
        fill="#73AAEB"
      />
      <Path
        d="M201.451 176.349C194.183 177.108 189.989 183.051 192.102 189.798C192.12 189.853 192.88 192.48 193.169 193.354C193.956 195.732 194.785 197.361 195.934 198.335C199.095 201.015 202.186 201.53 206.515 200.464C207.154 200.307 207.544 199.661 207.387 199.023C207.23 198.384 206.584 197.994 205.946 198.151C202.31 199.047 200.002 198.662 197.475 196.518C196.76 195.913 196.087 194.589 195.43 192.605C195.158 191.783 194.406 189.186 194.376 189.089C192.723 183.812 195.89 179.325 201.698 178.718C202.352 178.65 202.827 178.064 202.759 177.41C202.69 176.756 202.105 176.281 201.451 176.349Z"
        fill="#121212"
      />
      <Path
        d="M201.451 176.349C194.183 177.108 189.989 183.051 192.102 189.798C192.12 189.853 192.88 192.48 193.169 193.354C193.956 195.732 194.785 197.361 195.934 198.335C199.095 201.015 202.186 201.53 206.515 200.464C207.154 200.307 207.544 199.661 207.387 199.023C207.23 198.384 206.584 197.994 205.946 198.151C202.31 199.047 200.002 198.662 197.475 196.518C196.76 195.913 196.087 194.589 195.43 192.605C195.158 191.783 194.406 189.186 194.376 189.089C192.723 183.812 195.89 179.325 201.698 178.718C202.352 178.65 202.827 178.064 202.759 177.41C202.69 176.756 202.105 176.281 201.451 176.349Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M195.58 179.825L195.576 179.826C189.429 180.904 171.483 181.808 163.496 181.63C162.68 181.612 161.856 181.603 160.68 181.596C160.479 181.595 160.319 181.594 159.957 181.592C152.14 181.55 148.826 181.272 145.185 179.911C140.249 178.067 137.067 174.393 135.692 168.317C134.22 161.808 133.167 154.796 132.218 145.923C131.864 142.614 131.68 140.694 131.038 133.797C130.907 132.387 130.814 131.393 130.72 130.406C129.883 121.576 129.128 111.826 128.045 96.5032C128.147 97.9548 127.023 81.9406 126.722 77.7722C126.304 71.9675 125.946 67.2221 125.598 62.9471C124.734 52.1779 124.606 41.4076 126.046 37.0897C127.798 31.6985 132.51 27.8073 138.106 27.1107C152.33 24.9771 170.206 23.3636 179.689 23.5611C180.537 23.5788 181.287 23.5864 182.916 23.5973C187.718 23.6292 189.955 23.6866 192.782 23.9183C201.738 24.6521 207.993 26.9491 211.588 31.8871C213.548 34.5842 214.708 38.4793 215.432 43.7297C215.614 45.0443 215.765 46.4179 215.912 48.0112C215.968 48.6221 216.022 49.2366 216.088 50.0327C216.076 49.8862 216.21 51.5063 216.245 51.9189C216.289 52.4351 216.328 52.865 216.366 53.2639C216.394 53.5813 216.394 53.5813 216.424 53.8981C217.62 66.9709 217.655 67.3478 218.149 72.2313C218.486 75.5579 218.818 78.6349 219.161 81.5745C222.273 107.908 224.139 130.121 224.837 151.666C225.155 161.389 223.704 168.001 218.662 172.33C216.27 174.373 212.374 176.012 207.264 177.391C205.16 177.959 202.938 178.458 200.528 178.928C199.621 179.104 198.729 179.269 197.721 179.448C197.307 179.522 195.872 179.773 195.58 179.825Z"
        fill="#0537FF"
      />
      <Path
        d="M192.935 174.854C185.876 175.638 178.775 175.984 171.673 175.89C161.307 175.664 152.584 177.045 149.768 164.607C147.261 153.533 146.338 139.189 145.261 127.841C143.51 109.383 142.105 83.7805 140.599 65.2275C140.194 60.2261 139.021 43.0366 140.956 38.5294C142.891 34.0221 147.636 32.6229 151.048 31.9799C159.247 30.3961 169.238 29.1636 178.616 29.3601C187.148 29.5387 201.104 28.6515 206.796 36.505C210.143 41.1134 210.422 52.3667 210.964 57.8147C211.774 66.2873 212.53 75.022 213.548 83.6197C216.049 104.781 218.014 128.871 218.71 150.306C218.907 156.26 218.532 163.917 213.465 168.263C208.731 172.306 197.026 174.354 192.935 174.854Z"
        fill="#121212"
      />
      <Path
        d="M192.935 174.854C185.876 175.638 178.775 175.984 171.673 175.89C161.307 175.664 152.584 177.045 149.768 164.607C147.261 153.533 146.338 139.189 145.261 127.841C143.51 109.383 142.105 83.7805 140.599 65.2275C140.194 60.2261 139.021 43.0366 140.956 38.5294C142.891 34.0221 147.636 32.6229 151.048 31.9799C159.247 30.3961 169.238 29.1636 178.616 29.3601C187.148 29.5387 201.104 28.6515 206.796 36.505C210.143 41.1134 210.422 52.3667 210.964 57.8147C211.774 66.2873 212.53 75.022 213.548 83.6197C216.049 104.781 218.014 128.871 218.71 150.306C218.907 156.26 218.532 163.917 213.465 168.263C208.731 172.306 197.026 174.354 192.935 174.854Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.363 118.632L168.878 119.065C164.28 119.372 161.489 118.59 159.918 115.473C158.895 113.435 158.302 110.987 157.911 107.767C157.78 106.689 157.703 105.871 157.537 103.925C157.419 102.552 157.36 101.912 157.279 101.192C157.174 100.255 157.085 99.5542 156.844 97.7311C156.577 95.714 156.454 94.7251 156.333 93.5446C155.956 89.8725 155.939 86.9657 156.397 84.2117C157.022 80.5106 158.98 77.9597 162.096 76.3946C164.53 75.1724 167.468 74.5908 171.558 74.3049C172.534 74.2367 173.35 74.1952 175.015 74.1216C176.6 74.0515 177.215 74.0213 178.005 73.9692C183.648 73.5924 189.154 73.4941 194.961 73.654C197.832 73.7355 199.064 74.439 200.23 76.8845C200.908 78.3083 201.289 79.9892 201.485 82.048C201.582 83.0623 201.623 83.9425 201.671 85.5665C201.679 85.8377 201.683 85.9713 201.687 86.1017C201.782 89.1664 202.001 92.1468 202.42 96.5897C202.476 97.1786 202.52 97.6399 202.624 98.7166C202.841 100.985 202.938 102.013 203.05 103.291C203.37 106.96 203.06 110.832 201.913 113.176C200.991 115.087 199.248 116.249 196.772 116.876C194.988 117.327 193.193 117.482 189.81 117.589C189.672 117.594 189.563 117.598 189.317 117.605C187.534 117.66 186.316 117.713 185.341 117.798C183.747 123.224 182.88 125.719 181.387 128.657C180.922 129.572 179.595 129.509 179.218 128.555C179.004 128.013 178.797 127.494 178.468 126.669C178.267 126.167 178.164 125.91 178.066 125.663C177.039 123.088 176.426 121.509 175.807 119.8C175.69 119.478 175.54 119.085 175.363 118.632Z"
        fill="#121212"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.363 118.632L168.878 119.065C164.28 119.372 161.489 118.59 159.918 115.473C158.895 113.435 158.302 110.987 157.911 107.767C157.78 106.689 157.703 105.871 157.537 103.925C157.419 102.552 157.36 101.912 157.279 101.192C157.174 100.255 157.085 99.5542 156.844 97.7311C156.577 95.714 156.454 94.7251 156.333 93.5446C155.956 89.8725 155.939 86.9657 156.397 84.2117C157.022 80.5106 158.98 77.9597 162.096 76.3946C164.53 75.1724 167.468 74.5908 171.558 74.3049C172.534 74.2367 173.35 74.1952 175.015 74.1216C176.6 74.0515 177.215 74.0213 178.005 73.9692C183.648 73.5924 189.154 73.4941 194.961 73.654C197.832 73.7355 199.064 74.439 200.23 76.8845C200.908 78.3083 201.289 79.9892 201.485 82.048C201.582 83.0623 201.623 83.9425 201.671 85.5665C201.679 85.8377 201.683 85.9713 201.687 86.1017C201.782 89.1664 202.001 92.1468 202.42 96.5897C202.476 97.1786 202.52 97.6399 202.624 98.7166C202.841 100.985 202.938 102.013 203.05 103.291C203.37 106.96 203.06 110.832 201.913 113.176C200.991 115.087 199.248 116.249 196.772 116.876C194.988 117.327 193.193 117.482 189.81 117.589C189.672 117.594 189.563 117.598 189.317 117.605C187.534 117.66 186.316 117.713 185.341 117.798C183.747 123.224 182.88 125.719 181.387 128.657C180.922 129.572 179.595 129.509 179.218 128.555C179.004 128.013 178.797 127.494 178.468 126.669C178.267 126.167 178.164 125.91 178.066 125.663C177.039 123.088 176.426 121.509 175.807 119.8C175.69 119.478 175.54 119.085 175.363 118.632Z"
        fill="#2E51DF"
        fillOpacity="0.4"
      />
      <Path
        d="M181.187 86.2896C184.27 83.123 187.929 81.458 191.271 83.3384C194.147 84.9525 194.795 89.2718 193.546 92.4574C191.955 96.4584 188.862 100.856 184.898 105.272C184.351 105.877 183.609 106.597 182.704 107.416C182.462 107.635 182.209 107.86 181.947 108.09C181.1 108.833 180.194 109.596 179.286 110.339C178.968 110.599 178.673 110.839 178.408 111.051C178.248 111.179 178.133 111.27 178.071 111.32C177.663 111.642 177.093 111.662 176.663 111.369C171.54 107.88 166.027 99.1552 165.447 93.227C165.121 89.9765 166.103 86.8403 168.037 85.0784C170.257 83.0562 173.282 83.1673 176.137 85.5654C177.383 86.6212 178.191 88.2753 178.593 90.1832C179.434 88.5157 180.262 87.2374 181.187 86.2896Z"
        fill="#0537FF"
      />
      <Path
        d="M136.37 30.7102C133.454 32.6604 132.517 37.6303 132.649 45.476C132.683 47.4671 132.778 49.4343 132.952 52.1684C132.977 52.556 133.082 54.1666 133.107 54.5673C133.161 55.4223 133.198 56.0564 133.223 56.6125C133.457 61.6997 133.813 67.6008 134.355 75.7413C134.431 76.8874 134.512 78.0866 134.605 79.4506C134.665 80.3491 135.028 85.6905 135.129 87.191C135.325 90.0811 135.474 92.3472 135.606 94.4186C136.831 113.908 138.683 134.698 141.165 157.733C141.846 164.098 143.267 170.059 145.417 174.227C145.719 174.811 146.437 175.041 147.021 174.739C147.606 174.438 147.835 173.72 147.533 173.135C145.545 169.28 144.185 163.577 143.533 157.478C141.055 134.475 139.205 113.719 137.982 94.268C137.85 92.1924 137.701 89.9239 137.506 87.0308C137.404 85.5292 137.042 80.1878 136.981 79.2899C136.889 77.9264 136.808 76.7278 136.732 75.5829C136.19 67.4573 135.836 61.5699 135.602 56.5036C135.576 55.9314 135.539 55.2848 135.484 54.4173C135.459 54.0136 135.354 52.4018 135.329 52.0172C135.157 49.317 135.063 47.3813 135.03 45.4361C134.913 38.4627 135.757 33.9852 137.694 32.6896C138.241 32.324 138.387 31.5847 138.022 31.038C137.656 30.4914 136.917 30.3445 136.37 30.7102Z"
        fill="#0D0F3F"
      />
      <Path
        d="M121.154 199.819C120.252 199.798 119.701 198.821 120.149 198.038C121.694 195.336 123.452 192.478 125.631 189.088C126.526 187.696 127.454 186.272 128.688 184.391C129.082 183.791 130.527 181.592 130.412 181.767C131.103 180.716 131.618 179.931 132.117 179.166C133.392 177.213 134.433 175.594 135.392 174.067C142.856 162.178 150.626 152.695 159.003 146.163C163.801 142.423 169.49 143.386 173.091 148.48C174.978 151.144 175.066 154.163 173.812 157.757C173.32 159.17 172.715 160.482 171.641 162.589C170.255 165.309 170.259 165.301 169.864 166.2C168.428 169.467 167.506 175.093 167.302 180.336C167.217 182.576 167.478 185.129 168.047 188.035C168.488 190.29 169.083 192.633 169.919 195.537C170.094 196.144 170.275 196.762 170.503 197.535C170.563 197.738 170.955 199.058 171.065 199.429C171.293 200.203 170.702 200.975 169.895 200.956L121.154 199.819Z"
        fill="#73AAEB"
      />
      <Path
        d="M131.26 71.827C131.032 68.4927 130.861 65.1852 130.75 61.9355C130.727 61.2782 130.176 60.7637 129.519 60.7863C128.862 60.809 128.347 61.3603 128.37 62.0177C128.482 65.2942 128.655 68.6285 128.884 71.989C128.929 72.6451 129.497 73.1411 130.153 73.0958C130.809 73.0512 131.305 72.4832 131.26 71.827Z"
        fill="#0D0F3F"
      />
      <Path
        d="M132.467 88.2258C132.171 85.0392 131.942 81.656 131.768 78.0312C131.736 77.3744 131.178 76.8672 130.521 76.8987C129.864 76.9303 129.357 77.4888 129.388 78.1455C129.565 81.8037 129.797 85.2213 130.095 88.4449C130.155 89.0998 130.735 89.5815 131.39 89.5208C132.045 89.4607 132.527 88.8807 132.467 88.2258Z"
        fill="#0D0F3F"
      />
      <Path
        d="M167.04 182.803C167.43 186.885 168.393 190.088 170.322 194.717C170.428 194.972 170.536 195.229 170.682 195.575C170.771 195.787 170.771 195.787 170.86 195.997C170.948 196.207 170.984 196.293 171.035 196.416C171.316 197.083 171.52 197.575 171.711 198.054C171.955 198.664 172.648 198.961 173.259 198.716C173.87 198.472 174.166 197.779 173.922 197.168C173.724 196.675 173.517 196.172 173.231 195.493C173.179 195.369 173.143 195.283 173.054 195.073C172.966 194.862 172.966 194.862 172.876 194.65C172.732 194.307 172.625 194.053 172.52 193.801C170.675 189.373 169.774 186.375 169.411 182.577C169.349 181.922 168.768 181.442 168.113 181.504C167.458 181.567 166.978 182.148 167.04 182.803Z"
        fill="#121212"
      />
      <Path
        d="M167.04 182.803C167.43 186.885 168.393 190.088 170.322 194.717C170.428 194.972 170.536 195.229 170.682 195.575C170.771 195.787 170.771 195.787 170.86 195.997C170.948 196.207 170.984 196.293 171.035 196.416C171.316 197.083 171.52 197.575 171.711 198.054C171.955 198.664 172.648 198.961 173.259 198.716C173.87 198.472 174.166 197.779 173.922 197.168C173.724 196.675 173.517 196.172 173.231 195.493C173.179 195.369 173.143 195.283 173.054 195.073C172.966 194.862 172.966 194.862 172.876 194.65C172.732 194.307 172.625 194.053 172.52 193.801C170.675 189.373 169.774 186.375 169.411 182.577C169.349 181.922 168.768 181.442 168.113 181.504C167.458 181.567 166.978 182.148 167.04 182.803Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M121.198 196.285C122.711 193.64 124.438 190.831 126.582 187.497C127.474 186.109 128.401 184.687 129.635 182.807C130.03 182.206 131.471 180.014 131.367 180.173C132.061 179.117 132.579 178.327 133.082 177.556C134.368 175.586 135.421 173.95 136.392 172.403C136.741 171.846 136.573 171.111 136.016 170.761C135.459 170.412 134.724 170.58 134.374 171.137C133.412 172.67 132.367 174.295 131.088 176.255C130.586 177.022 130.07 177.811 129.376 178.865C129.481 178.705 128.04 180.898 127.644 181.5C126.406 183.385 125.475 184.814 124.578 186.209C122.414 189.575 120.667 192.417 119.131 195.102C118.804 195.673 119.002 196.401 119.573 196.727C120.144 197.054 120.872 196.856 121.198 196.285Z"
        fill="#121212"
      />
      <Path
        d="M121.198 196.285C122.711 193.64 124.438 190.831 126.582 187.497C127.474 186.109 128.401 184.687 129.635 182.807C130.03 182.206 131.471 180.014 131.367 180.173C132.061 179.117 132.579 178.327 133.082 177.556C134.368 175.586 135.421 173.95 136.392 172.403C136.741 171.846 136.573 171.111 136.016 170.761C135.459 170.412 134.724 170.58 134.374 171.137C133.412 172.67 132.367 174.295 131.088 176.255C130.586 177.022 130.07 177.811 129.376 178.865C129.481 178.705 128.04 180.898 127.644 181.5C126.406 183.385 125.475 184.814 124.578 186.209C122.414 189.575 120.667 192.417 119.131 195.102C118.804 195.673 119.002 196.401 119.573 196.727C120.144 197.054 120.872 196.856 121.198 196.285Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M170.657 231.084C171.877 231.364 173.071 231.743 174.229 232.217C174.838 232.465 175.533 232.174 175.782 231.566C176.032 230.957 175.74 230.262 175.131 230.012C173.854 229.489 172.535 229.072 171.189 228.763C170.548 228.616 169.91 229.017 169.763 229.657C169.615 230.299 170.016 230.938 170.657 231.084Z"
        fill="#121212"
      />
      <Path
        d="M170.657 231.084C171.877 231.364 173.071 231.743 174.229 232.217C174.838 232.465 175.533 232.174 175.782 231.566C176.032 230.957 175.74 230.262 175.131 230.012C173.854 229.489 172.535 229.072 171.189 228.763C170.548 228.616 169.91 229.017 169.763 229.657C169.615 230.299 170.016 230.938 170.657 231.084Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M315.548 279.819C314.729 279.239 313.753 278.923 312.749 278.914C313.565 276.998 313.701 274.862 313.136 272.859C312.498 270.696 310.858 268.972 308.73 268.226C305.206 266.934 302.443 268.375 300.299 270.531C300.747 266.824 300.079 263.068 298.382 259.742C298.186 259.39 293.619 251.168 288.933 249.971C285.099 248.983 281.943 249.608 279.555 251.835C274.387 256.646 274.268 268.113 275.137 275.574C274.446 275.333 273.715 275.22 272.982 275.24C269.029 275.318 263.592 278.813 262.009 282.314C261.413 283.635 260.723 285.559 261.693 287.077C262.664 288.595 264.771 288.863 267.647 288.822C270.059 288.822 272.476 288.821 274.792 288.875C276.578 288.911 278.424 288.941 280.276 288.941C282.128 288.941 283.848 288.911 285.635 288.822L288.016 288.702C293.696 288.429 300.776 288.107 305.878 287.297L307.432 287.059C312.523 286.303 316.542 285.702 316.506 281.897C316.534 281.092 316.178 280.321 315.548 279.819Z"
        fill="#24245D"
      />
      <Path
        d="M59.6886 226.804C59.1301 226.542 58.5198 226.408 57.9024 226.411C58.1715 225.335 58.0381 224.198 57.5273 223.213C56.4793 221.237 53.9548 220.099 50.8468 220.236C46.6791 220.421 42.7078 222.791 39.7843 225.839L39.9987 225.244C41.0823 222.225 42.1957 219.099 41.0585 215.009C39.9808 211.156 37.9564 206.786 31.6332 207.072C25.8756 207.334 19.85 211.347 15.5095 217.789C11.937 223.148 10.2758 229.072 10.9308 233.955C8.53128 233.22 5.93881 233.467 3.72038 234.639C1.33874 235.83 -0.0961961 237.741 0.00502341 239.48C0.0467021 240.272 0.457534 241.731 2.71413 242.57C3.83999 242.924 5.01795 243.083 6.19728 243.04C7.51313 243.04 8.92425 242.921 10.2461 242.814C11.3178 242.725 12.33 242.642 13.0862 242.642H29.4718C31.1449 242.642 32.8478 242.671 34.6519 242.707C42.6363 242.856 51.6923 243.023 59.1825 240.29L59.3314 240.224C61.7606 238.944 63.1717 236.348 63.1122 233.282C63.0527 230.358 61.6713 227.756 59.6886 226.804Z"
        fill="#24245D"
      />
      <Path
        d="M211.518 275.925C225.373 261.51 235.793 223.529 206.159 216.878C204.2 216.438 202.247 216.092 200.306 215.83C196.634 225.052 193.685 234.546 191.488 244.225C189.905 251.644 188.916 267.446 188.356 282.766C180.926 286.511 174.239 294.924 172.394 300.754C171.733 295.186 163.736 287.905 153.984 289.5C144.231 291.096 133.775 303.129 133.091 310.625C130.643 297.717 114.752 287.559 102.766 285.303C99.0517 284.624 95.2363 284.721 91.5608 285.588C91.9955 275.556 92.6385 265.059 93.5555 258.259C94.7463 249.447 96.0919 240.058 97.8126 230.65H97.7055C90.9059 231.186 83.4156 232.793 77.6044 236.348C70.269 240.843 62.2131 248.137 58.6823 256.175C54.7467 265.142 51.1087 278.336 54.5145 290.745C46.0894 285.886 36.5212 289.518 33.7883 295.728C29.674 305.058 34.1813 319.08 46.405 323.349C58.6288 327.618 76.4494 330 97.3899 330H165.386C190.226 330 210.547 328.523 218.728 326.493C236.716 322.016 248.898 313.186 253.173 296.925C258.555 276.366 234.816 255.794 211.518 275.925Z"
        fill="#24245D"
      />
      <Path
        d="M153.833 288.319C145.625 289.663 136.258 298.421 133.05 306.277C128.635 295.678 115.603 286.5 103.028 284.132C94.5004 282.526 85.2989 285.159 78.1135 290.726C77.5938 291.129 77.4991 291.877 77.9016 292.397C78.3047 292.917 79.0525 293.011 79.5723 292.609C86.2445 287.439 94.7683 285 102.588 286.473C115.9 288.979 129.846 299.686 131.962 310.847C132.22 312.204 134.193 312.109 134.318 310.734C135.003 303.244 145.546 292.089 154.218 290.67C158.431 289.98 162.576 291.041 165.953 293.412C168.944 295.513 170.96 298.431 171.253 300.894C171.404 302.167 173.184 302.335 173.571 301.113C175.142 296.144 180.254 289.579 185.859 285.675C192.379 281.132 198.441 280.675 202.957 285.214C203.422 285.68 204.175 285.682 204.642 285.218C205.108 284.754 205.11 284 204.646 283.534C199.176 278.037 191.864 278.588 184.497 283.721C179.574 287.151 175.036 292.452 172.597 297.275C169.759 291.537 161.774 287.019 153.833 288.319Z"
        fill="#121212"
      />
      <Path
        d="M153.833 288.319C145.625 289.663 136.258 298.421 133.05 306.277C128.635 295.678 115.603 286.5 103.028 284.132C94.5004 282.526 85.2989 285.159 78.1135 290.726C77.5938 291.129 77.4991 291.877 77.9016 292.397C78.3047 292.917 79.0525 293.011 79.5723 292.609C86.2445 287.439 94.7683 285 102.588 286.473C115.9 288.979 129.846 299.686 131.962 310.847C132.22 312.204 134.193 312.109 134.318 310.734C135.003 303.244 145.546 292.089 154.218 290.67C158.431 289.98 162.576 291.041 165.953 293.412C168.944 295.513 170.96 298.431 171.253 300.894C171.404 302.167 173.184 302.335 173.571 301.113C175.142 296.144 180.254 289.579 185.859 285.675C192.379 281.132 198.441 280.675 202.957 285.214C203.422 285.68 204.175 285.682 204.642 285.218C205.108 284.754 205.11 284 204.646 283.534C199.176 278.037 191.864 278.588 184.497 283.721C179.574 287.151 175.036 292.452 172.597 297.275C169.759 291.537 161.774 287.019 153.833 288.319Z"
        fill="#2E51DF"
        fillOpacity="0.09"
      />
      <Path
        d="M213.562 15.2138L219.701 2.12074C220.05 1.37642 219.729 0.490091 218.985 0.141121C218.241 -0.207848 217.354 0.112661 217.005 0.856982L210.867 13.95C210.518 14.6944 210.838 15.5807 211.582 15.9296C212.327 16.2787 213.213 15.9582 213.562 15.2138Z"
        fill="#73AAEB"
      />
      <Path
        d="M237.748 8.9415L224.643 18.3192C223.975 18.7976 223.821 19.7274 224.299 20.3959C224.778 21.0645 225.707 21.2186 226.376 20.7402L239.481 11.3625C240.149 10.8841 240.304 9.95435 239.825 9.28577C239.347 8.61724 238.417 8.46309 237.748 8.9415Z"
        fill="#73AAEB"
      />
      <Path
        d="M230.373 36.2608L242.311 36.0286C243.133 36.0126 243.787 35.3332 243.771 34.5109C243.755 33.6893 243.075 33.0361 242.254 33.0522L230.316 33.2844C229.493 33.2999 228.84 33.9792 228.856 34.8015C228.872 35.6232 229.551 36.2763 230.373 36.2608Z"
        fill="#73AAEB"
      />
    </Svg>
  );
};

export default SvgComponent;
