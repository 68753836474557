import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Medium;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  color: #ffffff;
  margin-bottom: 23px;
`;
