import styled from 'styled-components/native';

import Button from 'components/Button';

export default styled(Button).attrs(({ theme }) => ({
  labelColor: theme.marketplaceOrderDetails.actionText,
  bold: false,
  labelStyle: {
    fontSize: 15,
    fontFamily: 'BasierCircle-Medium',
    letterSpacing: -0.23,
  },
}))`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.backgroundHighlight : theme.marketplaceOrderDetails.actionBg};
  border-radius: 40px;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 40px 15px 0;
`;
