import * as ImagePicker from 'expo-image-picker';
import React, { useState } from 'react';
import { Platform } from 'react-native';
import { Image, ImageOrVideo, Options } from 'react-native-image-crop-picker';

import { VARIANT } from 'context/appInfo';
import { useImagePicker } from 'hooks/imagePicker';
import { UserGeneralInformation } from 'hooks/user';
import { useTheme } from 'themes';
import { getImageFile } from 'utils/imageUpload';

import ActivityIndicator from './styled/ActivityIndicator';
import ActivityIndicatorView from './styled/ActivityIndicatorView';
import Avatar from './styled/Avatar';
import AvatarGroup from './styled/AvatarGroup';
import AvatarView from './styled/AvatarView';
import BackgroundImage from './styled/BackgroundImage';
import BackgroundOverlay from './styled/BackgroundOverlay';
import ChangeAvatarButton from './styled/ChangeAvatarButton';
import ChangeCoverButton from './styled/ChangeCoverButton';
import Container from './styled/Container';
import CoverImage from './styled/CoverImage';
import CoverView from './styled/CoverView';
import CropAvatarButton from './styled/CropAvatarButton';
import CropBackgroundButton from './styled/CropBackgroundButton';
import CropIcon from './styled/CropIcon';
import Icon from './styled/Icon';
export interface ProfileAvatarProps {
  isUpdating?: boolean;
  profilePicture?: string;
  cover?: string;
  update: (data: UserGeneralInformation) => void;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  isUpdating,
  profilePicture,
  cover,
  update,
}: ProfileAvatarProps): JSX.Element => {
  const { theme } = useTheme();
  const [loadingBackground, setLoadingBackground] = useState<boolean>(VARIANT == 'artist');
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(true);

  const onAvatarChange = (result?: ImageOrVideo | ImagePicker.ImagePickerResult) => {
    setLoadingAvatar(true);
    const newImagePath = Platform.OS !== 'web' ? (result as any)?.path : (result as any)?.uri;
    getImageFile(newImagePath).then((file) => {
      update({
        profilePicture: file,
      });
    });
  };

  const onCoverChange = (result?: ImageOrVideo | ImagePicker.ImagePickerResult) => {
    setLoadingBackground(true);
    const newImagePath = Platform.OS !== 'web' ? (result as any)?.path : (result as any)?.uri;
    getImageFile(newImagePath).then((file) => {
      update({
        cover: file,
      });
    });
  };

  const { launchImagePicker: launchAvatarPicker } = useImagePicker({
    onMediaChange: onAvatarChange,
  });

  const { launchImagePicker: launchCoverPicker } = useImagePicker({
    onMediaChange: onCoverChange,
  });

  const { launchImageCropper } = useImagePicker();

  const getMediaUri = (m: Image) => {
    return m.path || m.uri;
  };

  const updateAvatar = (m: Image) => {
    const croppedImageUri = getMediaUri(m);
    getImageFile(croppedImageUri).then((file) => {
      update({
        profilePicture: file,
      });
    });
  };

  const updateCover = (m: Image) => {
    const croppedImageUri = getMediaUri(m);
    getImageFile(croppedImageUri).then((file) => {
      update({
        cover: file,
      });
    });
  };

  const onCropAvatarPress = () => {
    if (profilePicture) {
      launchImageCropper({
        cropperOptions: {
          path: profilePicture,
          forceJpg: true,
          height: 106 * 3,
          width: 106 * 3,
          cropperCircleOverlay: true,
        },
        onCrop: updateAvatar,
      });
    }
  };

  const onCropBackgroundPress = () => {
    if (cover) {
      launchImageCropper({
        cropperOptions: {
          path: cover,
          forceJpg: true,
          height: 277 * 3,
          width: 400 * 3,
        },
        onCrop: updateCover,
      });
    }
  };

  const pickerNativeOptions = {
    cropping: false,
    mediaType: 'photo',
    forceJpg: true,
  } as Options;

  const pickerWebOptions = {
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
  };

  const onChangeAvatarPress = async () => {
    launchAvatarPicker({ pickerNativeOptions, pickerWebOptions });
  };

  const onChangeCoverPress = async () => {
    launchCoverPicker({ pickerNativeOptions, pickerWebOptions });
  };

  return Platform.OS === 'web' ? (
    <Container>
      <AvatarGroup>
        <AvatarView>
          <Avatar onLoad={() => setLoadingAvatar(false)} source={{ uri: profilePicture }} />
          <ActivityIndicatorView>
            <ActivityIndicator animating={loadingAvatar || loadingBackground || isUpdating} />
          </ActivityIndicatorView>
        </AvatarView>
        <ChangeAvatarButton disabled={loadingAvatar || isUpdating} onPress={onChangeAvatarPress}>
          <Icon />
        </ChangeAvatarButton>
      </AvatarGroup>
    </Container>
  ) : (
    <Container>
      {VARIANT === 'artist' && (
        <CoverView>
          <BackgroundImage onLoad={() => setLoadingBackground(false)} source={{ uri: cover }} />
          <BackgroundOverlay colors={['transparent', theme.colors.background]} />
          <ChangeCoverButton
            disabled={loadingBackground || isUpdating}
            onPress={onChangeCoverPress}
          >
            <Icon />
          </ChangeCoverButton>
          {cover && (
            <CropBackgroundButton
              disabled={loadingBackground || isUpdating}
              onPress={onCropBackgroundPress}
            >
              <CropIcon />
            </CropBackgroundButton>
          )}
        </CoverView>
      )}
      {VARIANT === 'fan' && (
        <CoverView>
          <CoverImage source={{ uri: profilePicture }} blurRadius={8} />
          <BackgroundOverlay colors={['transparent', theme.colors.background]} />
        </CoverView>
      )}
      <AvatarGroup>
        <AvatarView>
          <Avatar onLoad={() => setLoadingAvatar(false)} source={{ uri: profilePicture }} />
          <ActivityIndicatorView>
            <ActivityIndicator animating={loadingAvatar || loadingBackground || isUpdating} />
          </ActivityIndicatorView>
        </AvatarView>
        <ChangeAvatarButton disabled={loadingAvatar || isUpdating} onPress={onChangeAvatarPress}>
          <Icon />
        </ChangeAvatarButton>
        {profilePicture && (
          <CropAvatarButton disabled={loadingAvatar || isUpdating} onPress={onCropAvatarPress}>
            <CropIcon />
          </CropAvatarButton>
        )}
      </AvatarGroup>
    </Container>
  );
};

export default ProfileAvatar;
