import React from 'react';

import Button from './styled/Button';
import Label from './styled/ButtonLabel';

interface Props {
  onPress?: () => void;
  bold?: boolean;
  warning?: boolean;
  children: React.ReactNode;
}

const ModalButton: React.FC<Props> = ({ bold, warning, onPress, children }) => (
  <Button onPress={onPress}>
    <Label bold={bold} warning={warning}>
      {children}
    </Label>
  </Button>
);

export default ModalButton;
