import React from 'react';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';

const SvgComponent: React.FC = styled(({ ...rest }) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
      <Path
        d="M22 3.5H2C1.17157 3.5 0.5 4.17157 0.5 5V19C0.5 19.8284 1.17157 20.5 2 20.5H22C22.8284 20.5 23.5 19.8284 23.5 19V5C23.5 4.17157 22.8284 3.5 22 3.5Z"
        fill="#243747"
      />
      <Path
        d="M9.5 16C11.7091 16 13.5 14.2091 13.5 12C13.5 9.79086 11.7091 8 9.5 8C7.29086 8 5.5 9.79086 5.5 12C5.5 14.2091 7.29086 16 9.5 16Z"
        fill="#E61C24"
      />
      <Path
        d="M14.467 16C16.6761 16 18.467 14.2091 18.467 12C18.467 9.79086 16.6761 8 14.467 8C12.2578 8 10.467 9.79086 10.467 12C10.467 14.2091 12.2578 16 14.467 16Z"
        fill="#F99F1B"
      />
      <Path
        d="M11.9835 8.86816C11.5108 9.24191 11.1287 9.71783 10.8661 10.2603C10.6035 10.8027 10.467 11.3975 10.467 12.0002C10.467 12.6028 10.6035 13.1977 10.8661 13.7401C11.1287 14.2825 11.5108 14.7584 11.9835 15.1322C12.4563 14.7584 12.8383 14.2825 13.101 13.7401C13.3636 13.1977 13.5 12.6028 13.5 12.0002C13.5 11.3975 13.3636 10.8027 13.101 10.2603C12.8383 9.71783 12.4563 9.24191 11.9835 8.86816Z"
        fill="#F26622"
      />
    </Svg>
  );
})`
  margin-right: 5px;
`;

export default SvgComponent;
