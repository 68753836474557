import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';

import { useBackEnd } from 'context';
import {
  InsightsDayRange,
  PostEngagement,
  PostGeneralInsights,
  PostReach,
  PostRevenue,
  ProductType,
  ProfileInsights,
} from 'types';
import { getModelNamePlural } from 'utils/product';

import { useAuthSwr } from './swr';

export interface UseProfileInsights {
  data?: ProfileInsights;
  isLoading?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export const useProfileInsights = (
  dayRange: InsightsDayRange,
  mockValue?: ProfileInsights,
): UseProfileInsights => {
  const { isLoading, data, error, mutate } = useAuthSwr<ProfileInsights>({
    key: `/artists/me/insights/profile/${dayRange}`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

export interface UsePostGeneralInsights {
  data?: PostGeneralInsights;
  isLoading?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export const usePostGeneralInsights = (
  id: number,
  modelName: ProductType,
  mockValue?: PostGeneralInsights,
): UsePostGeneralInsights => {
  const { isLoading, data, error, mutate } = useAuthSwr<PostGeneralInsights>({
    key: `/artists/me/products/${getModelNamePlural(modelName)}/${id}/insights/general`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

export const useLiveStreamInsights = (
  id: number,
  mockValue?: PostGeneralInsights,
): UsePostGeneralInsights => {
  const { isLoading, data, error, mutate } = useAuthSwr<PostGeneralInsights>({
    key: `/artists/me/insights/live_stream/${id}`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

export interface UsePostEngagement {
  data?: PostEngagement;
  isLoading?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export const usePostEngagement = (
  id: number,
  modelName: ProductType,
  mockValue?: PostEngagement,
): UsePostEngagement => {
  const { isLoading, data, error, mutate } = useAuthSwr<PostEngagement>({
    key: `/artists/me/products/${getModelNamePlural(modelName)}/${id}/insights/engagement`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

export interface UsePostReach {
  data?: PostReach;
  isLoading?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export const usePostReach = (
  id: number,
  modelName: ProductType,
  mockValue?: PostReach,
): UsePostReach => {
  const { isLoading, data, error, mutate } = useAuthSwr<PostReach>({
    key: `/artists/me/products/${getModelNamePlural(modelName)}/${id}/insights/reach`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

export interface UsePostRevenue {
  data?: PostRevenue;
  isLoading?: boolean;
  error: AxiosError;
  refresh: () => void;
}

export const usePostRevenue = (
  id: number,
  modelName: ProductType,
  mockValue?: PostRevenue,
): UsePostRevenue => {
  const { isLoading, data, error, mutate } = useAuthSwr<PostRevenue>({
    key: `/artists/me/products/${getModelNamePlural(modelName)}/${id}/insights/revenue`,
    isPublic: false,
    cache: true,
    swrOptions: {},
    mockValue,
  });

  return {
    isLoading: isLoading,
    data,
    error,
    refresh: mutate,
  };
};

interface UseInsights {
  createView: () => void;
  createShare: (to: 'whatsapp' | 'web') => void;
  createEngagement: () => void;
  createNewStan: () => void;
}

export const useInsights = (
  id: number,
  modelName: ProductType,
  artistUsername: string,
): UseInsights => {
  const BASE_KEY = useMemo(
    () => `/artists/${artistUsername}/products/${getModelNamePlural(modelName)}/${id}/insights`,
    [artistUsername, modelName, id],
  );
  const { axiosInstance } = useBackEnd();

  const createView = useCallback(() => {
    axiosInstance.post(`${BASE_KEY}/views`).catch();
  }, [axiosInstance, BASE_KEY]);

  const createShare = useCallback(
    (to: 'whatsapp' | 'web') => {
      axiosInstance.post(`${BASE_KEY}/shares`, { to }).catch();
    },
    [axiosInstance, BASE_KEY],
  );

  const createEngagement = useCallback(() => {
    axiosInstance.post(`${BASE_KEY}/engagements`).catch();
  }, [axiosInstance, BASE_KEY]);

  const createNewStan = useCallback(() => {
    axiosInstance.post(`${BASE_KEY}/new_subscriptions`).catch();
  }, [axiosInstance, BASE_KEY]);

  return { createView, createShare, createEngagement, createNewStan };
};
