export default {
  product_creation: {
    image: {
      id: 'notificationsGroupItem.add_photo',
      defaultMessage: '<strong>{fullName}</strong> added new photo.',
    },
    audio: {
      id: 'notificationsGroupItem.add_audio',
      defaultMessage: '<strong>{fullName}</strong> added new audio.',
    },
    video: {
      id: 'notificationsGroupItem.add_video',
      defaultMessage: '<strong>{fullName}</strong> added new video.',
    },
    poll: {
      id: 'notificationsGroupItem.add_poll',
      defaultMessage: '<strong>{fullName}</strong> added new poll.',
    },
    marketplaceitem: {
      id: 'notificationsGroupItem.marketplaceitem',
      defaultMessage: '<strong>{fullName}</strong> added a new listing in the StanStore.',
    },
    marketplaceitempayment: {
      id: 'notificationsGroupItem.marketplaceitempayment',
      defaultMessage: '<strong>{fullName}</strong> just paid for product.',
    },
    subscription: {
      id: 'notificationsGroupItem.subscription',
      defaultMessage: '<strong>{fullName}</strong> has subscribed to you!.',
    },
  },
  started_stream: {
    id: 'notificationsGroupItem.started_stream',
    defaultMessage: '<strong>{fullName}</strong> started Live moment.',
  },
};
