import { useNavigation, useRoute } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard, Platform, View } from 'react-native';

import { SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME } from 'stacks/fan/FanMarketplaceStack';

import Image from 'screens/ArtistJoinRequestSentScreen/styled/Image';
import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen/constants';

import MarketplaceOrderForm from 'containers/MarketplaceOrderForm';
import SubmitButton from 'containers/MarketplaceOrderForm/styled/SubmitButton';

import ActivityIndicator from 'components/ActivityIndicator';
import KeyboardAvoidingView from 'components/KeyboardAvoidingView';
import LogoImage from 'components/LogoImage';
import NavigationHeader from 'components/NavigationHeader';
import Logo from 'components/NavigationHeaderFan/styled/Logo';
import OverlayButton from 'components/OverlayButton';

import { useFanMarketplaceCreateOrder, useMarketplaceItem } from 'hooks';
import { useMarketplaceOrderControls } from 'hooks/marketplace';
import { MarketplaceItemQuestion, MarketplaceOrder, MarketplaceQuestionareAnswer } from 'types';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import FormContainer from './styled/FormContainer';
import HeaderWrapper from './styled/HeaderWrapper';
import OrderPreview from './styled/OrderPreview';
import OrderSuccessContainer from './styled/OrderSuccessContainer';
import { Description, Details, Title } from './styled/OrderSuccessContainer';
import ScreenView from './styled/ScreenView';
import SectionTitle from './styled/SectionTitle';
import WebHeaderWrapper from './styled/WebHeaderWrapper';

export { SCREEN_NAME };

const isWeb = Platform.OS === 'web';

type PaymentMethodFormProps = { order?: MarketplaceOrder; onSuccess: () => void };
const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  order,
  onSuccess,
}: PaymentMethodFormProps) => {
  const { onActionPress, paymentForm, order: updatedOrder } = useMarketplaceOrderControls(order);

  useEffect(() => {
    onActionPress(onSuccess, true);
  }, [updatedOrder]);

  return <>{paymentForm}</>;
};

interface MarketplaceProductDetailsScreenProps {}

type Params = {
  marketplaceProductId: number;
  artistUsername: string;
  item: string;
  answers: MarketplaceQuestionareAnswer[];
};

const MarketplaceProductDetailsScreen: React.FC<MarketplaceProductDetailsScreenProps> = () => {
  const intl = useIntl();
  const navigation = useNavigation<any>();
  const [form, setForm] = useState(true);
  const [payment, setPayment] = useState(true);
  const [success, setSuccess] = useState(false);
  const route = useRoute();
  const {
    marketplaceProductId,
    artistUsername,
    item: restoredItem,
    answers: restoredAnswers,
  } = route.params as Params;
  const { createOrder } = useFanMarketplaceCreateOrder(artistUsername, marketplaceProductId);
  const { item: apiItem, isLoading } = useMarketplaceItem(artistUsername, marketplaceProductId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const item = useMemo(() => apiItem || JSON.parse(restoredItem), [apiItem, restoredItem]);
  const [order, setOrder] = useState<MarketplaceOrder>();
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const updateOrder = async (formData: any) => {
    const answers = item?.questionares?.map((question: MarketplaceItemQuestion, index: number) => ({
      questionare: {
        id: question.id,
      },
      text: formData[`question-${index}`],
    }));
    return await createOrder({
      id: marketplaceProductId,
      questionare_answers: answers || [],
    });
  };

  const navigateToDetails = useCallback(
    (updatedOrder: MarketplaceOrder) =>
      navigation.navigate(MARKETPLACE_ORDER_DETAILS_SCREEN_NAME, {
        marketplaceProductId,
        order: JSON.stringify(updatedOrder),
      }),
    [],
  );

  const onValidSubmit = async (formData: any) => {
    setIsSubmitting(true);
    const updatedOrder = await updateOrder(formData);
    setOrder(updatedOrder);
    setIsSubmitting(false);
    if (isWeb) setPayment(true);
    else navigateToDetails(updatedOrder);
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }
    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  const saveDraft = () => {
    handleSubmit(updateOrder)();
  };

  const onLogoPress = () => navigation.navigate(HOME_SCREEN_NAME);
  const onCloseButtonPress = () =>
    navigation.canGoBack()
      ? navigation.goBack()
      : navigation.navigate(MARKETPLACE_STACK_SCREEN_NAME);

  useEffect(() => {
    if (isWeb && item?.questionares?.length === 0) {
      handleSubmit(onValidSubmit)();
    }
  }, [item?.questionares?.length]);

  return (
    <ScreenView>
      <KeyboardAvoidingView>
        {isWeb && (
          <WebHeaderWrapper>
            <Logo onPress={onLogoPress}>
              <LogoImage />
            </Logo>
            {!success && <OverlayButton icon="close" onPress={onCloseButtonPress} transparent />}
          </WebHeaderWrapper>
        )}
        {success ? (
          <Container isWeb>
            <OrderSuccessContainer>
              <Image />
              <Title completedOrder>Thanks!</Title>
              <Description>You will find your order in Purchased tab.</Description>
              <SubmitButton onPress={() => navigation.navigate(MARKETPLACE_STACK_SCREEN_NAME)}>
                Close
              </SubmitButton>
              <Details onPress={() => order && navigateToDetails(order)}>
                Show order details
              </Details>
            </OrderSuccessContainer>
          </Container>
        ) : (
          <Container stickyHeaderIndices={[0]} isWeb={isWeb} showsVerticalScrollIndicator={false}>
            {!isWeb && (
              <NavigationHeader
                insets={{ top: 0, bottom: 0, left: 0, right: 0 }}
                navigation={navigation}
                title={intl.formatMessage(messages.title)}
                onGoingBack={saveDraft}
              />
            )}
            <HeaderWrapper>
              <OrderPreview
                artistName={item?.artist?.display_name || ''}
                name={item?.name || ''}
                previewImage={item?.low_res_cover || ''}
                price={item?.price || ''}
                currency={item?.price_currency || ''}
                withoutMarker
                imageSize={isWeb ? '216px' : '104px'}
              />
            </HeaderWrapper>
            <View style={!isWeb && { flex: 1 }}>
              {!isLoading && (
                <>
                  {((Platform.OS === 'web' && item?.questionares?.length > 0) ||
                    Platform.OS !== 'web') && (
                    <FormContainer withBorderBottom={isWeb}>
                      {item?.questionares?.length > 0 && (
                        <SectionTitle
                          title={intl.formatMessage(messages.purchaseQuestionnaire)}
                          onToggle={() => setForm(!form)}
                          toggled={!form}
                          large={isWeb}
                        />
                      )}
                      {form && (
                        <MarketplaceOrderForm
                          questionares={item?.questionares || []}
                          updateError=""
                          isUpdating={false}
                          onSubmit={onSubmit}
                          isValid={isValid}
                          control={control}
                          isSubmitting={isSubmitting}
                          handleSubmit={handleSubmit}
                          clearErrors={clearErrors}
                          restoredAnswers={restoredAnswers || order?.questionare_answers}
                          saveDraft={saveDraft}
                          buttonLabel={intl.formatMessage(
                            messages[
                              Platform.OS === 'web'
                                ? order
                                  ? 'editQuestions'
                                  : 'confirmOrderWeb'
                                : 'confirmOrder'
                            ],
                          )}
                        />
                      )}
                    </FormContainer>
                  )}
                  {isWeb && (
                    <FormContainer>
                      <SectionTitle
                        title={intl.formatMessage(messages.paymentMethod)}
                        onToggle={() => setPayment(!payment)}
                        toggled={!payment}
                        large
                      />
                      {payment &&
                        (item?.questionares?.length === 0 && !order ? (
                          <ActivityIndicator animating style={{ marginTop: 30 }} />
                        ) : (
                          <PaymentMethodForm onSuccess={() => setSuccess(true)} order={order} />
                        ))}
                    </FormContainer>
                  )}
                </>
              )}
            </View>
          </Container>
        )}
      </KeyboardAvoidingView>
    </ScreenView>
  );
};

export default MarketplaceProductDetailsScreen;
