import React, { useEffect, useState } from 'react';

import PlaceholderWord from './styled/PlaceholderWord';
import View from './styled/View';

export interface PlaceholderTextProps {
  minNumWords?: number;
  maxNumWords?: number;
  minWordLength?: number;
  maxWordLength?: number;
  singleLine?: boolean;
}

const PlaceholderText: React.FC<PlaceholderTextProps> = ({
  minNumWords,
  maxNumWords,
  minWordLength,
  maxWordLength,
  singleLine,
  ...restProps
}: PlaceholderTextProps) => {
  const [wordLengths, setWordLengths] = useState<number[]>([]);

  useEffect(() => {
    const lengths = [
      ...new Array(
        (minNumWords as number) +
          Math.round(Math.random() * ((maxNumWords as number) - (minNumWords as number))),
      ),
    ].map(
      () =>
        (minWordLength as number) +
        Math.round(Math.random() * ((maxWordLength as number) - (minWordLength as number))),
    );

    setWordLengths(lengths);
  }, []);

  return (
    <View {...restProps}>
      {wordLengths.map((length, index) => (
        <PlaceholderWord
          key={index}
          numChars={length}
          singleLine={singleLine}
          firstChild={index === 0}
          lastChild={index === length - 1}
        />
      ))}
    </View>
  );
};

PlaceholderText.defaultProps = {
  minNumWords: 3,
  maxNumWords: 10,
  minWordLength: 2,
  maxWordLength: 8,
  singleLine: false,
};

export default PlaceholderText;
