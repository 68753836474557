import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const Icon = ({ props }: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path
      d="M18 20V10"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12 20V4"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6 20V14"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const Box = styled.View`
  border-width: 1px;
  border-color: transparent;
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
`;

const PollSymbol: React.FC = () => {
  return (
    <Box>
      <Icon />
    </Box>
  );
};

export default PollSymbol;
