import { Video, VideoProps } from 'expo-av';
import styled from 'styled-components/native';

export default styled(Video)<VideoProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
