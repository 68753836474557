import React from 'react';
import styled from 'styled-components/native';

const TouchableOpacity = styled.TouchableOpacity``;

interface NavigationButtonProps {
  icon: any;
  onPress?: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  icon,
  onPress,
}: NavigationButtonProps) => {
  return <TouchableOpacity onPress={onPress}>{icon}</TouchableOpacity>;
};

export default NavigationButton;
