import React from 'react';
import { useIntl } from 'react-intl';

import { useArtist } from 'hooks';

import hands from './images/hands.png';
import messages from './messages';
import ArtistImage from './styled/ArtistImage';
import CardView from './styled/CardView';
import ContentWrapper from './styled/ContentWrapper';
import Description from './styled/Description';
import Icon from './styled/Icon';
import TitleText from './styled/TitleText';
import TitleView from './styled/TitleView';

interface Props {
  artistUsername?: string;
}

const SubscriptionActivatedModal: React.FC<Props> = ({ artistUsername }: Props) => {
  const { artist } = useArtist(artistUsername ? artistUsername : '');
  const intl = useIntl();

  return (
    <CardView>
      <ContentWrapper>
        <ArtistImage source={{ uri: artist?.profile_picture }} />
        <TitleView>
          <TitleText>{intl.formatMessage(messages.youAreStan)} </TitleText>
          <Icon source={{ uri: hands }} />
        </TitleView>
        <Description>{intl.formatMessage(messages.description)}</Description>
      </ContentWrapper>
    </CardView>
  );
};

export default SubscriptionActivatedModal;
