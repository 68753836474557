export default {
  title: {
    id: 'post.title',
    defaultMessage: 'Edit post',
  },
  cancel: {
    id: 'post.cancel',
    defaultMessage: 'Cancel',
  },
  next: {
    id: 'post.next',
    defaultMessage: 'Next',
  },
  caption: {
    id: 'post.caption',
    defaultMessage: 'Caption (optional)',
  },
  delete: {
    id: 'post.delete',
    defaultMessage: 'Delete post',
  },
  deleted: {
    id: 'post.deleted',
    defaultMessage: 'Post has been deleted.',
  },
  closeSnackbar: {
    id: 'post.closeSnackbar',
    defaultMessage: 'Close',
  },
  deleteModal: {
    title: {
      id: 'post.deleteModal.title',
      defaultMessage: 'Are you sure that you want to delete your post?',
    },
    delete: {
      id: 'post.deleteModal.modal',
      defaultMessage: 'Yes. Delete it.',
    },
    cancel: {
      id: 'post.deleteModal.cancel',
      defaultMessage: 'Cancel',
    },
  },
  error: {
    other: {
      id: 'post.error.other',
      defaultMessage: 'Unexpected error happened',
    },
  },
};
