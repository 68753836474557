import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_JOIN_REQUEST_SENT_SCREEN_NAME } from 'screens/ArtistJoinRequestSentScreen';

import KeyboardAvoidingView from 'components/KeyboardAvoidingView';

import { useBackEnd } from 'context';
import regex from 'utils/regex';

import { SCREEN_NAME } from './constants';
import messages from './messages';
import Container from './styled/Container';
import Dropdown from './styled/Dropdown';
import DropdownView from './styled/DropdownView';
import ErrorsView from './styled/ErrorsView';
import ErrorText from './styled/ErrorText';
import FeeTextInput from './styled/FeeTextInput';
import FeeTextView from './styled/FeeTextView';
import ScreenView from './styled/ScreenView';
import ScrollView from './styled/ScrollView';
import SubmitButton from './styled/SubmitButton';
import Subtitle from './styled/Subtitle';
import TextInput from './styled/TextInput';
import Title from './styled/Title';

export { SCREEN_NAME };

type ArtistJoinStanbaseScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ArtistJoinStanbaseScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ArtistJoinStanbaseScreenRouteProp;
  navigation: ArtistJoinStanbaseScreenNavigationProp;
};

const ArtistJoinStanbaseScreen: React.FC<Props> = ({ navigation }: Props) => {
  const [submitError, setSubmitError] = useState<string>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [activeCurrency, setActiveCurrency] = useState<'USD' | 'GBP' | 'EUR'>('USD');

  const intl = useIntl();
  const { axiosInstance } = useBackEnd();
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useForm<{
    artistName: string;
    email: string;
    referralCode: string;
    instagramUsername: string;
    subscriptionFee: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      artistName: '',
      email: '',
      referralCode: '',
      instagramUsername: '',
      subscriptionFee: '',
    },
  });

  const onSelectCurrency = (currency: string) => {
    setActiveCurrency(currency);
    setExpanded(false);
  };

  const onValidSubmit = async (args: {
    artistName: string;
    email: string;
    referralCode: string;
    instagramUsername: string;
    subscriptionFee: string;
  }) => {
    setSubmitError(undefined);
    const { artistName, email, referralCode, instagramUsername, subscriptionFee } = args;
    try {
      await axiosInstance.post('/support/artist_access_request', {
        name: artistName,
        email,
        referral_code: referralCode,
        instagram_username: instagramUsername,
        subscription_fee: subscriptionFee,
        subscription_fee_currency: activeCurrency,
      });
    } catch (e) {
      console.log(e);
      setSubmitError(intl.formatMessage(messages.error.tryAgain));
      return false;
    }
    return navigation.navigate(ARTIST_JOIN_REQUEST_SENT_SCREEN_NAME);
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    Keyboard.dismiss();
    handleSubmit(onValidSubmit)();
  };

  return (
    <ScreenView>
      <KeyboardAvoidingView>
        <ScrollView>
          <Title>{intl.formatMessage(messages.join)}</Title>
          <Subtitle>{intl.formatMessage(messages.fill)}</Subtitle>
          <Container>
            <Controller
              control={control}
              name="artistName"
              defaultValue=""
              rules={{
                required: intl.formatMessage(messages.error.artistNameRequired),
              }}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  disabled={isSubmitting}
                  returnKeyType="next"
                  autoCapitalize="words"
                  placeholder="Artist name"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('artistName');
                    onChange(value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{
                required: intl.formatMessage(messages.error.emailRequired),
                pattern: {
                  value: regex.email,
                  message: intl.formatMessage(messages.error.emailIncorrect),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  disabled={isSubmitting}
                  returnKeyType="next"
                  autoCapitalize="none"
                  placeholder="E-mail address"
                  keyboardType="email-address"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('email');
                    onChange(value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="referralCode"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  disabled={isSubmitting}
                  returnKeyType="next"
                  autoCapitalize="none"
                  placeholder="Referral code (optional)"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('referralCode');
                    onChange(value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="instagramUsername"
              defaultValue=""
              rules={{
                required: 'Instagram username is required.',
              }}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  disabled={isSubmitting}
                  returnKeyType="next"
                  autoCapitalize="none"
                  placeholder="Instagram username"
                  value={value}
                  onBlur={onBlur}
                  onChangeText={(value: string) => {
                    clearErrors('instagramUsername');
                    onChange(value);
                  }}
                />
              )}
            />

            <DropdownView>
              <Dropdown
                expanded={expanded}
                activeCurrency={activeCurrency}
                onSelectCurrency={onSelectCurrency}
                setExpanded={setExpanded}
              />
              <FeeTextView>
                <Controller
                  control={control}
                  name="subscriptionFee"
                  defaultValue=""
                  rules={{
                    required: 'Subscription fee is required.',
                  }}
                  render={({ onChange, onBlur, value }) => (
                    <FeeTextInput
                      disabled={isSubmitting}
                      keyboardType="numeric"
                      returnKeyType="go"
                      autoCapitalize="none"
                      placeholder="Subscription fee"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={(value: string) => {
                        clearErrors('subscriptionFee');
                        onChange(value);
                      }}
                    />
                  )}
                />
              </FeeTextView>
            </DropdownView>

            {!!submitError && (
              <ErrorsView>
                <ErrorText>{submitError}</ErrorText>
              </ErrorsView>
            )}

            <SubmitButton disabled={!isValid} processing={isSubmitting} onPress={onSubmit}>
              {intl.formatMessage(messages.send)}
            </SubmitButton>
          </Container>
        </ScrollView>
      </KeyboardAvoidingView>
    </ScreenView>
  );
};

ArtistJoinStanbaseScreen.displayName = 'ArtistJoinStanbaseScreen';

export default ArtistJoinStanbaseScreen;
