import styled from 'styled-components/native';

export default styled.ScrollView.attrs({
  contentContainerStyle: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: 60,
  },
})`
  padding: 50px 15px 0;
`;
