import styled from 'styled-components/native';

export default styled.Text`
  font-family: 'BasierCircle-Regular';
  color: ${({ theme }) => theme.bottomSheet.secondText};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.06px;
  align-self: center;
  text-align: center;
  margin: 20px 0 20px 0;
`;
