import { ActivityIndicator } from 'react-native-paper';
import styled from 'styled-components/native';

const LoadingIndicator = styled(ActivityIndicator).attrs({
  color: '#ffffff',
  size: 50,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
`;

export default LoadingIndicator;
