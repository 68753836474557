import debounce from 'lodash.debounce';
import React, { useCallback, useRef, useState } from 'react';

import Action from './styled/Action';
import ActivityIndicator from './styled/ActivityIndicator';
import Container from './styled/Container';
import Icon from './styled/Icon';
import Input from './styled/Input';

export interface SearchBarProps {
  isLoading?: boolean;
  inputRestProps?: { [k: string]: any };
  value: string;
  onCancel: () => void;
  onChange: (text: string) => void;
  onFocus?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  inputRestProps = {},
  value,
  onCancel,
  onChange,
  onFocus,
  ...restProps
}: SearchBarProps) => {
  const inputRef = useRef(null);
  const [showAction, setShowAction] = useState<boolean>(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  const onSearchFocus = () => {
    setShowAction(true);
    onFocus?.();
  };

  const onSearchCancel = () => {
    (inputRef.current as any)?.blur();
    setShowAction(false);
    onCancel();
  };

  const renderIcon = () => {
    if (isSpinnerVisible) {
      return <ActivityIndicator animating />;
    } else {
      return <Icon />;
    }
  };

  const changeVisibility = () => {
    setIsSpinnerVisible(false);
  };
  const debouncedHandler = useCallback(debounce(changeVisibility, 700), []);

  return (
    <Container {...restProps}>
      <Input
        {...inputRestProps}
        clearButtonMode="always"
        onChangeText={(text) => {
          onChange(text);
          setIsSpinnerVisible(true);
          debouncedHandler();
        }}
        onFocus={onSearchFocus}
        placeholder="Search"
        value={value}
        ref={inputRef}
      />
      {renderIcon()}
      {showAction && <Action onPress={onSearchCancel} text="Cancel" />}
    </Container>
  );
};

SearchBar.displayName = 'SearchBar';

export default SearchBar;
