import styled from 'styled-components/native';

export default styled.Text`
  color: ${({ theme }) => theme.colors.primaryActive};
  font-size: 34px;
  letter-spacing: 0.41px;
  font-weight: bold;
  line-height: 41px;
  margin: 0 0 35px 15px;
`;
