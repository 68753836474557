export default {
  title: {
    id: 'investorRelations.title',
    defaultMessage: 'Investors',
  },
  description: {
    id: 'investorRelations.description',
    defaultMessage: 'Please click the button below to contact our investor relations department',
  },
  button: {
    id: 'investorRelations.button',
    defaultMessage: 'Contact Us',
  },
};
