import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

interface GradientProps {
  isActive?: boolean;
  index?: number;
  currentIndex?: number;
}

export default styled(LinearGradient).attrs((props) => {
  if (props.index > props.currentIndex) {
    return {
      colors: ['rgba(18, 18, 18, 0.3)', 'transparent'],
      start: { x: 0.5, y: 0 },
      end: { x: -0.2, y: 0 },
    };
  } else {
    return {
      colors: ['rgba(18, 18, 18, 0.3)', 'transparent'],
      start: { x: 0.5, y: 0 },
      end: { x: 1.5, y: 0 },
    };
  }
})<GradientProps>`
  width: 150px;
  height: 160px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isActive }) => (isActive ? '0' : '1')};
  z-index: 5;
`;
