import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

interface Props {
  bold?: boolean;
}

export default styled.Text<Props>`
  font-size: 17px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: center;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${Platform.OS === 'web' &&
  css`
    align-self: center;
    width: 66%;
  `}
`;
