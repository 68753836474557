import React, { useCallback } from 'react';
import { Linking } from 'react-native';

import { VARIANT } from 'context/appInfo';
import { artistGooglePlayURL, fanGooglePlayURL } from 'utils/links';

import googlePlay from './images/google-play.svg';
import Image from './styled/Image';
import TouchableOpacity from './styled/TouchableOpacity';

const DownloadButtonGooglePlay: React.FC = (props) => {
  const onDownloadPress = useCallback(() => {
    Linking.openURL(VARIANT === 'fan' ? fanGooglePlayURL : artistGooglePlayURL);
  }, [fanGooglePlayURL, artistGooglePlayURL]);

  return (
    <TouchableOpacity onPress={onDownloadPress}>
      <Image resizeMode="contain" source={{ uri: googlePlay }} {...props} />
    </TouchableOpacity>
  );
};

export default DownloadButtonGooglePlay;
