import React, { memo } from 'react';

import Gradient from './styled/Gradient';
import OverlayView from './styled/OverlayView';

export interface ItemOverlayProps {}

const ItemOverlay: React.FC<ItemOverlayProps> = () => {
  return (
    <OverlayView>
      <Gradient />
    </OverlayView>
  );
};

export default memo(ItemOverlay);
