import styled from 'styled-components/native';

interface TextProps {
  isActive?: boolean;
}

export default styled.Text<TextProps>`
  margin-top: ${({ isActive }) => (isActive ? 0 : 25)}px;
  letter-spacing: -0.08px;
  color: ${({ isActive }) => (isActive ? 'rgba(255, 255, 255, 0.87)' : 'rgba(255, 255, 255, 0.4)')};
  font-size: 13px;
  line-height: 18px;
  font-family: 'BasierCircle-Regular';
`;
