import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { PanResponder } from 'react-native';
import { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

import ArtistMarketplaceOrderHeader from 'components/ArtistMarketplaceOrderHeader';

import { MarketplaceOrder } from 'types';
import { SPRING_CONFIG } from 'utils/animated';

import messages from './messages';
import DeleteButton from './styled/DeleteButton';
import GreenDot from './styled/GreenDot';
import ItemView from './styled/ItemView';
import TouchableOpacity from './styled/TouchableOpacity';

interface ArtistMarketplaceListItemProps {
  order: MarketplaceOrder;
  index: number;
  isNew?: boolean;
  hideStatus?: boolean;
  deletable?: boolean;
  onPress?: (order: MarketplaceOrder) => void;
  onDeletePress?: (order: MarketplaceOrder) => void;
}

const ArtistMarketplaceListItem: React.FC<ArtistMarketplaceListItemProps> = ({
  order,
  index,
  isNew,
  hideStatus = false,
  deletable = false,
  onPress,
  onDeletePress,
}: ArtistMarketplaceListItemProps) => {
  const intl = useIntl();

  const buttonsViewWidth = 95;
  const translateX = useSharedValue(0);
  const translateStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: withSpring(translateX.value, SPRING_CONFIG.NO_BOUNCE),
      },
    ],
  }));
  let isExpanded = useRef(false).current;

  const onTouchCancel = (dx: number) => {
    if (!isExpanded) {
      if (dx <= -buttonsViewWidth / 2) {
        translateX.value = -buttonsViewWidth;
        isExpanded = true;
      } else {
        translateX.value = 0;
        isExpanded = false;
      }
    } else {
      if (dx >= buttonsViewWidth / 2) {
        translateX.value = 0;
        isExpanded = false;
      } else {
        translateX.value = -buttonsViewWidth;
        isExpanded = true;
      }
    }
  };

  const panResponder = useMemo(
    () =>
      deletable
        ? PanResponder.create({
            onStartShouldSetPanResponder: () => true,
            onStartShouldSetPanResponderCapture: () => false,
            onMoveShouldSetPanResponder: (_, gestureState) => Math.abs(gestureState.dx) > 10,
            onShouldBlockNativeResponder: () => false,
            onPanResponderTerminate: (_, gestureState) => onTouchCancel(gestureState.dx),
            onPanResponderMove: (e, gestureState) => {
              const dx = gestureState.dx;
              if (!isExpanded) {
                if (dx < 0 && dx >= -buttonsViewWidth) {
                  translateX.value = dx;
                }
              } else {
                if (dx > 0 && dx <= buttonsViewWidth) {
                  translateX.value = -buttonsViewWidth + dx;
                }
              }
            },
            onPanResponderRelease: (_, gestureState) => onTouchCancel(gestureState.dx),
          })
        : null,
    [buttonsViewWidth, deletable],
  );

  const onPressInner = useCallback(() => onPress?.(order), [onPress, order]);
  const onDeletePressInner = useCallback(() => onDeletePress?.(order), [onDeletePress, order]);

  return (
    <>
      {deletable && (
        <DeleteButton style={{ width: buttonsViewWidth }} onPress={onDeletePressInner}>
          {intl.formatMessage(messages.delete)}
        </DeleteButton>
      )}
      <ItemView style={translateStyle} {...panResponder?.panHandlers} index={index}>
        <TouchableOpacity onPress={onPressInner}>
          {isNew && <GreenDot />}
          <ArtistMarketplaceOrderHeader
            title={order.product.name}
            description={intl.formatMessage(messages.orderNumber, { id: () => order.number })}
            timestamp={order.time_created}
            cover={order.product.low_res_cover}
            avatar={order.user?.low_res_profile_picture || ''}
            status={hideStatus ? undefined : order.status}
          />
        </TouchableOpacity>
      </ItemView>
    </>
  );
};

export default ArtistMarketplaceListItem;
