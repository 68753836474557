import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(${({ blurAmount }: { blurAmount?: number }) => blurAmount || 0}px);
`;
