export default {
  title: {
    id: 'artistConfirmEmailScreen.title',
    defaultMessage: 'Email Confirmed!',
  },
  description: {
    id: 'artistConfirmEmailScreen.description',
    defaultMessage:
      'Thanks for confirming your details. StanBase will be paying you using your our partner wise.com at the end of every month, so keep your eyes peeled for an email from them',
  },
};
