import styled from 'styled-components/native';

export default styled.View`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
