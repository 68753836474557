import { PathConfigMap } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';

import DiscoverStack, {
  linking as discoverStackLinking,
  SCREEN_NAME as DISCOVER_STACK_SCREEN_NAME,
} from 'stacks/fan/FanDiscoverStack';
import FanHomeStack, {
  linking as homeStackLinking,
  SCREEN_NAME as HOME_STACK_SCREEN_NAME,
} from 'stacks/fan/FanHomeStack';
import MarketplaceStack, {
  linking as marketplaceStackLinking,
  SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME,
} from 'stacks/fan/FanMarketplaceStack';
import FanSettingsStack, {
  linking as settingsStackLinking,
  SCREEN_NAME as SETTINGS_STACK_SCREEN_NAME,
} from 'stacks/fan/FanSettingsStack';

import { SCREEN_NAME as DISCOVER_SCREEN_NAME } from 'screens/DiscoverScreen/constants';
import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen/constants';
import { SCREEN_NAME as MARKETPLACE_SCREEN_NAME } from 'screens/MarketplaceAvailableItemsScreen/constants';
import { SCREEN_NAME as PROFILE_DASHBOARD_SCREEN_NAME } from 'screens/ProfileDashboardScreen/constants';

import Tab from 'components/TabNavigator';

import { useNotificationsContext } from 'context/notifications';
import { useConfig } from 'hooks';

export { SCREEN_NAME } from './constants';
import IconDiscover from 'components/Icons/IconDiscover';
import IconFeed from 'components/Icons/IconFeed';
import IconMarketplace from 'components/Icons/IconMarketplace';
import IconProfile from 'components/Icons/IconProfile';

export interface Props {}

export const pathConfigMap: PathConfigMap = {
  [HOME_STACK_SCREEN_NAME]: {
    path: '/',
    initialRouteName: HOME_SCREEN_NAME,
    screens: homeStackLinking,
  },
  [DISCOVER_STACK_SCREEN_NAME]: {
    path: '/discover',
    initialRouteName: DISCOVER_SCREEN_NAME,
    screens: discoverStackLinking,
  },
  [MARKETPLACE_STACK_SCREEN_NAME]: {
    path: '/marketplace',
    initialRouteName: MARKETPLACE_SCREEN_NAME,
    screens: marketplaceStackLinking,
  },
  [SETTINGS_STACK_SCREEN_NAME]: {
    path: '/settings',
    initialRouteName: PROFILE_DASHBOARD_SCREEN_NAME,
    screens: settingsStackLinking,
  },
};

const FanTabStack: React.FC<Props> = () => {
  const intl = useIntl();
  const { config } = useConfig();
  const notifications = useNotificationsContext();

  return (
    <Tab.Navigator>
      <Tab.Screen
        name={HOME_STACK_SCREEN_NAME}
        component={FanHomeStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.home',
            defaultMessage: 'Home',
          }),
          tabBarIcon: function TabBarIconHome(iconProps) {
            return <IconFeed {...iconProps} />;
          },
        }}
      />
      <Tab.Screen
        name={DISCOVER_STACK_SCREEN_NAME}
        component={DiscoverStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.discover',
            defaultMessage: 'Discover',
          }),
          tabBarIcon: function TabBarIconDiscover(iconProps) {
            return <IconDiscover {...iconProps} />;
          },
        }}
      />
      {config.fanMarketplaceEnabled && (
        <Tab.Screen
          name={MARKETPLACE_STACK_SCREEN_NAME}
          component={MarketplaceStack}
          options={{
            title: intl.formatMessage({
              id: 'tab.marketplace',
              defaultMessage: 'Marketplace',
            }),
            tabBarIcon: function TabBarIconDiscover(iconProps) {
              return (
                <IconMarketplace
                  hasNotifications={
                    notifications.marketplaceOrder || notifications.marketplaceMessage
                  }
                  {...iconProps}
                />
              );
            },
          }}
        />
      )}
      <Tab.Screen
        name={SETTINGS_STACK_SCREEN_NAME}
        component={FanSettingsStack}
        options={{
          title: intl.formatMessage({
            id: 'tab.profile',
            defaultMessage: 'Profile',
          }),
          tabBarIcon: function TabBarIconProfile(iconProps) {
            return <IconProfile {...iconProps} />;
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default FanTabStack;
