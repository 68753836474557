import React from 'react';

import Footer from './styled/Footer';
import FooterContainer from './styled/FooterContainer';
import Text from './styled/Text';

interface WebFooterProps {
  secondary?: boolean;
}

const WebFooter: React.FC<WebFooterProps> = ({ secondary }: WebFooterProps) => {
  return (
    <Footer textColor="rgba(255, 255, 255, 0.52)" secondary={secondary}>
      <FooterContainer>
        <Text>{`Contact   •   Investor Relations`}</Text>
      </FooterContainer>
    </Footer>
  );
};

export default WebFooter;
