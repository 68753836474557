import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  align-items: center;
  background-color: ${({ theme }) => theme.profileAvatar.actionBackground};
  border-radius: 16px;
  bottom: -5px;
  height: 32px;
  justify-content: center;
  position: absolute;
  left: -2px;
  width: 32px;
`;
