import styled from 'styled-components/native';

import TextInput from 'components/TextInput';

interface TextInputProps {
  multiline?: boolean;
}

export default styled(TextInput).attrs(() => {
  return {
    theme: {
      colors: {
        primary: 'transparent',
        background: '#fafafa',
        borderColor: '#9e9e9e',
        text: '#121212',
      },
    },
  };
})<TextInputProps>`
  position: relative;
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 6px;
  background-color: #fafafa;
  margin-top: 32px;
`;
