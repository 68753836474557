import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

function GlobeIcon(props: SvgProps) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z"
        stroke="#BCFFC9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.3335 8H14.6668"
        stroke="#BCFFC9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.00016 1.33337C9.66768 3.15894 10.6153 5.52806 10.6668 8.00004C10.6153 10.472 9.66768 12.8411 8.00016 14.6667C6.33264 12.8411 5.38499 10.472 5.3335 8.00004C5.38499 5.52806 6.33264 3.15894 8.00016 1.33337V1.33337Z"
        stroke="#BCFFC9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const StyledGlobeIcon = styled(GlobeIcon)`
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export default React.memo(StyledGlobeIcon);
