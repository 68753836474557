import styled from 'styled-components/native';

export default styled.Text`
  font-family: BasierCircle-Regular;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.26px;
  color: #ffffff;
  margin-bottom: 25px;
`;
