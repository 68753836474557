import { Platform, Share, ShareAction } from 'react-native';

import { ArtistProduct } from 'types';

const PRODUCTION_WEB_URL = 'https://stanbase.com';

export const BASE_WEB_URL =
  process.env.ENV === 'production'
    ? PRODUCTION_WEB_URL
    : `https://${process.env.ENV?.replace(
        'local',
        'development',
      )}-dot-stanbase-c16b.uc.r.appspot.com`;

export function generateProductUrl(product: ArtistProduct): string {
  return `${BASE_WEB_URL}/artist/${product.artist.username}/${product.model_name}/${product.id}`;
}

export function generateMarketplaceUrl(product: ArtistProduct): string {
  return `${BASE_WEB_URL}/marketplace/product-details/${product.artist.username}/${product.id}`;
}

export function generateArtistUrl(username: string): string {
  return `${BASE_WEB_URL}/artist/${username}`;
}

export async function shareProduct(product: ArtistProduct): Promise<ShareAction> {
  if (Platform.OS === 'web') {
    console.warn('Sharing not supported on web yet.');
    return Promise.reject();
  }

  const url =
    product?.model_name === 'marketplaceitem'
      ? generateMarketplaceUrl(product)
      : generateProductUrl(product);

  if (Platform.OS === 'ios') {
    return Share.share({
      url,
    });
  } else {
    return Share.share({
      message: url,
    });
  }
}

export async function shareArtist(username: string): Promise<ShareAction> {
  if (Platform.OS === 'web') {
    console.warn('Sharing not supported on web yet.');
    return Promise.reject();
  }

  const url = generateArtistUrl(username);

  if (Platform.OS === 'ios') {
    return Share.share({
      url,
    });
  } else {
    return Share.share({
      message: url,
    });
  }
}

export async function shareCustomWebsite(url: string): Promise<ShareAction> {
  if (Platform.OS === 'web') {
    console.warn('Sharing not supported on web yet.');
    return Promise.reject();
  }

  if (Platform.OS === 'ios') {
    return Share.share({
      url,
    });
  } else {
    return Share.share({
      message: url,
    });
  }
}
