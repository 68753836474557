import styled from 'styled-components/native';

export default styled.Text`
  font-size: 17px;
  letter-spacing: -0.43px;
  color: #ffffff;
  margin: 25px 0 10px 0;
  line-height: 22px;
  font-family: 'BasierCircle-Semibold';
  font-weight: bold;
`;
