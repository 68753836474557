import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  stroke?: string;
  fill?: string;
}

const SvgComponent: React.FC<Props> = ({ stroke = '#fff', fill = 'none' }: Props) => {
  return (
    <Svg width={20} height={22} viewBox="0 0 20 22" fill={fill}>
      <Path
        d="M1 8l9-7 9 7v11a2 2 0 01-2 2H3a2 2 0 01-2-2V8z"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
