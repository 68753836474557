import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View<{ width?: number }>`
  align-self: center;
  flex-direction: ${Platform.OS === 'web' ? 'column' : 'row'};
  flex-wrap: wrap;

  ${({ theme, width }) =>
    theme.media.tablet &&
    css`
      width: ${width}px;
      flex-direction: row;
    `}
`;
