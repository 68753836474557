import { FC, useState } from 'react';
import Svg, { Path } from 'react-native-svg';

import * as S from 'components/SocialButtons/SignInWithFacebookButton/SignInWithFacebookButton.styled';

export interface SignInWithGoogleButtonProps {
  onPress?: () => void;
}

const SignInWithFacebookButton: FC<SignInWithGoogleButtonProps> = ({ onPress }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <S.Wrapper
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ backgroundColor: isHovered ? 'rgb(57, 117, 234)' : 'rgb(35, 116, 255)' }}
    >
      <S.IconView>
        <Svg viewBox="0 0 40 40" width={24} height={24}>
          <Path
            fill="#fff"
            d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z"
          />
          <Path
            fill="rgb(57, 117, 234)"
            d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z"
          />
        </Svg>
      </S.IconView>
      <S.Label>Continue with Facebook</S.Label>
    </S.Wrapper>
  );
};

export default SignInWithFacebookButton;
