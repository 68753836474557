import { getStateFromPath, LinkingOptions } from '@react-navigation/native';
import { Linking, Platform } from 'react-native';

import {
  pathConfigMap as loginStackConfigMap,
  SCREEN_NAME as LOGIN_STACK_SCREEN_NAME,
} from 'stacks/common/LoginStack';
import { SCREEN_NAME as HOME_STACK_SCREEN_NAME } from 'stacks/fan/FanHomeStack';
import {
  linking as fanMarketplaceStackPathConfigMap,
  SCREEN_NAME as MARKETPLACE_STACK_SCREEN_NAME,
} from 'stacks/fan/FanMarketplaceStack';
import {
  pathConfigMap as fanTabStackPathConfigMap,
  SCREEN_NAME as FAN_TAB_STACK_SCREEN_NAME,
} from 'stacks/fan/FanTabStack';

import { SCREEN_NAME as ARTIST_CONFIRM_EMAIL_SCREEN } from 'screens/ArtistConfirmEmailScreen';
import { SCREEN_NAME as ARTIST_PROFILE_SCREEN_NAME } from 'screens/ArtistProfileScreen';
import { SCREEN_NAME as CONFIRM_PAYMENT_SCREEN } from 'screens/ConfirmPaymentScreen';
import { SCREEN_NAME as CONTACT_FORM_SCREEN_NAME } from 'screens/ContactFormScreen';
import { SCREEN_NAME as CONTACT_SCREEN_NAME } from 'screens/ContactScreen';
import { SCREEN_NAME_ADD as ADD_PAYMENT_METHOD_SCREEN_NAME } from 'screens/EditPaymentMethodScreen';
import { SCREEN_NAME as FINISH_SIGN_UP_SCREEN_NAME } from 'screens/FinishSignUpScreen';
import { SCREEN_NAME as HELP_FORM_SCREEN_NAME } from 'screens/HelpFormScreen';
import { SCREEN_NAME as HELP_SCREEN_NAME } from 'screens/HelpScreen';
import { SCREEN_NAME as HOME_SCREEN_NAME } from 'screens/HomeScreen';
import { SCREEN_NAME as INVESTOR_RELATIONS_FORM_SCREEN_NAME } from 'screens/InvestorRelationsFormScreen';
import { SCREEN_NAME as INVESTOR_RELATIONS_SCREEN_NAME } from 'screens/InvestorRelationsScreen';
import { SCREEN_NAME as LIVE_STREAM_PLAYBACK_SCREEN_NAME } from 'screens/LiveStreamPlaybackScreen';
import { SCREEN_NAME as MARKETPLACE_AVAILABLE_SCREEN_NAME } from 'screens/MarketplaceAvailableItemsScreen';
import { SCREEN_NAME as MARKETPLACE_NEW_MESSAGE_SCREEN } from 'screens/MarketplaceNewMessageScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_DETAILS_SCREEN_NAME } from 'screens/MarketplaceOrderDetailsScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME } from 'screens/MarketplaceOrderInformationScreen';
import { SCREEN_NAME as MARKETPLACE_ORDER_REPORT_SCREEN_NAME } from 'screens/MarketplaceOrderReportScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME } from 'screens/MarketplaceProductDetailsScreen';
import { SCREEN_NAME as MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME } from 'screens/MarketplaceProductOrderScreen';
import { SCREEN_NAME as NOTIFICATIONS_SCREEN_NAME } from 'screens/NotificationsScreen';
import { SCREEN_NAME as ON_BOARDING_SCREEN_NAME } from 'screens/OnBoardingScreen';
import { SCREEN_NAME as PRESS_FORM_SCREEN_NAME } from 'screens/PressFormScreen';
import { SCREEN_NAME as PRESS_SCREEN_NAME } from 'screens/PressScreen';
import { SCREEN_NAME as PROFILE_SETTINGS_SCREEN_NAME } from 'screens/ProfileSettingsScreen';
import { SCREEN_NAME as SAVED_POSTS_SCREEN_NAME } from 'screens/SavedPostsScreen';
import { SCREEN_NAME as SEARCH_AND_DISCOVERY_SCREEN_NAME } from 'screens/SearchAndDiscoveryScreen';
import { SCREEN_NAME as SETTINGS_SCREEN_NAME } from 'screens/SettingsScreen';
import { SCREEN_NAME as SINGLE_POST_SCREEN_NAME } from 'screens/SinglePostScreen';
import { SCREEN_NAME as WELCOME_SCREEN_NAME } from 'screens/WelcomeScreen';

import { BASE_WEB_URL } from 'utils/sharing';

export const linking: LinkingOptions<any> = {
  prefixes: [BASE_WEB_URL, `com.stanbase://`],
  subscribe(listener: (arg: string) => any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);
    Linking.addEventListener('url', onReceiveURL);
    return () => {
      Linking.removeEventListener('url', onReceiveURL);
    };
  },
  config: {
    initialRouteName: LOGIN_STACK_SCREEN_NAME,
    screens: {
      [WELCOME_SCREEN_NAME]: {
        path: '/welcome',
      },
      ...(Platform.OS === 'web'
        ? {
            [HOME_SCREEN_NAME]: '/',
            [SAVED_POSTS_SCREEN_NAME]: '/saved-posts',
            [SETTINGS_SCREEN_NAME]: '/settings',
            [SEARCH_AND_DISCOVERY_SCREEN_NAME]: '/search',
            [SINGLE_POST_SCREEN_NAME]: 'artist/:artistUsername/:productType/:productId',
            [MARKETPLACE_STACK_SCREEN_NAME]: {
              path: '/marketplace',
              initialRouteName: MARKETPLACE_AVAILABLE_SCREEN_NAME,
              screens: fanMarketplaceStackPathConfigMap,
            },
          }
        : {
            [FAN_TAB_STACK_SCREEN_NAME]: {
              path: '/',
              initialRouteName: HOME_STACK_SCREEN_NAME,
              screens: fanTabStackPathConfigMap,
            },
            [LIVE_STREAM_PLAYBACK_SCREEN_NAME]: 'artist/:artistUsername/live',
            [SINGLE_POST_SCREEN_NAME]: 'artist/:artistUsername/:productType/:productId',
            [NOTIFICATIONS_SCREEN_NAME]: 'notifications',
            [MARKETPLACE_NEW_MESSAGE_SCREEN]: 'marketplace/order-details/:orderId/new-message',
          }),
      [FINISH_SIGN_UP_SCREEN_NAME]: {
        path: '/finish-sign-up',
        initialRouteName: HOME_STACK_SCREEN_NAME,
      },
      [LOGIN_STACK_SCREEN_NAME]: {
        path: 'auth',
        screens: loginStackConfigMap,
      },
      [ARTIST_PROFILE_SCREEN_NAME]: 'artist/:artistUsername',
      [MARKETPLACE_ORDER_DETAILS_SCREEN_NAME]: 'marketplace/order-details/:orderId',
      [MARKETPLACE_ORDER_INFORMATION_SCREEN_NAME]: 'marketplace/order-details/:orderId/information',
      [MARKETPLACE_ORDER_REPORT_SCREEN_NAME]: 'marketplace/order-details/:orderId/report',
      [MARKETPLACE_PRODUCT_DETAILS_SCREEN_NAME]: 'marketplace/product-details/:artist/:productId',
      [MARKETPLACE_PRODUCT_ORDER_SCREEN_NAME]: 'marketplace/product-details/:productId/order',
      [CONFIRM_PAYMENT_SCREEN]: 'confirm-payment',
      [ADD_PAYMENT_METHOD_SCREEN_NAME]: 'add-payment-method',
      [HELP_SCREEN_NAME]: 'help',
      [HELP_FORM_SCREEN_NAME]: '/help-form',
      [CONTACT_SCREEN_NAME]: 'contact',
      [CONTACT_FORM_SCREEN_NAME]: '/contact-form',
      [PRESS_SCREEN_NAME]: 'press',
      [PRESS_FORM_SCREEN_NAME]: '/press-form',
      [INVESTOR_RELATIONS_SCREEN_NAME]: '/investor-relations',
      [INVESTOR_RELATIONS_FORM_SCREEN_NAME]: '/investor-relations-form',
      [ON_BOARDING_SCREEN_NAME]: '/on-boarding',
      [PROFILE_SETTINGS_SCREEN_NAME]: '/profile',
      [LIVE_STREAM_PLAYBACK_SCREEN_NAME]: '/live-stream',
      [ARTIST_CONFIRM_EMAIL_SCREEN]: '/confirm/artist',
    },
  },
  getStateFromPath: (path, options) => {
    const trimmedPath = path.replace(/^\/|\/$/g, '');
    let newPath = path;
    console.log('path', path);
    if (
      options &&
      !Object.values(options.screens).some((screen) => {
        const screenPath = (typeof screen === 'string' ? screen : screen.path || '').replace(
          /^\/|\/$/g,
          '',
        );
        return trimmedPath === screenPath;
      }) &&
      !trimmedPath.includes('/')
    ) {
      newPath = `/artist/${trimmedPath}`;
    }
    return getStateFromPath(newPath, options);
  },
};
