import React, { memo } from 'react';

import Logo from 'components/Logo';

import NavView from './styled/NavView';
import NotificationsButton from './styled/NotificationsButton';
import Wrapper from './styled/Wrapper';

interface FeedTopNavProps {
  hasUnreadNotifications?: boolean;
  onNotificationsPress?: () => void;
  isArtist?: boolean;
  showNotificationIcon?: boolean;
}

const FeedTopNav: React.FC<FeedTopNavProps> = ({
  hasUnreadNotifications,
  onNotificationsPress,
  isArtist,
  showNotificationIcon = true,
  ...restProps
}: FeedTopNavProps) => {
  return (
    <Wrapper {...restProps} isArtist={isArtist}>
      <Logo isArtist={isArtist} />
      {!isArtist && showNotificationIcon && (
        <NavView>
          <NotificationsButton
            focused
            onPress={onNotificationsPress}
            hasNotifications={hasUnreadNotifications}
          />
        </NavView>
      )}
    </Wrapper>
  );
};

export default memo(FeedTopNav);
