import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/native';

import OrderStatusBadge, { OrderStatusProps } from 'components/OrderStatusBadge/OrderStatusBadge';

import messages from './messages';

const Wrapper = styled.View`
  margin-bottom: 40px;
  width: ${({ theme }) => (theme.media.tabletLandscape ? '30%' : '100%')};
`;

const Title = styled.Text`
  font-size: 15px;
  font-family: BasierCircle-Medium;
  opacity: 0.52;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.textWhite};
`;

const Number = styled.Text`
  font-size: 34px;
  font-family: BasierCircle-Bold;
  color: ${({ theme }) => theme.colors.textWhite};
`;

const Bottom = styled.View`
  justify-content: space-between;
  flex-direction: row;
`;

interface Props {
  number: string;
  statusProps: OrderStatusProps;
}

const OrderNumberContainer: React.FC<Props> = ({ statusProps, number }: Props) => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Title>{intl.formatMessage(messages.orderNumber)}</Title>
      <Bottom>
        <Number>{number}</Number>
        <OrderStatusBadge {...statusProps} />
      </Bottom>
    </Wrapper>
  );
};

export default OrderNumberContainer;
