import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export default styled.View<{ forPayment?: boolean }>`
  background-color: ${({ theme }) => theme.bottomSheet.background};

  width: 100%;

  ${({ forPayment }) =>
    Platform.OS === 'web' &&
    (forPayment
      ? css`
          padding: 10px 20px 30px;
        `
      : css`
          height: 420px;
          padding: 0 0 10px;
        `)}
  border-radius: 20px;
  align-items: center;
`;
