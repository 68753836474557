import { NavigationContainerRef } from '@react-navigation/native';
import React, {
  lazy,
  MutableRefObject,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Alert, Platform } from 'react-native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

import PaymentContainer from 'containers/PaymentContainer';

import BottomSheet from 'components/BottomSheet';
import TippingCardPrice from 'components/TippingCardPrice';

import { usePayments } from 'context/payments';
import { useConfig, useTipping, useTippingConfig } from 'hooks';
import { PaidProduct, Tip } from 'types';
import { SPRING_CONFIG } from 'utils/animated';

import hands from './images/hands.png';
import messages from './messages';
import ActivityIndicator from './styled/ActivityIndicator';
import BackButton from './styled/BackButton';
import Button from './styled/Button';
import Cards from './styled/Cards';
import CentredDialog from './styled/CenteredDialog';
import CompletedTipView from './styled/CompletedTipView';
import ContentView from './styled/ContentView';
import CustomAmountHeader from './styled/CustomAmountHeader';
import CustomAmountView from './styled/CustomAmountView';
import Dot from './styled/Dot';
import Dots from './styled/Dots';
import GestureControlView from './styled/GestureControlView';
import HandsImage from './styled/HandsImage';
import Input from './styled/Input';
import MainView from './styled/MainView';
import NextButton from './styled/NextButton';
import Text from './styled/Text';
import Title from './styled/Title';
import { translations } from './translations';
const OverlayWeb = lazy(() =>
  Platform.OS === 'web'
    ? import('components/ConfirmPaymentModalWeb/styled/Overlay')
    : new Promise(() => ({})),
);

interface Props {
  navigationRef: MutableRefObject<NavigationContainerRef | null>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  product: PaidProduct;
}

const TippingSheet: React.FC<Props> = ({
  navigationRef,
  isOpen,
  setIsOpen,
  product,
  ...rest
}: Props) => {
  const intl = useIntl();
  const bottomSheetTimeoutId = useRef<number>();
  const { tips, defaultTip } = useTippingConfig();
  const [isBottomSheetClosable, setIsBottomSheetClosable] = useState(true);
  const [isCustomValue, setIsCustomValue] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isAwaitingPayment, setIsAwaitingPayment] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(1);
  const translateX = useSharedValue(translations.noTranslate);

  const [tippingValue, setTippingValue] = useState<number>();
  const { isLoadingPaymentMethods, paymentMethods, iapError, setIapError } = usePayments();
  const { payForTipWithApple, isTipping } = useTipping();
  const { config } = useConfig();

  useEffect(() => {
    return () => {
      clearTimeout(bottomSheetTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    const index = tips.findIndex((t) => t.is_most_popular === true);
    snapToIndex(index);
  }, []);

  useEffect(() => {
    setTippingValue(tips.length && currentCardIndex >= 0 ? tips[currentCardIndex].price : 0);
  }, [currentCardIndex, tips]);

  useEffect(() => {
    if (!isOpen && tips.length) {
      setTimeout(reset, 300);
    }
  }, [isOpen, tips]);

  const snapToIndex = (index: number) => {
    setCurrentCardIndex(index);

    index === 0
      ? (translateX.value = translations.noTranslate + translations.margin)
      : index === 1
      ? (translateX.value = translations.index1)
      : index === 2
      ? (translateX.value = translations.index2)
      : (translateX.value = -translations.noTranslate);
  };

  const reset = () => {
    setIsAwaitingPayment(false);
    setIsCustomValue(false);
    const index = tips.findIndex((t) => t.is_most_popular === true);
    setCurrentCardIndex(index);
    setIsPaid(false);
    if (index >= 0) {
      snapToIndex(index);
    }
  };

  const throwInvalidValueAlert = () => {
    Alert.alert(
      intl.formatMessage(messages.errorAlertTitle),
      intl.formatMessage(messages.errorAlertDescription),
      [{ text: 'OK' }],
    );
  };

  const onConfirmValuePress = useCallback(() => {
    if (tippingValue) {
      if (isNaN(tippingValue) || tippingValue < 0) {
        return throwInvalidValueAlert();
      }
      setIsAwaitingPayment(true);
      // return (bottomSheetTimeoutId.current = setTimeout(() => {
      //   setIsOpen(false);
      // }, 3000));
    } else {
      setIsCustomValue(true);
    }
  }, [setIsOpen, isCustomValue, isAwaitingPayment, tippingValue]);

  const onConfirmCustomAmountPress = () => {
    onConfirmValuePress();
  };

  const onBackPress = useCallback(() => {
    setIsAwaitingPayment(false);
    setIsCustomValue(false);
  }, []);

  const updateIndex = (index: number) => {
    return setCurrentCardIndex(index);
  };

  const onApplePayPress = useCallback(async () => {
    const appleProductId = tips[currentCardIndex].apple_product_id;
    const { artistUsername, modelNamePlural, productId } = product;
    if (!(appleProductId && tippingValue && productId && modelNamePlural)) {
      return;
    }
    const isSuccess = await payForTipWithApple({
      amount: tippingValue,
      artistUsername,
      productId,
      modelNamePlural,
      appleProductId,
    });
    if (isSuccess) {
      setIsPaid(true);
      bottomSheetTimeoutId.current = setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }
  }, [product, currentCardIndex, tippingValue, setIsOpen]);

  const renderItem = ({ item, index }: { item: Tip; index: number }) => (
    <TippingCardPrice
      key={`tipping-card-price-${index}`}
      isActive={index === currentCardIndex}
      price={Number(Number(item.price).toFixed(0))}
      isMostPopular={item.is_most_popular}
      onCardPress={() => snapToIndex(index)}
      index={index}
      currentIndex={currentCardIndex}
    />
  );

  const renderDots = () => {
    return tips.map((_, index) => {
      return <Dot key={`tipping-dot-${index}`} isActive={currentCardIndex === index} />;
    });
  };

  type AnimatedGHContext = {
    startX: number;
    currentIndex: number;
  };

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (_, ctx) => {
      ctx.startX = translateX.value;
      ctx.currentIndex = currentCardIndex;
    },
    onActive: (event, ctx) => {
      translateX.value = ctx.startX + event.translationX;
    },
    onEnd: (event, ctx) => {
      event.translationX > 0
        ? runOnJS(snapToIndex)(ctx.currentIndex !== 0 ? ctx.currentIndex - 1 : 0)
        : runOnJS(snapToIndex)(
            ctx.currentIndex !== tips.length - 1 ? ctx.currentIndex + 1 : tips.length - 1,
          );

      ctx.startX = 0;
    },
  });

  const animatedSheetStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: withSpring(translateX.value, SPRING_CONFIG.NO_BOUNCE),
      },
    ],
  }));

  const renderTipSelection = () => {
    return currentCardIndex >= 0 ? (
      <ContentView>
        <Title>{intl.formatMessage(messages.title.selection)}</Title>
        <Cards>
          <PanGestureHandler onGestureEvent={gestureHandler}>
            <GestureControlView style={animatedSheetStyle}>
              {tips && tips.map((item, index) => renderItem({ item, index }))}
            </GestureControlView>
          </PanGestureHandler>
        </Cards>
        <Dots>{renderDots()}</Dots>
        <Button
          primary
          onPress={Platform.OS === 'ios' ? onApplePayPress : onConfirmValuePress}
          processing={Platform.OS !== 'ios' && isTipping}
          disabled={Platform.OS !== 'ios' && isTipping}
        >
          {intl.formatMessage(messages.button)}
        </Button>
      </ContentView>
    ) : (
      <ActivityIndicator />
    );
  };

  const renderCustomAmountForm = () => (
    <CustomAmountView>
      <CustomAmountHeader>
        <BackButton onBackPress={onBackPress} />
        <Title>{intl.formatMessage(messages.customAmountTitle)}</Title>
        <NextButton
          text={intl.formatMessage(messages.buttonNext)}
          onNextPress={onConfirmCustomAmountPress}
        />
      </CustomAmountHeader>
      <Input value={tippingValue} onChangeValue={setTippingValue} />
    </CustomAmountView>
  );

  const renderPayment = () => (
    <PaymentContainer
      navigationRef={navigationRef}
      title={intl.formatMessage(messages.title.payment)}
      isLoadingPaymentMethods={isLoadingPaymentMethods}
      paymentMethods={paymentMethods}
      iapError={iapError}
      setIapError={setIapError}
      setIsOpen={setIsOpen}
      onBackPress={onBackPress}
      product={{
        ...product,
        price: Number(Number(tippingValue).toFixed(0)),
        appleProductId:
          tips.length && currentCardIndex >= 0
            ? tips[currentCardIndex].apple_product_id
            : undefined,
      }}
      onProcessingChange={(isProcessing) => setIsBottomSheetClosable(!isProcessing)}
    />
  );

  const renderTipCompleted = () => (
    <CompletedTipView>
      <HandsImage source={hands} style={{ resizeMode: 'contain' }} />
      <Title completedOrder>{intl.formatMessage(messages.completedTipTitle)}</Title>
      <Text>{intl.formatMessage(messages.completedTipDescription)}</Text>
    </CompletedTipView>
  );

  const renderContent = () => {
    if (isPaid) {
      return renderTipCompleted();
    }
    if (isAwaitingPayment) {
      if (Platform.OS === 'web') return <ContentView forPayment>{renderPayment()}</ContentView>;
      return renderPayment();
    }
    if (isCustomValue) {
      return renderCustomAmountForm();
    }
    return isOpen ? renderTipSelection() : null;
  };

  if (!config.tippingEnabled) return null;
  if (Platform.OS !== 'web')
    return (
      <MainView {...rest}>
        <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen} isClosable={isBottomSheetClosable}>
          {renderContent()}
        </BottomSheet>
      </MainView>
    );
  return (
    <Suspense fallback={null}>
      <OverlayWeb overlayPress={() => setIsOpen(false)} isOpen={isOpen}>
        <CentredDialog>{renderContent()}</CentredDialog>
      </OverlayWeb>
    </Suspense>
  );
};

export default TippingSheet;
