import { memo } from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components/native';

export default memo(
  styled.View`
    margin: 5.5% 0 0 0;
  `,
  isEqual,
);
