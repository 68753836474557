import React from 'react';
import styled, { css } from 'styled-components/native';

const WrapperView = styled.View`
  position: relative;
  width: 100%;
  align-items: center;
  margin: 5px 0;
  height: 5px;
`;

interface HandleViewProps {
  dark?: boolean;
}

const HandleView = styled.View<HandleViewProps>`
  width: 36px;
  height: 5px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ dark }) =>
    dark &&
    css`
      background-color: ${({ theme }) => theme.colors.primaryGhost};
    `}
`;

interface HandleProps {
  dark?: boolean;
}

const Handle: React.FC<HandleProps> = (props: HandleProps) => (
  <WrapperView {...props}>
    <HandleView dark={props.dark} />
  </WrapperView>
);

export default Handle;
