export const fullName = /^\D+\s+\D+$/i;
export const email =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const weakPassword = /.{8,}/;
export const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const lowerAndUpper = /^(?=.*[a-z])(?=.*[A-Z])/;
export const specialCharacter = /[!@#$%^&*(),.?":{}|<>+=]/;
export const number = /(?=.*[0-9])/;
export const artistId = /\d+/;
export const hashtag = /#(\w+)/g;
export const username = /^([\w-]{1,100})$/;
export const filename = /^([\w-]+)$/;
export const date =
  /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
export const link =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const containsLink = (test: string): boolean =>
  /.*https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*).*/.test(
    test,
  );

export default {
  fullName,
  email,
  weakPassword,
  strongPassword,
  lowerAndUpper,
  specialCharacter,
  number,
  artistId,
  hashtag,
  username,
  date,
  link,
  filename,
};
