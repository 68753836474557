import styled from 'styled-components/native';

import Fade from 'components/Transitions/Fade/Fade';

export default styled(Fade)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(18, 18, 18, 0.6);
`;
