import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

export const getFingerprint = async (): Promise<string> => {
  const fp = await fpPromise;
  const result = await fp.get();
  return result.visitorId;
};
