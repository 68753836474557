import {
  AVPlaybackStatus,
  ResizeMode,
  Video,
  VideoFullscreenUpdate,
  VideoFullscreenUpdateEvent,
  VideoReadyForDisplayEvent,
} from 'expo-av';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Platform, StyleProp, View as RNView, ViewProps, ViewStyle } from 'react-native';

import ActivityIndicator from 'components/ActivityIndicator';
import ItemOverlay from 'components/ItemOverlay';
import { LockedItemProps } from 'components/LockedItem/LockedItem';
import Fade from 'components/Transitions/Fade/Fade';

import { VARIANT } from 'context/appInfo';
import { useMemoizedImage } from 'hooks/useMemoizedImage';
import { screenWidth } from 'utils/screen';
import { uriWithoutParams } from 'utils/string';

import LockedItem from './styled/LockedItem';
import VideoStyled from './styled/Video';
import VideoContainer from './styled/VideoContainer';
import VideoControls from './styled/VideoControls';
import VideoEndScreen from './styled/VideoEndScreen';
import View from './styled/View';

export interface FeedItemVideoContentProps {
  /** User's access to the video */
  access: {
    hasAccess: boolean;
  };
  /** If enabled the video will be playing in background */
  playInBackground?: boolean;
  /** Video URL */
  url?: string;
  /** Video cover URL. This cover is shown until the video is played for the first time */
  coverImageUrl?: string;
  /** Whether the video should auto play */
  autoPlay?: boolean;
  /** Whether the video should start with the audio muted */
  startMuted?: boolean;
  /** Whether the video should loop */
  shouldLoop?: boolean;
  /** If true, the video controls will automatically hide when the video is playing */
  autoHideControls?: boolean;
  /** Show/hide a darker overlay over the video to help with text or icons legibility */
  showOverlay?: boolean;
  /** Video media width in pixels */
  mediaWidth?: number;
  /** Video media height in pixels */
  mediaHeight?: number;
  /** Video teaser URL. Used as the video source URL if `access.hasAccess` is `false` */
  teaserUrl?: string;
  /** Extra data: the video item's index on the `<Feed/>`, passed in callbacks */
  dataIndex?: number;
  /** Show/hide fullscreen button */
  showFullscreenButton?: boolean;
  /** Show/hide progress bar */
  showProgressBar?: boolean;
  /** Show/hide timestamp */
  showVideoTimestamp?: boolean;
  /** Show/hide all controls */
  showControls?: boolean;
  /** Show/hide controls at the bottom of the video */
  showControlsBottom?: boolean;
  /** Video resize mode */
  resizeMode?: ResizeMode;
  /** Wheather the video is played in fulscreen mode */
  isFullscreen?: boolean;
  /** Callback fired when the video is ready to be displayed, containing the video resolution */
  onDimensions?: (width: number, height: number) => void;
  /** Callback fired when the video is ready to be displayed */
  onLoad?: () => void;
  /** Callback fired when the video is muted / unmuted */
  onMutedChange?: (isMuted: boolean) => void;
  /** Callback fired when the video is played / paused */
  onIsPlayingChange?: (isPlaying: boolean) => void;
  /** Callback fired when the video plays all the way till the end */
  onEndCallback?: () => void;
  /**
   * Callback fired upon initialization of the component, passing an activation function to the
   * parent component. That function can be then used to externally activate (play) the video.
   */
  refActivate?: (index: number, activateFn: (() => void) | null) => void;
  /**
   * Callback fired upon initialization of the component, passing an deactivation function to the
   * parent component. That function can be then used to externally deactivate (pause) the video.
   */
  refDeactivate?: (index: number, deactivateFn: (() => void) | null) => void;
  /** Callback fired when the locked item is pressed */
  lockedItemProps?: LockedItemProps;
  style?: StyleProp<ViewStyle>;
}

/**
 * Internal component used by FeedItem to display video content.
 */
const FeedItemVideoContent: React.FC<FeedItemVideoContentProps> = ({
  access,
  url,
  coverImageUrl,
  autoPlay = false,
  startMuted = true,
  autoHideControls = true,
  showOverlay,
  mediaWidth,
  mediaHeight,
  teaserUrl,
  dataIndex,
  showFullscreenButton = VARIANT === 'fan',
  showProgressBar = false,
  showVideoTimestamp = false,
  showControls = true,
  showControlsBottom = false,
  shouldLoop = false,
  playInBackground = false,
  resizeMode = ResizeMode.COVER,
  onDimensions,
  onLoad,
  onMutedChange,
  onIsPlayingChange,
  onEndCallback,
  refActivate,
  refDeactivate,
  lockedItemProps,
  style,
  ...restProps
}: FeedItemVideoContentProps) => {
  const videoRef = useRef<Video>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [durationMillis, setDurationMillis] = useState(0);
  const [positionMillis, setPositionMillis] = useState(0);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(startMuted);
  const [hasEnded, setHasEnded] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const coverImageSrc = useMemoizedImage({ uri: coverImageUrl });

  //google storage url contains params which change on every request, we need to change the source only when the base url changes
  const memoUrl = useMemo(() => (url ? uriWithoutParams(url) : ''), [url]);
  const memoTeaserUrl = useMemo(() => (teaserUrl ? uriWithoutParams(teaserUrl) : ''), [teaserUrl]);
  const videoSource = useMemo(() => {
    let src;
    if (access?.hasAccess) {
      src = url ? url : '';
    } else {
      src = teaserUrl ? teaserUrl : '';
    }
    return { uri: src };
  }, [access?.hasAccess, memoTeaserUrl, memoUrl]);

  const activateExternal = () => {
    !hasEnded && setShouldPlay(true);
  };

  const deactivateExternal = () => {
    setShouldPlay(false);
  };

  const refView = (el: RNView | null): void => {
    // On web, the internal <video> element does not have its height set properly.
    if (el && Platform.OS === 'web') {
      const divEl = el as unknown as HTMLDivElement;
      divEl.style.backgroundColor = '#000';
      const videoEl = divEl.querySelector('video') as HTMLVideoElement;
      videoEl.style.position = 'absolute';
      videoEl.style.objectFit = 'contain';
      videoEl.style.top = '0';
      videoEl.style.left = '0';
      videoEl.style.height = '100%';
      videoEl.style.width = '100%';
      videoEl.oncanplay = function () {
        setIsLoaded(true);
      };
    }
  };

  const toggleVideoPlayback = () => {
    setShouldPlay((prev) => {
      onIsPlayingChange?.(!prev);
      if (Platform.OS === 'web') {
        if (!prev) {
          videoRef.current?.playAsync();
        } else {
          videoRef.current?.pauseAsync();
        }
      }
      return !prev;
    });
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    onMutedChange?.(!isMuted);
  };

  const replay = () => {
    videoRef.current?.replayAsync().then(() => {
      setShouldPlay(true);
      onIsPlayingChange?.(true);
      if (Platform.OS === 'web') {
        videoRef.current?.playAsync();
      }
    });
  };

  const onReadyForDisplay = (event: VideoReadyForDisplayEvent) => {
    let videoWidth = mediaWidth || 640;
    let videoHeight = mediaHeight || 480;

    if (mediaWidth === undefined || mediaHeight === undefined) {
      if (event.naturalSize) {
        // Native
        videoWidth = event.naturalSize.width;
        videoHeight = event.naturalSize.height;
      } else if ((event as any).srcElement) {
        // Web
        videoWidth = (event as any).srcElement.videoWidth;
        videoHeight = (event as any).srcElement.videoHeight;
      }
    }

    onDimensions?.(videoWidth, videoHeight);
    onLoad?.();

    if (autoPlay) {
      setShouldPlay(true);
      if (Platform.OS === 'web') {
        videoRef.current?.playAsync();
      }
    }
  };

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    setIsLoaded(status.isLoaded);
    if (status.isLoaded) {
      setIsBuffering(status.isBuffering);
      setIsPlaying(status.isPlaying);
      const positionMillis = status.positionMillis ?? 0;
      const durationMillis = Number.isNaN(status.durationMillis) ? 1 : status.durationMillis ?? 1;
      setPositionMillis(positionMillis);
      setDurationMillis(durationMillis);

      if (status.didJustFinish) {
        // On iOS, didJustFinish is true, then false quickly after. So we make sure we set it to false
        // only when the video actually resumes playing.
        setHasEnded(true);
      }

      if (status.isPlaying && hasEnded) {
        setHasEnded(false);
      }
    }
  };

  const onFullscreenPress = () => {
    videoRef.current?.presentFullscreenPlayer();
  };

  const onFullscreenUpdate = (e: VideoFullscreenUpdateEvent) => {
    setIsFullscreen((prevState) => {
      if (e.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_PRESENT) return true;
      if (e.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_WILL_DISMISS) return false;
      return prevState;
    });
  };

  useEffect(() => {
    typeof dataIndex !== 'undefined' && refActivate?.(dataIndex, activateExternal);
    typeof dataIndex !== 'undefined' && refDeactivate?.(dataIndex, deactivateExternal);

    return () => {
      typeof dataIndex !== 'undefined' && refActivate?.(dataIndex, null);
      typeof dataIndex !== 'undefined' && refDeactivate?.(dataIndex, null);
    };
  }, [dataIndex, refActivate, refDeactivate]);

  useEffect(() => {
    if (isPlaying) {
      setHasStartedPlaying(true);
      if (shouldLoop) setIsLooping(true);
    }
  }, [isPlaying, shouldLoop]);

  useEffect(() => {
    setIsMuted(startMuted);
  }, [startMuted]);

  useEffect(() => {
    if (hasEnded) {
      onEndCallback?.();

      if (shouldLoop) {
        replay();
      }
    }
  }, [hasEnded, shouldLoop, onEndCallback]);

  if (!access.hasAccess) {
    return (
      <LockedItem
        type="video"
        showOverlay={showOverlay}
        isLandscape={Number(mediaWidth) > Number(mediaHeight)}
        {...lockedItemProps}
      />
    );
  }

  return (
    <View
      ref={refView}
      style={
        Platform.OS !== 'web'
          ? mediaHeight && mediaWidth && lockedItemProps?.isExpanded
            ? {
                width: screenWidth,
                height: (mediaHeight * screenWidth) / mediaWidth,
              }
            : undefined
          : style
      }
      {...restProps}
    >
      <VideoContainer>
        <VideoStyled
          ref={videoRef}
          source={videoSource}
          shouldPlay={shouldPlay}
          isMuted={isMuted}
          resizeMode={isFullscreen ? ResizeMode.CONTAIN : resizeMode}
          onReadyForDisplay={onReadyForDisplay}
          onPlaybackStatusUpdate={onPlaybackStatusUpdate}
          onFullscreenUpdate={onFullscreenUpdate}
          posterSource={coverImageSrc}
          posterStyle={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            resizeMode: ResizeMode.COVER,
          }}
          usePoster
          progressUpdateIntervalMillis={500}
        />
        {!showControls && (!isLoaded || isBuffering) && <ActivityIndicator animating />}
      </VideoContainer>
      {showOverlay && <ItemOverlay />}
      {showControls && !hasEnded && (
        <Fade fill>
          <VideoControls
            showControlsBottom={showControlsBottom}
            durationMillis={durationMillis}
            positionMillis={positionMillis}
            setPositionMillis={async (positionMillis) => {
              await videoRef.current?.setStatusAsync({
                positionMillis: positionMillis,
                seekMillisToleranceBefore: 0,
                seekMillisToleranceAfter: 0,
              });
            }}
            showLoader={isBuffering || !isLoaded}
            isPlaying={isPlaying}
            showMuteButton
            isMuted={playInBackground ? isMuted : true}
            isLooping={isLooping}
            autoHide={(autoHideControls && isPlaying) || (autoPlay && !hasStartedPlaying)}
            onFullscreenPress={onFullscreenPress}
            showFullscreenButton={!isFullscreen && showFullscreenButton}
            onPlayPausePress={toggleVideoPlayback}
            onMutePress={toggleMute}
            showProgressBar={showProgressBar}
            showVideoTimestamp={showVideoTimestamp}
            isFullscreen={isFullscreen}
          />
        </Fade>
      )}

      {access?.hasAccess && hasEnded && !shouldLoop && (
        <Fade fill>
          <VideoEndScreen
            onReplayPress={replay}
            onMutePress={toggleMute}
            onFullscreenPress={onFullscreenPress}
            showMuteButton
            showFullscreenButton={false}
            showProgressBar={showProgressBar}
            isMuted={isMuted}
          />
        </Fade>
      )}
    </View>
  );
};

export default FeedItemVideoContent;
