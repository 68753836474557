import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';

const BackArrowSvg = (props: SvgProps) => {
  return (
    <Svg width="8" height="14" viewBox="0 0 8 14" fill="none" {...props}>
      <Path
        d="M7 13L1 7L7 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const StyledTouchableOpacity = styled.TouchableOpacity`
  margin-top: 38px;
  padding: 5px;
`;

interface BackButtonProps {
  onBackPress?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onBackPress }: BackButtonProps) => {
  return (
    <StyledTouchableOpacity onPress={onBackPress}>
      <BackArrowSvg />
    </StyledTouchableOpacity>
  );
};

export default BackButton;
