import Animated from 'react-native-reanimated';
import styled, { css } from 'styled-components/native';

interface Props {
  index: number;
}

export default styled(Animated.View)<Props>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-color: ${({ theme }) => theme.marketplace.border};
  border-bottom-width: 1px;
  ${({ index }) =>
    index === 0 &&
    css`
      border-top-width: 1px;
    `}
`;
