import React from 'react';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';

import Dialog from 'components/Dialog';

import { SubscriptionStatus } from 'hooks/subscriptions';
import { Artist } from 'types';

import Button from './styled/Button';
import ButtonText from './styled/ButtonText';
import ButtonTextSecondary from './styled/ButtonTextSecondary';

interface Props {
  artist?: Artist;
  isOpen: boolean;
  isLoadingSubscriptionStatus?: boolean;
  subscriptionStatus?: SubscriptionStatus;
  setIsOpen: (isOpen: boolean) => void;
  unsubscribe: (artistUsername: string) => Promise<null | undefined>;
  onUnsubscribed: () => void;
}

const UnsubscribeDialog: React.FC<Props> = ({
  artist,
  isOpen,
  isLoadingSubscriptionStatus,
  subscriptionStatus,
  setIsOpen,
  unsubscribe,
  onUnsubscribed,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      onPressOutside={() => setIsOpen(false)}
      visible={isOpen}
      title={intl.formatMessage({
        id: 'subscription.unsubscribe',
        defaultMessage: 'Unsubscribe',
      })}
      subtitle={`${intl.formatMessage({
        id: 'subscription.areYouSure',
        defaultMessage: 'Are you sure that you want to unsubscribe',
      })} ${artist?.display_name.replaceAll(' ', '\xa0')}?`}
    >
      <Button onPress={() => setIsOpen(false)}>
        <ButtonText>
          {intl.formatMessage({
            id: 'subscription.stayStan',
            defaultMessage: 'Stay as a Stan',
          })}
        </ButtonText>
      </Button>
      <Button
        processing={isLoadingSubscriptionStatus}
        secondary
        onPress={async () => {
          setIsOpen(false);
          if (subscriptionStatus?.is_paid_with_apple_iap) {
            Linking.openURL('https://apps.apple.com/account/subscriptions');
          } else {
            const result = artist?.username && (await unsubscribe(artist.username));
            if (result !== undefined) {
              onUnsubscribed?.();
            }
          }
        }}
      >
        <ButtonTextSecondary>
          {intl.formatMessage({
            id: 'subscription.unsubscribe',
            defaultMessage: 'Unsubscribe',
          })}
        </ButtonTextSecondary>
      </Button>
    </Dialog>
  );
};

export default UnsubscribeDialog;
