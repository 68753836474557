import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useMoreOptionsSet } from 'context/moreOptions';
import { useTimeAgo } from 'hooks/useTimeAgo';
import { Comment, CommentDeleteFunc } from 'types';
import { getProfileLowResPictureUrl } from 'utils/user';

import { MAX_DISPLAY_LENGTH } from './CommentItem.constants';
import { useExpandedComment } from './CommentItem.hooks';
import messages from './messages';
import ArtistBadge from './styled/ArtistBadge';
import Author from './styled/Author';
import Avatar from './styled/Avatar';
import CommentWrapper from './styled/CommentWrapper';
import Container from './styled/Container';
import Content from './styled/Content';
import ContentFooter from './styled/ContentFooter';
import OptionsButton from './styled/OptionsButton';
import ShowMoreButton from './styled/ShowMoreButton';
import ShowMoreLabel from './styled/ShowMoreLabel';
import Text from './styled/Text';
import TimeAgo from './styled/TimeAgo';
import TimeAgoWrapper from './styled/TimeAgoWrapper';

export interface CommentItemProps {
  comment: Comment;
  reportable?: boolean;
  deleteComment: CommentDeleteFunc;
  isProcessingComment: (id: string | number) => boolean;
  onReport?: () => void;
}

const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  deleteComment,
  reportable = true,
  isProcessingComment,
  ...rest
}: CommentItemProps): JSX.Element | null => {
  const { display_name: displayName, id, is_artist: isArtist, time_created: timeCreated } = comment;

  const intl = useIntl();
  const isProcessing = isProcessingComment(id);
  const { timeAgoText } = useTimeAgo(timeCreated, 5000);
  const setMoreOptionsState = useMoreOptionsSet();
  const [isHovered, setIsHovered] = useState(false);
  const { isExpanded, setIsExpanded, displayText } = useExpandedComment(comment);

  const onMorePress = () =>
    setMoreOptionsState({
      itemType: 'comment',
      itemId: id,
      isMoreOptionsOpen: true,
      blockedUsername: displayName,
      onClose: undefined,
    });

  return (
    <Container
      {...rest} // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CommentWrapper>
        <Avatar
          mode="compact-small"
          imageUrl={getProfileLowResPictureUrl(comment)}
          showName={false}
        />
        <Content isProcessing={isProcessing}>
          <Text>
            <Author>{displayName}</Author>
            <Text>{` ${displayText}`}</Text>
          </Text>
          <ContentFooter>
            <TimeAgoWrapper>
              <TimeAgo>{timeAgoText}</TimeAgo>
              {isArtist && <ArtistBadge text={intl.formatMessage(messages.artistLabel)} />}
              <OptionsButton
                isOwnComment={!reportable}
                isVisible={isHovered}
                onPress={reportable ? onMorePress : () => deleteComment(id)}
              />
            </TimeAgoWrapper>
            {comment?.text.length > MAX_DISPLAY_LENGTH && (
              <ShowMoreButton onPress={() => setIsExpanded((prev) => !prev)}>
                <ShowMoreLabel>
                  {intl.formatMessage(isExpanded ? messages.showLess : messages.showMore)}
                </ShowMoreLabel>
              </ShowMoreButton>
            )}
          </ContentFooter>
        </Content>
      </CommentWrapper>
    </Container>
  );
};

CommentItem.displayName = 'CommentItem';

export default CommentItem;
