import styled from 'styled-components/native';

export default styled.TouchableOpacity`
  width: 100%;
  aspect-ratio: 1;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.mediaPicker.border};
  border-radius: 15px;
  margin: 10px 0 20px 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
`;
