import styled from 'styled-components/native';

export default styled.Text`
  font-size: 15px;
  font-family: 'BasierCircle-Regular';
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.primaryGhost};
`;
