export default {
  title: {
    id: 'artistContactAndDetailScreen.title',
    defaultMessage: 'Contact Details & Payments',
  },
  description: {
    id: 'artistContactAndDetailScreen.title',
    defaultMessage:
      'Stanbase use wise.com to pay our artists internationally straight in to their bank accounts. Please confirm the email you would like us to contact you on to receive your money.',
  },
  submit: {
    id: 'artistContactAndDetailScreen.submit',
    defaultMessage: 'Update email',
  },
  email: {
    id: 'artistContactAndDetailScreen.email',
    defaultMessage: 'E-mail address',
  },
  password: {
    id: 'artistContactAndDetailScreen.password',
    defaultMessage: 'Current password',
  },
  success: {
    title: {
      id: 'artistContactAndDetailScreen.success.title',
      defaultMessage: 'Success!',
    },
    description: {
      id: 'artistContactAndDetailScreen.success.description',
      defaultMessage:
        'Please confirm your address by clicking the link in the e-mail you received. This will also update your login credentials.',
    },
  },
  emailConfirmed: {
    title: {
      id: 'artistContactAndDetailScreen.emailConfirmed.title',
      defaultMessage: 'Email Confirmed!',
    },
    description: {
      id: 'artistContactAndDetailScreen.emailConfirmed.description',
      defaultMessage:
        'Thanks for confirming your details. StanBase will be paying you using your our partner wise.com at the end of every month, so keep your eyes peeled for an email from them',
    },
  },
  error: {
    emailRequired: {
      id: 'artistContactAndDetailScreen.error.emailRequired',
      defaultMessage: 'Please provide e-mail address.',
    },
    passwordRequired: {
      id: 'artistContactAndDetailScreen.error.passwordRequired',
      defaultMessage: 'Please provide your password.',
    },
    emailIncorrect: {
      id: 'artistContactAndDetailScreen.error.emailIncorrect',
      defaultMessage: 'Provided e-mail address is incorrect.',
    },
  },
};
