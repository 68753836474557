import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Button from './Button';

export default styled(Button)`
  margin-left: 8px;

  ${Platform.OS === 'web' &&
  css`
    margin: 0 16px 0 0;
    border: 1.6px solid ${(props) => props.theme.button.backgroundColorBlue};
  `}
`;
