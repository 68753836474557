import { AxiosError } from 'axios';

import { useAuthSwr } from 'hooks/swr';
import { flattenMessages } from 'utils/translations';

export const TRANSLATIONS_KEY = '/copy';

export function useTranslations(
  countryCode: string,
  localeCode: string,
  mockValue?: Record<string, string>,
): {
  translations: Record<string, any>;
  isLoading: boolean;
  error: AxiosError<any>;
} {
  const {
    isLoading,
    data: translations,
    error,
  } = useAuthSwr<Record<string, string>>({
    key: `${TRANSLATIONS_KEY}?country_code=${countryCode}&locale_code=${localeCode}`,
    isPublic: true,
    cache: true,
    mockValue,
    swrOptions: { refreshInterval: 0, revalidateOnFocus: false },
  });

  return {
    translations: flattenMessages(translations),
    isLoading,
    error,
  };
}
