import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Portal } from 'react-native-paper';

import { ParamList } from 'stacks/types';

import { SCREEN_NAME as ARTIST_CHANGE_SUBSCRIPTION_PRICE_SCREEN_NAME } from 'screens/ArtistChangeSubscriptionPriceScreen';
// import { SCREEN_NAME as NOTIFICATIONS_SETTINGS_SCREEN_NAME } from 'screens/NotificationsSettingsScreen/constants';
import { SCREEN_NAME as BLOCKED_USERS_SCREEN_NAME } from 'screens/BlockedUsersScreen/constants';
import { SCREEN_NAME as PAYMENT_METHODS_SCREEN_NAME } from 'screens/PaymentMethodsScreen/constants';
import { SCREEN_NAME as PROFILE_SETTINGS_SCREEN_NAME } from 'screens/ProfileSettingsScreen/constants';
import { SCREEN_NAME as SAVED_POSTS_SCREEN_NAME } from 'screens/SavedPostsScreen/constants';
import { SCREEN_NAME as SUBSCRIBED_PROFILES_SCREEN_NAME } from 'screens/SubscribedProfilesScreen/constants';

import ActivityIndicatorStyled from 'components/ActivityIndicator/ActivityIndicator';
import BottomSheet from 'components/BottomSheet';
import ScreenView from 'components/ScreenView';

import { usePushNotifications, VARIANT } from 'context';
import { useBackEnd } from 'context/backEnd';
import { WebviewContext } from 'context/webview';
import { useAuth, useUser } from 'hooks';
import { helpURL, privacyPolicyURL } from 'utils/links';

import { SCREEN_NAME as ARTIST_CONTACT_AND_DETAILS_SCREEN_NAME } from '../ArtistContactAndDetailsScreen';
import { SCREEN_NAME } from './constants';
import messages from './messages';
import ProfileDashboardButton from './ProfileDashboardButton';
import ActivityIndicatorView from './styled/ActivityIndicatorView';
import ConfirmationButton from './styled/ConfirmationButton';
import ConfirmationButtonSpacer from './styled/ConfirmationButtonSpacer';
import ConfirmationRow from './styled/ConfirmationRow';
import ConfirmationText from './styled/ConfirmationText';
import ConfirmationView from './styled/ConfirmationView';
import DimView from './styled/DimView';
import GoLiveButton from './styled/GoLiveButton';
import GoLiveText from './styled/GoLiveText';
import Header from './styled/Header';
import OptionsGroup from './styled/OptionsGroup';
import ScrollView from './styled/ScrollView';
import SuccessText from './styled/SuccessText';
import SuccessView from './styled/SuccessView';
import Version from './styled/Version';

export { SCREEN_NAME };

const artistProfileStackScreenName =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VARIANT === 'fan' ? undefined : require('stacks/artist/ArtistProfileStack').SCREEN_NAME;

type ProfileDashboardScreenRouteProp = RouteProp<ParamList, typeof SCREEN_NAME>;

type ProfileDashboardScreenNavigationProp = StackNavigationProp<ParamList, typeof SCREEN_NAME>;

type Props = {
  route: ProfileDashboardScreenRouteProp;
  navigation: ProfileDashboardScreenNavigationProp;
};

const ProfileDashboardScreen: React.FC<Props> = ({ navigation }: Props) => {
  const intl = useIntl();
  const { deviceToken } = usePushNotifications();
  const { logOut } = useAuth();
  const { openWebView } = useContext(WebviewContext);
  const { profile } = useUser();
  const [buildNumber, setBuildNumber] = useState<string>('');
  const [isConfirmationAlertOpen, setIsConfirmationAlertOpen] = useState<boolean>(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { axiosInstance } = useBackEnd();

  useEffect(() => {
    getBuildNumber();
  }, []);

  const getBuildNumber = async () => {
    const number = await DeviceInfo.getBuildNumber();
    const version = await DeviceInfo.getVersion();
    setBuildNumber(`v. ${version} (${number})`);
  };
  const goTo = useCallback(
    (target: any) => {
      navigation.navigate(target);
    },
    [navigation],
  );

  const logout = useCallback(() => {
    logOut(deviceToken, DeviceInfo.getUniqueId());
  }, [deviceToken, logOut]);

  const onHelpPress = useCallback(() => {
    openWebView?.(helpURL);
  }, [openWebView]);

  const onPrivacyPolicyPress = useCallback(() => {
    openWebView?.(privacyPolicyURL);
  }, [openWebView]);

  const onDeleteAccountPress = useCallback(() => {
    setIsConfirmationAlertOpen(true);
  }, []);

  const onAlertConfirm = useCallback(async () => {
    setIsConfirmationAlertOpen(false);
    setIsLoading(true);
    const response = await axiosInstance.delete('/auth/user');
    setIsLoading(false);
    return alert(
      response.status == 200
        ? 'Your Stanbase user has been deleted.'
        : 'Your Stanbase user can’t be deleted at this time. Please try later.',
    );
  }, [axiosInstance]);

  const onAlertCancel = useCallback(() => {
    setIsConfirmationAlertOpen(false);
  }, []);

  const renderConfirmationAlert = () => (
    <BottomSheet isOpen={isConfirmationAlertOpen} setIsOpen={setIsConfirmationAlertOpen}>
      <ConfirmationView>
        <ConfirmationText>Are you sure?</ConfirmationText>
        <ConfirmationText isDescription>
          You will lose access to your favourite artists, purchases and your uploaded content.
        </ConfirmationText>
        <ConfirmationRow>
          <ConfirmationButton onPress={onAlertConfirm} warning>
            Confirm
          </ConfirmationButton>
          <ConfirmationButtonSpacer />
          <ConfirmationButton onPress={onAlertCancel}>Cancel</ConfirmationButton>
        </ConfirmationRow>
      </ConfirmationView>
    </BottomSheet>
  );

  return (
    <ScreenView>
      <ScrollView>
        <Header>
          {intl.formatMessage(
            VARIANT === 'artist'
              ? messages.headerTitleArtist
              : profile?.full_name
              ? messages.headerTitle
              : messages.headerTitleNoName,
            { fullName: profile?.full_name },
          )}
        </Header>
        {VARIANT === 'fan' ? (
          <>
            <OptionsGroup
              options={[
                // {
                //   label: intl.formatMessage(messages.liveEventBookings),
                //   value: LIVE_EVENT_BOOKINGS_SCREEN_NAME,
                //   onAction: goTo,
                // },
                {
                  label: intl.formatMessage(messages.bookmarks),
                  value: SAVED_POSTS_SCREEN_NAME,
                  onAction: goTo,
                },
                {
                  label: intl.formatMessage(messages.subscriptions),
                  value: SUBSCRIBED_PROFILES_SCREEN_NAME,
                  onAction: goTo,
                },
                {
                  label: intl.formatMessage(messages.blockedUsers),
                  value: BLOCKED_USERS_SCREEN_NAME,
                  onAction: goTo,
                },
              ]}
            />
            <OptionsGroup
              options={[
                {
                  label: intl.formatMessage(messages.profile),
                  value: PROFILE_SETTINGS_SCREEN_NAME,
                  onAction: goTo,
                },
                // TODO: re-enable once implemented on the BE
                // {
                //   label: intl.formatMessage(messages.notifications),
                //   value: NOTIFICATIONS_SETTINGS_SCREEN_NAME,
                //   onAction: goTo,
                // },
                {
                  label: intl.formatMessage(messages.paymentMethods),
                  value: PAYMENT_METHODS_SCREEN_NAME,
                  onAction: goTo,
                },
              ]}
            />
          </>
        ) : (
          <>
            {VARIANT === 'artist' && profile && (
              <ProfileDashboardButton
                label={profile.display_name}
                imageSrc={profile.profile_picture}
                onPress={() => goTo(artistProfileStackScreenName)}
              />
            )}
            <OptionsGroup
              options={[
                {
                  label: intl.formatMessage(messages.profile),
                  value: PROFILE_SETTINGS_SCREEN_NAME,
                  onAction: goTo,
                },
                {
                  label: intl.formatMessage(messages.contactAndPayments),
                  value: ARTIST_CONTACT_AND_DETAILS_SCREEN_NAME,
                  onAction: goTo,
                },
              ]}
            />
            <OptionsGroup
              options={[
                {
                  label: intl.formatMessage(messages.subscriptionPrice),
                  value: ARTIST_CHANGE_SUBSCRIPTION_PRICE_SCREEN_NAME,
                  onAction: goTo,
                },
              ]}
            />
          </>
        )}
        <OptionsGroup
          options={[
            {
              label: intl.formatMessage(messages.help),
              onAction: onHelpPress,
            },
            {
              label: intl.formatMessage(messages.privacyPolicy),
              onAction: onPrivacyPolicyPress,
            },
            {
              label: intl.formatMessage(messages.logOut),
              noArrow: true,
              onAction: logout,
            },
          ]}
        />
        {VARIANT === 'fan' && (
          <OptionsGroup
            options={[
              {
                label: 'Delete your Stanbase account',
                onAction: onDeleteAccountPress,
                noArrow: true,
                isDeleteButton: true,
              },
            ]}
          />
        )}
        <Version>{buildNumber}</Version>
      </ScrollView>
      <Portal>
        <Modal animationType="fade" transparent={true} visible={isLoading} statusBarTranslucent>
          <ActivityIndicatorView>
            <ActivityIndicatorStyled />
          </ActivityIndicatorView>
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={isConfirmationAlertOpen}
          statusBarTranslucent
        >
          <GestureHandlerRootView style={{ width: '100%', height: '100%' }}>
            {renderConfirmationAlert()}
          </GestureHandlerRootView>
        </Modal>
      </Portal>
    </ScreenView>
  );
};

export default ProfileDashboardScreen;
